import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { headerActions as actions } from './';
import { Search, SearchSupplier } from './types';

function* getSearch(action) {
  const requestURL = `${protectedResources.api.apiEndpoint}/Search/JobSearch?JobNumber=${action.payload.value}`;
  try {
    const search: Search = yield call(request, requestURL);
    if (search) {
      yield put(actions.searchLoaded(search));
    } else {
      yield put(
        actions.searchError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.searchError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.searchError({ error: 'Issue with api', loading: false }));
    }
  }
}
function* getSearchSupplier(action) {
  const requestURL = `${protectedResources.api.apiEndpoint}/Search/JobLineItemSearch?JobLineId=${action.payload.value}`;
  try {
    const searchSupplier: SearchSupplier = yield call(request, requestURL);
    if (searchSupplier) {
      yield put(actions.searchLoadedSupplier(searchSupplier));
    } else {
      yield put(
        actions.searchErrorSupplier({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.searchErrorSupplier({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.searchErrorSupplier({ error: 'Issue with api', loading: false }));
    }
  }
}

export function* headerSaga() {
  yield takeLatest(actions.loadSearch.type, getSearch);
  yield takeLatest(actions.loadSearchSupplier.type, getSearchSupplier);
}
