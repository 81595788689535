import React from 'react';
import { useParams } from 'react-router-dom';
import { request } from 'utils/request';
import { protectedResources } from '../../../../../configs/msalAuthConfigs';
import { Modal, Button } from 'react-bootstrap';
import { ReactComponent as ConfirmationWarningIcon } from '../../../../../assets/icons/confirmationWarning.svg';
import { QUOTATION, QUOTED } from '../../../shared/Constants';
import { useRequestForQuoteSlice } from '../../../../pages/requestForQuote/slice';
import { useDispatch, useSelector } from 'react-redux';
import { apiCallBegan } from 'store/reducers/loading';
import MODULES from '../../../../../data/modules';
import API from '../../../../../data/api';
import { setUpdateJobData } from 'store/reducers/entities';
import { getActionToggle } from 'store/reducers/entities/selectors';

const UseSupplierQuoteHandlerModal = props => {
  const { show, onHide, productItemDetailsRows } = props;

  const { jobId } = useParams();
  const dispatch = useDispatch();

  const { actions } = useRequestForQuoteSlice();
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));
  const productQuotationRow = productItemDetailsRows.filter(item => item.status === QUOTATION && item.details.clientQuotations == null && item.details?.printSpecifications?.form?.isActive === true);
  const filteredQuotedQuotes = productQuotationRow
    .map(item => {
      return {
        productName: `${item.title}`,
        quantityRunon: item?.details?.printSpecifications?.form?.quantityRunOn ?? null,
        productId: item.id,
        data: item.details.jobQuotes.filter(item => item.jobQuoteSummary.statusName === QUOTED),
      };
    })
    .filter(item => item.data.length > 0);
  var productAndCheckedQuote: any = [];
  filteredQuotedQuotes.forEach(item => {
    var checkedItem = item.data.map(item => item.jobQuoteSummary).filter(item => item.checked);
    var lowestTotalItem = item.data.map(item => item.jobQuoteSummary).reduce((acc, cur) => (acc?.totalRate > cur?.totalRate ? cur : acc));
    var q = checkedItem.length ? checkedItem : [lowestTotalItem];
    if (q.length > 0) {
      productAndCheckedQuote.push({
        productName: item.productName,
        quantityRunon: item.quantityRunon,
        productId: item.productId,
        data: q,
        supplierDetails: {
          supplierName: q[0].supplierName,
          jobQuoteId: q[0].jobQuoteId,
        },
      });
    }
  });
  const supplierInformation = (jobQuoteId, rowid, quantityRunon) => {
    const filteredSupplierFormData =
      productQuotationRow && productQuotationRow?.filter(row => row.id == rowid)[0].details.jobQuoteSummaryDetails.filter(item => item?.jobQuoteInfoSummaryDetails?.jobQuoteId == jobQuoteId);

    const supplierFormData = filteredSupplierFormData.length > 0 ? filteredSupplierFormData[0]?.jobQuoteInfoSummaryDetails : [];

    return (
      <div className="info_body">
        <div className="info_body_information">
          <table>
            <thead>
              <tr>
                <th style={{ textAlign: 'right' }}>Production(&#163;)</th>
                <th style={{ textAlign: 'right' }}>Material (&#163;)</th>
                <th style={{ textAlign: 'right' }}>Delivery (&#163;)</th>
                <th style={{ textAlign: 'right' }}>Total (&#163;)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ textAlign: 'right' }}>{supplierFormData?.productionRate == null ? '-' : supplierFormData?.productionRate?.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>{supplierFormData?.materialRate == null ? '-' : supplierFormData?.materialRate?.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>{supplierFormData?.deliveryRate == null ? '-' : supplierFormData?.deliveryRate?.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>{supplierFormData?.totalRate == null ? '-' : supplierFormData?.totalRate?.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {quantityRunon !== null && quantityRunon !== 0 && (
          <div className="info_body_runon_information">
            <div className="runon_title">Runon Information</div>
            <div>
              <table>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'right' }}>Production Runon (&#163;)</th>
                    <th style={{ textAlign: 'right' }}>Material Runon (&#163;)</th>
                    <th style={{ textAlign: 'right' }}>Delivery Runon (&#163;)</th>
                    <th style={{ textAlign: 'right' }}>Total Runon (&#163;)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'right' }}>{supplierFormData?.productionRunon == null ? '-' : supplierFormData?.productionRunon?.toFixed(2)}</td>
                    <td style={{ textAlign: 'right' }}>{supplierFormData?.materialRunon == null ? '-' : supplierFormData?.materialRunon?.toFixed(2)}</td>
                    <td style={{ textAlign: 'right' }}>{supplierFormData?.deliveryRunon == null ? '-' : supplierFormData?.deliveryRunon?.toFixed(2)}</td>
                    <td style={{ textAlign: 'right' }}>{supplierFormData?.totalRunon == null ? '-' : supplierFormData?.totalRunon?.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  };
  const saveSupplierQuote = async () => {
    var payload = {
      jobId: Number(jobId),
      supplierQuote: productAndCheckedQuote.map(item => {
        return {
          productTemplateId: item.productId,
          jobQuoteID: item.supplierDetails.jobQuoteId,
        };
      }),
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Quotes/SaveSupplierQuote/${jobId}`, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      jobId && dispatch(actions.loadRfqData({ jobId: jobId, toggleAction: toggleAction }));
      dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setUpdateJobData.type, module: MODULES.MY_JOBS }));
      onHide();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <React.Fragment>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="fullScreen__overPopup"
        centered
        keyboard={false}
        onHide={() => onHide()}
        scrollable
        show={show}
      >
        <Modal.Body>
          <div className="use_supplier_quote_handler">
            <div className="body__titleContainer">
              <label>Are you sure you want to use this rates?</label>
            </div>
            <div className="body_container">
              {productAndCheckedQuote.map((item, i) => (
                <div className="info_container mt-3" key={i}>
                  <div className="info_header">
                    <label>{item.productName}</label>
                    <label>{item.supplierDetails.supplierName}</label>
                  </div>
                  {productQuotationRow && supplierInformation(item.supplierDetails.jobQuoteId, item.productId, item.quantityRunon)}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="outline__button" onClick={() => onHide()} variant="outline-primary">
            No
          </Button>
          <Button className="full__button" onClick={() => saveSupplierQuote()} variant="primary">
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default UseSupplierQuoteHandlerModal;
