import React, { useState, useEffect } from 'react';

import { TableCell, TableContainer, Table, TableHead, TableBody, TableRow, Paper } from '@mui/material';
import { ReactComponent as AddProductItemIcon } from '../../../../../images/addProductItem.svg';
import { protectedResources } from 'configs/msalAuthConfigs';
import { useSelector, useDispatch } from 'react-redux';
import { grtProductData } from '../../slice/selector';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import { request } from 'utils/request';

export const ProductsList = props => {
  const { openProductsPopup } = props;
  const dispatch = useDispatch();
  const [productsList, setProductsList] = useState<any>();
  const { actions: accountAction } = useInvoiceSlice();
  const { actions: loadData } = useInvoiceSlice();
  const Products = useSelector(grtProductData);
  const { accountId } = useParams();

  useEffect(() => {
    setProductsList(Products);
  }, [Products]);

  const HandleDelete = async productId => {
    const formData = {
      accountId: Number(accountId),
      productIds: [productId],
      isRemove: true,
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/AD/AddORRemoveAccountProducts`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      accountId && dispatch(accountAction.loadProduct(+accountId));
      accountId && dispatch(loadData.loadProductList(+accountId));
    } catch (err) {}
  };

  return (
    <div>
      <div className="productsPage__header">
        <span>Products Available to this Account</span>
        <span className="header__button" onClick={() => openProductsPopup()}>
          Add Product
          <AddProductItemIcon className="svg__icon" />
        </span>
      </div>
      <div className="products__content">
        <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
          <Table aria-label="collapsible table" className="productItem__details__table">
            <TableHead>
              <TableRow>
                <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '1rem' }}>
                  Product Name
                </TableCell>
                <TableCell className="productItem__details__headerCell" align="right" style={{ paddingRight: '4rem' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsList &&
                productsList?.data?.items?.map(item => (
                  <TableRow key={item.productId}>
                    <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '3rem' }}>
                      {item.name}
                    </TableCell>
                    <TableCell className="table__details__headerCell" align="right">
                      <span onClick={() => HandleDelete(item.productId)} style={{ marginRight: '50px' }}>
                        Delete
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
