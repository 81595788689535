import { createSelector } from '@reduxjs/toolkit';
//  Internals
import { RootState } from '../../../../../store/model';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.adminSuppliers || initialState;

export const getSuppliers = createSelector([selectSlice], adminSuppliers => adminSuppliers.supplierList.data.items);
export const getGeoLocations = createSelector([selectSlice], adminSuppliers => adminSuppliers.geoLocations);
export const getSupplierCategories = createSelector([selectSlice], adminSuppliers => adminSuppliers.supplierCategories);
export const updateAddSupplier = createSelector([selectSlice], adminSuppliers => adminSuppliers.addSupplier);
export const editSupplier = createSelector([selectSlice], adminSuppliers => adminSuppliers.editSupplier);
