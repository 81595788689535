//  Externals
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//  Internals
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getEntities, getItems } from '../../../../store/reducers/entities/selectors';
import { apiCallBegan, apiCallEnd, apiCallFailed } from 'store/reducers/loading';
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { useBreadcrumbSlice } from '../../../pages/breadcrumb/slice';
import { setItems, setTotalCount } from 'store/reducers/entities';
import callApi from './../../../../services/callApi';
import Breadcrumb from '../../../pages/breadcrumb';
import Pagination from './../../shared/Pagination';
import MODULES from '../../../../data/modules';
import STATUS from '../../../../data/status';
import { Helmet } from 'react-helmet-async';
import { Dropdown } from 'react-bootstrap';
import { request } from 'utils/request';
import URL from '../../../../data/url';
import API from '../../../../data/api';
import { isEmpty } from 'lodash';
import moment from 'moment';

const UnallocatedRFQ = () => {
  const { actions: breadcrumbActions } = useBreadcrumbSlice();

  const unallocatedRFQs = useSelector(getItems(MODULES.UN_ALLOCATED_RFQ));
  const filteredUnallocatedRFQs = unallocatedRFQs.filter(item => ['New RFQ', 'Amended RFQ', 'On hold'].includes(item.jobStatusName));
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { page, limit } = useSelector(getEntities(MODULES.UN_ALLOCATED_RFQ));

  const [cancelStatusId, setCancelStatusId] = useState<number>();
  const [showActionDropDown, setShowActionDropDown] = useState({});

  /******************************************************************************************
	@Purpose    :   To get the orders list
	*******************************************************************************************/
  const getUnallocatedRFQs = useCallback(async () => {
    try {
      dispatch(apiCallBegan(''));
      const response: any = await callApi({ url: `${API.GET_ALL_UNALLOCATEDRFQS}?PageNumber=${page}&PageSize=${limit}`, method: 'get' });
      if (response.isSuccess) {
        const { data } = response;
        const { items, totalCount } = data;
        //	Store the Data in Redux
        dispatch(setItems({ module: MODULES.UN_ALLOCATED_RFQ, items }));
        dispatch(setTotalCount({ module: MODULES.UN_ALLOCATED_RFQ, totalCount }));
      }
      dispatch(apiCallEnd());
    } catch (error) {
      console.error('error In ====>>>> getUnallocatedRFQs <<<<====', error);
      dispatch(apiCallFailed());
    }
  }, [dispatch, page, limit]);

  useEffect(() => {
    getUnallocatedRFQs();
  }, [page, limit, getUnallocatedRFQs]);

  // get status
  const getStatusList = async () => {
    const response = await request(`${protectedResources.api.apiEndpoint}/MasterData/getstatuslist`, {
      method: 'GET',
    });

    const cancelEntity = response.data.items.find(status => status.code === STATUS.CANCELLED);

    if (cancelEntity) setCancelStatusId(cancelEntity.id);
  };

  const removeActionDowdown = () => {
    var x: any = '';
    if (!isEmpty(showActionDropDown)) {
      x = Object.keys(showActionDropDown);
      setShowActionDropDown({ ...showActionDropDown, [Number(x)]: false });
    }
  };

  useEffect(() => {
    getStatusList();
  }, []);

  useEffect(() => {
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Dashboard', url: '/dashboard' },
        { name: 'Unallocated RFQ', url: '/unallocatedRFQ' },
      ]),
    );
  }, [breadcrumbActions, dispatch]);

  useEffect(() => {
    document.addEventListener('click', removeActionDowdown);

    return () => {
      document.removeEventListener('click', removeActionDowdown);
    };
  });

  /******************************************************************************************
	@Purpose    :   Cancel Job
	*******************************************************************************************/
  const handleCancel = async id => {
    const data = {
      rfqJobId: id,
      status: cancelStatusId,
    };
    try {
      const response: any = await callApi({ url: API.UPDATE_JOB_STATUS, method: 'post', body: data });
      if (response.isSuccess) getUnallocatedRFQs();
    } catch (error) {
      console.error('error In ====>>>> handleCancel <<<<====', error);
    }
  };

  /******************************************************************************************
	@Purpose    :   Navigate to the Job Details
	*******************************************************************************************/
  const handleSelectJob = (job, event) => {
    if (['Action', 'Cancel'].includes(event?.target?.innerText)) {
      event.stopPropagation();
    } else {
      navigate(`${URL.MY_JOBS}/${job.id}`);
    }
  };

  return (
    <div className="myJobs__container">
      <Helmet>
        <title>Unallocated RFQ</title>
      </Helmet>
      <Breadcrumb />
      <div style={{ border: '1px solid #CCCCCC', margin: '10px' }}>
        {filteredUnallocatedRFQs && (
          <>
            <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none', minHeight: 'calc(100vh - 120px)', background: 'transparent' }}>
              <Table aria-label="collapsible table" className="productIte__table">
                <TableHead>
                  <TableRow style={{ backgroundColor: '#F2F5F5' }}>
                    <TableCell style={{ paddingLeft: '1rem' }}>Job Number</TableCell>
                    <TableCell style={{ padding: '0px' }} sx={{ paddingLeft: 0 }} align="left">
                      Title
                    </TableCell>
                    <TableCell style={{ padding: '0px' }}>Account</TableCell>
                    <TableCell style={{ padding: '0px' }}>Business unit</TableCell>
                    <TableCell style={{ padding: '0px' }}>Submitted</TableCell>
                    <TableCell style={{ padding: '0px' }}>Status</TableCell>
                    <TableCell style={{ padding: '0px' }} align="center">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUnallocatedRFQs &&
                    filteredUnallocatedRFQs?.map(item => {
                      return (
                        <TableRow onClick={event => handleSelectJob(item, event)} style={{ backgroundColor: '#F2F5F5', cursor: 'pointer' }} key={item.id}>
                          <TableCell style={{ paddingLeft: '1rem' }}>{item.jobNumber}</TableCell>
                          <TableCell style={{ padding: '0px' }} sx={{ paddingLeft: 0 }} align="left">
                            {item.title}
                          </TableCell>
                          <TableCell style={{ padding: '0px' }}>{item.accountName}</TableCell>
                          <TableCell style={{ padding: '0px' }}>{item.businessUnit}</TableCell>
                          <TableCell style={{ padding: '0px' }}>{item?.createdDateTime ? moment(item?.createdDateTime).format('DD/MM/YYYY HH:mm') : '-'}</TableCell>
                          <TableCell style={{ padding: '0px' }}>{item.jobStatusName}</TableCell>
                          <TableCell style={{ padding: '0px' }} align="center">
                            <Dropdown show={showActionDropDown[item.id]}>
                              <Dropdown.Toggle
                                onClick={() => item?.jobStatusName === 'New RFQ' && setShowActionDropDown({ [item.id]: true })}
                                aria-haspopup="true"
                                disabled={!['New RFQ'].includes(item?.jobStatusName)}
                                id="dropdown-actions"
                                style={{
                                  backgroundColor: '#F2F5F5',
                                  height: '30px',
                                  zIndex: '5',
                                  border: 'none',
                                  color: 'gray',
                                }}
                              >
                                Action
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {item?.jobStatusName === 'New RFQ' && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      handleCancel(item.id);
                                      removeActionDowdown();
                                    }}
                                  >
                                    Cancel
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
      <Pagination module={MODULES.UN_ALLOCATED_RFQ} name="Unallocated RFQs" />
    </div>
  );
};

export default UnallocatedRFQ;
