import * as actions from '../reducers/loading';
import callApi from './../../services/callApi';

const api =
  ({ dispatch }) =>
  next =>
  async action => {
    if (!action.payload) return next(action);

    if (action.type !== actions.apiCallBegan.type) return next(action);

    const { onSuccess, module } = action.payload;

    next(action);
    try {
      const response: any = await callApi({ ...action.payload });
      if (response.isSuccess) {
        //  General
        dispatch(actions.apiCallSuccess());
        //  Specific
        if (module) response.data.module = module;
        if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
      } else dispatch(actions.apiCallFailed());
    } catch (error) {
      console.error('error In ====>>>> api <<<<====', error);
      dispatch(actions.apiCallFailed());
    }
  };

export default api;
