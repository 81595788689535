import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../store/model';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.dashboard || initialState;

export const selectDashboardCards = createSelector([selectSlice], dashboardState => dashboardState.dashboardCards.data);
export const isLoading = createSelector([selectSlice], dashboardState => dashboardState.isLoading);
export const getSelectFilterList = createSelector([selectSlice], dashboardState => dashboardState.selectFilterList);
