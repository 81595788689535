import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AddProductsPopup } from './AddProductPopup';
import { ProductsList } from './ProductsList';
import { useSelector, useDispatch } from 'react-redux';
import { grtProductData, grtProductList } from '../../slice/selector';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const Products = () => {
  const dispatch = useDispatch();
  const ProductsAccount = useSelector(grtProductList);
  const Products = useSelector(grtProductData);
  const { actions: accountAction } = useInvoiceSlice();
  const { actions: loadData } = useInvoiceSlice();
  const { accountId } = useParams();
  const [productsPopup, setProductsPopup] = useState(false);
  const [productsTableData, setProductsTableData] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    setProductsTableData(Products);
  }, [ProductsAccount, Products]);

  useEffect(() => {
    accountId && dispatch(accountAction.loadProduct(+accountId));
    accountId && dispatch(loadData.loadProductList(+accountId));
  }, []);
  return (
    <div className="products__page">
      <AddProductsPopup show={productsPopup} onHide={() => setProductsPopup(false)} accountList={ProductsAccount} productsTableData={productsTableData} />
      {productsTableData && productsTableData?.data?.items?.length === 0 ? (
        <div className="extra__container">
          <div className="addProduct__container">
            <Button className="full__button" variant="primary" style={{ paddingRight: '2rem', paddingLeft: '2rem' }} onClick={() => setProductsPopup(true)}>
              Add Product
            </Button>
            <span style={{ marginTop: '0.5rem' }}>You have not added a Product yet</span>
            <span>
              Click on <strong>'Add Product'</strong>
            </span>
          </div>
        </div>
      ) : (
        <>
          <ProductsList openProductsPopup={() => setProductsPopup(true)} />
        </>
      )}
      <footer>
        <Button className="full__button" variant="primary" style={{ paddingRight: '2rem', paddingLeft: '2rem' }} onClick={() => navigate(`/administration/account`)}>
          Done
        </Button>
      </footer>
    </div>
  );
};
