import { PayloadAction } from '@reduxjs/toolkit';
import { search } from 'app/pages/closedPurchaseOrder/slice/types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { headerSaga } from './saga';
import { HeaderState, Search, SearchSupplier } from './types';

export const initialState: HeaderState = {
  title: '',
  isNotification: false,
  isNotes: false,
  isLoading: false,
  error: null,
  search: {
    data: {
      pageNumber: 0,
      pageSize: 0,
      totalCount: 0,
      items: [
        {
          jobId: null,
          jobNumber: '',
          title: '',
        },
      ],
    },
  },
  searchSupplier: {
    data: {
      pageNumber: 0,
      pageSize: 0,
      totalCount: 0,
      items: [
        {
          jobId: null,
          jobLineId: '',
          title: '',
        },
      ],
    },
  },
};

const slice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    changeHeaderTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    setIsNotifications(state, action: PayloadAction<boolean>) {
      state.isNotification = action.payload;
    },
    setIsNotes(state, action: PayloadAction<boolean>) {
      state.isNotes = action.payload;
    },
    loadSearch(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    searchLoaded(state, action: PayloadAction<Search>) {
      state.search = action.payload;
      state.isLoading = false;
    },
    searchError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
    loadSearchSupplier(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    searchLoadedSupplier(state, action: PayloadAction<SearchSupplier>) {
      state.searchSupplier = action.payload;
      state.isLoading = false;
    },
    searchErrorSupplier(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
  },
});

export const { actions: headerActions, reducer } = slice;

export const useHeaderSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: headerSaga });
  return { actions: slice.actions };
};
