import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectPlaceSupplierOrderDetails } from '../slice/selectors';
import ProductItemDetails from './ProductItemDetails';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './style.sass';
import Collapse from '@mui/material/Collapse';

const PlaceSupplierOrder = props => {
  const placeSupplierOrder = useSelector(selectPlaceSupplierOrderDetails);
  const [open, setOpen] = useState(true);

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="fullScreen"
      centered
      onHide={() => {
        props.onHide();
      }}
      dialogClassName="modal__supplierOrder"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Place Supplier Order</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal__supplierOrderBody">
        <div className="modal__supplierOrderTitleRow">
          <div>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(prevState => !prevState)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
            <span style={{ verticalAlign: 'middle' }}>Order</span>
          </div>
          <span>{placeSupplierOrder.details.poNumber}</span>
          <span style={{ marginRight: '6rem' }}>&#163;{placeSupplierOrder.details.totalValue?.toFixed(2)}</span>
        </div>

        <div className="bodyRow">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <div className="order__details">
                <div>
                  <h6>SUPPLIER</h6>
                  <h6>{placeSupplierOrder.details.supplier}</h6>
                </div>
                <div>
                  <h6>PO NUMBER</h6>
                  <h6>{placeSupplierOrder.details.poNumber}</h6>
                </div>
                <div>
                  <h6>TOTAL VALUE</h6>
                  <h6>&#163;{placeSupplierOrder.details.totalValue?.toFixed(2)}</h6>
                </div>
              </div>
              <div className="partitionLine"></div>
              {placeSupplierOrder.details.productItems.map(productItem => (
                <ProductItemDetails key={productItem.orderDetails.title} productItem={productItem} />
              ))}
            </div>
          </Collapse>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            props.onHide();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            props.onHide();
          }}
        >
          Place Supplier Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlaceSupplierOrder;
