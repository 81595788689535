//  Externals
import { Pagination } from '@mui/material';
import React, { useState } from 'react';

import { ReactComponent as AddProductItemIcon } from '../../../images/addProductItem.svg';
import { TableCell, TableRow, TableHead, TableBody } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet-async';
import Table from '@mui/material/Table';
import usePagination from '../profile/Pagination';

export const AllAccounts = props => {
  const { accounts } = props;
  const navigate = useNavigate();

  const handleAddAccount = () => {
    navigate(`/administration/account/addAccount`);
  };

  const gotoAccountId = row => {
    navigate(`/administration/account/${row}`);
  };

  const [page, setPage] = useState(1);
  const dataPerPage = 20;
  const count = Math.ceil(accounts.length / dataPerPage);
  const _PerPageData_ = usePagination(accounts, dataPerPage);

  const handlePageChange = (e, p) => {
    setPage(p);
    _PerPageData_.jump(p);
  };

  return (
    <div>
      <Helmet>
        <title>Administration Account</title>
      </Helmet>
      <TableContainer style={{ backgroundColor: '#F2F5F5' }} component={Paper} className="tabular" sx={{ borderRadius: '0', boxShadow: 'none' }}>
        <div className="productsPage__header">
          <span>Accounts</span>
          <span className={`addAccount__button`} onClick={handleAddAccount}>
            Add Account
            <AddProductItemIcon className="svg__icon" />
          </span>
        </div>
        <Table style={{ backgroundColor: 'white' }} aria-label="collapsible table" className="item__details__table tabular">
          <TableHead style={{ height: '50px' }}>
            <TableRow>
              <TableCell className="table__details__headerCell" style={{ paddingLeft: '25px' }} sx={{ paddingLeft: 0 }} align="left">
                Account Name
              </TableCell>
              <TableCell className="table__details__headerCell">CDS Business Unit</TableCell>
              <TableCell className="table__details__headerCell">Code Prefix</TableCell>
              <TableCell className="table__details__headerCell">Export Type</TableCell>
              <TableCell className="table__details__headerCell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_PerPageData_.currentData().length > 0 &&
              _PerPageData_.currentData().map(item => (
                <TableRow key={item.accountId} onClick={() => gotoAccountId(item.accountId)} style={{ cursor: 'pointer' }}>
                  <TableCell className="table__details__headerCell" style={{ paddingLeft: '25px' }} sx={{ paddingLeft: 0 }} align="left">
                    {item.name}
                  </TableCell>
                  <TableCell className="table__details__headerCell" sx={{ paddingLeft: 0 }}>
                    {item.cdsBusinessUnitName}
                  </TableCell>
                  <TableCell className="table__details__headerCell" sx={{ paddingLeft: 0 }}>
                    {item.codePrefix}
                  </TableCell>
                  <TableCell className="table__details__headerCell" sx={{ paddingLeft: 0 }}>
                    {item.exportType}
                  </TableCell>
                  <TableCell className="table__details__headerCell table__edit" sx={{ paddingLeft: 0 }}>
                    <span style={{ marginRight: '20px', color: '#1BA39C' }}>Edit</span>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="d-flex justify-content-center ">
        <div className="pagination">
          <Pagination count={count} size="large" page={page} onChange={handlePageChange} />
        </div>
      </div>
    </div>
  );
};
