import VALIDATIONS from '../../data/validations';

//  Check option other is selected or not
export const checkIsOtherSelected = (id: number, items: { value: number; label: string }[]): boolean => (id ? items.find(item => item.value === id)?.label === VALIDATIONS.OTHER : false);

//  Check option Complex Job is selected or not
export const checkIsComplexJobSelected = (options: string[], items: { value: number; label: string }[]): boolean => {
  const selectedOptions: number[] = options.map(e => +e);
  const complexJob = items.find(item => item.label.trim() === 'Complex job');
  return complexJob && selectedOptions.includes(complexJob.value) ? true : false;
};

//  Get the text area hight for showing all the content
export const getRowsOfTextArea = (length: number, linesCount: number): number => {
  const defaultRows = 3;
  const rows = Math.round(length / 75);
  const finalRows = rows < linesCount ? linesCount : rows;
  return finalRows > defaultRows ? finalRows : defaultRows;
};

//  Allow only numbers
export const allowNumbers = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

//  Get the Unique form id
export const uniqueId = ((): ((prefix: string) => string) => {
  let counter = 0;
  return (prefix: string): string => `${prefix}${++counter}`;
})();
