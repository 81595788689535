import React from 'react';

import './LoadingSpinner.css';

type AppProps = {
  color?: string;
};

const LoadingSpinner = (props: AppProps) => {
  return (
    <div className="loading-spinner__overlay">
      <div
        style={
          {
            '--spinner-color': props.color ? props.color : '#000',
          } as React.CSSProperties
        }
        className="lds-dual-ring"
      ></div>
    </div>
  );
};

export default LoadingSpinner;
