import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { search, closedPurchase, poInvoiceDetails } from './types';
import { useClosedPurchaseSaga } from './saga';

export const initialState: closedPurchase = {
  isLoading: false,
  error: null,
  search: {
    isSuccess: false,
    validationErrors: null,
    message: '',
    statusCode: 0,
    data: {
      items: [
        {
          name: '',
          id: 0,
          isAccount: false,
        },
      ],
    },
  },
  account: {
    isSuccess: false,
    message: '',
    statusCode: 0,
    validationErrors: [],
    data: {
      totalOutstandingProducts: 0,
      totalOutstandinvPoValue: 0,
      accountName: '',
      items: [
        {
          poNumber: '',
          jobQuoteId: 0,
          title: '',
          noOfOutstandingItems: 0,
          outstandingPoValue: 0,
        },
      ],
    },
  },
  poInvoiceDetails: {
    isSuccess: false,
    message: '',
    statusCode: 0,
    validationErrors: [],
    data: {
      supplierPo: '',
      supplierName: '',
      supplierAddress: '',
      supplierPostCode: '',
      supplierWebsite: '',
      jobReference: '',
      jobTitle: '',
      totalPoValue: 0,
      jobNumber: 0,
      totalInvoicedValue: 0,
      outstandingValue: 0,
      closedProductDetails: [],
      invoiceDetails: [],
    },
  },
};
const slice = createSlice({
  name: 'closedPurchase',
  initialState,
  reducers: {
    loadSearch(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    searchLoaded(state, action: PayloadAction<search>) {
      state.search = action.payload;
      state.isLoading = false;
    },
    searchError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
    loadSelectedAccount(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    accountDetails(state, action: PayloadAction<any>) {
      state.account = action.payload;
      state.isLoading = false;
    },
    accountError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
    loadPoInvoiceDetails(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    poInvoiceDetails(state, action: PayloadAction<any>) {
      state.poInvoiceDetails = action.payload;
      state.isLoading = false;
    },
    poInvoiceDetailsError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
  },
});

export const { actions: closedPurchaseActions, reducer } = slice;

export const useClosedPurchase = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: useClosedPurchaseSaga });
  return { actions: slice.actions };
};
