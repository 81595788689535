//  Externals
import { AUDIT, JOB_INFORMATION, OTHER_COSTS, PRODUCT_ITEMS, TASKS } from '../../../data/constants';
import { Account, BusinessUnits, CdsClientExecutives, Contacts, initialJobInformation, initialProductItems, JobInformation, JobOptions, ProductItemType } from '../../../types/myJobs';

type Sort = {
  order: 'asc' | 'desc' | '';
  path: string;
};

type DropDowns = {
  accounts: Account[];
  businessUnits: BusinessUnits[];
  cdsClientExecutives: CdsClientExecutives[];
  contacts: Contacts[];
  jobOptions: JobOptions[];
};

type Navigation = { selected: boolean; visible: boolean };

type JobNavigation = {
  [JOB_INFORMATION]: Navigation;
  [OTHER_COSTS]: Navigation;
  [PRODUCT_ITEMS]: Navigation;
  [TASKS]: Navigation;
  [AUDIT]: Navigation;
};

export interface Entity {
  page: number;
  limit: number;
  sort?: Sort;
  totalCount: number;
  items: any[];
  dropdowns?: DropDowns;
  jobInformation?: JobInformation;
  productItems?: ProductItemType[];
  navigation?: JobNavigation;
  actionsToggle?: boolean;
}

export interface Entities {
  myJobs?: Entity;
  [x: string]: any;
}

export const page = 1;
export const limit = 20;
export const sort: Sort = { path: '', order: '' };

const initialEntity: Entity = {
  page,
  limit,
  totalCount: 0,
  items: [],
};

const initialDropdowns: DropDowns = {
  accounts: [],
  businessUnits: [],
  cdsClientExecutives: [],
  contacts: [],
  jobOptions: [],
};

const initialJobNavigation: JobNavigation = {
  jobInformation: { visible: true, selected: true },
  productItems: { visible: true, selected: false },
  otherCosts: { visible: true, selected: false },
  tasks: { visible: true, selected: false },
  audit: { visible: true, selected: false },
};

const actionsToggle = false;
export const initialState: Entities = {
  myJobs: { ...initialEntity, navigation: initialJobNavigation, dropdowns: initialDropdowns, jobInformation: initialJobInformation, productItems: initialProductItems, actionsToggle: actionsToggle },
  unallocatedRFQ: { ...initialEntity },
  isAddPoAction: false,
};
