import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TableCell, TableContainer, Table, TableHead, TableBody, TableRow, Paper } from '@mui/material';
import { ReactComponent as AddProductItemIcon } from '../../../../../images/addProductItem.svg';
import { protectedResources } from '../../../../../configs/msalAuthConfigs';
import { useSelector, useDispatch } from 'react-redux';
import { getJobRateList } from '../../slice/selector';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import { EditJobRate } from './EditJobRate';
import { request } from 'utils/request';

export const JobRateList = props => {
  const { openJobRatePopup } = props;
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const jobRateList = useSelector(getJobRateList);
  const { actions: accountAction } = useInvoiceSlice();
  const [editJobRatePopup, setEditJobRatePopup] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [editJobRateId, setEditJobRateId] = useState<number>();
  const [accountList, setAccountList] = useState<any>();

  const handleEdit = (Id, otherJobCostId) => {
    setEditJobRateId(Id);
    setEditJobRatePopup(true);
  };

  const handleDelete = async () => {
    const id = deleteId;
    try {
      await request(`${protectedResources.api.apiEndpoint}/Admin/DeleteJobRate/${id}`, {
        method: 'DELETE',
      });
    } catch (responseError) {}
    setDeleteShow(false);
    accountId && dispatch(accountAction.loadJobRateList(+accountId));
  };

  useEffect(() => {
    setAccountList(jobRateList);
  }, [jobRateList]);

  useEffect(() => {
    accountId && dispatch(accountAction.loadJobRateList(+accountId));
  }, [accountAction, accountId, dispatch]);

  return (
    <div>
      <EditJobRate show={editJobRatePopup} onHide={() => setEditJobRatePopup(false)} JobRateId={editJobRateId} />
      <div className="productsPage__header">
        <span>Job Rates</span>
        <span className="header__button" onClick={() => openJobRatePopup()}>
          Add Job Rate
          <AddProductItemIcon className="svg__icon" />
        </span>
      </div>
      <div className="products__content">
        <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
          <Table aria-label="collapsible table" className="productItem__details__table">
            <TableHead>
              <TableRow>
                <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '1rem' }}>
                  Name
                </TableCell>
                <TableCell className="productItem__details__headerCell" sx={{ paddingLeft: 0 }} align="left">
                  Service
                </TableCell>
                <TableCell className="productItem__details__headerCell" sx={{ paddingLeft: 0 }} align="left">
                  Ledger ID
                </TableCell>
                <TableCell className="productItem__details__headerCell">Rate Based</TableCell>
                <TableCell className="productItem__details__headerCell text-end">Sell rate (£)</TableCell>
                <TableCell className="productItem__details__headerCell" align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountList?.data?.length > 0 &&
                accountList?.data?.map(item => {
                  return (
                    <>
                      <TableRow>
                        <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '1rem' }}>
                          {item?.accountJobRateName}
                        </TableCell>
                        <TableCell className="productItem__details__headerCell" sx={{ paddingLeft: 0 }} align="left">
                          {item?.name}
                        </TableCell>
                        <TableCell className="productItem__details__headerCell" sx={{ paddingLeft: 0 }} align="left">
                          {item.ledgerID ? item.ledgerID : '-'}
                        </TableCell>
                        <TableCell className="productItem__details__headerCell" sx={{ paddingLeft: 0 }}>
                          {item?.isRateBased ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell className="productItem__details__headerCell text-end" sx={{ paddingLeft: 0 }}>
                          {item?.sellRate?.toFixed(2)}
                        </TableCell>
                        <TableCell className="productItem__details__headerCell" align="center">
                          <span onClick={() => handleEdit(item.id, item.otherJobCostId)} style={{ marginRight: '20px', cursor: 'pointer' }}>
                            Edit
                          </span>
                          <span
                            style={{ ...(item.otherJobCostId != null && { color: '#ddd', cursor: 'pointer' }) }}
                            onClick={() => {
                              if (item.otherJobCostId !== null) return;
                              setDeleteId(item.id);
                              setDeleteShow(true);
                            }}
                          >
                            Delete
                          </span>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal backdrop="static" keyboard={false} show={deleteShow} aria-labelledby="contained-modal-title-vcenter" backdropClassName="fullScreen" centered onHide={() => setDeleteShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span>Are you sure you want to delete this job rate?</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="modal_footer">
          <>
            <Button variant="outline-primary" onClick={() => setDeleteShow(false)} className="outline__button">
              No
            </Button>
            <Button variant="outline-primary" onClick={() => handleDelete()} className="full__button">
              Yes
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
