//  Externals
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton/IconButton';
import { useDispatch } from 'react-redux';
import { TableCell } from '@mui/material';
import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import moment from 'moment';
//  Internals
import RejectDiscountPopup from '../myJobs/productItems/actions/RejectDiscountPopup';
import { setUpdateJobData } from '../../../../store/reducers/entities';
import { protectedResources } from 'configs/msalAuthConfigs';
import { apiCallBegan } from 'store/reducers/loading';
import MODULES from '../../../../data/modules';
import STATUS from '../../../../data/status';
import { request } from 'utils/request';
import API from '../../../../data/api';

const DiscountDetails = props => {
  const { initialState } = props;

  const dispatch = useDispatch();

  const [showRejectReasonPopup, setShowRejectReasonPopup] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = async ({ accepted, rejectionReason }) => {
    const body = {
      accepted,
      rejectionReason,
      templateId: initialState.templateId,
    };
    try {
      const response: any = await request(`${protectedResources.api.apiEndpoint}/Products/ApproveRejectDiscount`, {
        method: 'POST',
        body: JSON.stringify(body),
      });

      if (response.isSuccess) {
        dispatch(apiCallBegan({ url: `${API.GET_JOB}/${initialState.jobId}`, method: 'GET', onSuccess: setUpdateJobData.type, module: MODULES.MY_JOBS }));
      }
    } catch (err) {}
  };
  return (
    <>
      <RejectDiscountPopup onHide={() => setShowRejectReasonPopup(false)} onSubmit={handleSubmit} show={showRejectReasonPopup} />
      <div className="d-flex justify-content-between"></div>
      <div style={{ gridColumn: '1 / 3', border: '1px solid #dee2e6', marginTop: '25px' }}>
        <table onClick={() => setOpen(prevState => !prevState)}>
          <TableCell style={{ padding: '8px' }}>
            <h6>Discounted details</h6>
          </TableCell>
          <TableCell style={{ padding: '8px' }} align="right">
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
        </table>
        {open && (
          <div style={{ padding: '10px' }}>
            <div className="container">
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>Added By</strong>
                </div>
                <div className="col">{initialState?.discountDetails?.requestedBy}</div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>Date</strong>
                </div>
                <div className="col">{initialState?.discountDetails?.requestedDateTime ? moment(initialState?.discountDetails?.requestedDateTime).format('DD/MM/YYYY') : '-'}</div>
              </div>
              <div className="row" style={{ marginTop: '20px' }}>
                <div className="col">
                  <strong>Discounted Offered To Client</strong>
                </div>

                <div className="col">
                  {initialState?.discountDetails?.clientDiscount != null ? (
                    <span>
                      &#163;
                      {initialState?.discountDetails?.clientDiscount?.toFixed(2)}
                    </span>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>Discounted Offered From Supplier</strong>
                </div>

                <div className="col">
                  {initialState?.discountDetails?.supplierDiscount != null ? (
                    <span>
                      &#163;
                      {initialState?.discountDetails?.supplierDiscount?.toFixed(2)}
                    </span>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <hr />
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>Discount % offered to client</strong>
                </div>
                <div className="col">{initialState?.discountDetails?.clientDiscountPercentage != null ? <span>{initialState?.discountDetails?.clientDiscountPercentage}</span> : '-'}</div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>Discount % from supplier</strong>
                </div>
                <div className="col">{initialState?.discountDetails?.supplierDiscountPercentage != null ? <span>{initialState?.discountDetails?.supplierDiscountPercentage}</span> : '-'}</div>
              </div>
              <hr />
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>New Line Value</strong>
                </div>
                <div className="col">£{initialState?.discountDetails?.newLineValue?.toFixed(2)}</div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>New Supplier Order Value</strong>
                </div>
                <div className="col">£{initialState?.discountDetails?.supplierLineValue?.toFixed(2)}</div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>Reasons for Discounts</strong>
                </div>
                <div className="col">{initialState?.discountDetails?.discountReasonOther}</div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>status</strong>
                </div>
                <div
                  className="col"
                  style={{
                    color: `${
                      (initialState?.discountDetails?.status === STATUS.APPROVED && '#1BA39C') ||
                      (initialState?.discountDetails?.status === 'Rejected' && 'red') ||
                      (initialState?.discountDetails?.status === 'Pending' && '#ffc107')
                    }`,
                  }}
                >
                  {initialState?.discountDetails?.status}
                </div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>Reasons For Rejection</strong>
                </div>
                <div className="col">{initialState?.discountDetails?.rejectReason}</div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>Review Date</strong>
                </div>
                <div className="col">{initialState?.discountDetails?.reviewDateTime ? moment(initialState?.discountDetails?.reviewDateTime).format('DD/MM/YYYY') : ''}</div>
              </div>
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                  <strong>Review by</strong>
                </div>
                <div className="col">{initialState?.discountDetails?.reviewedBy}</div>
              </div>
            </div>
            <br />
            {initialState?.canApprove && initialState?.discountDetails?.status === 'Pending' ? (
              <>
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => setShowRejectReasonPopup(true)}
                    type="button"
                    className="btn btn-outline-danger"
                    style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem', borderRadius: '6px' }}
                    variant="outline-primary"
                  >
                    Reject
                  </Button>
                  <Button onClick={() => handleSubmit({ accepted: true, rejectionReason: 'Approve' })} type="button" variant="primary" className="full__button" style={{ marginLeft: '12px' }}>
                    Accept
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DiscountDetails;
