import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/model';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.supplierView || initialState;

export const selectVatRate = createSelector([selectSlice], supplierView => supplierView.vatRate);

export const selectMaterial = createSelector([selectSlice], supplierView => supplierView.material);

export const supplierList = createSelector([selectSlice], supplierView => supplierView.supplierListRow);

export const selectPrintSpecifications = createSelector([selectSlice], supplierView => supplierView.printSpecificationsForm);

export const selectProductItemDetailsRowsOpenArray = createSelector([selectSlice], supplierView => supplierView.productItemsState.productItemDetails.rows.map(item => item.open));

export const selectProductItemDetailsRowsCheckboxesArray = createSelector([selectSlice], supplierView => supplierView.productItemsState.productItemDetails.rows.map(item => item.checked));

export const selectProductItemDetailsRows = createSelector([selectSlice], supplierView => supplierView.productItemsState.productItemDetails.rows);

export const getProductInformationData = createSelector([selectSlice], supplierView => supplierView.productInformation.values);

export const isLoading = createSelector([selectSlice], supplierView => supplierView.isSupplierListLoading);
