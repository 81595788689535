import React, { useState } from 'react';
import './style.sass';
import LoadingSpinnerWithBackdrop from 'app/components/shared/LoadingSpinnerWithBackdrop';
import { Modal, Button } from 'react-bootstrap';
import { useRequestForQuoteSlice } from '../slice';
import { selectPlaceSupplierOrder } from '../slice/selectors';
import MomentUtils from '@date-io/moment';
import { request } from 'utils/request';
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import MODULES from '../../../../data/modules';
import API from '../../../../data/api';
import { useDispatch, useSelector } from 'react-redux';
import { apiCallBegan } from 'store/reducers/loading';
import { setUpdateJobData } from 'store/reducers/entities';
import ProductItemDetails from './ProductItemDetails';
import { getActionToggle } from 'store/reducers/entities/selectors';

const PlaceSupplierOrderPopUp = props => {
  const { show, onPlaceOrder, jobId, onCancel, placeSupplierOrderSelectedOrderId } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));

  const moment = new MomentUtils();
  const dispatch = useDispatch();
  const { actions } = useRequestForQuoteSlice();
  const data = useSelector(selectPlaceSupplierOrder);

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      await request(`${protectedResources.api.apiEndpoint}/Quotes/CreatesupplierOrder/${jobId}`, {
        method: 'POST',
        body: JSON.stringify({
          jobId: jobId && parseInt(jobId),
          templateIds: placeSupplierOrderSelectedOrderId.length > 0 ? placeSupplierOrderSelectedOrderId : [],
        }),
      })
        .then(res => {
          setLoading(false);
          props.onPlaceOrder();
        })
        .catch(error => {
          setLoading(false);
          console.error('err', error.response);
        });
      jobId && dispatch(actions.loadRfqData({ jobId: jobId, toggleAction: toggleAction }));
      jobId && dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setUpdateJobData.type, module: MODULES.MY_JOBS }));
    } catch (responseError) {
      console.error('Error while submitting the form ' + responseError);
    }
  };
  return (
    <>
      {loading && <LoadingSpinnerWithBackdrop color="grey" message="Processing order - please wait" />}
      <Modal
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="fullScreen"
        centered
        dialogClassName="modal__maxFullScreen"
        scrollable={true}
        show={show}
        onHide={() => onCancel()}
        onSubmit={handleOnSubmit}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Place Supplier Order</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal__body">
          {data && data?.supplierDetails && (
            <div className="container">
              <div className="d-flex justify-content-between table_padding">
                <div>
                  <p>{data.supplierDetails.supplierName != null ? data.supplierDetails.supplierName : '-'}</p>
                  <p className="p_wrap">{data.supplierDetails.supplierAddress != null ? data.supplierDetails.supplierAddress : ''}</p>
                  <p>{data.supplierDetails.supplierPinCode != null ? data.supplierDetails.supplierPinCode : ''}</p>
                </div>
                <div>
                  <p className="h6_style">JOB NUMBER</p>
                  <p>{data.supplierDetails.jobNumber != null ? data.supplierDetails.jobNumber : '-'}</p>
                  <p> Please use this in all correspondence</p>
                </div>
              </div>
              <hr />
              <div>
                <div>
                  <div className="row table_padding">
                    <div className="col">
                      <div className="col_style">
                        <h6 className="h6_style">Job Title</h6>
                        <p>{data.supplierDetails.jobTitle != null ? data.supplierDetails.jobTitle : '-'}</p>
                      </div>
                      <div className="col_style">
                        <h6 className="h6_style">Buyer's Name</h6>
                        <p>{data.supplierDetails.buyerName != null ? data.supplierDetails.buyerName : '-'}</p>
                      </div>
                      <div className="col_style">
                        <h6 className="h6_style">Email</h6>
                        <p>{data.supplierDetails.email != null ? data.supplierDetails.email : '-'}</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="col_style">
                        <h6 className="h6_style">PO Number</h6>
                        <p>{data.supplierDetails.poNumber != null ? data.supplierDetails.poNumber : '-'}</p>
                      </div>
                      <div className="col_style">
                        <h6 className="h6_style">Total Value</h6>
                        <p>£ {data.supplierDetails.totalValue != null ? data.supplierDetails.totalValue?.toFixed(2) : '-'}</p>
                      </div>
                      <div className="col_style">
                        <h6 className="h6_style">Number Of Lines</h6>
                        <p>{data.supplierDetails.numberOfLines}</p>
                      </div>
                      <div className="col_style">
                        <h6 className="h6_style">Order Date</h6>
                        <p>{data.supplierDetails.orderDate != null ? `${moment?.date(data?.supplierDetails?.orderDatetworkDueDate)?.format('DD/MM/YYYY')}` : ''}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {data &&
                  data.jobLineItems &&
                  data?.jobLineItems?.map(item => (
                    <div key={item.title}>
                      <div className="container leaflet">
                        <h5>
                          {item.productType} : {item.title}
                        </h5>
                        <div>
                          <div className="leaflet-inner">
                            <div className="border-bottom">
                              <div>
                                <h6 className="h6_style">Line ID</h6>
                                <p>{item.lineId != null ? item.lineId : '-'}</p>
                              </div>
                              <div className="pt-3">
                                <h6 className="h6_style">Your Reference</h6>
                                <p>{item?.reference != null ? item?.reference : '-'}</p>
                              </div>

                              <div className="d-flex">
                                <div className="fare_details">
                                  <h6 className="h6_style">Production</h6>
                                  <p>£{Number(item.production).toFixed(2)}</p>
                                </div>
                                <div className="fare_details">
                                  <h6 className="h6_style">Material</h6>
                                  <p>£{Number(item.materialRate).toFixed(2)}</p>
                                </div>
                                <div className="fare_details">
                                  <h6 className="h6_style">Delivery</h6>
                                  <p>£{Number(item.delivery).toFixed(2)}</p>
                                </div>
                                <div className="fare_details">
                                  <h6 className="h6_style">Total</h6>
                                  <p>£{Number(Number(item.production) + Number(item.materialRate) + Number(item.delivery)).toFixed(2)}</p>
                                </div>
                              </div>
                              <div className="fare_details">
                                <h6 className="h6_style">Material</h6>
                                <p className="p_wrap">{item.material != null && item.material != '' ? item.material : '-'}</p>
                              </div>
                            </div>
                            <ProductItemDetails item={item} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => onCancel()} className="outline__button">
            Cancel
          </Button>
          <Button variant="outline-primary" onClick={() => handleOnSubmit()} className="full__button">
            Place Supplier Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PlaceSupplierOrderPopUp;
