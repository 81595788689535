import { Actions } from 'node-plop';
//  Externals
import { call, put, takeLatest } from 'redux-saga/effects';
//  Internals
import { clientQuoteConfirmation, productItemRow, supplierOrder, ProductInformationType, rfqDataForm, productTemplate, statusDetails, addTasks, OtherCostRateItems } from './types';
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { requestForQuoteActions as actions } from '.';
import { request } from 'utils/request';

function* getProductInformation(action) {
  const { productId, jobId } = action.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/Products/getProductsinformation/${productId}/${jobId}`;
  try {
    const result: { data: ProductInformationType } = yield call(request, requestURL);
    if (result) {
      let updatedResult = {
        ...result.data,
        tempSecondaryCategories: result.data.secondaryCategories,
      };
      yield put(actions.productInformationLoaded(updatedResult));
    } else {
      yield put(actions.productInformationError('Received an empty response'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.productInformationError('Could not fetch data from endpoint'));
    } else {
      yield put(actions.productInformationError('Issue with api'));
    }
  }
}

function* getProductItemDetails() {
  const requestURL = `${protectedResources.api.apiEndpoint}/productItemDetails`;
  try {
    const result: productItemRow[] = yield call(request, requestURL);
    if (result) {
      yield put(actions.productItemDetailsLoaded(result));
    } else {
      yield put(actions.productItemsDetailsError('Received an empty response'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.productItemsDetailsError('Could not fetch data from endpoint'));
    } else {
      yield put(actions.productItemsDetailsError('Issue with api'));
    }
  }
}

function* getClientQuoteConfirmationDetails(action) {
  const { rfqJobId, jobQuoteIds } = action.payload;
  const quotes = jobQuoteIds;
  var test = '';
  quotes &&
    quotes?.forEach(item => {
      test += `&quotes=${item}`;
    });
  const requestURL = `${protectedResources.api.apiEndpoint}/Quotes/GetClientQuoteData?jobId=${rfqJobId}${test}`;

  try {
    const result: clientQuoteConfirmation = yield call(request, requestURL);
    if (result) {
      yield put(actions.clientQuoteConfirmationPopUpLoaded(result));
    } else {
      yield put(actions.clientQuoteConfirmationPopUpError('Received an empty response'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.clientQuoteConfirmationPopUpError('Could not fetch data from endpoint'));
    } else {
      yield put(actions.clientQuoteConfirmationPopUpError('Issue with api'));
    }
  }
}

function* getPlaceSupplierOrderDetails() {
  const requestURL = `${protectedResources.api.apiEndpoint}/placeSupplierOrder`;
  try {
    const result: supplierOrder = yield call(request, requestURL);
    if (result) {
      yield put(actions.placeSupplierOrderPopUpLoaded(result));
    } else {
      yield put(actions.placeSupplierOrderPopUpError('Received an empty response'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.placeSupplierOrderPopUpError('Could not fetch data from endpoint'));
    } else {
      yield put(actions.placeSupplierOrderPopUpError('Issue with api'));
    }
  }
}

function* getRfqData(action) {
  const jobId = +action.payload.jobId;
  const toggleAction = action.payload.toggleAction;
  const requestURL = `${protectedResources.api.apiEndpoint}/RFQ/GetRfqJobInformation/${jobId}?inActive=${toggleAction}`;
  try {
    const result: any = yield call(request, requestURL);
    const data: rfqDataForm = result.data;
    if (result) {
      yield put(actions.rfqDataLoaded(data));
    }
  } catch (err: any) {}
}

function* getRfqDataForOtherCosts(action) {
  const rfqJobId = action.payload;

  //  step2
  const requestURL = `${protectedResources.api.apiEndpoint}/RFQ/GetRfqJobInformation/${rfqJobId}`;
  try {
    //  step2 get call
    const result: any = yield call(request, requestURL);
    const data: rfqDataForm = result.data;

    if (result) {
      yield put(actions.rfqDataForOtherCostLoaded(data));
    }
  } catch (err: any) {}
}

function* cloneProductItem(action) {
  const requestURL = `${protectedResources.api.apiEndpoint}/Products/CopyProductTemplate/${action.payload}`;
  try {
    const result: productTemplate = yield call(request, requestURL);
    if (result) {
      yield put(actions.addClonedProductItemDetails(result));
    } else {
      yield put(actions.addClonedProductItemDetailsError('Received an empty response'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.addClonedProductItemDetailsError('Could not fetch data from endpoint'));
    } else {
      yield put(actions.addClonedProductItemDetailsError('Issue with api'));
    }
  }
}

//  Get Supplier Information for Assign Suppliers
function* getSupplierList(action) {
  const { id } = action.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/RFQ/GetProductSupplierDetails/${id}`;
  try {
    var result = yield call(request, requestURL);
    if (result) {
      yield put(actions.supplierListLoaded({ id, suppliers: result }));
    } else {
      yield put(actions.supplierListError({ id, error: 'Received an empty response' }));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.supplierListError({
          id,
          error: 'Could not fetch data from endpoint',
        }),
      );
    } else {
      yield put(actions.supplierListError({ id, error: 'Issue with api' }));
    }
  }
}

function* getVatRate() {
  const requestURL = `${protectedResources.api.apiEndpoint}/MasterData/GetVatRate`;
  try {
    const vatRate: any = yield call(request, requestURL);
    if (vatRate) {
      yield put(actions.CreateQuoteVatRate(vatRate));
    } else {
      console.error('error');
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      console.error('error');
    } else {
      console.error('error');
    }
  }
}

//  Status DropDown
function* getStatus() {
  const requestURL = `${protectedResources.api.apiEndpoint}/MasterData/getstatuslist`;
  try {
    const status: statusDetails[] = yield call(request, requestURL);
    if (status) {
      yield put(actions.statusLoaded(status));
    } else {
      yield put(
        actions.StatusError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.StatusError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.StatusError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* getPlaceSupplierOrder(action) {
  const { rfqJobId, productIds } = action.payload;
  const ids = productIds;
  var test = '';
  ids &&
    ids?.forEach(item => {
      test += `&TemplateIds=${item}`;
    });
  const requestURL = `${protectedResources.api.apiEndpoint}/Quotes/GetSupplierPlaceOrderData?jobId=${rfqJobId}${test}`;
  try {
    const data: any = yield call(request, requestURL);
    if (data) {
      yield put(actions.PlaceSupplierOrderLoaded(data));
    } else {
      yield put(
        actions.PlaceSupplierOrderError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.PlaceSupplierOrderError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(
        actions.PlaceSupplierOrderError({
          error: 'Issue with api',
          loading: false,
        }),
      );
    }
  }
}

function* getAllUsers(action) {
  const requestURL = action.payload.isClient ? `${protectedResources.api.apiEndpoint}/User/GetAllUsers?jobId=${action.payload.jobId}` : `${protectedResources.api.apiEndpoint}/User/GetAllUsers`;
  try {
    const users: any = yield call(request, requestURL);
    if (users) {
      yield put(actions.setAllUsers(users));
    } else {
      console.error('error');
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      console.error('error');
    } else {
      console.error('error');
    }
  }
}
function* getAllTaskData(action) {
  const requestURL = `${protectedResources.api.apiEndpoint}/${action.payload.url}`;
  try {
    var data: any = yield call(request, requestURL);
    if (data) {
      yield put(actions.setAllTaskData({ data: data, isProductId: action.payload.isProductId }));
    } else {
      console.error('error');
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      console.error('error');
    } else {
      console.error('error');
    }
  }
}

function* getOtherCostRateItems(action) {
  const accountId = action.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/RFQ/GetRateItems/${accountId}`;
  try {
    const status: OtherCostRateItems = yield call(request, requestURL);
    if (status) {
      yield put(actions.setOtherCostRateItems(status));
    } else {
      yield put(
        actions.StatusError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.StatusError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.StatusError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* getAddTasksData() {
  const requestURL = `${protectedResources.api.apiEndpoint}/Task/GetAddTasks`;
  try {
    const data: addTasks[] = yield call(request, requestURL);
    if (data) {
      yield put(actions.setAddTasksData(data));
    } else {
      console.error('error');
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      console.error('error');
    } else {
      console.error('error');
    }
  }
}

export function* requestForQuoteSaga() {
  yield takeLatest(actions.loadProductInformation.type, getProductInformation);
  yield takeLatest(actions.loadRfqData.type, getRfqData);
  yield takeLatest(actions.changeSelectedProductsOnClone.type, cloneProductItem);
  yield takeLatest(actions.loadRfqDataForOtherCost.type, getRfqDataForOtherCosts);
  yield takeLatest(actions.loadSupplierList.type, getSupplierList);
  yield takeLatest(actions.loadProductItemDetails.type, getProductItemDetails);
  yield takeLatest(actions.loadClientQuoteConfirmationPopUp.type, getClientQuoteConfirmationDetails);
  yield takeLatest(actions.loadPlaceSupplierOrderPopUp.type, getPlaceSupplierOrderDetails);
  yield takeLatest(actions.loadVatRateDetails.type, getVatRate);
  yield takeLatest(actions.loadStatusDetails.type, getStatus);
  yield takeLatest(actions.loadPlaceSupplierOrder.type, getPlaceSupplierOrder);
  yield takeLatest(actions.loadAllUsers.type, getAllUsers);
  yield takeLatest(actions.loadAllTaskData.type, getAllTaskData);
  yield takeLatest(actions.loadOtherCostRateItems.type, getOtherCostRateItems);
  yield takeLatest(actions.loadAddTasksData.type, getAddTasksData);
}
