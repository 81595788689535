const api = {
  GET_MASTER_DATA: '/MasterData/GetMasterData',
  GET_STATUS_LIST: '/MasterData/getstatuslist',
  CREATE_JOB: '/RFQ/CreateRfqJob',
  GET_JOB: 'RFQ/GetRfqJobInformation',
  UPDATE_JOB: '/RFQ/UpdateRfqJob',
  GET_ALL_JOBS: '/RFQ/GetAllRfq',
  COPY_JOB: '/RFQ/CopyRfqJob',
  DELETE_JOB: '/RFQ/DeleteRfqJob',
  UPDATE_JOB_STATUS: '/RFQ/UpdateJobStatus',
  GET_JOB_INFORMATION: '/RFQ/getjobinformation',
  GET_PRODUCT_ITEMS: '/RFQ/GetAllProductitems',
  ADD_PRODUCT_TEMPLATE: '/Products/CreateProductTemplate',
  UPDATE_PRODUCT_TEMPLATE: '/Products/UpdateProductTemplate',
  GET_ALL_UNALLOCATEDRFQS: '/RFQ/GetUnAllocatedRfq',
};

export default api;
