//  Externals
import { Modal, Button, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
//  Internals
import { getJobInformation } from '../../../../../store/reducers/entities/selectors';
import { Product, ProductsProps, initialProduct } from './types';
import callApi from './../../../../../services/callApi';
import MODULES from '../../../../../data/modules';
import API from '../../../../../data/api';
import { useSelector } from 'react-redux';

const Products = (props: ProductsProps) => {
  const { onHide, onOpen, setProduct, show } = props;

  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));
  const [productsList, setProductsList] = useState<Product[]>([]);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const getAllProducts = async () => {
      const response: any = await callApi({ url: `${API.GET_PRODUCT_ITEMS}/${jobInformation.accountId}`, method: 'get' });
      if (response.isSuccess) {
        //  Format the response
        const sortedData = sortBy(response.data.items, 'name').map((product: Product) => ({ ...product, checked: false }));
        setProductsList(sortedData);
        setProducts(sortedData);
      }
    };
    getAllProducts();
  }, [jobInformation.accountId]);

  /******************************************************************************************
  @Purpose    :   Handle change for Search Product
  *******************************************************************************************/
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredProducts = products.filter(product => product.name.toLowerCase().includes(event.target.value.trim().toLowerCase()));
    setProductsList(filteredProducts.map(product => ({ ...product, checked: false })));
  };

  /******************************************************************************************
  @Purpose    :   Handle Click on product
  *******************************************************************************************/
  const handleClick = (entity: Product) => {
    const checkedProductsList = productsList.map(product => {
      if (product.productId === entity.productId) product.checked = true;
      else product.checked = false;
      return product;
    });
    setProductsList(checkedProductsList);
  };

  /******************************************************************************************
  @Purpose    :   Handle select product
  *******************************************************************************************/
  const onSelect = () => {
    const selectedItem = productsList.find(product => product.checked);
    if (selectedItem) setProduct(selectedItem);
  };

  /******************************************************************************************
  @Purpose    :   Reset the products
  *******************************************************************************************/
  const resetHandler = (reset: boolean) => {
    setProductsList(products.map(product => ({ ...product, checked: false })));
    if (reset) setProduct(initialProduct);
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="fullScreen"
      centered
      dialogClassName="modal__searchProductItems"
      keyboard={false}
      onHide={() => {
        onHide();
        resetHandler(true);
      }}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Product Items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control type="search" placeholder="Search" className="search-products" onChange={handleChange} />
        {productsList.length > 0 ? (
          <ul className="productItem__classContainer">
            {productsList.map(product => (
              <li key={product.name + product.checked} className="productItem__class" onClick={() => handleClick(product)}>
                <Form.Check name="productItem__checkbox" type="radio" id={product.name} checked={product.checked} label={product.name} readOnly />
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="outline__button"
          onClick={() => {
            onHide();
            resetHandler(true);
          }}
          variant="outline-primary"
        >
          Cancel
        </Button>
        <Button
          className="full__button"
          onClick={() => {
            onHide();
            onSelect();
            onOpen();
            resetHandler(false);
          }}
          disabled={productsList.filter(product => product.checked).length === 0 ? true : false}
          variant="primary"
        >
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Products;
