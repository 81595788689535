import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { dashboardActions as actions } from './';
import { invoiceData } from './types';

function* getInvoice() {
  const requestURL = `${protectedResources.api.apiEndpoint}/Quotes/GetListofReadyToInvoice`;
  try {
    const invoice: invoiceData = yield call(request, requestURL);
    if (invoice) {
      yield put(actions.invoiceDataLoaded(invoice));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}

export function* invoiceSaga() {
  yield takeLatest(actions.loadInvoice.type, getInvoice);
}
