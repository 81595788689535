import React, { useEffect, useState } from 'react';
import { selectConfirmClientQuote, selectProductItemDetailsRows } from '../slice/selectors';
import { ClientQuoteProductItemDetails } from './ClientQuoteProductItemDetails';
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { getJobInformation, getActionToggle } from 'store/reducers/entities/selectors';
import { SUCCESS, FULL } from 'app/components/shared/Constants';
import PopupModal from 'app/components/shared/PopupModal';
import { Modal, Button } from 'react-bootstrap';
import MODULES from '../../../../data/modules';
import API from '../../../../data/api';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { request } from 'utils/request';
import moment from 'moment';
import { setUpdateJobData } from 'store/reducers/entities';
import { apiCallBegan } from 'store/reducers/loading';
import { useRequestForQuoteSlice } from './../slice/index';
import { ClientQuoteOtherCostsDetails } from './ClientQuoteOtherCostsDetails';
import LoadingSpinnerWithBackdrop from 'app/components/shared/LoadingSpinnerWithBackdrop';

const ClientQuoteConfirmation = props => {
  const dispatch = useDispatch();
  const { actions } = useRequestForQuoteSlice();
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));

  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [printItemRunonCheck, setPrintItemRunonCheck] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const quoteDataList = useSelector(selectConfirmClientQuote);
  const quoteData = quoteDataList.details;
  const checkVat = quoteDataList?.details?.products?.map(item => item.priceSummary?.isVatincludedOnQuotes).includes(false);
  const checkRunonVat = quoteData?.quoteSummary?.printItems?.every(item => item.runonVat == null);
  const productID = quoteData?.products ? quoteData?.products.map(item => item.templateId) : [];
  const productItemDetailsRows = props.productItemDetailsRows;
  const productItemDetailsRowsConfirm = props.productItemDetailsRowsConfirm;
  const quoteDataPopup = quoteDataList.details?.products?.filter(item => !['On Hold', 'Cancelled'].includes(item.statusName) && item.isActive === true);
  const otherCostsItem = quoteDataList?.details?.quoteSummary?.otherItems;
  var otherTitleData = otherCostsItem?.map(item => item?.otherCostTitle);
  var otherJobCostId = otherCostsItem?.map(item => {
    return {
      otherJobCostsId: item?.otherJobCostId,
    };
  });
  var confirmPopupBodyMessage = productItemDetailsRowsConfirm
    .filter(item => item.details.jobQuotes?.length > 0)
    .filter(item => item.value != null)
    .filter(item => item?.details?.printSpecifications?.form?.isActive != false)
    .filter(item => !['On Hold', 'Cancelled'].includes(item.statusName))
    .filter(item => item.details.jobQuotes.filter(item => item.jobQuoteSummary.checked)?.length > 0)
    .map(item => {
      return `${item?.title} - ${item?.productType}`;
    });
  const productDetails = useSelector(selectProductItemDetailsRows)?.filter(row => row.id == productID[0]);

  const { jobId } = useParams();

  const clientQuoteConfirmationRequestBody = () => {
    setLoader(true);
    let data = productItemDetailsRows
      .filter(item => !['On Hold', 'Cancelled'].includes(item.statusName))
      .filter(item => item?.details?.printSpecifications?.form?.isActive != false)
      .filter(item => item.value != null)
      ?.map(item => {
        return {
          productId: item.details.printSpecifications.form.templateId,
          quoteId: item.details.jobQuotes?.filter(item => item.jobQuoteSummary.checked),
        };
      })
      ?.filter(item => item.quoteId && item.quoteId?.length > 0)
      ?.map(item => {
        return {
          productTemplateId: item.productId,
          jobQuoteID: item.quoteId && item.quoteId?.length && item.quoteId[0].jobQuoteSummary.jobQuoteId,
        };
      });
    return otherJobCostId?.length ? [...data, ...otherJobCostId] : data;
  };

  const confirmQuoteSubmission = async () => {
    try {
      await request(`${protectedResources.api.apiEndpoint}/Quotes/CreateClientQuote/${jobId}`, {
        method: 'POST',
        body: JSON.stringify({
          jobId: jobId && parseInt(jobId),
          clientQuote: clientQuoteConfirmationRequestBody(),
          doNotSendEmailToClient: props?.checkedValues,
        }),
      })
        .then(res => {
          props.onHide();
          setConfirmModal(true);
          setLoader(false);
        })
        .catch(err => {
          setLoader(false);
        });
      jobId && dispatch(actions.loadRfqData({ jobId: jobId, toggleAction: toggleAction }));
      dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setUpdateJobData.type, module: MODULES.MY_JOBS }));
    } catch (responseError) {
      console.error('Error while submitting the form ' + responseError);
    }
  };

  useEffect(() => {
    if (quoteData?.quoteSummary?.printItems?.length > 0) {
      setPrintItemRunonCheck(quoteData?.quoteSummary?.printItems?.filter(item => item.runon !== null)?.length > 0);
    }
  }, [quoteData?.quoteSummary?.printItems]);

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="fullScreen"
        centered
        onHide={() => {
          props.onHide();
        }}
        dialogClassName="modal__maxFullScreen"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Client Quote Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal__body">
          {loader && <LoadingSpinnerWithBackdrop color="grey" />}
          {Object.keys(quoteData)?.length !== 0 && (
            <div className="clientQuoteConfirmation">
              <div className="top">
                <span className="reference_jobId className='fw-bold font-14'">
                  <p>
                    Reference: <strong>{quoteData?.jobDetails?.jobNumber}</strong>
                  </p>
                </span>
                <h6 className="fw-bold ps-1 pt-3 pb-1">CLIENT DETAILS</h6>
                <div className="grid__container" style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '10px', gridAutoRows: '50px', padding: '4px' }}>
                  <div className="grid__item">
                    <span>Contact Name</span>
                    <p className="fw-bold font-14">{quoteData?.clientDetails?.contactName === null ? '-' : quoteData?.clientDetails?.contactName}</p>
                  </div>
                  <div className="grid__item">
                    <span>Email</span>
                    <p className="fw-bold font-14">{quoteData?.clientDetails?.email === null ? '-' : quoteData?.clientDetails?.email}</p>
                  </div>
                  <div className="grid__item">
                    <span>Telephone</span>
                    <p className="fw-bold font-14">{quoteData?.clientDetails?.telephone === null ? '-' : quoteData?.clientDetails?.telephone}</p>
                  </div>
                </div>
                <hr />
                <h6 className="fw-bold ps-1 pt-3 pb-1">JOB DETAILS</h6>
                <div className="grid__container" style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '10px', gridAutoRows: '50px', padding: '4px' }}>
                  <div className="grid__item">
                    <span>Job Reference</span>
                    <p className="fw-bold font-14">{quoteData?.jobDetails?.jobNumber === null ? '-' : quoteData?.jobDetails?.jobNumber}</p>
                  </div>
                  <div className="grid__item">
                    <span>Job Title</span>
                    <p className="fw-bold font-14">{quoteData?.jobDetails?.jobTitle === null ? '-' : quoteData?.jobDetails?.jobTitle}</p>
                  </div>
                  <div className="grid__item">
                    <span>Requisition</span>
                    <p className="fw-bold font-14">{quoteData?.jobDetails?.requisition === null ? '-' : quoteData?.jobDetails?.requisition}</p>
                  </div>
                  <div className="grid__item">
                    <span>Quote Date</span>
                    <p className="fw-bold font-14">{quoteData?.jobDetails?.quoteDate === null ? '-' : moment(quoteData?.jobDetails?.quoteDate).format('DD/MM/YYYY')}</p>
                  </div>
                </div>
                <hr />
                <h6 className="fw-bold ps-1 pt-3 pb-1">CDS EXECUTIVE</h6>
                <div className="grid__container" style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '10px', gridAutoRows: '50px', padding: '4px' }}>
                  <div className="grid__item">
                    <span>CDS Executive Name</span>
                    <p className="fw-bold font-14">{quoteData?.clientDetails?.contactName === null ? '-' : quoteData?.clientDetails?.cdsContactName}</p>
                  </div>
                  <div className="grid__item">
                    <span>Email</span>
                    <p className="fw-bold font-14">{quoteData?.clientDetails?.cdsEmail === null ? '-' : quoteData?.clientDetails?.cdsEmail}</p>
                  </div>
                </div>
                <hr />
              </div>
              <div className="productItemDetails">
                {quoteDataPopup?.map((productItem, index) => (
                  <ClientQuoteProductItemDetails key={index} productItem={productItem} productDetails={productDetails} checkVat={!checkVat} />
                ))}
              </div>
              <div className="productItemDetails">
                {quoteData?.otherJobCosts && quoteData?.otherJobCosts?.map((productItem, index) => <ClientQuoteOtherCostsDetails key={index} productItem={productItem} checkVat={!checkVat} />)}
              </div>
              <div className="summary">
                <table>
                  <thead>
                    <tr>
                      <td colSpan={8}>
                        <h6 style={{ margin: '0' }}>SUMMARY</h6>
                        <hr style={{ margin: '5px 0px 0px 0px' }} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Print Item</strong>
                      </td>

                      <td className="text-end fw-bold font-14">Net (&#163;)</td>
                      {!checkVat && <td className="text-end fw-bold font-14">VAT (&#163;)</td>}
                      <td className="text-end fw-bold font-14">Total (&#163;)</td>
                      {printItemRunonCheck && (
                        <>
                          <td className="text-end fw-bold font-14">Runon Net (&#163;)</td>
                          {!checkVat && <td className="text-end fw-bold font-14">Runon VAT (&#163;)</td>}
                          <td className="text-end fw-bold font-14">Runon Total (&#163;)</td>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {quoteDataPopup?.length > 0 &&
                      quoteDataPopup?.map((printItems, index) => (
                        <tr key={index}>
                          <td className="font-14 text-start">{quoteDataPopup[index]?.title}</td>
                          <td className="font-14 text-end">{printItems?.priceSummary?.net === null ? '-' : printItems?.priceSummary?.net?.toFixed(2)}</td>
                          {!checkVat && <td className="font-14 text-end">{printItems?.priceSummary?.vat === null ? '-' : printItems?.priceSummary?.vat?.toFixed(2)}</td>}
                          <td className="font-14 text-end">{printItems?.priceSummary?.total === null ? '-' : printItems?.priceSummary?.total?.toFixed(2)}</td>
                          {printItems?.priceSummary?.runon !== null && (
                            <>
                              <td className="font-14 text-end">{printItems?.priceSummary?.runon?.toFixed(2)}</td>
                              {!checkVat && <td className="font-14 text-end">{printItems?.priceSummary?.runonVat?.toFixed(2)}</td>}

                              <td className="font-14 text-end">{printItems?.priceSummary?.runonTotal?.toFixed(2)}</td>
                            </>
                          )}
                        </tr>
                      ))}
                    {quoteData.quoteSummary?.otherItems && quoteData.quoteSummary?.otherItems?.length > 0 && (
                      <>
                        <tr key={quoteData.quoteSummary?.otherItems[0]?.vat}>
                          <td>
                            <strong>Other Items</strong>
                          </td>
                        </tr>
                        {quoteData.quoteSummary?.otherItems &&
                          quoteData.quoteSummary?.otherItems?.map((item, i) => (
                            <tr key={i}>
                              <td className="font-14 text-start">{item?.otherCostTitle === null ? '-' : item?.otherCostTitle}</td>
                              <td className="font-14 text-end">{item?.net === null ? '0.00' : item?.net?.toFixed(2)}</td>
                              {!checkVat && <td className="font-14 text-end">{item?.vat === null ? '0.00' : item?.vat?.toFixed(2)}</td>}
                              <td className="font-14 text-end">{item?.total === null ? '0.00' : item?.total?.toFixed(2)}</td>
                              <td className="font-14 text-end">{item.runon?.toFixed(2)}</td>
                              {!checkVat && <td className="font-14 text-end">{item.runonVat?.toFixed(2)}</td>}
                              <td className="font-14 text-end">{item.runonTotal?.toFixed(2)}</td>
                            </tr>
                          ))}
                      </>
                    )}
                    {!jobInformation.quoteBasket && (
                      <>
                        <tr>
                          <td colSpan={8}>
                            <hr style={{ margin: '0px 0px 0px 0px' }} />
                          </td>
                        </tr>
                        <tr key={Math.random()}>
                          <td>Total (&#163;)</td>
                          <td className="font-14 text-end">
                            {[
                              quoteDataPopup?.map(item => item.priceSummary).reduce((sum, { net }) => sum + net, 0),
                              quoteData.quoteSummary.otherItems && quoteData.quoteSummary.otherItems.length && quoteData.quoteSummary.otherItems[0].net,
                            ]
                              .reduce((sum, val) => sum + val, 0)
                              ?.toFixed(2)}
                          </td>
                          {!checkVat && (
                            <td className="font-14 text-end">
                              {[
                                quoteDataPopup?.map(item => item.priceSummary)?.reduce((sum, { vat }) => sum + vat, 0),
                                quoteData.quoteSummary.otherItems && quoteData.quoteSummary.otherItems.length && quoteData.quoteSummary.otherItems[0].vat,
                              ]
                                .reduce((sum, val) => sum + val, 0)
                                ?.toFixed(2)}
                            </td>
                          )}
                          <td className="font-14 text-end">
                            {[
                              quoteDataPopup?.map(item => item.priceSummary)?.reduce((sum, { total }) => sum + total, 0),
                              quoteData.quoteSummary.otherItems && quoteData.quoteSummary.otherItems.length && quoteData.quoteSummary.otherItems[0].total,
                            ]
                              .reduce((sum, val) => sum + val, 0)
                              ?.toFixed(2)}
                          </td>
                          {printItemRunonCheck && (
                            <>
                              <td className="font-14 text-end">
                                {[
                                  quoteDataPopup?.map(item => item.priceSummary)?.reduce((sum, { runon }) => sum + runon, 0),
                                  quoteData.quoteSummary.otherItems && quoteData.quoteSummary.otherItems.length && quoteData.quoteSummary.otherItems[0].runon,
                                ]
                                  .reduce((sum, val) => sum + val, 0)
                                  ?.toFixed(2)}
                              </td>
                              {!checkVat && (
                                <td className="font-14 text-end">
                                  {[
                                    quoteDataPopup?.map(item => item.priceSummary)?.reduce((sum, { runonVat }) => sum + runonVat, 0),
                                    quoteData.quoteSummary.otherItems && quoteData.quoteSummary.otherItems.length && quoteData.quoteSummary.otherItems[0].runonVat,
                                  ]
                                    .reduce((sum, val) => sum + val, 0)
                                    ?.toFixed(2)}
                                </td>
                              )}
                              <td className="font-14 text-end">
                                {[
                                  quoteDataPopup?.map(item => item.priceSummary)?.reduce((sum, { runonTotal }) => sum + runonTotal, 0),
                                  quoteData.quoteSummary.otherItems && quoteData.quoteSummary.otherItems.length && quoteData.quoteSummary.otherItems[0].runonTotal,
                                ]
                                  .reduce((sum, val) => sum + val, 0)
                                  ?.toFixed(2)}
                              </td>
                            </>
                          )}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => {
              props.onHide();
            }}
            className="outline__button"
          >
            Cancel
          </Button>
          <Button variant="outline-primary" onClick={confirmQuoteSubmission} className="full__button">
            Confirm client quote
          </Button>
        </Modal.Footer>
      </Modal>
      <PopupModal
        show={showConfirmModal}
        onHide={() => setConfirmModal(false)}
        type={SUCCESS}
        header={'Success'}
        bodyTitle={'A quote for the following item has been created and sent to client'}
        bodyContent={otherTitleData ? [...confirmPopupBodyMessage, ...otherTitleData] : confirmPopupBodyMessage}
        footerButtons={[
          {
            type: FULL,
            name: 'OK',
            onClick: () => setConfirmModal(false),
          },
        ]}
      />
    </>
  );
};

export default ClientQuoteConfirmation;
