//  Externals
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
//  Internals
import { selectProductItemsButtonsHeader } from '../../../../pages/requestForQuote/slice/selectors';
import { CONFIRMATION, OUTLINED, FULL } from '../../../shared/Constants';
import { getJobInformation } from 'store/reducers/entities/selectors';
import { uniqueId } from './../../../../../utils/form/index';
import PopupModal from 'app/components/shared/PopupModal';
import MODULES from '../../../../../data/modules';
import GetProductItem from './GetProductItem';
import { ProductItemProps } from './types';

const ProductItem = (props: ProductItemProps) => {
  const { create, initialState, onHide, onShowProductsModal, productId, productType, show } = props;

  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [formId] = useState(uniqueId('printSpecificationsForm-'));

  const headerButtons = useSelector(selectProductItemsButtonsHeader);
  const buttons = headerButtons.buttons;

  /******************************************************************************************
  @Purpose    :   Handle Back Button
  *******************************************************************************************/
  const handleBackPopup = () => {
    if (create) onShowProductsModal();
    onHide();
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="fullScreen"
        centered
        dialogClassName="modal__printSpecificationForm"
        keyboard={false}
        onHide={() => {
          setShowConfirmModal(true);
        }}
        scrollable
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              {create && !buttons.addProductItem.hide && <ArrowBackIosIcon className={`header__button ${buttons.addProductItem.disabled && 'header__button-disabled'}`} onClick={handleBackPopup} />}
              {jobInformation.title} - {productType}
            </h2>
            <h3>{jobInformation.jobNumber}</h3>
          </Modal.Title>
        </Modal.Header>
        <hr style={{ margin: '0px' }} />
        <Modal.Body>
          <h6 className="form__subHeader top__subHeader">Print Specifications</h6>
          <GetProductItem create={create} disabled={false} formId={formId} initialState={initialState} jobId={jobInformation.jobId} onHide={onHide} productId={productId} productType={productType} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="outline__button"
            onClick={() => {
              setShowConfirmModal(true);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button className="full__button" form={formId} type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <PopupModal
        bodyTitle={'Are you sure? Any changes will be lost'}
        footerButtons={[
          {
            type: OUTLINED,
            name: 'No',
            onClick: () => setShowConfirmModal(false),
          },
          {
            type: FULL,
            name: 'Yes',
            onClick: () => {
              setShowConfirmModal(false);
              onHide();
            },
          },
        ]}
        header={'Confirmation'}
        onHide={() => setShowConfirmModal(false)}
        show={showConfirmModal}
        type={CONFIRMATION}
      />
    </>
  );
};

export default ProductItem;
