//  Externals
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import moment from 'moment';
//  Internals
import { CONFIRMATION, OUTLINED, FULL, ERROR, INVOICED, READYTOINVOICE } from '../../shared/Constants';
import { getProductInformationData } from '../../../pages/requestForQuote/slice/selectors';
import { getIsCDS, getIsClient, getIsSupplier } from './../sideBar/slice/selectors';
import { getMasterData } from './../../../../store/reducers/masterData/selectors';
import { ReactComponent as CalendarIcon } from '../../../../images/calendar.svg';
import LoadingSpinnerWithBackdrop from '../../shared/LoadingSpinnerWithBackdrop';
import { getJobInformation } from 'store/reducers/entities/selectors';
import { setPrintSpecifications } from 'store/reducers/entities';
import { DropDown, JobItemOption, OrderedProps } from './types';
import PopupModal from 'app/components/shared/PopupModal';
import PLACEHOLDER from '../../../../data/placeholder';
import VALIDATIONS from '../../../../data/validations';
import callApi from '../../../../services/callApi';
import MODULES from '../../../../data/modules';
import { orderedSchema } from './validator';
import API from '../../../../data/api';
import './style.sass';

const Ordered = (props: OrderedProps) => {
  const { initialState, onHide, productId, show } = props;

  const dispatch = useDispatch();

  const productInformationData = useSelector(getProductInformationData);
  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));
  const isSupplier = useSelector(getIsSupplier);
  const isClient = useSelector(getIsClient);
  const isCDS = useSelector(getIsCDS);

  const deliveryAddressChangeReasons: DropDown[] = useSelector(getMasterData('deliveryAddressChangeReasons'));
  const [deliveryChange, setDeliveryChange] = useState<boolean>(false);
  const [deliveryOnChangeState, setDeliveryOnChangeState] = useState<{
    deliveryAddress: string | null;
    deliveryInstructions: string | null;
    deliveryContact: string | null;
    deliveryDueDate: any;
  }>({
    deliveryAddress: '',
    deliveryInstructions: '',
    deliveryContact: '',
    deliveryDueDate: '',
  });

  const [jobItemOptionsList, setJobItemOptionsList] = useState<JobItemOption[]>([]);
  const [typeOfProofsOther, setTypeOfProofsOther] = useState(false);
  const [artworkFormatsList, setArtworkFormatsList] = useState<DropDown[]>([]);
  const [artworkFormatOther, setArtworkFormatOther] = useState(false);
  const [typeOfProofsList, setTypeOfProofsList] = useState<DropDown[]>([]);
  const [showProofOfDueDate, setShowProofOfDueDate] = useState(false);
  const [typeOfProofs, setTypeOfProofs] = useState(true);

  //  Other Fields
  const [targetDate, setTargetDate] = useState(false);

  useEffect(() => {
    const { artworkFormatId, typeOfProofsId } = initialState;

    //  Artwork format Other
    const artworkFormat = artworkFormatsList.find(entity => entity.value === artworkFormatId);
    if (artworkFormat && artworkFormat.label === VALIDATIONS.OTHER) setArtworkFormatOther(true);
    else setArtworkFormatOther(false);
    //  Type of Proofs Other
    if (typeOfProofsList.length) {
      const typeOfProof = typeOfProofsList.find(entity => entity.value === typeOfProofsId);
      if (typeOfProof && typeOfProof.label === VALIDATIONS.OTHER) setTypeOfProofsOther(true);
      else setTypeOfProofsOther(false);
      if (typeOfProof && typeOfProof.label !== VALIDATIONS.PROOF_NOT_REQUIRED) setShowProofOfDueDate(true);
      else setShowProofOfDueDate(false);
    } else {
      setTypeOfProofs(false);
      setTypeOfProofsOther(true);
      setShowProofOfDueDate(true);
    }
  }, [artworkFormatsList, initialState, typeOfProofsList]);

  useEffect(() => {
    const { artworkFormat, jobItemOptions, typeOfProofs } = productInformationData;
    //  Art work format list
    setArtworkFormatsList(artworkFormat.map(item => ({ value: +item.value, label: item.name })));
    //  Type of Proofs list
    setTypeOfProofsList(typeOfProofs.map(item => ({ value: +item.value, label: item.name })));
    //  Job Options list
    let updateJobItemOptions = jobItemOptions.map(item => ({ value: +item.value, label: item.name, checked: false }));
    if (!isEmpty(initialState.jobItemOptions)) {
      updateJobItemOptions = jobItemOptions.map(item => ({ value: +item.value, label: item.name, checked: initialState.jobItemOptions.includes(+item.value) }));
      //  Show / Hide Target Date
      const complexJobEntity = updateJobItemOptions.find(jobOption => jobOption.label.trim() === 'Complex job' && jobOption.checked);
      if (complexJobEntity) setTargetDate(true);
      else setTargetDate(false);
    } else setTargetDate(false);
    setJobItemOptionsList(updateJobItemOptions);
  }, [initialState.jobItemOptions, productInformationData]);

  /*  Counters  */
  //  Deliver Address change reason
  const [deliveryAddressChangeReasonOtherCounter, setDeliveryAddressChangeReasonOtherCounter] = useState(0);
  //  Deliver Instructions
  const [deliveryInstructionsCounter, setDeliveryInstructionsCounter] = useState(0);
  //  Delivery Address
  const [deliveryAddressCounter, setDeliveryAddressCounter] = useState(0);
  //  Description
  const [descriptionCounter, setDescriptionCounter] = useState(0);
  //  CDS Notes
  const [cdsNotesCounter, setCdsNotesCounter] = useState(0);
  //  Supplier Notes
  const [supplierNotesCounter, setSupplierNotesCounter] = useState(0);
  //  Client Notes
  const [clientNotesCounter, setClientNotesCounter] = useState(0);
  //  Title
  const [titleCounter, setTitleCounter] = useState(0);

  useEffect(() => {
    const { deliveryContact, deliveryAddress, deliveryInstructions, deliveryDueDate, description, supplierNotes, title } = initialState;
    //  Delivery Address
    setDeliveryAddressCounter(deliveryAddress?.length ? deliveryAddress.length : 0);
    //  Deliver Instructions
    setDeliveryInstructionsCounter(deliveryInstructions?.length ? deliveryInstructions.length : 0);
    //  Supplier Notes
    setSupplierNotesCounter(supplierNotes?.length ? supplierNotes.length : 0);
    //  Description
    setDescriptionCounter(description?.length ? description.length : 0);
    //  Title
    setTitleCounter(title?.length ? title.length : 0);
    setDeliveryOnChangeState({
      deliveryAddress: deliveryAddress,
      deliveryInstructions: deliveryInstructions,
      deliveryContact: deliveryContact,
      deliveryDueDate: deliveryDueDate && moment(new Date(deliveryDueDate)).format('YYYY-MM-DD'),
    });
  }, [initialState]);
  useEffect(() => {
    const { deliveryContact, deliveryAddress, deliveryInstructions } = initialState;
    const deliveryDueDate = initialState.deliveryDueDate ? moment(new Date(initialState.deliveryDueDate)).format('YYYY-MM-DD') : null;
    if (
      deliveryOnChangeState.deliveryAddress !== deliveryAddress ||
      deliveryOnChangeState.deliveryInstructions !== deliveryInstructions ||
      deliveryOnChangeState.deliveryContact !== deliveryContact ||
      (deliveryOnChangeState.deliveryDueDate && deliveryOnChangeState.deliveryDueDate && moment(new Date(deliveryOnChangeState.deliveryDueDate)).format('YYYY-MM-DD') !== deliveryDueDate)
    ) {
      setDeliveryChange(true);
    } else {
      setDeliveryChange(false);
    }
  }, [deliveryOnChangeState]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);

  /******************************************************************************************
  @Purpose    :  Use Form
  *******************************************************************************************/
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: initialState,
    resolver: yupResolver(orderedSchema({ deliveryAddressChangeReasons, jobItemOptionsList, deliveryChange })),
  });

  const onError = error => {
    console.log('ERROR:::', error);
  };

  //  Other Fields
  const [deliveryAddressChangeReasonOther, setDeliveryAddressChangeReasonOther] = useState(false);
  const [isNoFailDeliveryDateChecked, setIsNoFailDeliveryDateChecked] = useState(false);

  useEffect(() => {
    const { deliveryAddressChangeReasonId, noFailDeliveryDate } = initialState;

    //  Deliver Address change reason Other
    const deliveryAddressChangeReason = deliveryAddressChangeReasons.find(entity => entity.value === deliveryAddressChangeReasonId);
    if (deliveryAddressChangeReason && deliveryAddressChangeReason.label === VALIDATIONS.OTHER) setDeliveryAddressChangeReasonOther(true);
    else setDeliveryAddressChangeReasonOther(false);
    //  No Fail Delivery Date
    if (noFailDeliveryDate) setIsNoFailDeliveryDateChecked(true);
    else setIsNoFailDeliveryDateChecked(false);
    setValue('deliveryAddressChangeReasonId', null);
  }, [deliveryAddressChangeReasons, initialState]);

  /******************************************************************************************
  @Purpose    :  Handle Form Submit
  *******************************************************************************************/
  const onSubmit = async values => {
    setLoading(true);
    const requestBody = {
      ...initialState,
      ...values,
      productId,
      rfqjobId: jobInformation.jobId,
      isActive: values.isActive === 'Yes' ? true : false,
      laminated: values.laminated === 'Yes' ? true : false,
      samples: values.samples === 'Yes' ? true : false,
      noOfSampleCopies: values.samples === 'Yes' ? values.noOfSampleCopies : null,
      sampleCopiesDeliveryAddress: values.samples === 'Yes' ? values.sampleCopiesDeliveryAddress : null,
      sealerOnCover: values.sealerOnCover === 'Yes' ? true : false,
      sealerOnFace: values.sealerOnFace === 'Yes' ? true : false,
      sealerOnReverse: values.sealerOnReverse === 'Yes' ? true : false,
      sealerOnText: values.sealerOnText === 'Yes' ? true : false,
      artworkDueDate: values.artworkDueDate && moment(new Date(values.artworkDueDate)).format('YYYY-MM-DD'),
      deliveryDueDate: values.deliveryDueDate && moment(new Date(values.deliveryDueDate)).format('YYYY-MM-DD'),
      proofDueDate: values.proofDueDate && moment(new Date(values.proofDueDate)).format('YYYY-MM-DD'),
      targetDate: values.targetDate && moment(new Date(values.targetDate)).format('YYYY-MM-DD'),
      jobItemOptions: values.jobItemOptions.map((jobItemOption: string | number) => +jobItemOption),
    };
    try {
      //  Updating
      const response: any = await callApi({ url: `${API.UPDATE_PRODUCT_TEMPLATE}/${initialState.templateId}`, method: 'put', body: requestBody });
      if (response.isSuccess) {
        dispatch(setPrintSpecifications({ module: MODULES.MY_JOBS, printSpecifications: { ...response.data, productType: initialState.productType } }));
      }
      onHide();
      setLoading(false);
    } catch (responseError) {
      setLoading(false);
      setErrorModal(true);
      console.log('Error while submitting the form ' + responseError);
    }
  };

  /******************************************************************************************
  @Purpose    :  Handle Drop down select for other fields show / hide
  *******************************************************************************************/
  const handleSelect = (name: string, val: DropDown | null) => {
    //  Delivery Address Change Reason
    if (name === 'deliveryAddressChangeReasonId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setDeliveryAddressChangeReasonOther(true);
      } else {
        setValue('deliveryAddressChangeReasonOther', null, {
          shouldValidate: false,
        });
        setDeliveryAddressChangeReasonOther(false);
      }
    }
  };

  /******************************************************************************************
  @Purpose    :  Handle Job options change
  *******************************************************************************************/
  const handleJobItemChange = entity => {
    const updatedJobItemOptions = jobItemOptionsList.map(jobItemOption => {
      if (jobItemOption.value === entity.value) {
        return { ...jobItemOption, checked: !entity.checked };
      }
      return jobItemOption;
    });
    setJobItemOptionsList(updatedJobItemOptions);
    //  Show / Hide Target Date
    if (entity.label.trim() === 'Complex job') {
      setTargetDate(!entity.checked);
      setValue('targetDate', null, { shouldValidate: false });
    }
  };

  /******************************************************************************************
  @Purpose    :  Get Minimum Date
  *******************************************************************************************/
  const getMinimumDate = (value: string | number | Date | null): Date => {
    if (value && new Date() > new Date(value)) return new Date(value);
    return new Date();
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="fullScreen"
        centered
        dialogClassName="modal__printSpecificationForm"
        keyboard={false}
        onHide={() => {
          setShowConfirmModal(true);
        }}
        scrollable
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              {jobInformation.title} - {initialState.productType}
            </h2>
            <h3>{jobInformation.jobNumber}</h3>
          </Modal.Title>
        </Modal.Header>
        <hr style={{ margin: '0px' }} />
        <Modal.Body>
          {loading && <LoadingSpinnerWithBackdrop color="grey" />}
          <h6 className="form__subHeader top__subHeader">Print Specifications</h6>
          <Form className="printSpecifications__form" id="printSpecificationsForm-Quotation" onSubmit={handleSubmit(onSubmit, onError)}>
            <Form.Group controlId="form__title" style={{ gridColumn: '1 / 3' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>
                  Title<span className="input__required">*</span>
                </span>
                <span>{titleCounter}/100</span>
              </Form.Label>
              <Form.Control
                autoComplete="off"
                className={errors.title && 'border-danger'}
                maxLength={100}
                placeholder={PLACEHOLDER.TITLE}
                type="text"
                {...register('title')}
                onChange={e => setTitleCounter(e.target.value.length)}
              />
              {errors.title && <Form.Text className="text-danger">{errors.title.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__description" style={{ gridColumn: '1 / 3' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Description</span>
                <span>{descriptionCounter}/1000</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                autoComplete="off"
                className={errors.description && 'border-danger'}
                maxLength={1000}
                placeholder={PLACEHOLDER.DESCRIPTION}
                rows={3}
                type="text"
                {...register('description')}
                onChange={event => setDescriptionCounter(event.target.value.length)}
              />
              {errors.description && (
                <Form.Text className="text-danger">
                  <h6 style={{ fontSize: '15px', fontWeight: 'lighter', marginTop: '2px' }}>{errors.description.message}</h6>
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="form__reference" style={{ gridColumn: '1 / 2' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>Reference</Form.Label>
              <Form.Control autoComplete="off" className={errors.reference && 'border-danger'} placeholder={PLACEHOLDER.REFERENCE} type="text" {...register('reference')} />
              {errors.reference && (
                <Form.Text className="text-danger">
                  <h6 style={{ fontSize: '15px', fontWeight: 'lighter', marginTop: '2px' }}>{errors.reference.message}</h6>
                </Form.Text>
              )}
            </Form.Group>
            <h6 style={{ gridColumn: '1/4', marginTop: '30px' }}>Delivery Information</h6>
            <Form.Group controlId="form__deliveryContact" style={{ gridColumn: '1 / 3' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Delivery Contact</span>
              </Form.Label>
              <Form.Control
                autoComplete="off"
                className={errors.deliveryContact && 'border-danger'}
                placeholder={PLACEHOLDER.DELIVERY_CONTACT}
                type="text"
                {...register('deliveryContact')}
                onChange={event => {
                  setDeliveryOnChangeState(prevState => ({
                    ...prevState,
                    deliveryContact: event.target.value,
                  }));
                }}
              />
              {errors.deliveryContact && (
                <Form.Text className="text-danger">
                  <h6 style={{ fontSize: '15px', fontWeight: 'lighter', marginTop: '2px' }}>{errors.deliveryContact.message}</h6>
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="form__deliveryAddress" style={{ gridColumn: '1/3' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Delivery Address</span>
                <span>{deliveryAddressCounter}/1000</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                autoComplete="off"
                className={errors.deliveryAddress && 'border-danger'}
                maxLength={1000}
                placeholder={PLACEHOLDER.DELIVERY_ADDRESS}
                rows={3}
                type="text"
                {...register('deliveryAddress')}
                onChange={event => {
                  setDeliveryOnChangeState(prevState => ({
                    ...prevState,
                    deliveryAddress: event.target.value,
                  }));
                  setDeliveryAddressCounter(event.target.value.length);
                }}
              />
              {errors.deliveryAddress && (
                <Form.Text className="text-danger">
                  <h6 style={{ fontSize: '15px', fontWeight: 'lighter', marginTop: '2px' }}>{errors.deliveryAddress.message}</h6>
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="form__deliveryInstructions" style={{ gridColumn: '1/3' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Delivery Instructions</span>
                <span>{deliveryInstructionsCounter}/1000</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                autoComplete="off"
                className={errors.deliveryInstructions && 'border-danger'}
                maxLength={1000}
                placeholder={PLACEHOLDER.DELIVERY_INSTRUCTIONS}
                rows={3}
                type="text"
                {...register('deliveryInstructions')}
                onChange={event => {
                  setDeliveryOnChangeState(prevState => ({
                    ...prevState,
                    deliveryInstructions: event.target.value,
                  }));
                  setDeliveryInstructionsCounter(event.target.value.length);
                }}
              />
              {errors.deliveryInstructions && (
                <Form.Text className="text-danger">
                  <h6 style={{ fontSize: '15px', fontWeight: 'lighter', marginTop: '2px' }}>{errors.deliveryInstructions.message}</h6>
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="form__deliveryDueDate" style={{ gridColumn: '1' }}>
              <Form.Label>Delivery Due Date</Form.Label>
              <Controller
                control={control}
                name="deliveryDueDate"
                render={({ field: { onBlur, onChange, ref, value } }) => (
                  <div className={`form-control picker__container ${errors.deliveryDueDate ? 'border-danger' : ''}`}>
                    <DatePicker
                      className="picker__input"
                      dateFormat="dd/MM/yyyy"
                      disabled={[READYTOINVOICE, INVOICED].includes(initialState?.status?.name)}
                      minDate={getMinimumDate(value)}
                      onBlur={onBlur} //  Notify when input is touched
                      onChange={event => {
                        onChange(event);
                        setDeliveryOnChangeState(prevState => ({
                          ...prevState,
                          deliveryDueDate: event,
                        }));
                      }}
                      placeholderText={PLACEHOLDER.SELECT_DELIVERY_DUE_DATE}
                      ref={ref}
                      selected={value ? new Date(value) : null}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      strictParsing
                    />
                    <CalendarIcon />
                  </div>
                )}
              />
              {errors.deliveryDueDate && (
                <Form.Text className="text-danger">
                  <h6 style={{ fontSize: '15px', fontWeight: 'lighter', marginTop: '2px' }}>{errors.deliveryDueDate.message}</h6>
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="form__noFailDeliveryDate" style={{ gridColumn: '1' }}>
              <Controller
                control={control}
                name="noFailDeliveryDate"
                render={({ field: { onBlur, onChange, ref, value } }) => (
                  <Form.Check
                    onBlur={onBlur}
                    onChange={e => {
                      onChange(e);
                      setIsNoFailDeliveryDateChecked(!isNoFailDeliveryDateChecked);
                    }}
                    label="No Fail Deliver Date"
                    ref={ref}
                    type="checkbox"
                    checked={isNoFailDeliveryDateChecked}
                  />
                )}
              />
              {errors.noFailDeliveryDate && (
                <Form.Text className="text-danger">
                  <h6 style={{ fontSize: '15px', fontWeight: 'lighter', marginTop: '2px' }}>{errors.noFailDeliveryDate.message}</h6>
                </Form.Text>
              )}
            </Form.Group>
            {deliveryChange && (
              <>
                <Form.Label style={{ gridColumn: '1/3' }}>
                  Reason for Changing the Delivery Address<span className="input__required">*</span>
                </Form.Label>
                <Form.Group controlId="form__deliveryAddressChangeReasonId" style={{ gridColumn: '1', marginTop: '-1rem' }}>
                  <Controller
                    control={control}
                    name="deliveryAddressChangeReasonId"
                    render={({ field: { name, onBlur, onChange, ref, value } }) => {
                      return (
                        <Select
                          className={errors.deliveryAddressChangeReasonId && 'border-danger-select'}
                          classNamePrefix="custom-select-input"
                          id="form__deliveryAddressChangeReasonId"
                          isClearable
                          maxMenuHeight={175}
                          onBlur={onBlur} //  Notify when input is touched
                          onChange={(val, { action }) => {
                            if (action === 'clear') {
                              onChange(null);
                            } else {
                              onChange(val?.value);
                            }
                            handleSelect(name, val);
                          }} // Send value to hook f = items.find(item => item.value = val);
                          options={deliveryAddressChangeReasons}
                          placeholder={PLACEHOLDER.SELECT_DELIVERY_ADDRESS_REASON_CHANGE}
                          ref={ref}
                          value={deliveryAddressChangeReasons.find(deliveryAddressChangeReason => deliveryAddressChangeReason.value === value) || null}
                        />
                      );
                    }}
                  />
                  {errors.deliveryAddressChangeReasonId && <Form.Text className="text-danger">{errors.deliveryAddressChangeReasonId.message}</Form.Text>}
                </Form.Group>
                {deliveryAddressChangeReasonOther && (
                  <Form.Group controlId="form__deliveryAddressChangeReasonOther" style={{ gridColumn: '1 / 3' }}>
                    <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>
                        Other Reason for Changing the Delivery Address<span className="input__required">*</span>
                      </span>
                      <span>{deliveryAddressChangeReasonOtherCounter}/1000</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      autoComplete="off"
                      className={errors.deliveryAddressChangeReasonOther && 'border-danger'}
                      maxLength={1000}
                      placeholder={PLACEHOLDER.DELIVERY_ADDRESS_REASON_CHANGE_OTHER}
                      rows={3}
                      type="text"
                      {...register('deliveryAddressChangeReasonOther')}
                      onChange={event => setDeliveryAddressChangeReasonOtherCounter(event.target.value.length)}
                    />
                    {errors.deliveryAddressChangeReasonOther && <Form.Text className="text-danger">{errors.deliveryAddressChangeReasonOther.message}</Form.Text>}
                  </Form.Group>
                )}
              </>
            )}
            <>
              <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/4', marginTop: '30px' }}>
                Artwork
              </h6>
              <Form.Group controlId="form__artworkFormatId" style={{ gridColumn: '1 / 2' }}>
                <Form.Label>
                  Artwork Format<span className="input__required">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="artworkFormatId"
                  render={({ field: { name, onBlur, onChange, ref, value } }) => {
                    return (
                      <Select
                        className={errors.artworkFormatId && 'border-danger-select'}
                        classNamePrefix="custom-select-input"
                        id="form__artworkFormatId"
                        isClearable
                        isDisabled={true}
                        maxMenuHeight={175}
                        onBlur={onBlur} //  Notify when input is touched
                        options={artworkFormatsList}
                        placeholder={PLACEHOLDER.SELECT_ARTWORK_FORMAT}
                        ref={ref}
                        value={artworkFormatsList.find(artworkFormat => artworkFormat.value === value)}
                      />
                    );
                  }}
                />
                {errors.artworkFormatId && <Form.Text className="text-danger">{errors.artworkFormatId.message}</Form.Text>}
              </Form.Group>

              {artworkFormatOther && (
                <Form.Group controlId="form__quantity" style={{ gridColumn: '1' }}>
                  <Form.Label>
                    Other Artwork Format<span className="input__required">*</span>
                  </Form.Label>
                  <Form.Control autoComplete="off" className={errors.quantity && 'border-danger'} disabled type="text" {...register('artworkFormatOther')} />
                  {errors.artworkFormatOther && <Form.Text className="text-danger">{errors.artworkFormatOther.message}</Form.Text>}
                </Form.Group>
              )}

              <Form.Group controlId="form__artworkDueDate" style={{ gridColumn: '1' }}>
                <Form.Label>Artwork Due Date</Form.Label>
                <Controller
                  control={control}
                  name="artworkDueDate"
                  render={({ field: { onBlur, onChange, ref, value } }) => (
                    <div
                      className={`form-control picker__container ${[READYTOINVOICE, INVOICED].includes(initialState?.status?.name) ? 'picker__container__disabled' : ''} ${
                        errors.proofDueDate ? 'border-danger' : ''
                      }`}
                    >
                      <DatePicker
                        className="picker__input"
                        dateFormat="dd/MM/yyyy"
                        disabled={[READYTOINVOICE, INVOICED].includes(initialState?.status?.name)}
                        minDate={getMinimumDate(value)}
                        onBlur={onBlur} //  Notify when input is touched
                        onChange={onChange}
                        placeholderText={PLACEHOLDER.SELECT_ARTWORK_DUE_DATE}
                        ref={ref}
                        selected={value ? new Date(value) : null}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        strictParsing
                      />
                      <CalendarIcon />
                    </div>
                  )}
                />
                {errors.artworkDueDate && <Form.Text className="text-danger">{errors.artworkDueDate.message}</Form.Text>}
              </Form.Group>
              <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/4', marginTop: '30px' }}>
                Proofs
              </h6>
              <Form.Group controlId="form__typeOfProofsId" style={{ gridColumn: '1 / 2' }}>
                <Form.Label>
                  Type Of Proofs<span className="input__required">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="typeOfProofsId"
                  render={({ field: { name, onBlur, onChange, ref, value } }) => {
                    return (
                      <Select
                        className={errors.typeOfProofsId && 'border-danger-select'}
                        classNamePrefix="custom-select-input"
                        id="form__typeOfProofsId"
                        isClearable
                        isDisabled={true}
                        maxMenuHeight={175}
                        onBlur={onBlur} //  Notify when input is touched
                        onChange={(val, { action }) => {
                          if (action === 'clear') {
                            onChange(null);
                          } else {
                            onChange(val?.value);
                          }
                          handleSelect(name, val);
                        }} // Send value to hook f = items.find(item => item.value = val);
                        options={typeOfProofsList}
                        placeholder={PLACEHOLDER.SELECT_TYPE_OF_PROOFS}
                        ref={ref}
                        value={typeOfProofsList.find(typeOfProof => typeOfProof.value === value)}
                      />
                    );
                  }}
                />
                {errors.typeOfProofsId && <Form.Text className="text-danger">{errors.typeOfProofsId.message}</Form.Text>}
              </Form.Group>
              {typeOfProofsOther && (
                <Form.Group controlId="form__typeOfProofsOther" style={{ gridColumn: '1 / 3' }}>
                  <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                      Other Type Of Proofs<span className="input__required">*</span>
                    </span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    autoComplete="off"
                    className={errors.typeOfProofsOther && 'border-danger'}
                    disabled
                    maxLength={1000}
                    placeholder={PLACEHOLDER.TYPE_OF_PROOFS_OTHER}
                    type="text"
                    {...register('typeOfProofsOther')}
                  />
                  {errors.typeOfProofsOther && <Form.Text className="text-danger">{errors.typeOfProofsOther.message}</Form.Text>}
                </Form.Group>
              )}
              {showProofOfDueDate && (
                <Form.Group controlId="form__proofDueDate" style={{ gridColumn: '1' }}>
                  <Form.Label>Proof Due Date</Form.Label>
                  <Controller
                    control={control}
                    name="proofDueDate"
                    render={({ field: { onBlur, onChange, ref, value } }) => (
                      <div
                        className={`form-control picker__container ${[READYTOINVOICE, INVOICED].includes(initialState?.status?.name) ? 'picker__container__disabled' : ''} ${
                          errors.proofDueDate ? 'border-danger' : ''
                        }`}
                      >
                        <DatePicker
                          className="picker__input"
                          dateFormat="dd/MM/yyyy"
                          minDate={getMinimumDate(value)}
                          onBlur={onBlur} //  Notify when input is touched
                          onChange={onChange}
                          placeholderText={PLACEHOLDER.SELECT_PROOF_OF_DUE_DATE}
                          ref={ref}
                          selected={value ? new Date(value) : null}
                          shouldCloseOnSelect={true}
                          showYearDropdown
                          strictParsing
                        />
                        <CalendarIcon />
                      </div>
                    )}
                  />
                  {errors.proofDueDate && <Form.Text className="text-danger">{errors.proofDueDate.message}</Form.Text>}
                </Form.Group>
              )}
            </>
            {isCDS && (
              <>
                <Form.Group controlId="form__jobItemOptions" style={{ gridColumn: '1/4' }}>
                  <Form.Label>Job Options</Form.Label>
                  <br />
                  {jobItemOptionsList.map(jobItemOption => (
                    <Form.Check
                      key={jobItemOption.label}
                      id={jobItemOption.label}
                      inline
                      label={jobItemOption.label}
                      type="checkbox"
                      value={jobItemOption.value}
                      checked={jobItemOption?.checked}
                      {...register('jobItemOptions')}
                      onChange={() => handleJobItemChange(jobItemOption)}
                    />
                  ))}
                  {errors.jobItemOptions && <Form.Text className="text-danger">{errors.jobItemOptions}</Form.Text>}
                </Form.Group>
                {targetDate && (
                  <Form.Group controlId="form__targetDate" style={{ gridColumn: '1' }}>
                    <Form.Label>
                      Target Date
                      <span className="input__required">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="targetDate"
                      render={({ field: { onBlur, onChange, ref, value } }) => (
                        <div className={`form-control picker__container ${errors.targetDate ? 'border-danger' : ''}`}>
                          <DatePicker
                            className="picker__input"
                            dateFormat="dd/MM/yyyy"
                            minDate={getMinimumDate(value)}
                            onBlur={onBlur} //  Notify when input is touched
                            onChange={onChange}
                            placeholderText={PLACEHOLDER.SELECT_TARGET_DATE}
                            ref={ref}
                            selected={value ? new Date(value) : null}
                            shouldCloseOnSelect={true}
                            showYearDropdown
                            strictParsing
                          />
                          <CalendarIcon />
                        </div>
                      )}
                    />
                    {errors.targetDate && <Form.Text className="text-danger">{errors.targetDate.message}</Form.Text>}
                  </Form.Group>
                )}
              </>
            )}
            <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/4', marginTop: '30px' }}>
              Notes
            </h6>
            {isCDS && (
              <Form.Group controlId="form__cdsNotes" style={{ gridColumn: '1 / 3' }}>
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>CDS Notes (For Internal Use)</span>
                  <span>{cdsNotesCounter}/1000</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  autoComplete="off"
                  className={errors.cdsNotes && 'border-danger'}
                  maxLength={1000}
                  placeholder={PLACEHOLDER.CDS_NOTES}
                  rows={3}
                  type="text"
                  {...register('cdsNotes')}
                  onChange={event => setCdsNotesCounter(event.target.value.length)}
                />
                {errors.cdsNotes && <Form.Text className="text-danger">{errors.cdsNotes.message}</Form.Text>}
              </Form.Group>
            )}
            {(isCDS || isSupplier) && (
              <Form.Group controlId="form__supplierNotes" style={{ gridColumn: '1 / 3' }}>
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {isSupplier ? <span></span> : <span>Supplier Notes (Can Be Seen By Supplier)</span>}
                  <span>{supplierNotesCounter}/1000</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  autoComplete="off"
                  className={errors.supplierNotes && 'border-danger'}
                  maxLength={1000}
                  placeholder={isSupplier ? ' ' : PLACEHOLDER.SUPPLIER_NOTES}
                  rows={3}
                  type="text"
                  {...register('supplierNotes')}
                  onChange={event => setSupplierNotesCounter(event.target.value.length)}
                />
                {errors.supplierNotes && <Form.Text className="text-danger">{errors.supplierNotes.message}</Form.Text>}
              </Form.Group>
            )}
            {!isSupplier && (
              <Form.Group controlId="form__clientNotes" style={{ gridColumn: '1 / 3' }}>
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {isCDS && <span>Client Notes (Can Be Seen By Client)</span>}
                  {isClient && <span>Notes</span>}
                  <span>{clientNotesCounter}/1000</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  autoComplete="off"
                  className={errors.clientNotes && 'border-danger'}
                  maxLength={1000}
                  placeholder={isCDS ? PLACEHOLDER.CLIENT_NOTES : PLACEHOLDER.NOTES}
                  rows={3}
                  type="text"
                  {...register('clientNotes')}
                  onChange={event => setClientNotesCounter(event.target.value.length)}
                />
                {errors.clientNotes && <Form.Text className="text-danger">{errors.clientNotes.message}</Form.Text>}
              </Form.Group>
            )}
            <Form.Group controlId="form__jobType" style={{ gridColumn: '1 / 2' }}>
              <Form.Label>Job Type</Form.Label>
              <Form.Control autoComplete="off" disabled type="text" value={initialState.productType} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="outline__button"
            onClick={() => {
              setShowConfirmModal(true);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button className="full__button" form="printSpecificationsForm-Quotation" type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <PopupModal
        bodyTitle={'Are you sure? Any changes will be lost'}
        footerButtons={[
          {
            type: OUTLINED,
            name: 'No',
            onClick: () => setShowConfirmModal(false),
          },
          {
            type: FULL,
            name: 'Yes',
            onClick: () => {
              setShowConfirmModal(false);
              onHide();
            },
          },
        ]}
        header={'Confirmation'}
        onHide={() => setShowConfirmModal(false)}
        show={showConfirmModal}
        type={CONFIRMATION}
      />
      <PopupModal
        show={errorModal}
        onHide={() => setErrorModal(false)}
        type={ERROR}
        header={'Error'}
        bodyTitle={'Saving Job Information failed!'}
        footerButtons={[
          {
            type: OUTLINED,
            name: 'Close',
            onClick: () => setErrorModal(false),
          },
        ]}
      />
    </>
  );
};

export default Ordered;
