import getAccessToken from 'services/getAccessToken';

import { msalInstance } from '../index';

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
async function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const responseData = await response.json();
  return responseData;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new ResponseError(response);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(url: string, options: RequestInit = {}) {
  const accessToken = await getAccessToken(msalInstance);
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Content-Type', 'application/json');
  options.headers = headers;
  const fetchResponse = await fetch(url, options);
  const response = checkStatus(fetchResponse);
  return await parseJSON(response);
}
