import React, { useEffect, useState } from 'react';

import { useBreadcrumbSlice } from '../breadcrumb/slice';
import { BusinessUnitInput } from './BusinessUnitInput';
import { Step, Stepper, Box } from '@mui/material';
import { AccountDetails } from './AccountDetails';
import { useInvoiceSlice } from './slice/index';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import Breadcrumb from '../breadcrumb';

export const AddAccounts = () => {
  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  const { actions: accountAction } = useInvoiceSlice();
  const dispatch = useDispatch();

  const [accountDetailsInputs, setAccountDetailsInputs] = useState<any>();
  const [showAccountDetails, setShowAccountDetails] = useState(true);

  const handleAccountDetails = data => {
    setAccountDetailsInputs(data);
    setShowAccountDetails(false);
  };

  useEffect(() => {
    dispatch(accountAction.loadDropDowns());
  }, [accountAction, dispatch]);

  useEffect(() => {
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Dashboard', url: '/dashboard' },
        { name: 'Administration/Account', url: '/administration/account' },
        { name: 'Add Account', url: '#' },
      ]),
    );
  }, [breadcrumbActions, dispatch]);

  return (
    <div>
      <Breadcrumb />
      <div style={{ paddingLeft: '20px' }} className="rfq__container">
        <Box sx={{ margin: '50px', width: '35%' }}>
          <Stepper nonLinear>
            <Step>
              <div>
                <Button style={{ borderRadius: '20px', backgroundColor: `${showAccountDetails ? '#000000' : '#cdcccc'}`, border: '0px' }}>1</Button>
              </div>
            </Step>
            <Step>
              <div>
                <Button style={{ borderRadius: '20px', backgroundColor: `${!showAccountDetails ? '#000000' : '#cdcccc'}`, border: ')px' }}>2</Button>
              </div>
            </Step>
          </Stepper>
          <span style={{ fontWeight: `${showAccountDetails ? '600' : '100'}` }}>Add Account Details</span>
          <span style={{ paddingLeft: '10%', position: 'absolute', fontWeight: `${!showAccountDetails ? '600' : '100'}` }}>Add Business Unit</span>
        </Box>
        {showAccountDetails ? (
          <AccountDetails accountInputs={accountDetailsInputs} onAccountDetails={handleAccountDetails} />
        ) : (
          <BusinessUnitInput accountInputs={accountDetailsInputs} onShowAccountDetails={() => setShowAccountDetails(true)} />
        )}
      </div>
    </div>
  );
};
