export interface ClientUserType {
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
  telephoneNumber: string | null;
  roleId: string | null;
  active: boolean | string | null;
  accountId: number | null;
  businessUnitsId: number[] | null;
  cache: boolean;
  approver: boolean;
  userId?: number;
}

export interface AddClientUserPropType {
  create: boolean;
  onHide: () => void;
  show: boolean;
  clientUser: ClientUserType;
}

export interface BusinessUnit {
  value: number;
  label: string;
  accountId: number;
}

export interface RoleList {
  admin: boolean;
  value: string;
  label: string;
}

export const initialClientUser: ClientUserType = {
  firstName: null,
  lastName: null,
  emailAddress: null,
  telephoneNumber: null,
  roleId: null,
  active: null,
  accountId: null,
  businessUnitsId: null,
  cache: false,
  approver: false,
};
