//  Leaflet Print Specifications
interface LeafletType {
  title: string | null;
  description: string | null;
  reference: string | null;
  relatedLineId: string | null;
  relatedLineValue: number | null;
  quantity: number | null;
  quantityRunOn: number | null;
  quantityActual?: number | null;
  noOfPages: number | null;
  finishedSizeId: number | null;
  finishedSizeOther: string | null;
  coloursOnFaceId: number | null;
  coloursOnFaceOther: string | null;
  coloursOnReverseId: number | null;
  coloursOnReverseOther: string | null;
  sealerOnFace: boolean | string | null;
  sealerOnReverse: boolean | string | null;
  materialId: number | null;
  materialOther: string | null;
  materialWeightId: number | null;
  materialWeightOther: string | null;
  finishingId: number | null;
  finishingOther: string | null;
  addendum: string | null;
  deliveryContact: string | null;
  deliveryAddress: string | null;
  deliveryInstructions: string | null;
  deliveryDueDate: string | Date | null;
  noFailDeliveryDate: boolean;
  samples: boolean | string | null;
  noOfSampleCopies: number | null;
  sampleCopiesDeliveryAddress: string | null;
  artworkFormatId: number | null;
  artworkFormatOther: string | null;
  artworkDueDate: string | Date | null;
  typeOfProofsId: number | null;
  typeOfProofsOther: string | null;
  proofDueDate: string | Date | null;
  primaryCategoryId: number | null;
  secondaryCategoryId: number | null;
  jobItemOptions: number[];
  targetDate: string | Date | null;
  cdsNotes: string | null;
  supplierNotes: string | null;
  clientNotes: string | null;
  jobType: string;
  isActive: boolean;
  status?: {
    code: string;
    id: number;
    name: string;
  };
  managementFee?: number;
  deliveryAddressChangeReasonId: number | null;
  deliveryAddressChangeReasonOther: string | null;
}

//  Initial state of Leaflet product
export const initialLeaflet: LeafletType = {
  title: null,
  description: null,
  reference: null,
  relatedLineId: null,
  relatedLineValue: null,
  quantity: null,
  quantityRunOn: null,
  noOfPages: null,
  finishedSizeId: null,
  finishedSizeOther: null,
  coloursOnFaceId: null,
  coloursOnFaceOther: null,
  coloursOnReverseId: null,
  coloursOnReverseOther: null,
  sealerOnFace: null,
  sealerOnReverse: null,
  materialId: null,
  materialOther: null,
  materialWeightId: null,
  materialWeightOther: null,
  finishingId: null,
  finishingOther: null,
  addendum: null,
  deliveryContact: null,
  deliveryAddress: null,
  deliveryInstructions: null,
  deliveryDueDate: null,
  noFailDeliveryDate: false,
  samples: null,
  noOfSampleCopies: null,
  sampleCopiesDeliveryAddress: null,
  artworkFormatId: null,
  artworkFormatOther: null,
  artworkDueDate: null,
  typeOfProofsId: null,
  typeOfProofsOther: null,
  proofDueDate: null,
  primaryCategoryId: null,
  secondaryCategoryId: null,
  jobItemOptions: [],
  targetDate: null,
  cdsNotes: null,
  supplierNotes: null,
  clientNotes: null,
  jobType: '',
  isActive: false,
  deliveryAddressChangeReasonId: null,
  deliveryAddressChangeReasonOther: null,
};
