//  Externals
import { PayloadAction } from '@reduxjs/toolkit';
//  Internals
import { Entities, initialState, limit, page, sort } from './types';
import { useInjectReducer } from '../../../utils/redux-injectors';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { JOB_INFORMATION } from '../../../data/constants';

const slice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setPage: (entities, action: PayloadAction<{ module: string; page: number }>) => {
      entities[action.payload.module].page = action.payload.page;
    },

    setLimit: (entities, action: PayloadAction<{ module: string; limit: number }>) => {
      entities[action.payload.module].limit = action.payload.limit;
      entities[action.payload.module].page = 1;
    },

    setTotalCount: (entities, action: PayloadAction<{ module: string; totalCount: number }>) => {
      entities[action.payload.module].totalCount = action.payload.totalCount;
    },

    setItems: (entities, action: PayloadAction<{ module: string; items: any[] }>) => {
      entities[action.payload.module].items = action.payload.items;
    },

    setDefaultPagination: (entities, action: PayloadAction<{ module: string }>) => {
      entities[action.payload.module].page = page;
      entities[action.payload.module].sort = sort;
      entities[action.payload.module].limit = limit;
    },

    setDropdowns: (entities, action: PayloadAction<Entities>) => {
      entities[action.payload.module].dropdowns = { ...entities[action.payload.module].dropdowns, ...action.payload };
    },

    setJobData: (entities, action: PayloadAction<Entities>) => {
      entities[action.payload.module].jobInformation = action.payload.jobInformation;
      entities[action.payload.module].productItems = action.payload.productItems
        ? action.payload.productItems.map(productItem => ({
            ...productItem,
            checked: false,
            open: false,
            printSpecifications: {
              ...productItem.printSpecifications,
              isActive: productItem.printSpecifications.isActive ? 'Yes' : 'No',
              laminated: productItem.printSpecifications.laminated ? 'Yes' : 'No',
              samples: productItem.printSpecifications.samples ? 'Yes' : 'No',
              sealerOnCover: productItem.printSpecifications.sealerOnCover ? 'Yes' : 'No',
              sealerOnFace: productItem.printSpecifications.sealerOnFace ? 'Yes' : 'No',
              sealerOnReverse: productItem.printSpecifications.sealerOnReverse ? 'Yes' : 'No',
              sealerOnText: productItem.printSpecifications.sealerOnText ? 'Yes' : 'No',
            },
          }))
        : [];
    },

    setUpdateJobData: (entities, action: PayloadAction<Entities>) => {
      entities[action.payload.module].jobInformation = action.payload.jobInformation;
      entities[action.payload.module].productItems = action.payload.productItems
        ? entities[action.payload.module].productItems.map((productItem, i) => {
            const entity = {
              ...productItem,
              ...action.payload.productItems[i],
              checked: productItem.checked,
              open: productItem.open,
              printSpecifications: {
                ...action.payload.productItems[i].printSpecifications,
                isActive: action.payload.productItems[i].printSpecifications.isActive ? 'Yes' : 'No',
                laminated: action.payload.productItems[i].printSpecifications.laminated ? 'Yes' : 'No',
                samples: action.payload.productItems[i].printSpecifications.samples ? 'Yes' : 'No',
                sealerOnCover: action.payload.productItems[i].printSpecifications.sealerOnCover ? 'Yes' : 'No',
                sealerOnFace: action.payload.productItems[i].printSpecifications.sealerOnFace ? 'Yes' : 'No',
                sealerOnReverse: action.payload.productItems[i].printSpecifications.sealerOnReverse ? 'Yes' : 'No',
                sealerOnText: action.payload.productItems[i].printSpecifications.sealerOnText ? 'Yes' : 'No',
              },
            };
            return entity;
          })
        : [];
    },

    setJobInformation: (entities, action: PayloadAction<Entities>) => {
      entities[action.payload.module].jobInformation = action.payload;
    },

    setProductItems: (entities, action: PayloadAction<Entities>) => {
      entities[action.payload.module].productItems = action.payload.productItems;
    },

    setPrintSpecifications: (entities, action: PayloadAction<Entities>) => {
      const { module, printSpecifications } = action.payload;
      printSpecifications.isActive = action.payload.printSpecifications.isActive ? 'Yes' : 'No';
      printSpecifications.laminated = action.payload.printSpecifications.laminated ? 'Yes' : 'No';
      printSpecifications.samples = action.payload.printSpecifications.samples ? 'Yes' : 'No';
      printSpecifications.sealerOnCover = action.payload.printSpecifications.sealerOnCover ? 'Yes' : 'No';
      printSpecifications.sealerOnFace = action.payload.printSpecifications.sealerOnFace ? 'Yes' : 'No';
      printSpecifications.sealerOnReverse = action.payload.printSpecifications.sealerOnReverse ? 'Yes' : 'No';
      printSpecifications.sealerOnText = action.payload.printSpecifications.sealerOnText ? 'Yes' : 'No';
      const entity = entities[module].productItems.find(product => product.printSpecifications.templateId === printSpecifications.templateId);
      if (entity) {
        entities[module].productItems = entities[module].productItems.map(product => {
          if (product.printSpecifications.templateId === printSpecifications.templateId) {
            return {
              ...product,
              printSpecifications: printSpecifications,
              ...(printSpecifications.priceSummary !== null &&
                printSpecifications.runonSummary !== null && {
                  clientQuotations: { ...product.clientQuotations, priceSummary: printSpecifications.priceSummary, runonSummary: printSpecifications.runonSummary },
                }),
            };
          }
          return product;
        });
      } else {
        entities[module].productItems = [...entities[module].productItems, { checked: false, open: false, printSpecifications: { ...action.payload.printSpecifications } }];
      }
    },

    changeNavigation: (entities, action: PayloadAction<Entities>) => {
      for (const key in entities[action.payload.module].navigation) {
        if (action.payload.key === key) {
          entities[action.payload.module].navigation[key] = { ...entities[action.payload.module].navigation[key], selected: true };
        } else {
          entities[action.payload.module].navigation[key] = { ...entities[action.payload.module].navigation[key], selected: false };
        }
      }
    },

    setJobInformationNavigation: (entities, action: PayloadAction<Entities>) => {
      for (const key in initialState[action.payload.module].navigation) {
        if (key === JOB_INFORMATION) {
          entities[action.payload.module].navigation[key] = { selected: true, visible: true };
        } else {
          entities[action.payload.module].navigation[key] = { selected: false, visible: false };
        }
      }
    },

    showFullNavigation: (entities, action: PayloadAction<Entities>) => {
      for (const key in entities[action.payload.module].navigation) {
        entities[action.payload.module].navigation[key] = { ...entities[action.payload.module].navigation[key], visible: true };
      }
    },

    resetNavigation: (entities, action: PayloadAction<Entities>) => {
      entities[action.payload.module].navigation = initialState[action.payload.module].navigation;
    },

    setActionToggleState: (entities, action: PayloadAction<Entities>) => {
      entities[action.payload.module].actionsToggle = action.payload.actionToggle;
    },

    setIsAddPoAction: (entities, action: PayloadAction<Entities>) => {
      entities[action.payload.module] = action.payload.isAddPoAction;
    },
  },
});

export default slice.reducer;

export const {
  setPage,
  setLimit,
  setTotalCount,
  setItems,
  setDefaultPagination,
  setDropdowns,
  setJobData,
  setUpdateJobData,
  setJobInformation,
  setPrintSpecifications,
  setProductItems,
  changeNavigation,
  setJobInformationNavigation,
  showFullNavigation,
  resetNavigation,
  setActionToggleState,
  setIsAddPoAction,
} = slice.actions;

export const useEntitiesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
