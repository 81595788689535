//  Externals
import React, { Fragment, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
//  Internals
import { selectPrintSpecifications, selectVatRate, selectMaterial } from './slice/selectors';
import GetProductItem from './../../components/modules/myJobs/productItems/GetProductItem';
import LoadingSpinnerWithBackdrop from 'app/components/shared/LoadingSpinnerWithBackdrop';
import { getMasterData } from '../../../store/reducers/masterData/selectors';
import { ReactComponent as EditIcon } from '../../../images/edit.svg';
import { getRowsOfTextArea } from '../../../utils/form/index';
import { protectedResources } from 'configs/msalAuthConfigs';
import CreateQuote from './modal/CreateQuote';
import { request } from 'utils/request';
import Decline from './modal/Decline';
import './style.sass';
import { getStatusData } from '../../../store/reducers/statusList/selectors';

const RFQDetails = props => {
  const { disabled, jobQuoteId, productType, saveFlag, status, statusCode, title, id, statusName, quoteStatusName } = props;
  const getStatus = useSelector(getStatusData);
  const [selectedQuote, setSelectedQuote] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const vatRateList = useSelector(selectVatRate);
  const merchantMaterial = useSelector(selectMaterial);
  const masterDataDecline = useSelector(getMasterData('supplierQuoteDeclineReasons'));
  const handleOnSubmit = event => {
    event.preventDefault();
    const payload = {
      inputs: {},
      jobQuoteId,
    };
    try {
      request(`${protectedResources.api.apiEndpoint}/Quotes/UpdateSupplierQuote/${jobQuoteId}`, {
        method: 'PUT',
        body: JSON.stringify(payload.inputs),
      });
    } catch (responseError) {
      console.error('Error while submitting the form ' + responseError);
    }
  };

  const specifications = useSelector(selectPrintSpecifications);
  const [modalShow, setModalShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [declineShow, setDeclineShow] = useState(false);

  const cancellationReasonId = specifications?.map(item => item?.cancellationReasonId).pop();
  const cancellationReasonObject = masterDataDecline?.filter(item => cancellationReasonId && cancellationReasonId === item?.value);
  const cancellationReason = cancellationReasonObject?.map(item => item?.label);

  return (
    <>
      {specifications.map(
        (item, i) =>
          item.jobQuoteId === jobQuoteId && (
            <div>
              <table style={{ width: '300px' }} className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Job Number</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item?.jobNumber}</td>
                  </tr>
                </tbody>
              </table>
              <table style={{ width: '300px' }} className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Line ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item?.jobLineId}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ),
      )}
      {cancellationReason && cancellationReason.length > 0 ? (
        specifications.map(
          (item, i) =>
            item.jobQuoteId === jobQuoteId &&
            item.cancellationReasonId !== null && (
              <>
                <Form.Group className="mb-3" controlId="cancelationReason">
                  <Form.Label>Reason For Declining</Form.Label>
                  <Form.Control
                    disabled
                    as="textarea"
                    rows={3}
                    value={item?.cancellationReasonOther ? item?.cancellationReasonOther : masterDataDecline.find(row => row.value == item.cancellationReasonId)?.label}
                    className="notes_supplier"
                    placeholder="Enter here"
                    name="cancelationReason"
                  />
                </Form.Group>
                <div className="readOnlyForm__header">
                  <span className="title_supp">Print Specifications</span>
                </div>
                <GetProductItem
                  create={false}
                  disabled={true}
                  formId="printSpecificationsForm"
                  initialState={item}
                  jobId={item.rfqjobId}
                  onHide={() => {}}
                  productId={item.productId}
                  productType={productType}
                />
              </>
            ),
        )
      ) : (
        <>
          {loading && <LoadingSpinnerWithBackdrop color="grey" />}
          <CreateQuote
            status={status}
            statusCode={statusCode}
            isEdit={isEdit}
            show={modalShow}
            saveFlag={saveFlag}
            data={selectedQuote}
            onHide={setModalShow}
            jobQuoteId={jobQuoteId}
            rowId={id}
            id={productType}
            quantityRunOn={specifications.find(specification => specification.jobQuoteId === jobQuoteId)?.quantityRunOn}
            title={title}
            materialRebate={specifications.find(specification => specification.jobQuoteId === jobQuoteId)?.materialRebate}
            productType={productType}
          />
          <Decline show={declineShow} onHide={setDeclineShow} jobQuoteId={jobQuoteId} title={title} />
          {(statusCode === 'Quoted' || saveFlag || ['Order', 'Quoted', 'Dispatched', 'Declined'].includes(statusCode)) && (
            <div className="form_styling">
              {specifications.map(
                (item, i) =>
                  item.jobQuoteId === jobQuoteId && (
                    <Fragment key={i}>
                      {quoteStatusName === 'New RFQ' && (
                        <div className="edit">
                          <p
                            onClick={() => {
                              setSelectedQuote({ ...item });
                              setIsEdit(true);
                              setModalShow(true);
                            }}
                          >
                            Edit
                            <EditIcon className="svg__icon" />
                          </p>
                        </div>
                      )}
                      {item.jobQuoteId === item.productJobquoteId && item.status.code === 'Quoted' && <span>Quote successful - awaiting client approval.</span>}
                      {item.productJobquoteId != null && item.status.code === 'Quoted' && item.jobQuoteId !== item.productJobquoteId && <span>Quote Unsuccessful</span>}
                      <Form onSubmit={e => handleOnSubmit(e)} key={i}>
                        {item.quoteInstructions?.length && (
                          <Form.Group controlId="form__quoteInstructions" style={{ gridColumn: '1 / 3' }}>
                            <Form.Label className="title_supp" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              Quote Instructions
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              autoComplete="off"
                              disabled
                              rows={getRowsOfTextArea(item.quoteInstructions?.length, item.quoteInstructions?.split('\n').length)}
                              type="text"
                              value={item.quoteInstructions}
                              name="quoteInstructions"
                            />
                          </Form.Group>
                        )}

                        <h6 style={{ gridColumn: '1/4', marginTop: '30px' }}>Quotation</h6>
                        {item?.supplierReference?.length > 0 && (
                          <Form.Group className="mb-3 width_quotation" controlId="supplierReference">
                            <Form.Label className="title_supp">Supplier Reference</Form.Label>
                            <Form.Control disabled placeholder="Enter here" value={item.supplierReference} name="supplierReference" />
                          </Form.Group>
                        )}
                        {['NewRFQ', 'Quoted', 'Declined'].includes(statusCode) ? (
                          <></>
                        ) : (
                          <div>
                            <br></br>
                            <table style={{ width: '400px' }} className="table">
                              <thead className="thead-light">
                                <tr>
                                  <th>PO number</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{item.supplierPo ? item.supplierPo : '0.00'}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )}
                        <br></br>
                        <div>
                          <span className="title_supp">Pricing Information</span>
                          <br></br>
                          <div>
                            <table className="table_information table">
                              <thead className="thead-light">
                                <tr>
                                  <th className="text-end">Production (£)</th>
                                  <th className="text-end">Material (£)</th>
                                  <th className="text-end">Delivery (£)</th>
                                  <th className="text-end">Total (£)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-end">{item.productionRate?.toFixed(2)}</td>
                                  <td className="text-end">{item.materialRate?.toFixed(2)}</td>
                                  <td className="text-end">{item.deliveryRate?.toFixed(2)}</td>
                                  <td className="text-end">{(item.productionRate + item.materialRate + item.deliveryRate)?.toFixed(2)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br></br>
                        {item.productionRunon != null || item.materialRunon != null || item.deliveryRunon != null ? (
                          <div>
                            <span className="title_supp">Runon Information</span>
                            <div>
                              <table className="table_information table">
                                <thead className="thead-light">
                                  <tr>
                                    <th className="text-end">Production Runon(&#163;)</th>
                                    <th className="text-end">Material Runon(&#163;)</th>
                                    <th className="text-end">Delivery Runon(&#163;)</th>
                                    <th className="text-end">Total Runon(&#163;)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="text-end">{item.productionRunon?.toFixed(2)}</td>
                                    <td className="text-end">{item.materialRunon?.toFixed(2)}</td>
                                    <td className="text-end">{item.deliveryRunon?.toFixed(2)}</td>
                                    <td className="text-end">{(item.productionRunon + item.materialRunon + item.deliveryRunon)?.toFixed(2)}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : null}
                        <br />

                        <p className="title_supp">Vat Rate</p>
                        <p className="mb-4">{item.vatRateId != null ? vatRateList?.find(e => e.id === item.vatRateId)?.name : '-'}</p>

                        <div>
                          <>
                            {['Dispatched', 'Order'].includes(statusCode) && (
                              <>
                                <br />
                                <div>
                                  <span className="title_supp">Actual Summary Information</span>
                                  <div>
                                    <table className="table_information table">
                                      <thead className="thead-light">
                                        <tr>
                                          <th>Quantity</th>
                                          <th className="text-end">Net Amount</th>
                                          <th className="text-end">VAT</th>
                                          <th className="text-end">Gross Amount</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{item?.quantityActual ? item?.quantityActual : '0.00'}</td>
                                          <td className="text-end">{item?.netAmount ? item?.netAmount?.toFixed(2) : '0.00'}</td>
                                          <td className="text-end">{item?.vatAmount ? item?.vatAmount : '0.00'}</td>
                                          <td className="text-end">{item?.grossAmount ? item?.grossAmount.toFixed(2) : '0.00'}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <br />
                              </>
                            )}
                          </>
                          {item?.discountSummary?.discountedValue !== null && item?.discountSummary?.discountedValue !== 0 && (
                            <div>
                              <span className="title_supp">Discounts</span>
                              <div>
                                <table className="table_information table">
                                  <thead className="thead-light">
                                    <tr>
                                      <th className="text-end pe-2">Original Amount</th>
                                      <th className="text-end pe-3">Discounted Amount</th>
                                      <th className="pe-2">Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <td className="text-end pe-2">{item?.discountSummary?.originalCost && item?.discountSummary?.originalCost?.toFixed(2)}</td>
                                    <td className="text-end pe-3">{item?.discountSummary?.discountedValue && item?.discountSummary?.discountedValue?.toFixed(2)}</td>
                                    <td className="pe-2">{item?.discountSummary?.approvedDateTime && moment(item?.discountSummary?.approvedDateTime).format('DD/MM/YYYY')}</td>
                                  </tbody>
                                </table>
                              </div>
                              <br />
                            </div>
                          )}
                        </div>
                        <div>
                          <span className="title_supp">Material Information</span>
                          <br></br>
                          <div>
                            <table className="table_information table">
                              <thead className="thead-light">
                                <tr>
                                  <th>Material Supplier</th>
                                  <th>Material Quantity</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{merchantMaterial?.find(e => e.id === item.materialSupplierId)?.name}</td>
                                  <td>{item.materialQuantity}</td>
                                </tr>
                              </tbody>
                            </table>
                            {item.merchantSupplier?.toLowerCase().trim() === 'other' ? (
                              <span className="material__information__span text-danger">
                                As you have selected use of a non-CDS materials merchant you may be liable to £{((Number(item?.materialRebate) * item?.materialRate) / 100)?.toFixed(2)} charge if the
                                order is accepted.
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <br></br>
                        {item?.material != null && (
                          <Form.Group controlId="form__material" className="mb-3" style={{ gridColumn: '1 / 3' }}>
                            <Form.Label className="title_supp" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              Material
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              autoComplete="off"
                              disabled
                              rows={getRowsOfTextArea(item.material?.length, item.material?.split('\n').length)}
                              type="text"
                              value={item.material}
                              name="material"
                            />
                          </Form.Group>
                        )}
                        <div>
                          <span className="title_supp">Schedule Information</span>
                          <br></br>
                          <div>
                            <table className="table_information table">
                              <thead className="thead-light">
                                <tr>
                                  <th>Proof Days</th>
                                  <th>Delivery Days</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{item.proofDays}</td>
                                  <td>{item.deliveryDays}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br></br>
                        {item?.supplierQuoteNotes?.length > 0 && (
                          <Form.Group controlId="form__notes" className="mb-3" style={{ gridColumn: '1 / 3' }}>
                            <Form.Label className="title_supp" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              Notes
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              autoComplete="off"
                              disabled
                              rows={getRowsOfTextArea(item.supplierQuoteNotes?.length, item.supplierQuoteNotes?.split('\n').length)}
                              type="text"
                              value={item.supplierQuoteNotes}
                              name="notes"
                            />
                          </Form.Group>
                        )}
                        <div className="row date_time">
                          <div className="col">
                            <Form.Group className="mb-3" controlId="quoteDueDateTime">
                              <span className="title_supp">Quote Due Date & Time</span>
                              <Form.Control
                                disabled
                                type="text"
                                plaintext
                                value={item.dueDate != null ? `${moment(item.dueDate).format('DD/MM/YYYY')}, ${item.dueTime.split(':').splice(0, 2).join(':')}` : ''}
                                placeholder="Enter here"
                                name="quoteDueDateTime"
                              />
                            </Form.Group>
                          </div>
                          <div className="col">
                            <Form.Group className="mb-3" controlId="quoteDate">
                              <span className="title_supp">Quote Date</span>
                              <Form.Control
                                disabled
                                type="text"
                                value={item.quoteDate != null ? `${moment(item.quoteDate).format('DD/MM/YYYY')}, ${item.quoteTime.split(':').splice(0, 2).join(':')}` : ''}
                                plaintext
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </Form>
                    </Fragment>
                  ),
              )}
            </div>
          )}
          {specifications.map(
            (element, i) =>
              element.jobQuoteId === jobQuoteId && (
                <div key={i}>
                  <Form id={`${disabled ? 'disabled={disabled}PrintSpecificationsForm' : ''}`} className="printSpecifications__form">
                    {element?.podDate != null && element?.podDate != undefined && (
                      <>
                        <span className="title_supp">Proof of Delivery details</span>
                        <Form.Group className="mb-3" controlId="title" style={{ gridColumn: '1' }}>
                          <Form.Label
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span className="title_supp">Proof of Delivery date</span>
                          </Form.Label>
                          <Form.Control disabled={disabled} value={element.podDate != null ? `${moment(element?.podDate).format('DD/MM/YYYY')}` : ''} type="string" plaintext />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="title" style={{ gridColumn: '2 / 3' }}>
                          <Form.Label
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span className="title_supp">Proof of Delivery reference</span>
                          </Form.Label>
                          <Form.Control disabled={disabled} value={element.podReference} type="string" plaintext />
                        </Form.Group>
                      </>
                    )}

                    {!saveFlag && statusCode !== 'Quoted' && element.quoteInstructions?.length && (
                      <Form.Group className="mb-3" style={{ gridColumn: '1 / 3' }} controlId="quoteInstructions">
                        <Form.Label
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span className="title_supp">Quote Instruction</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={disabled ? getRowsOfTextArea(element.quoteInstructions?.length, element.quoteInstructions?.split('\n').length) : 3}
                          value={element.quoteInstructions}
                          disabled={disabled}
                        />
                      </Form.Group>
                    )}
                  </Form>
                  <div>
                    <p className="title_supp mb-4">Print Specifications</p>
                  </div>
                  <GetProductItem
                    create={false}
                    disabled={true}
                    formId="printSpecificationsForm"
                    initialState={element}
                    jobId={element.rfqjobId}
                    onHide={() => {}}
                    productId={element.productId}
                    productType={productType}
                  />
                </div>
              ),
          )}
          {statusCode === 'NewRFQ' && (
            <div className="createQuote_button">
              <Button
                variant="outline-danger"
                style={{ paddingRight: '2rem', paddingLeft: '2rem' }}
                className="btn btn-outline-danger button_spacing"
                onClick={() => {
                  setDeclineShow(true);
                }}
              >
                Decline
              </Button>
              {!saveFlag && (
                <Button
                  variant="outline-primary"
                  style={{ paddingRight: '2rem', paddingLeft: '2rem' }}
                  onClick={() => {
                    setIsEdit(false);
                    setModalShow(true);
                  }}
                  className="full__button button_spacing"
                >
                  Create Quote
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RFQDetails;
