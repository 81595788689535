import { otherCosts } from './../../../app/pages/requestForQuote/slice/types';
//  Externals
import { createSelector } from '@reduxjs/toolkit';
//  Internals
import { Entities, Entity } from './types';
import { RootState } from '../../model';

export const getEntities = (module: string) =>
  createSelector(
    (state: RootState): Entities => state.entities,
    (entities: Entities): Entity => entities[module],
  );

export const getItems = (module: string) =>
  createSelector(
    (state: RootState) => state.entities,
    entities => entities[module].items,
  );

export const getDropDowns = (module: string, key: string) =>
  createSelector(
    (state: RootState) => state.entities,
    entities => entities[module].dropdowns[key],
  );

export const getJobInformation = (module: string) =>
  createSelector(
    (state: RootState) => state.entities,
    entities => entities[module].jobInformation,
  );

export const getProductItems = (module: string) =>
  createSelector(
    (state: RootState) => state.entities,
    entities => entities[module].productItems,
  );

export const getNavigation = (module: string) =>
  createSelector(
    (state: RootState) => state.entities,
    entities => entities[module].navigation,
  );

export const getActionToggle = (module: string) =>
  createSelector(
    (state: RootState) => state.entities,
    entities => entities[module].actionsToggle,
  );

export const getAddPoAction = (module: string) =>
  createSelector(
    (state: RootState) => state.entities,
    entities => entities[module],
  );
