import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { dashboardActions as actions } from './';
import { dashboardCard } from './types';

function* getDashboard(action) {
  const requestURL = `${protectedResources.api.apiEndpoint}/Dashboard/${action.payload.url}`;
  try {
    const dashboard: dashboardCard = yield call(request, requestURL);
    if (dashboard) {
      yield put(actions.dashboardLoaded(dashboard));
    } else {
      yield put(
        actions.dashboardError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dashboardError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dashboardError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* getFilterByList(action) {
  const requestURL = `${protectedResources.api.apiEndpoint}/${action.payload.url}`;
  try {
    const selectFilterList: any = yield call(request, requestURL);
    if (selectFilterList) {
      yield put(actions.selectFilterListLoaded({ list: selectFilterList, name: action.payload.name }));
    } else {
      yield put(
        actions.dashboardError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dashboardError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dashboardError({ error: 'Issue with api', loading: false }));
    }
  }
}

export function* dashboardSaga() {
  yield takeLatest(actions.loadDashboard.type, getDashboard);
  yield takeLatest(actions.loadSelectFilterList.type, getFilterByList);
}
