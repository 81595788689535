import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import { request } from 'utils/request';
import { useDispatch } from 'react-redux';
import { protectedResources } from '../../../configs/msalAuthConfigs';
import { ReactComponent as CalendarIcon } from 'images/calendar.svg';
import { useCreateQuoteSlice } from './slice';
import { useLocation } from 'react-router-dom';

export interface proofOfDelivery {
  productTemplateId: [];
  rfqJobId: number;
  podDate: string;
  podReference: string;
}
export const initialData: proofOfDelivery = {
  productTemplateId: [],
  podDate: '',
  podReference: '',
  rfqJobId: 0,
};
export const ProofOfDeliveryPopUp = props => {
  const location = useLocation();
  const [initialState, setInitialState] = useState({
    podDate: new Date(),
    podReference: '',
    rfqJobId: 0,
  });
  const validationSchema = yup.object({
    // podDate: yup.string().trim().nullable().required('Please enter Pod Date'),
    podReference: yup.string().trim().nullable().required('Please enter comments'),
  });
  const dispatch = useDispatch();
  const { actions: createQuoteActions } = useCreateQuoteSlice();
  const { show, onHide, rfqJobId, productTemplateId } = props;

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialData,
  });

  const onSubmit = async (data: any) => {
    const formData = {
      podReference: data.podReference,
      podDate: data.podDate && JSON.stringify(data.podDate).replace(/"/g, ''),
      rfqJobId,
      productTemplateIds: [productTemplateId],
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Quotes/ProofOfDelivery/${rfqJobId}`, {
        method: 'PUT',
        body: JSON.stringify(formData),
      });
      if (location?.pathname === '/orders') {
        dispatch(createQuoteActions.loadRFQsList({ order: true }));
      } else {
        dispatch(createQuoteActions.loadRFQsList({ order: false }));
      }
      onHide();
    } catch (err) {
      console.log(err);
    }
    reset();
  };
  return (
    <Modal backdrop="static" keyboard={false} show={show} aria-labelledby="contained-modal-title-vcEnter" backdropClassName="fullScreen" centered onHide={() => onHide()}>
      <Modal.Header style={{ border: '0px' }} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Proof of delivery</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: '20px', marginRight: '20px' }}>
        <Form onSubmit={handleSubmit(onSubmit)} id="pod__form">
          <Form.Group className="mb-3">
            <Form.Label>Proof Of Delivery Date</Form.Label>
            <Controller
              control={control}
              name="podDate"
              render={({ field: { onChange, onBlur, value } }) => (
                <div className={`form-control picker__container `}>
                  <DatePicker
                    className="picker__input"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Date"
                    maxDate={new Date()}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    shouldCloseOnSelect={true}
                  />
                  <CalendarIcon />
                </div>
              )}
            />
          </Form.Group>
          <Form.Group controlId="form__podReference" style={{ gridColumn: '1' }}>
            <Form.Label>POD Reference</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="POD Reference" className={errors.podReference && 'border-danger'} {...register('podReference')} />
            {errors.podReference && <Form.Text className="text-danger">{errors.podReference.message}</Form.Text>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ paddingBottom: '0px' }}>
        <Button style={{ border: '0px', color: 'black', width: '20%' }} variant="outline-primary" onClick={() => onHide()}>
          Cancel
        </Button>
        <Button variant="outline-primary" form="pod__form" style={{ border: '0px', color: 'black', width: '20%' }} type="submit">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
