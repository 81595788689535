//  Externals
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useNavigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
//  Internals
import {
  selectModules,
  selectFirstName,
  selectLastName,
  getRoleName,
  selectError,
  selectIsLoading,
  selectNotificationCountModules,
  selectAdministrationSubMenu,
  selectProfileImageUrl,
  getIsCDS,
} from './slice/selectors';
import { useStatusListSlice } from './../../../../store/reducers/statusList/index';
import { useEntitiesSlice } from './../../../../store/reducers/entities/index';
import { ReactComponent as CDSLogo } from '../../../../assets/brand/logo.svg';
import { setMasterData } from '../../../../store/reducers/masterData/index';
import { useMasterDataSlice } from '../../../../store/reducers/masterData';
import { setStatusList } from '../../../../store/reducers/statusList';
import { useLoadingSlice } from '../../../../store/reducers/loading';
import LoadingSpinner from '../../navigation/LoadingSpinner';
import { useUserDataSlice } from 'store/reducers/userData';
import { ErrorText } from '../../shared/ErrorText';
import LogoutModal from '../../logout/LogoutModal';
import renderIcon from '../../shared/renderIcon';
import { userInfo } from './slice/selectors';
import { useAuthSlice } from './slice/index';
import API from '../../../../data/api';
import URL from '../../../../data/url';
import './style.sass';
import { useMsal } from '@azure/msal-react';
import { msalConfig } from '../../../../configs/msalAuthConfigs';

const SideBar = () => {
  /*  Loading store -- start */
  useMasterDataSlice();
  useStatusListSlice();
  useEntitiesSlice();
  /*  Loading store -- end */
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const isCDS = useSelector(getIsCDS);

  const { actions: loadingActions } = useLoadingSlice();
  const { actions: userDataActions } = useUserDataSlice();
  const { actions } = useAuthSlice();

  useEffect(() => {
    dispatch(loadingActions.resetLoading());
    dispatch(actions.loadMenu());
    dispatch(loadingActions.apiCallBegan({ url: API.GET_STATUS_LIST, method: 'GET', onSuccess: setStatusList.type }));
    dispatch(loadingActions.apiCallBegan({ url: API.GET_MASTER_DATA, method: 'GET', onSuccess: setMasterData.type }));
    dispatch(userDataActions.loadUserData());
  }, [actions, dispatch, loadingActions, userDataActions]);

  const changeNotificationCount = () => {
    dispatch(actions.loadNotificationCounts());
  };

  const loading = useSelector(selectIsLoading);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const roleName = useSelector(getRoleName);
  const modules = useSelector(selectModules);

  const notificationModules = useSelector(selectNotificationCountModules);
  const administrationSubMenu = useSelector(selectAdministrationSubMenu);
  const profileImageUrl = useSelector(selectProfileImageUrl);
  const error = useSelector(selectError);
  const userInformation = useSelector(userInfo);
  const navigate = useNavigate();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const expandSubMenu = (event, menuName) => {
    if (menuName === 'Administration') {
      event.preventDefault();
      setShowSubMenu(prevState => !prevState);
    } else setShowSubMenu(false);
  };

  const navigateUrl = url => {
    navigate(url);
  };

  return (
    <div className="menu-block">
      <div className="top__menuContainer">
        <div className="logo-block">
          <NavLink to="/" className="logo">
            <CDSLogo width={'65px'} className="svg__icon" />
          </NavLink>
          <span>Welcome to CDS Print Portal</span>
        </div>
        {(userInformation.isCDS || userInformation.isClient) && (
          <button
            className="btn btn-request"
            data-bs-toggle="pill"
            data-bs-target="#pills-request-for-quote"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            onClick={() => {
              changeNotificationCount();
              navigateUrl(URL.REQUEST_FOR_QUOTE);
            }}
          >
            Request for quote
          </button>
        )}
        {loading && (
          <div style={{ position: 'absolute', top: '50%', width: '100%' }}>
            <LoadingSpinner color="#fff" />
          </div>
        )}
        {modules.length > 0 ? (
          <ul className="menu-list" role="tablist">
            {modules.map(
              menuitem =>
                !['/rfq', '/profile'].includes(menuitem.url.toLowerCase()) && (
                  <li key={menuitem.moduleName}>
                    <NavLink to={menuitem.url} className="main__menu" onClick={event => expandSubMenu(event, menuitem.moduleName)}>
                      <div className="main__menuLeft">
                        <div className="menu__highlight"></div>
                        <div className="img__container">{renderIcon(menuitem.moduleName)}</div>
                        <span className="nav-link">{menuitem.moduleName}</span>
                      </div>
                      {/* {(menuitem.moduleName === 'Unallocated RFQ' || menuitem.moduleName === 'My Jobs' || menuitem.moduleName === 'Notifications') && (
                        <>
                          {notificationModules.filter(item => item.moduleName === menuitem.moduleName)[0].notificationCount > 0 && (
                            <div className="notification__badge">{notificationModules.filter(item => item.moduleName === menuitem.moduleName)[0].notificationCount}</div>
                          )}
                        </>
                      )} */}
                      {menuitem.moduleName === 'Administration' && (showSubMenu ? <KeyboardArrowDownIcon className="submenu__arrow" /> : <KeyboardArrowRightIcon className="submenu__arrow" />)}
                    </NavLink>
                    {menuitem.moduleName === 'Administration' && showSubMenu && (
                      <div className="sub__menu">
                        {administrationSubMenu?.map(menu => (
                          <NavLink key={menu.moduleName} to={`${menuitem.url}${menu.url}`} className="nav-link">
                            {menu.moduleName}
                          </NavLink>
                        ))}
                      </div>
                    )}
                  </li>
                ),
            )}
          </ul>
        ) : error ? (
          <ErrorText>{error}</ErrorText>
        ) : null}
      </div>
      <div className="bottom__menu">
        <div className="menu__line"></div>
        {error ? (
          <ErrorText>{error}</ErrorText>
        ) : (
          <>
            {firstName && (
              <div className="bottom__menuContainer">
                <div className="profile__menu">
                  <div className="menu__highlight"></div>
                  <div className="img__container">
                    {profileImageUrl.length > 0 ? (
                      <img src={profileImageUrl} alt="avatar" className="avatar" />
                    ) : (
                      <div className="profile__name">{firstName?.charAt(0).toUpperCase().concat(lastName?.charAt(0).toUpperCase())}</div>
                    )}
                  </div>
                  <span className="">
                    {firstName} {lastName}
                    <br />
                    <p>{roleName}</p>
                  </span>
                  {/* <KeyboardArrowDownIcon className="profile__arrow" /> */}
                </div>
              </div>
            )}
            <div className="top__menuContainer">
              <ul className="menu-list">
                <li>
                  <NavLink to="/profile">
                    <div className="main__menu">
                      <div className="main__menuLeft">
                        <div className="menu__highlight"></div>
                        <div className="img__container"></div>
                        <span className="nav-link">My Profile</span>
                      </div>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <div className="main__menu" onClick={() => setShowLogoutModal(true)} style={{ cursor: 'pointer' }}>
                    <div className="main__menuLeft">
                      <div className="menu__highlight"></div>
                      {/* <div className="img__container">
                          <LogoutOutlinedIcon className="svg__icon" style={{ color: '#8C8B8C' }} />
                        </div> */}
                      <span className="nav-link">Logout</span>
                    </div>
                  </div>
                </li>
                {!isCDS && (
                  <li>
                    <div
                      className="main__menu"
                      onClick={() => instance.loginRedirect({ scopes: [process.env.REACT_APP_SCOPES || ''], authority: msalConfig.auth.changePassword })}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="main__menuLeft">
                        <div className="menu__highlight"></div>
                        <span className="nav-link">Change Password</span>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
      <LogoutModal show={showLogoutModal} onHide={() => setShowLogoutModal(false)} />
    </div>
  );
};

export default SideBar;
