import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getOtherJobCostTableList, selectConfirmClientOrder, selectConfirmClientOrderPODetails } from '../slice/selectors';
import { OrderDetailsTable } from './OrderDetailsTable';
import PODetails from './PODetails';
import './style.sass';
import { request } from 'utils/request';
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestForQuoteSlice } from '../slice';
import { getActionToggle, getJobInformation } from 'store/reducers/entities/selectors';
import { selectProductItemDetailsRows } from '../slice/selectors';
import MODULES from '../../../../data/modules';
import API from '../../../../data/api';
import { getProductItems } from '../../../../store/reducers/entities/selectors';
import { apiCallBegan } from 'store/reducers/loading';
import { setUpdateJobData } from 'store/reducers/entities';
import { isEmpty } from 'lodash';
import Status from 'data/status';
import LoadingSpinnerWithBackdrop from 'app/components/shared/LoadingSpinnerWithBackdrop';
import { getAddPoAction } from 'store/reducers/entities/selectors';

const ProductOrderConfirmation = props => {
  const [oderDetails, setOrderDetails] = useState<{
    currentOrder: any[];
    newOrder: any[];
    orderTotal: number;
    currentOtherCost: any[] | null;
    newOrderOtherCost: any[] | null;
  }>({ currentOrder: [], newOrder: [], orderTotal: 0, currentOtherCost: null, newOrderOtherCost: null });
  const confirmOrder = useSelector(selectConfirmClientOrder);
  const otherCostList = useSelector(getOtherJobCostTableList);
  const jobInfo = JSON.parse(JSON.stringify(useSelector(getJobInformation(MODULES.MY_JOBS))));
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));
  const poDetails = useSelector(selectConfirmClientOrderPODetails);
  const isAddPoAction = useSelector(getAddPoAction(MODULES.IS_ADD_PO_ACTION));
  const [isValidConfirmBtn, setIsValidConfirmBtn] = useState(false);
  const [poFromJobInformation, setPoFromJobInformation] = useState<any>([]);
  const params = useParams();
  const { actions } = useRequestForQuoteSlice();
  const dispatch = useDispatch();
  const productItems = useSelector(getProductItems(MODULES.MY_JOBS));
  const checkedProductItems = productItems.filter(productItem => productItem.checked);
  const checkedProductItemsIds = checkedProductItems.map(productItem => productItem.printSpecifications.templateId);
  const productItemDetails = useSelector(selectProductItemDetailsRows);
  const productItemDetailsCheckedList = productItemDetails.filter(item => checkedProductItemsIds.includes(item.id));

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    confirmOrder?.details?.poValueAvailable == false &&
    poDetails &&
    poDetails?.length > 0 &&
    poDetails.filter(item => item.poValue !== 0)?.length > 0 &&
    poDetails.filter(item => item.valid)?.length > 0
      ? setIsValidConfirmBtn(poDetails.filter(item => item.valid == true)?.length == poDetails?.length ? true : false)
      : confirmOrder && confirmOrder?.details && setIsValidConfirmBtn(confirmOrder?.details?.poValueAvailable);
  }, [confirmOrder]);

  function roundNumber(number, decimals) {
    // eslint-disable-next-line no-new-wrappers
    var newnumber = new Number(number + '').toFixed(parseInt(decimals));
    return parseFloat(newnumber);
  }

  useEffect(() => {
    const productItemDetail = JSON.parse(JSON.stringify(productItemDetails));
    var newOrder: any = [];
    if (!isAddPoAction) {
      if (productItemDetailsCheckedList?.length > 0) {
        newOrder = productItemDetailsCheckedList?.filter(
          item => item.status == 'Quotation' && item.quoted != null && item.details.clientQuotations !== null && item.details?.printSpecifications?.form?.isActive === true,
        );
      } else {
        newOrder = productItemDetail?.filter(
          item => item.status == 'Quotation' && item.quoted != null && item.details.clientQuotations !== null && item.details?.printSpecifications?.form?.isActive === true,
        );
      }
    }
    const currentOrder = productItemDetail?.filter(item => item.status !== 'Quotation' && item.status !== 'Draft' && item.status !== 'New RFQ' && item.status !== 'Invoiced');
    const coc = otherCostList?.filter(item => ![Status.NEW_RFQ, Status.QUOTATION].includes(item.status.code))?.map(item => item.netValue);
    const noc = otherCostList?.filter(item => [Status.NEW_RFQ, Status.QUOTATION].includes(item.status.code))?.map(item => item.netValue);
    setOrderDetails({
      ...oderDetails,
      currentOrder: currentOrder,
      newOrder: newOrder,
      currentOtherCost: otherCostList !== null ? otherCostList.filter(item => ![Status.NEW_RFQ, Status.QUOTATION].includes(item.status.code)) : null,
      newOrderOtherCost: otherCostList !== null ? otherCostList.filter(item => [Status.NEW_RFQ, Status.QUOTATION].includes(item.status.code)) : null,
      orderTotal:
        otherCostList !== null
          ? roundNumber(
              currentOrder.reduce((sum, { details }) => sum + details?.clientQuotations?.actualSummary?.totalNetPrice, 0) +
                newOrder.reduce((sum, { details }) => sum + details?.clientQuotations?.actualSummary?.totalNetPrice, 0) +
                coc.reduce((accumulator, currentValue) => accumulator + currentValue, 0) +
                noc.reduce((accumulator, currentValue) => accumulator + currentValue, 0),
              2,
            )
          : roundNumber(
              currentOrder.reduce((sum, { details }) => sum + details?.clientQuotations?.actualSummary?.totalNetPrice, 0) +
                newOrder.reduce((sum, { details }) => sum + details?.clientQuotations?.actualSummary?.totalNetPrice, 0),
              2,
            ),
    });
    setPoFromJobInformation(jobInfo.podetails);
  }, [productItemDetails, productItems, otherCostList, isAddPoAction]);

  const updatePoFromJobInformation = (data, id) => {
    setPoFromJobInformation(data);
  };

  const onComfirmSubmit = async () => {
    setLoader(true);
    const originalPoList = jobInfo.podetails != null ? jobInfo.podetails : [];
    const copyOderDetails = JSON.parse(JSON.stringify(oderDetails));
    var poNumbers = poFromJobInformation?.length > 0 ? poFromJobInformation?.map(item => item.poNumber) : [];
    // var poDetailPonumbers = poDetails?.length > 0 ? poDetails?.map(item => item.poNumber) : [];
    // var satisfiedNewOrder = poFromJobInformation?.length > 0 && poDetails?.length > 0 ? poDetails?.find(item => item.satisfiedNewOrder == true)?.poNumber : [];
    var newPoDetails: any = poDetails?.length > 0 ? poDetails?.find(item => poNumbers.indexOf(item.poNumber) == -1) : {};
    var updatedValuesForApi: any = {};

    const jobInfoPoNumbers = poFromJobInformation?.map(item => item.poNumber);
    const removedPoList = jobInfo.podetails?.filter(item => jobInfoPoNumbers.indexOf(item.poNumber) === -1);
    const removedPoNumbers = removedPoList?.length > 0 ? removedPoList?.map(item => item.poNumber) : [];
    const isRemoved = removedPoList?.length > 0;
    const noPoCurrentOrderList = copyOderDetails?.currentOrder?.filter(item => item?.details?.printSpecifications?.form?.poDetails == null);
    const noPoCurrentOrderListIds = noPoCurrentOrderList.length > 0 ? noPoCurrentOrderList.map(item => item.id) : [];
    const currentOtherCostNoPo = copyOderDetails?.currentOtherCost?.length > 0 ? copyOderDetails?.currentOtherCost?.filter(item => item.poNumber === null) : null;

    if (!isRemoved) {
      if (copyOderDetails?.currentOrder?.length === 0 && poDetails?.length > 0) {
        updatedValuesForApi = {
          exsistingPoDetails: null,
          newPoDetails: {
            poId: null,
            poNumber: confirmOrder?.details?.poValueAvailable ? null : poDetails[0]?.poNumber,
            poValue: Number(confirmOrder?.details?.poValueAvailable ? null : poDetails[0]?.poValue),
            noPo: confirmOrder?.details?.poValueAvailable ? true : false,
            noPoComments: confirmOrder?.details?.poValueAvailable ? confirmOrder?.details?.comment : '',
            productTemplateIds: copyOderDetails?.newOrder?.map(row => row?.id),
            otherCostIds: copyOderDetails?.newOrderOtherCost !== null && copyOderDetails?.newOrderOtherCost?.length > 0 ? copyOderDetails?.newOrderOtherCost.map(item => item.id) : null,
          },
        };
      } else if (poFromJobInformation?.length > 0 && poDetails?.length > 0 && !isEmpty(newPoDetails)) {
        let filterExisting = poDetails.filter(item => item.poNumber !== newPoDetails.poNumber);
        updatedValuesForApi = {
          exsistingPoDetails: filterExisting?.map(item => ({
            poId: item?.id,
            poNumber: item?.poNumber,
            poValue: Number(item?.poValue),
            noPo: false,
            noPoComments: '',
            productTemplateIds: originalPoList?.find(row => row?.poNumber == item.poNumber)?.productTemplateIds,
            otherCostIds: originalPoList.find(item => item?.poNumber == item?.poNumber)?.otherCostIds != null ? originalPoList.find(item => item?.poNumber == item?.poNumber)?.otherCostIds : null,
          })),
          newPoDetails: {
            poId: null,
            poNumber: confirmOrder?.details?.poValueAvailable ? null : newPoDetails?.poNumber,
            poValue: Number(confirmOrder?.details?.poValueAvailable ? null : newPoDetails?.poValue),
            noPo: confirmOrder?.details?.poValueAvailable ? true : false,
            noPoComments: confirmOrder?.details?.poValueAvailable ? confirmOrder?.details?.comment : '',
            productTemplateIds:
              noPoCurrentOrderList?.length > 0 ? copyOderDetails?.newOrder?.map(row => row?.id)?.concat(noPoCurrentOrderList?.map(row => row?.id)) : copyOderDetails?.newOrder?.map(row => row?.id),
            otherCostIds:
              currentOtherCostNoPo !== null && currentOtherCostNoPo.length > 0
                ? copyOderDetails?.newOrderOtherCost !== null && copyOderDetails?.newOrderOtherCost?.length > 0
                  ? copyOderDetails?.newOrderOtherCost.map(row => row.id)?.concat(currentOtherCostNoPo.map(row => row.id))
                  : currentOtherCostNoPo.map(row => row.id)
                : copyOderDetails?.newOrderOtherCost !== null && copyOderDetails?.newOrderOtherCost?.length > 0
                ? copyOderDetails?.newOrderOtherCost.map(row => row.id)
                : null,
          },
        };
      } else if (poFromJobInformation?.length > 0 && poDetails?.length == 1 && isEmpty(newPoDetails)) {
        updatedValuesForApi = {
          exsistingPoDetails: poDetails?.map(item => ({
            poId: item?.id,
            poNumber: item?.poNumber,
            poValue: Number(item?.poValue),
            noPo: false,
            noPoComments: '',
            productTemplateIds: confirmOrder?.details?.poValueAvailable
              ? noPoCurrentOrderList?.length > 0
                ? copyOderDetails?.currentOrder?.map(row => row?.id).filter(data => noPoCurrentOrderListIds.indexOf(data) == -1)
                : copyOderDetails?.currentOrder?.map(row => row?.id)
              : noPoCurrentOrderList?.length > 0
              ? copyOderDetails?.newOrder
                  ?.map(row => row?.id)
                  ?.concat(noPoCurrentOrderList?.map(row => row?.id))
                  ?.concat(copyOderDetails?.currentOrder?.map(row => row?.id))
              : copyOderDetails?.newOrder?.map(row => row?.id)?.concat(copyOderDetails?.currentOrder?.map(row => row?.id)),
            otherCostIds: confirmOrder?.details?.poValueAvailable
              ? copyOderDetails?.currentOtherCost !== null && copyOderDetails?.currentOtherCost?.length > 0
                ? originalPoList.find(item => item?.poNumber == item?.poNumber)?.otherCostIds
                : null
              : copyOderDetails?.newOrderOtherCost !== null &&
                copyOderDetails?.newOrderOtherCost?.length > 0 &&
                copyOderDetails?.currentOtherCost !== null &&
                copyOderDetails?.currentOtherCost?.length > 0
              ? copyOderDetails?.newOrderOtherCost
                  .map(row => row.id)
                  ?.concat(originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds != null ? originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds : null)
                  ?.concat(currentOtherCostNoPo !== null && currentOtherCostNoPo.length > 0 ? currentOtherCostNoPo.map(row => row?.id) : null)
              : copyOderDetails?.newOrderOtherCost == null && copyOderDetails?.currentOtherCost !== null && copyOderDetails?.currentOtherCost?.length > 0
              ? originalPoList
                  .find(row => row?.poNumber == item?.poNumber)
                  ?.otherCostIds?.concat(currentOtherCostNoPo !== null && currentOtherCostNoPo.length > 0 ? currentOtherCostNoPo.map(row => row?.id) : null)
              : copyOderDetails?.newOrderOtherCost !== null && copyOderDetails?.currentOtherCost == null && copyOderDetails?.newOrderOtherCost?.length > 0
              ? copyOderDetails?.newOrderOtherCost !== null && copyOderDetails?.newOrderOtherCost?.length > 0
                ? copyOderDetails?.newOrderOtherCost.map(item => item.id)
                : null
              : null,
          })),
          newPoDetails: confirmOrder?.details?.poValueAvailable
            ? {
                poId: null,
                poNumber: null,
                poValue: null,
                noPo: true,
                noPoComments: confirmOrder?.details?.comment,
                productTemplateIds: copyOderDetails?.newOrder?.map(row => row?.id),
                otherCostIds: copyOderDetails?.newOrderOtherCost !== null && copyOderDetails?.newOrderOtherCost?.length > 0 ? copyOderDetails?.newOrderOtherCost.map(item => item.id) : null,
              }
            : null,
        };
      } else if (poFromJobInformation == null && poDetails?.length == 1 && !isEmpty(newPoDetails)) {
        updatedValuesForApi = {
          exsistingPoDetails: null,
          newPoDetails: {
            poId: null,
            poNumber: confirmOrder?.details?.poValueAvailable ? null : poDetails[0]?.poNumber,
            poValue: Number(confirmOrder?.details?.poValueAvailable ? null : poDetails[0]?.poValue),
            noPo: confirmOrder?.details?.poValueAvailable ? true : false,
            noPoComments: confirmOrder?.details?.poValueAvailable ? confirmOrder?.details?.comment : '',
            productTemplateIds: confirmOrder?.details?.poValueAvailable
              ? copyOderDetails?.newOrder?.map(row => row?.id)
              : copyOderDetails?.newOrder?.map(row => row?.id).concat(copyOderDetails?.currentOrder?.map(row => row?.id)),
            otherCostIds:
              currentOtherCostNoPo !== null && currentOtherCostNoPo.length > 0 && copyOderDetails?.newOrderOtherCost !== null && copyOderDetails?.newOrderOtherCost?.length > 0
                ? copyOderDetails?.newOrderOtherCost.map(item => item.id).concat(currentOtherCostNoPo.map(row => row.id))
                : copyOderDetails?.newOrderOtherCost !== null && copyOderDetails?.newOrderOtherCost?.length > 0
                ? copyOderDetails?.newOrderOtherCost.map(item => item.id)
                : null,
          },
        };
      } else if (poFromJobInformation?.length > 0 && poDetails?.length > 0 && isEmpty(newPoDetails)) {
        if (confirmOrder?.details?.poValueAvailable) {
          updatedValuesForApi = {
            exsistingPoDetails: poDetails?.map(item => ({
              poId: item?.id,
              poNumber: item?.poNumber,
              poValue: Number(item?.poValue),
              noPo: false,
              noPoComments: '',
              productTemplateIds: originalPoList?.find(row => row?.poNumber == item.poNumber)?.productTemplateIds,
              otherCostIds: originalPoList.find(item => item?.poNumber == item?.poNumber)?.otherCostIds != null ? originalPoList.find(item => item?.poNumber == item?.poNumber)?.otherCostIds : null,
            })),
            newPoDetails: {
              poId: null,
              poNumber: null,
              poValue: null,
              noPo: true,
              noPoComments: confirmOrder?.details?.comment,
              productTemplateIds:
                noPoCurrentOrderList?.length > 0 ? copyOderDetails?.newOrder?.map(row => row?.id)?.concat(noPoCurrentOrderList?.map(row => row?.id)) : copyOderDetails?.newOrder?.map(row => row?.id),
              otherCostIds: copyOderDetails?.newOrderOtherCost !== null && copyOderDetails?.newOrderOtherCost?.length > 0 ? copyOderDetails?.newOrderOtherCost.map(item => item.id) : null,
            },
          };
        } else {
          if (poDetails?.filter(item => item?.satisfiedNewOrder)?.length > 0) {
            updatedValuesForApi = {
              exsistingPoDetails: poDetails?.map(item => ({
                poId: item?.id,
                poNumber: item?.poNumber,
                poValue: Number(item?.poValue),
                noPo: false,
                noPoComments: '',
                productTemplateIds: item?.satisfiedNewOrder
                  ? noPoCurrentOrderList?.length > 0
                    ? originalPoList
                        ?.find(row => row?.poNumber == item.poNumber)
                        ?.productTemplateIds?.concat(copyOderDetails?.newOrder?.map(row => row?.id))
                        ?.concat(noPoCurrentOrderList?.map(row => row?.id))
                    : originalPoList?.find(row => row?.poNumber == item.poNumber)?.productTemplateIds?.concat(copyOderDetails?.newOrder?.map(row => row?.id))
                  : originalPoList?.find(row => row?.poNumber == item.poNumber)?.productTemplateIds,
                otherCostIds: item?.satisfiedNewOrder
                  ? copyOderDetails?.newOrderOtherCost !== null &&
                    copyOderDetails?.newOrderOtherCost?.length > 0 &&
                    copyOderDetails?.currentOtherCost !== null &&
                    copyOderDetails?.currentOtherCost?.length > 0
                    ? copyOderDetails?.newOrderOtherCost.map(row => row.id)?.concat(originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds)
                    : originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds != null
                    ? originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds
                    : null
                  : originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds,
              })),
              newPoDetails: null,
            };
          } else {
            updatedValuesForApi = {
              exsistingPoDetails: poDetails?.map(item => ({
                poId: item?.id,
                poNumber: item?.poNumber,
                poValue: Number(item?.poValue),
                noPo: false,
                noPoComments: '',
                productTemplateIds: item?.isPreviousSatisfiedNewOrder
                  ? noPoCurrentOrderList?.length > 0
                    ? originalPoList
                        ?.find(row => row?.poNumber == item.poNumber)
                        ?.productTemplateIds?.concat(copyOderDetails?.newOrder?.map(row => row?.id))
                        ?.concat(noPoCurrentOrderList?.map(row => row?.id))
                    : originalPoList?.find(row => row?.poNumber == item.poNumber)?.productTemplateIds?.concat(copyOderDetails?.newOrder?.map(row => row?.id))
                  : originalPoList?.find(row => row?.poNumber == item.poNumber)?.productTemplateIds,
                otherCostIds: null,
              })),
              newPoDetails: null,
            };
          }
        }
      }
    }

    if (isRemoved) {
      if (!isEmpty(newPoDetails)) {
        let filterExisting = poDetails.filter(item => item.poNumber !== newPoDetails.poNumber);
        updatedValuesForApi = {
          exsistingPoDetails: filterExisting?.map(item => ({
            poId: item?.id,
            poNumber: item?.poNumber,
            poValue: Number(item?.poValue),
            noPo: false,
            noPoComments: '',
            productTemplateIds: originalPoList?.find(row => row?.poNumber == item.poNumber)?.productTemplateIds,
            otherCostIds:
              copyOderDetails?.currentOtherCost !== null && copyOderDetails?.currentOtherCost?.length > 0
                ? copyOderDetails?.currentOtherCost.filter(row => item.poNumber === row.poNumber).length > 0
                  ? copyOderDetails?.currentOtherCost.filter(row => item.poNumber === row.poNumber)?.map(row => row.id)
                  : null
                : null,
          })),
          newPoDetails: {
            poId: null,
            poNumber: newPoDetails.poNumber,
            poValue: Number(newPoDetails.poValue),
            noPo: false,
            noPoComments: '',
            productTemplateIds:
              noPoCurrentOrderList.length > 0
                ? originalPoList
                    ?.find(row => removedPoNumbers.indexOf(row?.poNumber) > -1)
                    ?.productTemplateIds?.concat(copyOderDetails?.newOrder?.map(row => row?.id))
                    ?.concat(noPoCurrentOrderList?.map(row => row?.id))
                : originalPoList?.find(row => removedPoNumbers.indexOf(row?.poNumber) > -1)?.productTemplateIds?.concat(copyOderDetails?.newOrder?.map(row => row?.id)),
            otherCostIds:
              !isEmpty(newPoDetails) &&
              poDetails?.length == 1 &&
              copyOderDetails?.newOrderOtherCost !== null &&
              copyOderDetails?.newOrderOtherCost?.length > 0 &&
              copyOderDetails?.currentOtherCost !== null &&
              copyOderDetails?.currentOtherCost?.length > 0
                ? copyOderDetails?.newOrderOtherCost.map(item => item.id).concat(copyOderDetails?.currentOtherCost.map(item => item.id))
                : !isEmpty(newPoDetails) &&
                  poDetails?.length == 1 &&
                  copyOderDetails?.newOrderOtherCost !== null &&
                  copyOderDetails?.newOrderOtherCost?.length > 0 &&
                  (copyOderDetails?.currentOtherCost === null || copyOderDetails?.currentOtherCost?.length === 0)
                ? copyOderDetails?.newOrderOtherCost.map(item => item.id)
                : !isEmpty(newPoDetails) &&
                  poDetails?.length == 1 &&
                  (copyOderDetails?.newOrderOtherCost === null || copyOderDetails?.newOrderOtherCost?.length === 0) &&
                  copyOderDetails?.currentOtherCost !== null &&
                  copyOderDetails?.currentOtherCost?.length > 0
                ? copyOderDetails?.currentOtherCost.map(item => item.id)
                : !isEmpty(newPoDetails) &&
                  poDetails?.length > 1 &&
                  copyOderDetails?.newOrderOtherCost !== null &&
                  copyOderDetails?.newOrderOtherCost?.length > 0 &&
                  copyOderDetails?.currentOtherCost !== null &&
                  copyOderDetails?.currentOtherCost?.length > 0
                ? copyOderDetails?.newOrderOtherCost.map(item => item.id).concat(copyOderDetails?.currentOtherCost.map(item => item.id))
                : !isEmpty(newPoDetails) &&
                  poDetails?.length > 1 &&
                  copyOderDetails?.newOrderOtherCost !== null &&
                  copyOderDetails?.newOrderOtherCost?.length > 0 &&
                  (copyOderDetails?.currentOtherCost === null || copyOderDetails?.currentOtherCost?.length === 0)
                ? copyOderDetails?.newOrderOtherCost.map(item => item.id)
                : !isEmpty(newPoDetails) &&
                  poDetails?.length > 1 &&
                  (copyOderDetails?.newOrderOtherCost === null || copyOderDetails?.newOrderOtherCost?.length === 0) &&
                  copyOderDetails?.currentOtherCost !== null &&
                  copyOderDetails?.currentOtherCost?.length > 0
                ? copyOderDetails?.currentOtherCost.map(item => item.id)
                : currentOtherCostNoPo !== null && currentOtherCostNoPo.length > 0 && copyOderDetails?.newOrderOtherCost !== null && copyOderDetails?.newOrderOtherCost?.length > 0
                ? copyOderDetails?.newOrderOtherCost.map(item => item.id).concat(currentOtherCostNoPo.map(row => row.id))
                : copyOderDetails?.newOrderOtherCost !== null && copyOderDetails?.newOrderOtherCost?.length > 0
                ? copyOderDetails?.newOrderOtherCost.map(item => item.id)
                : null,
          },
        };
      } else if (poDetails?.filter(item => item?.satisfiedRemoved)?.length > 0) {
        updatedValuesForApi = {
          exsistingPoDetails: poDetails?.map(item => ({
            poId: item?.id,
            poNumber: item?.poNumber,
            poValue: Number(item?.poValue),
            noPo: false,
            noPoComments: '',
            productTemplateIds: item?.satisfiedNewOrder
              ? noPoCurrentOrderList.length > 0
                ? originalPoList
                    ?.find(row => row?.poNumber == item.poNumber)
                    ?.productTemplateIds?.concat(copyOderDetails?.newOrder?.map(row => row?.id))
                    ?.concat(originalPoList?.find(row => removedPoNumbers.indexOf(row?.poNumber) > -1)?.productTemplateIds)
                    ?.concat(noPoCurrentOrderList?.map(row => row?.id))
                : originalPoList
                    ?.find(row => row?.poNumber == item.poNumber)
                    ?.productTemplateIds?.concat(copyOderDetails?.newOrder?.map(row => row?.id))
                    ?.concat(noPoCurrentOrderList?.map(row => row?.id))
                    ?.concat(originalPoList?.find(row => removedPoNumbers.indexOf(row?.poNumber) > -1)?.productTemplateIds)
              : originalPoList?.find(row => row?.poNumber == item.poNumber)?.productTemplateIds,
            otherCostIds: item?.satisfiedNewOrder
              ? copyOderDetails?.newOrderOtherCost !== null &&
                copyOderDetails?.newOrderOtherCost?.length > 0 &&
                copyOderDetails?.currentOtherCost !== null &&
                copyOderDetails?.currentOtherCost?.length > 0
                ? copyOderDetails?.newOrderOtherCost
                    .map(row => row.id)
                    ?.concat(originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds != null ? originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds : null)
                    ?.concat(
                      originalPoList?.find(row => removedPoNumbers.indexOf(row?.poNumber) > -1)?.otherCostIds != null
                        ? originalPoList?.find(row => removedPoNumbers.indexOf(row?.poNumber) > -1)?.otherCostIds
                        : null,
                    )
                : null
              : originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds != null
              ? originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds
              : null,
          })),
          newPoDetails: null,
        };
      } else if (poDetails?.filter(item => item?.isPreviousSatisfiedNewOrder)?.length > 0) {
        updatedValuesForApi = {
          exsistingPoDetails: poDetails?.map(item => ({
            poId: item?.id,
            poNumber: item?.poNumber,
            poValue: Number(item?.poValue),
            noPo: false,
            noPoComments: '',
            productTemplateIds: item?.isPreviousSatisfiedNewOrder
              ? noPoCurrentOrderList.length > 0
                ? originalPoList
                    ?.find(row => row?.poNumber == item.poNumber)
                    ?.productTemplateIds?.concat(copyOderDetails?.newOrder?.map(row => row?.id))
                    ?.concat(originalPoList?.find(row => removedPoNumbers.indexOf(row?.poNumber) > -1)?.productTemplateIds)
                    ?.concat(noPoCurrentOrderList?.map(row => row?.id))
                : originalPoList
                    ?.find(row => row?.poNumber == item.poNumber)
                    ?.concat(noPoCurrentOrderList?.map(row => row?.id))
                    ?.productTemplateIds?.concat(copyOderDetails?.newOrder?.map(row => row?.id))
                    ?.concat(originalPoList?.find(row => removedPoNumbers.indexOf(row?.poNumber) > -1)?.productTemplateIds)
              : originalPoList?.find(row => row?.poNumber == item.poNumber)?.productTemplateIds,
            otherCostIds: item?.satisfiedNewOrder
              ? copyOderDetails?.newOrderOtherCost !== null &&
                copyOderDetails?.newOrderOtherCost?.length > 0 &&
                copyOderDetails?.currentOtherCost !== null &&
                copyOderDetails?.currentOtherCost?.length > 0
                ? copyOderDetails?.newOrderOtherCost
                    .map(row => row.id)
                    ?.concat(originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds != null ? originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds : null)
                    ?.concat(
                      originalPoList?.find(row => removedPoNumbers.indexOf(row?.poNumber) > -1)?.otherCostIds != null
                        ? originalPoList?.find(row => removedPoNumbers.indexOf(row?.poNumber) > -1)?.otherCostIds
                        : null,
                    )
                : null
              : originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds != null
              ? originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds
              : null,
          })),
          newPoDetails: null,
        };
      } else {
        updatedValuesForApi = {
          exsistingPoDetails: poDetails?.map(item => ({
            poId: item?.id,
            poNumber: item?.poNumber,
            poValue: Number(item?.poValue),
            noPo: false,
            noPoComments: '',
            productTemplateIds: copyOderDetails?.newOrder
              ?.map(row => row?.id)
              ?.concat(originalPoList?.find(row => removedPoNumbers.indexOf(row?.poNumber) > -1)?.productTemplateIds)
              ?.concat(originalPoList?.find(row => row?.poNumber == item.poNumber)?.productTemplateIds),
            otherCostIds: item?.satisfiedNewOrder
              ? copyOderDetails?.newOrderOtherCost !== null &&
                copyOderDetails?.newOrderOtherCost?.length > 0 &&
                copyOderDetails?.currentOtherCost !== null &&
                copyOderDetails?.currentOtherCost?.length > 0
                ? copyOderDetails?.newOrderOtherCost
                    .map(row => row.id)
                    ?.concat(originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds != null ? originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds : null)
                : null
              : originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds != null
              ? originalPoList.find(row => row?.poNumber == item?.poNumber)?.otherCostIds
              : null,
          })),
          newPoDetails: null,
        };
      }
    }

    try {
      await request(`${protectedResources.api.apiEndpoint}/RFQ/CreateOrder/${params?.jobId}`, {
        method: 'POST',
        body: JSON.stringify(updatedValuesForApi),
      })
        .then(res => {
          props.onConfirmOrder();
          setLoader(false);
        })
        .catch(error => {
          setLoader(false);
          console.error('err', error.response);
        });
      dispatch(actions.updateStatusToAccepted());
      params.jobId && dispatch(actions.loadRfqData({ jobId: params.jobId, toggleAction: toggleAction }));
      params.jobId && dispatch(apiCallBegan({ url: `${API.GET_JOB}/${params.jobId}`, method: 'GET', onSuccess: setUpdateJobData.type, module: MODULES.MY_JOBS }));
    } catch (responseError) {
      console.log('Error while submitting the form ' + responseError);
    }
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="fullScreen"
      centered
      onHide={() => {
        props.onHide();
      }}
      dialogClassName="modal__confirmOrder"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Confirm Order</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal__confirmOrderBody">
        {loader && <LoadingSpinnerWithBackdrop color="grey" message="Processing order - please wait" />}
        {confirmOrder.details.firstTimeOrder ? (
          <span>Please confirm your order and provide us with a PO number and value. If you do not have the purchase order, please select the override and provide details</span>
        ) : (
          // <span>
          //   You have exceeded PO value. Please extend the value and provide a
          //   comment and add a new PO or select to override
          // </span>
          <span>Please confirm your order and provide us with a PO number and value. If you do not have the purchase order, please select the override and provide details</span>
        )}
        {oderDetails?.currentOrder?.length > 0 && (
          <>
            {poFromJobInformation?.length > 0 && (
              <table className="current__orderTable">
                <thead>
                  <tr>
                    <td>
                      <h6>CURRENT ORDER</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>PO Number</strong>
                    </td>
                    <td>
                      <strong>Total (&#163;)</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {poFromJobInformation?.map((item, index) => (
                    <tr key={item?.poNumber + index}>
                      <td>{item?.poNumber}</td>
                      <td className="text-end">{item?.poValue?.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <OrderDetailsTable products={oderDetails.currentOrder} productDetails={oderDetails.currentOrder} otherCost={oderDetails.currentOtherCost} isCurrent={true} />
          </>
        )}
        {oderDetails.newOrder?.length > 0 && (
          <>
            <div className="divider__line"></div>
            <h6>NEW ORDER</h6>
            <OrderDetailsTable products={oderDetails.newOrder} productDetails={oderDetails.newOrder} otherCost={oderDetails.newOrderOtherCost} isCurrent={false} />
          </>
        )}
        <PODetails
          currentOrder={oderDetails.currentOrder}
          newOrder={oderDetails.newOrder}
          currentOtherCost={oderDetails.currentOtherCost}
          newOtherCost={oderDetails.newOrderOtherCost}
          jobInfo={poFromJobInformation}
          originalPoList={jobInfo.podetails}
          onRmovePoDetails={id => {
            var data = poFromJobInformation.filter(item => item.poId != id);
            updatePoFromJobInformation(data, id);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            props.onHide();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={
            !isValidConfirmBtn || (!confirmOrder?.details?.poValueAvailable && Number(poDetails.reduce((sum, { poValue }) => roundNumber(sum + Number(poValue), 2), 0)) < oderDetails.orderTotal)
          }
          variant="outline-primary"
          onClick={() => onComfirmSubmit()}
        >
          Confirm Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductOrderConfirmation;
