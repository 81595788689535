import React, { useEffect, useState } from 'react';

import CustomSelect from 'app/components/shared/CustomSelect';
import { protectedResources } from 'configs/msalAuthConfigs';
import { getAccountService } from '../../slice/selector';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { request } from 'utils/request';
import * as yup from 'yup';

export const AddJobRataPopup = props => {
  const { onHide, show } = props;
  const [error, setError] = useState('');
  const accountService = useSelector(getAccountService);
  const { actions: accountAction } = useInvoiceSlice();
  const { accountId } = useParams();
  const dispatch = useDispatch();
  var validationSchema = yup.object({
    rateItemsId: yup.string().trim().nullable().required('Please select a service'),
    name: yup.string().trim().nullable().required('Please provide a name'),
    description: yup.string().nullable(),
    isRateBased: yup.string().trim().nullable().nullable().required('Please select Yes or No'),
    addUser: yup.string().trim().nullable(),
    sellRate: yup
      .number()
      .nullable()
      .when('isRateBased', {
        is: (isRateBased: string) => isRateBased === 'Yes',
        then: yup.number().nullable().required('Please provided a sell rate'),
      }),
  });

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const { isRateBased } = useWatch({ control });

  const onSubmit = async (data: any) => {
    const formData = {
      id: null,
      accountId: Number(accountId),
      rateItemsId: data?.rateItemsId && Number(data?.rateItemsId),
      name: data?.name && data?.name,
      description: data?.description && data?.description,
      isRateBased: data.isRateBased && data.isRateBased === 'Yes' ? true : false,
      sellRate: data?.sellRate ? Number(data?.sellRate) : null,
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Admin/CreateAccountJobRate`, {
        method: 'POST',
        body: JSON.stringify(formData),
      }).then(response => {
        if (response.isSuccess) {
          accountId && dispatch(accountAction.loadJobRateList(+accountId));
          reset();
          setError('');
          setValue('rateItemsId', null);
          onHide();
        } else {
          setError(response.message);
        }
      });
    } catch (err) {}
  };

  useEffect(() => {
    accountId && dispatch(accountAction.loadAccountService(+accountId));
  }, [accountAction, accountId, dispatch]);

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="fullScreen"
        style={{ width: '80%', marginLeft: '10%' }}
        centered
        dialogClassName="modal__printSpecificationForm"
        keyboard={false}
        onHide={() => {
          onHide();
        }}
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>Add Job Rate</h2>
          </Modal.Title>
          <hr style={{ margin: '0px' }} />
        </Modal.Header>

        <Modal.Body style={{ height: '450px', marginBottom: '30px' }}>
          <Form id="addjobRatePopup" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group style={{ gridColumn: '1', width: '400px' }}>
              <CustomSelect
                isDisabled={false}
                styles={{ gridColumn: '1' }}
                required={true}
                controlId={'form__rateItemsId'}
                label={'Service'}
                control={control}
                name={'rateItemsId'}
                placeholder={'Select'}
                options={accountService?.data?.items?.map(item => {
                  return {
                    value: +item?.id,
                    label: item?.value,
                  };
                })}
                errors={undefined}
                enableMultilineOptions={false}
                isSearchable={false}
                register={register}
                value={getValues('rateItemsId')}
                readOnlyFormField={false}
              />
              {errors.rateItemsId && <Form.Text className="text-danger">{errors.rateItemsId.message}</Form.Text>}
            </Form.Group>
            <Form.Group style={{ gridColumn: '1/2', width: '400px' }} controlId="form__name">
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>
                  Name<span className="input__required">*</span>
                </span>
              </Form.Label>
              <Form.Control autoComplete="off" placeholder="Enter" type="text" {...register('name')} />
              {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3" style={{ gridColumn: '1/3', width: '600px' }} controlId="form_description">
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Description</span>
              </Form.Label>
              <Form.Control as="textarea" rows={3} autoComplete="off" type="text" {...register('description')} />
            </Form.Group>
            <Form.Group style={{ gridColumn: '3/3', width: '400px' }} controlId="isRateBased">
              <Form.Label>
                Rate Based ?<span className="input__required">*</span>
              </Form.Label>
              <div>
                <Form.Check disabled={false} type="radio" label="Yes" inline value="Yes" aria-label="isRateBased__radio-yes" id="form__isRateBased-yes" {...register('isRateBased')} />
                <Form.Check disabled={false} type="radio" label="No" value="No" inline aria-label="isRateBased__radio-no" id="form__isRateBased-no" {...register('isRateBased')} />
              </div>
              {errors.isRateBased && <Form.Text className="text-danger">{errors.isRateBased.message}</Form.Text>}
            </Form.Group>
            {isRateBased === 'Yes' && (
              <Form.Group style={{ gridColumn: '1/2', width: '400px' }} controlId="form__sellRate">
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>
                    Sell Rate (£)<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control autoComplete="off" placeholder="Enter" type="string" {...register('sellRate')} />
                {errors.sellRate && <Form.Text className="text-danger">{errors.sellRate.message}</Form.Text>}
              </Form.Group>
            )}
          </Form>
          {error !== '' && (
            <div className="text-danger text-center w-100 pt-4">
              <strong className="fs-6">{error}</strong>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <hr style={{ margin: '0px' }} />
          <Button
            style={{ border: '0px', color: 'black', width: '15%' }}
            variant="outline-primary"
            onClick={() => {
              setError('');
              reset();
              onHide();
            }}
          >
            Cancel
          </Button>
          <Button variant="outline-primary" form="addjobRatePopup" style={{ border: '0px', color: 'black', width: '15%' }} type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
