import React, { useEffect } from 'react';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { useRequestForQuoteSlice } from '../slice';

const columns: GridColDef[] = [
  { field: 'supplierName', headerName: 'Supplier', flex: 1, sortable: false },
  {
    field: 'contactEmailAddress',
    headerName: 'Email Id',
    flex: 1,
    sortable: false,
  },
  { field: 'telephone', headerName: 'Phone', flex: 1, sortable: false },
];

export default function SupplierList(props) {
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(props.selectedIds);
  const dispatch = useDispatch();
  const { actions } = useRequestForQuoteSlice();
  const selectedSuppliers = props?.supplierRowArray?.map(item => item?.supplierName);
  const supplierList = props.rows?.filter(item => !selectedSuppliers.includes(item?.supplierName));
  const logSelectedSuppliers = ids => {
    const selectedIDs = new Set(ids);
    setSelectionModel(ids);
    const selectedRowData = supplierList.filter(row => selectedIDs.has(row.supplierId));
    dispatch(
      actions.setTempSelectedSupplierList({
        id: props.rowid,
        suppliers: selectedRowData,
      }),
    );
  };

  useEffect(() => {
    dispatch(actions.resetTempSelectedSupplierList(props.rowid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ height: 230, width: '100%' }}>
      <div style={{ height: 210, width: '100%' }}>
        {props.minSupplierCount ? (
          <strong>
            You should select minimum {props.minSupplierCount} {props.minSupplierCount > 1 ? 'of suppliers.' : 'of supplier.'}
          </strong>
        ) : null}
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-cell': {
              fontSize: '14px',
              fontFamily: 'Arial',
              '& :focus': {
                outline: 'none',
              },
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              fontFamily: 'Arial',
              fontSize: '1rem',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#ECECEC',
              width: '100%',
            },
            '& .MuiDataGrid-cellCheckbox:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: '8px',
              cursor: 'pointer',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              background: '#ECECEC',
              borderRadius: '40px',
            },
            '& .MuiDataGrid-cellCheckbox': {
              '> .MuiCheckbox-root': {
                color: '#8D8C8D',
              },
              '> .Mui-checked': {
                color: '#1BA39C',
              },
            },
            '& .MuiDataGrid-columnHeaderCheckbox:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
              color: '#8D8C8D',
            },
            '& .MuiDataGrid-columnHeaderCheckbox  .Mui-checked': {
              color: '#1BA39C',
            },
            '& .MuiDataGrid-columnHeaderCheckbox  .MuiCheckbox-indeterminate': {
              color: '#1BA39C',
            },
          }}
          getRowId={val => {
            return val.supplierId;
          }}
          rows={supplierList}
          columns={columns}
          getRowHeight={() => 'auto'}
          checkboxSelection
          headerHeight={40}
          disableSelectionOnClick
          disableColumnMenu
          onSelectionModelChange={ids => logSelectedSuppliers(ids)}
          selectionModel={selectionModel}
        />
      </div>
    </div>
  );
}
