//  Externals
import { createSlice } from 'utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
//  Internals
import { menuItem, userInfo, MenuState, notificationMenuItem } from './types';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import menuSaga from './saga';

export const initialState: MenuState = {
  isLoading: false,
  firstName: '',
  lastName: '',
  emailAddress: '',
  roleName: null,
  role: '',
  error: null,
  modules: [],
  notificationCountModules: [],
  administrationSubMenu: [],
  profileImageUrl: '',
  isCDS: true,
  isClient: false,
  isSupplier: false,
  userId: 0,
  accountId: 0,
};

interface responseType extends userInfo {
  modules: menuItem[];
  notificationCountModules: notificationMenuItem[];
  administrationSubMenu: menuItem[];
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loadMenu(state) {
      state.isLoading = true;
    },
    menuLoaded(state, action: PayloadAction<responseType>) {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.emailAddress = action.payload.emailAddress;
      state.roleName = action.payload.roleName;
      state.modules = action.payload.modules;
      state.notificationCountModules = action.payload.notificationCountModules;
      state.administrationSubMenu = action.payload.administrationSubMenu ? action.payload.administrationSubMenu : [];
      state.role = action.payload.role;
      state.profileImageUrl = action.payload.profileImageUrl;
      state.isLoading = false;
      state.isCDS = action.payload.isCDS;
      state.isClient = action.payload.isClient;
      state.isSupplier = action.payload.isSupplier;
      state.userId = action.payload.userId;
      state.accountId = action.payload.accountId;
    },
    loadNotificationCounts() {},
    changeNotificationCount(state, action: PayloadAction<notificationMenuItem[]>) {
      state.notificationCountModules = action.payload;
    },
  },
});

export const { actions: menuActions, reducer } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: menuSaga });
  return { actions: slice.actions };
};

export default reducer;
