import {
  ACCEPTED,
  CANCELLED,
  DELIVERED,
  DRAFT,
  INVOICED,
  NEWRFQ,
  QUOTATION,
  READYTOINVOICE,
  REJECTED,
  QUOTED,
  ORDERED,
  AMENDEDRFQ,
  PARTORDERED,
  PARTDELIVERED,
  DECLINED,
} from 'app/components/shared/Constants';

//Fix TimeZone offset
export const fixTimezoneOffset = (date: Date): string => {
  if (!date) return '';
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
};

//get option name using value
export const checkOtherIsSelected = (id, arr) => {
  return id
    ? arr
        .filter(item => item.value === id.toString())[0]
        .name.trim()
        .toLowerCase() === 'other'
    : false;
};

export const getRequestStatus = (id: number) => {
  switch (id) {
    case 1:
      return DRAFT;
    case 2:
      return NEWRFQ;
    case 3:
      return QUOTATION;
    case 4:
      return ACCEPTED;
    case 5:
      return REJECTED;
    case 6:
      return DELIVERED;
    case 7:
      return READYTOINVOICE;
    case 8:
      return INVOICED;
    case 9:
      return DECLINED;
    case 10:
      return QUOTED;
    case 11:
      return ORDERED;
    case 12:
      return AMENDEDRFQ;
    case 13:
      return PARTORDERED;
    case 14:
      return PARTDELIVERED;
    default:
      return DRAFT;
  }
};

export const getTimePickerHours = () => {
  var hours: any = [];
  for (var i = 0; i < 24; i++) {
    hours = [
      ...hours,
      {
        label: `${i}:00`,
        value: `${i}`,
        disabled: false,
      },
    ];
  }
  return hours;
};
