//  Externals
import * as yup from 'yup';
//  Internals
import { checkIsOtherSelected, checkIsComplexJobSelected } from '../../../../utils/form';
import ERRORS from '../../../../data/errors';

export const quotationSchema = ({ artworkFormatsList, jobItemOptionsList }) =>
  yup.object({
    title: yup.string().trim().nullable().required(ERRORS.LINE_TITLE),
    description: yup.string().trim().nullable(),
    reference: yup.string().trim().nullable(),
    managementFee: yup
      .number()
      .typeError('Please provide a valid Management Fee.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a Management Fee.')
      .min(0, 'Please provide a valid Management Fee'),
    quantity: yup
      .number()
      .typeError('Please provide a valid Quantity.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a Quantity.')
      .min(1, 'Please provide a valid Quantity'),
    quantityRunOn: yup
      .number()
      .typeError('Please provide a valid Quantity Run-On.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable(),
    quantityActual: yup
      .number()
      .nullable()
      .when('quantityRunOn', {
        is: (quantityRunOn: number | null) => ![0, null].includes(quantityRunOn),
        then: yup.number().nullable(),
      }),
    deliveryContact: yup.string().trim().nullable(),
    deliveryAddress: yup.string().trim().nullable(),
    deliveryInstructions: yup.string().trim().nullable(),
    deliveryDueDate: yup.string().trim().nullable(),
    noFailDeliveryDate: yup.boolean().required('Please provide No Fail Delivery Date.'),
    samples: yup.string().trim().nullable().required('Please provide Samples.'),
    noOfSampleCopies: yup
      .number()
      .nullable()
      .when('samples', {
        is: (samples: string) => samples === 'Yes',
        then: yup
          .number()
          .typeError('Please provide a valid No of Sample Copies.')
          .transform(value => (isNaN(value) ? null : value))
          .nullable()
          .required('Please provide a No of Sample Copies.')
          .min(1, 'Please provide a valid No of Sample Copies.'),
      }),
    sampleCopiesDeliveryAddress: yup
      .string()
      .trim()
      .nullable()
      .when('samples', {
        is: (samples: string) => samples === 'Yes',
        then: yup.string().nullable().required('Please provide Sample Copies Delivery Address.'),
      }),
    artworkFormatId: yup.number().nullable().required('Please select Artwork Format.'),
    artworkFormatOther: yup
      .string()
      .trim()
      .nullable()
      .when('artworkFormatId', {
        is: (artworkFormatId: number) => checkIsOtherSelected(artworkFormatId, artworkFormatsList),
        then: yup.string().trim().nullable().required('Please provide Other Artwork Format details.'),
      }),
    artworkDueDate: yup.string().trim().nullable(),
    typeOfProofsId: yup.number().nullable().required('Please select Type Of Proofs.'),
    typeOfProofsOther: yup.string().trim().nullable(),
    proofDueDate: yup.string().trim().nullable(),
    jobItemOptions: yup.array().nullable(),
    targetDate: yup
      .string()
      .trim()
      .nullable()
      .when('jobItemOptions', {
        is: (jobItemOptions: string[]) => checkIsComplexJobSelected(jobItemOptions, jobItemOptionsList),
        then: yup.string().trim().nullable().required('Please provide a valid complex job target quote date.'),
      }),
    cdsNotes: yup.string().trim().nullable(),
    supplierNotes: yup.string().trim().nullable(),
    clientNotes: yup.string().trim().nullable(),
  });

export const acceptedSchema = ({ deliveryAddressChangeReasons, jobItemOptionsList, deliveryChange, orderedFlag }) =>
  yup.object({
    title: yup.string().trim().nullable().required(ERRORS.LINE_TITLE),
    description: yup.string().trim().nullable(),
    reference: yup.string().trim().nullable(),
    deliveryContact: yup.string().trim().nullable(),
    deliveryAddress: yup.string().trim().nullable(),
    deliveryInstructions: yup.string().trim().nullable(),
    deliveryDueDate: yup.string().trim().nullable(),
    noFailDeliveryDate: yup.boolean().required('Please provide No Fail Delivery Date.'),
    ...(deliveryChange &&
      orderedFlag == true && {
        deliveryAddressChangeReasonId: yup.number().nullable().required('Please select Delivery Address Change Reason.'),
      }),
    deliveryAddressChangeReasonOther: yup
      .string()
      .trim()
      .nullable()
      .when('deliveryAddressChangeReasonId', {
        is: (deliveryAddressChangeReasonId: number) => checkIsOtherSelected(deliveryAddressChangeReasonId, deliveryAddressChangeReasons),
        then: yup.string().trim().nullable().required('Please provide Delivery Address Change Reason details.'),
      }),
    jobItemOptions: yup.array().nullable(),
    targetDate: yup
      .string()
      .trim()
      .nullable()
      .when('jobItemOptions', {
        is: (jobItemOptions: string[]) => checkIsComplexJobSelected(jobItemOptions, jobItemOptionsList),
        then: yup.string().trim().nullable().required('Please provide a valid complex job target quote date.'),
      }),
    cdsNotes: yup.string().trim().nullable(),
    supplierNotes: yup.string().trim().nullable(),
    clientNotes: yup.string().trim().nullable(),
  });

export const orderedSchema = ({ deliveryAddressChangeReasons, jobItemOptionsList, deliveryChange }) =>
  yup.object({
    title: yup.string().trim().nullable().required(ERRORS.LINE_TITLE),
    description: yup.string().trim().nullable(),
    reference: yup.string().trim().nullable(),
    deliveryContact: yup.string().trim().nullable(),
    deliveryAddress: yup.string().trim().nullable(),
    deliveryInstructions: yup.string().trim().nullable(),
    ...(deliveryChange && {
      deliveryAddressChangeReasonId: yup.number().nullable().required('Please select Delivery Address Change Reason.'),
    }),
    deliveryAddressChangeReasonOther: yup
      .string()
      .trim()
      .nullable()
      .when('deliveryAddressChangeReasonId', {
        is: (deliveryAddressChangeReasonId: number) => checkIsOtherSelected(deliveryAddressChangeReasonId, deliveryAddressChangeReasons),
        then: yup.string().trim().nullable().required('Please provide Delivery Address Change Reason details.'),
      }),
    deliveryDueDate: yup.string().trim().nullable(),
    noFailDeliveryDate: yup.boolean().required('Please provide No Fail Delivery Date.'),
    jobItemOptions: yup.array().nullable(),
    targetDate: yup
      .string()
      .trim()
      .nullable()
      .when('jobItemOptions', {
        is: (jobItemOptions: string[]) => checkIsComplexJobSelected(jobItemOptions, jobItemOptionsList),
        then: yup.string().trim().nullable().required('Please provide a valid complex job target quote date.'),
      }),
    cdsNotes: yup.string().trim().nullable(),
    supplierNotes: yup.string().trim().nullable(),
    clientNotes: yup.string().trim().nullable(),
  });
