import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import './style.sass';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { request } from 'utils/request';
import { protectedResources } from '../../../configs/msalAuthConfigs';
import { useInvoiceSlice } from './slice';

export const InvoiceReCheckPopup = props => {
  const { show, onHide, productTemplateId, accountID } = props;
  const { actions } = useInvoiceSlice();
  const dispatch = useDispatch();
  const validationSchema = yup.object({
    recheck: yup.string().trim().nullable().required('Please enter comments'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const formData = {
      productTemplateIds: [...productTemplateId],
      recheckReason: data.recheck,
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Quotes/RecheckInvoice`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      dispatch(actions.loadInvoice());
    } catch (err) {}
    onHide();
    dispatch(actions.updateReadyToInvoiceData({ id: accountID }));
  };
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
      aria-labelledby="contained-modal-title-vcEnter"
      backdropClassName="fullScreen"
      centered
      dialogClassName="modal__popup_pod"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Send for Re-check</h5>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="modal_popup_body">
          <Form.Group controlId="form__recheck" style={{ gridColumn: '1' }}>
            <Form.Label>Please provide the reason for the recheck</Form.Label>
            <Form.Control style={{ height: '150px' }} as="textarea" rows={3} placeholder="Enter the reason here" className={errors.recheck && 'border-danger'} {...register('recheck')} />
            {errors.recheck && <Form.Text className="text-danger">{errors.recheck.message}</Form.Text>}
          </Form.Group>
        </Modal.Body>
        <hr style={{ marginBottom: '0px' }} />
        <Modal.Footer style={{ paddingBottom: '0px' }}>
          <Button style={{ border: '0px', color: 'black', width: '48%' }} variant="outline-primary" onClick={() => onHide()}>
            Cancel
          </Button>
          <Button variant="outline-primary" style={{ border: '0px', color: 'black', width: '48%' }} type="submit">
            Send for Re-check
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
