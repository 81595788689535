//  Externals
import * as yup from 'yup';
//  Internals
import { checkIsOtherSelected, checkIsComplexJobSelected } from '../../../../../utils/form';
import ERRORS from '../../../../../data/errors';

const schema = ({ artworkFormatsList, colours, finishings, isCDS, jobItemOptionsList, mechanisms, materials }) =>
  yup.object({
    title: yup.string().trim().nullable().required(ERRORS.LINE_TITLE),
    description: yup.string().trim().nullable(),
    reference: yup.string().trim().nullable(),
    quantity: yup
      .number()
      .typeError('Please provide a valid Quantity.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a Quantity.')
      .min(1, 'Please provide a valid Quantity'),
    quantityRunOn: yup
      .number()
      .typeError('Please provide a valid Quantity Run-On.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable(),
    noOfPages: yup
      .number()
      .typeError('Please provide a valid No of Pages.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a No of Pages.')
      .min(1, 'Please provide a valid No of Pages.'),
    visibleSize: yup.string().trim().nullable().required('Please provide Other Visible Size details.'),
    coloursId: yup.number().nullable().required('Please select Colours.'),
    coloursOther: yup
      .string()
      .trim()
      .nullable()
      .when('coloursId', {
        is: (coloursId: number) => checkIsOtherSelected(coloursId, colours),
        then: yup.string().trim().nullable().required('Please provide Other Colours details.'),
      }),
    materialId: yup.number().nullable().required('Please select Material.'),
    materialOther: yup
      .string()
      .trim()
      .nullable()
      .when('materialId', {
        is: (materialId: number) => checkIsOtherSelected(materialId, materials),
        then: yup.string().trim().nullable().required('Please provide Other Material details.'),
      }),
    laminated: yup.string().trim().nullable().required('Please provide Laminated.'),
    mechansimId: yup.number().nullable().required('Please select Mechanism.'),
    mechansimOther: yup
      .string()
      .trim()
      .nullable()
      .when('mechansimId', {
        is: (mechansimId: number) => checkIsOtherSelected(mechansimId, mechanisms),
        then: yup.string().trim().nullable().required('Please provide Other Mechanism details.'),
      }),
    finishingId: yup.number().nullable().required('Please select Finishing.'),
    finishingOther: yup
      .string()
      .trim()
      .nullable()
      .when('finishingId', {
        is: (finishingId: number) => checkIsOtherSelected(finishingId, finishings),
        then: yup.string().trim().nullable().required('Please provide Other Finishing details.'),
      }),
    addendum: yup.string().trim().nullable(),
    deliveryContact: yup.string().trim().nullable(),
    deliveryAddress: yup.string().trim().nullable(),
    deliveryInstructions: yup.string().trim().nullable(),
    deliveryDueDate: yup.string().trim().nullable(),
    noFailDeliveryDate: yup.boolean().required('Please provide No Fail Delivery Date.'),
    samples: yup.string().trim().nullable().required('Please provide Samples.'),
    noOfSampleCopies: yup
      .number()
      .nullable()
      .when('samples', {
        is: (samples: string) => samples === 'Yes',
        then: yup
          .number()
          .typeError('Please provide a valid No of Sample Copies.')
          .transform(value => (isNaN(value) ? null : value))
          .nullable()
          .required('Please provide a No of Sample Copies.')
          .min(1, 'Please provide a valid No of Sample Copies.'),
      }),
    sampleCopiesDeliveryAddress: yup
      .string()
      .trim()
      .nullable()
      .when('samples', {
        is: (samples: string) => samples === 'Yes',
        then: yup.string().nullable().required('Please provide Sample Copies Delivery Address.'),
      }),
    artworkFormatId: yup.number().nullable().required('Please select Artwork Format.'),
    artworkFormatOther: yup
      .string()
      .trim()
      .nullable()
      .when('artworkFormatId', {
        is: (artworkFormatId: number) => checkIsOtherSelected(artworkFormatId, artworkFormatsList),
        then: yup.string().trim().nullable().required('Please provide Other Artwork Format details.'),
      }),
    artworkDueDate: yup.string().trim().nullable(),
    typeOfProofsId: yup.number().nullable().required('Please select Type Of Proofs.'),
    typeOfProofsOther: yup.string().trim().nullable().required('Please provide Type Of Proofs.'),
    proofDueDate: yup.string().trim().nullable(),
    primaryCategoryId: yup
      .number()
      .nullable()
      .when('$exist', {
        is: () => isCDS,
        then: yup.number().nullable().required('Please select Primary Category.'),
      }),
    secondaryCategoryId: yup
      .number()
      .nullable()
      .when('$exist', {
        is: () => isCDS,
        then: yup.number().nullable().required('Please select Secondary Category.'),
      }),
    jobItemOptions: yup.array().nullable(),
    targetDate: yup
      .string()
      .trim()
      .nullable()
      .when('jobItemOptions', {
        is: (jobItemOptions: string[]) => checkIsComplexJobSelected(jobItemOptions, jobItemOptionsList),
        then: yup.string().trim().nullable().required('Please provide a valid complex job target quote date.'),
      }),
    cdsNotes: yup.string().trim().nullable(),
    supplierNotes: yup.string().trim().nullable(),
    clientNotes: yup.string().trim().nullable(),
  });

export default schema;
