//  Internals
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
//  Externals
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { DropDown } from 'app/components/modules/products/types';
import { getRoles, getSuppliers } from '../slice/selectors';
import { AddSupplierUserPropType, RoleList } from './types';
import PLACEHOLDER from '../../../../data/placeholder';
import { useRegistrationSlice } from '../slice';
import { request } from 'utils/request';
import schema from './validator';

const AddSupplierUser = (props: AddSupplierUserPropType) => {
  const { create, onHide, show, supplierUser } = props;

  const { actions } = useRegistrationSlice();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.initialRegistrationState());
  }, [actions, dispatch]);

  const [error, setError] = useState('');

  const roles = useSelector(getRoles);
  const [rolesList, setRolesList] = useState<RoleList[]>([]);

  useEffect(() => {
    const rolesList = roles.filter(role => !role.isClient).map(role => ({ label: role.name, value: role.id }));
    setRolesList(rolesList);
  }, [roles]);

  const suppliers = useSelector(getSuppliers);
  const [suppliersList, setSuppliersList] = useState<DropDown[]>([]);

  useEffect(() => {
    const suppliersList = suppliers.map(supplier => ({ label: supplier.supplierName, value: supplier.supplierId }));
    setSuppliersList(suppliersList);
  }, [suppliers]);

  const [isApproverChecked, setIsApproverChecked] = useState(false);
  const [isCacheChecked, setIsCacheChecked] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema()),
  });

  useEffect(() => {
    setError('');
    reset(supplierUser);
    setIsApproverChecked(supplierUser.approver);
    setIsCacheChecked(supplierUser.cache);
  }, [reset, supplierUser]);

  const onError = error => {
    console.log('ERROR:::', error);
  };

  const onSubmit = async values => {
    const payload = {
      ...values,
      isSupplier: true,
      isClient: false,
      active: values.active === 'Yes' ? true : false,
    };
    if (!create) payload.userId = values.userId;
    try {
      if (create) {
        await request(`${protectedResources.api.apiEndpoint}/AD/CreateAccount`, {
          method: 'POST',
          body: JSON.stringify(payload),
        }).then(res => {
          if (res.isSuccess) {
            dispatch(actions.loadClientOrSupplierUser({ isClient: false, isSupplier: true }));
            onHide();
          } else {
            setError(res.message);
          }
        });
      } else {
        await request(`${protectedResources.api.apiEndpoint}/AD/EditAccount/${supplierUser?.userId}`, {
          method: 'PUT',
          body: JSON.stringify(payload),
        }).then(res => {
          if (res.isSuccess) {
            dispatch(actions.loadClientOrSupplierUser({ isClient: false, isSupplier: true }));
            onHide();
          } else {
            setError(res.message);
          }
        });
      }
    } catch (responseError) {
      console.log('Error while submitting the form ' + responseError);
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="fullScreen"
      size="lg"
      centered
      keyboard={false}
      onHide={() => {
        onHide();
      }}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>{create ? 'Add Supplier User' : 'Edit Supplier User'}</h4>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Body>
          <div className="row mb-3">
            <div className="col-6">
              <Form.Group controlId="form_firstName">
                <Form.Label>
                  <span>
                    First Name<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control autoComplete="off" className={errors.firstName && 'border-danger'} placeholder="Enter First Name" type="text" {...register('firstName')} />
                {errors.firstName && <Form.Text className="text-danger">{errors.firstName.message}</Form.Text>}
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group controlId="form__lastName">
                <Form.Label>
                  <span>
                    Last Name<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control autoComplete="off" className={errors.lastName && 'border-danger'} placeholder=" Enter Last Name" type="text" {...register('lastName')} />
                {errors.lastName && <Form.Text className="text-danger">{errors.lastName.message}</Form.Text>}
              </Form.Group>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              <Form.Group controlId="form_emailAddress">
                <Form.Label>
                  <span>
                    Email<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control autoComplete="off" className={errors.emailAddress && 'border-danger'} placeholder="Enter Email" type="text" {...register('emailAddress')} />
                {errors.emailAddress && <Form.Text className="text-danger">{errors.emailAddress.message}</Form.Text>}
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group controlId="form__telephoneNumber">
                <Form.Label>
                  <span>
                    Telephone<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control autoComplete="off" className={errors.telephoneNumber && 'border-danger'} placeholder="Enter Telephone Number" type="text" {...register('telephoneNumber')} />
                {errors.telephoneNumber && <Form.Text className="text-danger">{errors.telephoneNumber.message}</Form.Text>}
              </Form.Group>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              <Form.Group controlId="form__roleId">
                <Form.Label>
                  Role<span className="input__required">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="roleId"
                  render={({ field: { onBlur, onChange, ref, value } }) => {
                    return (
                      <Select
                        className={errors.roleId && 'border-danger-select'}
                        classNamePrefix="custom-select-input"
                        id="form__roleId"
                        isClearable
                        maxMenuHeight={175}
                        onBlur={onBlur} //  Notify when input is touched
                        onChange={(val, { action }) => {
                          if (action === 'clear') {
                            onChange(null);
                          } else {
                            onChange(val?.value);
                          }
                        }} // Send value to hook f = items.find(item => item.value = val);
                        options={rolesList}
                        placeholder={PLACEHOLDER.SELECT_ROLE}
                        ref={ref}
                        value={rolesList.find(role => role.value === value)}
                      />
                    );
                  }}
                />
                {errors.roleId && <Form.Text className="text-danger">{errors.roleId.message}</Form.Text>}
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group controlId="form__supplierId">
                <Form.Label>
                  Supplier<span className="input__required">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="supplierId"
                  render={({ field: { onBlur, onChange, ref, value } }) => {
                    return (
                      <Select
                        className={errors.supplierId && 'border-danger-select'}
                        classNamePrefix="custom-select-input"
                        id="form__supplierId"
                        isClearable
                        maxMenuHeight={175}
                        onBlur={onBlur} //  Notify when input is touched
                        onChange={(val, { action }) => {
                          if (action === 'clear') {
                            onChange(null);
                          } else {
                            onChange(val?.value);
                          }
                        }} // Send value to hook f = items.find(item => item.value = val);
                        options={suppliersList}
                        placeholder={PLACEHOLDER.SELECT_ROLE}
                        ref={ref}
                        value={suppliersList.find(role => role.value === value)}
                      />
                    );
                  }}
                />
                {errors.supplierId && <Form.Text className="text-danger">{errors.supplierId.message}</Form.Text>}
              </Form.Group>
            </div>
          </div>
          <div className="row mb-3">
            <Form.Group controlId="form__active">
              <Form.Label>
                <span>
                  Active<span className="input__required">*</span>
                </span>
              </Form.Label>
              <div>
                <Form.Check aria-label="active-yes" id="form__active-yes" inline label="Yes" type="radio" value="Yes" {...register('active')} />
                <Form.Check aria-label="active-no" id="form__active-no" inline label="No" type="radio" value="No" {...register('active')} />
              </div>
              {errors.active && <Form.Text className="text-danger">{errors.active.message}</Form.Text>}
            </Form.Group>
          </div>
          <div className="row mt-3">
            <div className="d-flex">
              <div>
                <Form.Group controlId="form__cache">
                  <Controller
                    control={control}
                    name="cache"
                    render={({ field: { onBlur, onChange, ref } }) => (
                      <Form.Check
                        onBlur={onBlur}
                        onChange={e => {
                          onChange(e);
                          setIsCacheChecked(!isCacheChecked);
                        }}
                        label="Cache"
                        ref={ref}
                        type="checkbox"
                        checked={isCacheChecked}
                      />
                    )}
                  />
                  {errors.cache && <Form.Text className="text-danger">{errors.cache.message}</Form.Text>}
                </Form.Group>
              </div>
              <div style={{ marginLeft: '20px' }}>
                <Form.Group controlId="form__approver">
                  <Controller
                    control={control}
                    name="approver"
                    render={({ field: { onBlur, onChange, ref } }) => (
                      <Form.Check
                        onBlur={onBlur}
                        onChange={e => {
                          onChange(e);
                          setIsApproverChecked(!isApproverChecked);
                        }}
                        label="Approver"
                        ref={ref}
                        type="checkbox"
                        checked={isApproverChecked}
                      />
                    )}
                  />
                  {errors.approver && <Form.Text className="text-danger">{errors.approver.message}</Form.Text>}
                </Form.Group>
              </div>
            </div>
          </div>
          {error !== '' && (
            <div className="text-danger text-center w-100 mt-3">
              <strong className="fs-6">{error}</strong>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <br />
          <hr />
          <Button
            className="outline__button"
            onClick={() => {
              onHide();
              setError('');
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button className="full__button" type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddSupplierUser;
