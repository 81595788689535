import React, { useState } from 'react';
import { selectSupplierDataFromAPI, useParamSelector } from '../slice/selectors';
import { getJobInformation } from 'store/reducers/entities/selectors';
import { selectProductItemDetailsRows } from '../slice/selectors';
import { SelectedSuppliersList } from './SelectedSuppliersList';
import AssignSuppliers from './AssignSuppliers';
import MODULES from '../../../../data/modules';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import './style.sass';

const Quotes = props => {
  const { productid, productType, rowid, title, status, supplierPo, supplierNotes, isActive } = props;
  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));
  const [modalShow, setModalShow] = useState(false);
  const supplierRowArrayData = useParamSelector(selectSupplierDataFromAPI, rowid);
  var supplierRowArray =
    supplierRowArrayData &&
    supplierRowArrayData.map(item => {
      return item.jobQuoteSummary;
    });
  const data = useSelector(selectProductItemDetailsRows);
  const hideEditSupplierQuote = data?.filter(row => row.id == rowid)[0]?.details?.clientQuotations === null;
  return (
    <div className="quotes__page">
      {supplierRowArray && supplierRowArray.length > 0 && (
        <>
          <div className="quotes__header">
            <h6>Quotes</h6>
            {['Delivered', 'Ready To Invoice', 'Ordered', 'Invoiced'].includes(status) ? (
              <></>
            ) : (
              <div>
                {jobInformation?.statusName != 'Cancelled' && isActive != 'No' && (
                  <>
                    {hideEditSupplierQuote && (
                      <p className="edit__button" onClick={() => setModalShow(true)}>
                        + Add Suppliers
                      </p>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <SelectedSuppliersList
            supplierNotes={supplierNotes}
            isClientQuotations={hideEditSupplierQuote}
            supplierPo={supplierPo}
            title={title}
            rowid={rowid}
            isActive={isActive}
            supplierRowDetails={supplierRowArray}
            productType={productType}
          />
        </>
      )}
      {modalShow && <AssignSuppliers show={modalShow} onHide={() => setModalShow(false)} rowid={props.rowid} supplierRowArray={supplierRowArray} productid={productid} />}
      {supplierRowArray && supplierRowArray.length === 0 && (
        <div className="assign__suppliersButtonContainer">
          <div className="assignSuppliers__button">
            <Button disabled={jobInformation?.statusName === 'Cancelled' || isActive === 'No'} variant="outline-primary" className="full__button" onClick={() => setModalShow(true)}>
              Assign Suppliers
            </Button>
            <span style={{ marginTop: '0.5rem' }}>You haven't assigned any suppliers yet.</span>
            <span>
              click on <strong>Assign Suppliers</strong>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quotes;
