import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { dashboardActions as actions } from '.';
import {
  accountData,
  dropDown,
  editAccountData,
  productData,
  productDetailList,
  supplierList,
  BusinessUnitList,
  JobrateList,
  supplierDropDownList,
  EditJobrateList,
  EditBusinessUnitList,
  masterData,
  selectApprovers,
  selectAccountService,
  accountUsers,
} from './types';

//All Accounts
function* getAccount() {
  const requestURL = `${protectedResources.api.apiEndpoint}/Admin/GetAllAccountDetail`;
  console.log('url', requestURL);
  try {
    const accounts: accountData = yield call(request, requestURL);
    if (accounts) {
      yield put(actions.accountDataLoaded(accounts));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//Edit Accounts
function* getAccountById(action) {
  const accountId = action.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/Admin/GetAccountDetailById?id=${accountId}`;
  console.log('url', requestURL);
  try {
    const accountsById: editAccountData = yield call(request, requestURL);
    if (accountsById) {
      yield put(actions.editAccountDataLoaded(accountsById));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//Products
function* getProduct(action) {
  const accountId = action.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/AD/GetProductDeatilsByAccount?accountId=${accountId}`;
  console.log('url', requestURL);
  try {
    const productData: productData = yield call(request, requestURL);
    if (productData) {
      yield put(actions.productDataLoaded(productData));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//Product List
function* getProductList(action) {
  const accountId = action.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/Products/GetAccountAvailableProduct/${accountId}`;
  try {
    const productList: productDetailList = yield call(request, requestURL);
    if (productList) {
      yield put(actions.productListDataLoaded(productList));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//Suppliers List
function* getSuppliersList(action) {
  const accountId = action.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/AD/GetSupplierDeatilsByAccount?accountId=${accountId}`;
  console.log('url', requestURL);
  try {
    const suppliersList: supplierList = yield call(request, requestURL);
    if (suppliersList) {
      yield put(actions.suppliersListDataLoaded(suppliersList));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//Suppliers DD List
function* getSuppliersDropDownList() {
  const requestURL = `${protectedResources.api.apiEndpoint}/Supplier/api/Supplier/GetAllSupplier`;
  console.log('url', requestURL);
  try {
    const suppliersDDList: supplierDropDownList = yield call(request, requestURL);
    if (suppliersDDList) {
      yield put(actions.suppliersDropDownLoaded(suppliersDDList));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//Business Unit List
function* getBusinessUnitsList(action) {
  const accountId = action.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/Admin/GetAccountBusinessUnitList?accountId=${accountId}`;
  try {
    const BusinessUnis: BusinessUnitList = yield call(request, requestURL);
    if (BusinessUnis) {
      yield put(actions.BusinessUnitDataLoaded(BusinessUnis));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//Edit Business Unit
function* getBusinessUnitsEditData(action) {
  const accountId = action.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/Admin/GetAccountBusinessUnitById?businessUnitId=${accountId}`;
  console.log('url', requestURL);
  try {
    const EditBusinessUnis: EditBusinessUnitList = yield call(request, requestURL);
    if (EditBusinessUnis) {
      yield put(actions.EditBusinessUnitDataLoaded(EditBusinessUnis));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//Job Rate List
function* getJobRateList(action) {
  const accountId = action.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/Admin/GetAccountJobRateList?accountId=${accountId}`;
  console.log('url', requestURL);
  try {
    const jobRate: JobrateList = yield call(request, requestURL);
    if (jobRate) {
      yield put(actions.JobRateDataLoaded(jobRate));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//Edit Job Rate List
function* getEditJobRateList(action) {
  const accountId = action.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/Admin/GetAccountJobRateById?jobRateId=${accountId}`;
  console.log('url', requestURL);
  try {
    const editjobRate: EditJobrateList = yield call(request, requestURL);
    if (editjobRate) {
      yield put(actions.EditJobRateDataLoaded(editjobRate));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//All DeopDown Accoumt
function* getDropDowns() {
  const requestURL = `${protectedResources.api.apiEndpoint}/MasterData/GetAccountDetailsData`;
  console.log('url', requestURL);
  try {
    const dropDown: dropDown = yield call(request, requestURL);
    if (dropDown) {
      yield put(actions.dropDownDataLoaded(dropDown));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//masterData Drop Down Dicline
function* getMasterData() {
  const requestURL = `${protectedResources.api.apiEndpoint}/MasterData/GetMasterData`;
  console.log('url', requestURL);
  try {
    const dropDown: masterData = yield call(request, requestURL);
    if (dropDown) {
      yield put(actions.masterDataLoaded(dropDown));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//masterData Drop Down Dicline
function* getApprovers() {
  const requestURL = `${protectedResources.api.apiEndpoint}/User/GetDiscountApprovers`;
  console.log('url', requestURL);
  try {
    const Approvers: selectApprovers = yield call(request, requestURL);
    if (Approvers) {
      yield put(actions.ApproversLoaded(Approvers));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//GetAccountService Drop Down
function* GetAccountService(action) {
  const AccountId = action?.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/MasterData/GetAccountService/${AccountId}`;
  console.log('url', requestURL);
  try {
    const Approvers: selectAccountService = yield call(request, requestURL);
    if (Approvers) {
      yield put(actions.AccountService(Approvers));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}
//GetAccount Users Drop Down
function* GetAccountUsers(action) {
  const AccountId = action?.payload;
  const requestURL = `${protectedResources.api.apiEndpoint}/User/GetAccountUsers/${AccountId}`;
  console.log('url', requestURL);
  try {
    const Approvers: accountUsers = yield call(request, requestURL);
    if (Approvers) {
      yield put(actions.AccountUsers(Approvers));
    } else {
      yield put(
        actions.dataLoadError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.dataLoadError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.dataLoadError({ error: 'Issue with api', loading: false }));
    }
  }
}

export function* accountSaga() {
  yield takeLatest(actions.loadAccount.type, getAccount);
  yield takeLatest(actions.editAccount.type, getAccountById);
  yield takeLatest(actions.loadProduct.type, getProduct);
  yield takeLatest(actions.loadProductList.type, getProductList);
  yield takeLatest(actions.loadSuppliersList.type, getSuppliersList);
  yield takeLatest(actions.loadSuppliersDropDownList.type, getSuppliersDropDownList);
  yield takeLatest(actions.loadBusinessUnits.type, getBusinessUnitsList);
  yield takeLatest(actions.loadEditBusinessUnitList.type, getBusinessUnitsEditData);
  yield takeLatest(actions.loadJobRateList.type, getJobRateList);
  yield takeLatest(actions.loadEditJobRateLis.type, getEditJobRateList);
  yield takeLatest(actions.loadDropDowns.type, getDropDowns);
  yield takeLatest(actions.loadMasterData.type, getMasterData);
  yield takeLatest(actions.loadApprovers.type, getApprovers);
  yield takeLatest(actions.loadAccountService.type, GetAccountService);
  yield takeLatest(actions.loadAccountUsers.type, GetAccountUsers);
}
