import React, { useState } from 'react';
import './style.sass';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { TableCell } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox } from '@mui/material';
import { Button } from 'react-bootstrap';
import { InvoiceReCheckPopup } from './InvoiceReCheckPopup';
import { InvoiceFooter } from './InvoicsRows';
import OtherCosts from '../requestForQuote/OtherCosts';

export const InvoiceBody = props => {
  const { invoiceRow, filter, FrequencyFilter, invoiceFooter } = props;

  const [openIndex, setOpenIndex] = useState<Number>(0);
  const [productId, setProductId] = useState<any>([]);
  const [sendInvoiceId, setSendInvoiceId] = useState<any>([]);
  const [accountId, setAccountId] = useState<any>([]);
  const [sendInvoicePopup, setSendInvoicePopup] = useState(false);
  const [open, setOpen] = useState(true);

  const openHandler = index => {
    setOpenIndex(index);
    setOpen(!open);
  };
  const productTemplateId: number[] = [];

  const handleSendInvoice = (jobId, AccountId) => {
    invoiceRow.length > 0 &&
      invoiceRow.map(item => {
        if (AccountId === item.accountId) {
          item.jobLineInvoiceList.rfqJobLine.map(item => {
            if (jobId === item.rfqJobId) {
              item.jobLine.map(item => {
                productTemplateId.push(item.productTemplateId);
              });
            }
          });
        }
      });
    setSendInvoicePopup(true);
    setProductId(productTemplateId);
    setAccountId(AccountId);
  };

  const handleInvoiceCheck = id => {
    const updatedInvoiceIds = [...sendInvoiceId];
    const index = updatedInvoiceIds.indexOf(id);
    if (index >= 0) {
      updatedInvoiceIds.splice(index, 1);
    } else {
      updatedInvoiceIds.push(id);
    }
    setSendInvoiceId([...updatedInvoiceIds]);
  };

  return (
    <>
      <InvoiceReCheckPopup show={sendInvoicePopup} productTemplateId={productId} accountID={accountId} onHide={() => setSendInvoicePopup(false)} />
      <div style={{ marginBottom: '60px' }}>
        <Table aria-label="collapsible table">
          <TableHead style={{ border: '1px solid #acacac', backgroundColor: '#e6e6e6' }}>
            <TableRow>
              <TableCell style={{ width: '110px' }}>
                <IconButton aria-label="expand row" size="small">
                  {<KeyboardArrowRightIcon />}
                </IconButton>
                <Checkbox
                  sx={{
                    color: '#8D8C8D',
                    '&.Mui-checked': {
                      color: '#1BA39C',
                    },
                  }}
                  indeterminateIcon={<IndeterminateCheckBox sx={{ color: '#1BA39C' }} />}
                />
              </TableCell>
              <TableCell sx={{ paddingLeft: 0 }} align="left">
                Accounts
              </TableCell>
              <TableCell>Frequency</TableCell>
              <TableCell>Export Type</TableCell>
              <TableCell>No.of Jobs</TableCell>
              <TableCell className="text-end">Net Total (£)</TableCell>
              <TableCell className="text-end">VAT Amount (£)</TableCell>
              <TableCell className="text-end">Gross (£)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <React.Fragment>
              {invoiceRow?.length > 0 &&
                invoiceRow?.map(item => {
                  if (!filter || filter === item?.accountId)
                    if (!FrequencyFilter || FrequencyFilter === item?.frequency)
                      return (
                        <>
                          <TableRow
                            sx={{
                              '& > *': { borderBottom: 'unset' },
                            }}
                            hover={true}
                          >
                            <TableCell className="quotes__details__bodyCell first__column" style={{ width: '110px' }}>
                              <div className="quote__highlight"></div>
                              <IconButton aria-label="expand row" size="small" onClick={() => openHandler(item.accountId)}>
                                {!open && item?.accountId === openIndex ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
                              </IconButton>
                              <Checkbox
                                sx={{
                                  color: '#8D8C8D',
                                  '&.Mui-checked': {
                                    color: '#1BA39C',
                                  },
                                }}
                                onChange={() => handleInvoiceCheck(item.accountId)}
                                id="supplierList-row-id"
                              />
                            </TableCell>
                            <TableCell>{item.accountName}</TableCell>
                            <TableCell>{item.frequency}</TableCell>
                            <TableCell>{item.exportName}</TableCell>
                            <TableCell>
                              {item.noofJob.toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}
                            </TableCell>
                            <TableCell className="text-end">{item.netTotal?.toFixed(2)}</TableCell>
                            <TableCell className="text-end">{item.vatAmount?.toFixed(2)}</TableCell>
                            <TableCell className="text-end">{item.gross?.toFixed(2)}</TableCell>
                          </TableRow>
                          {item.accountId === openIndex && !open && (
                            <>
                              <TableRow>
                                <TableCell className="border-0"></TableCell>
                                <TableCell colSpan={2}>ID</TableCell>
                                <TableCell>
                                  <span className="Invoice_Body_head">Title</span>
                                </TableCell>
                                <TableCell>
                                  <span className="Invoice_Body_head">PO</span>
                                </TableCell>
                                <TableCell className="text-end">
                                  <span className="Invoice_Body_head ">Net (£)</span>
                                </TableCell>
                                <TableCell className="text-end">
                                  <span className="Invoice_Body_head ">VAT Amount (£)</span>
                                </TableCell>
                                <TableCell className="text-end">
                                  <span className="Invoice_Body_head ">Gross (£)</span>
                                </TableCell>
                              </TableRow>
                              {item.jobLineInvoiceList.rfqJobLine.map(rfqItem => {
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell className="border-0"></TableCell>
                                      <TableCell colSpan={2} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {rfqItem.jobNumber}
                                      </TableCell>
                                      <TableCell>{rfqItem.rfqTitle}</TableCell>
                                      <TableCell></TableCell>
                                      <TableCell className="text-end">{rfqItem.rfqNet?.toFixed(2)}</TableCell>
                                      <TableCell className="text-end">{rfqItem.rfqVat?.toFixed(2)}</TableCell>
                                      <TableCell className="text-end">{rfqItem.rfqGross?.toFixed(2)}</TableCell>
                                    </TableRow>
                                    {rfqItem.jobLine.map(item => {
                                      return (
                                        <>
                                          <TableRow>
                                            <TableCell colSpan={3} className="border-0"></TableCell>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell> {item.poNumber != null ? item.poNumber : '-'}</TableCell>
                                            <TableCell className="text-end">{item.netTotal?.toFixed(2)}</TableCell>
                                            <TableCell className="text-end">{item.vatAmount?.toFixed(2)}</TableCell>
                                            <TableCell className="text-end">{item.gross?.toFixed(2)}</TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })}
                                    {rfqItem?.otherJobCost?.length > 0 &&
                                      rfqItem?.otherJobCost?.map(otherItem => (
                                        <TableRow>
                                          <TableCell colSpan={3} className="border-0"></TableCell>
                                          <TableCell>{otherItem?.name}</TableCell>
                                          <TableCell>{otherItem?.poNumber}</TableCell>
                                          <TableCell className="text-end">{otherItem?.netValue?.toFixed(2)}</TableCell>
                                          <TableCell className="text-end">{otherItem?.vatAmount?.toFixed(2)}</TableCell>
                                          <TableCell className="text-end">{otherItem?.grossValue?.toFixed(2)}</TableCell>
                                        </TableRow>
                                      ))}
                                    <TableRow>
                                      <TableCell colSpan={8}>
                                        <div className="d-flex justify-content-end">
                                          <Button onClick={() => handleSendInvoice(rfqItem.rfqJobId, rfqItem.accountId)} className="Invoice_Body_Recheck_button flex-end" variant="outline-primary">
                                            Re-check
                                          </Button>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </>
                          )}
                        </>
                      );
                })}
            </React.Fragment>
          </TableBody>
        </Table>
      </div>
      <InvoiceFooter sendInvoiceId={sendInvoiceId} invoiceRow={invoiceRow} invoiceFooter={invoiceFooter} />
    </>
  );
};
