import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/model';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.header || initialState;

export const selectTitle = createSelector([selectSlice], headerState => headerState.title);
export const selectIsNotification = createSelector([selectSlice], headerState => headerState.isNotification);
export const selectIsNotes = createSelector([selectSlice], headerState => headerState.isNotes);
export const selectSearch = createSelector([selectSlice], headerState => headerState.search);
export const selectSearchSupplier = createSelector([selectSlice], headerState => headerState.searchSupplier);
