import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './style.sass';
import { useDispatch } from 'react-redux';
import { useRequestForQuoteSlice } from '../slice';

const OrderFromSupplier = props => {
  const { jobId, productItemDetailsCheckedList, productItemDetailsRows, onPlaceOrder, onCancel } = props;
  const { actions } = useRequestForQuoteSlice();
  const dispatch = useDispatch();
  var finalData: any = [];
  var filteredData: any = {};

  const productQuotationRow =
    productItemDetailsCheckedList.length > 0 ? productItemDetailsCheckedList?.filter(item => item.status === 'Accepted') : productItemDetailsRows?.filter(item => item.status === 'Accepted');
  const filteredQuotedQuotes = productQuotationRow
    ?.filter(item => item?.details?.printSpecifications?.form?.isActive === true)
    ?.map(item => {
      return {
        productName: `${item.title} - ${item.productType}`,
        productId: item.id,
        data: item.details.jobQuotes.filter(item => item.jobQuoteSummary.statusName == 'Quoted'),
      };
    })
    .filter(item => item.data.length > 0);
  const productAndCheckedQuote = filteredQuotedQuotes.map(item => {
    var jobQuoteDetail = item.data.map(item => item.jobQuoteSummary).filter(item => item.checked);
    return {
      productName: item.productName,
      productId: item.productId,
      supplierDetails: {
        supplierName: jobQuoteDetail.length > 0 && jobQuoteDetail[0].supplierName,
        jobQuoteId: jobQuoteDetail.length > 0 && jobQuoteDetail[0].jobQuoteId,
      },
    };
  });
  productAndCheckedQuote.forEach(item => {
    filteredData = {
      ...filteredData,
      [item.supplierDetails.supplierName]: {
        id:
          filteredData[item.supplierDetails.supplierName] !== undefined && filteredData[item.supplierDetails.supplierName]['id'] !== undefined
            ? filteredData[item.supplierDetails.supplierName]['id'].concat(item.productId)
            : [item.productId],
        supplierName: item.supplierDetails.supplierName,
        productName:
          filteredData[item.supplierDetails.supplierName] !== undefined && filteredData[item.supplierDetails.supplierName]['productName'] !== undefined
            ? filteredData[item.supplierDetails.supplierName]['productName'].concat(item.productName)
            : [item.productName],
      },
    };
  });
  for (var key in filteredData) {
    finalData.push(filteredData[key]);
  }
  const onPlaceOrderButtonClicked = productIds => {
    dispatch(actions.loadPlaceSupplierOrder({ rfqJobId: jobId, productIds: productIds }));
    props.selectedProduct(productIds);
    onPlaceOrder();
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered onHide={onCancel} backdrop="static">
      <Modal.Header closeButton className="border-0">
        <Modal.Title>Order from Supplier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          <h6>Are you sure you want to place an order for the following items?</h6>
          {finalData.map(item => (
            <div style={{ paddingTop: '5px' }} key={item.supplierName}>
              <div style={{ border: '1px solid black', padding: '3px' }}>
                <div>
                  <span> {item.supplierName}</span>
                  <Button variant="outline-primary" className="outline__button" style={{ float: 'right' }} onClick={() => onPlaceOrderButtonClicked(item.id)}>
                    Place Order
                  </Button>
                </div>
                <div>
                  <ol>
                    {item.productName.map(item => (
                      <li key={item}>{item}</li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          ))}
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel} variant="outline-primary" className="outline__button">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderFromSupplier;
