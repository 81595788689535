import React, { useState, useEffect } from 'react';

import CustomSelect from 'app/components/shared/CustomSelect';
import { protectedResources } from 'configs/msalAuthConfigs';
import { getBusinessUnitsList } from '../../slice/selector';
import ListItemText from '@material-ui/core/ListItemText';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { selectDropDown } from '../../slice/selector';
import { yupResolver } from '@hookform/resolvers/yup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Chip from '@material-ui/core/Chip';
import { request } from 'utils/request';
import _without from 'lodash/without';
import { Table } from '@mui/material';
import * as yup from 'yup';

export const AddBusinessUnit = props => {
  const { onHide, show, accountUsers } = props;
  const BusinessUnits = useSelector(getBusinessUnitsList);
  const { actions: businessAction } = useInvoiceSlice();
  const { actions: accountAction } = useInvoiceSlice();
  const getDropdowns = useSelector(selectDropDown);
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const [personName, setPersonName] = useState<string[]>([]);
  const [dropDowns, setDropDowns] = useState<any>({});

  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .transform(value => ([...BusinessUnits?.data?.map(item => item.name)].includes(value) ? true : value))
      .nullable()
      .typeError('This Unit Name is already exists')
      .required(`Unit Name is required`),
    reference: yup.string().nullable(),
    analsysCategoryId: yup.number().nullable(),
    isActive: yup.string().trim().nullable().required('Please select any one.'),
    addUser: yup.string().trim().nullable().nullable(),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPersonName(event.target.value as string[]);
  };
  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setPersonName(current => _without(current, value));
  };
  const userIdUpdate = accountUsers?.data?.items?.filter(item => personName.includes(item.name) === true);
  const selectedUserIds = userIdUpdate?.map(item => item.id);

  useEffect(() => {
    setDropDowns(getDropdowns?.data);
  }, [getDropdowns]);
  useEffect(() => {
    dispatch(accountAction.loadDropDowns());
  }, []);

  const onSubmit = async data => {
    const formData = {
      businessUnitId: 0,
      name: data.name && data.name,
      parentAccountId: Number(accountId),
      reference: data.reference && data.reference,
      analsysCategoryId: data.analsysCategoryId && data.analsysCategoryId,
      isActive: data.isActive && data.isActive === 'Yes' ? true : false,
      user: [...selectedUserIds],
    };
    try {
      const res = await request(`${protectedResources.api.apiEndpoint}/Admin/CreateAccountBusinessUnit?AccountId=${accountId}`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      accountId && dispatch(businessAction.loadBusinessUnits(+accountId));
    } catch (err) {}
    reset();
    setPersonName([]);
    onHide();
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="fullScreen"
        style={{ width: '80%', marginLeft: '10%' }}
        centered
        dialogClassName="modal__printSpecificationForm"
        keyboard={false}
        onHide={() => {
          onHide();
        }}
        scrollable
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>Add Business Unit</h2>
          </Modal.Title>
        </Modal.Header>
        <hr style={{ margin: '0px' }} />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <Form.Group controlId="form__name">
                  <Form.Label>
                    <span>
                      Name<span className="input__required">*</span>
                    </span>
                  </Form.Label>
                  <Form.Control autoComplete="off" type="text" {...register('name')} />
                  {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
                </Form.Group>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <Form.Group controlId="form__reference">
                    <Form.Label>
                      <span>Reference</span>
                    </Form.Label>
                    <Form.Control autoComplete="off" type="text" {...register('reference')} />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group>
                    <CustomSelect
                      isDisabled={false}
                      required={false}
                      controlId={'form__analsysCategoryId'}
                      label={'Analysis Category'}
                      control={control}
                      name={'analsysCategoryId'}
                      placeholder={'Select'}
                      options={dropDowns?.analysisCategory?.map(item => {
                        return {
                          value: +item?.id,
                          label: item?.value,
                        };
                      })}
                      errors={undefined}
                      enableMultilineOptions={false}
                      isSearchable={false}
                      register={register}
                      value={getValues('analsysCategoryId')}
                      readOnlyFormField={false}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <Form.Group style={{ gridColumn: '1/2', width: '300px' }}>
                    <Form.Label>Add Users</Form.Label>
                    <Select
                      labelId="demo-multiple-chip-checkbox-label"
                      style={{ border: '1px solid #cdcccc', width: '300px', borderRadius: '5px' }}
                      id="demo-mutiple-chip-checkbox"
                      multiple
                      value={personName}
                      placeholder={'Add Users'}
                      onChange={handleChange}
                      onOpen={() => console.log('select opened')}
                      renderValue={selected => <div></div>}
                    >
                      {accountUsers?.data?.items?.map(name => (
                        <MenuItem key={name.name} value={name.name}>
                          <Form.Check style={{ margin: '0px 10px' }} checked={personName.includes(name.name)} />
                          <ListItemText primary={name.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group controlId="isActive">
                    <Form.Label>
                      Active<span className="input__required">*</span>
                    </Form.Label>
                    <div>
                      <Form.Check disabled={false} type="radio" label="Yes" inline value="Yes" aria-label="isActive__radio-yes" id="form__isActive-yes" {...register('isActive')} />
                      <Form.Check disabled={false} type="radio" label="No" value="No" inline aria-label="isActive__radio-no" id="form__isActive-no" {...register('isActive')} />
                      {errors.isActive && (
                        <Form.Text className="text-danger" style={{ width: '200px' }}>
                          {errors.isActive.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div>
                {personName.map(value => (
                  <Chip
                    key={value}
                    label={value}
                    style={{ margin: '10px', borderRadius: '5px', border: '1px solid #cdcccc' }}
                    clickable
                    onDelete={e => handleDelete(e, value)}
                    onClick={() => console.log('clicked chip')}
                  />
                ))}
              </div>
            </div>
          </Modal.Body>
          <hr style={{ margin: '0px' }} />
          <Modal.Footer>
            <Button className="outline__button" variant="outline-primary" onClick={() => onHide()}>
              Cancel
            </Button>
            <Button className="full__button" variant="outline-primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
