import React, { useState, useEffect } from 'react';

import { getIsClient } from '../../components/modules/sideBar/slice/selectors';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAddTasksData } from './slice/selectors';
import { useRequestForQuoteSlice } from './slice';
import { useParams } from 'react-router-dom';
import PopupTaskNext from './PopupTaskNext';
import './style.sass';

const PopupTask = props => {
  const { show, onHide, data, onSetShow } = props;
  const { actions } = useRequestForQuoteSlice();
  const addTask = useSelector(getAddTasksData);
  const isClient = useSelector(getIsClient);
  const dispatch = useDispatch();
  const { jobId } = useParams();

  const [tasks, setTasks] = useState<{ id?: number; name?: string }[]>([]);
  const [nextModal, setNextModal] = useState(false);
  const [titleName, setTitleName] = useState('');
  const [taskId, setTaskId] = useState('');

  const PopupHandler = () => {
    setNextModal(true);
    onHide();
  };

  useEffect(() => {
    dispatch(actions.loadAddTasksData());
    dispatch(actions.loadAllUsers({ isClient: isClient, jobId: Number(jobId) }));
  }, []);

  useEffect(() => {
    setTasks(addTask);
  }, [addTask]);
  const handleClick = id => {
    setTaskId(id);
    tasks.map(item => {
      if (item.id === id) {
        setTitleName(item.name || '');
      }
    });
    setNextModal(false);
  };

  return (
    <div style={{ display: 'block', width: 200, height: 300, padding: 10 }}>
      <Modal backdrop="static" keyboard={false} show={show} centered onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Tasks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Table responsive hover size="lg">
              <thead>
                {tasks.map(task => (
                  <tr key={task.name}>
                    <th>
                      <Form.Check className="form-checkTaskList" aria-label={task.name} label={task.name} id={task.name} name="tasksList" onClick={() => handleClick(task.id)} type="radio" />
                    </th>
                  </tr>
                ))}
              </thead>
            </Table>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="outline__button"
            style={{ marginRight: '3%' }}
            onClick={() => {
              props.onHide();
            }}
          >
            Cancel
          </Button>
          <Button variant="outline-primary" className="outline__button" disabled={taskId == ''} onClick={PopupHandler}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
      {/* {tasks &&  */}
      {/* on top label we no need to add product id only on down task neede
       */}
      <PopupTaskNext
        show={nextModal}
        onHide={() => setNextModal(false)}
        data={data}
        taskId={taskId}
        productid={props.productid}
        titleName={titleName}
        task={{ status: 'Open' }}
        isProductId={props.isProductId}
        onBack={() => {
          onSetShow(true);
          setTaskId('');
        }}
      />
      {/* } */}
    </div>
  );
};
export default PopupTask;
