import React, { useState, useEffect } from 'react';

import { getEditJobRateList, getAccountService } from '../../slice/selector';
import CustomSelect from 'app/components/shared/CustomSelect';
import { protectedResources } from 'configs/msalAuthConfigs';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { request } from 'utils/request';
import * as yup from 'yup';

export const EditJobRate = props => {
  const { onHide, show, JobRateId } = props;
  const [error, setError] = useState('');
  const { actions: editAccountAction } = useInvoiceSlice();
  const accountService = useSelector(getAccountService);
  const { actions: accountAction } = useInvoiceSlice();
  const jobRateData = useSelector(getEditJobRateList);
  const { accountId } = useParams();
  const dispatch = useDispatch();

  const [editData, setEditData] = useState<any>(null);
  const checkOtherJobCostId = editData?.data.length > 0 && editData?.data[0]?.otherJobCostId === null;
  const validationSchema = yup.object({
    rateItemsId: yup.number().nullable(),
    name: yup.string().nullable(),
    description: yup.string().nullable(),
    isRateBased: yup.string().trim().nullable().nullable(),
    addUser: yup.string().trim().nullable().nullable(),
    sellRate: yup
      .number()
      .typeError('Minimum number of quotes 1-9.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable(),
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    setEditData(jobRateData);
  }, [jobRateData, JobRateId]);
  useEffect(() => {
    JobRateId && dispatch(editAccountAction.loadEditJobRateLis(JobRateId));
  }, [JobRateId]);
  useEffect(() => {
    if (accountId) reset({ ...editData?.data?.[0] });
    if (accountId) reset({ ...editData?.data?.[0], isRateBased: editData?.data?.[0]?.isRateBased ? 'Yes' : 'No' });
  }, [jobRateData, editData]);

  const { isRateBased } = useWatch({ control });
  const onSubmit = async (data: any) => {
    const formData = {
      id: JobRateId,
      accountId: Number(accountId),
      rateItemsId: data?.rateItemsId && data?.rateItemsId,
      name: data?.name && data?.name,
      description: data?.description && data?.description,
      isRateBased: data.isRateBased && data.isRateBased === 'Yes' ? true : false,
      sellRate: data?.sellRate && data?.sellRate,
    };
    try {
      const res = await request(`${protectedResources.api.apiEndpoint}/Admin/UpdateAccountJobRate/${JobRateId}`, {
        method: 'PUT',
        body: JSON.stringify(formData),
      }).then(response => {
        if (response.isSuccess) {
          accountId && dispatch(accountAction.loadJobRateList(+accountId));
          onHide();
          reset();
        } else {
          setError(response.message);
        }
      });
    } catch (err) {}
  };
  useEffect(() => {
    accountId && dispatch(accountAction.loadAccountService(+accountId));
  }, []);
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="fullScreen"
        style={{ width: '80%', marginLeft: '10%' }}
        centered
        dialogClassName="modal__printSpecificationForm"
        keyboard={false}
        onHide={() => {
          onHide();
        }}
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>Edit Job Rate</h2>
          </Modal.Title>
        </Modal.Header>
        <hr style={{ margin: '0px' }} />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body style={{ height: '450px', marginBottom: '30px' }}>
            <Form.Group style={{ gridColumn: '1', width: '400px' }}>
              <CustomSelect
                isDisabled={false}
                styles={{ gridColumn: '1' }}
                required={false}
                controlId={'form__rateItemsId'}
                label={'Service'}
                control={control}
                name={'rateItemsId'}
                placeholder={'Select'}
                options={accountService?.data?.items?.map(item => {
                  return {
                    value: +item?.id,
                    label: item?.value,
                  };
                })}
                errors={undefined}
                enableMultilineOptions={false}
                isSearchable={false}
                register={register}
                value={getValues('rateItemsId')}
                readOnlyFormField={false}
              />
            </Form.Group>
            <Form.Group style={{ gridColumn: '1/2', width: '400px' }} controlId="form__name">
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Name</span>
              </Form.Label>
              <Form.Control autoComplete="off" placeholder="Enter" type="text" {...register('name')} />
            </Form.Group>
            <Form.Group className="mb-3" style={{ gridColumn: '1/3', width: '600px' }} controlId="form_description">
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Description</span>
              </Form.Label>
              <Form.Control as="textarea" rows={3} autoComplete="off" type="text" {...register('description')} />
            </Form.Group>
            <Form.Group style={{ gridColumn: '3/3', width: '400px' }} controlId="isRateBased">
              <Form.Label>Rate Based ?</Form.Label>
              <div>
                <Form.Check type="radio" label="Yes" inline value="Yes" aria-label="isRateBased__radio-yes" disabled={!checkOtherJobCostId} id="form__isRateBased-yes" {...register('isRateBased')} />
                <Form.Check disabled={!checkOtherJobCostId} type="radio" label="No" value="No" inline aria-label="isRateBased__radio-no" id="form__isRateBased-no" {...register('isRateBased')} />
              </div>
            </Form.Group>
            {isRateBased === 'Yes' && (
              <Form.Group style={{ gridColumn: '1/2', width: '400px' }} controlId="form__sellRate">
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Sell Rate (£)</span>
                </Form.Label>
                <Form.Control disabled={!checkOtherJobCostId} autoComplete="off" placeholder="Enter" type="text" {...register('sellRate')} />
              </Form.Group>
            )}
            {error !== '' && (
              <div className="text-danger text-center w-100 pt-4">
                <strong className="fs-6">{error}</strong>
              </div>
            )}
          </Modal.Body>

          <hr style={{ margin: '0px' }} />
          <Modal.Footer>
            <Button
              style={{ border: '0px', color: 'black', width: '15%' }}
              variant="outline-primary"
              onClick={() => {
                onHide();
                setError('');
              }}
            >
              Cancel
            </Button>
            <Button variant="outline-primary" style={{ border: '0px', color: 'black', width: '15%' }} type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
