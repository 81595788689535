//  Externals
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
//  Internals
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getEntities, getItems } from './../../../../store/reducers/entities/selectors';
import { apiCallBegan, apiCallEnd, apiCallFailed } from 'store/reducers/loading';
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { useBreadcrumbSlice } from '../../../pages/breadcrumb/slice';
import { useLoadingSlice } from '../../../../store/reducers/loading';
import { setItems, setTotalCount } from 'store/reducers/entities';
import { getUserId } from '../sideBar/slice/selectors';
import callApi from './../../../../services/callApi';
import Breadcrumb from '../../../pages/breadcrumb';
import Pagination from './../../shared/Pagination';
import { Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import MODULES from '../../../../data/modules';
import STATUS from '../../../../data/status';
import { Helmet } from 'react-helmet-async';
import { Dropdown } from 'react-bootstrap';
import { request } from 'utils/request';
import URL from '../../../../data/url';
import API from '../../../../data/api';
import { isEmpty } from 'lodash';

const MyJobs = () => {
  const { actions: loadingActions } = useLoadingSlice();
  const { actions: breadcrumbActions } = useBreadcrumbSlice();

  const [showModelDelete, setShowModelDelete] = useState<boolean>(false);
  const [showModelCancel, setShowModelCancel] = useState<boolean>(false);
  const [showActionDropDown, setShowActionDropDown] = useState({});
  const [offHoldStatusId, setOffHoldStatusId] = useState<number>();
  const [cancelStatusId, setCancelStatusId] = useState<number>();
  const [onHoldStatusId, setOnHoldStatusId] = useState<number>();
  const jobs = useSelector(getItems(MODULES.MY_JOBS));
  const [deleteId, setDeleteId] = useState<number>();
  const [cancelId, setCancelId] = useState<number>();
  const UserId = useSelector(getUserId);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { page, limit } = useSelector(getEntities(MODULES.MY_JOBS));

  /******************************************************************************************
	@Purpose    :   To get the orders list
	*******************************************************************************************/
  const getJobs = useCallback(async () => {
    try {
      dispatch(apiCallBegan(''));
      const response: any =
        location?.pathname === '/orders'
          ? await callApi({ url: `${API.GET_ALL_JOBS}?PageNumber=${page}&PageSize=${limit}&Orders=${true}`, method: 'get' })
          : await callApi({ url: `${API.GET_ALL_JOBS}?PageNumber=${page}&PageSize=${limit}`, method: 'get' });
      if (response.isSuccess) {
        const { data } = response;
        const { items, totalCount } = data;
        //	Store the Data in Redux
        dispatch(setItems({ module: MODULES.MY_JOBS, items }));
        dispatch(setTotalCount({ module: MODULES.MY_JOBS, totalCount }));
      }

      dispatch(apiCallEnd());
    } catch (error) {
      console.error('error In ====>>>> getJobs <<<<====', error);
      dispatch(apiCallFailed());
    }
  }, [dispatch, page, limit]);

  useEffect(() => {
    getJobs();
  }, [page, limit, getJobs]);

  useEffect(() => {
    //  Update Job
    dispatch(breadcrumbActions.changeBreadcrumb([{ name: 'My Jobs', url: URL.MY_JOBS }]));
  }, [breadcrumbActions, dispatch, loadingActions]);

  /******************************************************************************************
	@Purpose    :   Copy Job
	*******************************************************************************************/
  const handleCopy = async id => {
    try {
      const response: any = await callApi({ url: `${API.COPY_JOB}/${id}`, method: 'get' });
      if (response.isSuccess) getJobs();
    } catch (error) {
      console.error('error In ====>>>> handleCopy <<<<====', error);
    }
  };

  /******************************************************************************************
	@Purpose    :   On Hold
	*******************************************************************************************/
  const handleOnHold = async id => {
    const data = {
      rfqJobId: id,
      status: onHoldStatusId,
    };
    try {
      const response: any = await callApi({ url: API.UPDATE_JOB_STATUS, method: 'post', body: data });
      if (response.isSuccess) getJobs();
    } catch (error) {
      console.error('error In ====>>>> handleOnHold <<<<====', error);
    }
  };

  /******************************************************************************************
	@Purpose    :   Off Hold
	*******************************************************************************************/
  const handleOffHold = async id => {
    const data = {
      rfqJobId: id,
      status: offHoldStatusId,
    };
    try {
      const response: any = await callApi({ url: API.UPDATE_JOB_STATUS, method: 'post', body: data });
      if (response.isSuccess) getJobs();
    } catch (error) {
      console.error('error In ====>>>> handleOffHold <<<<====', error);
    }
  };

  /******************************************************************************************
	@Purpose    :   Delete Job
	*******************************************************************************************/
  const handleDelete = async () => {
    const id = deleteId;
    try {
      const response: any = await callApi({ url: `${API.DELETE_JOB}/${id}`, method: 'delete' });
      if (response.isSuccess) getJobs();
    } catch (error) {
      console.error('error In ====>>>> handleDelete <<<<====', error);
    }
    setShowModelDelete(false);
  };

  /******************************************************************************************
	@Purpose    :   Cancel Job
	*******************************************************************************************/
  const handleCancel = async () => {
    const id = cancelId;
    const data = {
      rfqJobId: id,
      status: cancelStatusId,
    };
    try {
      const response: any = await callApi({ url: API.UPDATE_JOB_STATUS, method: 'post', body: data });
      if (response.isSuccess) getJobs();
    } catch (error) {
      console.error('error In ====>>>> handleCancel <<<<====', error);
    }
    setShowModelCancel(false);
  };

  /******************************************************************************************
	@Purpose    :   Navigate to the Job Details
	*******************************************************************************************/
  const handleSelectJob = (job, event) => {
    if (['Action', 'Add Discount', 'Copy', 'Cancel', 'On hold', 'Off hold', 'In Active', 'Delete'].includes(event?.target?.innerText)) {
      event.stopPropagation();
    } else {
      navigate(`${URL.MY_JOBS}/${job.id}`);
    }
  };

  // get status
  const getStatusList = async () => {
    const response = await request(`${protectedResources.api.apiEndpoint}/MasterData/getstatuslist`, {
      method: 'GET',
    });

    const cancelEntity = response.data.items.find((status: { code: string }) => status.code === STATUS.CANCELLED);
    const onHoldEntity = response.data.items.find((status: { code: string }) => status.code === STATUS.ON_HOLD);
    const offHoldEntity = response.data.items.find((status: { code: string }) => status.code === STATUS.OFF_HOLD);

    if (cancelEntity) setCancelStatusId(cancelEntity.id);
    if (onHoldEntity) setOnHoldStatusId(onHoldEntity.id);
    if (offHoldEntity) setOffHoldStatusId(offHoldEntity.id);
  };

  const removeActionDowdown = () => {
    var x: any = '';
    if (!isEmpty(showActionDropDown)) {
      x = Object.keys(showActionDropDown);
      setShowActionDropDown({ ...showActionDropDown, [Number(x)]: false });
    }
  };

  useEffect(() => {
    getStatusList();
  }, []);

  useEffect(() => {
    document.addEventListener('click', removeActionDowdown);

    return () => {
      document.removeEventListener('click', removeActionDowdown);
    };
  });

  return (
    <div className="myJobs__container">
      <Helmet>
        <title>My Jobs</title>
      </Helmet>
      <>
        <Modal
          backdrop="static"
          keyboard={false}
          show={showModelDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdropClassName="fullScreen"
          centered
          onHide={() => setShowModelDelete(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <span>Are you sure you want to delete this job?</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="modal_footer">
            <>
              <Button variant="outline-primary" onClick={() => setShowModelDelete(false)} className="outline__button">
                No
              </Button>
              <Button variant="outline-primary" onClick={handleDelete} className="full__button">
                Yes
              </Button>
            </>
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal
          backdrop="static"
          keyboard={false}
          show={showModelCancel}
          aria-labelledby="contained-modal-title-vcenter"
          backdropClassName="fullScreen"
          centered
          onHide={() => setShowModelCancel(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <span>Are you sure you want to cancel this job?</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="modal_footer">
            <>
              <Button variant="outline-primary" onClick={() => setShowModelCancel(false)} className="outline__button">
                No
              </Button>
              <Button variant="outline-primary" onClick={handleCancel} className="full__button">
                Yes
              </Button>
            </>
          </Modal.Footer>
        </Modal>
      </>
      <Breadcrumb />
      <div style={{ border: '1px solid #CCCCCC', margin: '10px' }}>
        {jobs && (
          <>
            <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none', minHeight: 'calc(100vh - 120px)', background: 'transparent' }}>
              <Table aria-label="collapsible table" className="productIte__table">
                <TableHead>
                  <TableRow style={{ backgroundColor: '#F2F5F5' }}>
                    <TableCell style={{ paddingLeft: '1rem' }}>Job Number</TableCell>
                    <TableCell style={{ padding: '0px' }} sx={{ paddingLeft: 0 }} align="left">
                      Title
                    </TableCell>
                    <TableCell style={{ padding: '0px' }}>Account</TableCell>
                    <TableCell style={{ padding: '0px' }}>Business unit</TableCell>
                    <TableCell style={{ padding: '0px' }}>Submitted</TableCell>
                    <TableCell style={{ padding: '0px' }}>Status</TableCell>
                    <TableCell style={{ padding: '0px' }} align="center">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs &&
                    jobs?.map(item => {
                      return (
                        <TableRow onClick={event => handleSelectJob(item, event)} style={{ backgroundColor: '#F2F5F5', cursor: 'pointer' }} key={item.id}>
                          <TableCell style={{ paddingLeft: '1rem' }}>{item.jobNumber}</TableCell>
                          <TableCell style={{ padding: '0px' }} sx={{ paddingLeft: 0 }} align="left">
                            {item.title}
                          </TableCell>
                          <TableCell style={{ padding: '0px' }}>{item.accountName}</TableCell>
                          <TableCell style={{ padding: '0px' }}>{item.businessUnit}</TableCell>
                          <TableCell style={{ padding: '0px' }}>{item.submitted ? moment(item.submitted).format('DD/MM/YYYY') : '-'}</TableCell>
                          <TableCell style={{ padding: '0px' }}>{item.jobStatusName}</TableCell>
                          <TableCell style={{ padding: '0px' }} align="center">
                            <Dropdown show={showActionDropDown[item.id]}>
                              <Dropdown.Toggle
                                onClick={() => setShowActionDropDown({ [item.id]: true })}
                                disabled={
                                  ![
                                    'Draft',
                                    'Recheck',
                                    'New RFQ',
                                    'On hold',
                                    'Delivered',
                                    'Quotation',
                                    'Amended RFQ',
                                    'Part Ordered',
                                    'Ordered',
                                    'Part Delivered',
                                    'To Invoice',
                                    'Re-check',
                                    'Invoiced',
                                    'Part Invoiced',
                                    'Cancelled',
                                  ].includes(item?.jobStatusName)
                                }
                                id="dropdown-actions"
                                style={{
                                  backgroundColor: '#F2F5F5',
                                  height: '30px',
                                  zIndex: '5',
                                  border: 'none',
                                  color: 'gray',
                                }}
                              >
                                Action
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {[
                                  'Draft',
                                  'Recheck',
                                  'New RFQ',
                                  'On Hold',
                                  'On hold',
                                  'Delivered',
                                  'Quotation',
                                  'Amended RFQ',
                                  'Part Ordered',
                                  'Ordered',
                                  'Part Delivered',
                                  'To Invoice',
                                  'Re-check',
                                  'Invoiced',
                                  'Part Invoiced',
                                  'Cancelled',
                                ].includes(item?.jobStatusName) && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      handleCopy(item.id);
                                      removeActionDowdown();
                                    }}
                                  >
                                    Copy
                                  </Dropdown.Item>
                                )}
                                {['New RFQ', 'Quotation', 'Amended RFQ', 'Part Ordered', 'Ordered'].includes(item?.jobStatusName) && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      setCancelId(item.id);
                                      setShowModelCancel(true);
                                      removeActionDowdown();
                                    }}
                                  >
                                    Cancel
                                  </Dropdown.Item>
                                )}
                                {['New RFQ', 'Quotation', 'Amended RFQ'].includes(item?.jobStatusName) && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      handleOnHold(item.id);
                                      removeActionDowdown();
                                    }}
                                  >
                                    On hold
                                  </Dropdown.Item>
                                )}
                                {item?.jobStatusName === 'On hold' && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      handleOffHold(item.id);
                                      removeActionDowdown();
                                    }}
                                  >
                                    Off hold
                                  </Dropdown.Item>
                                )}
                                {item?.jobStatusName === 'Draft' && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      setDeleteId(item.id);
                                      setShowModelDelete(true);
                                      removeActionDowdown();
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
      <Pagination module={MODULES.MY_JOBS} name="jobs" />
    </div>
  );
};

export default MyJobs;
