import React, { useEffect } from 'react';
import './style.sass';
import { useDispatch, useSelector } from 'react-redux';
import CustomerDashboard from './dashboards/CustomerDashboard';
import InternalCdsDashboard from './dashboards/InternalCdsDashboard';
import SupplierDashboard from './dashboards/SupplierDashboard';
import { getIsCDS, getIsClient, getIsSupplier } from '../../components/modules/sideBar/slice/selectors';
import { useDashboardSlice } from './slice';
import Form from 'react-bootstrap/Form';
import { getSelectFilterList } from './slice/selectors';

const Dashboard = () => {
  const { actions } = useDashboardSlice();
  const dispatch = useDispatch();
  const userRoles = {
    isCDS: useSelector(getIsCDS),
    isClient: useSelector(getIsClient),
    isSupplier: useSelector(getIsSupplier),
  };
  const selectFilterList = useSelector(getSelectFilterList);

  useEffect(() => {
    userRoles.isSupplier &&
      dispatch(actions.loadDashboard({ url: 'SupplierDashboard' })) &&
      dispatch(actions.loadSelectFilterList({ url: 'Supplier/api/Supplier/GetAllSupplier', name: 'supplierDashboard' }));
    userRoles.isClient && dispatch(actions.loadDashboard({ url: 'CustomerDashboard' })) && dispatch(actions.loadSelectFilterList({ url: 'Admin/GetAllAccountDetail', name: 'customerDashboard' }));
    userRoles.isCDS && dispatch(actions.loadDashboard({ url: 'InternalDashboard' })) && dispatch(actions.loadSelectFilterList({ url: 'User/GetAvailableTeamMemebrs', name: 'internalCds' }));
  }, []);

  const handleFilterChange = (val: string) => {
    userRoles.isSupplier && dispatch(actions.loadDashboard({ url: `SupplierDashboard?SupplierId=${Number(val)}` }));
    userRoles.isClient && dispatch(actions.loadDashboard({ url: `CustomerDashboard?AccountId=${Number(val)}` }));
    userRoles.isCDS && dispatch(actions.loadDashboard({ url: `InternalDashboard?UserId=${Number(val)}` }));
  };
  return (
    <div className="d-flex flex-column bg-white">
      <div className="ms-auto p-3">
        {!userRoles.isSupplier && (
          <Form.Select aria-label="" size="sm" defaultValue={'Select'} onChange={e => handleFilterChange(e.target.value)}>
            <option value="Select" hidden>
              {userRoles.isCDS && 'Select user'}
              {userRoles.isClient && 'Select account'}
            </option>

            {selectFilterList?.map(item => (
              <option value={item.id} key={item.id + item.name}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        )}
      </div>
      {userRoles.isCDS && <InternalCdsDashboard userRoles={userRoles} />}
      {userRoles.isClient && <CustomerDashboard userRoles={userRoles} />}
      {userRoles.isSupplier && <SupplierDashboard userRoles={userRoles} />}
    </div>
  );
};

export default Dashboard;
