//  Externals
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
//  Internals
import { ReactComponent as AddProductItemIcon } from 'images/addProductItem.svg';
import { useBreadcrumbSlice } from '../../breadcrumb/slice';
import { getClientUsers } from '../slice/selectors';
import { useRegistrationSlice } from '../slice';
import AddClientUser from './AddClientUser';
import { initialClientUser } from './types';
import Breadcrumb from '../../breadcrumb';

const ClientUser = () => {
  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  const { actions } = useRegistrationSlice();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.loadClientOrSupplierUser({ isClient: true, isSupplier: false }));
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Dashboard', url: '/dashboard' },
        { name: 'Administration/ClientUser', url: '/administration/clientuser' },
      ]),
    );
  }, [actions, breadcrumbActions, dispatch]);

  const clientUsers = useSelector(getClientUsers);

  const [clientUser, setClientUser] = useState({ ...initialClientUser });

  const [showModal, setShowModal] = useState(false);
  const [create, setCreate] = useState(false);

  /******************************************************************************************
  @Purpose    :  Add Supplier User
  *******************************************************************************************/
  const handleAdd = () => {
    setShowModal(true);
    setCreate(true);
    setClientUser({ ...initialClientUser });
  };

  /******************************************************************************************
  @Purpose    :  Update Supplier User
  *******************************************************************************************/
  const handleUpdate = entity => {
    setShowModal(true);
    setCreate(false);
    setClientUser({ ...entity, active: entity.active ? 'Yes' : 'No' });
  };

  return (
    <div>
      <Helmet>
        <title>Administration Client User</title>
      </Helmet>
      <Breadcrumb />
      <div className="rfq__container">
        <div className="products__page">
          <div className="productsPage__header">
            <h4>Client Users ({clientUsers.length})</h4>
            <span className="addAccount__button" onClick={() => handleAdd()}>
              Add Client User
              <AddProductItemIcon className="svg__icon" />
            </span>
          </div>
          <div className="products__content">
            <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
              <Table aria-label="collapsible table" className="productItem__details__table">
                <TableHead>
                  <TableRow style={{ height: '3rem' }}>
                    <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '2rem' }} sx={{ paddingLeft: 0 }} align="left">
                      Name
                    </TableCell>
                    <TableCell className="productItem__details__headerCell">Email</TableCell>
                    <TableCell className="productItem__details__headerCell">Account</TableCell>
                    <TableCell className="productItem__details__headerCell">Telephone</TableCell>
                    <TableCell className="productItem__details__headerCell">Last login</TableCell>
                    <TableCell className="productItem__details__headerCell">Status</TableCell>
                    <TableCell className="productItem__details__headerCell">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientUsers &&
                    clientUsers?.map(clientUser => (
                      <Fragment key={clientUser.userId}>
                        <TableRow sx={{ height: '4px' }}></TableRow>
                        <TableRow
                          className="productItem__details__row"
                          onClick={() => {
                            handleUpdate(clientUser);
                          }}
                          style={{ cursor: 'pointer', height: '2.5rem' }}
                        >
                          <TableCell className="productItem__details__bodyCell" style={{ paddingLeft: '25px' }} sx={{ paddingLeft: 0 }} align="left">
                            {clientUser?.firstName} {clientUser?.lastName}
                          </TableCell>
                          <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                            {clientUser.emailAddress}
                          </TableCell>
                          <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                            {clientUser.accountName}
                          </TableCell>
                          <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                            {clientUser.telephoneNumber}
                          </TableCell>
                          <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                            {clientUser?.lastLogin != null ? `${moment(clientUser?.lastLogin).format('HH:mm DD/MM/YYYY')}` : ''}
                          </TableCell>
                          <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                            {clientUser.active ? 'Active' : 'Inactive'}
                          </TableCell>
                          <TableCell className="productItem__details__bodyCell table__edit" sx={{ paddingLeft: 0 }}>
                            <span style={{ color: '#1BA39C', marginRight: '20px' }}>Edit</span>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      {showModal && (
        <AddClientUser
          create={create}
          clientUser={clientUser}
          onHide={() => {
            setCreate(false);
            setShowModal(false);
            setClientUser({ ...initialClientUser });
          }}
          show={showModal}
        />
      )}
    </div>
  );
};

export default ClientUser;
