import React, { useEffect, useState } from 'react';

import { TableCell, TableContainer, Table, TableHead, TableBody, TableRow, Paper } from '@mui/material';
import { ReactComponent as AddProductItemIcon } from '../../../../../images/addProductItem.svg';
import { protectedResources } from 'configs/msalAuthConfigs';
import { getSuppliersList } from '../../slice/selector';
import { useDispatch, useSelector } from 'react-redux';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import { request } from 'utils/request';

export const SuppliersList = props => {
  const { openSuppliersPopup } = props;
  const dispatch = useDispatch();
  const [suppliersData, setSuppliersData] = useState<any>();
  const { actions: loadSuppliers } = useInvoiceSlice();
  const SuppliersRows = useSelector(getSuppliersList);
  const { accountId } = useParams();

  useEffect(() => {
    setSuppliersData(SuppliersRows);
  }, [SuppliersRows]);

  useEffect(() => {
    accountId && dispatch(loadSuppliers.loadSuppliersList(+accountId));
  }, [accountId, dispatch, loadSuppliers]);

  const HandleDelete = async suppliersId => {
    const formData = {
      accountId: Number(accountId),
      supplierIds: [suppliersId],
      isRemove: true,
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/AD/AddORRemoveAccountSupplier`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      accountId && dispatch(loadSuppliers.loadSuppliersList(+accountId));
    } catch (err) {}
  };

  return (
    <div>
      <div className="productsPage__header">
        <span>Suppliers excluded from this account</span>
        <span className="header__button" onClick={() => openSuppliersPopup()}>
          Add Supplier
          <AddProductItemIcon className="svg__icon" />
        </span>
      </div>
      <div className="products__content">
        <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
          <Table aria-label="collapsible table" className="productItem__details__table">
            <TableHead>
              <TableRow>
                <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '5rem' }}>
                  Name
                </TableCell>
                <TableCell className="productItem__details__headerCell" align="right" style={{ paddingRight: '4rem' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suppliersData &&
                suppliersData?.data?.items?.map(item => (
                  <TableRow key={item.supplierID}>
                    <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '4rem' }}>
                      {item.name}
                    </TableCell>
                    <TableCell onClick={() => HandleDelete(item.supplierID)} className="productItem__details__headerCell" align="right" style={{ paddingRight: '4rem' }}>
                      Delete
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
