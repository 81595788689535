import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TableCell, TableRow, TableHead, TableBody } from '@mui/material';
import { selectDropDown, getAccountData } from '../../slice/selector';
import { useBreadcrumbSlice } from '../../../breadcrumb/slice';
import { BUSINESSUNIT } from 'app/components/shared/Constants';
import CustomSelect from 'app/components/shared/CustomSelect';
import { protectedResources } from 'configs/msalAuthConfigs';
import { getEditAccountData } from '../../slice/selector';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import { request } from 'utils/request';
import Select from 'react-select';
import * as yup from 'yup';

const InvoiceFreq = [
  {
    value: 'Weekly',
  },
  {
    value: 'Monthly',
  },
];

export const AccountDetails = () => {
  const dispatch = useDispatch();
  const getDropdowns = useSelector(selectDropDown);
  const accountRow = useSelector(getEditAccountData);
  const allAccounts = useSelector(getAccountData);
  const { actions: accountAction } = useInvoiceSlice();
  const { actions: rfqActions } = useInvoiceSlice();
  const { actions: editAccountById } = useInvoiceSlice();
  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  const [dropDowns, setDropDowns] = useState<any>({});
  const [geographicLocations, setGeographicLocations] = useState<number[]>([]);
  const [contractOptions, setContractOptions] = useState<number[]>([]);
  const [samples, setSamples] = useState(false);
  const [submitState, setSubmitState] = useState<boolean>(false);
  const navigate = useNavigate();
  const { accountId } = useParams();
  const phoneRegExp = /^[0-9 ]+$/;
  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .transform(value => ([...allAccounts?.data?.items?.map(item => item.name)].filter(index => index != accountRow?.data?.[0]?.name).includes(value) ? true : value))
      .nullable()
      .typeError('An account with this name already exists.  Please provide another name')
      .required('Account name is required'),
    primaryCdscontactId: yup.string().nullable().required('Primary CDS Contact is required'),
    secondaryCdscontactId: yup.string().nullable().required('Secondary CDS Contact is required'),
    cdsownerId: yup.number().nullable().required('CDS Owner Id is required'),
    reference: yup.string().nullable(),
    isActive: yup.string().trim().nullable().required('Please provide Status.'),
    postCode: yup.string().nullable(),
    telephone: yup
      .string()
      .ensure()
      .when({ is: val => val?.length > 0, then: yup.string().trim().nullable().matches(phoneRegExp, 'Please Enter Valid Number').max(20, 'Please Enter Valid Number') }),
    contact: yup.string().nullable(),
    email: yup.string().nullable(),
    parentContractId: yup.string().nullable(),
    sectorId: yup.string().nullable(),
    hubspotReference: yup.string().nullable(),
    cdsEmailAddress: yup
      .string()
      .transform(value =>
        value?.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
          ? value
          : undefined,
      )
      .nullable(),
    codePrefix: yup.string().nullable().required('Code Prefix is required'),
    cdsbusinesUnitId: yup.string().nullable(),
    exportTypeId: yup.string().nullable().required('Please select Export Type'),
    invoiceAddress: samples ? yup.string().required('Invoice Address is required field') : yup.string().nullable(),
    invoicePostCode: samples ? yup.string().required('Invoice Postcode is required field') : yup.string().nullable(),
    invoiceContactName: samples ? yup.string().required('Invoice Contact Name is required field') : yup.string().nullable(),
    invoiceContactEmailAddress: samples ? yup.string().required('Invoice Contact Email Address is required ') : yup.string().nullable(),
    invoiceFrequency: yup.string().nullable(),
    partialInvoiceAllowed: yup.string().trim().nullable().required('Please select any one'),
    managementFee: yup
      .number()
      .typeError('Management Fee is required')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Management Fee is required')
      .max(99, 'Management Fee must be less then 99.'),
    supplierRebateRate: yup
      .number()
      .typeError('Supplier Rebate Rate is required')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Supplier Rebate Rate is required')
      .max(99, 'Supplier Rebate Rate must be less then 99.'),
    materialBrokerageOverrideRate: yup
      .number()
      .nullable()
      .transform(value => (isNaN(value) ? null : value))
      .max(99, 'Supplier Rebate Rate must be less then 99.'),
    vatlevy: yup.string().nullable(),
    isVatincludedOnQuotes: yup.string().trim().nullable().required('Include VAT is required'),
    accountCode: yup.string().nullable(),
    codePrint: yup.string().nullable(),
    codeTechnical: yup.string().nullable(),
    codeScanning: yup.string().nullable(),
    codePurchase: yup.string().nullable(),
    codeDistribution: yup.string().nullable(),
    cordStorage: yup.string().nullable(),
    codeProfessional: yup.string().nullable(),
    codeReprographic: yup.string().nullable(),
    codeJournalism: yup.string().nullable(),
    codeEditorial: yup.string().nullable(),
    codeDesign: yup.string().nullable(),
    codeMagazine: yup.string().nullable(),
    codeInternet: yup.string().nullable(),
    quoteToClientStandardKpi: yup
      .number()
      .typeError('Quote to client standard KPI in Between 1-99.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Quote to client standard KPI in Between 1-99.')
      .min(1, 'Quote to client standard KPI in Between 1-99.')
      .max(99, 'Quote to client standard KPI in Between 1-99.'),
    quoteToClientComplexKpi: yup.string().trim().nullable().required('Quote to client complex KPI is required'),
    proofToClientKpi: yup.string().trim().nullable().required('Proof to client KPI is required'),
    minimumNumberOfQuotesRequired: yup
      .number()
      .typeError('Minimum number of quotes 1-9.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .min(1, 'Minimum number of quotes 1-9.')
      .max(9, 'Minimum number of quotes 1-9.'),
    analysisCategory: yup.string().nullable(),
    showDelivery: yup.string().nullable(),
    businessUnitname: yup.string().nullable(),
    businessUnitreference: yup.string().nullable(),
    businessUnitCategory: yup.string().nullable(),
    businessUnitIsActive: yup.string().trim().nullable().nullable(),
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: JSON.parse(JSON.stringify(accountRow)),
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (accountId)
      reset({
        ...accountRow?.data?.[0],
        materialBrokerageOverrideRate: Number(accountRow?.data?.[0]?.materialBrokerageOverrideRate).toFixed(2),
        partialInvoiceAllowed: accountRow?.data?.[0]?.partialInvoiceAllowed ? 'Yes' : 'No',
        isVatincludedOnQuotes: accountRow?.data?.[0]?.isVatincludedOnQuotes ? 'Yes' : 'No',
        supplierRebateRate: Number(accountRow?.data?.[0]?.supplierRebateRate).toFixed(2),
        secondaryCdscontactId: Number(accountRow?.data?.[0]?.secondaryCdscontactId),
        primaryCdscontactId: Number(accountRow?.data?.[0]?.primaryCdscontactId),
        geographicLocations: Number(accountRow?.data?.[0]?.geographicLocations),
        managementFee: Number(accountRow?.data?.[0]?.managementFee).toFixed(2),
        analysisCategory: Number(accountRow?.data?.[0]?.analysisCategory),
        contractOptions: Number(accountRow?.data?.[0]?.contractOptions),
        invoiceFrequency: accountRow?.data?.[0]?.invoiceFrequency,
        showDelivery: accountRow?.data?.[0]?.showDelivery ? 'Yes' : 'No',
        isActive: accountRow?.data?.[0]?.isActive ? 'Yes' : 'No',
        vatlevy: Number(accountRow?.data?.[0]?.vatlevy),
      });
    setGeographicLocations(accountRow?.data?.[0]?.geographicLocations?.map(o => Number(o)));
    setContractOptions(accountRow?.data?.[0]?.contractOptions?.map(o => Number(o)));
    setDropDowns(getDropdowns?.data);
    setSamples(accountRow?.data?.[0]?.exportTypeId === 1);
  }, [accountId, accountRow, getDropdowns?.data, reset]);

  useEffect(() => {
    dispatch(accountAction.loadDropDowns());
    accountId && dispatch(editAccountById.editAccount(+accountId));
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Dashboard', url: '/Dashboard' },
        { name: 'Administration/Account', url: '/Administration/Account' },
        { name: accountRow?.data?.[0]?.name, url: '#' },
      ]),
    );
  }, [accountAction, accountId, breadcrumbActions, dispatch, editAccountById]);

  const onError = error => {
    console.log('ERROR:::', error);
  };

  const handleInputChange = e => {
    const exportType = dropDowns?.exportType?.filter(item => item.id === e)?.[0]?.value === 'Collection';
    setSamples(exportType);
  };
  const onSubmit = async (data: any) => {
    const formData = {
      accountId: 0,
      name: data?.name ? data?.name : null,
      primaryCdscontactId: data.primaryCdscontactId ? data.primaryCdscontactId : null,
      secondaryCdscontactId: data.secondaryCdscontactId ? data.secondaryCdscontactId : null,
      cdsownerId: data.cdsownerId ? +data.cdsownerId : null,
      reference: data.reference ? data.reference : null,
      businessAddress: data.businessAddress ? data.businessAddress : null,
      postCode: data.postCode ? data.postCode : null,
      telephone: data.telephone ? data.telephone : null,
      contact: data.contact ? data.contact : null,
      email: data.email ? data.email : null,
      parentContractId: data.parentContractId ? +data.parentContractId : null,
      geographicLocations: geographicLocations ? geographicLocations : null,
      isActive: data.isActive && data.isActive === 'Yes' ? true : false,
      sectorId: data.sectorId ? +data.sectorId : null,
      hubspotReference: data.hubspotReference ? data.hubspotReference : null,
      cdsEmailAddress: data.cdsEmailAddress ? data.cdsEmailAddress : null,
      codePrefix: data.codePrefix ? data.codePrefix : null,
      cdsbusinesUnitId: data.cdsbusinesUnitId ? +data.cdsbusinesUnitId : null,
      exportTypeId: data.exportTypeId ? +data.exportTypeId : null,
      invoiceAddress: data.invoiceAddress ? data.invoiceAddress : null,
      invoicePostCode: data.invoicePostCode ? data.invoicePostCode : null,
      invoiceContactName: data.invoiceContactName ? data.invoiceContactName : null,
      invoiceContactEmailAddress: data.invoiceContactEmailAddress ? data.invoiceContactEmailAddress : null,
      invoiceFrequency: data.invoiceFrequency ? data.invoiceFrequency : null,
      partialInvoiceAllowed: data.partialInvoiceAllowed && data.partialInvoiceAllowed === 'Yes' ? true : false,
      contractOptions: contractOptions ? contractOptions : null,
      quoteToClientStandardKpi: data.quoteToClientStandardKpi ? +data.quoteToClientStandardKpi : null,
      quoteToClientComplexKpi: data.quoteToClientComplexKpi ? +data.quoteToClientComplexKpi : null,
      proofToClientKpi: data.proofToClientKpi ? +data.proofToClientKpi : null,
      minimumNumberOfQuotesRequired: data.minimumNumberOfQuotesRequired ? +data.minimumNumberOfQuotesRequired : null,
      managementFee: data.managementFee ? +data.managementFee : null,
      supplierRebateRate: data.supplierRebateRate ? +data.supplierRebateRate : null,
      materialBrokerageOverrideRate: data.materialBrokerageOverrideRate ? +data.materialBrokerageOverrideRate : null,
      vatlevy: data.vatlevy ? +data.vatlevy : null,
      isVatincludedOnQuotes: data.isVatincludedOnQuotes && data.isVatincludedOnQuotes === 'Yes' ? true : false,
      accountCode: data.accountCode ? data.accountCode : null,
      codePrint: data.codePrint ? data.codePrint : null,
      codeTechnical: data.codeTechnical ? data.codeTechnical : null,
      codeScanning: data.codeScanning ? data.codeScanning : null,
      codePurchase: data.codePurchase ? data.codePurchase : null,
      codeDistribution: data.codeDistribution ? data.codeDistribution : null,
      cordStorage: data.cordStorage ? data.cordStorage : null,
      codeProfessional: data.codeProfessional ? data.codeProfessional : null,
      codeReprographic: data.codeReprographic ? data.codeReprographic : null,
      codeJournalism: data.codeJournalism ? data.codeJournalism : null,
      codeEditorial: data.codeEditorial ? data.codeEditorial : null,
      codeDesign: data.codeDesign ? data.codeDesign : null,
      codeMagazine: data.codeMagazine ? data.codeMagazine : null,
      codeInternet: data.codeInternet ? data.codeInternet : null,
      analysisCategory: data.analysisCategory ? data.analysisCategory : null,
      showDelivery: data.showDelivery && data.showDelivery === 'Yes' ? true : false,
      accountBusinessUnit: [
        {
          businessUnitId: 0,
          name: data.businessUnitname ? data.businessUnitname : null,
          parentAccountId: 0,
          analsysCategoryId: data.businessUnitCategory ? +data.businessUnitCategory : null,
          reference: data.businessUnitreference ? data.businessUnitreference : null,
          isActive: data.businessUnitIsActive && data.businessUnitIsActive === 'Yes' ? true : false,
        },
      ],
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Admin/UpdateAccountDetail/${accountId}`, {
        method: 'PUT',
        body: JSON.stringify(formData),
      }).then(response => {
        if (response.isSuccess) {
          submitState == false ? dispatch(rfqActions.selectNavigationButton(BUSINESSUNIT)) : navigate(`/administration/account`);
        }
      });
    } catch (err) {}
  };

  const handleGeographicLocations = val => {
    if (val) {
      setGeographicLocations(val?.map(o => o.value));
    } else setGeographicLocations([]);
  };

  const handleContractOptions = val => {
    if (val) {
      setContractOptions(val?.map(o => o.value));
    } else setContractOptions([]);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit, onError)} id="printSpecificationsForm" className="printSpecifications__form">
        <>
          <Form.Group controlId="form__name" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Account Name<span className="input__required">*</span>
              </span>
            </Form.Label>
            <Form.Control placeholder="Enter Account Name" autoComplete="off" className={errors.name && 'border-danger'} type="text" {...register('name')} maxLength={100} />
            {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
          </Form.Group>
          <Form.Group style={{ gridColumn: '2/2' }}>
            <CustomSelect
              isDisabled={false}
              styles={{ gridColumn: '2/2' }}
              required={true}
              controlId="form__cdsownerId"
              label={'CDS Owner'}
              control={control}
              name={'cdsownerId'}
              placeholder={'Select'}
              options={dropDowns?.users?.map(item => {
                return {
                  value: +item?.id,
                  label: item?.value,
                };
              })}
              errors={errors.cdsownerId}
              enableMultilineOptions={false}
              isSearchable={false}
              register={register}
              value={getValues('cdsownerId')}
              readOnlyFormField={false}
            />
          </Form.Group>
          <Form.Group controlId="form__reference" style={{ gridColumn: '1/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Reference</span>
            </Form.Label>
            <Form.Control placeholder="Enter Reference" autoComplete="off" type="text" {...register('reference')} maxLength={100} />
          </Form.Group>
          <Form.Group controlId="form__isActive" style={{ gridColumn: '2/2' }}>
            <Form.Label>
              Account Active<span className="input__required">*</span>
            </Form.Label>
            <div>
              <Form.Check disabled={false} type="radio" label="Yes" inline value="Yes" aria-label="isActive__radio-yes" id="form__isActive-yes" {...register('isActive')} />
              <Form.Check disabled={false} type="radio" label="No" value="No" inline aria-label="isActive__radio-no" id="form__isActive-no" {...register('isActive')} />
              {errors.isActive && <Form.Text className="text-danger">{errors.isActive.message}</Form.Text>}
            </div>
          </Form.Group>
          <Form.Group style={{ gridColumn: '1/2' }}>
            <CustomSelect
              isDisabled={false}
              styles={{ gridColumn: '1/2' }}
              required={true}
              controlId="form__primaryCdsContactId"
              label={'Primary CDS Contact'}
              control={control}
              name="primaryCdscontactId"
              placeholder={'Select'}
              options={dropDowns?.primaryCdscontact?.map(item => {
                return {
                  value: +item?.id,
                  label: item?.value,
                };
              })}
              errors={errors.primaryCdscontactId}
              enableMultilineOptions={false}
              isSearchable={false}
              register={register}
              value={getValues('primaryCdscontactId')}
              readOnlyFormField={false}
            />
          </Form.Group>
          <Form.Group style={{ gridColumn: '2/2' }}>
            <CustomSelect
              isDisabled={false}
              styles={{ gridColumn: '2/2' }}
              required={true}
              controlId={'form__secondaryCdscontactId'}
              label={'Secondary CDS Contact'}
              control={control}
              name={'secondaryCdscontactId'}
              placeholder={'Select'}
              options={dropDowns?.secondaryCdscontact?.map(item => {
                return {
                  value: +item?.id,
                  label: item?.value,
                };
              })}
              errors={errors.secondaryCdscontactId}
              enableMultilineOptions={false}
              isSearchable={false}
              register={register}
              value={getValues('secondaryCdscontactId')}
              readOnlyFormField={false}
            />
          </Form.Group>
          <Form.Group className="mb-3" style={{ gridColumn: '1 / 3' }} controlId="form_businessAddress">
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Business Address</span>
            </Form.Label>
            <Form.Control placeholder="Enter Business Address" as="textarea" rows={3} autoComplete="off" type="text" {...register('businessAddress')} />
          </Form.Group>
          <Form.Group controlId="form__postCode" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Post Code</span>
            </Form.Label>
            <Form.Control placeholder="Enter Post Code" autoComplete="off" type="text" {...register('postCode')} />
          </Form.Group>
          <Form.Group controlId="form__telephone" style={{ gridColumn: '2/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Telephone</span>
            </Form.Label>
            <Form.Control placeholder="Enter Telephone" autoComplete="off" type="text" {...register('telephone')} />
            {errors.telephone && <Form.Text className="text-danger">{errors.telephone.message}</Form.Text>}
          </Form.Group>

          <Form.Group controlId="form__contact" style={{ gridColumn: '1' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Contact</span>
            </Form.Label>
            <Form.Control placeholder="Enter Contact" autoComplete="off" type="text" {...register('contact')} />
          </Form.Group>
          <Form.Group controlId="form__title" style={{ gridColumn: '2/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Email</span>
            </Form.Label>
            <Form.Control placeholder="Enter Email" autoComplete="off" type="text" {...register('email')} />
          </Form.Group>
          <Form.Group style={{ gridColumn: '1/3' }}>
            <CustomSelect
              isDisabled={false}
              styles={{ gridColumn: '1/3' }}
              required={false}
              controlId={'form__parentContractId'}
              label={'Contract'}
              control={control}
              name={'parentContractId'}
              placeholder={'Select'}
              options={dropDowns?.contract?.map(item => {
                return {
                  value: +item?.id,
                  label: item?.value,
                };
              })}
              errors={undefined}
              enableMultilineOptions={false}
              isSearchable={false}
              register={register}
              value={getValues('parentContractId')}
            />
          </Form.Group>
          <Form.Group style={{ gridColumn: '1/3' }}>
            <span>Geographic locations</span>
            <Controller
              control={control}
              name="geographicLocations"
              render={({ field: { name, onBlur, onChange, ref, value } }) => {
                return (
                  <Select
                    isMulti
                    name="geographicLocations"
                    onBlur={onBlur}
                    onChange={(val, { action }) => {
                      if (action === 'clear') {
                        onChange(null);
                      } else {
                        onChange(val);
                      }
                      handleGeographicLocations(val);
                    }}
                    options={dropDowns?.geoLocation?.map(item => {
                      return {
                        value: +item?.id,
                        label: item?.value,
                      };
                    })}
                    placeholder="Select"
                    ref={ref}
                    value={dropDowns?.geoLocation
                      ?.map(item => {
                        return {
                          value: +item?.id,
                          label: item?.value,
                        };
                      })
                      .filter(item => geographicLocations?.includes(item.value))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                );
              }}
            />
          </Form.Group>
          <Form.Group style={{ gridColumn: '1/3' }}>
            <CustomSelect
              isDisabled={false}
              styles={{ gridColumn: '1/3' }}
              required={false}
              controlId={'form__sectorId'}
              label={'Sector'}
              control={control}
              name={'sectorId'}
              placeholder={'Select'}
              options={dropDowns?.sector?.map(item => {
                return {
                  value: +item?.id,
                  label: item?.value,
                };
              })}
              errors={undefined}
              enableMultilineOptions={false}
              isSearchable={false}
              register={register}
              value={getValues('sectorId')}
              readOnlyFormField={false}
            />
          </Form.Group>
          <Form.Group controlId="form__hubspotReference" style={{ gridColumn: '1' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Hubspot Reference</span>
            </Form.Label>
            <Form.Control placeholder="Enter Hubspot Reference" autoComplete="off" type="text" {...register('hubspotReference')} />
          </Form.Group>
          <Form.Group controlId="form__cdsEmailAddress" style={{ gridColumn: '2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>CDS Email</span>
            </Form.Label>
            <Form.Control placeholder="Enter CDS Email" autoComplete="off" type="email" {...register('cdsEmailAddress')} className={errors.cdsEmailAddress && 'border-danger'} />
          </Form.Group>
          <h4 style={{ gridColumn: '1' }}>Account Information</h4>
          <Form.Group controlId="form__codePrefix" style={{ gridColumn: '1' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Code Prefix<span className="input__required">*</span>
              </span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Prefix" autoComplete="off" type="text" {...register('codePrefix')} className={errors.cdsEmailAddress && 'border-danger'} />
            {errors.codePrefix && <Form.Text className="text-danger">{errors.codePrefix.message}</Form.Text>}
          </Form.Group>
          <Form.Group style={{ gridColumn: '1/3' }}>
            <CustomSelect
              isDisabled={false}
              styles={{ gridColumn: '1/3' }}
              required={false}
              controlId={'form__cdsbusinesUnitId'}
              label={'CDS Business Unit'}
              control={control}
              name={'cdsbusinesUnitId'}
              placeholder={'Select'}
              options={dropDowns?.cdsBusinesUnit?.map(item => {
                return {
                  value: +item?.id,
                  label: item?.value,
                };
              })}
              errors={undefined}
              enableMultilineOptions={false}
              isSearchable={false}
              register={register}
              value={getValues('cdsbusinesUnitId')}
              readOnlyFormField={false}
            />
          </Form.Group>
          <Form.Group style={{ gridColumn: '1' }}>
            <CustomSelect
              isDisabled={false}
              styles={{ gridColumn: '1' }}
              required={true}
              controlId={'form__exportTypeId'}
              label={'Export Type'}
              control={control}
              name={'exportTypeId'}
              placeholder={'Select'}
              options={dropDowns?.exportType?.map(item => {
                return {
                  value: +item?.id,
                  label: item?.value,
                };
              })}
              errors={errors.exportTypeId}
              enableMultilineOptions={false}
              isSearchable={false}
              register={register}
              value={getValues('exportTypeId')}
              readOnlyFormField={false}
              onChange={e => handleInputChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" style={{ gridColumn: '1 / 3' }} controlId="form__invoiceAddress">
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Invoice Address{samples && <span className="input__required">*</span>}</span>
            </Form.Label>
            <Form.Control placeholder="Enter >Invoice Address " as="textarea" rows={3} autoComplete="off" type="text" {...register('invoiceAddress')} />
            {samples && errors.invoiceAddress && <Form.Text className="text-danger">{errors.invoiceAddress.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="form__invoicePostCode" style={{ gridColumn: '1/3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Invoice Postcode{samples && <span className="input__required">*</span>}</span>
            </Form.Label>
            <Form.Control placeholder="Enter Invoice Postcode" autoComplete="off" type="text" {...register('invoicePostCode')} />
            {samples && errors.invoicePostCode && <Form.Text className="text-danger">{errors.invoicePostCode.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="form__invoiceContactName" style={{ gridColumn: '1/3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Invoice Contact Name{samples && <span className="input__required">*</span>}</span>
            </Form.Label>
            <Form.Control placeholder="Enter Invoice Contact Name " autoComplete="off" type="text" {...register('invoiceContactName')} />
            {samples && errors.invoiceContactName && <Form.Text className="text-danger">{errors.invoiceContactName.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="form__invoiceContactEmailAddress" style={{ gridColumn: '1/3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Invoice Contact Email Address{samples && <span className="input__required">*</span>}</span>
            </Form.Label>
            <Form.Control placeholder="Enter Invoice Contact Email Address" autoComplete="off" type="email" {...register('invoiceContactEmailAddress')} />
            {samples && errors.invoiceContactEmailAddress && <Form.Text className="text-danger">{errors.invoiceContactEmailAddress.message}</Form.Text>}
          </Form.Group>
          <Form.Group style={{ gridColumn: '1/3' }}>
            <CustomSelect
              isDisabled={false}
              styles={{ gridColumn: '1/3' }}
              required={false}
              controlId={'form__invoiceFrequency'}
              label={'Invoice Frequency'}
              control={control}
              name={'invoiceFrequency'}
              placeholder={'Select'}
              options={InvoiceFreq?.map(item => {
                return {
                  value: item?.value,
                  label: item?.value,
                };
              })}
              errors={undefined}
              enableMultilineOptions={false}
              isSearchable={false}
              register={register}
              value={getValues('invoiceFrequency')}
              readOnlyFormField={false}
            />
          </Form.Group>
          <Form.Group controlId="partialInvoiceAllowed" style={{ gridColumn: '1' }}>
            <Form.Label>
              Partial invoice allowed <span className="input__required">*</span>
            </Form.Label>
            <div>
              <Form.Check
                disabled={false}
                type="radio"
                label="Yes"
                inline
                value="Yes"
                aria-label="partialInvoiceAllowed__radio-yes"
                id="form__partialInvoiceAllowed-yes"
                {...register('partialInvoiceAllowed')}
              />
              <Form.Check
                disabled={false}
                type="radio"
                label="No"
                value="No"
                inline
                aria-label="partialInvoiceAllowed__radio-no"
                id="form__partialInvoiceAllowed-no"
                {...register('partialInvoiceAllowed')}
              />
            </div>
            {errors.partialInvoiceAllowed && <Form.Text className="text-danger">{errors.partialInvoiceAllowed.message}</Form.Text>}
          </Form.Group>

          <h4 style={{ gridColumn: '1' }}>Account KPIs</h4>
          <Form.Group style={{ gridColumn: '1/3' }}>
            <Form.Label>Contract Options</Form.Label>
            <Controller
              control={control}
              name="contractOptions"
              render={({ field: { name, onBlur, onChange, ref, value } }) => {
                return (
                  <Select
                    isMulti
                    name="contractOptions"
                    onBlur={onBlur}
                    onChange={(val, { action }) => {
                      if (action === 'clear') {
                        onChange(null);
                      } else {
                        onChange(val);
                      }
                      handleContractOptions(val);
                    }}
                    options={dropDowns?.contract?.map(item => {
                      return {
                        value: +item?.id,
                        label: item?.value,
                      };
                    })}
                    placeholder="Select"
                    ref={ref}
                    value={dropDowns?.contract
                      ?.map(item => {
                        return {
                          value: +item?.id,
                          label: item?.value,
                        };
                      })
                      .filter(item => contractOptions?.includes(item.value))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                );
              }}
            />
          </Form.Group>
          <Form.Group controlId="form__quoteToClientStandardKpi" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Quote to client standard KPI<span className="input__required">*</span>
              </span>
            </Form.Label>
            <Form.Control
              placeholder="Enter Quote to client standard KPI"
              autoComplete="off"
              type="number"
              {...register('quoteToClientStandardKpi')}
              className={errors.quoteToClientStandardKpi && 'border-danger'}
            />
            {errors.quoteToClientStandardKpi && <Form.Text className="text-danger">{errors.quoteToClientStandardKpi.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="form__quoteToClientComplexKpi" style={{ gridColumn: '2/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Quote to client Complex KPI<span className="input__required">*</span>
              </span>
            </Form.Label>
            <Form.Control
              className={errors.quoteToClientComplexKpi && 'border-danger'}
              placeholder="Enter Quote to client Complex KPI"
              autoComplete="off"
              type="number"
              {...register('quoteToClientComplexKpi')}
            />
            {errors.quoteToClientComplexKpi && <Form.Text className="text-danger">{errors.quoteToClientComplexKpi.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="form__proofToClientKpi" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Proof to client KPI<span className="input__required">*</span>
              </span>
            </Form.Label>
            <Form.Control placeholder="Enter Proof to client KPI" autoComplete="off" type="number" {...register('proofToClientKpi')} className={errors.proofToClientKpi && 'border-danger'} />
            {errors.proofToClientKpi && <Form.Text className="text-danger">{errors.proofToClientKpi.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="form__minimumNumberOfQuotesRequired" style={{ gridColumn: '2/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Minimum number of quotes</span>
            </Form.Label>
            <Form.Control autoComplete="off" type="number" {...register('minimumNumberOfQuotesRequired')} className={errors.minimumNumberOfQuotesRequired && 'border-danger'} />
            {errors.minimumNumberOfQuotesRequired && <Form.Text className="text-danger">{errors.minimumNumberOfQuotesRequired.message}</Form.Text>}
          </Form.Group>
          <h4 style={{ gridColumn: '1' }}>Additional Information</h4>
          <Form.Group style={{ gridColumn: '1' }}>
            <CustomSelect
              isDisabled={false}
              styles={{ gridColumn: '1' }}
              required={false}
              controlId={'form__analysisCategory'}
              label={'Analysis Category'}
              control={control}
              name={'analysisCategory'}
              placeholder={'Select'}
              options={dropDowns?.analysisCategory?.map(item => {
                return {
                  value: +item?.id,
                  label: item?.value,
                };
              })}
              errors={undefined}
              enableMultilineOptions={false}
              isSearchable={false}
              register={register}
              value={getValues('analysisCategory')}
              readOnlyFormField={false}
            />
          </Form.Group>
          <Form.Group controlId="isShowPickDelivery" style={{ gridColumn: '2' }}>
            <Form.Label>Show Pick / Delivery</Form.Label>
            <div>
              <Form.Check disabled={false} type="radio" label="Yes" inline value="Yes" aria-label="isShowDelivery__radio-yes" id="form__isShowDelivery-yes" {...register('showDelivery')} />
              <Form.Check disabled={false} type="radio" label="No" value="No" inline aria-label="isShowDelivery__radio-no" id="form__isShowDelivery-no" {...register('showDelivery')} />
            </div>
          </Form.Group>

          <h4 style={{ gridColumn: '1' }}> Account Administrators</h4>
          <div style={{ gridColumn: '1' }}>
            <Table style={{ backgroundColor: 'white', gridColumn: '1' }} aria-label="collapsible table" className="item__details__table tabular">
              <TableHead style={{ height: '50px' }}>
                <TableRow>
                  <TableCell className="table__details__headerCell" style={{ paddingLeft: '25px' }} sx={{ paddingLeft: 0 }} align="left">
                    Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountRow?.data?.[0]?.clientAdminstrative?.length > 0 ? (
                  accountRow?.data?.[0]?.clientAdminstrative?.map(item => {
                    return (
                      <TableRow style={{ padding: '0px' }}>
                        <TableCell className="table__details__headerCell" sx={{ paddingLeft: '25px' }} align="left">
                          {item?.name}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow style={{ padding: '0px' }}>
                    <TableCell className="table__details__headerCell" sx={{ paddingLeft: '25px' }} align="left">
                      -
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <h4 style={{ gridColumn: '1' }}>Finance Information</h4>
          <Form.Group controlId="form__managementFee" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Management Fee %<span className="input__required">*</span>
              </span>
            </Form.Label>
            <Form.Control placeholder="Enter Management Fee" autoComplete="off" type="text" {...register('managementFee')} />
            {errors.managementFee && <Form.Text className="text-danger">{errors.managementFee.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="form__supplierRebateRate" style={{ gridColumn: '2/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Supplier Rebate Rate %<span className="input__required">*</span>
              </span>
            </Form.Label>
            <Form.Control placeholder="Enter Supplier Rebate Rate" autoComplete="off" type="text" {...register('supplierRebateRate')} />
            {errors.supplierRebateRate && <Form.Text className="text-danger">{errors.supplierRebateRate.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="form__materialBrokerageOverrideRate" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Material Brokerage Over Ride Rate %</span>
            </Form.Label>
            <Form.Control placeholder="Enter Ride Rate" autoComplete="off" type="text" {...register('materialBrokerageOverrideRate')} />
            {errors.materialBrokerageOverrideRate && <Form.Text className="text-danger">{errors.materialBrokerageOverrideRate.message}</Form.Text>}
          </Form.Group>
          <Form.Group style={{ gridColumn: '2/2' }}>
            <CustomSelect
              isDisabled={false}
              styles={{ gridColumn: '2/2' }}
              required={false}
              controlId={'form__vatlevy'}
              label={'VAT Rate'}
              control={control}
              name={'vatlevy'}
              placeholder={'Select'}
              options={dropDowns?.vatRate?.map(item => {
                return {
                  value: +item?.id,
                  label: item?.value,
                };
              })}
              errors={undefined}
              enableMultilineOptions={false}
              isSearchable={false}
              register={register}
              value={getValues('exportTypeId')}
              readOnlyFormField={false}
            />
          </Form.Group>

          <Form.Group controlId="isVatincludedOnQuotes" style={{ gridColumn: '1' }}>
            <Form.Label>Include VAT on quotes </Form.Label>
            <div>
              <Form.Check
                disabled={false}
                type="radio"
                label="Yes"
                inline
                value="Yes"
                aria-label="isVatincludedOnQuotes__radio-yes"
                id="form__isVatincludedOnQuotes-yes"
                {...register('isVatincludedOnQuotes')}
              />
              <Form.Check
                disabled={false}
                type="radio"
                label="No"
                value="No"
                inline
                aria-label="isVatincludedOnQuotes__radio-no"
                id="form__isVatincludedOnQuotes-no"
                {...register('isVatincludedOnQuotes')}
              />
            </div>
          </Form.Group>
          <h4 style={{ gridColumn: '1' }}>Ledger Information</h4>
          <Form.Group controlId="form__accountCode" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Account Code</span>
            </Form.Label>
            <Form.Control placeholder="Enter Account Code" autoComplete="off" type="text" {...register('accountCode')} />
          </Form.Group>
          <Form.Group controlId="form__codePrint" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Print</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Print" autoComplete="off" type="text" {...register('codePrint')} />
          </Form.Group>
          <Form.Group controlId="form__codeTechnical" style={{ gridColumn: '2/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Technical</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Technical" autoComplete="off" type="text" {...register('codeTechnical')} />
          </Form.Group>
          <Form.Group controlId="form__codeScanning" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Scanning</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Scanning" autoComplete="off" type="text" {...register('codeScanning')} />
          </Form.Group>
          <Form.Group controlId="form__codePurchase" style={{ gridColumn: '2/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Purchase</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Purchase" autoComplete="off" type="text" {...register('codePurchase')} />
          </Form.Group>
          <Form.Group placeholder="Enter Code " controlId="form__codeDistribution" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Destribution</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Destribution" autoComplete="off" type="text" {...register('codeDistribution')} />
          </Form.Group>
          <Form.Group controlId="form__cordStorage" style={{ gridColumn: '2/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Cord Storage</span>
            </Form.Label>
            <Form.Control placeholder="Enter Cord Storage" autoComplete="off" type="" {...register('cordStorage')} />
          </Form.Group>
          <Form.Group controlId="form__codeProfessional" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Professional</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Professional" autoComplete="off" type="text" {...register('codeProfessional')} />
          </Form.Group>
          <Form.Group controlId="form__codeReprographic" style={{ gridColumn: '2/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Reprographic</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Reprographic" autoComplete="off" type="text" {...register('codeReprographic')} />
          </Form.Group>
          <Form.Group controlId="form__codeJournalism" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Journalism</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Journalism" autoComplete="off" type="text" {...register('codeJournalism')} />
          </Form.Group>
          <Form.Group controlId="form__codeEditorial" style={{ gridColumn: '2/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Editorial</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Editorial" autoComplete="off" type="text" {...register('codeEditorial')} />
          </Form.Group>
          <Form.Group controlId="form__codeDesign" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Design</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Design" autoComplete="off" type="text" {...register('codeDesign')} />
          </Form.Group>
          <Form.Group controlId="form__codeMagazine" style={{ gridColumn: '2/2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Magazine</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Magazine" autoComplete="off" type="text" {...register('codeMagazine')} />
          </Form.Group>
          <Form.Group controlId="form__codeInternet" style={{ gridColumn: '1 / 2' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Code Internet</span>
            </Form.Label>
            <Form.Control placeholder="Enter Code Internet" autoComplete="off" type="text" {...register('codeInternet')} />
          </Form.Group>
          <div style={{ height: '100px' }}></div>
        </>
        <>
          <footer>
            <Button variant="outline-primary" onClick={() => navigate(`/administration/account`)} className="outline__button" disabled={false}>
              Cancel
            </Button>
            <Button
              variant="success"
              form="printSpecificationsForm"
              type="submit"
              className="full__button"
              disabled={false}
              onClick={() => {
                setSubmitState(true);
              }}
            >
              Done
            </Button>
            <Button
              variant="success"
              form="printSpecificationsForm"
              type="submit"
              className="full__button"
              disabled={false}
              onClick={() => {
                setSubmitState(false);
              }}
            >
              Save & next
            </Button>
          </footer>
        </>
      </Form>
    </div>
  );
};
