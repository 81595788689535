//  Externals
import { PayloadAction } from '@reduxjs/toolkit';
//  Internals
import { useInjectReducer } from '../../../utils/redux-injectors';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { MasterData, initialState } from './types';

const slice = createSlice({
  name: 'masterData',
  initialState,
  reducers: {
    setMasterData: (masterData, action: PayloadAction<MasterData>) => {
      masterData.artworkFormats = action.payload.artworkFormats;
      masterData.colours = action.payload.colours;
      masterData.coloursOnFaces = action.payload.coloursOnFaces;
      masterData.coloursOnInside = action.payload.coloursOnInside;
      masterData.coloursOnReverses = action.payload.coloursOnReverses;
      masterData.coloursOnText = action.payload.coloursOnText;
      masterData.coloursOnOutside = action.payload.coloursOnOutside;
      masterData.coverMaterial = action.payload.coverMaterial;
      masterData.coverMaterialWeight = action.payload.coverMaterialWeight;
      masterData.deliveryAddressChangeReasons = action.payload.deliveryAddressChangeReasons;
      masterData.discountReasons = action.payload.discountReasons;
      masterData.exportTypes = action.payload.exportTypes;
      masterData.finishedSizes = action.payload.finishedSizes;
      masterData.finishings = action.payload.finishings;
      masterData.jobItemOptions = action.payload.jobItemOptions;
      masterData.jobOptions = action.payload.jobOptions;
      masterData.materials = action.payload.materials;
      masterData.materialSuppliers = action.payload.materialSuppliers;
      masterData.materialWeights = action.payload.materialWeights;
      masterData.mechansim = action.payload.mechansim;
      masterData.otherCosts = action.payload.otherCosts;
      masterData.supplierQuoteDeclineReasons = action.payload.supplierQuoteDeclineReasons;
      masterData.typesOfProofs = action.payload.typesOfProofs;
    },
  },
});

export default slice.reducer;

export const { setMasterData } = slice.actions;

export const useMasterDataSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
