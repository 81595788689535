import { PayloadAction } from '@reduxjs/toolkit';
import Dashboard from 'app/pages/dashboard';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { breadcrumbSaga } from './saga';
import { BreadcrumbState, breadcrumbItem } from './types';
import URL from '../../../../data/url';

export const initialState: BreadcrumbState = {
  breadcrumb: [
    { name: 'Dashboard', url: '/dashboard' },
    { name: 'Request for Quote', url: URL.REQUEST_FOR_QUOTE },
  ],
};

const slice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    changeBreadcrumb(state, action: PayloadAction<breadcrumbItem[]>) {
      state.breadcrumb = action.payload;
    },
  },
});

export const { actions: breadcrumbActions, reducer } = slice;

export const useBreadcrumbSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: breadcrumbSaga });
  return { actions: slice.actions };
};
