import React from 'react';
import { useSelector } from 'react-redux';
import DashboardLayout from '../layout/index';
import { isLoading, selectDashboardCards } from '../slice/selectors';
import LoadingSpinner from '../../../components/navigation/LoadingSpinner';

const SupplierDashboard = props => {
  const { userRoles } = props;
  const data = useSelector(selectDashboardCards);
  const isLoader = useSelector(isLoading);

  const labels = [
    {
      barLabel: 'Quotes Requested',
      lineLabel: 'Quotes Responded To',
    },
    {
      barLabel: 'Quotes Won',
      lineLabel: 'Win Rate',
    },
    {
      barLabel: 'POD,s Provided',
      lineLabel: 'Delivered on Time',
    },
  ];

  const c1 = { newRfq: '#3e1951', quoted: '#8c41b2', order: '#b764e1', invoiceReceived: '#cda5e1', dispatched: '#cfb8da' };
  const c2 = ['#3e1951', '#1ba39c', '#3e1951'];

  return (
    <>
      {isLoader ? (
        <div style={{ position: 'absolute', top: '50%', width: '100%' }}>
          <LoadingSpinner color="#000" />
        </div>
      ) : (
        <DashboardLayout data={data} labels={labels} userRoles={userRoles} c1={c1} c2={c2} />
      )}
    </>
  );
};

export default SupplierDashboard;
