export const PRINTSPECIFICATIONS = 'PRINTSPECIFICATIONS';
export const QUOTES = 'QUOTES';

//Navigation tabs for accounts
export const ACCOUNTDETAILS = 'ACCOUNTDETAILS';
export const BUSINESSUNIT = 'BUSINESSUNIT';
export const JOBRATE = 'JOBRATE';
export const PRODUCTS = 'PRODUCTS';
export const SUPPLIERS = 'SUPPLIERS';
//Status
export const DRAFT = 'Draft';
export const NEWRFQ = 'New RFQ';
export const QUOTATION = 'Quotation';
export const ACCEPTED = 'Accepted';
export const REJECTED = 'Rejected';
export const DELIVERED = 'Delivered';
export const READYTOINVOICE = 'Ready To Invoice';
export const INVOICED = 'Invoiced';
export const DECLINED = 'Declined';
export const QUOTED = 'Quoted';
export const ORDERED = 'Ordered';
export const AMENDEDRFQ = 'Amended RFQ';
export const PARTORDERED = 'Part Ordered';
export const PARTDELIVERED = 'Part Delivered';
export const CANCELLED = 'Cancelled';

//Roles
export const CDS_ROLE = 'CDS';
export const CLIENT_ROLE = 'CLIENT';
export const SUPPLIER_ROLE = 'SUPPLIER';

//Modal Types
export const CONFIRMATION = 'CONFIRMATION';
export const SUCCESS = 'SUCCESS';
export const PENDING = 'PENDING';
export const ERROR = 'ERROR';

//Button Types
export const OUTLINED = 'OUTLINED';
export const FULL = 'FULL';

export const jobInformationInitialState = {
  title: null,
  accountId: null,
  businessUnitId: null,
  clientReference: null,
  jobOptions: [],
  contactId: null,
  contactEmail: null,
  contactPhoneNumber: null,
  cdsexecutive: null,
  directInvoice: false,
  invoiceContactName: null,
  invoiceContactEmailAddress: null,
  invoiceAddress: null,
  forecastMonth: null,
  forecastYear: null,
  quoteBasket: false,
};

//other Costs
export const DESIGN = 'design';
export const SCANNING = 'scanning';
export const otherCostsFormInititalState = {
  name: null,
  description: null,
  hours: null,
  valueNet: null,
  valueRate: null,
};
