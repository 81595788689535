import MomentUtils from '@date-io/moment';
import React from 'react';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { selectPoInvoiceDetails } from './slice/selectors';
const PoNumberClosedPurchase = () => {
  const poInvoice = useSelector(selectPoInvoiceDetails);
  const moment = new MomentUtils();
  return (
    <React.Fragment>
      <div className="container">
        <div>
          <h4>{poInvoice.data.supplierPo}</h4>
        </div>
        <div>
          <div>
            <div className="row table_padding closed_purchase_colour">
              <div className="col">
                <div className="col_style">
                  <h6 className="h6_style_closedPurchase">{poInvoice.data.supplierName}</h6>
                </div>
                <div className="col_style">
                  <h6 className="h6_style_closedPurchase">{poInvoice.data.supplierAddress}</h6>
                </div>
                <div className="col_style">
                  <h6 className="h6_style_closedPurchase">{poInvoice.data.supplierPostCode}</h6>
                </div>
                <div className="col_style">
                  <h6 className="h6_style_closedPurchase">{poInvoice.data.supplierWebsite}</h6>
                </div>
              </div>
              <div className="col">
                <div className="col_style">
                  <h6 className="h6_style_closedPurchase">Reference</h6>
                  <p>{poInvoice.data.jobReference}</p>
                </div>
                <div className="col_style">
                  <h6 className="h6_style_closedPurchase">Job Title</h6>
                  <p>{poInvoice.data.jobTitle}</p>
                </div>
                <div className="col_style">
                  <h6 className="h6_style_closedPurchase">Total PO Value</h6>
                  <p>£ {poInvoice.data.totalPoValue?.toFixed(2)}</p>
                </div>
                <div className="col_style">
                  <h6 className="h6_style_closedPurchase">Total Invoiced</h6>
                  <p>£ {poInvoice.data.totalInvoicedValue?.toFixed(2)}</p>
                </div>
                <div className="col_style">
                  <h6 className="h6_style_closedPurchase">Job No</h6>
                  <p>{poInvoice.data?.jobNumber}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row table_padding closed_purchase_colour">
        {poInvoice.data.invoiceDetails.length === 0 ? (
          ''
        ) : (
          <>
            {poInvoice.data.invoiceDetails.map(item => (
              <>
                <div className="col">
                  <div className="col_style">
                    <h6 className="h6_style_closedPurchase">Invoice No</h6>
                    <p>{item.invoiceNumber}</p>
                  </div>
                </div>
                <div className="col">
                  <div className="col_style">
                    <h6 className="h6_style_closedPurchase">Invoice Date</h6>
                    <p>{item.invoiceDate == null ? '' : `${moment.date(item.invoiceDate)?.format('DD/MM/YYYY')}`}</p>
                  </div>
                </div>
                <Table>
                  <thead className="tableHead_outStanding">
                    <tr className="tableHorizontal_outStanding">
                      <th>Line No</th>
                      <th>Title</th>
                      <th>Quantity</th>
                      <th className="text-end">Unit Price (&#163;)</th>
                      <th className="text-end">Po Value (&#163;)</th>
                      <th className="text-end">Invoice Value (&#163;)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="tableHorizontal_outStanding">
                      <td>{item.jobLineId}</td>
                      <td>{item.title}</td>
                      <td className="text-end">{item.quantity}</td>
                      <td className="text-end">{item.unitPrice?.toFixed(2)}</td>
                      <td className="text-end">{item.poValue?.toFixed(2)}</td>
                      <td className="text-end">{item.invoiceValue?.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            ))}
          </>
        )}
        {poInvoice.data.closedProductDetails.length === 0 ? (
          ''
        ) : (
          <>
            <h4>Closed Lines</h4>
            <table>
              <thead className="tableHead_outStanding">
                <tr className="tableHorizontal_outStanding">
                  <th>Line No</th>
                  <th>Title</th>
                  <th>Quantity</th>
                  <th className="text-end">Unit Price (&#163;)</th>
                  <th className="text-end">Po Value (&#163;)</th>
                  <th className="text-end">Status</th>
                </tr>
              </thead>
              <tbody>
                {poInvoice.data.closedProductDetails.map(closedProductDetails => (
                  <tr className="tableHorizontal_outStanding">
                    <td>{closedProductDetails.jobLineId}</td>
                    <td>{closedProductDetails.title}</td>
                    <td>{closedProductDetails.quantity}</td>
                    <td className="text-end">{closedProductDetails.unitPrice?.toFixed(2)}</td>
                    <td className="text-end">{closedProductDetails.poValue?.toFixed(2)}</td>
                    <td className="text-end"> Closed at £{(Number(closedProductDetails.poValue) - Number(closedProductDetails.invoiceValue))?.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default PoNumberClosedPurchase;
