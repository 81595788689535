//  Externals
import React from 'react';
import moment from 'moment';

const LineDetails = props => {
  const { initialState } = props;

  return (
    <>
      <div style={{ gridColumn: '1/3', marginTop: '20px', marginBottom: '20px' }}>
        <div>
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th>Line ID</th>
                <th>Submitted</th>
                <th>Quote due</th>
                <th>Quote Date</th>
                <th>Delivery Date</th>
                <th>Invoice Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{initialState.lineId ? initialState.lineId : '-'}</td>
                <td>{initialState.added ? moment(initialState.added).format('DD/MM/YYYY') : '-'}</td>
                <td>{initialState.quoteDue ? moment(initialState.quoteDue).format('DD/MM/YYYY') : '-'}</td>
                <td>{initialState.quoteDate ? moment(initialState.quoteDate).format('DD/MM/YYYY') : '-'}</td>
                <td>{initialState?.podDate ? moment(initialState?.podDate).format('DD/MM/YYYY') : '-'}</td>
                <td>{initialState?.invoiceDate ? moment(initialState?.invoiceDate).format('DD/MM/YYYY') : '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {!initialState.noPo && initialState.poDetails && initialState.poDetails.poNumber && (
        <div style={{ gridColumn: '1/2', marginBottom: '20px' }}>
          <div>
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th>PO Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{initialState.poDetails ? initialState.poDetails.poNumber.toUpperCase() : '-'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {initialState.podReference !== null && (
        <div style={{ gridColumn: '1/2', marginBottom: '20px' }}>
          <div>
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th>POD reference</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{initialState.podReference ? initialState.podReference : '-'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default LineDetails;
