//  Internals
import React from 'react';
//  Externals
import { ReactComponent as UnallocatedRFQIcon } from 'assets/icons/unallocatedRFQ.svg';
import { ReactComponent as AdministrationIcon } from 'assets/icons/administration.svg';
import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as ReportsIcon } from 'assets/icons/reports.svg';
import { ReactComponent as MyJobsIcon } from 'assets/icons/myJobs.svg';

const renderIcon = moduleName => {
  switch (moduleName) {
    case 'Dashboard':
      return <DashboardIcon className="svg__icon" />;
    case 'Unallocated RFQ':
      return <UnallocatedRFQIcon className="svg__icon" />;
    case 'My Jobs':
      return <MyJobsIcon className="svg__icon" />;
    case 'Reports':
      return <ReportsIcon className="svg__icon" />;
    case 'Administration':
      return <AdministrationIcon className="svg__icon" />;
    case 'Notifications':
      return <NotificationsIcon className="svg__icon" />;
  }
};

export default renderIcon;
