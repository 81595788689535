import { ClientQuotations } from 'types/myJobs';

export interface ProductsProps {
  onHide: () => void;
  onOpen: () => void;
  setProduct: (product: Product) => void;
  show: boolean;
}

export interface Product {
  checked: boolean;
  name: string;
  productId: number;
}

export const initialProduct: Product = { checked: false, name: '', productId: 0 };

export interface ProductItemProps {
  create: boolean;
  initialState?;
  onHide: () => void;
  onShowProductsModal: () => void;
  productId: number;
  productType: string;
  show: boolean;
}

export interface GetProductItemProps {
  clientQuotations?: ClientQuotations;
  create: boolean;
  disabled: boolean;
  formId: string;
  initialState?;
  jobId: number;
  onHide: () => void;
  productId: number;
  productType: string;
  statusName?: string;
}

export interface HeaderProps {
  onShowProductsModal: () => void;
}

export interface FilerProps {
  show: boolean;
  onHide: () => void;
}
