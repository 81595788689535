import React, { useEffect, useState } from 'react';

import CustomSelect from 'app/components/shared/CustomSelect';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectDropDown } from './slice/selector';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import callApi from 'services/callApi';
import * as yup from 'yup';

export const BusinessUnitInput = props => {
  const { accountInputs, onShowAccountDetails } = props;
  const navigate = useNavigate();
  const [dropDowns, setDropDowns] = useState<any>({});
  const getDropdowns = useSelector(selectDropDown);
  const validationSchema = yup.object({
    businessUnitname: yup.string().trim().nullable().required('Unit Name is required'),
    businessUnitreference: yup.string().nullable(),
    analsysCategoryId: yup.string().nullable(),
    businessUnitIsActive: yup.string().trim().nullable().required('Please provide Status.'),
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitPost = async (data: any) => {
    const formData = {
      accountId: 0,
      name: accountInputs?.name ? accountInputs?.name : null,
      primaryCdscontactId: accountInputs?.primaryCdscontactId ? accountInputs?.primaryCdscontactId?.toString() : null,
      secondaryCdscontactId: accountInputs?.secondaryCdscontactId ? accountInputs?.secondaryCdscontactId.toString() : null,
      cdsownerId: accountInputs?.cdsownerId ? +accountInputs?.cdsownerId : null,
      reference: accountInputs?.reference ? accountInputs?.reference : null,
      businessAddress: accountInputs?.businessAddress ? accountInputs?.businessAddress : null,
      postCode: accountInputs?.postCode ? accountInputs?.postCode : null,
      telephone: accountInputs?.telephone ? accountInputs?.telephone : null,
      contact: accountInputs?.contact ? accountInputs?.contact : null,
      email: accountInputs?.email ? accountInputs?.email : null,
      parentContractId: accountInputs?.parentContractId ? +accountInputs?.parentContractId : null,
      geographicLocations: accountInputs?.geographicLocations ? [...accountInputs?.geographicLocations?.map(item => item.value)] : null,
      isActive: accountInputs?.isActive && accountInputs?.isActive === 'Yes' ? true : false,
      sectorId: accountInputs?.sectorId ? +accountInputs?.sectorId : null,
      hubspotReference: accountInputs?.hubspotReference ? accountInputs?.hubspotReference : null,
      cdsEmailAddress: accountInputs?.cdsEmailAddress ? accountInputs?.cdsEmailAddress : null,
      codePrefix: accountInputs?.codePrefix ? accountInputs?.codePrefix : null,
      cdsbusinesUnitId: accountInputs?.cdsbusinesUnitId ? +accountInputs?.cdsbusinesUnitId : null,
      exportTypeId: accountInputs?.exportTypeId ? +accountInputs?.exportTypeId : null,
      invoiceAddress: accountInputs?.invoiceAddress ? accountInputs?.invoiceAddress : null,
      invoicePostCode: accountInputs?.invoicePostCode ? accountInputs?.invoicePostCode : null,
      invoiceContactName: accountInputs?.invoiceContactName ? accountInputs?.invoiceContactName : null,
      invoiceContactEmailAddress: accountInputs?.invoiceContactEmailAddress ? accountInputs?.invoiceContactEmailAddress : null,
      invoiceFrequency: accountInputs?.invoiceFrequency ? accountInputs?.invoiceFrequency : null,
      partialInvoiceAllowed: accountInputs?.partialInvoiceAllowed && accountInputs?.partialInvoiceAllowed === 'Yes' ? true : false,
      contractOptions: accountInputs?.contractOptions ? [...accountInputs?.contractOptions?.map(item => item.value)] : null,
      quoteToClientStandardKpi: accountInputs?.quoteToClientStandardKpi ? +accountInputs?.quoteToClientStandardKpi : null,
      quoteToClientComplexKpi: accountInputs?.quoteToClientComplexKpi ? +accountInputs?.quoteToClientComplexKpi : null,
      proofToClientKpi: accountInputs?.proofToClientKpi ? +accountInputs?.proofToClientKpi : null,
      minimumNumberOfQuotesRequired: accountInputs?.minimumNumberOfQuotesRequired ? +accountInputs?.minimumNumberOfQuotesRequired : null,
      managementFee: accountInputs?.managementFee ? +accountInputs?.managementFee : null,
      supplierRebateRate: accountInputs?.supplierRebateRate ? +accountInputs?.supplierRebateRate : null,
      materialBrokerageOverrideRate: accountInputs?.materialBrokerageOverrideRate ? +accountInputs?.materialBrokerageOverrideRate : null,
      vatlevy: accountInputs?.vatlevy ? Number(accountInputs?.vatlevy) : null,
      isVatincludedOnQuotes: accountInputs?.isVatincludedOnQuotes && accountInputs?.isVatincludedOnQuotes === 'Yes' ? true : false,
      accountCode: accountInputs?.accountCode ? accountInputs?.accountCode : null,
      accountPrint: accountInputs?.accountPrint ? accountInputs?.accountPrint : null,
      codePrint: accountInputs?.codePrint ? accountInputs?.codePrint : null,
      codeTechnical: accountInputs?.codeTechnical ? accountInputs?.codeTechnical : null,
      codeScanning: accountInputs?.codeScanning ? accountInputs?.codeScanning : null,
      codePurchase: accountInputs?.codePurchase ? accountInputs?.codePurchase : null,
      codeDistribution: accountInputs?.codeDistribution ? accountInputs?.codeDistribution : null,
      cordStorage: accountInputs?.cordStorage ? accountInputs?.cordStorage : null,
      codeProfessional: accountInputs?.codeProfessional ? accountInputs?.codeProfessional : null,
      codeReprographic: accountInputs?.codeReprographic ? accountInputs?.codeReprographic : null,
      codeJournalism: accountInputs?.codeJournalism ? accountInputs?.codeJournalism : null,
      codeEditorial: accountInputs?.codeEditorial ? accountInputs?.codeEditorial : null,
      codeDesign: accountInputs?.codeDesign ? accountInputs?.codeDesign : null,
      codeMagazine: accountInputs?.codeMagazine ? accountInputs?.codeMagazine : null,
      codeInternet: accountInputs?.codeInternet ? accountInputs?.codeInternet : null,
      analysisCategory: accountInputs?.analysisCategory ? accountInputs?.analysisCategory.toString() : null,
      showDelivery: accountInputs?.showDelivery && accountInputs?.showDelivery === 'Yes' ? true : false,
      accountBusinessUnit: [
        {
          businessUnitId: 0,
          name: data.businessUnitname ? data.businessUnitname : null,
          parentAccountId: 0,
          analsysCategoryId: data.analsysCategoryId ? +data.analsysCategoryId : null,
          reference: data.businessUnitreference ? data.businessUnitreference : null,
          isActive: data.businessUnitIsActive && data.businessUnitIsActive === 'Yes' ? true : false,
        },
      ],
    };
    try {
      const response: any = await callApi({ url: '/Admin/CreateAccountDetail', method: 'post', body: formData });
      if (response.isSuccess) navigate(`/administration/account`);
    } catch (err) {
      onShowAccountDetails();
    }
  };

  useEffect(() => {
    setDropDowns(getDropdowns?.data);
  }, [getDropdowns]);

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmitPost)} id="printSpecificationsForm__onSubmitPost" className="printSpecifications__form">
        <Form.Group controlId="form__businessUnitname" style={{ gridColumn: '1 / 2' }}>
          <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              Unit Name<span className="input__required">*</span>
            </span>
          </Form.Label>
          <Form.Control placeholder="Enter Unit Name" autoComplete="off" type="text" {...register('businessUnitname')} />
          {errors.businessUnitname && <Form.Text className="text-danger">{errors.businessUnitname.message}</Form.Text>}
        </Form.Group>
        <Form.Group controlId="form__businessUnitreference" style={{ gridColumn: '1 / 2' }}>
          <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Reference</span>
          </Form.Label>
          <Form.Control placeholder="Enter Reference" autoComplete="off" type="text" {...register('businessUnitreference')} />
        </Form.Group>
        <Form.Group style={{ gridColumn: '2/2' }}>
          <CustomSelect
            isDisabled={false}
            styles={{ gridColumn: '2/2' }}
            required={false}
            controlId={'form__analsysCategoryId'}
            label="Analysis Category"
            control={control}
            name={'analsysCategoryId'}
            placeholder={'Select'}
            options={dropDowns?.analysisCategory?.map(item => {
              return {
                value: +item?.id,
                label: item?.value,
              };
            })}
            errors={undefined}
            enableMultilineOptions={false}
            isSearchable={false}
            register={register}
            value={getValues('analsysCategoryId')}
            readOnlyFormField={false}
          />
        </Form.Group>
        <Form.Group controlId="businessUnitIsActive" style={{ gridColumn: '1' }}>
          <Form.Label>
            Active<span className="input__required">*</span>
          </Form.Label>
          <div>
            <Form.Check
              disabled={false}
              type="radio"
              label="Yes"
              inline
              value="Yes"
              aria-label="businessUnitIsActive__radio-yes"
              id="form__businessUnitIsActive-yes"
              {...register('businessUnitIsActive')}
            />
            <Form.Check
              disabled={false}
              type="radio"
              label="No"
              value="No"
              inline
              aria-label="businessUnitIsActive__radio-no"
              id="form__businessUnitIsActive-no"
              {...register('businessUnitIsActive')}
            />
          </div>
          {errors.businessUnitIsActive && <Form.Text className="text-danger">{errors.businessUnitIsActive.message}</Form.Text>}
        </Form.Group>
        <footer>
          <Button variant="success" form="printSpecificationsForm__onSubmitPost" type="submit" className="full__button" disabled={false}>
            Save
          </Button>
        </footer>
      </Form>
    </div>
  );
};
