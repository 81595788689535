//  Externals
import { Modal, Button } from 'react-bootstrap';
import React from 'react';
//  Internals
import { ReactComponent as ConfirmationWarningIcon } from '../../../assets/icons/confirmationWarning.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';
import { CONFIRMATION, FULL, OUTLINED, SUCCESS, ERROR } from './Constants';
import './PopupModal.sass';

type AppProps = {
  bodyContent?: any[];
  bodyTitle: string;
  footerButtons: { type: string; name: string; onClick: () => void }[];
  onHide: () => void;
  header: string;
  show: boolean;
  type: string;
};

const PopupModal = (props: AppProps) => {
  return (
    <Modal
      // aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      // backdropClassName="fullScreen__overPopup"
      centered
      // dialogClassName="modal__popup"
      keyboard={false}
      onHide={props.onHide}
      show={props.show}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="popupModal__bodyContainer">
          <div className="body__titleContainer">
            {(props.type === CONFIRMATION || props.type === ERROR) && <ConfirmationWarningIcon />}
            {props.type === SUCCESS && <SuccessIcon />}
            <span className="body__title">{props.bodyTitle}</span>
          </div>
          {props.bodyContent && (
            <ol className="body__contentContainer" style={{ padding: '20px' }}>
              {props.bodyContent?.map((row, index) => (
                <li key={index}>{row}</li>
              ))}
            </ol>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {props.footerButtons.map(button => (
          <Button key={button.name} variant="outline-primary" className={`${button.type === OUTLINED && 'outline__button'} ${button.type === FULL && 'full__button'}`} onClick={button.onClick}>
            {button.name}
          </Button>
        ))}
      </Modal.Footer>
    </Modal>
  );
};

export default PopupModal;
