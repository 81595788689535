//  Externals
import { Box, Checkbox, Collapse, IconButton, Paper, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { getIsSupplier } from '../../components/modules/sideBar/slice/selectors';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { Fragment, useState, useEffect } from 'react';
import { IndeterminateCheckBox } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import RemoveIcon from '@mui/icons-material/Remove';
import { useLocation } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { Button } from 'react-bootstrap';
import moment from 'moment';
//  Internals
import { selectProductItemDetailsRows, selectProductItemDetailsRowsOpenArray, selectProductItemDetailsRowsCheckboxesArray, isLoading } from './slice/selectors';
import { ProofOfDeliveryPopUp } from './ProofDeliveryPopup';
import usePagination from './../profile/Pagination';
import { useCreateQuoteSlice } from './slice';
import RFQDetails from './RFQDetails';
import './style.sass';
import LoadingSpinner from 'app/components/navigation/LoadingSpinner';

const RowDetails = props => {
  const { jobQuote, onOpen, onSelect } = props;
  const [actionStatus, setActionStatus] = useState(false);
  const [actionStatusId, setActionStatusId] = useState(0);
  const [productTemplateId, setProductTemplateId] = useState(0);
  const location = useLocation();
  const handleActionStatus = () => {
    if (jobQuote.statusName === 'Order') {
      setActionStatus(true);
      setActionStatusId(jobQuote.rfqJobId);
      setProductTemplateId(jobQuote.productTemplateId);
    }
  };
  const getValidatedValue = val => {
    var value = val == null || val == undefined || val < 0 ? '-' : val;
    return value;
  };
  return (
    <>
      <ProofOfDeliveryPopUp
        show={actionStatus}
        rfqJobId={actionStatusId}
        productTemplateId={productTemplateId}
        onHide={() => {
          setActionStatus(false);
        }}
      />
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
        }}
        hover={true}
        selected={jobQuote.checked}
        className={`table__details__row ${jobQuote.open && 'open'}`}
      >
        <TableCell className="table__details__bodyCell" style={{ paddingLeft: '1rem', width: '6.5rem' }}>
          <IconButton aria-label="expand row" size="small" onClick={event => onOpen(event)}>
            {jobQuote.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
          <Checkbox
            sx={{
              color: '#8D8C8D',
              '&.Mui-checked': {
                color: '#1BA39C',
              },
            }}
            id="product-row-id"
            checked={jobQuote.checked}
            onChange={() => onSelect()}
          />
        </TableCell>
        <TableCell className="table__details__bodyCell">{jobQuote.jobNumber}</TableCell>
        <TableCell className="table__details__bodyCell">{jobQuote.jobLineId}</TableCell>
        <TableCell
          className="table__details__bodyCell"
          sx={{
            paddingLeft: 0,
          }}
        >
          {jobQuote.title}
        </TableCell>
        <TableCell className="table__details__bodyCell">{jobQuote.productType}</TableCell>
        <TableCell className="table__details__bodyCell">
          {location?.pathname === '/orders' ? (
            jobQuote.deliveryDate ? (
              moment(jobQuote.deliveryDate).format('DD/MM/YYYY')
            ) : (
              '-'
            )
          ) : jobQuote.dueDate ? (
            <>
              {moment(jobQuote.dueDate).format('DD/MM/YYYY')}
              &nbsp;
              {jobQuote.dueTime == null ? '-' : `${jobQuote.dueTime.split(':').splice(0, 2).join(':')}`}
            </>
          ) : (
            '-'
          )}
        </TableCell>

        {/* {console.log(jobQuote.dueDate)} */}
        <TableCell className="table__details__bodyCell text-end pe-2">{getValidatedValue(jobQuote.productionRate?.toFixed(2))}</TableCell>
        <TableCell className="table__details__bodyCell text-end pe-2">{getValidatedValue(jobQuote.materialRate?.toFixed(2))}</TableCell>
        <TableCell className="table__details__bodyCell text-end pe-2">{getValidatedValue(jobQuote.deliveryRate?.toFixed(2))}</TableCell>
        <TableCell className="table__details__bodyCell text-end pe-2">{getValidatedValue(jobQuote.total?.toFixed(2))}</TableCell>
        <TableCell className="table__details__bodyCell">{jobQuote.quoteStatusName}</TableCell>
        <TableCell className="table__details__bodyCell">
          <Button onClick={handleActionStatus} className="border-0" variant="outline-primary" disabled={jobQuote.statusName !== 'Order'}>
            <CalendarTodayOutlinedIcon sx={{ color: jobQuote.statusName !== 'Order' ? grey[400] : grey[900] }} titleAccess="Add POD details" />
          </Button>
        </TableCell>
      </TableRow>
      {jobQuote.open && (
        <TableRow>
          <TableCell className="productItem__details__subContainer productItem__details__bodyCell" colSpan={12}>
            <Collapse in={jobQuote.open} timeout="auto" unmountOnExit>
              <Box>
                <div className="productDetails__separator"></div>
                <div style={{ margin: '0rem 0.5rem' }}>
                  <div className="inPage__navigation__line"></div>
                  <div style={{ margin: '1rem' }}>
                    {jobQuote.open && (
                      <RFQDetails
                        id={jobQuote.productTemplateId}
                        title={jobQuote.title}
                        productType={jobQuote.productType}
                        status={jobQuote.status}
                        statusCode={jobQuote.quoteStatusCode}
                        statusName={jobQuote.statusName}
                        saveFlag={jobQuote.saveFlag}
                        jobQuoteId={jobQuote.jobQuoteId}
                        disabled={true}
                        readOnlyForm={true}
                        quoteStatusName={jobQuote.quoteStatusName}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const RFQSupplierView = () => {
  const isSupplier = useSelector(getIsSupplier);
  const isLoader = useSelector(isLoading);
  const { actions } = useCreateQuoteSlice();
  const location = useLocation();
  const dispatch = useDispatch();

  const rowArray = useSelector(selectProductItemDetailsRows);
  const openArray = useSelector(selectProductItemDetailsRowsOpenArray);
  const checkedArray = useSelector(selectProductItemDetailsRowsCheckboxesArray);

  const [dispatched, setDispatched] = useState(false);
  const [outOfTime, setOutOfTime] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [invoiced, setInvoiced] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  /******************************************************************************************
  @Purpose    :   To expand a row
  *******************************************************************************************/
  const handleOpen = (event, productTemplateId, jobQuoteId) => {
    if (event.target.id === 'product-row-id') {
      event.stopPropagation();
    } else {
      dispatch(actions.changeOpenForSupplierItem(jobQuoteId));
      dispatch(actions.loadSupplierList({ productTemplateId }));

      const openArr = rowArray.map(item => (item.jobQuoteId === jobQuoteId ? !item.open : item.open));
      const flag = new Set(openArr).size === 1 ? openArr[0] : null;
      setOpen(() => (flag !== null ? flag : false));
    }
  };

  /******************************************************************************************
  @Purpose    :   To select single row
  *******************************************************************************************/
  const handleSelect = id => {
    dispatch(actions.changeCheckedForSupplierItem(id));
    const checkedArr = rowArray.map((item, i) => (i === id ? !item.checked : item.checked));
    const flag = new Set(checkedArr).size === 1 ? checkedArr[0] : null;
    setChecked(() => (flag !== null ? flag : false));
  };

  const onChangeHandler = flag => {
    dispatch(actions.masterProductItemOnChange({ flag, open, checked }));
  };

  const [page, setPage] = useState(1);
  const dataPerPage = 20;
  const count = Math.ceil(rowArray.length / dataPerPage);
  const _PerPageData_ = usePagination(rowArray, dataPerPage);

  const handlePageChange = (e, p) => {
    setPage(p);
    _PerPageData_.jump(p);
  };
  const handleOutOfTime = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setOutOfTime(event.target.checked);
  };
  const handleDeclined = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeclined(event.target.checked);
  };
  const handleCancelled = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCancelled(event.target.checked);
  };
  const handleDispatched = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setDispatched(event.target.checked);
  };
  const handleInvoiced = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiced(event.target.checked);
  };

  useEffect(() => {
    const filterId = [outOfTime && 25, declined && 9, cancelled && 20, dispatched && 16, invoiced && 8].filter(item => item != false);
    let outPut = '';
    filterId.forEach(item => {
      outPut += `&StatusId=${item}`;
    });
    if (location?.pathname === '/orders') {
      isSupplier && dispatch(actions.loadRFQsList({ order: true, filter: outPut, isFilter: filterId.length > 0 }));
    } else {
      isSupplier && dispatch(actions.loadRFQsList({ order: false, filter: outPut, isFilter: filterId.length > 0 }));
    }
  }, [actions, location, outOfTime, declined, cancelled, dispatched, invoiced, dispatch]);

  return (
    <>
      <TableContainer component={Paper} className="tabular" sx={{ borderRadius: '0', boxShadow: 'none' }}>
        {isSupplier && (
          <div className="productsPage__header">
            <span></span>
            <span>
              {location?.pathname === '/requestedquotes' && (
                <>
                  <span>
                    <span style={{ marginTop: '8px' }}>Out of time</span>
                    <Switch checked={outOfTime} onChange={handleOutOfTime} inputProps={{ 'aria-label': 'controlled' }} />
                  </span>
                  <span>
                    <span style={{ marginTop: '8px' }}>Declined</span>
                    <Switch checked={declined} onChange={handleDeclined} inputProps={{ 'aria-label': 'controlled' }} />
                  </span>
                </>
              )}
              {location?.pathname === '/orders' && (
                <>
                  <span>
                    <span style={{ marginTop: '8px' }}>Dispatched</span>
                    <Switch checked={dispatched} onChange={handleDispatched} inputProps={{ 'aria-label': 'controlled' }} />
                  </span>
                  <span>
                    <span style={{ marginTop: '8px' }}>Invoiced</span>
                    <Switch checked={invoiced} onChange={handleInvoiced} inputProps={{ 'aria-label': 'controlled' }} />
                  </span>
                </>
              )}
              <span>
                <span style={{ marginTop: '8px' }}>Cancelled </span>
                <Switch checked={cancelled} onChange={handleCancelled} inputProps={{ 'aria-label': 'controlled' }} />
              </span>
            </span>
          </div>
        )}
        {isLoader ? (
          <div className="d-flex justy-content-center" style={{ height: '70vh' }}>
            <LoadingSpinner color="grey" />
          </div>
        ) : (
          <Table aria-label="collapsible table" className="item__details__table tabular">
            <TableHead>
              <TableRow>
                <TableCell className="table__details__headerCell" style={{ paddingLeft: '1rem', width: '6.5rem' }}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      setOpen(!open);
                      onChangeHandler(true);
                    }}
                  >
                    {new Set(openArray).size === 2 ? <RemoveIcon /> : open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
                  </IconButton>
                  <Checkbox
                    sx={{
                      color: '#8D8C8D',
                      '&.Mui-checked': {
                        color: '#1BA39C',
                      },
                    }}
                    indeterminate={new Set(checkedArray).size === 2 ? true : false}
                    indeterminateIcon={<IndeterminateCheckBox sx={{ color: '#1BA39C' }} />}
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked);
                      onChangeHandler(false);
                    }}
                  />
                </TableCell>
                <TableCell className="table__details__headerCell">Job Number</TableCell>
                <TableCell className="table__details__headerCell">Line ID</TableCell>
                <TableCell className="table__details__headerCell">Title</TableCell>
                <TableCell className="table__details__headerCell">Product Type</TableCell>
                <TableCell className="table__details__headerCell">Due Date</TableCell>
                <TableCell className="table__details__headerCell text-end pe-2">Production(£)</TableCell>
                <TableCell className="table__details__headerCell text-end pe-2">Material(£)</TableCell>
                <TableCell className="table__details__headerCell text-end pe-2">Delivery(£)</TableCell>
                <TableCell className="table__details__headerCell text-end pe-2">Total(£)</TableCell>
                <TableCell className="table__details__headerCell">Status</TableCell>
                <TableCell className="table__details__headerCell">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_PerPageData_.currentData().length > 0 &&
                _PerPageData_.currentData().map((jobQuote, i) => (
                  <Fragment key={jobQuote.jobQuoteId}>
                    <TableRow sx={{ height: '4px' }}></TableRow>
                    <RowDetails
                      jobQuote={jobQuote}
                      onOpen={event => {
                        handleOpen(event, jobQuote.productTemplateId, jobQuote.jobQuoteId);
                      }}
                      onSelect={() => {
                        handleSelect(i);
                      }}
                    />
                  </Fragment>
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <div className="d-flex justify-content-center ">
        <div className="pagination">
          <Pagination count={count} size="large" page={page} onChange={handlePageChange} />
        </div>
      </div>
    </>
  );
};

export default RFQSupplierView;
