//  Externals
import { Modal, Button, Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
//  Internals
import * as yup from 'yup';

const RejectDiscountPopup = props => {
  const { onHide, onSubmit, show } = props;

  const validationSchema = yup.object({
    rejectionReason: yup.string().trim().nullable().required('Please give Reason For Rejection'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const formSubmit = async data => {
    onSubmit({ accepted: false, rejectionReason: data.rejectionReason });
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      aria-labelledby="contained-modal-title-vcEnter"
      backdropClassName="fullScreen"
      centered
      onHide={() => onHide()}
      dialogClassName="modal__popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Reason For Rejection</h4>
        </Modal.Title>
      </Modal.Header>
      <Form id="reject-discount" style={{ backgroundColor: 'white' }}>
        <Modal.Body style={{ marginLeft: '1.5rem', marginRight: '1.5rem' }}>
          <Form.Group>
            <Form.Control as="textarea" className={errors.rejectionReason && 'border-danger'} rows={3} autoComplete="off" type="text" {...register('rejectionReason')} />
          </Form.Group>
          {errors.rejectionReason && <Form.Text className="text-danger">{errors.rejectionReason.message}</Form.Text>}
        </Modal.Body>
        <hr />
        <Modal.Footer>
          <Button variant="outline-primary" className="outline__button" onClick={() => onHide()}>
            Cancel
          </Button>
          <Button
            form="reject-discount"
            variant="outline-danger"
            className="btn btn-outline-danger"
            style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem', borderRadius: '6px' }}
            type="button"
            onClick={handleSubmit(formSubmit)}
          >
            Reject
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default RejectDiscountPopup;
