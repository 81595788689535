import React, { useState, useEffect } from 'react';

import { TableCell, TableContainer, Table, TableHead, TableBody, TableRow, Paper } from '@mui/material';
import { selectDropDown, getEditBusinessUnitsData, getAccountUsers } from '../../slice/selector';
import CustomSelect from 'app/components/shared/CustomSelect';
import { protectedResources } from 'configs/msalAuthConfigs';
import { getBusinessUnitsList } from '../../slice/selector';
import ListItemText from '@material-ui/core/ListItemText';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { request } from 'utils/request';
import _without from 'lodash/without';
import * as yup from 'yup';

export const EditBusinessUnit = props => {
  const { onHide, show, businessUnitId } = props;
  const getEditDataLoad = useSelector(getEditBusinessUnitsData);
  const { actions: editBusinessAction } = useInvoiceSlice();
  const BusinessUnits = useSelector(getBusinessUnitsList);
  const { actions: businessAction } = useInvoiceSlice();
  const accountUsers = useSelector(getAccountUsers);
  const getDropdowns = useSelector(selectDropDown);
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const [personName, setPersonName] = useState<string[]>([]);
  const [dropDowns, setDropDowns] = useState<any>({});
  const [editData, setEditData] = useState<any>({});

  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .transform(value => ([...BusinessUnits?.data?.map(item => item.name)].filter(index => index != editData?.data?.[0]?.name).includes(value) ? true : value))
      .nullable()
      .typeError('This Unit Name is already exists')
      .required(`Unit Name is required`),
    reference: yup.string().nullable(),
    analsysCategoryId: yup.number().nullable(),
    isActive: yup.string().trim().nullable().required('Please select any one.'),
    addUser: yup.string().trim().nullable().nullable(),
  });
  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPersonName(event.target.value as string[]);
  };
  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setPersonName(current => _without(current, value));
  };
  const userIdUpdate = accountUsers?.data?.items?.filter(item => personName.includes(item.name) === true);
  const selectedUserIds = userIdUpdate?.map(item => item.id);
  const listOfEditSelectedId = accountUsers?.data?.items?.filter(item => editData?.data?.[0]?.user?.includes(item?.id));
  const listOfEditSelectEdit = listOfEditSelectedId?.map(item => item?.name);

  useEffect(() => {
    setEditData(getEditDataLoad);
    setDropDowns(getDropdowns?.data);
  }, [getDropdowns?.data, getEditDataLoad]);

  useEffect(() => {
    businessUnitId && dispatch(editBusinessAction.loadEditBusinessUnitList(businessUnitId));
  }, [businessUnitId, dispatch, editBusinessAction]);

  useEffect(() => {
    if (accountId) {
      reset({ ...editData?.data?.[0] });
      reset({ ...editData?.data?.[0], isActive: editData?.data?.[0]?.isActive ? 'Yes' : 'No' });
    }
    listOfEditSelectEdit && setPersonName([...listOfEditSelectEdit]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, editData?.data, reset]);

  const onSubmit = async data => {
    const formData = {
      businessUnitId: businessUnitId,
      name: data.name && data.name,
      parentAccountId: Number(accountId),
      reference: data.reference && data.reference,
      analsysCategoryId: data.analsysCategoryId && data.analsysCategoryId,
      isActive: data.isActive && data.isActive === 'Yes' ? true : false,
      user: [...selectedUserIds],
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Admin/UpdateAccountBusinessUnit/${businessUnitId}`, {
        method: 'PUT',
        body: JSON.stringify(formData),
      });

      accountId && dispatch(businessAction.loadBusinessUnits(+accountId));
      businessUnitId && dispatch(editBusinessAction.loadEditBusinessUnitList(businessUnitId));
    } catch (err) {}
    reset();
    onHide();
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="fullScreen"
      style={{ width: '80%', marginLeft: '10%' }}
      centered
      dialogClassName="modal__printSpecificationForm"
      keyboard={false}
      onHide={() => {
        onHide();
      }}
      scrollable={true}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>Edit Business Unit</h2>
        </Modal.Title>
        <hr style={{ margin: '0px' }} />
      </Modal.Header>

      <Modal.Body>
        <Form id="editBusinessUnit" onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row">
              <Form.Group controlId="form__name">
                <Form.Label>
                  <span>
                    Name<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control autoComplete="off" type="text" {...register('name')} />
                {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
              </Form.Group>
            </div>
            <div className="row mt-3">
              <div className="col">
                <Form.Group controlId="form__reference" style={{ gridColumn: '1/2' }}>
                  <Form.Label>
                    <span>Reference</span>
                  </Form.Label>
                  <Form.Control autoComplete="off" type="text" {...register('reference')} />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group>
                  <CustomSelect
                    isDisabled={false}
                    required={false}
                    controlId={'form__analsysCategoryId'}
                    label="Analysis Category"
                    control={control}
                    name={'analsysCategoryId'}
                    placeholder={'Select'}
                    options={dropDowns?.analysisCategory?.map(item => {
                      return {
                        value: +item?.id,
                        label: item?.value,
                      };
                    })}
                    errors={undefined}
                    enableMultilineOptions={false}
                    isSearchable={false}
                    register={register}
                    value={getValues('analsysCategoryId')}
                    readOnlyFormField={false}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <Form.Group style={{ gridColumn: '2/2' }}>
                  <Form.Label>Add User</Form.Label>
                  <div>
                    <Select
                      labelId="demo-multiple-chip-checkbox-label"
                      style={{ border: '1px solid #cdcccc', width: '300px', borderRadius: '5px' }}
                      id="demo-mutiple-chip-checkbox"
                      multiple
                      value={personName}
                      placeholder={'Add Users'}
                      onChange={handleChange}
                      onOpen={() => console.log('select opened')}
                      renderValue={selected => <div></div>}
                    >
                      {accountUsers?.data?.items?.map(name => (
                        <MenuItem key={name.name} value={name.name}>
                          <Form.Check style={{ margin: '0px 10px' }} checked={personName.includes(name.name)} />
                          <ListItemText primary={name.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group controlId="isActive">
                  <Form.Label>
                    Active<span className="input__required">*</span>
                  </Form.Label>
                  <div>
                    <Form.Check disabled={false} type="radio" label="Yes" inline value="Yes" aria-label="isActive__radio-yes" id="form__isActive-yes" {...register('isActive')} />
                    <Form.Check disabled={false} type="radio" label="No" value="No" inline aria-label="isActive__radio-no" id="form__isActive-no" {...register('isActive')} />
                    {errors.isActive && <Form.Text className="text-danger">{errors.isActive.message}</Form.Text>}
                  </div>
                </Form.Group>
              </div>
            </div>
            <div>
              <div className="productsPage__header">
                <span>Users </span>
                <span className="header__button"></span>
              </div>
              <div className="products__content">
                <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
                  <Table aria-label="collapsible table" className="productItem__details__table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '1rem' }}>
                          Name
                        </TableCell>
                        <TableCell className="productItem__details__headerCell" align="right" style={{ paddingRight: '4rem' }}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {editData &&
                        personName?.map(value => {
                          return (
                            <TableRow>
                              <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '3rem' }}>
                                {value}
                              </TableCell>
                              <TableCell className="table__details__headerCell table__edit" align="right">
                                <span onClick={e => handleDelete(e, value)} style={{ marginRight: '50px', color: '#1BA39C', cursor: 'pointer' }}>
                                  Remove
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </Form>
        <hr style={{ margin: '0px' }} />
      </Modal.Body>
      <Modal.Footer>
        <Button className="outline__button" onClick={() => onHide()} variant="outline-primary">
          Cancel
        </Button>
        <Button form="editBusinessUnit" className="full__button" type="submit" variant="outline-primary">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
