//  Externals
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//  Internals
import { ACCEPTED, CONFIRMATION, DELIVERED, ERROR, FULL, ORDERED, QUOTATION, SUCCESS, QUOTED, READYTOINVOICE, INVOICED, NEWRFQ, DRAFT } from 'app/components/shared/Constants';
import PlaceSupplierOrderPopUp from '../../../../pages/requestForQuote/orderFromSupplier/PlaceSupplierOrderPopUp';
import { ReactComponent as ConfirmationWarningIcon } from '../../../../../assets/icons/confirmationWarning.svg';
import ProductOrderConfirmation from '../../../../pages/requestForQuote/confirmOrder/ProductOrderConfirmation';
import PlaceSupplierOrder from '../../../../pages/requestForQuote/placeSupplierOrder/PlaceSupplierOrder';
import ClientQuoteConfirmation from '../../../../pages/requestForQuote/quotes/ClientQuoteConfirmation';
import { getAddPoAction, getJobInformation } from './../../../../../store/reducers/entities/selectors';
import OrderFromSupplier from '../../../../pages/requestForQuote/orderFromSupplier/OrderFromSupplier';
import { selectProductItemsButtonsHeader } from './../../../../pages/requestForQuote/slice/selectors';
import { selectProductItemDetailsRows } from '../../../../pages/requestForQuote/slice/selectors';
import { setIsAddPoAction, setJobInformation, setUpdateJobData } from 'store/reducers/entities';
import { ReactComponent as AddProductItemIcon } from '../../../../../images/addProductItem.svg';
import { getOtherJobCostTableList } from './../../../../pages/requestForQuote/slice/selectors';
import { ReactComponent as ClientQuoteIcon } from '../../../../../images/clientQuote.svg';
import { DeliveredPODPopup } from '../../../../pages/requestForQuote/DeliveredPODPopup';
import { ReactComponent as SuccessIcon } from '../../../../../assets/icons/success.svg';
import { getProductItems, getActionToggle } from '../../../../../store/reducers/entities/selectors';
import PlaceOrder from '../../../../pages/requestForQuote/confirmOrder/PlaceOrder';
import { useRequestForQuoteSlice } from '../../../../pages/requestForQuote/slice';
import { ReactComponent as SubmitIcon } from '../../../../../images/submit.svg';
import { ReactComponent as OrderIcon } from '../../../../../images/order.svg';
import { protectedResources } from '../../../../../configs/msalAuthConfigs';
import UseSupplierQuoteHandlerModal from './UseSupplierQuoteHandlerModal';
import { getIsCDS, getIsClient } from '../../sideBar/slice/selectors';
import { setActionToggleState } from 'store/reducers/entities/index';
import { ProductItemType } from '../../../../../types/myJobs';
import PopupModal from 'app/components/shared/PopupModal';
import { apiCallBegan } from 'store/reducers/loading';
import MODULES from '../../../../../data/modules';
import STATUS from '../../../../../data/status';
import Switch from '@mui/material/Switch';
import API from '../../../../../data/api';
import { request } from 'utils/request';
import Filters from './actions/Filters';
import { HeaderProps } from './types';
import LoadingSpinnerWithBackdrop from 'app/components/shared/LoadingSpinnerWithBackdrop';

type SubmitModal = {
  show: boolean;
  type: string;
  header: string;
  bodyTitle: string;
  bodyContent: ProductItemType[];
  showFooterButtons: boolean;
};

const Header = (props: HeaderProps) => {
  const { jobId } = useParams();
  const otherCostList = useSelector(getOtherJobCostTableList);
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));
  const productItems: ProductItemType[] = useSelector(getProductItems(MODULES.MY_JOBS));
  const [checkedValues, setCheckValues] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(false);
  //  Job information
  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));
  const productItemDetailsRows = useSelector(selectProductItemDetailsRows);

  const isAddPoAction = useSelector(getAddPoAction(MODULES.IS_ADD_PO_ACTION));

  //  Drafter Product items
  const draftedProductItems: ProductItemType[] = productItems.filter(productItem => productItem.printSpecifications.status.code === STATUS.DRAFT);
  const selectedDraftedProductItems: ProductItemType[] = draftedProductItems.filter(productItem => productItem.checked);
  const filteredDraftedProductItems: ProductItemType[] = selectedDraftedProductItems.length !== 0 ? selectedDraftedProductItems : draftedProductItems;

  //  Ready To Invoiced Product items
  const deliveredProductItems: ProductItemType[] = productItems.filter(
    productItem => productItem.printSpecifications.status.code === STATUS.DELIVERED && productItem.printSpecifications?.noPo === null,
  );
  const selectedDeliveredProductItems: ProductItemType[] = jobInformation ? deliveredProductItems.filter(productItem => productItem.checked) : deliveredProductItems;
  const filteredDeliveredProductItems: ProductItemType[] = jobInformation?.partialInvoiceAllowed
    ? selectedDeliveredProductItems.length !== 0
      ? selectedDeliveredProductItems
      : deliveredProductItems
    : deliveredProductItems;
  //  Ready To Invoiced Product items NoPo
  const deliveredProductItemsNoPo: ProductItemType[] = productItems.filter(
    productItem => productItem.printSpecifications.status.code === STATUS.DELIVERED && productItem.printSpecifications?.noPo != null,
  );
  const selectedDeliveredProductItemsNoPo: ProductItemType[] = jobInformation ? deliveredProductItemsNoPo.filter(productItem => productItem.checked) : deliveredProductItems;
  const filteredDeliveredProductItemsNoPo: ProductItemType[] = selectedDeliveredProductItemsNoPo.length !== 0 ? selectedDeliveredProductItemsNoPo : deliveredProductItemsNoPo;

  //  Delivered
  const orderedProductItems: ProductItemType[] = productItems.filter(
    productItem => productItem.printSpecifications.status.code === STATUS.ACCEPTED && productItem.printSpecifications?.orderedFlag === true,
  );
  const selectedOrderedProductItems: ProductItemType[] = orderedProductItems.filter(productItem => productItem.checked);
  const filteredOrderedProductItems: ProductItemType[] = selectedOrderedProductItems.length !== 0 ? selectedOrderedProductItems : orderedProductItems;

  const headerButtons = useSelector(selectProductItemsButtonsHeader);
  const buttons = headerButtons.buttons;

  const checkedProductItems: ProductItemType[] = productItems.filter(productItem => productItem.checked);
  const checkedProductItemsIds = checkedProductItems.map(productItem => productItem.printSpecifications.templateId);
  const productItemDetailsCheckedList = productItemDetailsRows.filter(item => checkedProductItemsIds.includes(item.id));

  //  Filter Modal
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [PlaceOrderActualConfirmationModal, setPlaceOrderActualConfirmationModal] = useState(false);
  const [placeSupplierOrderPopUpBodyContent, setPlaceSupplierOrderPopUpBodyContent] = useState<any[]>([]);
  const [placeSupplierOrderSelectedOrderId, setPlaceSupplierOrderSelectedOrderId] = useState<any[]>([]);
  const [isPlaceOrderConfirmationModal, setIsPlaceOrderConfirmationModal] = useState(false);
  const [supplierQuoteHandlerModal, setSupplierQuoteHandlerModal] = useState(false);
  const otherJobCostTableList = useSelector(getOtherJobCostTableList);
  const [placeOrderConfirmationModal, setPlaceOrderConfirmationModal] = useState(false);
  const [confirmSupplierOrderModal, setConfirmSupplierOrderModal] = useState(false);
  const [clientQuoteCreationModal, setClientQuoteCreationModal] = useState(false);
  const [showQuoteBasketModal, setShowQuoteBasketModal] = useState(false);
  const [quoteBasket, setQuoteBasket] = useState<null | boolean>(null);
  const [confirmOrderModal, setConfirmOrderModal] = useState(false);
  const [orderFromSupplier, setOrderFromSupplier] = useState(false);
  const [filteredQuotation, setFilteredQuotation] = useState<any>();
  const [placeSupplierOrderPopUp, setPlaceSupplierOrderPopUp] = useState(false);
  const [orderFromSupplierSuccess, setOrderFromSupplierSuccess] = useState(false);
  const [actionToggle, setActionToggle] = useState<boolean>(true);
  const [deliveredPODPopup, setDeliveredPODPopup] = useState(false);
  const [clientQuoteModal, setClientQuoteModal] = useState(false);
  const [productTemplateIds, setProductTemplateIds] = useState<number[]>([]);
  const [productTemplateTitle, setProductTemplateTitle] = useState<any>([]);
  const [checked, setChecked] = useState(false);
  const [rfqJobId, setRfqjobId] = useState<any>();
  const [quoteBasketBtn, setQuoteBasketBtn] = useState<boolean>(true);
  const [readytoInvoiceErrorPopup, setReadytoInvoiceErrorPopup] = useState<boolean>(false);
  const { actions } = useRequestForQuoteSlice();
  const dispatch = useDispatch();
  const isCDS = useSelector(getIsCDS);
  const isClient = useSelector(getIsClient);
  const productQuotationRow =
    productItemDetailsCheckedList.length > 0 ? productItemDetailsCheckedList?.filter(item => item.status === 'Accepted') : productItemDetailsRows?.filter(item => item.status === 'Accepted');
  const filteredQuotes = productQuotationRow
    .map(item => {
      return {
        productName: `${item.title} - ${item.productType}`,
        productId: item.id,
        data: item.details.jobQuotes.filter(item => item.jobQuoteSummary.statusName === 'Quoted'),
      };
    })
    .filter(item => item.data.length > 0);
  const [filteredQuoteState, setFilteredQuoteState] = useState<any>([]);

  //Err
  const tabsErrorPopup: SubmitModal = {
    show: false,
    type: ERROR,
    header: 'Error',
    bodyTitle: '',
    bodyContent: [],
    showFooterButtons: true,
  };
  /*  Submit Modal  */
  const initialSubmitModal: SubmitModal = {
    show: false,
    type: CONFIRMATION,
    header: 'Confirmation',
    bodyTitle: 'Are you sure you want to submit these items?',
    bodyContent: [],
    showFooterButtons: false,
  };
  const [submitModal, setSubmitModal] = useState(initialSubmitModal);

  /******************************************************************************************
  @Purpose    :   Submit Modal - To show submit modal
  *******************************************************************************************/
  const showSubmitModal = () => {
    const productItemDetails = productItemDetailsCheckedList.length > 0 ? productItemDetailsCheckedList : productItemDetailsRows;
    if (productItemDetails?.filter(item => item.status != 'Draft').length === 0) {
      setSubmitModal({ ...initialSubmitModal, show: true, bodyContent: filteredDraftedProductItems });
    } else if (productItemDetailsCheckedList.length == 0) {
      setSubmitModal({ ...initialSubmitModal, show: true, bodyContent: filteredDraftedProductItems });
    } else {
      setSubmitModal({ ...tabsErrorPopup, show: true, bodyTitle: "Please only select to submit product items with a status of 'Draft'." });
    }
  };

  /******************************************************************************************
  @Purpose    :   Submit Modal - To hide submit modal
  *******************************************************************************************/
  const hideSubmitPopUpHandler = () => {
    setSubmitModal({ ...initialSubmitModal, show: false });
  };

  /******************************************************************************************
  @Purpose    :   Submit Modal - To confirm submit modal
  *******************************************************************************************/
  const confirmSubmitHandler = async () => {
    const requestBody: number[] = filteredDraftedProductItems.map(entity => entity.printSpecifications.templateId);
    hideSubmitPopUpHandler();
    try {
      const response: any = await request(`${protectedResources.api.apiEndpoint}/RFQ/SubmitRfqJob/${jobId}`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      });
      if (response.isSuccess) {
        response && dispatch(actions.updateProductItemDetailsAfterSubmit(response.data.items));
        dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setUpdateJobData.type, module: MODULES.MY_JOBS }));
        setSubmitModal({
          show: true,
          type: SUCCESS,
          header: 'Success',
          bodyTitle: 'Submit successful for these items.',
          bodyContent: filteredDraftedProductItems,
          showFooterButtons: true,
        });
      }
    } catch (error) {
      setSubmitModal(prevState => {
        return {
          ...prevState,
          show: true,
          type: ERROR,
          header: 'Error',
          bodyTitle: 'Submit failed for these items.',
        };
      });
    }
  };

  /*  Ready To Invoice Modal  */
  const initialReadyToInvoiceModal: SubmitModal = {
    show: false,
    type: CONFIRMATION,
    header: 'Ready To Invoice',
    bodyTitle: 'Are you sure you want to submit Ready To Invoice ?',
    bodyContent: [],
    showFooterButtons: false,
  };
  const [readyToInvoiceModal, setReadyToInvoiceModal] = useState(initialReadyToInvoiceModal);

  /*  Ready To Invoice Error Modal  */
  const initialReadyToInvoiceModalErr: SubmitModal = {
    show: false,
    type: ERROR,
    header: 'Ready to invoice',
    bodyTitle: 'To set these items as Ready to invoice you must provide PO details for the following items:',
    bodyContent: [],
    showFooterButtons: true,
  };

  /******************************************************************************************
  @Purpose    :  Ready To Invoice Modal - To hide Ready To Invoice
  *******************************************************************************************/
  const hideReadyToInvoicePopUpHandler = () => {
    setReadyToInvoiceModal({ ...initialReadyToInvoiceModal, show: false });
  };

  /******************************************************************************************
  @Purpose    :   Ready To Invoice Modal - To confirm Ready To Invoice
  *******************************************************************************************/
  const confirmReadyToInvoiceHandler = async () => {
    const data = productItemDetailsRows
      ?.filter(item => item?.status === DELIVERED && item.details?.printSpecifications?.form?.noPo != true)
      ?.map(item => item.details?.printSpecifications?.form?.templateId);
    const productTemplatesList: number[] = filteredDeliveredProductItems.map(entity => entity.printSpecifications.templateId);
    const formData = {
      rfqJobId: Number(jobId),
      productTemplates: jobInformation?.partialInvoiceAllowed ? productTemplatesList : data,
    };
    hideReadyToInvoicePopUpHandler();
    setLoading(true);
    try {
      await request(`${protectedResources.api.apiEndpoint}/RFQ/UpdateToReadyToInvoice`, {
        method: 'POST',
        body: JSON.stringify(formData),
      })
        .then(res => {
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.error('err', error.response);
        });
      dispatch(actions.UpdateToReadyToInvoice());
      jobId && dispatch(actions.loadRfqData({ jobId: jobId, toggleAction: toggleAction }));
      jobId && dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setUpdateJobData.type, module: MODULES.MY_JOBS }));
      setReadyToInvoiceModal({
        show: true,
        type: SUCCESS,
        header: 'Success',
        bodyTitle: 'Submit successful for these items.',
        bodyContent: filteredDeliveredProductItems,
        showFooterButtons: true,
      });
    } catch (error) {
      setReadyToInvoiceModal(prevState => {
        return {
          ...prevState,
          show: true,
          type: ERROR,
          header: 'Error',
          bodyTitle: 'Submit failed for these items.',
        };
      });
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setActionToggle(!actionToggle);
    dispatch(setActionToggleState({ module: MODULES.MY_JOBS, actionToggle: actionToggle }));
  };

  const [clientQuoteCreationModalData, setClientQuoteCreationModalData] = useState<any>([]);
  const productWithQuotation =
    productItemDetailsCheckedList.length > 0
      ? [...productItemDetailsCheckedList.filter(item => item.status === QUOTATION && item.details.clientQuotations !== null), ...productItemDetailsRows.filter(item => item.quoted != null)]
      : productItemDetailsRows;
  const productWithQuotationApproveQuotesData = productItemDetailsCheckedList.length > 0 ? productItemDetailsCheckedList : productItemDetailsRows;
  const productWithQuotationApproveQuotes = productWithQuotationApproveQuotesData?.filter(item => item?.details?.printSpecifications?.form?.isActive === true);
  const handleClientQuoteConfirmationHandler = () => {
    if (jobInformation.quoteBasket === null) setShowQuoteBasketModal(true);
    else clientQuoteConfirmationHandler();
    setClientQuoteCreationModal(false);
  };

  const handleQuoteBasketModal = () => {
    setShowQuoteBasketModal(false);
    //  Update job information
    dispatch(apiCallBegan({ url: API.UPDATE_JOB, body: { ...jobInformation, quoteBasket }, method: 'put', onSuccess: setJobInformation.type, module: MODULES.MY_JOBS }));
    clientQuoteConfirmationHandler();
  };

  const clientQuoteConfirmationHandler = () => {
    const jobQuoteIds = productWithQuotation
      .map(item => item.details.jobQuotes)
      .filter(item => item.length > 0)
      .map(item => item.filter(item => item.jobQuoteSummary.checked))
      .filter(item => item.length > 0)
      .map(item => item[0].jobQuoteSummary.jobQuoteId);
    dispatch(actions.loadClientQuoteConfirmationPopUp({ rfqJobId: jobId, jobQuoteIds }));
    setClientQuoteModal(true);
  };

  const filteredQuotedQuotes = productWithQuotation
    .filter(item => item.value != null)
    .filter(item => item?.details?.printSpecifications?.form?.isActive != false)
    .filter(item => !['On Hold', 'Cancelled'].includes(item.statusName))
    .map(item => {
      return {
        productName: `${item.title} ${item.productType}`,
        productId: item.id,
        data: item.details.jobQuotes.filter(item => item.jobQuoteSummary),
      };
    })
    .filter(item => item.data.length > 0);
  var popupBodyMessage: any = [];
  filteredQuotedQuotes.forEach(item => {
    var q = item.data.map(item => item.jobQuoteSummary).filter(item => item.checked);
    if (q.length > 0) {
      popupBodyMessage.push(item.productName);
    }
  });
  const test = JSON.parse(JSON.stringify(popupBodyMessage));
  useEffect(() => {
    setClientQuoteCreationModalData(test);
  }, [test && test.length]);

  const clientQuoteHandler = () => {
    const productItemDetails = productItemDetailsCheckedList.length > 0 ? productItemDetailsCheckedList : productItemDetailsRows;
    let errClientQuote = productItemDetails?.map(item => item?.value).includes(null);
    if (productItemDetails?.filter(item => item.status != 'Quotation').length === 0 && !errClientQuote) {
      setClientQuoteCreationModal(true);
    } else if (productItemDetailsCheckedList.length == 0) {
      setClientQuoteCreationModal(true);
    } else {
      setSubmitModal({ ...tabsErrorPopup, show: true, bodyTitle: 'Please only select product items that contain client quote details.' });
    }
  };

  const confirmSupplierOrderHandler = () => {
    dispatch(actions.loadPlaceSupplierOrderPopUp());
    setConfirmSupplierOrderModal(true);
  };

  const orderFromSupplierConfirm = () => {
    if (
      productItemDetailsCheckedList.length === 0 ||
      productItemDetailsCheckedList
        ?.filter(item => item.status === 'Accepted')
        ?.map(item => item?.details?.jobQuotes)
        ?.flat()
        ?.filter(item => item?.jobQuoteSummary?.statusName === 'Quoted')?.length === productItemDetailsCheckedList?.length
    ) {
      setFilteredQuoteState(filteredQuotes);
      setOrderFromSupplier(true);
    } else {
      setSubmitModal({ ...tabsErrorPopup, show: true, bodyTitle: 'Please only select items that have been ordered by the client.' });
    }
  };

  const DeliveredPopupHandler = () => {
    const productItemDetails = productItemDetailsCheckedList.length > 0 ? productItemDetailsCheckedList : productItemDetailsRows;
    const productItemDeliveredDetails = productItemDetails?.filter(
      item => item.status === STATUS.ACCEPTED && item?.details?.printSpecifications?.form?.orderedFlag === true && item?.details?.printSpecifications?.form?.isActive === true,
    );
    if (
      productItemDetailsCheckedList?.length === 0 ||
      productItemDetailsCheckedList
        ?.filter(item => item?.status === 'Accepted')
        ?.map(item => item?.details?.jobQuotes)
        ?.flat()
        ?.filter(item => item?.jobQuoteSummary?.statusName === 'Order')?.length === productItemDetailsCheckedList?.length
    ) {
      setProductTemplateIds(productItemDeliveredDetails?.map(item => item.id));
      setProductTemplateTitle(productItemDeliveredDetails?.map(item => item.title));
      setRfqjobId(jobId);
      setDeliveredPODPopup(true);
    } else {
      setSubmitModal({ ...tabsErrorPopup, show: true, bodyTitle: 'Please only select items that have been ordered .' });
    }
  };

  /******************************************************************************************
  @Purpose    :   Ready To Invoice Modal - To show Ready To Invoice
  *******************************************************************************************/
  const showReadyToInvoiceModal = () => {
    if (
      productItemDetailsCheckedList?.filter(item => item?.details?.printSpecifications?.form?.noPo === true).length === 0 &&
      filteredDeliveredProductItems.length > 0 &&
      (productItemDetailsCheckedList?.length === 0 || productItemDetailsCheckedList?.filter(item => item?.status === 'Delivered')?.length === productItemDetailsCheckedList?.length)
    ) {
      jobInformation.invoiceContactEmailAddress != null && jobInformation.invoiceAddress != null
        ? setReadyToInvoiceModal({ ...initialReadyToInvoiceModal, show: true, bodyContent: filteredDeliveredProductItems })
        : setReadytoInvoiceErrorPopup(true);
    } else {
      if (productItemDetailsCheckedList?.filter(item => item?.status != 'Delivered').length > 0) {
        setSubmitModal({ ...tabsErrorPopup, show: true, bodyTitle: 'Please only select items that have been delivered.' });
      } else {
        jobInformation.invoiceContactEmailAddress != null && jobInformation.invoiceAddress != null
          ? setReadyToInvoiceModal({ ...initialReadyToInvoiceModal, show: true, bodyContent: filteredDeliveredProductItems })
          : setReadytoInvoiceErrorPopup(true);
      }
    }
  };
  const useSupplierQuoteHandler = () => {
    const productItemDetails = productItemDetailsCheckedList.length > 0 ? productItemDetailsCheckedList : productItemDetailsRows;
    if (productItemDetails?.filter(item => item.status != 'Quotation').length === 0) {
      setSupplierQuoteHandlerModal(true);
    } else if (productItemDetailsCheckedList.length == 0) {
      setSupplierQuoteHandlerModal(true);
    } else {
      setSubmitModal({ ...tabsErrorPopup, show: true, bodyTitle: "Please only select to submit product items with a status of 'Quotation'." });
    }
  };

  useEffect(() => {
    const productItemDetails = productItemDetailsCheckedList.length > 0 ? productItemDetailsCheckedList : productItemDetailsRows;
    const filteredQuotation = productItemDetails.filter(item => item.status === QUOTATION && item.details.clientQuotations !== null && item.details?.printSpecifications?.form?.isActive === true);
    const submitButtonState = productItemDetails.filter(item => item.statusName === DRAFT).length > 0;
    const hideDelivered =
      productItemDetails.filter(row => row.status === ACCEPTED && row.details.printSpecifications.form.orderedFlag === true && row.details?.printSpecifications?.form?.isActive === true)?.length > 0;
    const hideClientQuoteTab =
      (productItemDetails?.filter(item => item?.status === 'Draft').length > 0 && otherCostList?.filter(item => item?.status?.code === 'NewRFQ').length > 0) ||
      productItemDetails?.filter(item => item?.status === 'Quotation' && item?.value && item?.quoted === null && item.details?.printSpecifications?.form?.isActive === true).length > 0;

    const hideAddProductItem = productItemDetailsRows?.filter(row => [DELIVERED, READYTOINVOICE, INVOICED, null].includes(row.status)).length === 0;

    const partialInvoiceAllowedWithDraft = productItemDetailsRows
      ?.filter(item => ![STATUS.DRAFT, STATUS.CANCELLED, null].includes(item.status))
      ?.filter(item => item?.details?.printSpecifications?.form?.isActive === true);
    const ReadyToInvoiceState =
      productItemDetailsRows.filter(item => item.status === DELIVERED && item?.details?.printSpecifications?.form?.noPo === true).length === productItemDetailsRows.length
        ? true
        : jobInformation?.partialInvoiceAllowed
        ? jobInformation?.accountCode && jobInformation?.codePrint && productItemDetails?.filter(row => row.status === DELIVERED && row?.details?.printSpecifications?.form?.noPo != true).length > 0
          ? false
          : true
        : jobInformation?.accountCode && jobInformation?.codePrint && productItemDetailsRows?.filter(row => row.status === DELIVERED).length === partialInvoiceAllowedWithDraft.length
        ? productItemDetailsRows?.filter(row => row.status === DELIVERED).length === 0
        : true;
    setFilteredQuotation(filteredQuotation);
    const orderFromSupplierState =
      productItemDetails.filter(item => item.status === ACCEPTED && item.details.printSpecifications.form.orderedFlag === false && item.details?.printSpecifications?.form?.isActive === true).length >
      0;
    let jobQuotesChecked = productItemDetailsRows.map(item => {
      return item.details.jobQuotes.some(item => {
        return item.jobQuoteSummary.checked;
      });
    });

    // Use Supplier
    const productQuotationRow = productItemDetails.filter(item => item.status === QUOTATION && item.details.clientQuotations == null && item.details?.printSpecifications?.form?.isActive === true);
    const filteredQuotedQuotes = productQuotationRow
      .map(item => {
        return {
          productName: `${item.title}`,
          quantityRunon: item?.details?.printSpecifications?.form?.quantityRunOn ?? null,
          productId: item.id,
          data: item.details.jobQuotes.filter(item => item.jobQuoteSummary.statusName === QUOTED),
        };
      })
      .filter(item => item.data.length > 0);
    var productAndCheckedQuote: any = [];
    filteredQuotedQuotes.forEach(item => {
      var q = item.data.map(item => item.jobQuoteSummary);
      if (q.length > 0) {
        productAndCheckedQuote.push(...q);
      }
    });
    let useSupplierQuoteButton = productAndCheckedQuote.length === 0;
    let approveQuoteBtn = true;
    if (productItemDetails?.filter(item => item?.status === 'Draft').length > 0 && otherCostList?.filter(item => item.status.code === STATUS.QUOTATION).length > 0) {
      approveQuoteBtn = false;
    } else if (productItemDetailsCheckedList.length > 0) {
      approveQuoteBtn =
        productItemDetailsCheckedList.filter(item => item.status === 'Quotation' && item.quoted !== null && item.details?.printSpecifications?.form?.isActive === true).length > 0 ? false : true;
    } else {
      approveQuoteBtn =
        productItemDetailsRows.filter(item => item.status === 'Quotation' && item.quoted !== null && item.details?.printSpecifications?.form?.isActive === true).length > 0 ? false : true;
    }
    dispatch(
      actions.changeButtonsHeader({
        filters: { hide: false, disabled: false },
        submit: { hide: !submitButtonState, disabled: !submitButtonState },
        clientQuote: { hide: !hideClientQuoteTab, disabled: !hideClientQuoteTab },
        approveQuote: {
          hide: approveQuoteBtn,
          disabled: approveQuoteBtn,
        },
        order: { hide: true, disabled: true },
        addProductItem: { hide: !hideAddProductItem, disabled: !hideAddProductItem },
        orderFromSupplier: {
          hide: !orderFromSupplierState,
          disabled: !orderFromSupplierState,
        },
        useSupplierQuote: { hide: useSupplierQuoteButton, disabled: useSupplierQuoteButton },
        delivered: { hide: !hideDelivered, disabled: !hideDelivered },
        readyToInvoice: { hide: ReadyToInvoiceState, disabled: ReadyToInvoiceState },
      }),
    );
  }, [actions, dispatch, jobInformation?.partialInvoiceAllowed, productItemDetailsRows, productItems]);

  const handleApproveQuote = () => {
    const productItemDetails = productItemDetailsCheckedList.length > 0 ? productItemDetailsCheckedList : productItemDetailsRows;
    const errApproveQuotes = productItemDetails
      ?.filter(item => item?.details?.printSpecifications?.form?.isActive === true)
      ?.map(item => item?.quoted)
      .includes(null);
    if ((productItemDetails?.filter(item => item.status !== 'Quotation').length === 0 && !errApproveQuotes) || productItemDetailsCheckedList.length === 0) {
      const quantityRunOn = filteredQuotation?.filter(item => {
        return item?.details?.printSpecifications?.form?.quantityRunOn;
      });
      const isQuantityRunOn = quantityRunOn?.includes(null) || quantityRunOn == 0;
      const quantityActual = filteredQuotation?.map(item => item?.quantityActual);
      const isQuantityActual = quantityActual?.includes(null);
      if (isQuantityRunOn) {
        // if No Run-on-Quantity
        setPlaceOrderActualConfirmationModal(true);
      } else {
        if (isQuantityActual) {
          if (
            isQuantityActual &&
            productWithQuotation?.filter(entity => entity?.statusName === QUOTATION && entity?.quoted != null && entity?.quantityActual != null).length ===
              productWithQuotation.filter(item => item?.statusName === QUOTATION && item.quoted != null).length
          ) {
            setPlaceOrderActualConfirmationModal(true);
          } else {
            // if No Run-on-Quantity and not Quantity Actual
            setPlaceOrderConfirmationModal(true);
          }
        } else {
          // if no Quantity Actual
          setPlaceOrderActualConfirmationModal(true);
        }
      }
    } else {
      setSubmitModal({ ...tabsErrorPopup, show: true, bodyTitle: 'Please only select to approve product lines that have been quoted.' });
    }
  };

  useEffect(() => {
    isAddPoAction && setConfirmOrderModal(true);
  }, [isAddPoAction]);

  const quoteBasketHandler = () => {
    setQuoteBasket(false);
    setQuoteBasketBtn(false);
  };

  useEffect(() => {
    if (!orderFromSupplierSuccess && filteredQuoteState?.length > 0 && filteredQuotes?.length > 0) {
      setOrderFromSupplier(true);
    } else {
      filteredQuotes?.length == 0 && setFilteredQuoteState([]);
      setOrderFromSupplier(false);
    }
  }, [orderFromSupplierSuccess]);

  return (
    <>
      {showFilterModal && <Filters show={showFilterModal} onHide={() => setShowFilterModal(false)} />}
      <Modal
        backdrop="static"
        centered
        keyboard={false}
        onHide={() => {
          hideSubmitPopUpHandler();
        }}
        show={submitModal.show}
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>{submitModal.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              {(submitModal.type === CONFIRMATION || submitModal.type === ERROR) && <ConfirmationWarningIcon />}
              {submitModal.type === SUCCESS && <SuccessIcon />}
              <span>{submitModal.bodyTitle}</span>
            </div>
            {submitModal.bodyContent && (
              <ol>
                {submitModal.bodyContent.map((row, index) => (
                  <li key={index}>
                    {row.printSpecifications.title} - {row.printSpecifications.productType}
                  </li>
                ))}
              </ol>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {submitModal.showFooterButtons ? (
            <Button
              className="full__button"
              onClick={() => {
                hideSubmitPopUpHandler();
              }}
              variant="primary"
            >
              OK
            </Button>
          ) : (
            <>
              <Button
                className="outline__button_productConfirmation"
                onClick={() => {
                  hideSubmitPopUpHandler();
                }}
                variant="outline-primary"
              >
                No
              </Button>
              <Button
                className="outline__button_productConfirmation"
                onClick={() => {
                  confirmSubmitHandler();
                }}
                variant="outline-primary"
              >
                Yes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <>
        {loading && <LoadingSpinnerWithBackdrop color="grey" message="Processing order - please wait" />}
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          backdropClassName="fullScreen__overPopup"
          centered
          keyboard={false}
          onHide={() => {
            hideReadyToInvoicePopUpHandler();
          }}
          scrollable
          show={readyToInvoiceModal.show}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{readyToInvoiceModal.header}</Modal.Title>
          </Modal.Header>
          <hr style={{ margin: '0px' }} />
          <Modal.Body>
            <div className="popupModal__bodyContainer">
              <div className="body__titleContainer">
                {(readyToInvoiceModal.type === CONFIRMATION || readyToInvoiceModal.type === ERROR) && <ConfirmationWarningIcon />}
                {readyToInvoiceModal.type === SUCCESS && <SuccessIcon />}
                <span className="body__title">{readyToInvoiceModal.bodyTitle}</span>
              </div>
              {readyToInvoiceModal.bodyContent && (
                <ol className="body__contentContainer submit-padding">
                  {readyToInvoiceModal.bodyContent.map((row, index) => (
                    <li key={index}>
                      {row.printSpecifications.title} - {row.printSpecifications.productType}
                    </li>
                  ))}
                </ol>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {readyToInvoiceModal.showFooterButtons ? (
              <Button
                className="outline__button"
                onClick={() => {
                  hideReadyToInvoicePopUpHandler();
                }}
                variant="outline-primary"
              >
                Ok
              </Button>
            ) : (
              <>
                <Button
                  className="outline__button"
                  onClick={() => {
                    hideReadyToInvoicePopUpHandler();
                  }}
                  variant="outline-primary"
                >
                  No
                </Button>
                <Button
                  className="full__button"
                  onClick={() => {
                    confirmReadyToInvoiceHandler();
                  }}
                  variant="primary"
                >
                  Yes
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </>
      <Modal backdrop="static" show={clientQuoteCreationModal} centered onHide={() => setClientQuoteCreationModal(false)}>
        <div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Client Quote</Modal.Title>
          </Modal.Header>
        </div>
        <Modal.Body>
          <div className="popupModal__bodyContainer">
            <div className="body__titleContainer mb-2">
              <span className="body__title">Are you sure you want to create client quotes from the following?</span>
            </div>
            {clientQuoteCreationModalData && (
              <>
                <ol className="body__contentContainer mt-3">
                  {clientQuoteCreationModalData?.map((row, index) => (
                    <li key={index}>{row}</li>
                  ))}
                  {otherJobCostTableList?.length > 0 && otherJobCostTableList?.map((item, index) => <li key={index}>{item?.name}</li>)}
                </ol>
                <ol>
                  <Form.Check type="checkbox" label="Do not send an email notification" onChange={e => setCheckValues(true)} />
                </ol>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button key="NO" variant="outline-primary" className="outline__button" onClick={() => setClientQuoteCreationModal(false)}>
            No
          </Button>
          <Button key="YES" variant="primary" className="full__button" onClick={handleClientQuoteConfirmationHandler}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        backdrop="static"
        show={showQuoteBasketModal}
        centered
        onHide={() => {
          setShowQuoteBasketModal(false);
          setQuoteBasketBtn(true);
        }}
      >
        <div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Quote Confirmation</Modal.Title>
          </Modal.Header>
        </div>
        <Modal.Body>
          <div className="popupModal__bodyContainer">
            <div className="body__titleContainer mb-2">
              <span className="body__title">Is this a Quote Basket?</span>
            </div>
            <div>
              <Form.Check name="quoteBasket" id="form__quoteBasket-yes" inline label="Yes" type="radio" onClick={() => quoteBasketHandler()} />
            </div>
            <div>
              <Form.Check name="quoteBasket" id="form__quoteBasket-no" inline label="No" type="radio" onClick={() => quoteBasketHandler()} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            key="NO"
            variant="outline-primary"
            className="outline__button"
            onClick={() => {
              setShowQuoteBasketModal(false);
              setQuoteBasketBtn(true);
            }}
          >
            Cancel
          </Button>
          <Button key="YES" variant="primary" className="full__button" disabled={quoteBasketBtn} onClick={() => handleQuoteBasketModal()}>
            Save & Proceed
          </Button>
        </Modal.Footer>
      </Modal>
      <ClientQuoteConfirmation
        show={clientQuoteModal}
        checkedValues={checkedValues}
        onHide={() => setClientQuoteModal(false)}
        productItemDetailsRows={
          productItemDetailsCheckedList.length > 0
            ? [...productItemDetailsCheckedList.filter(item => item.status === QUOTATION && item.details.clientQuotations !== null), ...productItemDetailsRows.filter(item => item.quoted != null)]
            : productItemDetailsRows
        }
        productItemDetailsRowsConfirm={productItemDetailsCheckedList.length > 0 ? [...productItemDetailsRows.filter(item => item.quoted != null)] : productItemDetailsRows}
      />
      <ProductOrderConfirmation
        show={confirmOrderModal}
        onHide={() => {
          dispatch(setIsAddPoAction({ module: MODULES.IS_ADD_PO_ACTION, isAddPoAction: false }));
          setConfirmOrderModal(false);
          setIsPlaceOrderConfirmationModal(false);
        }}
        onConfirmOrder={() => {
          dispatch(setIsAddPoAction({ module: MODULES.IS_ADD_PO_ACTION, isAddPoAction: false }));
          setConfirmOrderModal(false);
          setPlaceOrderConfirmationModal(false);
          setIsPlaceOrderConfirmationModal(true);
          setPlaceOrderActualConfirmationModal(true);
        }}
        // productItemDetailsCheckedList={productItemDetailsCheckedList}
      />
      <PlaceOrder
        show={PlaceOrderActualConfirmationModal}
        onHide={() => setPlaceOrderActualConfirmationModal(false)}
        onPlaceOrder={() => {
          setPlaceOrderConfirmationModal(false);
          setPlaceOrderActualConfirmationModal(false);
          setConfirmOrderModal(true);
          setTimeout(() => {
            setIsPlaceOrderConfirmationModal(true);
          }, 1000);
        }}
        onSuccess={() =>
          setTimeout(() => {
            setIsPlaceOrderConfirmationModal(false);
          })
        }
        productWithQuotation={productWithQuotationApproveQuotes}
        isSuccess={isPlaceOrderConfirmationModal}
        otherCostList={otherCostList}
      />
      <PopupModal
        show={placeOrderConfirmationModal}
        onHide={() => setPlaceOrderConfirmationModal(false)}
        type={ERROR}
        header="Place Order"
        bodyTitle="To place this order you need to provide Quantity Actual for the following items"
        bodyContent={productWithQuotationApproveQuotes
          ?.filter(entity => entity?.statusName === QUOTATION && entity?.quoted != null && entity?.quantityActual === null)
          .map(row => row.title + ' - ' + row.productType)}
        footerButtons={[
          {
            type: FULL,
            name: 'Ok',
            onClick: () => {
              setPlaceOrderConfirmationModal(false);
            },
          },
        ]}
      />
      <PlaceSupplierOrder show={confirmSupplierOrderModal} onHide={() => setPlaceSupplierOrderPopUp(false)} />
      {orderFromSupplier && (
        <OrderFromSupplier
          show={orderFromSupplier}
          onPlaceOrder={() => {
            setOrderFromSupplier(false);
            setPlaceSupplierOrderPopUp(true);
          }}
          onCancel={() => setOrderFromSupplier(false)}
          jobId={jobId}
          productItemDetailsCheckedList={productItemDetailsCheckedList}
          productItemDetailsRows={productItemDetailsRows}
          onOrderFromSupplier={() => {
            setOrderFromSupplier(false);
          }}
          selectedProduct={val => {
            var x = productItemDetailsRows.filter(item => val.indexOf(item.id) > -1).map(item => `${item.title} - ${item.productType}`);
            setPlaceSupplierOrderPopUpBodyContent(x);
            setPlaceSupplierOrderSelectedOrderId(val);
          }}
        />
      )}
      {placeSupplierOrderPopUp && (
        <PlaceSupplierOrderPopUp
          show={placeSupplierOrderPopUp}
          onCancel={() => setPlaceSupplierOrderPopUp(false)}
          onPlaceOrder={() => {
            setPlaceSupplierOrderPopUp(false);
            setOrderFromSupplierSuccess(true);
          }}
          jobId={jobId}
          placeSupplierOrderSelectedOrderId={placeSupplierOrderSelectedOrderId}
        />
      )}
      {orderFromSupplierSuccess && (
        <PopupModal
          show={orderFromSupplierSuccess}
          onHide={() => setOrderFromSupplierSuccess(false)}
          type={SUCCESS}
          header={'Success'}
          bodyTitle={'Order has been placed with the supplier'}
          bodyContent={placeSupplierOrderPopUpBodyContent}
          footerButtons={[
            {
              type: FULL,
              name: 'OK',
              onClick: () => {
                setOrderFromSupplierSuccess(false);
              },
            },
          ]}
        />
      )}

      <DeliveredPODPopup show={deliveredPODPopup} onHide={() => setDeliveredPODPopup(false)} productTemplateTitle={productTemplateTitle} productTemplateIds={productTemplateIds} rfqJobId={rfqJobId} />
      <PopupModal
        show={readytoInvoiceErrorPopup}
        onHide={() => setReadytoInvoiceErrorPopup(false)}
        type={ERROR}
        header={''}
        bodyTitle={'Please provide the job invoice details'}
        footerButtons={[
          {
            type: FULL,
            name: 'OK',
            onClick: () => {
              setReadytoInvoiceErrorPopup(false);
            },
          },
        ]}
      />

      {supplierQuoteHandlerModal && (
        <UseSupplierQuoteHandlerModal
          show={supplierQuoteHandlerModal}
          onHide={() => setSupplierQuoteHandlerModal(false)}
          productItemDetailsRows={productItemDetailsCheckedList.length > 0 ? productItemDetailsCheckedList : productItemDetailsRows}
        />
      )}
      {productItems.length !== 0 && (
        <>
          <h4>Product Items ({productItems.length})</h4>
          {jobInformation?.statusName != 'Cancelled' && (
            <div className="buttons__container">
              <span style={{ marginTop: '8px' }}>Inactive</span>
              <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
              {!buttons.submit.hide && (
                <p className={`header__button ${buttons.submit.disabled ? 'header__button-disabled' : 'header__button-active'}`} onClick={() => showSubmitModal()}>
                  Submit
                  <SubmitIcon className="svg__icon" />
                </p>
              )}
              {isCDS && !buttons.useSupplierQuote.hide && (
                <p className={`header__button ${buttons.useSupplierQuote.disabled && 'header__button-disabled'}`} onClick={useSupplierQuoteHandler}>
                  Use Supplier Quote
                  <ClientQuoteIcon className="svg__icon" />
                </p>
              )}
              {isCDS && !buttons.clientQuote.hide && (
                <p className={`header__button ${buttons.clientQuote.disabled && 'header__button-disabled'}`} onClick={clientQuoteHandler}>
                  Client Quote
                  <ClientQuoteIcon className="svg__icon" />
                </p>
              )}
              {isClient && !buttons.approveQuote.hide && (
                <p className={`header__button ${buttons.approveQuote.disabled && 'header__button-disabled'}`} onClick={() => handleApproveQuote()}>
                  Order
                  <ClientQuoteIcon className="svg__icon" />
                </p>
              )}
              {isCDS && !buttons.approveQuote.hide && (
                <p className={`header__button ${buttons.approveQuote.disabled && 'header__button-disabled'}`} onClick={() => handleApproveQuote()}>
                  Approve Quote
                  <ClientQuoteIcon className="svg__icon" />
                </p>
              )}
              {isCDS && !buttons.orderFromSupplier.hide && (
                <p className={`header__button ${buttons.orderFromSupplier.disabled && 'header__button-disabled'}`} onClick={() => orderFromSupplierConfirm()}>
                  Order from Supplier
                </p>
              )}
              {isCDS && !buttons.order.hide && (
                <p className={`header__button ${buttons.order.disabled && 'header__button-disabled'}`} onClick={confirmSupplierOrderHandler}>
                  Order
                  <OrderIcon className="svg__icon" />
                </p>
              )}
              {isCDS && !buttons.delivered.hide && (
                <p className={`header__button ${buttons.delivered.disabled && 'header__button-disabled'}`} onClick={DeliveredPopupHandler}>
                  Delivered
                </p>
              )}
              {isCDS && !buttons.readyToInvoice.hide && (
                <p className={`header__button ${buttons.readyToInvoice.disabled && 'header__button-disabled'}`} onClick={() => showReadyToInvoiceModal()}>
                  Ready to invoice
                </p>
              )}
              {!buttons.addProductItem.hide && (
                <p className={`header__button ${buttons.addProductItem.disabled && 'header__button-disabled'}`} onClick={() => props.onShowProductsModal()}>
                  Add Product Item
                  <AddProductItemIcon className="svg__icon" />
                </p>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Header;
