import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestForQuoteSlice } from '../slice';
import { selectConfirmClientOrderPODetails } from '../slice/selectors';
import InputGroup from 'react-bootstrap/InputGroup';
import { getAddPoAction } from 'store/reducers/entities/selectors';
import MODULES from 'data/modules';

const PODetails = props => {
  const poDetails = useSelector(selectConfirmClientOrderPODetails);
  const [noPODetails, setNoPODetails] = useState(false);
  const commentRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();
  const { actions } = useRequestForQuoteSlice();
  const isAddPoAction = useSelector(getAddPoAction(MODULES.IS_ADD_PO_ACTION));

  let currentOrderTotal = Number(props.currentOrder.reduce((sum, { details }) => roundNumber(sum + Number(details?.clientQuotations?.actualSummary?.totalNetPrice), 2), 0)?.toFixed(2));
  currentOrderTotal = currentOrderTotal ? currentOrderTotal : 0;

  let currentOrderOtherCostTotal = Number((+props.currentOtherCost?.reduce((sum, { netValue }) => roundNumber(sum + Number(netValue), 2), 0))?.toFixed(2));
  currentOrderOtherCostTotal = currentOrderOtherCostTotal ? currentOrderOtherCostTotal : 0;

  let newOrderTotal = Number(+props.newOrder.reduce((sum, { details }) => roundNumber(sum + Number(details?.clientQuotations?.actualSummary?.totalNetPrice), 2), 0)?.toFixed(2));
  newOrderTotal = newOrderTotal ? newOrderTotal : 0;
  let newOrderOtherCostTotal = Number(+props.newOtherCost?.reduce((sum, { netValue }) => roundNumber(sum + Number(netValue), 2), 0)?.toFixed(2));
  newOrderOtherCostTotal = newOrderOtherCostTotal ? newOrderOtherCostTotal : 0;

  const total = roundNumber(currentOrderTotal + currentOrderOtherCostTotal + newOrderTotal + newOrderOtherCostTotal, 2);

  function roundNumber(number, decimals) {
    // eslint-disable-next-line no-new-wrappers
    var newnumber = new Number(number + '').toFixed(parseInt(decimals));
    return parseFloat(newnumber);
  }

  const onPONumberChange = (id, event) => {
    event.target.value == ''
      ? dispatch(actions.updatePONumber({ id, value: event.target.value, isValidValue: false }))
      : dispatch(actions.updatePONumber({ id, value: event.target.value, isValidValue: true }));
  };
  const onPOValueChange = (id, val, event) => {
    const targetValue = JSON.parse(JSON.stringify(Number(event.target.value)));
    if (props.jobInfo != null) {
      const jobInfoPoNumbers = props.jobInfo.map(item => item.poNumber);
      const removedPoList = props.originalPoList?.filter(item => jobInfoPoNumbers.indexOf(item.poNumber) === -1);
      const isRemoved = removedPoList.length > 0;
      const isRemovedTotal = removedPoList.reduce((a, { poValue }) => roundNumber(a + Number(poValue), 2), 0);
      const noPoCurrentOrderList = props.currentOrder?.filter(item => item?.details?.printSpecifications?.form?.poDetails == null);
      const noPoCurrentOrderListTotal =
        noPoCurrentOrderList.length > 0 ? Number(noPoCurrentOrderList.reduce((sum, { details }) => roundNumber(sum + Number(details?.clientQuotations?.actualSummary?.totalNetPrice), 2), 0)) : 0;

      // const removedTotal = Number(removedPoList.reduce((sum, { poValue }) => sum + poValue, 0));
      var otherCostIds: any = null;
      const x = props.jobInfo?.filter(item => item.poNumber == val);
      var y = Number(
        props.currentOrder
          ?.filter(item => item?.details?.printSpecifications?.form?.poDetails?.poNumber == val)
          ?.map(item => item.details?.clientQuotations?.actualSummary?.totalNetPrice)
          ?.reduce((sum, poValue) => roundNumber(sum + Number(poValue), 2), 0),
      );
      if (props?.currentOtherCost !== null && props?.currentOtherCost?.length > 0) {
        otherCostIds = props.currentOtherCost?.filter(item => x?.otherCostIds?.indexOf(item.id) > -1).map(item => item.netValue);
        y = otherCostIds
          ? noPoCurrentOrderList.length > 0
            ? Number(roundNumber(otherCostIds.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0) + y + noPoCurrentOrderListTotal, 2))
            : Number(roundNumber(otherCostIds.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0) + y, 2))
          : 0.0;
      } else {
        y = noPoCurrentOrderList.length > 0 ? roundNumber(y + Number(noPoCurrentOrderListTotal), 2) : Number(y?.toFixed(2));
      }

      if (props.jobInfo.length > 0 && props.jobInfo[props.jobInfo.length - 1].poNumber !== val && poDetails.length == 1) {
        Number(targetValue) >= total
          ? dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: true,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            )
          : dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: false,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            );
      } else if (props.jobInfo.length > 0 && props.jobInfo[props.jobInfo.length - 1].poNumber == val && poDetails.length == 1) {
        Number(targetValue) == y || Number(targetValue) >= total
          ? dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: true,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            )
          : dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: false,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            );
      } else if (x.length > 0) {
        // const filterCurrentPo = poDetails?.filter(item => item.poNumber !== val);
        // const poDetailsTotal = Number(filterCurrentPo.reduce((sum, { poValue }) => sum + poValue, 0));
        if (!isRemoved) {
          if (Number(targetValue) >= roundNumber(newOrderTotal + y, 2)) {
            const poDetailsSatisfiedNewOrder: any = poDetails?.filter(item => item.satisfiedNewOrder);
            if (poDetailsSatisfiedNewOrder.length > 0) {
              dispatch(
                actions.updatePOValue({
                  id: poDetailsSatisfiedNewOrder[0].id,
                  value: poDetailsSatisfiedNewOrder[0].poValue,
                  valid: true,
                  satisfiedTotal: false,
                  satisfiedRemoved: false,
                  satisfiedNewOrder: false,
                  isPreviousSatisfiedNewOrder: true,
                  isPreviousSatisfiedRemoved: false,
                }),
              );
            }
            dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: true,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: true,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            );
          } else if (Number(targetValue) >= y) {
            dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: true,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            );
          } else {
            dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: false,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            );
          }
        }

        if (isRemoved) {
          if (Number(targetValue) >= newOrderTotal + y + isRemovedTotal) {
            const satisfyNewAndRemoved: any = poDetails?.filter(item => item.satisfiedRemoved);
            if (satisfyNewAndRemoved.length > 0) {
              dispatch(
                actions.updatePOValue({
                  id: satisfyNewAndRemoved[0].id,
                  value: satisfyNewAndRemoved[0].poValue,
                  valid: true,
                  satisfiedTotal: false,
                  satisfiedRemoved: false,
                  satisfiedNewOrder: false,
                  isPreviousSatisfiedNewOrder: true,
                  isPreviousSatisfiedRemoved: true,
                }),
              );
            }
            dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: true,
                satisfiedTotal: false,
                satisfiedRemoved: true,
                satisfiedNewOrder: true,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            );
          } else if (Number(targetValue) >= y) {
            dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: true,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            );
          } else {
            dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: false,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            );
          }
        }
      } else if (props.originalPoList.filter(item => item.poNumber == val).length === 0) {
        Number(targetValue) >= roundNumber(newOrderTotal + noPoCurrentOrderListTotal, 2)
          ? dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: true,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: true,
                isPreviousSatisfiedRemoved: false,
              }),
            )
          : dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: false,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: true,
                isPreviousSatisfiedRemoved: false,
              }),
            );
      } else {
        Number(targetValue) >= newOrderTotal
          ? dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: true,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            )
          : dispatch(
              actions.updatePOValue({
                id,
                value: targetValue,
                valid: false,
                satisfiedTotal: false,
                satisfiedRemoved: false,
                satisfiedNewOrder: false,
                isPreviousSatisfiedNewOrder: false,
                isPreviousSatisfiedRemoved: false,
              }),
            );
      }
    } else if (props.jobInfo == null) {
      Number(targetValue) >= total
        ? dispatch(
            actions.updatePOValue({
              id,
              value: targetValue,
              valid: true,
              satisfiedTotal: false,
              satisfiedRemoved: false,
              satisfiedNewOrder: false,
              isPreviousSatisfiedNewOrder: false,
              isPreviousSatisfiedRemoved: false,
            }),
          )
        : dispatch(
            actions.updatePOValue({
              id,
              value: targetValue,
              valid: false,
              satisfiedTotal: false,
              satisfiedRemoved: false,
              satisfiedNewOrder: false,
              isPreviousSatisfiedNewOrder: false,
              isPreviousSatisfiedRemoved: false,
            }),
          );
    } else {
      Number(targetValue) >= newOrderTotal
        ? dispatch(
            actions.updatePOValue({
              id,
              value: targetValue,
              valid: true,
              satisfiedTotal: false,
              satisfiedRemoved: false,
              satisfiedNewOrder: false,
              isPreviousSatisfiedNewOrder: false,
              isPreviousSatisfiedRemoved: false,
            }),
          )
        : dispatch(
            actions.updatePOValue({
              id,
              value: targetValue,
              valid: false,
              satisfiedTotal: false,
              satisfiedRemoved: false,
              satisfiedNewOrder: false,
              isPreviousSatisfiedNewOrder: false,
              isPreviousSatisfiedRemoved: false,
            }),
          );
    }

    val == '' && dispatch(actions.updatePONumber({ id, value: val, isValidValue: false }));
  };
  const addNewPO = () => {
    poDetails.length < props.jobInfo?.length + 1 && dispatch(actions.addPODetails(''));
  };
  const removePO = id => {
    dispatch(actions.removePODetails(id));
  };
  const onNoPOValueChange = e => {
    setNoPODetails(e.target.checked);
    dispatch(actions.updatePOValueChange({ value: e.target.checked }));
    // resetPODetails();
  };
  const removeAllPO = () => {
    dispatch(actions.removeAllPODetails());
  };
  /**
   * @Purpose
   * to reset all PO details
   * Currently comented code may need in future
   */
  // const resetPODetails = () => {
  //   var poDetailsCopy = JSON.parse(JSON.stringify(poDetails));
  //   poDetailsCopy.forEach(item =>
  //     dispatch(
  //       actions.resetPODetails({
  //         id: item.id,
  //         poNumber: '',
  //         poValue: 0,
  //         valid: true,
  //       }),
  //     ),
  //   );
  // };
  const onPOCommentChange = e => {
    dispatch(actions.updatePOComment({ value: e.target.value }));
  };
  useEffect(() => {
    props.jobInfo?.length > 0 ? dispatch(actions.addPODetails(props.jobInfo)) : dispatch(actions.addPODetails(''));
    return () => removeAllPO();
  }, []);

  return (
    <div className="po__details">
      {poDetails.map((poDetail, index) => (
        <div key={poDetail.id}>
          {props.currentOrder.length > 0 && (
            <>
              <span
                style={{
                  display: 'inline',
                  marginRight: '20px',
                  fontWeight: 'bold',
                }}
              >
                New PO
              </span>
              <span
                style={{
                  display: 'inline',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  props.onRmovePoDetails(poDetail.id);
                  removePO(poDetail.id);
                }}
              >
                X Remove
              </span>
            </>
          )}
          <div className="po__details__form">
            <Form.Group controlId="poNumber">
              <Form.Label>PO Number</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                className={`${!poDetail?.isValidValue && 'input__invalid'}`}
                onChange={event => onPONumberChange(poDetail.id, event)}
                value={poDetail.poNumber}
              />
            </Form.Group>
            <Form.Group className="mb-3 modal_placeholder" controlId="formName">
              <Form.Label>PO Value (Net)</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text style={{ padding: '6px 16px' }}>£</InputGroup.Text>
                <Form.Control
                  autoComplete="off"
                  aria-label="Amount (to the nearest dollar)"
                  type="number"
                  onChange={event => onPOValueChange(poDetail.id, poDetail.poNumber, event)}
                  className={`${!poDetail.valid && 'input__invalid'}`}
                  value={poDetail.poValue === 0 ? '' : poDetail.poValue}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      ))}

      {!isAddPoAction && <Form.Check type="checkbox" label="No PO value available" onChange={onNoPOValueChange} />}
      {noPODetails && (
        <Form.Group controlId="comment" style={{ marginTop: '1.5rem' }}>
          <Form.Label>Comment</Form.Label>
          <Form.Control autoComplete="off" as="textarea" rows={3} placeholder="sample text" onChange={onPOCommentChange} ref={commentRef} />
        </Form.Group>
      )}
      {(props.newOrder.length > 0 || isAddPoAction) && (
        <span
          style={
            props?.originalPoList === null || poDetails.length == props.jobInfo?.length + 1 || Number(poDetails.reduce((sum, { poValue }) => sum + poValue, 0)) >= total
              ? {
                  color: '#dddddd',
                  textDecoration: 'underline',
                  cursor: 'default',
                  marginTop: '1.5rem',
                  width: '45%',
                }
              : {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginTop: '1.5rem',
                  width: '45%',
                }
          }
          onClick={() => poDetails.length < props.jobInfo?.length + 1 && addNewPO()}
        >
          + Add New Purchase Order
        </span>
      )}
    </div>
  );
};

export default PODetails;
