//  Externals
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
//  Internals
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { registrationActions as actions } from '.';
import { request } from 'utils/request';
import { RoleType } from './types';

function* getAllAccounts() {
  const requestURL = `${protectedResources.api.apiEndpoint}/RFQ/GetAllAccounts`;
  try {
    const response: any = yield call(request, requestURL);
    if (response.isSuccess) {
      yield put(actions.setAccounts(response.data.items));
    } else {
      yield put(
        actions.RegistrationError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.RegistrationError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.RegistrationError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* getAllSuppliers() {
  const requestURL = `${protectedResources.api.apiEndpoint}/Supplier/api/Supplier/GetAllSupplier`;
  try {
    const response: any = yield call(request, requestURL);
    if (response.isSuccess) {
      yield put(actions.setSuppliers(response.data.items));
    } else {
      yield put(
        actions.RegistrationError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.RegistrationError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.RegistrationError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* getBusinessUnits() {
  const requestURL = `${protectedResources.api.apiEndpoint}/RFQ/GetBusinessUnits`;
  try {
    const response: any = yield call(request, requestURL);
    if (response.isSuccess) {
      yield put(actions.RegistrationFormBusinessUnitData(response.data.items));
    } else {
      yield put(
        actions.RegistrationError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.RegistrationError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.RegistrationError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* setRoles() {
  const requestURL = `${protectedResources.api.apiEndpoint}/AD/GetGroups`;
  try {
    const roles: RoleType[] = yield call(request, requestURL);
    if (roles) {
      yield put(actions.setRoles(roles));
    } else {
      yield put(
        actions.RegistrationError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.RegistrationError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.RegistrationError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* getClientOrSupplierUsers(action) {
  const requestURL = `${protectedResources.api.apiEndpoint}/User/GetListofAdminUser/${action.payload.isClient}/${action.payload.isSupplier}`;
  try {
    const response: any = yield call(request, requestURL);
    if (response.isSuccess) {
      if (action.payload.isClient) {
        yield put(actions.setClientUsers(response.data.items));
      } else if (action.payload.isSupplier) {
        yield put(actions.setSupplierUsers(response.data.items));
      }
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      console.error('error');
    } else {
      console.error('error');
    }
  }
}

export function* RegistrationSaga() {
  yield takeEvery(actions.initialRegistrationState.type, getAllAccounts);
  yield takeEvery(actions.initialRegistrationState.type, getAllSuppliers);
  yield takeEvery(actions.initialRegistrationState.type, getBusinessUnits);
  yield takeEvery(actions.initialRegistrationState.type, setRoles);
  yield takeLatest(actions.loadClientOrSupplierUser.type, getClientOrSupplierUsers);
}
