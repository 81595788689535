//  Externals
import axios, { AxiosResponse } from 'axios';
import Swal from 'sweetalert2';
//  Internals
import { API_URL } from '../configs/msalAuthConfigs';
import getAccessToken from './getAccessToken';
import { msalInstance } from '../index';
import { Headers } from './model';

/*************************************************************************************
  @purpose    :   Set Success Message
**************************************************************************************/
const showSuccessMessage = msg => {
  Swal.fire({
    icon: 'success',
    position: 'center',
    showConfirmButton: false,
    text: msg,
    timer: 2500,
  });
};
/*************************************************************************************
  @purpose    :   Set Error Message
**************************************************************************************/
const showErrorMessage = msg => {
  Swal.fire({
    icon: 'error',
    position: 'center',
    showConfirmButton: false,
    title: 'Oops...',
    text: msg,
    timer: 3000,
  });
};
/*************************************************************************************
  @purpose    :   Set Warning Message
**************************************************************************************/
const showWarningMessage = msg => {
  Swal.fire({
    icon: 'warning',
    position: 'center',
    showConfirmButton: false,
    text: msg,
    timer: 3000,
  });
};

/*******************************************************************************************
  @PURPOSE    : Call api.
  @Parameters :   {
                      url : <url of api>
                      data : <data object (JSON)>
                      method : String (get, post)
                      isForm (Optional) : Boolean - to call api with form data
                      isPublic (Optional) : Boolean - to call api without auth header
                  }
/*****************************************************************************************/
const callApi = async ({ url, method, body = {}, isMock = false, isAuthorized = true, isForm = false }) => {
  let errorMessage;
  //  Interceptors -- Start
  axios.interceptors.response.use(null, error => {
    // const expectedError =
    //  error.response &&
    //  error.response.status >= 400 &&
    //  error.response.status < 500;
    console.log('error ===>>>', error);
    console.log('error.response.status ===>>>', error.response.message);
    if (error.response.status === 404) {
      showErrorMessage('Network error');
    } else {
      errorMessage = error.response.data.message;
      if (typeof error.response.data.message === 'string') showErrorMessage(errorMessage);
      else showErrorMessage(errorMessage[0].msg);
      console.error('error In ====>>>> axios.interceptors <<<<====', error);
    }
    return Promise.reject(error);
  });

  //  Interceptors -- End
  //  Get Access Token
  const accessToken = await getAccessToken(msalInstance);
  const headers: Headers = {
    'Content-Type': 'application/json',
  };
  if (isAuthorized) headers.Authorization = `Bearer ${accessToken}`;
  if (isForm) headers['Content-Type'] = 'multipart/form-data';
  return new Promise(async (resolve, reject) => {
    try {
      const response: AxiosResponse = await axios(isMock ? { url } : { baseURL: API_URL, url, headers, method, data: body });
      const { data } = response;
      if (data.isSuccess) return resolve(data);
      showErrorMessage(data.message);
    } catch (error) {
      console.error('error In ====>>>> callApi <<<<====', error);
      return reject(error);
    }
  });
};

export default callApi;
