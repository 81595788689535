import React from 'react';
import { confirmOrderProductItem } from '../slice/types';

type AppProps = {
  products: confirmOrderProductItem[];
  productDetails: any;
  otherCost: any;
  isCurrent: boolean;
};

export const OrderDetailsTable = ({ products, productDetails, otherCost, isCurrent }: AppProps) => {
  return (
    <>
      <table className="table confirmOrder__table">
        <thead>
          <tr>
            <th>Ref</th>
            <th>Product Item</th>
            {isCurrent && <th>PO Number</th>}
            <th className="text-end">Quantity</th>
            <th className="text-end">Net (&#163;)</th>
            <th className="text-end">VAT(&#163;)</th>
            <th className="text-end">Total (&#163;)</th>
          </tr>
        </thead>
        <tbody>
          {productDetails.map(row => (
            <tr key={row.id}>
              <td>{row.lineId}</td>
              <td>{row.title}</td>
              {isCurrent && <td>{row?.details?.printSpecifications?.form?.poDetails?.poNumber}</td>}
              <td className="text-end">{row.quantityActual}</td>
              <td className="text-end">{row.details?.clientQuotations?.actualSummary?.totalNetPrice?.toFixed(2)}</td>
              <td className="text-end">{row.details?.clientQuotations?.actualSummary?.vatAmount?.toFixed(2)}</td>
              <td className="text-end">{row.details?.clientQuotations?.actualSummary?.grossAmount?.toFixed(2)}</td>
            </tr>
          ))}
          {otherCost?.length > 0 &&
            otherCost.map(item => (
              <tr key={item.id}>
                <td>{item.lineNumber}</td>
                <td>{item.rateItemType}</td>
                {isCurrent && <td>{item.poNumber}</td>}
                <td className="text-end">-</td>
                <td className="text-end">{item?.netValue?.toFixed(2)}</td>
                <td className="text-end">{item?.vatAmount?.toFixed(2)}</td>
                <td className="text-end">{item?.grossValue?.toFixed(2)}</td>
              </tr>
            ))}
          <tr>
            <td colSpan={isCurrent ? 4 : 3} style={{ textAlign: 'end', fontWeight: '700' }}>
              Total
            </td>
            <td className="text-end">
              {(
                productDetails &&
                productDetails?.reduce((sum, { details }) => sum + Number(Number(details?.clientQuotations?.actualSummary?.totalNetPrice)?.toFixed(2)), 0) +
                  (otherCost ? otherCost?.reduce((sum, { netValue }) => sum + Number(Number(netValue)?.toFixed(2)), 0) : 0)
              )?.toFixed(2)}
            </td>
            <td className="text-end">
              {(
                productDetails &&
                productDetails?.reduce((sum, { details }) => sum + Number(Number(details?.clientQuotations?.actualSummary?.vatAmount)?.toFixed(2)), 0) +
                  (otherCost ? otherCost?.reduce((sum, { vatAmount }) => sum + Number(Number(vatAmount)?.toFixed(2)), 0) : 0)
              )?.toFixed(2)}
            </td>
            <td className="text-end">
              {(
                productDetails &&
                productDetails?.reduce((sum, { details }) => sum + Number(Number(details?.clientQuotations?.actualSummary?.grossAmount)?.toFixed(2)), 0) +
                  (otherCost ? otherCost?.reduce((sum, { grossValue }) => sum + Number(Number(grossValue)?.toFixed(2)), 0) : 0)
              )?.toFixed(2)}
            </td>
          </tr>
          {/* {products.map(row => (
            <tr key={row.ref}>
              <td>{row.ref}</td>
              <td>{row.productItem}</td>
              <td>{row.net.toFixed(2)}</td>
              <td>{row.vat.toFixed(2)}</td>
              <td>{row.total.toFixed(2)}</td>
            </tr>
          ))} */}
        </tbody>
      </table>
    </>
  );
};
