import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { myProfileSaga } from './saga';
import { createMyProfileNavigation, myAccount, MyProfileState, myTeam, userProfile } from './types';

export const initialState: MyProfileState = {
  isLoading: false,
  error: null,
  navigation: {
    myAccount: {
      visible: false,
      selected: false,
    },
    myTeam: {
      visible: false,
      selected: false,
    },
  },
  accounts: {
    isSuccess: null,
    validationErrors: null,
    message: undefined,
    statusCode: undefined,
    data: {
      items: [],
    },
  },
  teams: {
    isSuccess: null,
    validationErrors: null,
    message: undefined,
    statusCode: undefined,
    data: {
      items: [],
    },
  },
  myProfile: {
    isSuccess: null,
    validationErrors: null,
    message: undefined,
    statusCode: undefined,
    data: {
      userId: null,
      firstName: null,
      lastName: null,
      telephoneNumber: null,
      lastLogin: null,
    },
  },
};

const slice = createSlice({
  name: 'myProfile',
  initialState,
  reducers: {
    loadMyAccount(state) {
      state.isLoading = true;
    },
    myAccountLoaded(state, action: PayloadAction<myAccount>) {
      state.accounts = action.payload;
      state.isLoading = false;
    },
    loadMyProfile(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    myProfileLoaded(state, action: PayloadAction<userProfile>) {
      state.myProfile = action.payload;
      state.isLoading = false;
    },
    loadMyTeam(state) {
      state.isLoading = true;
    },
    myTeamLoaded(state, action: PayloadAction<myTeam>) {
      state.teams = action.payload;
      state.isLoading = false;
    },
    myProfileError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
    changeNavigationMenu(state, action: PayloadAction<createMyProfileNavigation>) {
      state.navigation = action.payload;
    },
    addOrRemoveUserFavouriteAccount(state, action: PayloadAction<{ requestBody: any; isAll: boolean }>) {
      state.isLoading = true;
    },
    updateFavouriteList(state, action: PayloadAction<any>) {
      if (action.payload.isAll) {
        state.accounts.data.items = state.accounts.data.items.map(items => {
          items.isFavourite = !action.payload.requestBody.isRemove;
          return { ...items };
        });
      } else {
        state.accounts.data.items = state.accounts.data.items.map(items => {
          if (items.accountId == action.payload.requestBody.accountIds[0]) {
            items.isFavourite = !action.payload.requestBody.isRemove;
            return { ...items };
          }
          return { ...items };
        });
      }
      state.isLoading = false;
    },
    addOrRemoveMyTeam(state, action: PayloadAction<{ requestBody: any; name: string }>) {
      state.isLoading = true;
    },
    updateMyTeamList(state, action: PayloadAction<any>) {
      if (action.payload.requestBody.isRemove) {
        state.teams.data.items = state.teams.data.items.filter(items => items.memberId !== action.payload.requestBody.memberId);
      } else {
        var data = {
          memberId: action.payload.requestBody.memberId,
          name: action.payload.name,
        };
        state.teams.data.items = state.teams.data.items.concat(data);
      }
      state.isLoading = false;
    },
  },
});

export const { actions: MyProfileActions, reducer } = slice;

export const useMyProfileSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: myProfileSaga });
  return { actions: slice.actions };
};
