//  Externals
import { Button, Modal } from 'react-bootstrap';
import React from 'react';
//  Internals
import { FilerProps } from './../types';

const Filters = (props: FilerProps) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="fullScreen"
      centered
      dialogClassName="modal__printSpecificationFilters"
      keyboard={false}
      onHide={() => {
        props.onHide();
      }}
      scrollable
      show={props.show}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>Filters</Modal.Body>
      <Modal.Footer>
        <Button
          className="outline__button"
          onClick={() => {
            props.onHide();
          }}
          variant="outline-primary"
        >
          Cancel
        </Button>
        <Button
          className="full__button"
          onClick={() => {
            props.onHide();
          }}
          variant="primary"
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Filters;
