//  Externals
import * as yup from 'yup';
//  Internals
import ERRORS from '../../../../data/errors';

const schema = ({ isCDS }) =>
  yup.object({
    title: yup.string().trim().nullable().required(ERRORS.TITLE),
    accountId: yup.number().nullable().required(ERRORS.SELECT_ACCOUNT),
    businessUnitId: yup.number().nullable().required(ERRORS.SELECT_BUSINESS_UNIT),
    clientReference: yup.string().trim().nullable(),
    jobOptions: yup.array().of(yup.number()).nullable(),
    contactId: yup.number().nullable().required(ERRORS.SELECT_CONTACT),
    contactEmail: yup.string().email(ERRORS.VALID_EMAIL).trim().nullable(),
    contactPhoneNumber: yup.string().trim().nullable(),
    cdsexecutive: yup
      .number()
      .nullable()
      .when('$exist', {
        is: () => isCDS,
        then: yup.number().nullable().required(ERRORS.SELECT_CDS_CLIENT_EXECUTIVE),
      }),
    directInvoice: yup.boolean().nullable(),
    invoiceContactName: yup.string().trim().nullable().required(ERRORS.NAME),
    invoiceContactEmailAddress: yup.string().email(ERRORS.VALID_EMAIL).trim().nullable(),
    invoiceAddress: yup.string().trim().nullable(),
    forecastMonth: yup.string().trim().nullable(),
    forecastYear: yup.number().nullable(),
  });

export default schema;
