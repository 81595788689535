import { invoiceSaga } from './saga';
import { invoiceState, invoiceData } from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

export const initialState: invoiceState = {
  invoiceLoading: false,
  error: null,
  invoiceData: {
    getReadytoInvoice: [],
    totalInvoiceSummary: {
      noofJobs: 0,
      netTotals: 0,
      vatAmounts: 0,
      grossAmounts: 0,
    },
    frequencys: [],
    accounts: [],
  },
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    loadInvoice(state) {
      state.invoiceLoading = true;
    },
    invoiceDataLoaded(state, action: PayloadAction<invoiceData>) {
      state.invoiceData = action.payload;
      state.invoiceLoading = false;
    },
    dataLoadError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.invoiceLoading = action.payload.loading;
    },
    updateReadyToInvoiceData(state, action: PayloadAction<{ id: number }>) {
      state.invoiceData.getReadytoInvoice = state.invoiceData.getReadytoInvoice.filter(item => item.accountId !== action.payload.id);
    },
    updateSendToInvoiceData(state, action: PayloadAction<any>) {
      let ids = action.payload.map(item => item);
      state.invoiceData.getReadytoInvoice = state.invoiceData.getReadytoInvoice.filter(item => ids.indexOf(item.accountId) < 0);
    },
  },
});

export const { actions: dashboardActions, reducer } = slice;

export const useInvoiceSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: invoiceSaga });
  return { actions: slice.actions };
};
