//  Externals
import { createSlice } from 'utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
//  Internals
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { adminSuppliersSaga } from './saga';
import { AdminSuppliers } from './types';

export const initialState: AdminSuppliers = {
  isLoading: false,
  error: null,
  addSupplier: {},
  editSupplier: {},
  supplierList: {
    data: {
      items: [],
    },
  },
  geoLocations: [
    {
      id: 0,
      name: '',
    },
  ],
  supplierCategories: {
    supplierPrimaryCategories: [
      {
        primaryCategoryId: 0,
        name: '',
      },
    ],
    supplierSecondaryCategories: [
      {
        secondaryCategoryId: 0,
        primaryCategoryId: 0,
        name: '',
      },
    ],
  },
};

const slice = createSlice({
  name: 'adminSuppliers',
  initialState,
  reducers: {
    loadSupplierList(state) {
      state.isLoading = true;
    },
    supplierListLoaded(state, action: PayloadAction<any>) {
      state.supplierList = action.payload;
      state.isLoading = false;
    },
    supplierListError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },

    loadGeoLocations(state) {
      state.isLoading = true;
    },
    geoLocationsLoaded(state, action: PayloadAction<any>) {
      state.geoLocations = action.payload;
      state.isLoading = false;
    },
    geoLocationError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },

    loadSupplierCategories(state) {
      state.isLoading = true;
    },
    supplierCategoriesLoaded(state, action: PayloadAction<any>) {
      state.supplierCategories = action.payload;
      state.isLoading = false;
    },
    supplierCategoriesError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
  },
});

export const { actions: adminSuppliersActions, reducer } = slice;
export const useAdminSuppliersSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: adminSuppliersSaga });
  return { actions: slice.actions };
};
