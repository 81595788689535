//  Externals
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
//  Internals
import { getBusinessUnitsList, getAccountUsers } from '../../slice/selector';
import { AddBusinessUnit } from './AddBusinessUnitPopup';
import { BusinessUnitList } from './BusinessUnitList';
import { useInvoiceSlice } from '../../slice';

export const BusinessUnit = () => {
  const { actions: businessAction } = useInvoiceSlice();
  const { actions } = useInvoiceSlice();
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const [businessUnitPopup, setBusinessUnitPopup] = useState(false);
  const businessUnits = useSelector(getBusinessUnitsList);
  const accountUsers = useSelector(getAccountUsers);
  const navigate = useNavigate();

  useEffect(() => {
    accountId && dispatch(businessAction.loadBusinessUnits(+accountId));
    accountId && dispatch(actions.loadAccountUsers(+accountId));
  }, [accountId, businessAction, dispatch]);

  return (
    <div className="products__page">
      <AddBusinessUnit accountUsers={accountUsers} show={businessUnitPopup} onHide={() => setBusinessUnitPopup(false)} />
      {businessUnits && businessUnits?.data?.length === 0 ? (
        <div className="extra__container">
          <div className="addProduct__container">
            <Button className="full__button" variant="primary" style={{ paddingRight: '2rem', paddingLeft: '2rem' }} onClick={() => setBusinessUnitPopup(true)}>
              Add Business Unit
            </Button>
            <span style={{ marginTop: '0.5rem' }}>You have not added a Business Unit yet</span>
            <span>
              Click on <strong>'Add Business Unit'</strong>
            </span>
          </div>
        </div>
      ) : (
        <BusinessUnitList openBusinessUnitPopup={() => setBusinessUnitPopup(true)} />
      )}
      <footer>
        <Button className="full__button" variant="primary" style={{ paddingRight: '2rem', paddingLeft: '2rem' }} onClick={() => navigate(`/administration/account`)}>
          Done
        </Button>
      </footer>
    </div>
  );
};
