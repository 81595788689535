import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { closedPurchaseActions as actions } from './';
import { protectedResources } from 'configs/msalAuthConfigs';

function* getSearch(action) {
  const requestURL = `${protectedResources.api.apiEndpoint}/Invoice/SearchClosedPO?SearchTerm=${action.payload.value}`;
  try {
    const search: any = yield call(request, requestURL);
    if (search) {
      yield put(actions.searchLoaded(search));
    } else {
      yield put(
        actions.searchError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.searchError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.searchError({ error: 'Issue with api', loading: false }));
    }
  }
}
function* getAccount(action) {
  const accountId = action.payload?.id;
  const requestURL = `${protectedResources.api.apiEndpoint}/Invoice/GetClosedPODeatilsByAccount/${accountId}`;
  try {
    const search: any = yield call(request, requestURL);
    if (search) {
      yield put(actions.accountDetails(search));
    } else {
      yield put(
        actions.accountError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.accountError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.accountError({ error: 'Issue with api', loading: false }));
    }
  }
}
function* getPODetails(action) {
  const JobQuoteId = action.payload?.id;
  const requestURL = `${protectedResources.api.apiEndpoint}/Invoice/GetSupplierClosedInvoiceDetails/${JobQuoteId}`;
  try {
    const po: any = yield call(request, requestURL);
    if (po) {
      yield put(actions.poInvoiceDetails(po));
    } else {
      yield put(
        actions.poInvoiceDetailsError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.poInvoiceDetailsError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.poInvoiceDetailsError({ error: 'Issue with api', loading: false }));
    }
  }
}

export function* useClosedPurchaseSaga() {
  yield takeLatest(actions.loadSearch.type, getSearch);
  yield takeLatest(actions.loadSelectedAccount.type, getAccount);
  yield takeLatest(actions.loadPoInvoiceDetails.type, getPODetails);
}
