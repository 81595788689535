import React, { useEffect } from 'react';

import { getIsCDS, getIsClient, getIsSupplier, getUserId } from '../../components/modules/sideBar/slice/selectors';
import { selectNavigation, userProfileData } from './slice/selectors';
import { useBreadcrumbSlice } from '../breadcrumb/slice';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMyProfileSlice } from './slice';
import { Helmet } from 'react-helmet-async';
import { MyAccounts } from './myAccounts';
import { MyProfile } from './MyProfile';
import Breadcrumb from '../breadcrumb';
import { MyTeam } from './myTeam';
import './style.sass';

export const Profile = () => {
  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  const { actions: myProfileAction } = useMyProfileSlice();
  const createMyProfileNavigation = useSelector(selectNavigation);
  const userData = useSelector(userProfileData);

  const location = useLocation();
  const userId = useSelector(getUserId);
  const isClient = useSelector(getIsClient);
  const isCDS = useSelector(getIsCDS);
  const isSupplier = useSelector(getIsSupplier);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Home', url: '/dashboard' },
        { name: 'Profile', url: '#' },
        { name: 'My Accounts', url: '#' },
      ]),
    );
    dispatch(
      myProfileAction.changeNavigationMenu({
        myAccount: { visible: true, selected: true },
        myTeam: { visible: true, selected: false },
      }),
    );
    dispatch(myProfileAction.loadMyAccount());
    dispatch(myProfileAction.loadMyTeam());
    dispatch(myProfileAction.loadMyProfile({ userId: userId }));
  }, []);

  const onClickNavButton = val => {
    dispatch(
      myProfileAction.changeNavigationMenu({
        myAccount: { visible: true, selected: val.myAccount },
        myTeam: { visible: true, selected: val.myTask },
      }),
    );
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Home', url: '/dashboard' },
        { name: 'Profile', url: '#' },
        { name: val.myAccount ? 'My Accounts' : 'My Team', url: '#' },
      ]),
    );
  };

  return (
    <>
      <Helmet>
        <title>RFQ</title>
      </Helmet>
      <div className="myProfile">
        <Breadcrumb />
        <div className="myProfile__container">
          {isClient || isSupplier ? (
            <MyProfile userData={userData} />
          ) : (
            <>
              <div className="inpage__navigation">
                {createMyProfileNavigation.myAccount.visible && (
                  <button
                    className={`${createMyProfileNavigation.myAccount.selected && 'active'}`}
                    onClick={() =>
                      onClickNavButton({
                        myAccount: true,
                        myTask: false,
                      })
                    }
                  >
                    My Accounts
                  </button>
                )}
                {createMyProfileNavigation.myTeam.visible && (
                  <button
                    className={`${createMyProfileNavigation.myTeam.selected && 'active'}`}
                    onClick={() =>
                      onClickNavButton({
                        myAccount: false,
                        myTask: true,
                      })
                    }
                  >
                    My Team
                  </button>
                )}
              </div>
              <div className="inpage__navigation__line"></div>
              {createMyProfileNavigation.myAccount.selected && <MyAccounts />}
              {createMyProfileNavigation.myTeam.selected && <MyTeam />}
            </>
          )}
        </div>
      </div>
    </>
  );
};
