/*
 *  If we provide "Proof Not Required" option in Types of proofs field then Proof Due Date field should get disappear.
 *  If the Job Options has Complex job then only show Target date.
 */
//  Externals
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import moment from 'moment';
//  Internals
import { getProductInformationData } from '../../../../pages/requestForQuote/slice/selectors';
import { DropDown, JobItemOption, ProductProps, SecondaryCategories } from '../types';
import { getIsCDS, getIsClient, getIsSupplier } from '../../sideBar/slice/selectors';
import LoadingSpinnerWithBackdrop from '../../../shared/LoadingSpinnerWithBackdrop';
import { ReactComponent as CalendarIcon } from '../../../../../images/calendar.svg';
import { getMasterData } from '../../../../../store/reducers/masterData/selectors';
import { allowNumbers, getRowsOfTextArea } from '../../../../../utils/form/index';
import { useRequestForQuoteSlice } from '../../../../pages/requestForQuote/slice';
import { setPrintSpecifications } from '../../../../../store/reducers/entities';
import { getActionToggle } from 'store/reducers/entities/selectors';
import { ERROR, OUTLINED } from 'app/components/shared/Constants';
import VALIDATIONS from './../../../../../data/validations';
import PLACEHOLDER from '../../../../../data/placeholder';
import { BOOKLET } from '../../../../../data/constants';
import callApi from './../../../../../services/callApi';
import PopupModal from '../../../shared/PopupModal';
import ClientQuotation from './../ClientQuotation';
import MODULES from '../../../../../data/modules';
import DiscountDetails from '../DiscountDetails';
import STATUS from '../../../../../data/status';
import LineDetails from './../LineDetails';
import API from '../../../../../data/api';
import { initialBooklet } from './model';
import schema from './validator';
import '../style.sass';

const Booklet = (props: ProductProps) => {
  const { clientQuotations, create, disabled, formId, initialState, jobId, onHide, productId } = props;

  const dispatch = useDispatch();

  const isSupplier = useSelector(getIsSupplier);
  const isClient = useSelector(getIsClient);
  const isCDS = useSelector(getIsCDS);

  const deliveryAddressChangeReasons: DropDown[] = useSelector(getMasterData('deliveryAddressChangeReasons'));
  const productInformationData = useSelector(getProductInformationData);
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));

  const { actions } = useRequestForQuoteSlice();

  useEffect(() => {
    dispatch(actions.loadProductInformation({ productId, jobId }));
  }, [actions, dispatch, jobId, productId]);

  const [secondaryCategories, setSecondaryCategories] = useState<SecondaryCategories[]>([]);
  const [coverMaterialWeightsList, setCoverMaterialWeightsList] = useState<DropDown[]>([]);
  const [secondaryCategoriesList, setSecondaryCategoriesList] = useState<DropDown[]>([]);
  const [primaryCategoriesList, setPrimaryCategoriesList] = useState<DropDown[]>([]);
  const [jobItemOptionsList, setJobItemOptionsList] = useState<JobItemOption[]>([]);
  const [coloursOnOutsideList, setColoursOnOutsideList] = useState<DropDown[]>([]);
  const [materialWeightsList, setMaterialWeightsList] = useState<DropDown[]>([]);
  const [coloursOnInsideList, setColoursOnInsideList] = useState<DropDown[]>([]);
  const [coverMaterialsList, setCoverMaterialsList] = useState<DropDown[]>([]);
  const [artworkFormatsList, setArtworkFormatsList] = useState<DropDown[]>([]);
  const [coloursOnTextList, setColoursOnTextList] = useState<DropDown[]>([]);
  const [finishedSizesList, setFinishedSizesList] = useState<DropDown[]>([]);
  const [typeOfProofsList, setTypeOfProofsList] = useState<DropDown[]>([]);
  const [finishingsList, setFinishingsList] = useState<DropDown[]>([]);
  const [materialsList, setMaterialsList] = useState<DropDown[]>([]);

  useEffect(() => {
    const {
      artworkFormat,
      coloursOnInside,
      coloursOnOutside,
      coloursOnText,
      coverMaterial,
      coverMaterialWeight,
      finishedSize,
      finishing,
      jobItemOptions,
      material,
      materialWeight,
      primaryCategories,
      secondaryCategories,
      typeOfProofs,
    } = productInformationData;

    //  Art work format list
    setArtworkFormatsList(artworkFormat.map(item => ({ value: +item.value, label: item.name })));
    //  ColoursOnInside List
    setColoursOnInsideList(coloursOnInside.map(item => ({ value: +item.value, label: item.name })));
    //  Colours On Outside List
    setColoursOnOutsideList(coloursOnOutside.map(item => ({ value: +item.value, label: item.name })));
    //  Colours on Text list
    setColoursOnTextList(coloursOnText.map(item => ({ value: +item.value, label: item.name })));
    //  Cover Material List
    setCoverMaterialsList(coverMaterial.map(item => ({ value: +item.value, label: item.name })));
    //  Cover Material Weight List
    setCoverMaterialWeightsList(coverMaterialWeight.map(item => ({ value: +item.value, label: item.name })));
    //  Finished size list
    setFinishedSizesList(finishedSize.map(item => ({ value: +item.value, label: item.name })));
    //  Finishing list
    setFinishingsList(finishing.map(item => ({ value: +item.value, label: item.name })));
    //  Job Options list
    let updateJobItemOptions = jobItemOptions.map(item => ({ value: +item.value, label: item.name, checked: false }));
    if (!isEmpty(initialState.jobItemOptions)) {
      updateJobItemOptions = jobItemOptions.map(item => ({ value: +item.value, label: item.name, checked: initialState.jobItemOptions.includes(+item.value) }));
      //  Show / Hide Target Date
      const complexJobEntity = updateJobItemOptions.find(jobOption => jobOption.label.trim() === 'Complex job' && jobOption.checked);
      if (complexJobEntity) setTargetDate(true);
      else setTargetDate(false);
    } else setTargetDate(false);
    setJobItemOptionsList(updateJobItemOptions);
    //  Material list
    setMaterialsList(material.map(item => ({ value: +item.value, label: item.name })));
    //  Material Weight list
    setMaterialWeightsList(materialWeight.map(item => ({ value: +item.value, label: item.name })));
    //  Primary Categories list
    setPrimaryCategoriesList(
      primaryCategories.map(item => ({
        value: +item.primaryCategoryId,
        label: item.name,
      })),
    );
    //  Secondary Categories
    setSecondaryCategories([...secondaryCategories.map(e => ({ value: e.secondaryCategoryId, label: e.name, primaryCategoryId: e.primaryCategoryId }))]);
    //  Secondary Categories list
    if (!create) {
      var filterSecondaryCategories = secondaryCategories.filter(secondaryCategory => secondaryCategory.primaryCategoryId === initialState.primaryCategoryId);
      setSecondaryCategoriesList([...filterSecondaryCategories.map(e => ({ value: e.secondaryCategoryId, label: e.name, primaryCategoryId: e.primaryCategoryId }))]);
    } else {
      setSecondaryCategoriesList([...secondaryCategories.map(e => ({ value: e.secondaryCategoryId, label: e.name, primaryCategoryId: e.primaryCategoryId }))]);
    }
    //  Type of Proofs list
    setTypeOfProofsList(typeOfProofs.map(item => ({ value: +item.value, label: item.name })));
  }, [create, initialState.jobItemOptions, initialState.quantity, initialState.quantityRunOn, initialState.primaryCategoryId, productInformationData]);

  /******************************************************************************************
  @Purpose    :  Use Form
  *******************************************************************************************/
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: initialBooklet,
    resolver: yupResolver(
      schema({
        artworkFormatsList,
        coloursOnInsideList,
        coloursOnOutsideList,
        coloursOnTextList,
        coverMaterialsList,
        coverMaterialWeightsList,
        finishedSizesList,
        finishingsList,
        isCDS,
        jobItemOptionsList,
        materialsList,
        materialWeightsList,
        typeOfProofsList,
      }),
    ),
  });

  useEffect(() => {
    if (create) reset(initialBooklet);
    else reset(initialState);
  }, [create, initialState, reset]);

  const onError = error => {
    console.error('ERROR:::', error);
  };

  //  Other input fields
  const [deliveryAddressChangeReasonOther, setDeliveryAddressChangeReasonOther] = useState(false);
  const [isNoFailDeliveryDateChecked, setIsNoFailDeliveryDateChecked] = useState(false);
  const [coverMaterialWeightOther, setCoverMaterialWeightOther] = useState(false);
  const [coloursOnOutsideOther, setColoursOnOutsideOther] = useState(false);
  const [coloursOnInsideOther, setColoursOnInsideOther] = useState(false);
  const [materialWeightOther, setMaterialWeightOther] = useState(false);
  const [showProofOfDueDate, setShowProofOfDueDate] = useState(false);
  const [coloursOnTextOther, setColoursOnTextOther] = useState(false);
  const [artworkFormatOther, setArtworkFormatOther] = useState(false);
  const [coverMaterialOther, setCoverMaterialOther] = useState(false);
  const [finishedSizeOther, setFinishedSizeOther] = useState(false);
  const [typeOfProofsOther, setTypeOfProofsOther] = useState(false);
  const [finishingOther, setFinishingOther] = useState(false);
  const [materialOther, setMaterialOther] = useState(false);
  const [targetDate, setTargetDate] = useState(false);
  const [samples, setSamples] = useState(false);

  useEffect(() => {
    const {
      artworkFormatId,
      coloursOnInsideId,
      coloursOnOutsideId,
      coloursOnTextId,
      coverMaterialId,
      coverMaterialWeightId,
      deliveryAddressChangeReasonId,
      finishedSizeId,
      finishingId,
      materialId,
      materialWeightId,
      noFailDeliveryDate,
      samples,
      typeOfProofsId,
    } = initialState;
    //  Artwork format Other
    const artworkFormat = artworkFormatsList.find(entity => entity.value === artworkFormatId);
    if (artworkFormat && artworkFormat.label === VALIDATIONS.OTHER) setArtworkFormatOther(true);
    else setArtworkFormatOther(false);
    //  Colours On Inside
    const coloursOnInside = coloursOnInsideList.find(entity => entity.value === coloursOnInsideId);
    if (coloursOnInside && coloursOnInside.label === VALIDATIONS.OTHER) setColoursOnInsideOther(true);
    else setColoursOnInsideOther(false);
    //  Colours On Outside
    const coloursOnOutside = coloursOnOutsideList.find(entity => entity.value === coloursOnOutsideId);
    if (coloursOnOutside && coloursOnOutside.label === VALIDATIONS.OTHER) setColoursOnOutsideOther(true);
    else setColoursOnOutsideOther(false);
    //  Colours On Text
    const coloursOnText = coloursOnTextList.find(entity => entity.value === coloursOnTextId);
    if (coloursOnText && coloursOnText.label === VALIDATIONS.OTHER) setColoursOnTextOther(true);
    else setColoursOnTextOther(false);
    //  Cover Material
    const coverMaterial = coverMaterialsList.find(entity => entity.value === coverMaterialId);
    if (coverMaterial && coverMaterial.label === VALIDATIONS.OTHER) setCoverMaterialOther(true);
    else setCoverMaterialOther(false);
    //  Cover Material Weight
    const coverMaterialWeight = coverMaterialWeightsList.find(entity => entity.value === coverMaterialWeightId);
    if (coverMaterialWeight && coverMaterialWeight.label === VALIDATIONS.OTHER) setCoverMaterialWeightOther(true);
    else setCoverMaterialWeightOther(false);
    //  Deliver Address change reason Other
    const deliveryAddressChangeReason = deliveryAddressChangeReasons.find(entity => entity.value === deliveryAddressChangeReasonId);
    if (deliveryAddressChangeReason && deliveryAddressChangeReason.label === VALIDATIONS.OTHER) setDeliveryAddressChangeReasonOther(true);
    else setDeliveryAddressChangeReasonOther(false);
    //  Finished Size Other
    const finishedSize = finishedSizesList.find(entity => entity.value === finishedSizeId);
    if (finishedSize && finishedSize.label === VALIDATIONS.OTHER) setFinishedSizeOther(true);
    else setFinishedSizeOther(false);
    //  Finishing Other
    const finishing = finishingsList.find(entity => entity.value === finishingId);
    if (finishing && finishing.label === VALIDATIONS.OTHER) setFinishingOther(true);
    else setFinishingOther(false);
    //  Material Other
    const material = materialsList.find(entity => entity.value === materialId);
    if (material && material.label === VALIDATIONS.OTHER) setMaterialOther(true);
    else setMaterialOther(false);
    //  Material Weight Other
    const materialWeight = materialWeightsList.find(entity => entity.value === materialWeightId);
    if (materialWeight && materialWeight.label === VALIDATIONS.OTHER) setMaterialWeightOther(true);
    else setMaterialWeightOther(false);
    //  No Fail Delivery Date
    if (noFailDeliveryDate) setIsNoFailDeliveryDateChecked(true);
    else setIsNoFailDeliveryDateChecked(false);
    //  Samples
    setSamples(samples === 'Yes' ? true : false);
    //  Type of Proofs Other
    const typeOfProof = typeOfProofsList.find(entity => entity.value === typeOfProofsId);
    if (typeOfProof && typeOfProof.label === VALIDATIONS.OTHER) setTypeOfProofsOther(true);
    else setTypeOfProofsOther(false);
    //  Proof Due Date
    if (typeOfProof && typeOfProof.label !== VALIDATIONS.PROOF_NOT_REQUIRED) setShowProofOfDueDate(true);
    else setShowProofOfDueDate(false);
  }, [
    artworkFormatsList,
    coloursOnInsideList,
    coloursOnOutsideList,
    coloursOnTextList,
    coverMaterialsList,
    coverMaterialWeightsList,
    deliveryAddressChangeReasons,
    finishedSizesList,
    finishingsList,
    initialState,
    materialWeightsList,
    materialsList,
    typeOfProofsList,
  ]);

  /*  Counters  */
  //  Deliver Address change reason
  const [deliveryAddressChangeReasonOtherLinesCounter, setDeliveryAddressChangeReasonOtherLinesCounter] = useState(0);
  const [deliveryAddressChangeReasonOtherCounter, setDeliveryAddressChangeReasonOtherCounter] = useState(0);
  //  Sample Copies Deliver Address
  const [sampleCopiesDeliveryAddressLinesCounter, setSampleCopiesDeliveryAddressLinesCounter] = useState(0);
  const [sampleCopiesDeliveryAddressCounter, setSampleCopiesDeliveryAddressCounter] = useState(0);
  //  Cover Material Other
  const [coverMaterialOtherLinesCounter, setCoverMaterialOtherLinesCounter] = useState(0);
  const [coverMaterialOtherCounter, setCoverMaterialOtherCounter] = useState(0);
  //  Cover Material Weight Other
  const [coverMaterialWeightOtherLinesCounter, setCoverMaterialWeightOtherLinesCounter] = useState(0);
  const [coverMaterialWeightOtherCounter, setCoverMaterialWeightOtherCounter] = useState(0);
  //  Colours On Outside Other
  const [coloursOnOutsideOtherLinesCounter, setColoursOnOutsideOtherLinesCounter] = useState(0);
  const [coloursOnOutsideOtherCounter, setColoursOutInsideOtherCounter] = useState(0);
  //  Colours On Inside Other
  const [coloursOnInsideOtherLinesCounter, setColoursOnInsideOtherLinesCounter] = useState(0);
  const [coloursOnInsideOtherCounter, setColoursOnInsideOtherCounter] = useState(0);
  //  Deliver Instructions
  const [deliveryInstructionsLinesCounter, setDeliveryInstructionsLinesCounter] = useState(0);
  const [deliveryInstructionsCounter, setDeliveryInstructionsCounter] = useState(0);
  //  Material Weight Other
  const [materialWeightOtherLinesCounter, setMaterialWeightOtherLinesCounter] = useState(0);
  const [materialWeightOtherCounter, setMaterialWeightOtherCounter] = useState(0);
  //  Colours on Text Other
  const [coloursOnTextOtherLinesCounter, setColoursOnTextOtherLinesCounter] = useState(0);
  const [coloursOnTextOtherCounter, setColoursOnTextOtherCounter] = useState(0);
  //  Artwork Format Other
  const [artworkFormatOtherLinesCounter, setArtworkFormatOtherLinesCounter] = useState(0);
  const [artworkFormatOtherCounter, setArtworkFormatOtherCounter] = useState(0);
  //  Type Of Proofs Other
  const [typeOfProofsOtherLinesCounter, setTypeOfProofsOtherLinesCounter] = useState(0);
  const [typeOfProofsOtherCounter, setTypeOfProofsOtherCounter] = useState(0);
  //  Finished Size Other
  const [finishedSizeOtherLinesCounter, setFinishedSizeOtherLinesCounter] = useState(0);
  const [finishedSizeOtherCounter, setFinishedSizeOtherCounter] = useState(0);
  //  Delivery Address
  const [deliveryAddressLinesCounter, setDeliveryAddressLinesCounter] = useState(0);
  const [deliveryAddressCounter, setDeliveryAddressCounter] = useState(0);
  //  Finishing Other
  const [finishingOtherLinesCounter, setFinishingOtherLinesCounter] = useState(0);
  const [finishingOtherCounter, setFinishingOtherCounter] = useState(0);
  //  Material Other
  const [materialOtherLinesCounter, setMaterialOtherLinesCounter] = useState(0);
  const [materialOtherCounter, setMaterialOtherCounter] = useState(0);
  //  Supplier Notes
  const [supplierNotesLinesCounter, setSupplierNotesLinesCounter] = useState(0);
  const [supplierNotesCounter, setSupplierNotesCounter] = useState(0);
  //  Client Notes
  const [clientNotesLinesCounter, setClientNotesLinesCounter] = useState(0);
  const [clientNotesCounter, setClientNotesCounter] = useState(0);
  //  Description
  const [descriptionLinesCounter, setDescriptionLinesCounter] = useState(0);
  const [descriptionCounter, setDescriptionCounter] = useState(0);
  //  CDS Notes
  const [cdsNotesLinesCounter, setCdsNotesLinesCounter] = useState(0);
  const [cdsNotesCounter, setCdsNotesCounter] = useState(0);
  //  Addendum
  const [addendumLinesCounter, setAddendumLinesCounter] = useState(0);
  const [addendumCounter, setAddendumCounter] = useState(0);
  //  Title
  const [titleCounter, setTitleCounter] = useState(0);

  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const {
      addendum,
      artworkFormatOther,
      cdsNotes,
      clientNotes,
      coloursOnInsideOther,
      coloursOnTextOther,
      coloursOnOutsideOther,
      coverMaterialOther,
      coverMaterialWeightOther,
      deliveryAddress,
      deliveryAddressChangeReasonOther,
      deliveryInstructions,
      description,
      finishingOther,
      finishedSizeOther,
      materialOther,
      materialWeightOther,
      sampleCopiesDeliveryAddress,
      supplierNotes,
      title,
      typeOfProofsOther,
    } = initialState;
    //  Addendum
    setAddendumLinesCounter(addendum?.length ? addendum.split('\n').length : 0);
    setAddendumCounter(addendum?.length ? addendum.length : 0);
    //  Artwork Format Other
    setArtworkFormatOtherLinesCounter(artworkFormatOther?.length ? artworkFormatOther.split('\n').length : 0);
    setArtworkFormatOtherCounter(artworkFormatOther?.length ? artworkFormatOther?.length : 0);
    //  CDS Notes
    setCdsNotesLinesCounter(cdsNotes?.length ? cdsNotes.split('\n').length : 0);
    setCdsNotesCounter(cdsNotes?.length ? cdsNotes.length : 0);
    //  Client Notes
    setClientNotesLinesCounter(clientNotes?.length ? clientNotes.split('\n').length : 0);
    setClientNotesCounter(clientNotes?.length ? clientNotes.length : 0);
    //  Colours On Inside Other
    setColoursOnInsideOtherLinesCounter(coloursOnInsideOther?.length ? coloursOnInsideOther.split('\n').length : 0);
    setColoursOnInsideOtherCounter(coloursOnInsideOther?.length ? coloursOnInsideOther.length : 0);
    //  Colours On Outside Other
    setColoursOnOutsideOtherLinesCounter(coloursOnOutsideOther?.length ? coloursOnOutsideOther.split('\n').length : 0);
    setColoursOutInsideOtherCounter(coloursOnOutsideOther?.length ? coloursOnOutsideOther.length : 0);
    //  Cover Material Other
    setCoverMaterialOtherLinesCounter(coverMaterialOther?.length ? coverMaterialOther.split('\n').length : 0);
    setCoverMaterialOtherCounter(coverMaterialOther?.length ? coverMaterialOther.length : 0);
    //  Colours on Text Other
    setColoursOnTextOtherLinesCounter(coloursOnTextOther?.length ? coloursOnTextOther.split('\n').length : 0);
    setColoursOnTextOtherCounter(coloursOnTextOther?.length ? coloursOnTextOther.length : 0);
    //  Cover Material Weight Other
    setCoverMaterialWeightOtherLinesCounter(coverMaterialWeightOther?.length ? coverMaterialWeightOther.split('\n').length : 0);
    setCoverMaterialWeightOtherCounter(coverMaterialWeightOther?.length ? coverMaterialWeightOther.length : 0);
    //  Delivery Address
    setDeliveryAddressLinesCounter(deliveryAddress?.length ? deliveryAddress.split('\n').length : 0);
    setDeliveryAddressCounter(deliveryAddress?.length ? deliveryAddress.length : 0);
    //  Deliver Address Change Reason
    setDeliveryAddressChangeReasonOtherLinesCounter(deliveryAddressChangeReasonOther?.length ? deliveryAddressChangeReasonOther.split('\n').length : 0);
    setDeliveryAddressChangeReasonOtherCounter(deliveryAddressChangeReasonOther?.length ? deliveryAddressChangeReasonOther.length : 0);
    //  Deliver Instructions
    setDeliveryInstructionsLinesCounter(deliveryInstructions?.length ? deliveryInstructions.split('\n').length : 0);
    setDeliveryInstructionsCounter(deliveryInstructions?.length ? deliveryInstructions.length : 0);
    //  Description
    setDescriptionLinesCounter(description?.length ? description.split('\n').length : 0);
    setDescriptionCounter(description?.length ? description.length : 0);
    //  Finished Size Other
    setFinishedSizeOtherLinesCounter(finishedSizeOther?.length ? finishedSizeOther.split('\n').length : 0);
    setFinishedSizeOtherCounter(finishedSizeOther?.length ? finishedSizeOther.length : 0);
    //  Finishing Other
    setFinishingOtherLinesCounter(finishingOther?.length ? finishingOther.split('\n').length : 0);
    setFinishingOtherCounter(finishingOther?.length ? finishingOther.length : 0);
    //  Material Other
    setMaterialOtherLinesCounter(materialOther?.length ? materialOther.split('\n').length : 0);
    setMaterialOtherCounter(materialOther?.length ? materialOther.length : 0);
    //  Material Weight Other
    setMaterialWeightOtherLinesCounter(materialWeightOther?.length ? materialWeightOther.split('\n').length : 0);
    setMaterialWeightOtherCounter(materialWeightOther?.length ? materialWeightOther.length : 0);
    //  Sample Copies Delivery Address
    setSampleCopiesDeliveryAddressLinesCounter(sampleCopiesDeliveryAddress?.length ? sampleCopiesDeliveryAddress.split('\n').length : 0);
    setSampleCopiesDeliveryAddressCounter(sampleCopiesDeliveryAddress?.length ? sampleCopiesDeliveryAddress.length : 0);
    //  Supplier Notes
    setSupplierNotesLinesCounter(supplierNotes?.length ? supplierNotes.split('\n').length : 0);
    setSupplierNotesCounter(supplierNotes?.length ? supplierNotes.length : 0);
    //  Title
    setTitleCounter(title?.length ? title.length : 0);
    //  Type Of Proofs Other
    setTypeOfProofsOtherLinesCounter(typeOfProofsOther?.length ? typeOfProofsOther.split('\n').length : 0);
    setTypeOfProofsOtherCounter(typeOfProofsOther?.length ? typeOfProofsOther?.length : 0);
  }, [initialState]);

  /******************************************************************************************
  @Purpose    :  Handle Form Submit
  *******************************************************************************************/
  const onSubmit = async values => {
    setLoading(true);
    const requestBody = {
      ...initialState,
      ...values,
      productId,
      rfqjobId: jobId,
      isActive: values.isActive === 'Yes' ? true : false,
      laminated: values.laminated === 'Yes' ? true : false,
      samples: values.samples === 'Yes' ? true : false,
      noOfSampleCopies: values.samples === 'Yes' ? values.noOfSampleCopies : null,
      sampleCopiesDeliveryAddress: values.samples === 'Yes' ? values.sampleCopiesDeliveryAddress : null,
      sealerOnCover: values.sealerOnCover === 'Yes' ? true : false,
      sealerOnFace: values.sealerOnFace === 'Yes' ? true : false,
      sealerOnReverse: values.sealerOnReverse === 'Yes' ? true : false,
      sealerOnText: values.sealerOnText === 'Yes' ? true : false,
      artworkDueDate: values.artworkDueDate && moment(new Date(values.artworkDueDate)).format('YYYY-MM-DD'),
      deliveryDueDate: values.deliveryDueDate && moment(new Date(values.deliveryDueDate)).format('YYYY-MM-DD'),
      proofDueDate: values.proofDueDate && moment(new Date(values.proofDueDate)).format('YYYY-MM-DD'),
      targetDate: values.targetDate && moment(new Date(values.targetDate)).format('YYYY-MM-DD'),
      jobItemOptions: values.jobItemOptions.map((jobItemOption: string | number) => +jobItemOption),
    };
    try {
      if (create) {
        //  Adding
        const response: any = await callApi({ url: API.ADD_PRODUCT_TEMPLATE, method: 'post', body: requestBody });
        dispatch(actions.loadRfqData({ jobId: jobId, toggleAction: toggleAction }));
        if (response.isSuccess) {
          dispatch(setPrintSpecifications({ module: MODULES.MY_JOBS, printSpecifications: { ...response.data, productType: BOOKLET } }));
        }
      } else {
        //  Updating
        const response: any = await callApi({ url: `${API.UPDATE_PRODUCT_TEMPLATE}/${initialState.templateId}`, method: 'put', body: requestBody });
        if (response.isSuccess) {
          dispatch(setPrintSpecifications({ module: MODULES.MY_JOBS, printSpecifications: { ...response.data, productType: BOOKLET } }));
        }
      }
      onHide();
      setLoading(false);
    } catch (responseError) {
      setLoading(false);
      setErrorModal(true);
      console.error('Error while submitting the form ' + responseError);
    }
  };

  /******************************************************************************************
  @Purpose    :  Handle Drop down select for other fields show / hide
  *******************************************************************************************/
  const handleSelect = (name: string, val: DropDown | null) => {
    //  Artwork Format
    if (name === 'artworkFormatId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setArtworkFormatOther(true);
      } else {
        setValue('artworkFormatOther', null, {
          shouldValidate: false,
        });
        setArtworkFormatOther(false);
      }
    }
    //  Colours On Text
    if (name === 'coloursOnTextId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setColoursOnTextOther(true);
      } else {
        setValue('coloursOnTextOther', null, {
          shouldValidate: false,
        });
        setColoursOnTextOther(false);
      }
    }
    //  Colours On Inside
    if (name === 'coloursOnInsideId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setColoursOnInsideOther(true);
      } else {
        setValue('coloursOnInsideOther', null, {
          shouldValidate: false,
        });
        setColoursOnInsideOther(false);
      }
    }
    //  Colours On Outside
    if (name === 'coloursOnOutsideId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setColoursOnOutsideOther(true);
      } else {
        setValue('coloursOnOutsideOther', null, {
          shouldValidate: false,
        });
        setColoursOnOutsideOther(false);
      }
    }
    //  Finished sizes
    if (name === 'finishedSizeId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setFinishedSizeOther(true);
      } else {
        setValue('finishedSizeOther', null, {
          shouldValidate: false,
        });
        setFinishedSizeOther(false);
      }
    }
    //  Finishing
    if (name === 'finishingId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setFinishingOther(true);
      } else {
        setValue('finishingOther', null, {
          shouldValidate: false,
        });
        setFinishingOther(false);
      }
    }
    //  Material
    if (name === 'materialId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setMaterialOther(true);
      } else {
        setValue('materialOther', null, {
          shouldValidate: false,
        });
        setMaterialOther(false);
      }
    }
    //  Material Weight
    if (name === 'materialWeightId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setMaterialWeightOther(true);
      } else {
        setValue('materialWeightOther', null, {
          shouldValidate: false,
        });
        setMaterialWeightOther(false);
      }
    }
    // Cover Material
    if (name === 'coverMaterialId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setCoverMaterialOther(true);
      } else {
        setValue('coverMaterialOther', null, {
          shouldValidate: false,
        });
        setCoverMaterialOther(false);
      }
    }
    // Cover Material Weight
    if (name === 'coverMaterialWeightId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setCoverMaterialWeightOther(true);
      } else {
        setValue('coverMaterialWeightOther', null, {
          shouldValidate: false,
        });
        setCoverMaterialWeightOther(false);
      }
    }
    //  Delivery Address Change Reason
    if (name === 'deliveryAddressChangeReasonId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setDeliveryAddressChangeReasonOther(true);
      } else {
        setValue('deliveryAddressChangeReasonOther', null, {
          shouldValidate: false,
        });
        setDeliveryAddressChangeReasonOther(false);
      }
    }
    //  Type Of Proofs
    if (name === 'typeOfProofsId') {
      //  Check option other is selected or not
      if (val && val.label === VALIDATIONS.OTHER) {
        setTypeOfProofsOther(true);
      } else {
        setValue('typeOfProofsOther', null, {
          shouldValidate: false,
        });
        setTypeOfProofsOther(false);
      }
      //  Check option Proof not required is selected or not
      if (val && val.label !== VALIDATIONS.PROOF_NOT_REQUIRED) setShowProofOfDueDate(true);
      else setShowProofOfDueDate(false);
    }
  };

  /******************************************************************************************
  @Purpose    :  Handle Samples
  *******************************************************************************************/
  const handleSamples = (value: boolean) => {
    setSamples(value);
    setValue('samples', value ? 'Yes' : 'No', { shouldValidate: false });
  };

  /******************************************************************************************
  @Purpose    :  Handle Category
  *******************************************************************************************/
  const handleCategory = (value: number | undefined) => {
    if (value) {
      //  Filter the Second Category list
      setSecondaryCategoriesList(secondaryCategories.filter(secondaryCategory => secondaryCategory.primaryCategoryId === value));
    } else setSecondaryCategoriesList([]);
    setValue('secondaryCategoryId', null, {
      shouldValidate: false,
    });
  };

  /******************************************************************************************
  @Purpose    :  Handle Job options change
  *******************************************************************************************/
  const handleJobItemChange = entity => {
    const updatedJobItemOptions = jobItemOptionsList.map(jobItemOption => {
      if (jobItemOption.value === entity.value) {
        return { ...jobItemOption, checked: !entity.checked };
      }
      return jobItemOption;
    });
    setJobItemOptionsList(updatedJobItemOptions);
    //  Show / Hide Target Date
    if (entity.label.trim() === 'Complex job') {
      setTargetDate(!entity.checked);
      setValue('targetDate', null, { shouldValidate: false });
    }
  };

  /******************************************************************************************
  @Purpose    :  Get Minimum Date
  *******************************************************************************************/
  const getMinimumDate = (value: string | number | Date | null): Date => {
    if (value && new Date() > new Date(value)) return new Date(value);
    return new Date();
  };

  return (
    <>
      {loading && <LoadingSpinnerWithBackdrop color="grey" />}
      <Form className="printSpecifications__form" id={formId} onSubmit={handleSubmit(onSubmit, onError)}>
        <Form.Group controlId="form__title" style={{ gridColumn: '1 / 3' }}>
          <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              Line Title<span className="input__required">*</span>
            </span>
            {!disabled && <span>{titleCounter}/100</span>}
          </Form.Label>
          <Form.Control
            autoComplete="off"
            className={errors.title && 'border-danger'}
            disabled={disabled}
            maxLength={100}
            placeholder={PLACEHOLDER.LINE_TITLE}
            type="text"
            {...register('title')}
            onChange={e => setTitleCounter(e.target.value.length)}
          />
          {errors.title && <Form.Text className="text-danger">{errors.title.message}</Form.Text>}
        </Form.Group>
        <Form.Group controlId="form__description" style={{ gridColumn: '1 / 3' }}>
          <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Description</span>
            {!disabled && <span>{descriptionCounter}/1000</span>}
          </Form.Label>
          <Form.Control
            as="textarea"
            autoComplete="off"
            className={errors.description && 'border-danger'}
            disabled={disabled}
            maxLength={1000}
            placeholder={PLACEHOLDER.DESCRIPTION}
            rows={disabled ? getRowsOfTextArea(descriptionCounter, descriptionLinesCounter) : 3}
            type="text"
            {...register('description')}
            onChange={event => setDescriptionCounter(event.target.value.length)}
          />
          {errors.description && <Form.Text className="text-danger">{errors.description.message}</Form.Text>}
        </Form.Group>
        {disabled && !isSupplier && (
          <>
            <Form.Group controlId="form__isActive" style={{ gridColumn: '1' }}>
              <Form.Label>Active</Form.Label>
              <div>
                <Form.Check aria-label="isActive-yes" disabled id="form__isActive-yes" inline label="Yes" type="radio" value="Yes" {...register('isActive')} />
                <Form.Check aria-label="isActive-no" disabled id="form__isActive-no" inline label="No" type="radio" value="No" {...register('isActive')} />
              </div>
            </Form.Group>
            <Form.Group controlId="form__status" style={{ gridColumn: '1 / 2' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>Status</Form.Label>
              <Form.Control autoComplete="off" className={errors.status && 'border-danger'} disabled placeholder={PLACEHOLDER.STATUS} type="text" {...register('status.name')} />
            </Form.Group>
            {isCDS && (
              <Form.Group controlId="form__managementFee" style={{ gridColumn: '2 / 2' }}>
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>Management Fee %</Form.Label>
                <Form.Control autoComplete="off" className={errors.managementFee && 'border-danger'} disabled placeholder={PLACEHOLDER.MANAGEMENTFEE} type="text" {...register('managementFee')} />
              </Form.Group>
            )}
            <LineDetails initialState={initialState} />
          </>
        )}
        <Form.Group controlId="form__reference" style={{ gridColumn: '1 / 2' }}>
          <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>Reference</Form.Label>
          <Form.Control autoComplete="off" className={errors.reference && 'border-danger'} disabled={disabled} placeholder={PLACEHOLDER.REFERENCE} type="text" {...register('reference')} />
          {errors.reference && <Form.Text className="text-danger">{errors.reference.message}</Form.Text>}
        </Form.Group>
        {initialState && initialState.dueDate && initialState.dueTime && (
          <>
            <h6 style={{ gridColumn: '1/4', marginTop: '10px' }}>Quote Due Date & Time</h6>
            <p style={{ marginBottom: '10px', marginTop: '-10px' }}>
              {initialState && initialState.dueDate && initialState.dueTime ? `${moment(initialState.dueDate).format('DD/MM/YYYY')}, ${initialState.dueTime.split(':').splice(0, 2).join(':')}` : '-'}
            </p>
          </>
        )}
        {initialState.relatedLineId && (
          <>
            <Form.Group controlId="form__relatedLineId" style={{ gridColumn: '1' }}>
              <Form.Label>Related Line Number</Form.Label>
              <Form.Control autoComplete="off" className={errors.relatedLineId && 'border-danger'} disabled type="text" {...register('relatedLineId')} />
            </Form.Group>
            <Form.Group controlId="form__relatedLineValue">
              <Form.Label>Related Line Value (£)</Form.Label>
              <Form.Control autoComplete="off" className={errors.relatedLineValue && 'border-danger'} disabled type="number" {...register('relatedLineValue')} />
            </Form.Group>
          </>
        )}
        {clientQuotations && <ClientQuotation clientQuotations={clientQuotations} initialState={initialState} />}
        <Form.Group controlId="form__quantity" style={{ gridColumn: '1' }}>
          <Form.Label>
            Quantity<span className="input__required">*</span>
          </Form.Label>
          <Form.Control
            autoComplete="off"
            className={errors.quantity && 'border-danger'}
            disabled={disabled}
            min={0}
            onKeyDown={e => allowNumbers(e)}
            placeholder={PLACEHOLDER.QUANTITY}
            type="number"
            {...register('quantity', {
              valueAsNumber: true,
            })}
          />
          {errors.quantity && <Form.Text className="text-danger">{errors.quantity.message}</Form.Text>}
        </Form.Group>
        {!disabled || initialState.quantityRunOn ? (
          <Form.Group controlId="form__quantityRunOn">
            <Form.Label>Quantity Run-On</Form.Label>
            <Form.Control
              autoComplete="off"
              className={errors.quantityRunOn && 'border-danger'}
              disabled={disabled}
              min={0}
              onKeyDown={e => allowNumbers(e)}
              placeholder={PLACEHOLDER.QUANTITY_RUN_ON}
              type="number"
              {...register('quantityRunOn')}
            />
            {errors.quantityRunOn && <Form.Text className="text-danger">{errors.quantityRunOn.message}</Form.Text>}
          </Form.Group>
        ) : (
          <></>
        )}
        {initialState.quantityRunOn && initialState.quantityActual && (isSupplier ? ![STATUS.DRAFT, STATUS.NEW_RFQ, STATUS.QUOTATION, STATUS.QUOTED].includes(initialState.status.code) : true) ? (
          <Form.Group controlId="form__quantityActual">
            <Form.Label>Quantity Actual</Form.Label>
            <Form.Control
              autoComplete="off"
              className={errors.quantityActual && 'border-danger'}
              disabled
              placeholder={PLACEHOLDER.QUANTITY_ACTUAL}
              type="number"
              {...register('quantityActual', {
                valueAsNumber: true,
              })}
            />
            {errors.quantityActual && <Form.Text className="text-danger">{errors.quantityActual.message}</Form.Text>}
          </Form.Group>
        ) : (
          <></>
        )}
        <Form.Group controlId="form__finishedSizeId" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>
            Finished Size<span className="input__required">*</span>
          </Form.Label>
          <Controller
            control={control}
            name="finishedSizeId"
            render={({ field: { name, onBlur, onChange, ref, value } }) => {
              return (
                <Select
                  className={errors.finishedSizeId && 'border-danger-select'}
                  classNamePrefix="custom-select-input"
                  id="form__finishedSizeId"
                  isClearable
                  isDisabled={disabled}
                  maxMenuHeight={175}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={(val, { action }) => {
                    if (action === 'clear') {
                      onChange(null);
                    } else {
                      onChange(val?.value);
                    }
                    handleSelect(name, val);
                  }} // Send value to hook f = items.find(item => item.value = val);
                  options={finishedSizesList}
                  placeholder={PLACEHOLDER.SELECT_FINISHED_SIZE}
                  ref={ref}
                  value={finishedSizesList.find(finishedSize => finishedSize.value === value)}
                />
              );
            }}
          />
          {errors.finishedSizeId && <Form.Text className="text-danger">{errors.finishedSizeId.message}</Form.Text>}
        </Form.Group>
        {finishedSizeOther && (
          <Form.Group controlId="form__finishedSizeOther" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Other Finished Size<span className="input__required">*</span>
              </span>
              {!disabled && <span>{finishedSizeOtherCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.finishedSizeOther && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.FINISHED_SIZE_OTHER}
              rows={disabled ? getRowsOfTextArea(finishedSizeOtherCounter, finishedSizeOtherLinesCounter) : 3}
              type="text"
              {...register('finishedSizeOther')}
              onChange={event => setFinishedSizeOtherCounter(event.target.value.length)}
            />
            {errors.finishedSizeOther && <Form.Text className="text-danger">{errors.finishedSizeOther.message}</Form.Text>}
          </Form.Group>
        )}
        <Form.Group controlId="form__pagesForText" style={{ gridColumn: '1' }}>
          <Form.Label>
            Pages For Text<span className="input__required">*</span>
          </Form.Label>
          <Form.Control
            autoComplete="off"
            className={errors.pagesForText && 'border-danger'}
            disabled={disabled}
            min={0}
            onKeyDown={e => allowNumbers(e)}
            placeholder={PLACEHOLDER.PAGES_FOR_TEXT}
            type="number"
            {...register('pagesForText', {
              valueAsNumber: true,
            })}
          />
          {errors.pagesForText && <Form.Text className="text-danger">{errors.pagesForText.message}</Form.Text>}
        </Form.Group>
        <Form.Group controlId="form__coloursOnTextId" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>Colours On Text</Form.Label>
          <Controller
            control={control}
            name="coloursOnTextId"
            render={({ field: { name, onBlur, onChange, ref, value } }) => {
              return (
                <Select
                  className={errors.coloursOnTextId && 'border-danger-select'}
                  classNamePrefix="custom-select-input"
                  id="form__coloursOnTextId"
                  isClearable
                  isDisabled={disabled}
                  maxMenuHeight={175}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={(val, { action }) => {
                    if (action === 'clear') {
                      onChange(null);
                    } else {
                      onChange(val?.value);
                    }
                    handleSelect(name, val);
                  }} // Send value to hook f = items.find(item => item.value = val);
                  options={coloursOnTextList}
                  placeholder={PLACEHOLDER.SELECT_COLOURS_ON_TEXT}
                  ref={ref}
                  value={coloursOnTextList.find(coloursOnText => coloursOnText.value === value)}
                />
              );
            }}
          />
          {errors.coloursOnTextId && <Form.Text className="text-danger">{errors.coloursOnTextId.message}</Form.Text>}
        </Form.Group>
        {coloursOnTextOther && (
          <Form.Group controlId="form__coloursOnTextOther" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Other Colours On Text
                <span className="input__required">*</span>
              </span>
              {!disabled && <span>{coloursOnTextOtherCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.coloursOnTextOther && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.COLOURS_ON_TEXT_OTHER}
              rows={disabled ? getRowsOfTextArea(coloursOnTextOtherCounter, coloursOnTextOtherLinesCounter) : 3}
              type="text"
              {...register('coloursOnTextOther')}
              onChange={event => setColoursOnTextOtherCounter(event.target.value.length)}
            />
            {errors.coloursOnTextOther && <Form.Text className="text-danger">{errors.coloursOnTextOther.message}</Form.Text>}
          </Form.Group>
        )}

        <Form.Group controlId="form__sealerOnText" style={{ gridColumn: '1' }}>
          <Form.Label>
            Sealer On Text<span className="input__required">*</span>
          </Form.Label>
          <div>
            <Form.Check aria-label="sealerOnText__radio-yes" disabled={disabled} id="form__sealerOnText-yes" inline label="Yes" type="radio" value="Yes" {...register('sealerOnText')} />
            <Form.Check aria-label="sealerOnText__radio-no" disabled={disabled} id="form__sealerOnText-no" inline label="No" type="radio" value="No" {...register('sealerOnText')} />
          </div>
          {errors.sealerOnText && <Form.Text className="text-danger">{errors.sealerOnText.message}</Form.Text>}
        </Form.Group>

        <Form.Group controlId="form__materialId" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>
            Material<span className="input__required">*</span>
          </Form.Label>
          <Controller
            control={control}
            name="materialId"
            render={({ field: { name, onBlur, onChange, ref, value } }) => {
              return (
                <Select
                  className={errors.materialId && 'border-danger-select'}
                  classNamePrefix="custom-select-input"
                  id="form__materialId"
                  isClearable
                  isDisabled={disabled}
                  maxMenuHeight={175}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={(val, { action }) => {
                    if (action === 'clear') {
                      onChange(null);
                    } else {
                      onChange(val?.value);
                    }
                    handleSelect(name, val);
                  }} // Send value to hook f = items.find(item => item.value = val);
                  options={materialsList}
                  placeholder={PLACEHOLDER.SELECT_MATERIAL}
                  ref={ref}
                  value={materialsList.find(material => material.value === value)}
                />
              );
            }}
          />
          {errors.materialId && <Form.Text className="text-danger">{errors.materialId.message}</Form.Text>}
        </Form.Group>
        {materialOther && (
          <Form.Group controlId="form__materialOther" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Other Material<span className="input__required">*</span>
              </span>
              {!disabled && <span>{materialOtherCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.materialOther && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.MATERIAL_OTHER}
              rows={disabled ? getRowsOfTextArea(materialOtherCounter, materialOtherLinesCounter) : 3}
              type="text"
              {...register('materialOther')}
              onChange={event => setMaterialOtherCounter(event.target.value.length)}
            />
            {errors.materialOther && <Form.Text className="text-danger">{errors.materialOther.message}</Form.Text>}
          </Form.Group>
        )}
        <Form.Group controlId="form__materialWeightId" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>
            Material Weight<span className="input__required">*</span>
          </Form.Label>
          <Controller
            control={control}
            name="materialWeightId"
            render={({ field: { name, onBlur, onChange, ref, value } }) => {
              return (
                <Select
                  className={errors.materialWeightId && 'border-danger-select'}
                  classNamePrefix="custom-select-input"
                  id="form__materialWeightId"
                  isClearable
                  isDisabled={disabled}
                  maxMenuHeight={175}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={(val, { action }) => {
                    if (action === 'clear') {
                      onChange(null);
                    } else {
                      onChange(val?.value);
                    }
                    handleSelect(name, val);
                  }} // Send value to hook f = items.find(item => item.value = val);
                  options={materialWeightsList}
                  placeholder={PLACEHOLDER.SELECT_MATERIAL_WEIGHT}
                  ref={ref}
                  value={materialWeightsList.find(materialWeight => materialWeight.value === value)}
                />
              );
            }}
          />
          {errors.materialWeightId && <Form.Text className="text-danger">{errors.materialWeightId.message}</Form.Text>}
        </Form.Group>
        {materialWeightOther && (
          <Form.Group controlId="form__materialWeightOther" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Other Material Weight
                <span className="input__required">*</span>
              </span>
              {!disabled && <span>{materialWeightOtherCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.materialWeightOther && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.MATERIAL_WEIGHT_OTHER}
              rows={disabled ? getRowsOfTextArea(materialWeightOtherCounter, materialWeightOtherLinesCounter) : 3}
              type="text"
              {...register('materialWeightOther')}
              onChange={event => setMaterialWeightOtherCounter(event.target.value.length)}
            />
            {errors.materialWeightOther && <Form.Text className="text-danger">{errors.materialWeightOther.message}</Form.Text>}
          </Form.Group>
        )}
        <Form.Group controlId="form__pagesForCover" style={{ gridColumn: '1' }}>
          <Form.Label>Pages For Cover</Form.Label>
          <Form.Control
            autoComplete="off"
            className={errors.pagesForCover && 'border-danger'}
            disabled={disabled}
            min={0}
            onKeyDown={e => allowNumbers(e)}
            placeholder={PLACEHOLDER.PAGES_FOR_COVER}
            type="number"
            {...register('pagesForCover', {
              valueAsNumber: true,
            })}
          />
          {errors.pagesForCover && <Form.Text className="text-danger">{errors.pagesForCover.message}</Form.Text>}
        </Form.Group>
        <Form.Group controlId="form__coloursOnInsideId" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>Colours On Inside</Form.Label>
          <Controller
            control={control}
            name="coloursOnInsideId"
            render={({ field: { name, onBlur, onChange, ref, value } }) => {
              return (
                <Select
                  className={errors.coloursOnInsideId && 'border-danger-select'}
                  classNamePrefix="custom-select-input"
                  id="form__coloursOnInsideId"
                  isClearable
                  isDisabled={disabled}
                  maxMenuHeight={175}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={(val, { action }) => {
                    if (action === 'clear') {
                      onChange(null);
                    } else {
                      onChange(val?.value);
                    }
                    handleSelect(name, val);
                  }} // Send value to hook f = items.find(item => item.value = val);
                  options={coloursOnInsideList}
                  placeholder={PLACEHOLDER.SELECT_COLOURS_ON_INSIDE}
                  ref={ref}
                  value={coloursOnInsideList?.find(coloursOnInside => coloursOnInside.value === value)}
                />
              );
            }}
          />
          {errors.coloursOnInsideId && <Form.Text className="text-danger">{errors.coloursOnInsideId.message}</Form.Text>}
        </Form.Group>
        {coloursOnInsideOther && (
          <Form.Group controlId="form__coloursOnInsideOther" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Other Colours On Inside
                <span className="input__required">*</span>
              </span>
              {!disabled && <span>{coloursOnInsideOtherCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.coloursOnInsideOther && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.COLOURS_ON_INSIDE_OTHER}
              rows={disabled ? getRowsOfTextArea(coloursOnInsideOtherCounter, coloursOnInsideOtherLinesCounter) : 3}
              type="text"
              {...register('coloursOnInsideOther')}
              onChange={event => setColoursOnInsideOtherCounter(event.target.value.length)}
            />
            {errors.coloursOnInsideOther && <Form.Text className="text-danger">{errors.coloursOnInsideOther.message}</Form.Text>}
          </Form.Group>
        )}
        <Form.Group controlId="form__coloursOnOutsideId" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>Colours On Outside</Form.Label>
          <Controller
            control={control}
            name="coloursOnOutsideId"
            render={({ field: { name, onBlur, onChange, ref, value } }) => {
              return (
                <Select
                  className={errors.coloursOnOutsideId && 'border-danger-select'}
                  classNamePrefix="custom-select-input"
                  id="form__coloursOnOutsideId"
                  isClearable
                  isDisabled={disabled}
                  maxMenuHeight={175}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={(val, { action }) => {
                    if (action === 'clear') {
                      onChange(null);
                    } else {
                      onChange(val?.value);
                    }
                    handleSelect(name, val);
                  }} // Send value to hook f = items.find(item => item.value = val);
                  options={coloursOnOutsideList}
                  placeholder={PLACEHOLDER.SELECT_COLOURS_ON_OUTSIDE}
                  ref={ref}
                  value={coloursOnOutsideList.find(coloursOnOutside => coloursOnOutside.value === value)}
                />
              );
            }}
          />
          {errors.coloursOnOutsideId && <Form.Text className="text-danger">{errors.coloursOnOutsideId.message}</Form.Text>}
        </Form.Group>
        {coloursOnOutsideOther && (
          <Form.Group controlId="form__coloursOnOutsideOther" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Other Colours on Outside<span className="input__required">*</span>
              </span>
              {!disabled && <span>{coloursOnOutsideOtherCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.coloursOnOutsideOther && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.COLOURS_ON_OUTSIDE_OTHER}
              rows={disabled ? getRowsOfTextArea(coloursOnOutsideOtherCounter, coloursOnOutsideOtherLinesCounter) : 3}
              type="text"
              {...register('coloursOnOutsideOther')}
              onChange={event => setColoursOutInsideOtherCounter(event.target.value.length)}
            />
            {errors.coloursOnOutsideOther && <Form.Text className="text-danger">{errors.coloursOnOutsideOther.message}</Form.Text>}
          </Form.Group>
        )}
        <Form.Group controlId="form__sealerOnCover" style={{ gridColumn: '1' }}>
          <Form.Label>
            Sealer On Cover<span className="input__required">*</span>
          </Form.Label>
          <div>
            <Form.Check aria-label="sealerOnCover__radio-yes" disabled={disabled} id="form__sealerOnCover-yes" inline label="Yes" type="radio" value="Yes" {...register('sealerOnCover')} />
            <Form.Check aria-label="sealerOnCover__radio-no" disabled={disabled} id="form__sealerOnCover-no" inline label="No" type="radio" value="No" {...register('sealerOnCover')} />
          </div>
          {errors.sealerOnCover && <Form.Text className="text-danger">{errors.sealerOnCover.message}</Form.Text>}
        </Form.Group>
        <Form.Group controlId="form__coverMaterialId" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>Cover Material</Form.Label>
          <Controller
            control={control}
            name="coverMaterialId"
            render={({ field: { name, onBlur, onChange, ref, value } }) => {
              return (
                <Select
                  className={errors.coverMaterialId && 'border-danger-select'}
                  classNamePrefix="custom-select-input"
                  id="form__coverMaterialId"
                  isClearable
                  isDisabled={disabled}
                  maxMenuHeight={175}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={(val, { action }) => {
                    if (action === 'clear') {
                      onChange(null);
                    } else {
                      onChange(val?.value);
                    }
                    handleSelect(name, val);
                  }} // Send value to hook f = items.find(item => item.value = val);
                  options={coverMaterialsList}
                  placeholder={PLACEHOLDER.SELECT_COVER_MATERIAL}
                  ref={ref}
                  value={coverMaterialsList.find(coverMaterial => coverMaterial.value === value)}
                />
              );
            }}
          />
          {errors.coverMaterialId && <Form.Text className="text-danger">{errors.coverMaterialId.message}</Form.Text>}
        </Form.Group>
        {coverMaterialOther && (
          <Form.Group controlId="form__coverMaterialOther" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Other Cover Material<span className="input__required">*</span>
              </span>
              {!disabled && <span>{coverMaterialOtherCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.coverMaterialOther && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.COVER_MATERIAL_OTHER}
              rows={disabled ? getRowsOfTextArea(coverMaterialOtherCounter, coverMaterialOtherLinesCounter) : 3}
              type="text"
              {...register('coverMaterialOther')}
              onChange={event => setMaterialOtherCounter(event.target.value.length)}
            />
            {errors.coverMaterialOther && <Form.Text className="text-danger">{errors.coverMaterialOther.message}</Form.Text>}
          </Form.Group>
        )}
        <Form.Group controlId="form__coverMaterialWeightId" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>Cover Material Weight</Form.Label>
          <Controller
            control={control}
            name="coverMaterialWeightId"
            render={({ field: { name, onBlur, onChange, ref, value } }) => {
              return (
                <Select
                  className={errors.coverMaterialWeightId && 'border-danger-select'}
                  classNamePrefix="custom-select-input"
                  id="form__coverMaterialWeightId"
                  isClearable
                  isDisabled={disabled}
                  maxMenuHeight={175}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={(val, { action }) => {
                    if (action === 'clear') {
                      onChange(null);
                    } else {
                      onChange(val?.value);
                    }
                    handleSelect(name, val);
                  }} // Send value to hook f = items.find(item => item.value = val);
                  options={coverMaterialWeightsList}
                  placeholder={PLACEHOLDER.SELECT_COVER_MATERIAL_WEIGHT}
                  ref={ref}
                  value={coverMaterialWeightsList.find(coverMaterialWeight => coverMaterialWeight.value === value)}
                />
              );
            }}
          />
          {errors.coverMaterialWeightId && <Form.Text className="text-danger">{errors.coverMaterialWeightId.message}</Form.Text>}
        </Form.Group>
        {coverMaterialWeightOther && (
          <Form.Group controlId="form__coverMaterialWeightOther" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Other Cover Material Weight
                <span className="input__required">*</span>
              </span>
              {!disabled && <span>{coverMaterialWeightOtherCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.coverMaterialWeightOther && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.COVER_MATERIAL_WEIGHT_OTHER}
              rows={disabled ? getRowsOfTextArea(coverMaterialWeightOtherCounter, coverMaterialWeightOtherLinesCounter) : 3}
              type="text"
              {...register('coverMaterialWeightOther')}
              onChange={event => setCoverMaterialWeightOtherCounter(event.target.value.length)}
            />
            {errors.coverMaterialWeightOther && <Form.Text className="text-danger">{errors.coverMaterialWeightOther.message}</Form.Text>}
          </Form.Group>
        )}
        <Form.Group controlId="form__finishingId" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>
            Finishing<span className="input__required">*</span>
          </Form.Label>
          <Controller
            control={control}
            name="finishingId"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <Select
                className={errors.finishingId && 'border-danger-select'}
                classNamePrefix="custom-select-input"
                id="form__finishingId"
                isClearable
                isDisabled={disabled}
                maxMenuHeight={175}
                onBlur={onBlur} //  Notify when input is touched
                onChange={(val, { action }) => {
                  if (action === 'clear') {
                    onChange(null);
                  } else {
                    onChange(val?.value);
                  }
                  handleSelect(name, val);
                }} // Send value to hook f = items.find(item => item.value = val);
                options={finishingsList}
                placeholder={PLACEHOLDER.SELECT_FINISHING}
                ref={ref}
                value={finishingsList.find(finishing => finishing.value === value)}
              />
            )}
          />
          {errors.finishingId && <Form.Text className="text-danger">{errors.finishingId.message}</Form.Text>}
        </Form.Group>
        {finishingOther && (
          <Form.Group controlId="form__finishingOther" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Other Finishing<span className="input__required">*</span>
              </span>
              {!disabled && <span>{finishingOtherCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.finishingOther && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.FINISHING_OTHER}
              rows={disabled ? getRowsOfTextArea(finishingOtherCounter, finishingOtherLinesCounter) : 3}
              type="text"
              {...register('finishingOther')}
              onChange={event => setFinishingOtherCounter(event.target.value.length)}
            />
            {errors.finishingOther && <Form.Text className="text-danger">{errors.finishingOther.message}</Form.Text>}
          </Form.Group>
        )}
        <Form.Group controlId="form__addendum" style={{ gridColumn: '1/3' }}>
          <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Addendum</span>
            {!disabled && <span>{addendumCounter}/5000</span>}
          </Form.Label>
          <Form.Control
            as="textarea"
            autoComplete="off"
            className={errors.addendum && 'border-danger'}
            disabled={disabled}
            maxLength={5000}
            placeholder={PLACEHOLDER.ADDENDUM}
            rows={disabled ? getRowsOfTextArea(addendumCounter, addendumLinesCounter) : 3}
            type="text"
            {...register('addendum')}
            onChange={event => setAddendumCounter(event.target.value.length)}
          />
          {errors.addendum && <Form.Text className="text-danger">{errors.addendum.message}</Form.Text>}
        </Form.Group>
        <h6 style={{ gridColumn: '1/4', marginTop: '30px' }}>Delivery Information</h6>
        <Form.Group controlId="form__deliveryContact" style={{ gridColumn: '1 / 3' }}>
          <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Delivery Contact</span>
          </Form.Label>
          <Form.Control
            autoComplete="off"
            className={errors.deliveryContact && 'border-danger'}
            disabled={disabled}
            placeholder={PLACEHOLDER.DELIVERY_CONTACT}
            type="text"
            {...register('deliveryContact')}
          />
          {errors.deliveryContact && <Form.Text className="text-danger">{errors.deliveryContact.message}</Form.Text>}
        </Form.Group>
        <Form.Group controlId="form__deliveryAddress" style={{ gridColumn: '1/3' }}>
          <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Delivery Address</span>
            {!disabled && <span>{deliveryAddressCounter}/1000</span>}
          </Form.Label>
          <Form.Control
            as="textarea"
            autoComplete="off"
            className={errors.deliveryAddress && 'border-danger'}
            disabled={disabled}
            maxLength={1000}
            placeholder={PLACEHOLDER.DELIVERY_ADDRESS}
            rows={disabled ? getRowsOfTextArea(deliveryAddressCounter, deliveryAddressLinesCounter) : 3}
            type="text"
            {...register('deliveryAddress')}
            onChange={event => setDeliveryAddressCounter(event.target.value.length)}
          />
          {errors.deliveryAddress && <Form.Text className="text-danger">{errors.deliveryAddress.message}</Form.Text>}
        </Form.Group>
        <Form.Group controlId="form__deliveryInstructions" style={{ gridColumn: '1/3' }}>
          <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Delivery Instructions</span>
            {!disabled && <span>{deliveryInstructionsCounter}/1000</span>}
          </Form.Label>
          <Form.Control
            as="textarea"
            autoComplete="off"
            className={errors.deliveryInstructions && 'border-danger'}
            disabled={disabled}
            maxLength={1000}
            placeholder={PLACEHOLDER.DELIVERY_INSTRUCTIONS}
            rows={disabled ? getRowsOfTextArea(deliveryInstructionsCounter, deliveryInstructionsLinesCounter) : 3}
            type="text"
            {...register('deliveryInstructions')}
            onChange={event => setDeliveryInstructionsCounter(event.target.value.length)}
          />
          {errors.deliveryInstructions && <Form.Text className="text-danger">{errors.deliveryInstructions.message}</Form.Text>}
        </Form.Group>
        <Form.Group controlId="form__deliveryDueDate" style={{ gridColumn: '1' }}>
          <Form.Label>Delivery Due Date</Form.Label>
          <Controller
            control={control}
            name="deliveryDueDate"
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className={`form-control picker__container ${disabled ? 'picker__container__disabled' : ''} ${errors.deliveryDueDate ? 'border-danger' : ''}`}>
                <DatePicker
                  className="picker__input"
                  disabled={disabled}
                  dateFormat="dd/MM/yyyy"
                  minDate={getMinimumDate(value)}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={onChange}
                  placeholderText={PLACEHOLDER.SELECT_DELIVERY_DUE_DATE}
                  ref={ref}
                  selected={value ? new Date(value) : null}
                  shouldCloseOnSelect={true}
                  showYearDropdown
                  strictParsing
                />
                <CalendarIcon />
              </div>
            )}
          />
          {errors.deliveryDueDate && <Form.Text className="text-danger">{errors.deliveryDueDate.message}</Form.Text>}
        </Form.Group>
        <Form.Group controlId="form__noFailDeliveryDate" style={{ gridColumn: '1' }}>
          <Controller
            control={control}
            name="noFailDeliveryDate"
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <Form.Check
                disabled={disabled}
                onBlur={onBlur}
                onChange={e => {
                  onChange(e);
                  setIsNoFailDeliveryDateChecked(!isNoFailDeliveryDateChecked);
                }}
                label="No Fail Deliver Date"
                ref={ref}
                type="checkbox"
                checked={isNoFailDeliveryDateChecked}
              />
            )}
          />
          {errors.noFailDeliveryDate && <Form.Text className="text-danger">{errors.noFailDeliveryDate.message}</Form.Text>}
        </Form.Group>
        {initialState.deliveryAddressChangeReasonId && (
          <>
            <Form.Label style={{ gridColumn: '1/3' }}>
              Reason for Changing the Delivery Address<span className="input__required">*</span>
            </Form.Label>
            <Form.Group controlId="form__deliveryAddressChangeReasonId" style={{ gridColumn: '1', marginTop: '-1rem' }}>
              <Controller
                control={control}
                name="deliveryAddressChangeReasonId"
                render={({ field: { name, onBlur, onChange, ref, value } }) => {
                  return (
                    <Select
                      className={errors.deliveryAddressChangeReasonId && 'border-danger-select'}
                      classNamePrefix="custom-select-input"
                      isDisabled={disabled}
                      id="form__deliveryAddressChangeReasonId"
                      isClearable
                      maxMenuHeight={175}
                      onBlur={onBlur} //  Notify when input is touched
                      onChange={(val, { action }) => {
                        if (action === 'clear') {
                          onChange(null);
                        } else {
                          onChange(val?.value);
                        }
                        handleSelect(name, val);
                      }} // Send value to hook f = items.find(item => item.value = val);
                      options={deliveryAddressChangeReasons}
                      placeholder={PLACEHOLDER.SELECT_DELIVERY_ADDRESS_REASON_CHANGE}
                      ref={ref}
                      value={deliveryAddressChangeReasons.find(deliveryAddressChangeReason => deliveryAddressChangeReason.value === value) || null}
                    />
                  );
                }}
              />
              {errors.deliveryAddressChangeReasonId && <Form.Text className="text-danger">{errors.deliveryAddressChangeReasonId.message}</Form.Text>}
            </Form.Group>
            {deliveryAddressChangeReasonOther && (
              <Form.Group controlId="form__deliveryAddressChangeReasonOther" style={{ gridColumn: '1 / 3' }}>
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>
                    Other Reason for Changing the Delivery Address<span className="input__required">*</span>
                  </span>
                  <span>{deliveryAddressChangeReasonOtherCounter}/1000</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  autoComplete="off"
                  className={errors.deliveryAddressChangeReasonOther && 'border-danger'}
                  disabled={disabled}
                  maxLength={1000}
                  placeholder={PLACEHOLDER.DELIVERY_ADDRESS_REASON_CHANGE_OTHER}
                  rows={disabled ? getRowsOfTextArea(deliveryAddressChangeReasonOtherCounter, deliveryAddressChangeReasonOtherLinesCounter) : 3}
                  type="text"
                  {...register('deliveryAddressChangeReasonOther')}
                  onChange={event => setDeliveryAddressChangeReasonOtherCounter(event.target.value.length)}
                />
                {errors.deliveryAddressChangeReasonOther && <Form.Text className="text-danger">{errors.deliveryAddressChangeReasonOther.message}</Form.Text>}
              </Form.Group>
            )}
          </>
        )}

        <Form.Group controlId="form__samples" style={{ gridColumn: '1' }}>
          <Form.Label>
            Samples<span className="input__required">*</span>
          </Form.Label>
          <div>
            <Form.Check
              aria-label="samples__radio-yes"
              disabled={disabled}
              id="form__samples-yes"
              inline
              label="Yes"
              type="radio"
              value="Yes"
              {...register('samples')}
              onChange={() => handleSamples(true)}
            />
            <Form.Check
              aria-label="samples__radio-no"
              disabled={disabled}
              id="form__samples-no"
              inline
              label="No"
              type="radio"
              value="No"
              {...register('samples')}
              onChange={() => handleSamples(false)}
            />
          </div>
          {errors.samples && <Form.Text className="text-danger">{errors.samples.message}</Form.Text>}
        </Form.Group>
        {samples && (
          <>
            <Form.Group controlId="form__noOfSampleCopies" style={{ gridColumn: '1 / 2' }}>
              <Form.Label>
                Number Of Sample Copies<span className="input__required">*</span>
              </Form.Label>
              <Form.Control
                autoComplete="off"
                className={errors.noOfSampleCopies && 'border-danger'}
                disabled={disabled}
                min={0}
                onKeyDown={e => allowNumbers(e)}
                placeholder={PLACEHOLDER.NUMBER_OF_SAMPLE_COPIES}
                type="number"
                {...register('noOfSampleCopies', {
                  valueAsNumber: true,
                })}
              />
              {errors.noOfSampleCopies && <Form.Text className="text-danger">{errors.noOfSampleCopies.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__sampleCopiesDeliveryAddress" style={{ gridColumn: '1 / 3' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>
                  Sample Copies Delivery Address
                  <span className="input__required">*</span>
                </span>
                {!disabled && <span>{sampleCopiesDeliveryAddressCounter}/1000</span>}
              </Form.Label>
              <Form.Control
                as="textarea"
                autoComplete="off"
                className={errors.sampleCopiesDeliveryAddress && 'border-danger'}
                disabled={disabled}
                maxLength={1000}
                placeholder={PLACEHOLDER.SAMPLE_COPIES_DELIVERY_ADDRESS}
                rows={disabled ? getRowsOfTextArea(sampleCopiesDeliveryAddressCounter, sampleCopiesDeliveryAddressLinesCounter) : 3}
                type="text"
                {...register('sampleCopiesDeliveryAddress')}
                onChange={event => setSampleCopiesDeliveryAddressCounter(event.target.value.length)}
              />
              {errors.sampleCopiesDeliveryAddress && <Form.Text className="text-danger">{errors.sampleCopiesDeliveryAddress.message}</Form.Text>}
            </Form.Group>
          </>
        )}
        <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/4', marginTop: '30px' }}>
          Artwork
        </h6>
        <Form.Group controlId="form__artworkFormatId" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>
            Artwork Format<span className="input__required">*</span>
          </Form.Label>
          <Controller
            control={control}
            name="artworkFormatId"
            render={({ field: { name, onBlur, onChange, ref, value } }) => {
              return (
                <Select
                  className={errors.artworkFormatId && 'border-danger-select'}
                  classNamePrefix="custom-select-input"
                  id="form__artworkFormatId"
                  isClearable
                  isDisabled={disabled}
                  maxMenuHeight={175}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={(val, { action }) => {
                    if (action === 'clear') {
                      onChange(null);
                    } else {
                      onChange(val?.value);
                    }
                    handleSelect(name, val);
                  }} // Send value to hook f = items.find(item => item.value = val);
                  options={artworkFormatsList}
                  placeholder={PLACEHOLDER.SELECT_ARTWORK_FORMAT}
                  ref={ref}
                  value={artworkFormatsList.find(artworkFormat => artworkFormat.value === value)}
                />
              );
            }}
          />
          {errors.artworkFormatId && <Form.Text className="text-danger">{errors.artworkFormatId.message}</Form.Text>}
        </Form.Group>
        {artworkFormatOther && (
          <Form.Group controlId="form__artworkFormatOther" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Other Artwork Format<span className="input__required">*</span>
              </span>
              {!disabled && <span>{artworkFormatOtherCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.artworkFormatOther && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.ARTWORK_FORMAT_OTHER}
              rows={disabled ? getRowsOfTextArea(artworkFormatOtherCounter, artworkFormatOtherLinesCounter) : 3}
              type="text"
              {...register('artworkFormatOther')}
              onChange={event => setArtworkFormatOtherCounter(event.target.value.length)}
            />
            {errors.artworkFormatOther && <Form.Text className="text-danger">{errors.artworkFormatOther.message}</Form.Text>}
          </Form.Group>
        )}
        <Form.Group controlId="form__artworkDueDate" style={{ gridColumn: '1' }}>
          <Form.Label>Artwork Due Date</Form.Label>
          <Controller
            control={control}
            name="artworkDueDate"
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className={`form-control picker__container ${disabled ? 'picker__container__disabled' : ''} ${errors.artworkDueDate ? 'border-danger' : ''}`}>
                <DatePicker
                  className="picker__input"
                  dateFormat="dd/MM/yyyy"
                  disabled={disabled}
                  minDate={getMinimumDate(value)}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={onChange}
                  placeholderText={PLACEHOLDER.SELECT_ARTWORK_DUE_DATE}
                  ref={ref}
                  selected={value ? new Date(value) : null}
                  shouldCloseOnSelect={true}
                  showYearDropdown
                  strictParsing
                />
                <CalendarIcon />
              </div>
            )}
          />
          {errors.artworkDueDate && <Form.Text className="text-danger">{errors.artworkDueDate.message}</Form.Text>}
        </Form.Group>
        <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/4', marginTop: '30px' }}>
          Proofs
        </h6>
        <Form.Group controlId="form__typeOfProofsId" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>
            Type Of Proofs<span className="input__required">*</span>
          </Form.Label>
          <Controller
            control={control}
            name="typeOfProofsId"
            render={({ field: { name, onBlur, onChange, ref, value } }) => {
              return (
                <Select
                  className={errors.typeOfProofsId && 'border-danger-select'}
                  classNamePrefix="custom-select-input"
                  id="form__typeOfProofsId"
                  isClearable
                  isDisabled={disabled}
                  maxMenuHeight={175}
                  onBlur={onBlur} //  Notify when input is touched
                  onChange={(val, { action }) => {
                    if (action === 'clear') {
                      onChange(null);
                    } else {
                      onChange(val?.value);
                    }
                    handleSelect(name, val);
                  }} // Send value to hook f = items.find(item => item.value = val);
                  options={typeOfProofsList}
                  placeholder={PLACEHOLDER.SELECT_TYPE_OF_PROOFS}
                  ref={ref}
                  value={typeOfProofsList.find(typeOfProof => typeOfProof.value === value)}
                />
              );
            }}
          />
          {errors.typeOfProofsId && <Form.Text className="text-danger">{errors.typeOfProofsId.message}</Form.Text>}
        </Form.Group>
        {typeOfProofsOther && (
          <Form.Group controlId="form__typeOfProofsOther" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                Other Type Of Proofs<span className="input__required">*</span>
              </span>
              {!disabled && <span>{typeOfProofsOtherCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.typeOfProofsOther && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.TYPE_OF_PROOFS_OTHER}
              rows={disabled ? getRowsOfTextArea(typeOfProofsOtherCounter, typeOfProofsOtherLinesCounter) : 3}
              type="text"
              {...register('typeOfProofsOther')}
              onChange={event => setTypeOfProofsOtherCounter(event.target.value.length)}
            />
            {errors.typeOfProofsOther && <Form.Text className="text-danger">{errors.typeOfProofsOther.message}</Form.Text>}
          </Form.Group>
        )}
        {showProofOfDueDate && (
          <Form.Group controlId="form__proofDueDate" style={{ gridColumn: '1' }}>
            <Form.Label>Proof Due Date</Form.Label>
            <Controller
              control={control}
              name="proofDueDate"
              render={({ field: { onBlur, onChange, ref, value } }) => (
                <div className={`form-control picker__container ${disabled ? 'picker__container__disabled' : ''} ${errors.proofDueDate ? 'border-danger' : ''}`}>
                  <DatePicker
                    className="picker__input"
                    dateFormat="dd/MM/yyyy"
                    disabled={disabled}
                    minDate={getMinimumDate(value)}
                    onBlur={onBlur} //  Notify when input is touched
                    onChange={onChange}
                    placeholderText={PLACEHOLDER.SELECT_PROOF_OF_DUE_DATE}
                    ref={ref}
                    selected={value ? new Date(value) : null}
                    shouldCloseOnSelect={true}
                    showYearDropdown
                    strictParsing
                  />
                  <CalendarIcon />
                </div>
              )}
            />
            {errors.proofDueDate && <Form.Text className="text-danger">{errors.proofDueDate.message}</Form.Text>}
          </Form.Group>
        )}
        {isCDS && (
          <>
            <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/4', marginTop: '30px' }}>
              Additional Information
            </h6>
            <Form.Group controlId="form__primaryCategoryId">
              <Form.Label>
                Primary Category<span className="input__required">*</span>
              </Form.Label>
              <Controller
                control={control}
                name="primaryCategoryId"
                render={({ field: { onBlur, onChange, ref, value } }) => {
                  return (
                    <Select
                      className={errors.primaryCategoryId && 'border-danger-select'}
                      classNamePrefix="custom-select-input"
                      id="form__primaryCategoryId"
                      isClearable
                      isDisabled={disabled}
                      maxMenuHeight={175}
                      onBlur={onBlur} //  Notify when input is touched
                      onChange={(val, { action }) => {
                        if (action === 'clear') {
                          onChange(null);
                        } else {
                          onChange(val?.value);
                        }
                        handleCategory(val?.value);
                      }} // Send value to hook f = items.find(item => item.value = val);
                      options={primaryCategoriesList}
                      placeholder={PLACEHOLDER.SELECT_PRIMARY_CATEGORY}
                      ref={ref}
                      value={primaryCategoriesList.find(primaryCategory => primaryCategory.value === value)}
                    />
                  );
                }}
              />
              {errors.primaryCategoryId && <Form.Text className="text-danger">{errors.primaryCategoryId.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__secondaryCategoryId">
              <Form.Label>
                Secondary Category<span className="input__required">*</span>
              </Form.Label>
              <Controller
                control={control}
                name="secondaryCategoryId"
                render={({ field: { onBlur, onChange, ref, value } }) => {
                  return (
                    <Select
                      className={errors.secondaryCategoryId && 'border-danger-select'}
                      classNamePrefix="custom-select-input"
                      id="form__secondaryCategoryId"
                      isClearable
                      isDisabled={disabled}
                      maxMenuHeight={175}
                      onBlur={onBlur} //  Notify when input is touched
                      onChange={(val, { action }) => {
                        if (action === 'clear') {
                          onChange(null);
                        } else {
                          onChange(val?.value);
                        }
                      }} // Send value to hook f = items.find(item => item.value = val);
                      options={secondaryCategoriesList}
                      placeholder={PLACEHOLDER.SELECT_SECONDARY_CATEGORY}
                      ref={ref}
                      value={
                        disabled
                          ? secondaryCategories.find(secondaryCategory => secondaryCategory.value === value) || null
                          : secondaryCategoriesList.find(secondaryCategory => secondaryCategory.value === value) || null
                      }
                    />
                  );
                }}
              />
              {errors.secondaryCategoryId && <Form.Text className="text-danger">{errors.secondaryCategoryId.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__jobItemOptions" style={{ gridColumn: '1/4' }}>
              <Form.Label>Job Options</Form.Label>
              <br />
              {jobItemOptionsList.map(jobItemOption => (
                <Form.Check
                  key={jobItemOption.label}
                  disabled={disabled}
                  id={jobItemOption.label}
                  inline
                  label={jobItemOption.label}
                  type="checkbox"
                  value={jobItemOption.value}
                  checked={jobItemOption?.checked}
                  {...register('jobItemOptions')}
                  onChange={() => handleJobItemChange(jobItemOption)}
                />
              ))}
              {errors.jobItemOptions && <Form.Text className="text-danger">{errors.jobItemOptions}</Form.Text>}
            </Form.Group>
            {targetDate && (
              <Form.Group controlId="form__targetDate" style={{ gridColumn: '1' }}>
                <Form.Label>
                  Target Date
                  <span className="input__required">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="targetDate"
                  render={({ field: { onBlur, onChange, ref, value } }) => (
                    <div className={`form-control picker__container ${disabled ? 'picker__container__disabled' : ''} ${errors.targetDate ? 'border-danger' : ''}`}>
                      <DatePicker
                        className="picker__input"
                        dateFormat="dd/MM/yyyy"
                        disabled={disabled}
                        minDate={getMinimumDate(value)}
                        onBlur={onBlur} //  Notify when input is touched
                        onChange={onChange}
                        placeholderText={PLACEHOLDER.SELECT_TARGET_DATE}
                        ref={ref}
                        selected={value ? new Date(value) : null}
                        showYearDropdown
                        strictParsing
                        shouldCloseOnSelect={true}
                      />
                      <CalendarIcon />
                    </div>
                  )}
                />
                {errors.targetDate && <Form.Text className="text-danger">{errors.targetDate.message}</Form.Text>}
              </Form.Group>
            )}
          </>
        )}
        {isSupplier && initialState?.supplierNotes && (
          <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/4', marginTop: '30px' }}>
            Notes
          </h6>
        )}
        {!isSupplier && (
          <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/4', marginTop: '30px' }}>
            Notes
          </h6>
        )}
        {isCDS && (
          <Form.Group controlId="form__cdsNotes" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>CDS Notes (For Internal Use)</span>
              {!disabled && <span>{cdsNotesCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.cdsNotes && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={PLACEHOLDER.CDS_NOTES}
              rows={disabled ? getRowsOfTextArea(cdsNotesCounter, cdsNotesLinesCounter) : 3}
              type="text"
              {...register('cdsNotes')}
              onChange={event => setCdsNotesCounter(event.target.value.length)}
            />
            {errors.cdsNotes && <Form.Text className="text-danger">{errors.cdsNotes.message}</Form.Text>}
          </Form.Group>
        )}
        {isCDS && (
          <Form.Group controlId="form__supplierNotes" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              {isSupplier ? <span></span> : <span>Supplier Notes (Can Be Seen By Supplier)</span>}
              {!disabled && <span>{supplierNotesCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.supplierNotes && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={isSupplier ? ' ' : PLACEHOLDER.SUPPLIER_NOTES}
              rows={disabled ? getRowsOfTextArea(supplierNotesCounter, supplierNotesLinesCounter) : 3}
              type="text"
              {...register('supplierNotes')}
              onChange={event => setSupplierNotesCounter(event.target.value.length)}
            />
            {errors.supplierNotes && <Form.Text className="text-danger">{errors.supplierNotes.message}</Form.Text>}
          </Form.Group>
        )}
        {isSupplier && initialState?.supplierNotes && (
          <Form.Group controlId="form__supplierNotes" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              {isSupplier ? <span></span> : <span>Supplier Notes (Can Be Seen By Supplier)</span>}
              {!disabled && <span>{supplierNotesCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.supplierNotes && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              // placeholder={PLACEHOLDER.SUPPLIER_NOTES}
              placeholder={isSupplier ? ' ' : PLACEHOLDER.SUPPLIER_NOTES}
              rows={disabled ? getRowsOfTextArea(supplierNotesCounter, supplierNotesLinesCounter) : 3}
              type="text"
              {...register('supplierNotes')}
              onChange={event => setSupplierNotesCounter(event.target.value.length)}
            />
            {errors.supplierNotes && <Form.Text className="text-danger">{errors.supplierNotes.message}</Form.Text>}
          </Form.Group>
        )}
        {!isSupplier && (
          <Form.Group controlId="form__clientNotes" style={{ gridColumn: '1 / 3' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              {isCDS && <span>Client Notes (Can Be Seen By Client)</span>}
              {isClient && <span>Notes</span>}
              {!disabled && <span>{clientNotesCounter}/1000</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              className={errors.clientNotes && 'border-danger'}
              disabled={disabled}
              maxLength={1000}
              placeholder={isCDS ? PLACEHOLDER.CLIENT_NOTES : PLACEHOLDER.NOTES}
              rows={disabled ? getRowsOfTextArea(clientNotesCounter, clientNotesLinesCounter) : 3}
              type="text"
              {...register('clientNotes')}
              onChange={event => setClientNotesCounter(event.target.value.length)}
            />
            {errors.clientNotes && <Form.Text className="text-danger">{errors.clientNotes.message}</Form.Text>}
          </Form.Group>
        )}
        <Form.Group controlId="form__jobType" style={{ gridColumn: '1 / 2' }}>
          <Form.Label>Job Type</Form.Label>
          <Form.Control autoComplete="off" disabled type="text" value={BOOKLET} />
        </Form.Group>
        {isCDS && initialState?.discountDetails && <DiscountDetails initialState={initialState} />}
      </Form>
      <PopupModal
        show={errorModal}
        onHide={() => setErrorModal(false)}
        type={ERROR}
        header={'Error'}
        bodyTitle={'Saving Job Information failed!'}
        footerButtons={[
          {
            type: OUTLINED,
            name: 'Close',
            onClick: () => setErrorModal(false),
          },
        ]}
      />
    </>
  );
};

export default Booklet;
