import React, { useState, useEffect } from 'react';
import './style.sass';
import { isEmpty } from 'lodash';
import { protectedResources } from '../../../configs/msalAuthConfigs';
import { Controller, useForm } from 'react-hook-form';
import { request } from 'utils/request';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalendarIcon } from 'images/calendar.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectPo, isPoDetailsLoading } from './slice/selectors';
import { useOutstandingPurchase } from './slice';
import moment from 'moment';

export interface initialCloseInputState {
  jobQuoteId: number;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceValue: number;
}
export interface initialSaveInputState {
  invoiceNumber: string;
  invoiceDate: string;
  invoiceDetails: [
    {
      jobQuoteId: number;
      invoiceValue: number | string;
    },
  ];
}

const PoNumber = props => {
  const { onPostSuccess } = props;
  const [inputs, setInput] = useState({
    invoiceNumber: '',
    invoiceDate: '',
  });
  var poDetails = useSelector(selectPo);
  var isPoDetailsLoader = useSelector(isPoDetailsLoading);
  const dispatch = useDispatch();
  const { actions } = useOutstandingPurchase();
  const { control, reset } = useForm();

  const [errors, setErrors] = useState({
    invoiceNumber: '',
    invoiceValue: '',
    invoiceDate: '',
  });

  const saveValidateForm = () => {
    var { invoiceNumber, invoiceDate } = inputs;
    var isInvoiceValue = poDetails.productDetails.filter(item => item.isClosed == null && item.invoiceValue !== null);
    typeof invoiceDate === 'string' && invoiceDate.trim();
    isEmpty(invoiceDate) ? (errors.invoiceDate = 'Invoice date is required') : (errors.invoiceDate = '');
    setErrors({ ...errors });
    typeof invoiceNumber === 'string' && invoiceNumber.trim();
    isEmpty(invoiceNumber) ? (errors.invoiceNumber = 'Invoice number is required') : (errors.invoiceNumber = '');
    setErrors({ ...errors });
    return isInvoiceValue.length == 0 || (!isEmpty(errors.invoiceNumber) && isEmpty(errors.invoiceDate)) ? false : true;
  };

  const handleInputChange = (e, val) => {
    let value = val !== '' ? val : '';
    if (val !== '') {
      setInput({
        ...inputs,
        invoiceDate: moment(new Date(value)).format('YYYY-MM-DD'),
      });
    } else {
      setInput({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOnSaveOrClose = (clicked = 'Save', id) => {
    var payload = {};
    var endpoint = '';
    const invoiceDetails = poDetails.productDetails
      .filter(item => item.isClosed == null && item.invoiceValue !== null)
      .map(item => {
        return {
          jobQuoteId: item.jobQuoteId,
          invoiceValue: item.invoiceValue,
        };
      });

    if (clicked == 'Save') {
      if (!saveValidateForm()) return;
      payload = {
        ...inputs,
        invoiceDetails: invoiceDetails,
      };
      endpoint = 'SaveInvoiceDetails';
      dispatch(actions.removeMultipleProductDetails({ invoiceDetails: invoiceDetails }));
    } else {
      payload = {
        invoiceValue: poDetails.productDetails.find(item => item.jobQuoteId == id)?.invoiceValue,
        jobQuoteId: id,
      };
      endpoint = 'CloseLineItem';
      dispatch(actions.removeClosedProductDetails({ id: id }));
    }

    try {
      request(`${protectedResources.api.apiEndpoint}/Invoice/${endpoint}`, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      onPostSuccess();
    } catch (responseError) {
      console.error('Error while submitting the form ' + responseError);
    }
  };

  useEffect(() => {
    !isPoDetailsLoader && dispatch(actions.updateInvoiceValue({ id: null, value: null }));
  }, [isPoDetailsLoader]);

  return (
    <React.Fragment>
      <div className="margin_outStanding ">
        <h4>{poDetails.supplierPo}</h4>
        <h5>{poDetails.supplierName}</h5>
        <h6>{poDetails.supplierAddress}</h6>
        <h6>{poDetails.supplierPostCode}</h6>
      </div>
      <div className="row table_padding">
        <div className="col margin_outStanding">
          <div className="col_style ">
            <h6 className="h6_outStanding">Reference</h6>
            <p>{poDetails.jobReference}</p>
          </div>
          <div className="col_style">
            <h6 className="h6_outStanding">Job Title</h6>
            <p>{poDetails.jobTitle}</p>
          </div>
          <div className="col_style">
            <h6 className="h6_outStanding">Job No</h6>
            <p>{poDetails.jobNumber}</p>
          </div>
        </div>
      </div>
      <div className="row table_padding">
        <div className="col margin_outStanding">
          <div className="col_style">
            <h6 className="h6_outStanding">
              <strong>Total PO Value</strong>
            </h6>
            <p>&#163;&nbsp;{poDetails.totalPoValue?.toFixed(2)}</p>
          </div>
          <div className="col_style">
            <h6 className="h6_outStanding">
              <strong>Outstanding Value</strong>
            </h6>
            <p>&#163;&nbsp;{poDetails.outstandingValue?.toFixed(2)}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col_style invoiceNo_outStanding">
          <Form.Group className="mb-3" controlId="formInvoiceNo">
            <Form.Label>
              Invoice No<span className="input__required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              required
              placeholder="Enter"
              name="invoiceNumber"
              isInvalid={errors.invoiceNumber === '' ? false : true}
              value={inputs.invoiceNumber}
              onChange={e => handleInputChange(e, '')}
            />
            <Form.Control.Feedback type="invalid">{errors.invoiceNumber}</Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="col col_style invoiceDate_outStanding">
          <Form.Group className="mb-3" controlId="formInvoiceDate">
            <Form.Label>
              Invoice Date<span className="input__required">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="invoiceDate"
              render={({ field: { onChange, onBlur, value } }) => (
                <div className={`form-control picker__container `}>
                  <DatePicker
                    shouldCloseOnSelect={true}
                    className="picker__input"
                    name="invoiceDate"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Date"
                    onChange={e => {
                      onChange(e);
                      handleInputChange('', e);
                    }}
                    onBlur={onBlur}
                    selected={value}
                  />
                  <CalendarIcon />
                </div>
              )}
            />
            {errors.invoiceDate == '' && <Form.Control.Feedback>{errors.invoiceDate}</Form.Control.Feedback>}
          </Form.Group>
        </div>
      </div>
      <div className="margin_outSttanding ">
        <Table className="table_outStanding">
          <thead className="tableHead_outStanding">
            <tr className="tableHorizontal_outStanding">
              <th>Line.No</th>
              <th>Title</th>
              <th>Quantity</th>
              <th style={{ textAlign: 'right' }}>Unit Price (&#163;)</th>
              <th style={{ textAlign: 'right' }}>PO Value (&#163;)</th>
              <th style={{ textAlign: 'right' }}>Outstanding value (&#163;)</th>
              <th style={{ textAlign: 'right' }}>Invoice Value (&#163;)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {poDetails.productDetails.map(item => (
              <tr className="tableHorizontal_outStanding">
                <td>{item.jobLineId}</td>
                <td>{item.title}</td>
                <td style={{ textAlign: 'right' }}>{item.quantity}</td>
                <td style={{ textAlign: 'right' }}>{item.unitPrice?.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>{item.poValue?.toFixed(2)}</td>
                <td style={{ textAlign: 'right' }}>{item.outstandingValue?.toFixed(2)}</td>
                <td>
                  <>
                    <Form.Group controlId="formInvoiceValue">
                      <Form.Control
                        type="text"
                        placeholder="Enter"
                        name="invoiceValue"
                        isInvalid={errors.invoiceValue !== ''}
                        onChange={e => {
                          dispatch(actions.updateInvoiceValue({ id: item.jobQuoteId, value: e.target.value == '' || null ? null : Number(e.target.value) }));
                        }}
                      />
                      <Form.Control.Feedback type="invalid">{errors.invoiceValue}</Form.Control.Feedback>
                    </Form.Group>
                  </>
                </td>
                <td>
                  {item.isClosed === null ? (
                    <Button
                      variant="outline-primary"
                      className="full__button"
                      onClick={() => {
                        handleOnSaveOrClose('Close', item.jobQuoteId);
                      }}
                    >
                      Close
                    </Button>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="border-bottom_outStanding">
        <Button
          variant="outline-primary"
          className="full__button saveButton_outStanding"
          disabled={
            poDetails.productDetails.filter(item => item.isClosed == null && item.invoiceValue !== null)?.length == 0 ||
            isEmpty(inputs.invoiceNumber) ||
            inputs.invoiceDate == '' ||
            errors.invoiceValue !== ''
          }
          onClick={() => handleOnSaveOrClose('Save', '')}
        >
          Save
        </Button>
      </div>
    </React.Fragment>
  );
};

export default PoNumber;
