//  Externals
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
//  Internals
import { RootState } from 'store/model';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.requestForQuote || initialState;

//  Navigation Selectors
export const selectNavigation = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.navigation);

//  Product Items Selectors
export const selectSelectedProductItems = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.productItemsState.selectedProductItems);

export const selectAddedProducts = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.productItemsState.tempSelectedProductItems);

//  Product Information Selectors
export const getProductInformationData = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.productInformation.values);

export const selectProductInformationLoading = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.productInformation.isLoading);

//  Product Item Details Selectors
export const selectProductItemDetailsRows = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.productItemsState.productItemDetails.rows);

export const selectProductItemDetailsRowsCheckboxesArray = createSelector([selectSlice], requestForQuoteState =>
  requestForQuoteState.productItemsState.productItemDetails.rows.map(item => item.checked),
);

export const selectProductItemDetailsRowsOpenArray = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.productItemsState.productItemDetails.rows.map(item => item.open));

//  Common functions for selecting specific id row
const getId = (_, id) => id;

export const useParamSelector = (selector, ...params) => {
  return useSelector(state => selector(state, ...params));
};

//  Supplier Selectors
export const getSupplierList = createSelector(
  selectSlice,
  getId,
  (requestForQuoteState, id) => requestForQuoteState.productItemsState.productItemDetails.rows.filter(item => item.id === id)[0]?.details.quotes.supplierList,
);

export const selectFilteredSupplierList = createSelector(
  selectSlice,
  getId,
  (requestForQuoteState, id) => requestForQuoteState.productItemsState.productItemDetails.rows.filter(item => item.id === id)[0]?.details.quotes.filteredSupplierList,
);

export const getMinSupplierCount = createSelector(
  selectSlice,
  getId,
  (requestForQuoteState, id) => requestForQuoteState.productItemsState.productItemDetails.rows.filter(item => item.id === id)[0]?.details.quotes.minSupplierCount,
);

export const selectSelectedSupplierList = createSelector(
  selectSlice,
  getId,
  (requestForQuoteState, id) => requestForQuoteState.productItemsState.productItemDetails.rows.filter(item => item.id === id)[0]?.details.quotes.selectedSuppliers,
);

export const selectTempSelectedSupplierList = createSelector(
  selectSlice,
  getId,
  (requestForQuoteState, id) => requestForQuoteState.productItemsState.productItemDetails.rows.filter(item => item.id === id)[0]?.details.quotes.tempSelectedSuppliers,
);

export const selectSupplierData = createSelector(
  selectSlice,
  getId,
  (requestForQuoteState, id) => requestForQuoteState.productItemsState.productItemDetails.rows.filter(item => item.id === id)[0]?.details.quotes.supplierData,
);

export const selectSupplierDataCheckboxesArray = createSelector(selectSlice, getId, (requestForQuoteState, id) =>
  requestForQuoteState.productItemsState.productItemDetails.rows.filter(item => item.id === id)[0]?.details.quotes.supplierData.supplierFormData.map(item => item.checked),
);
export const selectSupplierDataOpenArray = createSelector(selectSlice, getId, (requestForQuoteState, id) =>
  requestForQuoteState.productItemsState.productItemDetails.rows.filter(item => item.id === id)[0]?.details.quotes.supplierData.supplierFormData.map(item => item.open),
);

//  Product Items Buttons Header Selectors
export const selectProductItemsButtonsHeader = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.productItemsButtonHeader);

//  Print Specifications Selectors
export const selectPrintSpecificationsForm = createSelector(
  selectSlice,
  getId,
  (requestForQuoteState, id) => requestForQuoteState.productItemsState.productItemDetails.rows.filter(row => row.id === id)[0]?.details.printSpecifications.form,
);

//  Client Quote Confirmation
export const selectConfirmClientQuote = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.confirmClientQuote);

//  Client Order Confirmation
export const selectConfirmClientOrder = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.confirmClientOrder);

export const selectConfirmClientOrderPODetails = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.confirmClientOrder?.details.poDetails);

//  Supplier Order Confirmation
export const selectPlaceSupplierOrderDetails = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.placeSupplierOrder);

export const selectRfqDataIsLoading = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.rfqData.isLoading);

export const selectProductQuoteSupplierList = createSelector(
  selectSlice,
  getId,
  (requestForQuoteState, id) =>
    requestForQuoteState.rfqData.form.productTemplate &&
    requestForQuoteState.rfqData.form.productTemplate.map(item => {
      if (item.productTemplateDetails.rfqjobId == parseInt(id.rfqJobId) && item.productTemplateDetails.templateId == id.templateId && item.productTemplateDetails.productId == id.productId) {
        return item;
      }
    }),
);

export const selectSupplierDataFromAPI = createSelector(
  selectSlice,
  getId,
  (requestForQuoteState, id) => requestForQuoteState?.productItemsState?.productItemDetails?.rows?.filter(item => item?.id === id)[0]?.details?.jobQuotes,
);

export const getOtherJobCostTableList = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.rfqData.form.otherJobCosts);

export const getIsJobCostSubmitted = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.rfqData.isJobCostSubmitted);

export const selectedSupplierList = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.SelectedSupplierList);

export const getQuoteVatRate = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.vatRateDetails);

export const getClientQuoteConfirmationRequestBody = createSelector([selectSlice], requestForQuoteState =>
  requestForQuoteState.productItemsState.productItemDetails.rows
    ?.map(item => {
      return {
        productId: item?.details.printSpecifications.form.templateId,
        quoteId: item?.details.jobQuotes?.filter(item => item.jobQuoteSummary.checked),
      };
    })
    ?.filter(item => item.quoteId && item.quoteId?.length > 0)
    ?.map(item => {
      return {
        productTemplateId: item.productId,
        jobQuoteID: item.quoteId && item.quoteId?.length && item.quoteId[0].jobQuoteSummary.jobQuoteId,
      };
    }),
);

export const EditSupplierQuoteData = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.EditSupplierQuote);

export const selectStatusDetails = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.statusDetails);

export const selectPlaceSupplierOrder = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.placeSupplierOrderDetails);

export const getSupplierOrderConfirmationRequestBody = createSelector([selectSlice], requestForQuoteState =>
  requestForQuoteState.productItemsState.productItemDetails.rows?.map(item => {
    return item?.details.printSpecifications.form.templateId;
  }),
);

export const getAllUserList = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.userList);

export const getAllTaskData = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.taskList);

export const getAddTasksData = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.addTasks);

export const getOtherCostList = createSelector([selectSlice], requestForQuoteState => requestForQuoteState.OtherCostRateItems);
export const getPoDetails = createSelector(
  selectSlice,
  getId,
  (requestForQuoteState, id) => requestForQuoteState.productItemsState.productItemDetails.rows.filter(row => row.id === id)[0]?.details.printSpecifications.form.poDetails,
);
