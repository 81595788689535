import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import './style.sass';
import { useOutstandingPurchase } from './slice';
import { selectSearch } from './slice/selectors';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import Account from './Account';
import { Helmet } from 'react-helmet-async';
import PoNumber from './PoNumber';
import { searchItems } from './slice/types';
import Breadcrumb from '../breadcrumb';
import { useBreadcrumbSlice } from '../breadcrumb/slice';

const Search = () => {
  const [searchResponse, setSearchResponse] = useState<searchItems[]>([]);
  const [searchText, setSearchText] = useState('');
  const search = useSelector(selectSearch);
  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  const { actions: outstandingPurchaseActions } = useOutstandingPurchase();
  const dispatch = useDispatch();
  const [isAccOrPO, setIsAccOrPO] = useState({
    isAccount: false,
    isPoNumber: false,
  });

  const onHandleSelectedValueChange = val => {
    const filteredData = searchResponse.find(item => item.name == val);
    let data = JSON.parse(JSON.stringify(filteredData));
    data?.isAccount && dispatch(outstandingPurchaseActions.loadSelectedAccount(data));
    !data?.isAccount && dispatch(outstandingPurchaseActions.loadSelectedPO(data));
    setIsAccOrPO({
      isAccount: data?.isAccount,
      isPoNumber: !data?.isAccount,
    });
  };

  const onIputValueChange = val => {
    setSearchText(val);
    if (val.length >= 3) {
      dispatch(outstandingPurchaseActions.loadSearch({ value: val }));
    }
  };
  useEffect(() => {
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Home', url: '/dashboard' },
        { name: 'Outstanding POs', url: '/outstandingpos' },
      ]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let response = JSON.parse(JSON.stringify(search.data.items));
    setSearchResponse(response);
  }, [search]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Outstanding Purchase Orders</title>
      </Helmet>
      <h3>Outstanding Purchase Order</h3>
      <Breadcrumb />
      <div className="p-3">
        <div className={!isAccOrPO.isAccount && !isAccOrPO.isPoNumber ? 'd-flex flex-row min-vh-100 justify-content-center align-items-center' : 'd-none'}>
          <Stack spacing={2} sx={!isAccOrPO.isAccount && !isAccOrPO.isPoNumber ? { width: 700 } : { width: 400 }}>
            <Autocomplete
              id=""
              freeSolo
              value={searchText}
              options={searchResponse?.map(item => item?.name)}
              onChange={(event, value) => onHandleSelectedValueChange(value)}
              renderInput={params => <TextField {...params} label="Search PO Number / Account Name" onChange={e => onIputValueChange(e.target.value)} />}
            />
          </Stack>
          <div className="ps-4">
            <Button variant="outline-primary " className="outline__button">
              Search
            </Button>
          </div>
        </div>
        {isAccOrPO.isAccount && <Account />}
        {isAccOrPO.isPoNumber && (
          <PoNumber
            onPostSuccess={() => {
              setIsAccOrPO({ ...isAccOrPO, isPoNumber: false });
              setSearchResponse([]);
              setSearchText('');
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Search;
