import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton/IconButton';
import React, { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { supplierOrderProductItem } from './../slice/types';

type productItemProps = {
  productItem: supplierOrderProductItem;
};
export const ProductItemDetails = ({ productItem }: productItemProps) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="productItemDetail" style={{ borderBottom: `${!open ? 'none' : '1px solid #000'}` }}>
      <div className="titleRow">
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(prevState => !prevState)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
        {productItem.orderDetails.type}: {productItem.orderDetails.title}
      </div>
      <div className="contentRow">
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className="grid__container__orderDetails">
            <div className="grid__item">
              <span>Job Reference</span>
              <p>{productItem.orderDetails.jobReference}</p>
            </div>
            <div className="grid__item">
              <span>Job Title</span>
              <p>{productItem.orderDetails.jobTitle}</p>
            </div>
            <div className="grid__item">
              <span>Job Requisition</span>
              <p>{productItem.orderDetails.jobRequisition}</p>
            </div>
            <div className="grid__item">
              <span>Order Date</span>
              <p>{productItem.orderDetails.orderDate}</p>
            </div>
            <div className="grid__item">
              <span>Your Reference</span>
              <p>{productItem.orderDetails.yourReference}</p>
            </div>
            <div className="grid__item">
              <span>Production</span>
              <p>&#163;{productItem.orderDetails.productionCost?.toFixed(2)}</p>
            </div>
            <div className="grid__item">
              <span>Material</span>
              <p>&#163;{productItem.orderDetails.materialCost?.toFixed(2)}</p>
            </div>
            <div className="grid__item">
              <span>Delivery</span>
              <p>&#163;{productItem.orderDetails.deliveryCost?.toFixed(2)}</p>
            </div>
            <div className="grid__item">
              <span>Total</span>
              <p>&#163;{productItem.orderDetails.total?.toFixed(2)}</p>
            </div>
            <div className="grid__item"></div>
            <div className="grid__item">
              <span>Buyer Name</span>
              <p>{productItem.orderDetails.buyerName}</p>
            </div>
            <div className="grid__item">
              <span>Materials</span>
              <p>{productItem.orderDetails.materials}</p>
            </div>
            <div className="grid__item">
              <span>Specifications</span>
              <p>{productItem.orderDetails.specifications}</p>
            </div>
          </div>
          <div className="delivery__address">
            <h6>DELIVERY ADDRESS</h6>
            <h6>{productItem.orderDetails.deliveryAddress}</h6>
          </div>
          <div className="grid__container__productDetails">
            <div className="grid__item">
              <span>Quantity</span>
              <p>{productItem.productDetails.quantity}</p>
            </div>
            <div className="grid__item">
              <span>No.Of Pages</span>
              <p>{productItem.productDetails.noOfPages}</p>
            </div>
            <div className="grid__item">
              <span>Finished Size</span>
              <p>{productItem.productDetails.finishedSize}</p>
            </div>
            <div className="grid__item">
              <span>Finishing</span>
              <p>{productItem.productDetails.finishing}</p>
            </div>
            <div className="grid__item">
              <span>Colours On Face</span>
              <p>{productItem.productDetails.coloursOnFace}</p>
            </div>
            <div className="grid__item">
              <span>Colours On Reverse</span>
              <p>{productItem.productDetails.coloursOnReverse}</p>
            </div>
            <div className="grid__item">
              <span>Sealer On Face</span>
              <p>{productItem.productDetails.sealerOnFace}</p>
            </div>
            <div className="grid__item">
              <span>Sealer On Reverse</span>
              <p>{productItem.productDetails.sealerOnReverse}</p>
            </div>
            <div className="grid__item">
              <span>Material</span>
              <p>{productItem.productDetails.material}</p>
            </div>
            <div className="grid__item">
              <span>Material Weight</span>
              <p>{productItem.productDetails.materialWeight}gms</p>
            </div>
            <div className="grid__item">
              <span>Atrwork Format</span>
              <p>{productItem.productDetails.artWorkFormat}</p>
            </div>
            <div className="grid__item">
              <span>Types Of Proofs</span>
              <p>{productItem.productDetails.typesOfProofs}</p>
            </div>
            <div className="grid__item">
              <span>Addendum</span>
              <p>{productItem.productDetails.addendum}</p>
            </div>
          </div>
          <div className="artWork__container">
            <div className="grid__item">
              <span>Artwork Date</span>
              <p>{productItem.productDetails.artWorkDate}</p>
            </div>
            <div className="grid__item">
              <span>Proof Date</span>
              <p>{productItem.productDetails.proofDate}</p>
            </div>
            <div className="grid__item">
              <span>Delivery Date</span>
              <p>{productItem.productDetails.deliveryDate}</p>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ProductItemDetails;
