/*
 *  Job date, Latest quotation date, Job value will be shown if the job status is not equal to Draft.
 *  Job Options, CDS Client Executive, Direct Invoice, and Forecast Date are fields only available for CDS User.
 */
//  Externals
import { KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { range } from 'lodash';
import moment from 'moment';
//  Internals
import { Account, BusinessUnits, CdsClientExecutives, Contacts, initialJobInformation, JobOptions, JobOptionsList } from '../../../../types/myJobs';
import { getDropDowns, getJobInformation, getProductItems } from './../../../../store/reducers/entities/selectors';
import { changeNavigation, setJobInformation } from '../../../../store/reducers/entities';
import { CONFIRMATION, ERROR, FULL, OUTLINED } from 'app/components/shared/Constants';
import { getOtherJobCostTableList } from 'app/pages/requestForQuote/slice/selectors';
import { getAccountId, getIsClient, userInfo } from '../sideBar/slice/selectors';
import { initialDisabledFields, JobInformationProps } from './types';
import { apiCallBegan } from '../../../../store/reducers/loading';
import { PRODUCT_ITEMS } from './../../../../data/constants';
import PopupModal from 'app/components/shared/PopupModal';
import { getIsCDS } from './../sideBar/slice/selectors';
import PLACEHOLDER from '../../../../data/placeholder';
import callApi from './../../../../services/callApi';
import { months } from '../../../../data/constants';
import STATUS from './../../../../data/status';
import MODULES from '../../../../data/modules';
import AddNewContact from './AddNewContact';
import API from '../../../../data/api';
import URL from '../../../../data/url';
import schema from './validation';
import './style.sass';

const date = new Date();
const currentYear = date.getFullYear();
const upperLimit = currentYear + 10;

const yearsList = range(currentYear, upperLimit, 1).map(year => ({ value: year, label: year.toString() }));
const defaultMonths = months.map(month => ({ value: month, label: month }));

const JobInformation = (props: JobInformationProps) => {
  const { create } = props;
  //  Current User
  const currentUser = useSelector(userInfo);
  const accountId = useSelector(getAccountId);
  const isClient = useSelector(getIsClient);
  const isCDS = useSelector(getIsCDS);

  //  Job information
  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));
  const productItems = useSelector(getProductItems(MODULES.MY_JOBS));
  const otherCostList = useSelector(getOtherJobCostTableList);
  const poDetailsProductItems = productItems
    .filter(productItem => productItem.printSpecifications.lineId && ![STATUS.DRAFT, STATUS.NEW_RFQ, STATUS.QUOTATION].includes(productItem.printSpecifications.status.code))
    .filter(productItem =>
      productItem.printSpecifications.poDetails
        ? ![STATUS.CANCELLED].includes(productItem.printSpecifications.status.code)
        : productItem.printSpecifications?.noPo && ![STATUS.CANCELLED].includes(productItem.printSpecifications.status.code),
    )
    .map(productItem => ({
      lineId: productItem.printSpecifications.lineId,
      poNumber: productItem.printSpecifications.poDetails ? productItem.printSpecifications.poDetails.poNumber : 'Not Available',
    }));

  const { jobId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /*  Dropdown values */
  //  Accounts Name
  const accounts: Account[] = useSelector(getDropDowns(MODULES.MY_JOBS, 'accounts'));
  //  Business Unit
  const businessUnits: BusinessUnits[] = useSelector(getDropDowns(MODULES.MY_JOBS, 'businessUnits'));
  //  Business Units List
  const [businessUnitsList, setBusinessUnitsList] = useState<BusinessUnits[]>([]);
  //  Contacts
  const contacts: Contacts[] = useSelector(getDropDowns(MODULES.MY_JOBS, 'contacts'));
  //  Contacts list
  const [contactsList, setContactsList] = useState<Contacts[]>([]);
  //  CDS Client Executives
  const cdsClientExecutives: CdsClientExecutives[] = useSelector(getDropDowns(MODULES.MY_JOBS, 'cdsClientExecutives'));
  //  Job options
  const jobOptions: JobOptions[] = useSelector(getDropDowns(MODULES.MY_JOBS, 'jobOptions'));
  //  Job options List
  const [jobOptionsList, setJobOptionsList] = useState<JobOptionsList[]>([]);

  //  Account Type
  const [isAccountTypeCollection, setIsAccountTypeCollection] = useState(false);
  //  Direct Invoice
  const [isDirectInvoiceChecked, setIsDirectInvoiceChecked] = useState(false);
  //  Discard Model
  const [discardModalShow, setDiscardModalShow] = useState(false);
  //  Failure Model
  const [failureModal, setFailureModal] = useState(false);
  //  Counters
  const [invoiceAddressCounter, setInvoiceAddressCounter] = useState(0);
  const [titleCounter, setTitleCounter] = useState(0);
  //  Months
  const [monthList, setMonthList] = useState(defaultMonths);
  //  Disabled Fields
  const [disabledFields, setDisabledFields] = useState(initialDisabledFields);

  const [accountDetails, setAccountDetails] = useState<Account | null>(null);
  const [showAddNewContactModal, setShowAddNewContactModal] = useState(false);
  const [newContact, setNewContact] = useState<any>(null);

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    register,
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: initialJobInformation,
    resolver: yupResolver(schema({ isCDS })),
  });

  useEffect(() => {
    let { userId } = currentUser;
    if (create) {
      //  Job options
      setJobOptionsList([...jobOptions.map(jobOption => ({ ...jobOption, checked: false }))]);
      //  If the user is CDS then auto-populate the cds executive
    } else {
      if (jobInformation.cdsexecutive !== null) userId = jobInformation.cdsexecutive;
      if (newContact) reset({ ...jobInformation, contactId: newContact?.contactId, contactEmail: newContact?.email, contactPhoneNumber: newContact?.phoneNumber });
      else reset(jobInformation);
      //  Title Count
      setTitleCounter(jobInformation.title ? jobInformation.title?.length : 0);
      //  Filter the Business Units
      setBusinessUnitsList(businessUnits.filter(businessUnit => businessUnit.parentAccountId === jobInformation.accountId));
      //  Job options
      setJobOptionsList([...jobOptions.map(jobOption => ({ ...jobOption, checked: jobInformation.jobOptions.includes(jobOption.value) }))]);
      //  Filter the Contacts
      setContactsList(contacts.filter(contact => contact.accountId === jobInformation.accountId));
      //  Direct Invoice
      setIsDirectInvoiceChecked(jobInformation.directInvoice ? jobInformation.directInvoice : false);
      //  Invoice Address Count
      setInvoiceAddressCounter(jobInformation.invoiceAddress ? jobInformation.invoiceAddress?.length : 0);
      //  Export Type Collection
      const account = accounts.find(account => account.value === jobInformation.accountId);
      if (account && account.exportType === 'Collection') {
        setIsAccountTypeCollection(true);
        setAccountDetails(account);
      }
    }
    if (isCDS) {
      const entity = cdsClientExecutives.find(cdsClientExecutive => cdsClientExecutive.value === userId);
      if (entity) setValue('cdsexecutive', entity.value, { shouldValidate: false });
    }
  }, [accounts, businessUnits, cdsClientExecutives, contacts, create, currentUser, currentUser.userId, isCDS, jobInformation, jobOptions, newContact, reset, setValue]);

  useEffect(() => {
    if (isClient) {
      setValue('accountId', accountId, { shouldValidate: false });
      //  Filter the Business Units
      setBusinessUnitsList(businessUnits.filter(businessUnit => businessUnit.parentAccountId === accountId));
      //  Filter the Contacts
      setContactsList(contacts.filter(contact => contact.accountId === accountId));
      //  Auto update the Invoice Name, Email Address, Address.
      const account = accounts.find(account => account.value === accountId);
      if (create && account) {
        //  Invoice Name
        setValue('invoiceContactName', account.invoiceContactName, {
          shouldValidate: false,
        });
        //  Invoice Email Address
        setValue('invoiceContactEmailAddress', account.invoiceContactEmailAddress, {
          shouldValidate: false,
        });
        //  Invoice Address
        setValue('invoiceAddress', account.invoiceAddress, {
          shouldValidate: false,
        });
      }
    }
  }, [accountId, accounts, businessUnits, contacts, create, isClient, setValue]);
  useEffect(() => {
    if (!create) {
      const updatedDisabledFields = { ...disabledFields };
      //  Draft - As of now all fields
      //  New RFQ - As of now all fields
      //  Amended RFQ - As of now all fields
      if ([STATUS.DRAFT, STATUS.NEW_RFQ].includes(jobInformation.status?.code)) {
        updatedDisabledFields.title = false;
        updatedDisabledFields.accountId = false;
        updatedDisabledFields.businessUnitId = false;
        updatedDisabledFields.clientReference = false;
        updatedDisabledFields.contactId = false;
      }
      if ([STATUS.AMENDED_RFQ, STATUS.QUOTATION, STATUS.PART_ORDERED, STATUS.ORDERED, STATUS.PART_DELIVERED, STATUS.DELIVERED].includes(jobInformation.status?.code)) {
        updatedDisabledFields.accountId = true;
      }
      //  To Invoice
      if (jobInformation.status?.code === STATUS.TO_INVOICE) {
        updatedDisabledFields.title = true;
        updatedDisabledFields.accountId = true;
        updatedDisabledFields.businessUnitId = true;
        updatedDisabledFields.clientReference = true;
        updatedDisabledFields.contactId = true;
        // updatedDisabledFields.contactEmail = true;
        // updatedDisabledFields.contactPhoneNumber = true;
        updatedDisabledFields.directInvoice = true;
        updatedDisabledFields.invoiceContactName = true;
        updatedDisabledFields.invoiceContactEmailAddress = true;
        updatedDisabledFields.invoiceAddress = true;
      }
      //  Re-check
      if (jobInformation.status?.code === STATUS.RECHECK) {
        updatedDisabledFields.accountId = true;
      }
      //  Part Invoiced
      if (jobInformation.status?.code === STATUS.PART_INVOICED) {
        updatedDisabledFields.title = true;
        updatedDisabledFields.accountId = true;
        updatedDisabledFields.businessUnitId = true;
        updatedDisabledFields.clientReference = true;
        updatedDisabledFields.contactId = true;
        // updatedDisabledFields.contactEmail = true;
        // updatedDisabledFields.contactPhoneNumber = true;
        updatedDisabledFields.directInvoice = true;
        updatedDisabledFields.invoiceContactName = true;
        updatedDisabledFields.invoiceContactEmailAddress = true;
        updatedDisabledFields.invoiceAddress = true;
      }
      //  Invoiced
      if (jobInformation.status?.code === STATUS.INVOICED) {
        updatedDisabledFields.title = true;
        updatedDisabledFields.accountId = true;
        updatedDisabledFields.businessUnitId = true;
        updatedDisabledFields.clientReference = true;
        updatedDisabledFields.contactId = true;
        // updatedDisabledFields.contactEmail = true;
        // updatedDisabledFields.contactPhoneNumber = true;
        updatedDisabledFields.cdsexecutive = true;
        updatedDisabledFields.directInvoice = true;
        updatedDisabledFields.invoiceContactName = true;
        updatedDisabledFields.invoiceContactEmailAddress = true;
        updatedDisabledFields.invoiceAddress = true;
      }
      //  Cancelled
      if (jobInformation.status?.code === STATUS.CANCELLED) {
        updatedDisabledFields.title = true;
        updatedDisabledFields.accountId = true;
        updatedDisabledFields.businessUnitId = true;
        updatedDisabledFields.clientReference = true;
        updatedDisabledFields.jobOptions = true;
        updatedDisabledFields.contactId = true;
        // updatedDisabledFields.contactEmail = true;
        // updatedDisabledFields.contactPhoneNumber = true;
        updatedDisabledFields.cdsexecutive = true;
        updatedDisabledFields.directInvoice = true;
        updatedDisabledFields.invoiceContactName = true;
        updatedDisabledFields.invoiceContactEmailAddress = true;
        updatedDisabledFields.invoiceAddress = true;
        updatedDisabledFields.forecastMonth = true;
        updatedDisabledFields.forecastYear = true;
      }
      setDisabledFields({ ...updatedDisabledFields });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create, jobInformation.status?.code, jobInformation]);

  useEffect(() => {
    if (accountDetails && accountDetails?.value !== accountId) setContactsList(contacts.filter(contact => contact.accountId === accountDetails?.value));
  }, [accountDetails, accountId, contacts]);

  useEffect(() => {
    if (newContact) {
      setValue('contactId', newContact?.contactId, {
        shouldValidate: true,
      });
      setValue('contactEmail', newContact?.email, {
        shouldValidate: true,
      });
      setValue('contactPhoneNumber', newContact?.phoneNumber, {
        shouldValidate: true,
      });
    }
  }, [newContact, setValue]);

  /******************************************************************************************
  @Purpose    :   Handle Form submit
  *******************************************************************************************/
  const onSubmit = async (values: typeof initialJobInformation) => {
    try {
      if (create) {
        //  Create Job
        const response: any = await callApi({ url: API.CREATE_JOB, body: values, method: 'post' });
        if (response.isSuccess) navigate(`${URL.MY_JOBS}/${response.data.jobId}`);
      } else {
        //  Update Job
        dispatch(apiCallBegan({ url: API.UPDATE_JOB, body: values, method: 'put', onSuccess: setJobInformation.type, module: MODULES.MY_JOBS }));
      }
      //  Change Navigation
      dispatch(changeNavigation({ module: MODULES.MY_JOBS, key: PRODUCT_ITEMS }));
    } catch (responseError) {
      setFailureModal(true);
      console.error('Error while submitting the form ' + responseError);
    }
  };

  /******************************************************************************************
  @Purpose    :   React Select down and up arrows.
  *******************************************************************************************/
  const DropdownIndicator = props => {
    return <components.DropdownIndicator {...props}>{props.selectProps.menuIsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</components.DropdownIndicator>;
  };

  /******************************************************************************************
  @Purpose    :   React Select Multiline options name and email.
  *******************************************************************************************/
  const MultilineOption = props => {
    return (
      <components.Option {...props} className="search__select">
        <span>{props.data.label}</span>
        <span>{props.data.email}</span>
      </components.Option>
    );
  };

  /******************************************************************************************
  @Purpose    :   Whenever the Account Name is changed Business Units and Contacts should filter.
                  And Invoice contact name, email and address auto populate.
  *******************************************************************************************/
  const handleAccountChange = (value: Account | null) => {
    if (value === null) {
      setValue('invoiceContactName', null, {
        shouldValidate: false,
      });
      setValue('invoiceContactEmailAddress', null, {
        shouldValidate: false,
      });
      setValue('invoiceAddress', null, {
        shouldValidate: false,
      });
      setAccountDetails(null);
      setBusinessUnitsList([]);
      setContactsList([]);
    } else {
      if (value.exportType === 'Collection' && !getValues('directInvoice')) {
        setIsAccountTypeCollection(true);
        setValue('invoiceContactName', value.invoiceContactName, {
          shouldValidate: false,
        });
        setValue('invoiceContactEmailAddress', value.invoiceContactEmailAddress, {
          shouldValidate: false,
        });
        setValue('invoiceAddress', value.invoiceAddress, {
          shouldValidate: false,
        });
      } else {
        setIsAccountTypeCollection(false);
        setValue('invoiceContactName', null, {
          shouldValidate: false,
        });
        setValue('invoiceContactEmailAddress', null, {
          shouldValidate: false,
        });
        setValue('invoiceAddress', null, {
          shouldValidate: false,
        });
      }
      //  Filter the Business Units
      setBusinessUnitsList(businessUnits.filter(businessUnit => businessUnit.parentAccountId === value.value));
      //  Filter the Contacts
      setContactsList(contacts.filter(contact => contact.accountId === value.value));
      setAccountDetails(value);
    }
    setValue('businessUnitId', null, {
      shouldValidate: false,
    });
    setValue('contactId', null, {
      shouldValidate: false,
    });
    setValue('contactEmail', null, {
      shouldValidate: false,
    });
    setValue('contactPhoneNumber', null, {
      shouldValidate: false,
    });
  };

  /******************************************************************************************
  @Purpose    :   Handle Job Options.
  *******************************************************************************************/
  const handleJobOptionChange = (entity: JobOptionsList) => {
    const updatedJobOptions = jobOptionsList.map(jobOption => (jobOption.value === entity.value ? { ...jobOption, checked: !entity.checked } : jobOption));
    setJobOptionsList(updatedJobOptions);
    const selectedJobOptions = updatedJobOptions.filter(jobOption => jobOption.checked).map(jobOption => jobOption.value);
    setValue('jobOptions', selectedJobOptions, {
      shouldValidate: false,
    });
  };

  /******************************************************************************************
  @Purpose    :   Whenever the Contact Name is changed email and phone number auto populate.
  *******************************************************************************************/
  const handleContactChange = (value: Contacts | null) => {
    if (value === null) {
      setValue('contactEmail', null, {
        shouldValidate: false,
      });
      setValue('contactPhoneNumber', null, {
        shouldValidate: false,
      });
    } else {
      setValue('contactEmail', value.email, {
        shouldValidate: true,
      });
      setValue('contactPhoneNumber', value.phoneNumber, {
        shouldValidate: true,
      });
    }
  };

  /******************************************************************************************
  @Purpose    :   Whenever the Direct Invoice is clicked clear the values
  *******************************************************************************************/
  const handleDirectInvoice = value => {
    setIsDirectInvoiceChecked(value);
    if (value) {
      setValue('invoiceContactName', null, {
        shouldValidate: false,
      });
      setValue('invoiceContactEmailAddress', null, {
        shouldValidate: false,
      });
      setValue('invoiceAddress', null, {
        shouldValidate: false,
      });
    } else if (accountDetails) {
      if (accountDetails.exportType === 'Collection') {
        setIsAccountTypeCollection(true);
        setValue('invoiceContactName', accountDetails.invoiceContactName, {
          shouldValidate: false,
        });
        setValue('invoiceContactEmailAddress', accountDetails.invoiceContactEmailAddress, {
          shouldValidate: false,
        });
        setValue('invoiceAddress', accountDetails.invoiceAddress, {
          shouldValidate: false,
        });
      } else {
        setIsAccountTypeCollection(false);
        setValue('invoiceContactName', null, {
          shouldValidate: false,
        });
        setValue('invoiceContactEmailAddress', null, {
          shouldValidate: false,
        });
        setValue('invoiceAddress', null, {
          shouldValidate: false,
        });
      }
    }
  };

  /******************************************************************************************
  @Purpose    :   Whenever the Year is changed the months get filtered based on the current date.
  *******************************************************************************************/
  const handleYearChange = (year: { value: number; label: string } | null) => {
    if (year !== null && year.value === currentYear) {
      setMonthList(months.slice(date.getMonth()).map(month => ({ value: month, label: month })));
    } else {
      setMonthList(defaultMonths);
    }
  };

  /******************************************************************************************
  @Purpose    :   Reset the form data.
  *******************************************************************************************/
  const discardHandler = () => {
    reset(initialJobInformation);
    navigate(-1);
  };

  const onError = error => {
    console.error('ERROR:::', error);
  };

  return (
    <>
      <div className="form__container job__information_container">
        <Form onSubmit={handleSubmit(onSubmit, onError)} id="jobInformation">
          <h4 className="form__subHeader top__subHeader">Job Information</h4>
          <div className="job__information">
            {create ? (
              <div className="form__row">
                <Form.Group controlId="form__status">
                  <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>Status</Form.Label>
                  <Form.Control autoComplete="off" disabled name="status" placeholder={PLACEHOLDER.STATUS} type="text" value="Draft" />
                </Form.Group>
              </div>
            ) : (
              <div>
                <div className="form__row">
                  <Form.Group controlId="form__status">
                    <Form.Label>Status</Form.Label>
                    <Form.Control autoComplete="off" disabled name="status" placeholder={PLACEHOLDER.STATUS} type="text" value={jobInformation.status?.name || ''} />
                  </Form.Group>
                </div>
                <div>
                  {jobInformation.status?.name == 'Re-check' && (
                    <div>
                      <div className="form__row">
                        <Form.Group controlId="form_reason">
                          <Form.Label>Re-check reason</Form.Label>
                          <Form.Control as="textarea" autoComplete="off" disabled name="status" type="text" value={jobInformation.recheckReason || ''} />
                        </Form.Group>
                      </div>

                      <div className="form__row">
                        <Form.Group controlId="form__recheckBy">
                          <Form.Label>Re-check by</Form.Label>
                          <Form.Control autoComplete="off" disabled name="status" type="text" value={jobInformation.reviewedBy || ''} />
                        </Form.Group>

                        <Form.Group controlId="form__recheckDate">
                          <Form.Label>Re-check date</Form.Label>
                          <Form.Control autoComplete="off" disabled name="status" type="text" value={moment(jobInformation.recheckDate)?.format('DD/MM/YYYY') || ''} />
                        </Form.Group>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="form__row">
              <Form.Group controlId="form__title" style={{ gridColumn: '1 / 3' }}>
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>
                    Title<span className="input__required">*</span>
                  </span>
                  <span>{titleCounter}/100</span>
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  className={errors.title && 'border-danger'}
                  disabled={disabledFields.title}
                  maxLength={100}
                  placeholder={PLACEHOLDER.TITLE}
                  type="text"
                  {...register('title')}
                  onChange={e => setTitleCounter(e.target.value?.length)}
                />
                {errors.title && <Form.Text className="text-danger">{errors.title.message}</Form.Text>}
              </Form.Group>
            </div>
            {jobId && jobInformation.status?.code !== STATUS.DRAFT && (
              <div className="form__row">
                <Table style={{ gridColumn: '1 / 3', border: '1px solid #e1d5ec' }} borderless={true}>
                  <thead style={{ backgroundColor: '#eae3f1' }}>
                    <tr>
                      <th>Job date</th>
                      <th>Latest quotation date</th>
                      <th style={{ textAlign: 'right' }}>Job value (£)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{jobInformation.createdDateTime ? moment(jobInformation.createdDateTime).format('DD/MM/YYYY') : '-'}</td>
                      <td>{jobInformation.latestQuotationDate ? moment(jobInformation.latestQuotationDate).format('DD/MM/YYYY') : '-'}</td>
                      <td style={{ textAlign: 'right' }}>{jobInformation.jobValue ? jobInformation.jobValue?.toFixed(2) : '-'}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
            <div className="form__row">
              {!isClient && (
                <Form.Group controlId="form__accountId">
                  <Form.Label>
                    Account Name<span className="input__required">*</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="accountId"
                    render={({ field: { onBlur, onChange, ref, value } }) => (
                      <Select
                        className={errors.accountId && 'border-danger-select'}
                        classNamePrefix="custom-select-input"
                        components={{
                          DropdownIndicator,
                        }}
                        id="form__accountId"
                        isClearable
                        isDisabled={disabledFields.accountId}
                        maxMenuHeight={175}
                        onBlur={onBlur} //  Notify when input is touched
                        onChange={(val, { action }) => {
                          if (action === 'clear') {
                            onChange(null);
                          } else {
                            onChange(val?.value);
                          }
                          handleAccountChange(val);
                        }} // Send value to hook f = items.find(item => item.value = val);
                        options={accounts}
                        placeholder={PLACEHOLDER.SELECT_ACCOUNT}
                        ref={ref}
                        value={accounts.find(account => account.value === value) || null}
                      />
                    )}
                  />
                  {errors.accountId && <Form.Text className="text-danger">{errors.accountId.message}</Form.Text>}
                </Form.Group>
              )}
              <Form.Group controlId="form__businessUnitId">
                <Form.Label>
                  Business Unit<span className="input__required">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="businessUnitId"
                  render={({ field: { onBlur, onChange, ref, value } }) => (
                    <Select
                      className={errors.businessUnitId && 'border-danger-select'}
                      classNamePrefix="custom-select-input"
                      components={{
                        DropdownIndicator,
                      }}
                      id="form__businessUnitId"
                      isClearable
                      isDisabled={disabledFields.businessUnitId}
                      maxMenuHeight={175}
                      onBlur={onBlur} //  Notify when input is touched
                      onChange={(val, { action }) => {
                        if (action === 'clear') {
                          onChange(null);
                        } else {
                          onChange(val?.value);
                        }
                      }} // Send value to hook f = items.find(item => item.value = val);
                      options={businessUnitsList}
                      placeholder={PLACEHOLDER.SELECT_BUSINESS_UNIT}
                      ref={ref}
                      value={businessUnitsList.find(account => account.value === value) || null}
                    />
                  )}
                />
                {errors.businessUnitId && <Form.Text className="text-danger">{errors.businessUnitId.message}</Form.Text>}
              </Form.Group>
            </div>
            <div className="form__row">
              <Form.Group controlId="form__clientReference">
                <Form.Label>Client Reference</Form.Label>
                <Form.Control
                  autoComplete="off"
                  className={errors.clientReference && 'border-danger'}
                  disabled={disabledFields.clientReference}
                  placeholder={PLACEHOLDER.CLIENT_REFERENCE}
                  type="text"
                  {...register('clientReference')}
                />
                {errors.clientReference && <Form.Text className="text-danger">{errors.clientReference.message}</Form.Text>}
              </Form.Group>
            </div>
            <div className="form__row row">
              {jobInformation.relatedJobId && (
                <Form.Group controlId="form__relatedJobId">
                  <Form.Label>Related Job Id</Form.Label>
                  <Form.Control autoComplete="off" className={errors.relatedJobId && 'border-danger'} disabled type="text" {...register('relatedJobId')} />
                  {errors.relatedJobId && <Form.Text className="text-danger">{errors.relatedJobId.message}</Form.Text>}
                </Form.Group>
              )}
            </div>
            {isCDS && (
              <div className="form__row">
                <Form.Group controlId="form__jobOptions" style={{ gridColumn: '1/4' }}>
                  <Form.Label>Job Options</Form.Label>
                  <br />
                  {jobOptionsList.map(jobOption => (
                    <Controller
                      control={control}
                      key={jobOption.label}
                      name="jobOptions"
                      render={({ field: { onBlur, onChange, ref } }) => (
                        <Form.Check
                          disabled={disabledFields.jobOptions}
                          id={jobOption.label}
                          inline
                          label={jobOption.label}
                          type="checkbox"
                          value={jobOption.value}
                          checked={jobOption.checked}
                          onBlur={onBlur} //  Notify when input is touched
                          onChange={val => {
                            onChange(val);
                            handleJobOptionChange(jobOption);
                          }}
                          ref={ref}
                        />
                      )}
                    />
                  ))}
                  {errors.jobOptions && <Form.Text className="text-danger">{errors.jobOptions}</Form.Text>}
                </Form.Group>
              </div>
            )}
          </div>
          <div className="d-flex mt-5 mb-3 align-items-center">
            <h4 style={{ fontSize: '18px', fontWeight: '700' }} className="align-self-center mt-2">
              Contact
            </h4>
          </div>
          <div className="form__contact">
            <div className="form__row">
              <Form.Group controlId="form__contactId">
                <Form.Label>
                  Name<span className="input__required">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="contactId"
                  render={({ field: { onBlur, onChange, ref, value } }) => (
                    <Select
                      className={errors.contactId && 'border-danger-select'}
                      classNamePrefix="custom-select-input"
                      components={{
                        DropdownIndicator,
                        Option: MultilineOption,
                      }}
                      id="form__contactId"
                      isClearable
                      isDisabled={disabledFields.contactId}
                      maxMenuHeight={175}
                      onBlur={onBlur} //  Notify when input is touched
                      onChange={(val, { action }) => {
                        if (action === 'clear') {
                          onChange(null);
                        } else {
                          onChange(val?.value);
                        }
                        handleContactChange(val);
                      }} // Send value to hook f = items.find(item => item.value = val);
                      options={contactsList}
                      placeholder={PLACEHOLDER.SELECT_CONTACT_NAME}
                      ref={ref}
                      value={contactsList.find(account => account.value === value) || null}
                    />
                  )}
                />
                {errors.contactId && <Form.Text className="text-danger">{errors.contactId.message}</Form.Text>}
              </Form.Group>
              <Form.Group controlId="form__contactEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control autoComplete="off" className={errors.contactEmail && 'border-danger'} disabled placeholder={PLACEHOLDER.CONTACT_EMAIL} type="text" {...register('contactEmail')} />
                {errors.contactEmail && <Form.Text className="text-danger">{errors.contactEmail.message}</Form.Text>}
              </Form.Group>
              <Form.Group style={{ gridColumn: '1' }} controlId="form__contactPhoneNumber">
                <Form.Label>Telephone Number</Form.Label>
                <Form.Control
                  autoComplete="off"
                  className={errors.contactPhoneNumber && 'border-danger'}
                  disabled
                  type="text"
                  {...register('contactPhoneNumber')}
                  placeholder="Enter Telephone Number"
                />
                {errors.contactPhoneNumber && <Form.Text className="text-danger">{errors.contactPhoneNumber.message}</Form.Text>}
              </Form.Group>
            </div>
            <Button
              className="outline__button btn-sm ms-2"
              disabled={create ? getValues('accountId') === null : create}
              form="jobInformation"
              onClick={() => setShowAddNewContactModal(true)}
              tabIndex={-1}
              variant="outline-primary"
            >
              Add new
            </Button>
          </div>
          {isCDS && (
            <>
              <h4 className="form__subHeader">CDS Client Executive</h4>
              <div className="form__cds">
                <div className="form__row">
                  <Form.Group controlId="form__cdsexecutive">
                    <Form.Label>
                      Name<span className="input__required">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="cdsexecutive"
                      render={({ field: { onBlur, onChange, ref, value } }) => (
                        <Select
                          className={errors.cdsexecutive && 'border-danger-select'}
                          classNamePrefix="custom-select-input"
                          components={{
                            DropdownIndicator,
                          }}
                          id="form__cdsexecutive"
                          isClearable
                          isDisabled={disabledFields.cdsexecutive}
                          maxMenuHeight={175}
                          onBlur={onBlur} //  Notify when input is touched
                          onChange={(val, { action }) => {
                            if (action === 'clear') {
                              onChange(null);
                            } else {
                              onChange(val?.value);
                            }
                          }} // Send value to hook f = items.find(item => item.value = val);
                          options={cdsClientExecutives}
                          placeholder={PLACEHOLDER.SELECT_CONTACT_NAME}
                          ref={ref}
                          value={cdsClientExecutives.find(cdsClientExecutive => cdsClientExecutive.value === value) || null}
                        />
                      )}
                    />
                    {errors.cdsexecutive && <Form.Text style={{ width: '210%', color: '#C35139' }}>{errors.cdsexecutive.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
            </>
          )}
          <br />
          {isClient && (
            <>
              {poDetailsProductItems && poDetailsProductItems?.length !== 0 && !create ? (
                <Table style={{ width: '49.5%', border: '1px solid #e1d5ec' }} borderless={true}>
                  <thead style={{ backgroundColor: '#eae3f1' }}>
                    <tr>
                      <th>Product line</th>
                      <th>PO number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poDetailsProductItems.map((podetail, i) => (
                      <tr key={i} style={{ border: '1px solid #e1d5ec' }}>
                        <td>{podetail ? podetail.lineId : '-'}</td>
                        <td className={podetail.poNumber === 'Not Available' ? 'text-danger' : ''}>{podetail ? podetail.poNumber.toUpperCase() : '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
              {otherCostList && otherCostList?.filter(item => item.status.code != 'NewRFQ')?.length !== 0 && !create ? (
                <Table style={{ width: '49.5%', border: '1px solid #e1d5ec' }} borderless={true}>
                  <thead style={{ backgroundColor: '#eae3f1' }}>
                    <tr>
                      <th>Other Costs line</th>
                      <th>PO number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {otherCostList
                      .filter(item => item.status.code != 'NewRFQ')
                      .map((item, i) => (
                        <tr key={i} style={{ border: '1px solid #e1d5ec' }}>
                          <td>{item?.lineNumber ? item?.lineNumber : '-'}</td>
                          <td>{item?.poNumber ? item.poNumber.toUpperCase() : <h5 className="text-danger">NOT AVAILABLE</h5>}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
              <h4 className="form__subHeader">Forecast Date</h4>
              <div className="form__forecastDate">
                <div className="form__row" style={{ width: '55%' }}>
                  <Form.Group controlId="form__forecastMonth">
                    <Form.Label>Month</Form.Label>
                    <Controller
                      control={control}
                      name="forecastMonth"
                      render={({ field: { onBlur, onChange, ref, value } }) => (
                        <Select
                          className={errors.forecastMonth && 'border-danger-select'}
                          classNamePrefix="custom-select-input"
                          components={{
                            DropdownIndicator,
                          }}
                          id="form__forecastMonth"
                          isClearable
                          isDisabled={disabledFields.forecastMonth}
                          maxMenuHeight={175}
                          menuPlacement="top"
                          onBlur={onBlur} //  Notify when input is touched
                          onChange={(val, { action }) => {
                            if (action === 'clear') {
                              onChange(null);
                            } else {
                              onChange(val?.value);
                            }
                          }} // Send value to hook f = items.find(item => item.value = val);
                          options={monthList}
                          placeholder={PLACEHOLDER.MONTH}
                          ref={ref}
                          value={monthList.find(month => month.value === value) || null}
                        />
                      )}
                    />
                    {errors.forecastMonth && <Form.Text className="text-danger">{errors.forecastMonth.message}</Form.Text>}
                  </Form.Group>
                  <Form.Group controlId="form__forecastYear">
                    <Form.Label>Year</Form.Label>
                    <Controller
                      control={control}
                      name="forecastYear"
                      render={({ field: { onBlur, onChange, ref, value } }) => (
                        <Select
                          className={errors.forecastYear && 'border-danger-select'}
                          classNamePrefix="custom-select-input"
                          components={{
                            DropdownIndicator,
                          }}
                          id="form__forecastYear"
                          isClearable
                          isDisabled={disabledFields.forecastYear}
                          maxMenuHeight={175}
                          menuPlacement="top"
                          onBlur={onBlur} //  Notify when input is touched
                          onChange={(val, { action }) => {
                            if (action === 'clear') {
                              onChange(null);
                            } else {
                              onChange(val?.value);
                            }
                            handleYearChange(val);
                          }} // Send value to hook f = items.find(item => item.value = val);
                          options={yearsList}
                          placeholder={PLACEHOLDER.YEAR}
                          ref={ref}
                          value={yearsList.find(year => year.value === value) || null}
                        />
                      )}
                    />
                    {errors.forecastYear && <Form.Text className="text-danger">{errors.forecastYear.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
            </>
          )}
          <h4 className="form__subHeader">Invoice</h4>
          <div className="form__invoice">
            <div className="form__row">
              <Form.Group controlId="form__directInvoice" style={{ gridColumn: '1' }}>
                <Controller
                  control={control}
                  name="directInvoice"
                  render={({ field: { onBlur, onChange, ref } }) => (
                    <Form.Check
                      disabled={disabledFields.directInvoice}
                      onBlur={onBlur}
                      onChange={e => {
                        onChange(e);
                        handleDirectInvoice(!isDirectInvoiceChecked);
                      }}
                      label="Direct Invoice"
                      ref={ref}
                      type="checkbox"
                      checked={isDirectInvoiceChecked}
                    />
                  )}
                />
                {errors.directInvoice && <Form.Text className="text-danger">{errors.directInvoice.message}</Form.Text>}
              </Form.Group>
            </div>
            <div className="form__row">
              <Form.Group controlId="form__invoiceContactName">
                <Form.Label>
                  <span>
                    Name<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  className={errors.invoiceContactName && 'border-danger'}
                  disabled={disabledFields.invoiceContactName || (isCDS ? isAccountTypeCollection && !isDirectInvoiceChecked : !isDirectInvoiceChecked)}
                  placeholder={PLACEHOLDER.NAME}
                  type="text"
                  {...register('invoiceContactName')}
                />
                {errors.invoiceContactName && <Form.Text className="text-danger">{errors.invoiceContactName.message}</Form.Text>}
              </Form.Group>
              <Form.Group controlId="form__invoiceContactEmailAddress" style={{ gridColumn: '2/3' }}>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  autoComplete="off"
                  className={errors.invoiceContactEmailAddress && 'border-danger'}
                  disabled={disabledFields.invoiceContactName || (isCDS ? isAccountTypeCollection && !isDirectInvoiceChecked : !isDirectInvoiceChecked)}
                  placeholder={PLACEHOLDER.EMAIL}
                  type="text"
                  {...register('invoiceContactEmailAddress')}
                />
                {errors.invoiceContactEmailAddress && <Form.Text className="text-danger">{errors.invoiceContactEmailAddress.message}</Form.Text>}
              </Form.Group>
              <Form.Group controlId="form__invoiceAddress" style={{ gridColumn: '1 / 3' }}>
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Invoice Address</span>
                  <span>{invoiceAddressCounter}/1000</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  autoComplete="off"
                  className={errors.invoiceAddress && 'border-danger'}
                  disabled={disabledFields.invoiceContactName || (isCDS ? isAccountTypeCollection && !isDirectInvoiceChecked : !isDirectInvoiceChecked)}
                  maxLength={1000}
                  placeholder={PLACEHOLDER.INVOICE_ADDRESS}
                  rows={3}
                  type="text"
                  {...register('invoiceAddress')}
                  onChange={event => setInvoiceAddressCounter(event.target.value?.length)}
                />
                {errors.invoiceAddress && <Form.Text className="text-danger">{errors.invoiceAddress.message}</Form.Text>}
              </Form.Group>
            </div>
          </div>
          {isCDS && (
            <>
              {poDetailsProductItems && poDetailsProductItems?.length !== 0 && !create ? (
                <Table style={{ width: '49.5%', border: '1px solid #e1d5ec' }} borderless={true}>
                  <thead style={{ backgroundColor: '#eae3f1' }}>
                    <tr>
                      <th>Product line</th>
                      <th>PO number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poDetailsProductItems.map((podetail, i) => (
                      <tr key={i} style={{ border: '1px solid #e1d5ec' }}>
                        <td>{podetail ? podetail.lineId : '-'}</td>
                        <td className={podetail.poNumber === 'Not Available' ? 'text-danger' : ''}>{podetail ? podetail.poNumber.toUpperCase() : '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
              {otherCostList && otherCostList?.filter(item => item.status.code != 'NewRFQ')?.length !== 0 && !create ? (
                <Table style={{ width: '49.5%', border: '1px solid #e1d5ec' }} borderless={true}>
                  <thead style={{ backgroundColor: '#eae3f1' }}>
                    <tr>
                      <th>Other Costs line</th>
                      <th>PO number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {otherCostList
                      .filter(item => item.status.code != 'NewRFQ')
                      .map((item, i) => (
                        <tr key={i} style={{ border: '1px solid #e1d5ec' }}>
                          <td>{item?.lineNumber ? item?.lineNumber : '-'}</td>
                          <td>{item?.poNumber ? item.poNumber.toUpperCase() : <h5 className="text-danger">NOT AVAILABLE</h5>}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
              <h4 className="form__subHeader">Forecast Date</h4>
              <div className="form__forecastDate">
                <div className="form__row" style={{ width: '55%' }}>
                  <Form.Group controlId="form__forecastMonth">
                    <Form.Label>Month</Form.Label>
                    <Controller
                      control={control}
                      name="forecastMonth"
                      render={({ field: { onBlur, onChange, ref, value } }) => (
                        <Select
                          className={errors.forecastMonth && 'border-danger-select'}
                          classNamePrefix="custom-select-input"
                          components={{
                            DropdownIndicator,
                          }}
                          id="form__forecastMonth"
                          isClearable
                          isDisabled={disabledFields.forecastMonth}
                          maxMenuHeight={175}
                          menuPlacement="top"
                          onBlur={onBlur} //  Notify when input is touched
                          onChange={(val, { action }) => {
                            if (action === 'clear') {
                              onChange(null);
                            } else {
                              onChange(val?.value);
                            }
                          }} // Send value to hook f = items.find(item => item.value = val);
                          options={monthList}
                          placeholder={PLACEHOLDER.MONTH}
                          ref={ref}
                          value={monthList.find(month => month.value === value) || null}
                        />
                      )}
                    />
                    {errors.forecastMonth && <Form.Text className="text-danger">{errors.forecastMonth.message}</Form.Text>}
                  </Form.Group>
                  <Form.Group controlId="form__forecastYear">
                    <Form.Label>Year</Form.Label>
                    <Controller
                      control={control}
                      name="forecastYear"
                      render={({ field: { onBlur, onChange, ref, value } }) => (
                        <Select
                          className={errors.forecastYear && 'border-danger-select'}
                          classNamePrefix="custom-select-input"
                          components={{
                            DropdownIndicator,
                          }}
                          id="form__forecastYear"
                          isClearable
                          isDisabled={disabledFields.forecastYear}
                          maxMenuHeight={175}
                          menuPlacement="top"
                          onBlur={onBlur} //  Notify when input is touched
                          onChange={(val, { action }) => {
                            if (action === 'clear') {
                              onChange(null);
                            } else {
                              onChange(val?.value);
                            }
                            handleYearChange(val);
                          }} // Send value to hook f = items.find(item => item.value = val);
                          options={yearsList}
                          placeholder={PLACEHOLDER.YEAR}
                          ref={ref}
                          value={yearsList.find(year => year.value === value) || null}
                        />
                      )}
                    />
                    {errors.forecastYear && <Form.Text className="text-danger">{errors.forecastYear.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
            </>
          )}
        </Form>
        <div style={{ marginBottom: '5rem' }}></div>
      </div>
      <footer>
        <Button
          disabled={jobInformation?.statusName === 'Cancelled'}
          variant="outline-primary"
          form="jobInformation"
          className="outline__button"
          onClick={() => setDiscardModalShow(true)}
          tabIndex={-1}
        >
          Discard
        </Button>
        <Button disabled={jobInformation?.statusName === 'Cancelled'} variant="success" form="jobInformation" type="submit" className="full__button">
          Save
        </Button>
      </footer>
      <PopupModal
        show={discardModalShow}
        onHide={() => setDiscardModalShow(false)}
        type={CONFIRMATION}
        header="Confirmation"
        bodyTitle="Are you sure?  Any changes will be lost"
        footerButtons={[
          {
            type: OUTLINED,
            name: 'No',
            onClick: () => setDiscardModalShow(false),
          },
          { type: FULL, name: 'Yes', onClick: discardHandler },
        ]}
      />
      <PopupModal
        show={failureModal}
        onHide={() => setFailureModal(false)}
        type={ERROR}
        header="Error"
        bodyTitle="Saving Job Information failed!"
        footerButtons={[
          {
            type: OUTLINED,
            name: 'Close',
            onClick: () => setFailureModal(false),
          },
        ]}
      />
      {showAddNewContactModal && (
        <AddNewContact accountId={getValues('accountId')} onHide={() => setShowAddNewContactModal(false)} onNewContact={contact => setNewContact(contact)} show={showAddNewContactModal} />
      )}
    </>
  );
};

export default JobInformation;
