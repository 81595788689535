import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useAuditHistorySlice } from './slice';
import { useBreadcrumbSlice } from '../breadcrumb/slice';
import { useDispatch, useSelector } from 'react-redux';
import { getAuditHistory, getLoader } from './slice/selectors';
import Breadcrumb from '../breadcrumb';
import Spinner from 'react-bootstrap/Spinner';
import MomentUtils from '@date-io/moment';
import { Pagination } from '@mui/material';
import usePagination from '../profile/Pagination';
import { Helmet } from 'react-helmet-async';

const Audit = props => {
  const { isAdministrator } = props;
  const dispatch = useDispatch();
  const { actions } = useAuditHistorySlice();
  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  const isLoader = useSelector(getLoader);
  const auditHistoryList = useSelector(getAuditHistory);
  const moment = new MomentUtils();
  const [page, setPage] = useState(1);
  const dataPerPage = 11;
  const count = Math.ceil(auditHistoryList.length / dataPerPage);
  const _PerPageData_ = usePagination(auditHistoryList, dataPerPage);

  const handlePageChange = (e, p) => {
    setPage(p);
    _PerPageData_.jump(p);
  };

  useEffect(() => {
    dispatch(actions.loadAuditInformation());
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Dashboard', url: '/dashboard' },
        { name: 'Administration/Audit History', url: '/administration/audithistory' },
      ]),
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Audit History</title>
      </Helmet>
      {isAdministrator && <Breadcrumb />}
      <div className="p-3 d-flex flex-column align-tems-center justify-content-center audit__history">
        {isLoader ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Table responsive="sm" className="audit__history__table">
            <thead className="bg-light">
              <tr>
                <th className="w-25">User Name</th>
                <th className="w-25">Audit type</th>
                <th className="w-25">Date and Time</th>
                <th>Old Values</th>
                <th>New Values</th>
                {isAdministrator && <th className="w-25">Job Id</th>}
              </tr>
            </thead>
            <tbody>
              {_PerPageData_.currentData()?.map((item, index) => {
                const oldValues = obj => {
                  var newStr = obj.replace(/\\\//g, '/');
                  var setHtml = '';
                  for (const [key, value] of Object.entries(JSON.parse(newStr))) {
                    setHtml += `<div><strong>${key}</strong>: ${value}</div>`;
                  }
                  return <div dangerouslySetInnerHTML={{ __html: setHtml }} />;
                };
                return (
                  <tr key={`${item.auditType} ${index}`}>
                    <td>{item?.userName}</td>
                    <td>{item?.auditType}</td>
                    <td>{`${moment.date(item?.dateTime).format('DD/MM/YYYY HH:mm:ss')}`}</td>
                    <td>{item?.oldValues !== null ? oldValues(item?.oldValues) : '-'}</td>
                    <td>{item?.newValues !== null ? oldValues(item?.newValues) : '-'}</td>
                    {isAdministrator && <td>{item?.rfqJobId}</td>}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
        <div className="pagination">
          <Pagination count={count} size="large" page={page} onChange={handlePageChange} />
        </div>
      </div>
    </>
  );
};

export default Audit;
