//  Externals
import { call, put, takeLatest } from 'redux-saga/effects';
//  Internals
import { protectedResources } from '../../../../../configs/msalAuthConfigs';
import { MenuState, notificationMenuItem } from './types';
import { menuActions as actions } from '.';
import { request } from 'utils/request';

function* getMenu() {
  const menuRequestURL = `${protectedResources.api.apiEndpoint}/menu/getMenuItems`;
  const notificationsRequestURL = `${protectedResources.api.apiEndpoint}/menu/getNotificationCounts`;
  try {
    const menu: MenuState = yield call(request, menuRequestURL);
    const notificationCountModules: notificationMenuItem[] = yield call(request, notificationsRequestURL);
    if (menu && notificationCountModules) {
      menu.notificationCountModules = notificationCountModules;
      yield put(actions.menuLoaded(menu));
    }
  } catch (err: any) {
    console.error('err ', err);
  }
}

function* getNotificationCounts() {
  const requestURL = `${protectedResources.api.apiEndpoint}/menu/getNotificationCounts`;
  try {
    const notificationCountModules: notificationMenuItem[] = yield call(request, requestURL);
    if (notificationCountModules) {
      yield put(actions.changeNotificationCount(notificationCountModules));
    }
  } catch (err: any) {
    console.error('err ', err);
  }
}

export default function* menuSaga() {
  yield takeLatest(actions.loadMenu.type, getMenu);
  yield takeLatest(actions.loadNotificationCounts.type, getNotificationCounts);
}
