export interface Account {
  exportType: 'Collection' | 'Individual';
  invoiceAddress: string;
  invoiceContactEmailAddress: string;
  invoiceContactName: string;
  label: string;
  value: number;
}

export interface BusinessUnits {
  label: string;
  parentAccountId: number;
  value: number;
}

export interface CdsClientExecutives {
  label: string;
  value: number;
}

export interface Contacts {
  accountId: number;
  email: string;
  label: string;
  phoneNumber: string;
  value: number;
}

export interface JobOptions {
  label: string;
  value: number;
}

export interface JobOptionsList {
  checked: boolean;
  label: string;
  value: number;
}

export interface Status {
  code: string;
  id: number;
  name: string;
}

export type JobInformation = {
  accountId: number | null;
  businessUnitId: number | null;
  cdsexecutive: number | null;
  clientReference: string | null;
  contactEmail: string | null;
  contactId: number | null;
  contactPhoneNumber: string | null;
  createdDateTime?: Date;
  directInvoice: boolean | null;
  forecastMonth: string | null;
  forecastYear: number | null;
  invoiceAddress: string | null;
  invoiceContactEmailAddress: string | null;
  invoiceContactName: string | null;
  jobId?: number;
  jobNumber?: string;
  jobOptions: number[];
  jobValue?: number;
  latestQuotationDate?: Date;
  status?: Status;
  title: string | null;
  relatedJobId?: string;
  quoteBasket: boolean | null;
  podetails?: {
    poId: number;
    poNumber: string;
  }[];
};

export interface PrintSpecification {
  title: string | null;
  description: string | null;
  reference: string | null;
  relatedLineId: string | null;
  relatedLineValue: number | null;
  quantity: number | null;
  quantityRunOn: number | null;
  noOfPages: number | null;
  visibleSize: string | null;
  finishedSizeId: number | null;
  finishedSizeOther: string | null;
  coloursOnFaceId: number | null;
  coloursOnFaceOther: string | null;
  coloursOnReverseId: number | null;
  coloursOnReverseOther: string | null;
  sealerOnFace: boolean | string;
  sealerOnReverse: boolean | string;
  orderedFlag: boolean | string;
  materialId: number | null;
  materialOther: string | null;
  materialWeightId: number | null;
  materialWeightOther: string | null;
  finishingId: number | null;
  finishingOther: string | null;
  addendum: string | null;
  deliveryContact: string | null;
  deliveryAddress: string | null;
  deliveryInstructions: string | null;
  deliveryDueDate: string | Date | null;
  noFailDeliveryDate: boolean;
  samples: boolean | string;
  noOfSampleCopies: number | null;
  sampleCopiesDeliveryAddress: string | null;
  artworkFormatId: number | null;
  artworkFormatOther: string | null;
  artworkDueDate: string | Date | null;
  typeOfProofsId: number | null;
  typeOfProofsOther: string | null;
  proofDueDate: string | Date | null;
  primaryCategoryId: number | null;
  secondaryCategoryId: number | null;
  jobItemOptions: number[];
  targetDate: string | Date | null;
  cdsNotes: string | null;
  supplierNotes: string | null;
  clientNotes: string | null;
  jobType: string;
  sealerOnCover: boolean | string;
  sealerOnText: boolean | string;
  coverMaterialId: number | null;
  coverMaterialOther: string | null;
  coverMaterialWeightId: number | null;
  coverMaterialWeightOther: string | null;
  coloursOnTextId: number | null;
  coloursOnTextOther: string | null;
  quantityActual?: number | null;
  statusName?: string;
  status: Status;
  productType?: string;
  templateId: number;
  coloursId: number | null;
  coloursOther: string | null;
  discountSummary: {
    originalCost: number;
    discountedPercentage: string;
    approvedDateTime: string;
    discountedValue: number;
    totalNetPrice: number;
    vatPercentage: string;
    vatValue: number;
    grossAmount: number;
  };
  discountDetails: {
    requestedBy: string;
    requestedDateTime: string | Date;
    clientDiscount: number | null;
    supplierDiscount: number | null;
    totalDiscount: number;
    clientDiscountPercentage: string;
    supplierDiscountPercentage: string;
    totalDiscountPercentage: string;
    newLineValue: number;
    discountReasonId: number;
    discountReasonOther: null;
    status: string;
    rejectReason: string | null;
    reviewDateTime: string | null;
    reviewedBy: string | null;
  };
  isActive: boolean;
  added: string;
  quoteDate: string | Date;
  quoteDue: string | Date;
  dueDate: string | Date;
  dueTime: string;
  lineId: string;
  laminated: boolean | string;
  mechansimId: number | null;
  mechansimOther: string | null;
  noPo: boolean;
  poDetails: {
    poNumber: string;
  };
  managementFee?: number;
  deliveryAddressChangeReasonId: number | null;
  deliveryAddressChangeReasonOther: string | null;
  //  Other Fields
  rfqjobId: number | null;
  podDate: string | Date;
  productId: number | null;
  pagesForCover: number | null;
  coloursOnInsideId: number | undefined | null;
  coloursOnInsideOther: string | null;
  coloursOnOutsideId: number | undefined | null;
  coloursOnOutsideOther: string | null;
  coloursMaterialId: number | undefined | null;
  coloursMaterialOther: string | null;
  coloursMaterialWeightId: number | undefined | null;
  coloursMaterialWeightOther: string | null;
  pagesForText: number | null;
  jobStatusId: number | undefined | null;
  productVatRateId: number;
}

type Summary = {
  cost: number;
  grossAmount: number;
  managementFeeAmount: number;
  managementfee: number;
  totalNetPrice: number;
  vat: number;
  vatAmount: number;
};

export interface ClientQuotations {
  actualSummary: Summary;
  cost: number;
  deliveryRate: number;
  deliveryRunon: number;
  grossAmount: number;
  jobQuoteId: number;
  managementFeeAmount: number;
  managementfee: number;
  materialRate: number;
  materialRunon: number;
  merchantAmount: number;
  merchantName: string;
  merchantRate: number;
  merchantRunon: number;
  productionRate: number;
  productionRunon: number;
  priceSummary: Summary;
  quantityActual: number;
  quantityStock: number;
  runonAmount: number;
  runonSummary: Summary;
  summaryRunon: number;
  supplierId: number;
  supplierName: string;
  total: number;
  totalNetPrice: number;
  vat: number;
  vatAmount: number;
}

export interface ProductItemType {
  checked: boolean;
  open: boolean;
  printSpecifications: PrintSpecification;
  clientQuotations: ClientQuotations;
}

export const initialJobInformation: JobInformation = {
  accountId: null,
  businessUnitId: null,
  cdsexecutive: null,
  clientReference: null,
  contactEmail: null,
  contactId: null,
  contactPhoneNumber: null,
  directInvoice: false,
  forecastMonth: null,
  forecastYear: null,
  invoiceAddress: null,
  invoiceContactEmailAddress: null,
  invoiceContactName: null,
  jobOptions: [],
  title: null,
  quoteBasket: null,
  podetails: [],
};

export const initialProductItems: ProductItemType[] = [];

export interface AddNewContact {
  name: string;
  emailAddress: string;
  phoneNumber: number | null;
}

export const initialAddNewContact: AddNewContact = {
  name: '',
  emailAddress: '',
  phoneNumber: null,
};
