//  Internals
import { protectedResources } from '../configs/msalAuthConfigs';

const getAccessToken = async pca => {
  const activeAccount = pca.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = pca.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }
  const request = {
    scopes: protectedResources.api.scopes,
    account: activeAccount || accounts[0],
  };

  const authResult = await pca.acquireTokenSilent(request);

  return authResult.accessToken;
};

export default getAccessToken;
