//  Externals
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Warning as WarningIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Chip } from '@mui/material';
import Form from 'react-bootstrap/Form';
//  Internals
import { selectProductItemDetailsRows } from '../../../../pages/requestForQuote/slice/selectors';
import { getJobInformation } from 'store/reducers/entities/selectors';
import MODULES from '../../../../../data/modules';
import Quotation from '../../products/Quotation';
import Accepted from './../../products/Accepted';
import STATUS from '../../../../../data/status';
import Ordered from './../../products/Ordered';
import GetProductItem from './GetProductItem';
import ProductItem from './ProductItem';
import '../style.sass';

const ProductItemDetails = props => {
  const { clientQuotations, printSpecifications } = props;

  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));

  //  Print specification
  const [showPrintSpecificationsModal, setShowPrintSpecificationsModal] = useState(false);
  //  Quotation form
  const [showQuotationModal, setShowQuotationModal] = useState(false);
  //  Accepted form
  const [showAcceptedModal, setShowAcceptedModal] = useState(false);
  //  Ordered form
  const [showOrderedModal, setShowOrderedModal] = useState(false);

  const productDetailsRow = useSelector(selectProductItemDetailsRows);
  const filteredRowByTemplateID = productDetailsRow && productDetailsRow.filter(item => printSpecifications.templateId == item.id);
  const isNoPOCheck =
    filteredRowByTemplateID && filteredRowByTemplateID.length > 0 && ['Accepted', 'Ordered', 'Delivered', null].includes(filteredRowByTemplateID[0].status) && printSpecifications.noPo;

  const handleEdit = () => {
    if ([STATUS.DRAFT, STATUS.NEW_RFQ].includes(printSpecifications.status.code)) setShowPrintSpecificationsModal(true);

    if (printSpecifications.status.code === STATUS.QUOTATION) setShowQuotationModal(true);

    if (printSpecifications.status.code === STATUS.ACCEPTED) setShowAcceptedModal(true);

    if (printSpecifications.status.code === STATUS.ORDERED) setShowOrderedModal(true);

    if (printSpecifications.status.code === STATUS.DELIVERED) setShowOrderedModal(true);
  };

  return (
    <div>
      {showPrintSpecificationsModal ? (
        <ProductItem
          create={false}
          initialState={printSpecifications}
          onHide={() => setShowPrintSpecificationsModal(false)}
          onShowProductsModal={() => setShowPrintSpecificationsModal(false)}
          productId={printSpecifications.productId}
          productType={printSpecifications.productType}
          show={showPrintSpecificationsModal}
        />
      ) : (
        <></>
      )}
      {showQuotationModal ? <Quotation initialState={printSpecifications} onHide={() => setShowQuotationModal(false)} productId={printSpecifications.productId} show={showQuotationModal} /> : <></>}
      {showAcceptedModal ? <Accepted initialState={printSpecifications} onHide={() => setShowAcceptedModal(false)} productId={printSpecifications.productId} show={showAcceptedModal} /> : <></>}
      {showOrderedModal ? <Ordered initialState={printSpecifications} onHide={() => setShowOrderedModal(false)} productId={printSpecifications.productId} show={showOrderedModal} /> : <></>}
      {isNoPOCheck && <Chip icon={<WarningIcon />} label="No PO available " className="mt-2" />}
      {isNoPOCheck && printSpecifications?.noPoComments !== null && printSpecifications?.noPoComments !== '' && (
        <div className="mt-4" style={{ width: '66%', backgroundColor: 'rgb(229, 228, 226)', padding: '12px', borderRadius: '5px' }}>
          {printSpecifications?.noPoComments}
        </div>
      )}
      <div className="readOnlyForm__header">
        <h6 className="form__subHeader top__subHeader">Print Specifications</h6>
        {printSpecifications?.isActive === 'Yes' &&
          [STATUS.DRAFT, STATUS.NEW_RFQ, STATUS.QUOTATION, STATUS.ACCEPTED, STATUS.ORDERED, STATUS.DELIVERED, 'New RFQ'].includes(printSpecifications.status.name) && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2" className="icon-tooltip">
                  Edit
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <Button
                  disabled={jobInformation?.statusName === 'Cancelled'}
                  {...triggerHandler}
                  ref={ref}
                  style={{
                    color: 'gray',
                    backgroundColor: 'white',
                    border: 'none',
                  }}
                  onClick={() => {
                    handleEdit();
                  }}
                >
                  Edit
                </Button>
              )}
            </OverlayTrigger>
          )}
      </div>
      <GetProductItem
        clientQuotations={clientQuotations}
        create={false}
        disabled={true}
        formId="printSpecificationsForm"
        initialState={printSpecifications}
        jobId={jobInformation.jobId}
        onHide={() => {}}
        productId={printSpecifications.productId}
        productType={printSpecifications.productType}
        statusName={printSpecifications.status.code}
      />
    </div>
  );
};

export default ProductItemDetails;
