import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { userDataSaga } from './saga';
import { userDataState } from './types';

export const initialState: userDataState = {
  isLoading: false,
  error: null,
  userData: {
    isSuccess: false,
    message: null,
    statusCode: null,
    validationErrors: [],
    data: {
      items: [],
    },
  },
};

const slice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    loadUserData(state) {
      state.isLoading = true;
    },
    userDataLoaded(state, action: PayloadAction<any>) {
      state.userData = action.payload;
      state.isLoading = false;
    },
    userDataError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
  },
});

export const { actions: userDataActions, reducer } = slice;

export const useUserDataSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: userDataSaga });
  return { actions: slice.actions };
};
