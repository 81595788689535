//  Externals
import { Paper, TableCell, TableContainer, TableRow, TableHead, TableBody } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Table from 'react-bootstrap/Table';
//  Internals
import { ReactComponent as AddProductItemIcon } from '../../../../images/addProductItem.svg';
import { useBreadcrumbSlice } from '../../breadcrumb/slice';
import { getSuppliers } from './slice/selector';
import { useAdminSuppliersSlice } from './slice';
import AddSupplier from './AddSupplierDetails';
import EditSupplier from './EditSupplier';
import Breadcrumb from '../../breadcrumb';
import { initialSupplier } from './types';

const AdminSuppliers = () => {
  const dispatch = useDispatch();
  const suppliers = useSelector(getSuppliers);

  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  useEffect(() => {
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Dashboard', url: '/dashboard' },
        { name: 'Administration/Suppliers', url: '/administration/suppliers' },
      ]),
    );
  }, [breadcrumbActions, dispatch]);

  const { actions } = useAdminSuppliersSlice();
  useEffect(() => {
    dispatch(actions.loadSupplierList());
    dispatch(actions.loadGeoLocations());
    dispatch(actions.loadSupplierCategories());
  }, [actions, dispatch]);

  const [showEditSupplierModal, setShowEditSupplierModal] = useState(false);
  const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);
  const [supplier, setSupplier] = useState(initialSupplier);

  /******************************************************************************************
  @Purpose    :  Update Supplier User
  *******************************************************************************************/
  const handleUpdate = entity => {
    setShowEditSupplierModal(true);
    setSupplier(entity);
  };

  return (
    <div>
      <Helmet>
        <title>Administration Supplier</title>
      </Helmet>
      <Breadcrumb />
      <div className="rfq__container">
        <div className="products__page">
          <div className="productsPage__header">
            <h4>Suppliers ({suppliers.length})</h4>
            <span
              className="addAccount__button"
              onClick={() => {
                setShowAddSupplierModal(true);
              }}
            >
              Add Supplier
              <AddProductItemIcon className="svg__icon" />
            </span>
          </div>
          <div className="products__content">
            <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
              <Table aria-label="collapsible table" className="productItem__details__table">
                <TableHead>
                  <TableRow style={{ height: '3rem' }}>
                    <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '2rem' }} sx={{ paddingLeft: 0 }} align="left">
                      Supplier ID
                    </TableCell>
                    <TableCell className="productItem__details__headerCell">Name</TableCell>
                    <TableCell className="productItem__details__headerCell">Email</TableCell>
                    <TableCell className="productItem__details__headerCell">Telephone</TableCell>
                    <TableCell className="productItem__details__headerCell">Status</TableCell>
                    <TableCell className="productItem__details__headerCell">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {suppliers?.map(supplier => (
                    <Fragment key={supplier.supplierId}>
                      <TableRow sx={{ height: '4px' }}></TableRow>
                      <TableRow
                        className="productItem__details__row"
                        onClick={() => {
                          handleUpdate(supplier);
                        }}
                        style={{ cursor: 'pointer', height: '2.5rem' }}
                      >
                        <TableCell className="productItem__details__bodyCell" style={{ paddingLeft: '25px' }} sx={{ paddingLeft: 0 }} align="left">
                          {supplier.supplierId}
                        </TableCell>
                        <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                          {supplier.supplierName}
                        </TableCell>
                        <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                          {supplier.contactEmailAddress}
                        </TableCell>
                        <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                          {supplier.telephone}
                        </TableCell>
                        <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                          {supplier.isActive === true ? 'Active' : 'Inactive'}
                        </TableCell>
                        <TableCell className="productItem__details__bodyCell table__edit" sx={{ paddingLeft: 0 }}>
                          <span style={{ color: '#1BA39C', marginRight: '20px' }}>Edit</span>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      {showAddSupplierModal && <AddSupplier show={showAddSupplierModal} onHide={() => setShowAddSupplierModal(false)} />}
      {showEditSupplierModal && <EditSupplier show={showEditSupplierModal} onHide={() => setShowEditSupplierModal(false)} supplier={supplier} />}
    </div>
  );
};

export default AdminSuppliers;
