const errors = {
  EMAIL: 'Please enter an email address',
  FIRST_NAME: 'Please enter a first name',
  LAST_NAME: 'Please enter a last name',
  LINE_TITLE: 'Please provide a Line Title.',
  NAME: 'Please provide Name',
  SELECT_ACCOUNT: 'Please select an account',
  SELECT_BUSINESS_UNIT: 'Please select at least one business unit',
  SELECT_CDS_CLIENT_EXECUTIVE: 'Please select a CDS Client Executive',
  SELECT_CONTACT: 'Please select Contact Name',
  SELECT_RECEIVE_REMINDERS: 'Please Select Receive Reminders',
  SELECT_ROLE: 'Please select a role',
  TELEPHONE_NUMBER: 'Please enter a telephone number',
  TITLE: 'Please provide a Title.',
  VALID_EMAIL: 'Please enter a valid email address',
  VALID_TELEPHONE_NUMBER: 'Please enter a valid telephone number',
};

export default errors;
