import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { TableCell } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import { Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useRequestForQuoteSlice } from '../slice';
import { useParamSelector, selectSupplierDataCheckboxesArray, selectSupplierDataOpenArray, selectProductItemDetailsRows } from '../slice/selectors';
import SupplierDataForm from './SupplierDataForm';
import { ReactComponent as NotesIcon } from './../../../../images/notes.svg';
import { useParams } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { useSelector } from 'react-redux';

function Row(props: {
  row: any;
  open: Function;
  checked: Function;
  deleteRow: Function;
  cloneRow: Function;
  rowid: any;
  minTotal: any;
  productType: any;
  checkedQuotesList: any;
  title: any;
  isActive: any;
  supplierPo: any;
  isClientQuotations: any;
  supplierNotes: any;
}) {
  const { row, open, checked, deleteRow, cloneRow, rowid, minTotal, productType, checkedQuotesList, title, supplierPo, isClientQuotations, isActive } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const moment = new MomentUtils();
  const openMenu = Boolean(anchorEl);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const data = useSelector(selectProductItemDetailsRows);
  const supplierFormData = data
    ?.filter(row => row.id == rowid)[0]
    .details.jobQuoteSummaryDetails.filter(item => item?.jobQuoteInfoSummaryDetails?.jobQuoteId == row.jobQuoteId)[0]?.jobQuoteInfoSummaryDetails;
  return (
    <React.Fragment>
      <TableRow sx={{ height: '4px' }}></TableRow>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
        }}
        hover={true}
        selected={row.checked}
        onClick={event => open(event)}
        className={`productItem__details__row ${row.open && 'open'}`}
      >
        <TableCell className="quotes__details__bodyCell first__column" style={{ width: '5.5rem' }}>
          {/* show the below line based on lowest minimum total value */}
          {minTotal == row.totalRate && <div className="quote__highlight"></div>}
          <IconButton aria-label="expand row" size="small">
            {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
          <Checkbox
            sx={{
              color: '#8D8C8D',
              '&.Mui-checked': {
                color: '#1BA39C',
              },
            }}
            id="supplierList-row-id"
            disabled={(checkedQuotesList.length > 0 && !row.checked) || !isClientQuotations}
            checked={row.checked}
            onChange={() => checked()}
          />
        </TableCell>
        <TableCell
          className="quotes__details__bodyCell"
          sx={{
            paddingLeft: 0,
          }}
        >
          {row.supplierName}
        </TableCell>
        <TableCell className="quotes__details__bodyCell">{row?.quantityActual ? row?.quantityActual : row?.quantity}</TableCell>
        <TableCell className="quotes__details__bodyCell text-end pe-2">{row?.productionRate != null ? row.productionRate?.toFixed(2) : '-'}</TableCell>
        <TableCell className="quotes__details__bodyCell text-end pe-2">{row?.materialRate != null ? row?.materialRate?.toFixed(2) : '-'}</TableCell>
        <TableCell className="quotes__details__bodyCell text-end pe-2">{row?.deliveryRate != null ? row?.deliveryRate?.toFixed(2) : '-'}</TableCell>
        <TableCell className="quotes__details__bodyCell text-end pe-2">{row?.totalRate != null ? row?.totalRate?.toFixed(2) : '-'}</TableCell>
        <TableCell className="quotes__details__bodyCell text-center">
          {supplierFormData.supplierQuoteNotes !== null && supplierFormData.supplierQuoteNotes !== '' && <NotesIcon className="notes__icon" />}
        </TableCell>
        <TableCell className="quotes__details__bodyCell">{row?.statusName === '' ? '-' : row?.statusName}</TableCell>
        <TableCell className="quotes__details__bodyCell">
          {row && row.dueDate && row.dueTime ? ` ${moment.date(row.dueDate).format('DD/MM/YYYY')} ${row.dueTime.split(':').splice(0, 2).join(':')}  ` : '-'}
          {/* {row?.dueDate == '' ? '-' : moment(row?.dueDate).format('DD MMM YYYY')} */}
        </TableCell>
        {/* <TableCell
          className="quotes__details__bodyCell"
          align="center"
          style={{ paddingRight: '1rem' }}
        >
          <div>
            <IconButton
              id="fade-button"
              aria-controls={openMenu ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onClick={handleMenu}
              color="inherit"
              size="small"
            >
              <KebabMenuIcon />
            </IconButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              PaperProps={{ sx: { width: '101px' } }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  deleteRow();
                }}
              >
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  cloneRow();
                }}
              >
                Clone
              </MenuItem>
            </Menu>
          </div>
        </TableCell> */}
      </TableRow>
      {row.open && (
        <TableRow>
          <TableCell className="quotes__details__subContainer quotes__details__bodyCell" colSpan={12}>
            <Collapse in={row.open} timeout="auto" unmountOnExit>
              <Box>
                <div className="quotes__details__separator"></div>
                <div style={{ margin: '0rem 1rem 0rem 1rem' }}>
                  <SupplierDataForm jobQuoteId={row.jobQuoteId} rowid={rowid} productType={productType} statusName={row.statusName} title={title} supplierPo={supplierPo} isActive={isActive} />
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

const selectedIDQuotes: any = [];
const selectedProductIDQuotes: any = [];

export const SelectedSuppliersList = props => {
  const { jobId } = useParams();
  const copyRFQID: any = jobId;
  const { supplierRowDetails, isClientQuotations, supplierNotes, isActive } = props;
  const supplierDetailsisChecked = supplierRowDetails.filter(item => item.checked == true);
  const getMinTotal = Math.min(...supplierRowDetails.map(item => item.totalRate));
  const checkedQuotesList = supplierRowDetails.filter(item => item.checked);
  if (supplierDetailsisChecked.length == 0) {
    localStorage.removeItem('clientQuoteConfirmationID');
    localStorage.removeItem('rfqJobId');
    localStorage.removeItem('productID');
  }

  const checkedArray = useParamSelector(selectSupplierDataCheckboxesArray, props.rowid);
  const openArray = useParamSelector(selectSupplierDataOpenArray, props.rowid);
  const dispatch = useDispatch();
  const { actions } = useRequestForQuoteSlice();
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const openHandler = id => {
    dispatch(actions.changeOpenForSupplierRowArray({ rowId: props.rowid, id: id }));
    const openArr = supplierRowDetails.map(item => (item.supplierId === id ? !item.open : item.open));
    const flag = new Set(openArr).size === 1 ? openArr[0] : null;
    setOpen(() => (flag !== null ? flag : false));
  };

  const checkedHandler = id => {
    selectedIDQuotes.push(id);
    localStorage.setItem('clientQuoteConfirmationID', selectedIDQuotes);
    localStorage.setItem('rfqJobId', copyRFQID);
    localStorage.setItem('productID', props.rowid);
    selectedProductIDQuotes.push(props.rowid);
    localStorage.setItem('selectedProductID', selectedProductIDQuotes);
    dispatch(actions.changeCheckedForSupplierRowArray({ rowId: props.rowid, id: id }));
    const checkedArr = supplierRowDetails.map(item => (item.supplierId === id ? !item.checked : item.checked));
    const flag = new Set(checkedArr).size === 1 ? checkedArr[0] : null;
    setChecked(() => (flag !== null ? flag : false));
  };

  const deleteRowHandler = id => {};

  const cloneRowHandler = id => {};

  const onChangeHandler = flag => {
    dispatch(
      actions.masterSupplierDataOnChange({
        flag,
        rowId: props.rowid,
        open,
        checked,
      }),
    );
  };

  useEffect(() => {
    dispatch(actions.loadVatRateDetails());
  }, []);

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: '0.5rem',
        marginBottom: '2rem',
        borderRadius: '0',
        boxShadow: 'none',
      }}
    >
      <Table aria-label="collapsible table">
        <TableHead sx={{ backgroundColor: '#acacac' }}>
          <TableRow>
            <TableCell className="quotes__details__headerCell" style={{ paddingLeft: '4px', width: '5.5rem' }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpen(!open);
                  onChangeHandler(true);
                }}
              >
                {new Set(openArray).size === 2 ? <RemoveIcon /> : open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
              <Checkbox
                sx={{
                  color: '#8D8C8D',
                  '&.Mui-checked': {
                    color: '#1BA39C',
                  },
                }}
                indeterminate={new Set(checkedArray).size === 2 ? true : false}
                indeterminateIcon={<IndeterminateCheckBox sx={{ color: '#1BA39C' }} />}
              />
            </TableCell>
            <TableCell className="quotes__details__headerCell" sx={{ paddingLeft: 0 }} align="left">
              Supplier Name
            </TableCell>
            <TableCell className="quotes__details__headerCell">Quantity</TableCell>
            <TableCell className="quotes__details__headerCell text-end pe-2">Production (&#163;)</TableCell>
            <TableCell className="quotes__details__headerCell text-end pe-2">Material(&#163;)</TableCell>
            <TableCell className="quotes__details__headerCell text-end pe-2">Delivery(&#163;)</TableCell>
            <TableCell className="quotes__details__headerCell text-end pe-2">Total(&#163;)</TableCell>
            <TableCell className="quotes__details__headerCell text-center">Notes</TableCell>
            <TableCell className="quotes__details__headerCell">Status</TableCell>
            <TableCell className="quotes__details__headerCell">Quote Due Date</TableCell>
            {/* <TableCell
              className="quotes__details__headerCell"
              align="center"
              style={{ paddingRight: '1rem' }}
            >
              Actions
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {supplierRowDetails &&
            supplierRowDetails.map(row => {
              return (
                <Row
                  key={row.supplierId}
                  row={row}
                  open={event => {
                    if (event.target.id === 'supplierList-row-id') {
                      event.stopPropagation();
                    } else {
                      openHandler(row.supplierId);
                    }
                  }}
                  checked={() => checkedHandler(row.jobQuoteId)}
                  deleteRow={() => deleteRowHandler(row.supplierId)}
                  cloneRow={() => cloneRowHandler(row.supplierId)}
                  rowid={props.rowid}
                  title={props.title}
                  isActive={isActive}
                  supplierPo={props.supplierPo}
                  minTotal={getMinTotal}
                  productType={props.productType}
                  checkedQuotesList={checkedQuotesList}
                  isClientQuotations={isClientQuotations}
                  supplierNotes={supplierNotes}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
