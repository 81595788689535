import React from 'react';
import { useSelector } from 'react-redux';
import DashboardLayout from '../layout/index';
import { isLoading, selectDashboardCards } from '../slice/selectors';
import LoadingSpinner from '../../../components/navigation/LoadingSpinner';

const InternalCdsDashboard = props => {
  const { userRoles } = props;
  const data = useSelector(selectDashboardCards);
  const isLoader = useSelector(isLoading);
  const c1 = { onHold: '#3e1951', toInvoice: '#8c41b2', quotation: '#b764e1', ordered: '#cda5e1' };

  return (
    <>
      {isLoader ? (
        <div style={{ position: 'absolute', top: '50%', width: '100%' }}>
          <LoadingSpinner color="#000" />
        </div>
      ) : (
        <DashboardLayout data={data} userRoles={userRoles} c1={c1} />
      )}
    </>
  );
};

export default InternalCdsDashboard;
