export interface MyJobsProps {
  create: boolean;
}

export interface JobInformationProps {
  create: boolean;
}

export interface DisabledFields {
  accountId: boolean;
  businessUnitId: boolean;
  cdsexecutive: boolean;
  clientReference: boolean;
  contactEmail: boolean;
  contactId: boolean;
  contactPhoneNumber: boolean;
  directInvoice: boolean;
  forecastMonth: boolean;
  forecastYear: boolean;
  invoiceAddress: boolean;
  invoiceContactEmailAddress: boolean;
  invoiceContactName: boolean;
  jobOptions: boolean;
  title: boolean;
}

export const initialDisabledFields: DisabledFields = {
  accountId: false,
  businessUnitId: false,
  cdsexecutive: false,
  clientReference: false,
  contactEmail: false,
  contactId: false,
  contactPhoneNumber: false,
  directInvoice: false,
  forecastMonth: false,
  forecastYear: false,
  invoiceAddress: false,
  invoiceContactEmailAddress: false,
  invoiceContactName: false,
  jobOptions: false,
  title: false,
};
