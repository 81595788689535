import React, { useEffect, useState } from 'react';

import { selectProductItemDetailsRows, EditSupplierQuoteData, getPoDetails, useParamSelector } from '../slice/selectors';
import { getMasterData } from '../../../../store/reducers/masterData/selectors';
import { getJobInformation } from 'store/reducers/entities/selectors';
import { getRowsOfTextArea } from './../../../../utils/form/index';
import EditSupplierQuote from '../quotes/EditSupplierQuote';
import MODULES from '../../../../data/modules';
import { Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MomentUtils from '@date-io/moment';
import './style.sass';

const SupplierDataForm = props => {
  const { jobQuoteId, rowid, productType, statusName, title, supplierPo, isActive } = props;
  const moment = new MomentUtils();
  const data = useSelector(selectProductItemDetailsRows);
  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));
  const poDetails = useParamSelector(getPoDetails, rowid);
  const masterDataDecline = useSelector(getMasterData('supplierQuoteDeclineReasons'));
  const [reasonForDeclining, setReasonForDeclining] = useState<any>();
  const [supplierQuoteNotesLinesCounter, setSupplierQuoteNotesLinesCounter] = useState(0);
  const [supplierQuoteNotesCounter, setSupplierQuoteNotesCounter] = useState(0);
  const [quoteNotesLinesCounter, setQuoteNotesLinesCounter] = useState(0);
  const [quoteNotesCounter, setQuoteNotesCounter] = useState(0);
  const supplierFormData = data
    ?.filter(row => row.id == rowid)[0]
    .details.jobQuoteSummaryDetails.filter(item => item?.jobQuoteInfoSummaryDetails?.jobQuoteId == jobQuoteId)[0]?.jobQuoteInfoSummaryDetails;
  const [modalShow, setModalShow] = useState(false);
  const editSupplierSubmittedData = useSelector(EditSupplierQuoteData);
  const isEditSupplierSubmittedDataEmpty = editSupplierSubmittedData && Object.keys(editSupplierSubmittedData).length === 0 && Object.getPrototypeOf(editSupplierSubmittedData) === Object.prototype;

  useEffect(() => {
    if (supplierFormData?.cancellationReasonId) {
      const declinedRow = masterDataDecline?.filter(item => item.value === supplierFormData?.cancellationReasonId && item?.label);
      const declineLabel = declinedRow?.map(item => item.label);
      setReasonForDeclining(declineLabel);
    }
    if (supplierFormData?.cancellationReasonOther) {
      setReasonForDeclining(supplierFormData?.cancellationReasonOther);
    }
  }, []);

  useEffect(() => {
    let supplierQuoteNotes = '';
    if (!isEditSupplierSubmittedDataEmpty) {
      if (editSupplierSubmittedData?.supplierQuoteNotes == null) supplierQuoteNotes = '-';
      else supplierQuoteNotes = editSupplierSubmittedData?.supplierQuoteNotes;
    } else {
      if (supplierFormData?.supplierQuoteNotes == null) supplierQuoteNotes = '-';
      else supplierQuoteNotes = supplierFormData?.supplierQuoteNotes;
    }
    setSupplierQuoteNotesLinesCounter(supplierQuoteNotes?.length ? supplierQuoteNotes.split('\n').length : 0);
    setSupplierQuoteNotesCounter(supplierQuoteNotes?.length ? supplierQuoteNotes.length : 0);

    let quoteNotes = '';
    if (!isEditSupplierSubmittedDataEmpty) {
      if (editSupplierSubmittedData?.notes == null) quoteNotes = '-';
      else quoteNotes = editSupplierSubmittedData?.notes;
    } else {
      if (supplierFormData?.notes == null) quoteNotes = '-';
      else quoteNotes = supplierFormData?.notes;
    }
    setQuoteNotesLinesCounter(quoteNotes?.length ? quoteNotes.split('\n').length : 0);
    setQuoteNotesCounter(quoteNotes?.length ? quoteNotes.length : 0);
  }, [editSupplierSubmittedData?.notes, editSupplierSubmittedData?.supplierQuoteNotes, isEditSupplierSubmittedDataEmpty, supplierFormData?.notes, supplierFormData?.supplierQuoteNotes]);
  const quantityRunOn = data?.filter(row => row.id == rowid)[0].details.printSpecifications.form.quantityRunOn;
  const disableEditSupplierQuoteBtn =
    jobInformation?.statusName === 'Cancelled' || isActive === 'No' || ['Ordered', 'Declined', 'Dispatched', 'Invoice received', 'Paid', 'Cancelled'].includes(statusName);

  return (
    <>
      <div className="supplierData__form">
        {supplierFormData?.cancellationReasonId || supplierFormData?.cancellationReasonOther ? (
          <div>
            <span>Reason For Declining</span>
            <div>
              <Form.Control autoComplete="off" as="textarea" className="supplier__note__textArea" rows={3} disabled value={reasonForDeclining && reasonForDeclining} />
            </div>
          </div>
        ) : (
          <div>
            {data?.filter(row => row.id == rowid)[0].details?.clientQuotations === null && (
              <Button variant="outline-primary" className="outline__button edit_supplier_quote" onClick={() => setModalShow(true)} disabled={disableEditSupplierQuoteBtn}>
                Edit Supplier Quote
              </Button>
            )}
            {['New RFQ', 'Quoted', 'Declined'].indexOf(statusName) == -1 && (
              <div>
                <span>PO number</span>

                <Form.Control autoComplete="off" disabled value={supplierPo} />
              </div>
            )}
            {supplierFormData.supplierQuoteNotes !== null && supplierFormData.supplierQuoteNotes !== '' && (
              <div>
                <span>Supplier Note</span>
                <div>
                  <Form.Control
                    autoComplete="off"
                    as="textarea"
                    className="supplier__note__textArea"
                    rows={getRowsOfTextArea(supplierQuoteNotesCounter, supplierQuoteNotesLinesCounter)}
                    disabled
                    value={
                      !isEditSupplierSubmittedDataEmpty
                        ? editSupplierSubmittedData?.supplierQuoteNotes == null
                          ? '-'
                          : editSupplierSubmittedData?.supplierQuoteNotes
                        : supplierFormData?.supplierQuoteNotes == null
                        ? '-'
                        : supplierFormData?.supplierQuoteNotes
                    }
                  />
                </div>
              </div>
            )}
            <div>
              <span>Supplier Reference</span>
              <div>
                <Form.Control
                  autoComplete="off"
                  className="supplier__note__textArea"
                  disabled
                  value={
                    !isEditSupplierSubmittedDataEmpty
                      ? editSupplierSubmittedData?.reference == null
                        ? '-'
                        : editSupplierSubmittedData?.reference
                      : supplierFormData?.reference == null
                      ? '-'
                      : supplierFormData?.reference
                  }
                />
              </div>
            </div>
            <div>
              <span>Pricing Information</span>
              <div>
                <table className="w-500">
                  <thead>
                    <tr>
                      <th style={{ width: '25%', fontSize: '12px', textAlign: 'right' }}>Production (&#163;)</th>
                      <th style={{ width: '20%', fontSize: '12px', textAlign: 'right' }}>Material (&#163;)</th>
                      <th style={{ width: '20%', fontSize: '12px', textAlign: 'right' }}>Delivery (&#163;)</th>
                      <th style={{ width: '25%', fontSize: '12px', textAlign: 'right' }}>Total (&#163;)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-end">
                        {!isEditSupplierSubmittedDataEmpty
                          ? editSupplierSubmittedData?.productionRate == null
                            ? '-'
                            : editSupplierSubmittedData?.productionRate?.toFixed(2)
                          : supplierFormData?.productionRate == null
                          ? '-'
                          : supplierFormData?.productionRate?.toFixed(2)}
                      </td>
                      <td className="text-end">
                        {!isEditSupplierSubmittedDataEmpty
                          ? editSupplierSubmittedData?.materialRate == null
                            ? '-'
                            : editSupplierSubmittedData?.materialRate?.toFixed(2)
                          : supplierFormData?.materialRate == null
                          ? '-'
                          : supplierFormData?.materialRate?.toFixed(2)}
                      </td>
                      <td className="text-end">
                        {!isEditSupplierSubmittedDataEmpty
                          ? editSupplierSubmittedData?.deliveryRate == null
                            ? '-'
                            : editSupplierSubmittedData?.deliveryRate?.toFixed(2)
                          : supplierFormData?.deliveryRate == null
                          ? '-'
                          : supplierFormData?.deliveryRate?.toFixed(2)}
                      </td>
                      <td className="text-end">
                        {!isEditSupplierSubmittedDataEmpty
                          ? editSupplierSubmittedData?.totalRate == null
                            ? '-'
                            : editSupplierSubmittedData?.totalRate?.toFixed(2)
                          : supplierFormData?.totalRate == null
                          ? '-'
                          : supplierFormData?.totalRate?.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {quantityRunOn != null && (
              <div>
                <span>Runon Information</span>
                <div>
                  <table className="w-500">
                    <thead>
                      <tr>
                        <th style={{ width: '25%', fontSize: '11px', textAlign: 'right' }}>Production Runon (&#163;)</th>
                        <th style={{ width: '20%', fontSize: '11px', textAlign: 'right' }}>Material Runon (&#163;)</th>
                        <th style={{ width: '20%', fontSize: '11px', textAlign: 'right' }}>Delivery Runon (&#163;)</th>
                        <th style={{ width: '25%', fontSize: '11px', textAlign: 'right' }}>Total Runon (&#163;)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-end">
                          {!isEditSupplierSubmittedDataEmpty
                            ? editSupplierSubmittedData?.productionRunon == null
                              ? '-'
                              : supplierFormData?.productionRunon?.toFixed(2)
                            : supplierFormData?.productionRunon == null
                            ? '-'
                            : supplierFormData?.productionRunon?.toFixed(2)}
                        </td>
                        <td className="text-end">
                          {!isEditSupplierSubmittedDataEmpty
                            ? editSupplierSubmittedData?.materialRunon == null
                              ? '-'
                              : editSupplierSubmittedData?.materialRunon?.toFixed(2)
                            : supplierFormData?.materialRunon == null
                            ? '-'
                            : supplierFormData?.materialRunon?.toFixed(2)}
                        </td>
                        <td className="text-end">
                          {!isEditSupplierSubmittedDataEmpty
                            ? editSupplierSubmittedData?.deliveryRunon == null
                              ? '-'
                              : editSupplierSubmittedData?.deliveryRunon?.toFixed(2)
                            : supplierFormData?.deliveryRunon == null
                            ? '-'
                            : supplierFormData?.deliveryRunon?.toFixed(2)}
                        </td>
                        <td className="text-end">
                          {!isEditSupplierSubmittedDataEmpty
                            ? editSupplierSubmittedData?.totalRunon == null
                              ? '-'
                              : editSupplierSubmittedData?.totalRunon?.toFixed(2)
                            : supplierFormData?.totalRunon == null
                            ? '-'
                            : supplierFormData?.totalRunon?.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <Form.Group className="mb-3" controlId="vatRate">
              <Form.Label>Vat Rate</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                name="vatName"
                plaintext
                disabled
                value={
                  !isEditSupplierSubmittedDataEmpty
                    ? editSupplierSubmittedData?.vatName == null
                      ? '-'
                      : editSupplierSubmittedData?.vatName
                    : supplierFormData?.vatName == null
                    ? '-'
                    : supplierFormData?.vatName
                }
                placeholder="Enter here"
              />
            </Form.Group>
            {['New RFQ', 'Quoted', 'Declined'].indexOf(statusName) == -1 && (
              <div>
                <span className="title_supp">Actual Summary Information</span>
                <div>
                  <table className="table_information table">
                    <thead className="thead-light">
                      <tr>
                        <th>Quantity</th>
                        <th className="text-end">Net Amount</th>
                        <th className="text-end">VAT</th>
                        <th className="text-end">Gross Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{supplierFormData?.quantityActual != null ? supplierFormData?.quantityActual : '-'}</td>
                        <td className="text-end">
                          {supplierFormData?.supplierActualDiscountSummary?.totalNetPrice != null ? supplierFormData?.supplierActualDiscountSummary?.totalNetPrice.toFixed(2) : '-'}
                        </td>
                        <td className="text-end">
                          {supplierFormData?.supplierActualDiscountSummary?.vatValue != null ? supplierFormData?.supplierActualDiscountSummary?.vatValue.toFixed(2) : '0.00'}
                        </td>
                        <td className="text-end">
                          {supplierFormData?.supplierActualDiscountSummary?.grossAmount != null ? supplierFormData?.supplierActualDiscountSummary?.grossAmount.toFixed(2) : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {supplierFormData?.supplierActualDiscountSummary.discountedValue != null && supplierFormData?.supplierActualDiscountSummary.discountedValue != 0 && (
              <div>
                <span className="title_supp">Discounts</span>
                <div>
                  <table className="table_information table">
                    <thead className="thead-light">
                      <tr>
                        <th className="text-end pe-2">Original Amount</th>
                        <th className="text-end pe-3">Discounted Amount</th>
                        <th className="pe-2">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td className="text-end pe-2">
                        {supplierFormData?.supplierActualDiscountSummary.originalCost != null ? supplierFormData?.supplierActualDiscountSummary.originalCost?.toFixed(2) : '-'}
                      </td>
                      <td className="text-end pe-3">
                        {supplierFormData?.supplierActualDiscountSummary.discountedValue != null ? supplierFormData?.supplierActualDiscountSummary.discountedValue.toFixed(2) : '-'}
                      </td>
                      <td className="pe-2">
                        {supplierFormData?.supplierActualDiscountSummary.approvedDateTime != null
                          ? `${moment.date(supplierFormData?.supplierActualDiscountSummary.approvedDateTime).format('DD/MM/YYYY')}`
                          : '-'}
                      </td>
                    </tbody>
                  </table>
                </div>
                <br />
              </div>
            )}
            <div>
              <span>Material Information</span>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '16%', fontSize: '11px' }}>Material Supplier</th>
                      <th style={{ width: '15%', fontSize: '11px' }}>Material Quantity</th>
                      <th className="text-end" style={{ width: '15%', fontSize: '11px' }}>
                        Merchant Rebate(&#163;)
                      </th>
                      <th className="text-end" style={{ width: '19%', fontSize: '11px' }}>
                        Merchant Runon Rebate (&#163;)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {!isEditSupplierSubmittedDataEmpty
                          ? editSupplierSubmittedData?.merchantSupplier == null
                            ? '-'
                            : editSupplierSubmittedData?.merchantSupplier
                          : supplierFormData?.merchantSupplier == null
                          ? '-'
                          : supplierFormData?.merchantSupplier}
                      </td>
                      <td>
                        {!isEditSupplierSubmittedDataEmpty
                          ? editSupplierSubmittedData?.materialQuantity == null
                            ? '-'
                            : editSupplierSubmittedData?.materialQuantity
                          : supplierFormData?.materialQuantity == null
                          ? '-'
                          : supplierFormData?.materialQuantity}
                      </td>
                      <td className="text-end">
                        {!isEditSupplierSubmittedDataEmpty
                          ? editSupplierSubmittedData?.cdsMerchantRate == null
                            ? '-'
                            : editSupplierSubmittedData?.cdsMerchantRate?.toFixed(2)
                          : supplierFormData?.cdsMerchantRate == null
                          ? '-'
                          : supplierFormData?.cdsMerchantRate?.toFixed(2)}
                      </td>
                      <td className="text-end">
                        {!isEditSupplierSubmittedDataEmpty
                          ? editSupplierSubmittedData?.cdsMerchantRunon == null
                            ? '-'
                            : editSupplierSubmittedData?.cdsMerchantRunon?.toFixed(2)
                          : supplierFormData?.cdsMerchantRunon == null
                          ? '-'
                          : supplierFormData?.cdsMerchantRunon?.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {editSupplierSubmittedData.merchantSupplier?.toLowerCase().trim() === 'other' || supplierFormData.merchantSupplier?.toLowerCase().trim() === 'other' ? (
                  <Form.Text className="text-danger">The supplier is not using a CDS materials merchant</Form.Text>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div>
              <span>Material</span>
              <div>
                <Form.Control
                  autoComplete="off"
                  as="textarea"
                  rows={
                    !isEditSupplierSubmittedDataEmpty
                      ? getRowsOfTextArea(editSupplierSubmittedData?.material?.length, editSupplierSubmittedData?.material?.split('\n').length)
                      : getRowsOfTextArea(supplierFormData?.material?.length, supplierFormData?.material?.split('\n').length)
                  }
                  disabled
                  value={
                    !isEditSupplierSubmittedDataEmpty
                      ? editSupplierSubmittedData?.material == null
                        ? '-'
                        : editSupplierSubmittedData?.material
                      : supplierFormData?.material == null
                      ? '-'
                      : supplierFormData?.material
                  }
                />
              </div>
            </div>
            <div>
              <span>Schedule Information</span>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Proof Days</th>
                      <th>Delivery Days</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {!isEditSupplierSubmittedDataEmpty
                          ? editSupplierSubmittedData?.proofDays == null
                            ? '-'
                            : editSupplierSubmittedData?.proofDays
                          : supplierFormData?.proofDays == null
                          ? '-'
                          : supplierFormData?.proofDays}
                      </td>
                      <td>
                        {!isEditSupplierSubmittedDataEmpty
                          ? editSupplierSubmittedData?.deliveryDays == null
                            ? '-'
                            : editSupplierSubmittedData?.deliveryDays
                          : supplierFormData?.deliveryDays == null
                          ? '-'
                          : supplierFormData?.deliveryDays}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <span>Additional Information</span>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Quote Date</th>
                      <th>Quote Time</th>
                      <th>Quote Due Date</th>
                      <th>Quote Due Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{supplierFormData?.quoteDate == null ? '-' : `${moment.date(supplierFormData?.quoteDate).format('DD/MM/YYYY')}`}</td>
                      <td>{supplierFormData?.quoteTime == null ? '-' : `${supplierFormData?.quoteTime.split(':').splice(0, 2).join(':')}`}</td>
                      <td>{supplierFormData?.dueDate == null ? '-' : `${moment.date(supplierFormData?.dueDate).format('DD/MM/YYYY')}`}</td>
                      <td>{supplierFormData?.dueTime == null ? '-' : `${supplierFormData?.dueTime.split(':').splice(0, 2).join(':')}`}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <span>Quote Instructions</span>
              <div>
                <Form.Control
                  autoComplete="off"
                  as="textarea"
                  rows={getRowsOfTextArea(quoteNotesCounter, quoteNotesLinesCounter)}
                  disabled
                  value={
                    !isEditSupplierSubmittedDataEmpty
                      ? editSupplierSubmittedData?.notes == null
                        ? '-'
                        : editSupplierSubmittedData?.notes
                      : supplierFormData?.notes == null
                      ? '-'
                      : supplierFormData?.notes
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <EditSupplierQuote show={modalShow} title={title} onHide={() => setModalShow(false)} jobQuoteId={jobQuoteId} rowid={rowid} productType={productType} quantityRunOn={quantityRunOn} />
    </>
  );
};

export default SupplierDataForm;
