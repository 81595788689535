//  Externals
import { createSelector } from '@reduxjs/toolkit';
//  Internals
import { RootState } from '../../../../../store/model';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.auth || initialState;

export const selectFirstName = createSelector([selectSlice], menuState => menuState.firstName);
export const getUserId = createSelector([selectSlice], menuState => menuState.userId);
export const userInfo = createSelector([selectSlice], menuState => menuState);
export const selectLastName = createSelector([selectSlice], menuState => menuState.lastName);
export const selectError = createSelector([selectSlice], menuState => menuState.error);
export const selectIsLoading = createSelector([selectSlice], menuState => menuState.isLoading);

export const getRoleName = createSelector([selectSlice], menuState => menuState.roleName);

export const selectModules = createSelector([selectSlice], menuState => menuState.modules);

export const selectEmailAddress = createSelector([selectSlice], menuState => menuState.emailAddress);

export const getRole = createSelector([selectSlice], menuState => menuState.role);

export const getAccountId = createSelector([selectSlice], menuState => menuState.accountId);

export const getIsCDS = createSelector([selectSlice], menuState => menuState.isCDS);
export const getIsClient = createSelector([selectSlice], menuState => menuState.isClient);
export const getIsSupplier = createSelector([selectSlice], menuState => menuState.isSupplier);

export const selectNotificationCountModules = createSelector([selectSlice], menuState => menuState.notificationCountModules);

export const selectAdministrationSubMenu = createSelector([selectSlice], menuState => menuState.administrationSubMenu);

export const selectProfileImageUrl = createSelector([selectSlice], menuState => menuState.profileImageUrl);
