import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import SearchClosedPurchase from './app/pages/closedPurchaseOrder/SearchClosedPurchase';
import { OrdersTabOfUsers } from 'app/components/modules/myJobs/OrderUsersTab';
import { AccountTabs } from 'app/pages/adminstrationAccount/AccountTabs';
import { ProtectedRoute } from './app/components/shared/ProtectedRoute';
import { AddAccounts } from 'app/pages/adminstrationAccount/AddAccount';
import UnallocatedRFQ from './app/components/modules/unallocatedrfq';
import SupplierUsers from './app/pages/adminstration/supplierUsers';
import Search from './app/pages/outstandingPurchaseOrder/Search';
import ClientUsers from './app/pages/adminstration/clientUsers';
import MyJobs from './app/components/modules/myJobs/myJobsList';
import RFQSupplierView from './app/pages/RFQSupplierView/index';
import AdminSuppliers from 'app/pages/adminstration/suppliers';
import RequestForQuote from './app/components/modules/myJobs';
import Account from './app/pages/adminstrationAccount/index';
import { NotFoundPage } from './app/components/notFoundPage';
import SideBar from './app/components/modules/sideBar';
import Dashboard from './app/pages/dashboard/index';
import { Profile } from './app/pages/profile';
import Invoice from './app/pages/Invoice';
import Header from './app/pages/header';
import Audit from 'app/pages/audit';
import URL from './data/url';
import { persistor, store } from './store';
import { AuthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionRequiredAuthError, EventType, InteractionType } from '@azure/msal-browser';
import { b2cPolicies, msalConfig } from './configs/msalAuthConfigs';
import { protectedResources } from 'configs/msalAuthConfigs';
import { request } from 'utils/request';

const App = () => {
  //  MSAL
  const { login, error } = useMsalAuthentication(InteractionType.Redirect);
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();

  if (accounts.length > 0) {
    //  Set Active Account in msal for token
    instance.setActiveAccount(accounts[0]);
  }
  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup);
    }
  }, [error, login]);

  useEffect(() => {
    const callbackId = instance.addEventCallback(event => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect({ scopes: [process.env.REACT_APP_SCOPES || ''], authority: msalConfig.auth.forgotPassword });
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup({ scopes: [process.env.REACT_APP_SCOPES || ''], authority: msalConfig.auth.forgotPassword }).catch(e => {
              return;
            });
          }
        }
      }
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        try {
          request(`${protectedResources.api.apiEndpoint}/User/UpdateUserDetails`, {
            method: 'POST',
          });
        } catch (responseError) {
          console.error('Error while logging in' + responseError);
        }
        if (event?.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (event.payload.idTokenClaims['acr'] === b2cPolicies.names.forgotPassword) {
            window.alert('Password has been reset successfully. \nPlease sign-in with your new password');
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <AuthenticatedTemplate>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <HelmetProvider>
              <div className="page-row">
                <div className="menu-side-bar">
                  <SideBar />
                </div>
                <div className="right-container">
                  <Header />
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" replace />}></Route>
                    <Route
                      path="/dashboard"
                      element={
                        <ProtectedRoute url="/dashboard">
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    ></Route>
                    <Route
                      path={URL.REQUEST_FOR_QUOTE}
                      element={
                        <ProtectedRoute url={URL.REQUEST_FOR_QUOTE}>
                          <RequestForQuote create={true} />
                        </ProtectedRoute>
                      }
                    ></Route>
                    <Route
                      path="/requestedquotes"
                      element={
                        <ProtectedRoute url="/dashboard">
                          <RFQSupplierView />
                        </ProtectedRoute>
                      }
                    ></Route>
                    <Route
                      path="/orders"
                      element={
                        <ProtectedRoute url="/dashboard">
                          <OrdersTabOfUsers />
                        </ProtectedRoute>
                      }
                    ></Route>
                    <Route path="/profile" element={<Profile />}></Route>
                    <Route path="/unallocatedrfq" element={<UnallocatedRFQ />}></Route>
                    <Route
                      path="/myjobs"
                      element={
                        <ProtectedRoute url="/myjobs">
                          <MyJobs />
                        </ProtectedRoute>
                      }
                    ></Route>
                    <Route path="/readytoinvoice" element={<Invoice />}></Route>
                    <Route path="/myjobs/:jobId" element={<RequestForQuote create={false} />}></Route>
                    <Route path="/administration/clientuser" element={<ClientUsers />}></Route>
                    <Route path="/administration/supplieruser" element={<SupplierUsers />}></Route>
                    <Route path="/administration/account" element={<Account />}></Route>
                    <Route path="/administration/account/addAccount" element={<AddAccounts />}></Route>
                    <Route path="/administration/account/:accountId" element={<AccountTabs />}></Route>
                    <Route path="/outstandingpos" element={<Search />}></Route>
                    <Route path="/completedpos" element={<SearchClosedPurchase />}></Route>
                    <Route path="/administration/suppliers" element={<AdminSuppliers />}></Route>
                    <Route path="/administration/audithistory" element={<Audit isAdministrator={true} />}></Route>
                    <Route path="/*" element={<NotFoundPage />}></Route>
                  </Routes>
                </div>
              </div>
            </HelmetProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </AuthenticatedTemplate>
  );
};

export default App;
