import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBreadcrumbSlice } from '../breadcrumb/slice';
import Breadcrumb from '../breadcrumb';
import { AccountDetails } from './AccountsHeader/AccountDetails/AccountDetails';
import { BusinessUnit } from './AccountsHeader/BusinessUnit/BusinessUnit';
import { JobRate } from './AccountsHeader/JobRate/JobRate';
import { Products } from './AccountsHeader/Products/Products';
import { Suppliers } from './AccountsHeader/Suppliers/Suppliers';
import { useInvoiceSlice } from './slice';
import { getEditAccountData, selectNavigation } from './slice/selector';
import { ACCOUNTDETAILS, BUSINESSUNIT, JOBRATE, PRODUCTS, SUPPLIERS } from 'app/components/shared/Constants';

export const AccountTabs = () => {
  const dispatch = useDispatch();
  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  const { actions: rfqActions } = useInvoiceSlice();
  const createAccountNavigation = useSelector(selectNavigation);
  const accountRow = useSelector(getEditAccountData);

  useEffect(() => {
    dispatch(
      rfqActions.changeNavigationMenu({
        accountDetails: { visible: true, selected: true },
        businessUnit: { visible: false, selected: false },
        jobrate: { visible: false, selected: false },
        products: { visible: false, selected: false },
        suppliers: { visible: false, selected: false },
      }),
    );
  }, [dispatch, rfqActions]);

  useEffect(() => {
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Dashboard', url: '/Dashboard' },
        { name: 'Administration/Account', url: '/Administration/Account' },
        { name: accountRow?.data?.[0]?.name, url: '#' },
      ]),
    );
  }, [accountRow?.data, breadcrumbActions, dispatch]);

  return (
    <div>
      <>
        <div className="rfq">
          <Breadcrumb />
          <div className="rfq__container">
            <div className="rfq__header"></div>
            <div className="inPage__navigation">
              <button></button>
              {createAccountNavigation.accountDetails && (
                <button className={`${createAccountNavigation.accountDetails.selected && 'active'}`} onClick={() => dispatch(rfqActions.selectNavigationButton(ACCOUNTDETAILS))}>
                  Account Details
                </button>
              )}
              {createAccountNavigation.businessUnit && (
                <button className={`${createAccountNavigation.businessUnit.selected && 'active'}`} onClick={() => dispatch(rfqActions.selectNavigationButton(BUSINESSUNIT))}>
                  Business Units
                </button>
              )}
              {createAccountNavigation.jobrate && (
                <button className={`${createAccountNavigation.jobrate.selected && 'active'}`} onClick={() => dispatch(rfqActions.selectNavigationButton(JOBRATE))}>
                  Job Rates
                </button>
              )}
              {createAccountNavigation.products && (
                <button className={`${createAccountNavigation.products.selected && 'active'}`} onClick={() => dispatch(rfqActions.selectNavigationButton(PRODUCTS))}>
                  Products
                </button>
              )}
              {createAccountNavigation.suppliers && (
                <button className={`${createAccountNavigation.suppliers.selected && 'active'}`} onClick={() => dispatch(rfqActions.selectNavigationButton(SUPPLIERS))}>
                  Suppliers
                </button>
              )}
            </div>
            <div className="inPage__navigation__line"></div>
            <div style={{ margin: '30px' }}>
              {createAccountNavigation.accountDetails.selected && <AccountDetails />}
              {createAccountNavigation.businessUnit.selected && <BusinessUnit />}
              {createAccountNavigation.jobrate.selected && <JobRate />}
              {createAccountNavigation.products.selected && <Products />}
              {createAccountNavigation.suppliers.selected && <Suppliers />}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
