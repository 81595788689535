import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './logoutModal.sass';
import { useMsal } from '@azure/msal-react';

const LogoutModal = props => {
  const { instance } = useMsal();

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="fullScreen"
      centered
      onHide={() => {
        props.onHide();
      }}
      scrollable={true}
      className="logout-modal"
    >
      <Modal.Header closeButton className="logout-modal__header"></Modal.Header>
      <Modal.Body className="logout-modal__body text-center">
        <h6>Are you sure you want to Logout</h6>
      </Modal.Body>
      <Modal.Footer className="logout-modal__footer">
        <Button
          variant="outline-primary"
          className="outline__button btn btn-outline-primary"
          onClick={() => {
            props.onHide();
          }}
        >
          No
        </Button>
        <Button variant="outline-primary" className="full__button btn btn-success" onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: '/' })}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
