import { useDispatch, useSelector } from 'react-redux';
import InputGroup from 'react-bootstrap/InputGroup';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { pick } from 'lodash';
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import Form from 'react-bootstrap/Form';
import { selectMaterial, selectVatRate } from '../slice/selectors';
import { useCreateQuoteSlice } from '../slice/index';
import { request } from 'utils/request';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import './style.sass';

export interface CreateQuoteType {
  jobQuoteId: number;
  reference: string;
  productionRate: number | string;
  materialRate: number | string;
  deliveryRate: number | string;
  total?: number;
  productionRunon: number | string | undefined;
  materialRunon: number | string | undefined;
  deliveryRunon: number | string | undefined;
  totalRunon: number;
  vatRateId: number | null;
  materialSupplierId: number | string;
  material: string;
  materialQuantity: number | string;
  supplierQuoteNotes: string;
  proofDays: string;
  deliveryDays: string;
  confirmRebate: boolean;
}

const CreateQuote = props => {
  const { data, isEdit, jobQuoteId, onHide, show, status, statusCode, materialRebate } = props;
  const [materialCounter, setMaterialCounter] = useState<number>(0);
  const [materialquantityCounter, setMaterialquantityCounter] = useState<number>(0);
  const [notesCounter, setNotesCounter] = useState<number>(0);
  const [confirmationShow, setConfirmationShow] = useState(false);
  const [cancelShow, setCancelShow] = useState(false);
  const [materialOther, setMaterialOther] = useState(false);

  const [inputs, setInputs] = useState<CreateQuoteType>({
    jobQuoteId,
    reference: '',
    productionRate: '',
    materialRate: '',
    deliveryRate: '',
    total: 0.0,
    productionRunon: undefined,
    materialRunon: undefined,
    deliveryRunon: undefined,
    totalRunon: 0.0,
    vatRateId: null,
    materialSupplierId: '',
    material: '',
    materialQuantity: '',
    supplierQuoteNotes: '',
    proofDays: '',
    deliveryDays: '',
    confirmRebate: false,
  });
  const validationSchema = yup.object({
    productionRate: yup
      .number()
      .typeError('Please provide a valid Production rate.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a Production rate.'),
    materialRate: yup
      .number()
      .typeError('Please provide a valid Material rate.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a Material rate.'),
    deliveryRate: yup
      .number()
      .typeError('Please provide a valid Delivery rate.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a Delivery rate.'),
    ...(props.quantityRunOn != null &&
      props.quantityRunOn != 0 && {
        productionRunon: yup
          .number()
          .typeError('Please provide a valid Production runon.')
          .transform(value => (isNaN(value) ? null : value))
          .nullable()
          .required('Please provide a Production runon.'),
        materialRunon: yup
          .number()
          .typeError('Please provide a valid Material runon.')
          .transform(value => (isNaN(value) ? null : value))
          .nullable()
          .required('Please provide a Material runon.'),
        deliveryRunon: yup
          .number()
          .typeError('Please provide a valid Delivery runon.')
          .transform(value => (isNaN(value) ? null : value))
          .nullable()
          .required('Please provide a Delivery runon.'),
      }),
    materialSupplierId: yup
      .number()
      .nullable()
      .transform(value => (isNaN(value) ? null : value))
      .required('Please select a material supplier'),
    proofDays: yup.string().trim().nullable().required('Proof days are required'),
    deliveryDays: yup.string().trim().nullable().required('Delivery days are required'),
    confirmRebate: yup.bool().oneOf([true], 'Please Select Confirm Rebate.'),
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setInputs({ ...inputs, ...pick(data, Object.keys(inputs)), reference: data.supplierReference });
    reset({ ...pick(data, Object.keys(inputs)), reference: data.supplierReference });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, reset]);

  const dispatch = useDispatch();
  const { actions: createQuoteActions } = useCreateQuoteSlice();
  const vatRate = useSelector(selectVatRate);
  let vatRateData = JSON.parse(JSON.stringify(vatRate));
  const vatRateList: { value: number; label: string }[] = vatRateData.map(item => ({
    value: item.id,
    label: item.name,
  }));
  const material = useSelector(selectMaterial);
  let materialData = JSON.parse(JSON.stringify(material));
  const materialList: { value: number; label: string }[] = materialData.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const handleInputMaterialSupplier = e => {
    const updatedInputs = {
      ...inputs,
      materialSupplierId: e,
    };
    updatedInputs.materialSupplierId = Number(updatedInputs.materialSupplierId);
    const entity = material.find(o => o.id === updatedInputs.materialSupplierId);
    if (entity?.name.toLowerCase().trim() === 'other') setMaterialOther(true);
    else setMaterialOther(false);
    setInputs(updatedInputs);
  };
  const handleInputVatRateId = e => {
    const updatedInputs = {
      ...inputs,
      vatRateId: e,
    };
    updatedInputs.vatRateId = Number(updatedInputs.vatRateId);
    setInputs(updatedInputs);
  };
  useEffect(() => {
    dispatch(createQuoteActions.loadRFQModal());
  }, [createQuoteActions, dispatch]);

  const handleInputChange = e => {
    const updatedInputs = {
      ...inputs,
      [e.target.name]: e.target?.checked ? e.target.checked : e.target.value,
    };
    if (e.target.name === 'vatRateId') updatedInputs.vatRateId = Number(updatedInputs.vatRateId);
    if (e.target.name === 'materialSupplierId') updatedInputs.materialSupplierId = Number(updatedInputs.materialSupplierId);
    setInputs(updatedInputs);
  };
  const [saveFlag, setIsSave] = useState(false);

  const onSubmit = async values => {
    if (saveFlag) {
      submitForm();
      onHide();
    } else {
      onHide();
      setConfirmationShow(true);
    }
  };

  const submitForm = async () => {
    delete inputs.total;
    delete inputs[''];

    const requestBody = {
      ...inputs,
      jobQuoteId,
      productionRate: Number(inputs.productionRate),
      materialRate: Number(inputs.materialRate),
      deliveryRate: Number(inputs.deliveryRate),
      productionRunon: inputs.productionRunon == null ? null : Number(inputs.productionRunon),
      materialRunon: inputs.materialRunon == null ? null : Number(inputs.materialRunon),
      deliveryRunon: inputs.deliveryRunon == null ? null : Number(inputs.deliveryRunon),
      vatRateId: inputs.vatRateId,
      confirmRebate: inputs.confirmRebate,
      saveFlag,
    };

    const method = 'PUT';
    let APIName = 'CreateSupplierQuote';
    if (isEdit) APIName = 'updateSupplierQuote';
    try {
      await request(`${protectedResources.api.apiEndpoint}/Quotes/${APIName}/${jobQuoteId}`, {
        method,
        body: JSON.stringify(requestBody),
      }).then(res => {
        dispatch(createQuoteActions.responseCreateQuote({ ...res, supplierReference: res.reference }));
      });
      dispatch(
        createQuoteActions.UpdateSupplierList({
          inputs: {
            ...inputs,
            jobQuoteId,
            saveFlag,
            productionRate: Number(inputs.productionRate),
            materialRate: Number(inputs.materialRate),
            deliveryRate: Number(inputs.deliveryRate),
            productionRunon: Number(inputs.productionRunon),
            materialRunon: Number(inputs.materialRunon),
            deliveryRunon: Number(inputs.deliveryRunon),
            supplierReference: requestBody.reference,
            vatRateId: Number(inputs.vatRateId),
            total: Number(inputs.productionRate) + Number(inputs.materialRate) + Number(inputs.deliveryRate),
          },
          jobQuoteId,
          saveFlag,
          statusCode,
        }),
      );
    } catch (responseError) {
      console.error('Error while submitting the form ' + responseError);
    }
  };
  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="fullScreen"
        centered
        onHide={() => {
          onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{isEdit ? 'Edit Quote' : 'Create Quote'}</h4>
            <small>{props.id}</small>
          </Modal.Title>
        </Modal.Header>
        <Form id="createQuote" onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body className="fullScreenStyling">
            <Form.Group className="mb-3 modal_placeholder col-6" controlId="formName">
              <Form.Label>Supplier Reference</Form.Label>
              <Form.Control autoComplete="off" type="text" name="reference" placeholder="Enter reference" value={inputs.reference} onChange={e => handleInputChange(e)} />
            </Form.Group>
            <b>Please include your agreed supplier rebate % within your uploaded costs</b>
            <br></br>
            <h5 style={{ paddingTop: '10px' }}>Pricing Information</h5>
            <div className="row">
              <div className="col">
                <Form.Group className="mb-3 text-clr modal_halfPlaceholder" controlId="formProductionRate">
                  <Form.Label>
                    Production(&#163;)<span className="input__required">*</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    className={errors.productionRate && 'border-danger'}
                    {...register('productionRate')}
                    value={inputs?.productionRate?.toString()}
                    placeholder="Enter Production"
                    onChange={e => handleInputChange(e)}
                  />
                  {errors.productionRate && <Form.Text className="text-danger">{errors.productionRate.message}</Form.Text>}
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group className="mb-3 modal_halfPlaceholder" controlId="formMaterialRate">
                  <Form.Label>
                    Material(&#163;)<span className="input__required">*</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className={errors.materialRate && 'border-danger'}
                    {...register('materialRate')}
                    type="number"
                    placeholder="Enter Material"
                    value={inputs?.materialRate}
                    onChange={e => handleInputChange(e)}
                  />
                  {errors.materialRate && <Form.Text className="text-danger">{errors.materialRate.message}</Form.Text>}
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-3 modal_placeholder col-6" controlId="formDeliveryRate">
              <Form.Label>
                Delivery(&#163;)<span className="input__required">*</span>
              </Form.Label>
              <Form.Control
                autoComplete="off"
                type="number"
                className={errors.deliveryRate && 'border-danger'}
                {...register('deliveryRate')}
                placeholder="Enter Delivery"
                value={inputs?.deliveryRate}
                onChange={e => handleInputChange(e)}
              />
              {errors.deliveryRate && <Form.Text className="text-danger">{errors.deliveryRate.message}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3 modal_placeholder col-6" controlId="formName">
              <span>Total</span>
              <InputGroup className="mb-3">
                <InputGroup.Text>£</InputGroup.Text>
                <Form.Control
                  autoComplete="off"
                  aria-label="Amount (to the nearest dollar)"
                  placeholder="0.00"
                  value={Number(Number(inputs.deliveryRate) + Number(inputs.materialRate) + Number(inputs.productionRate)).toFixed(2)}
                  disabled
                />
              </InputGroup>
            </Form.Group>
            {props.quantityRunOn != null && props.quantityRunOn != 0 && (
              <>
                <h5>Runon Information</h5>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3 text-clr modal_halfPlaceholder" controlId="formProductionRunon">
                      <Form.Label>
                        Production Runon (&#163;)<span className="input__required">*</span>
                      </Form.Label>
                      <Form.Control
                        autoComplete="off"
                        type="number"
                        className={errors.productionRunon && 'border-danger'}
                        {...register('productionRunon')}
                        placeholder="Enter Production Runon"
                        value={Number(inputs?.productionRunon)}
                        onChange={e => handleInputChange(e)}
                      />
                      {errors.productionRunon && <Form.Text className="text-danger">{errors.productionRunon.message}</Form.Text>}
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mb-3 modal_halfPlaceholder" controlId="formMaterialRunon">
                      <Form.Label>
                        Material Runon (&#163;)<span className="input__required">*</span>
                      </Form.Label>
                      <Form.Control
                        autoComplete="off"
                        type="number"
                        className={errors.materialRunon && 'border-danger'}
                        {...register('materialRunon')}
                        placeholder="Enter Material Runon"
                        value={inputs?.materialRunon}
                        onChange={e => handleInputChange(e)}
                      />
                      {errors.materialRunon && <Form.Text className="text-danger">{errors.materialRunon.message}</Form.Text>}
                    </Form.Group>
                  </div>
                </div>
                <Form.Group className="mb-3 modal_placeholder col-6" controlId="formDeliveryRunon">
                  <Form.Label>
                    Delivery Runon (&#163;)<span className="input__required">*</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    className={errors.deliveryRunon && 'border-danger'}
                    {...register('deliveryRunon')}
                    placeholder="Enter Delivery Runon"
                    value={inputs?.deliveryRunon}
                    onChange={e => handleInputChange(e)}
                  />
                  {errors.deliveryRunon && <Form.Text className="text-danger">{errors.deliveryRunon.message}</Form.Text>}
                </Form.Group>
                <Form.Group className="mb-3 modal_placeholder col-6" controlId="formName">
                  <span>Total</span>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>£</InputGroup.Text>
                    <Form.Control
                      autoComplete="off"
                      aria-label="Amount (to the nearest dollar)"
                      value={
                        isNaN(Number(Number(inputs.deliveryRunon) + Number(inputs.materialRunon) + Number(inputs.productionRunon)))
                          ? '0.00'
                          : Number(Number(inputs.deliveryRunon) + Number(inputs.materialRunon) + Number(inputs.productionRunon))?.toFixed(2)
                      }
                      disabled
                    />
                  </InputGroup>
                </Form.Group>
              </>
            )}
            <h5>VAT Rate</h5>
            <Form.Group controlId="form__materialSupplierId" className="mb-3 label col-6">
              <Controller
                control={control}
                name="vatRateId"
                render={({ field: { name, onBlur, onChange, ref, value } }) => {
                  return (
                    <Select
                      className={errors.vatRateId && 'border-danger-select'}
                      classNamePrefix="custom-select-input"
                      id="form__vatRateId"
                      onBlur={onBlur}
                      onChange={val => {
                        onChange(val?.value);
                        handleInputVatRateId(val?.value);
                      }}
                      options={vatRateList}
                      placeholder="Select VAT Rate"
                      ref={ref}
                      value={vatRateList.find(item => item.value === value) || (inputs.vatRateId && vatRateList.find(item => item.value === inputs.vatRateId)) || null}
                    />
                  );
                }}
              />
            </Form.Group>
            <h5>Material Information</h5>
            <div className="row">
              <div className="col">
                <Form.Group controlId="form__materialSupplierId" style={{ gridColumn: '1 / 2' }}>
                  <Form.Label>
                    Material Supplier<span className="input__required">*</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="materialSupplierId"
                    render={({ field: { onBlur, onChange, ref, value } }) => {
                      return (
                        <Select
                          className={errors.materialSupplierId && 'border-danger-select'}
                          classNamePrefix="custom-select-input"
                          id="form__materialSupplierId"
                          onBlur={onBlur}
                          onChange={val => {
                            onChange(val?.value);
                            handleInputMaterialSupplier(val?.value);
                          }}
                          options={materialList}
                          placeholder="Select Supplier"
                          ref={ref}
                          value={materialList.find(item => item.value === value) || (inputs.materialSupplierId && materialList.find(item => item.value === inputs.materialSupplierId)) || null}
                        />
                      );
                    }}
                  />
                  {errors.materialSupplierId && <Form.Text className="text-danger">{errors.materialSupplierId.message}</Form.Text>}
                  {(materialOther || (inputs.materialSupplierId && materialList.find(item => item.value === inputs.materialSupplierId)?.label?.toLowerCase() == 'other')) && (
                    <Form.Text className="text-danger">
                      As you have selected use of a non-CDS materials merchant you may be liable to £{((materialRebate * Number(inputs?.materialRate)) / 100)?.toFixed(2)} charge if the order is
                      accepted.
                    </Form.Text>
                  )}
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group className="mb-3 modal_placeholder" controlId="formMaterialQuantity">
                  <Form.Label>
                    Material Quantity
                    <span style={{ marginLeft: '200px' }}>{materialquantityCounter}/100</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    {...register('materialQuantity')}
                    className={errors.materialQuantity && 'border-danger'}
                    placeholder="Enter material quantity"
                    value={inputs?.materialQuantity}
                    onChange={e => {
                      handleInputChange(e);
                      setMaterialquantityCounter(e.target.value.length);
                    }}
                  />
                  {errors.materialQuantity && <Form.Text className="text-danger">{errors.materialQuantity.message}</Form.Text>}
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-3 modal_placeholder" controlId="formMaterial">
              <Form.Label className="d-flex align-items-center">
                Material
                <span className="ms-auto">{materialCounter}/1000</span>
              </Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                as="textarea"
                placeholder="Enter Material"
                {...register('material')}
                className={errors.material && 'border-danger'}
                value={inputs.material}
                onChange={e => {
                  handleInputChange(e);
                  setMaterialCounter(e.target.value.length);
                }}
                rows={3}
              />
              {errors.material && <Form.Text className="text-danger">{errors.material.message}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3 modal_placeholder" controlId="formNotes">
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                Notes<span>{notesCounter}/5000</span>
              </Form.Label>
              <Form.Control
                autoComplete="off"
                as="textarea"
                type="text"
                name="supplierQuoteNotes"
                placeholder="Enter notes"
                value={inputs.supplierQuoteNotes}
                onChange={e => {
                  handleInputChange(e);
                  setNotesCounter(e.target.value.length);
                }}
                rows={3}
              />
            </Form.Group>
            <h5>Scheduled Information</h5>
            <div className="row">
              <div className="col">
                <Form.Group className="mb-3 text-clr modal_halfPlaceholder" controlId="formProofDays">
                  <Form.Label>
                    Proof Days<span className="input__required">*</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    className={errors.proofDays && 'border-danger'}
                    {...register('proofDays')}
                    placeholder="Enter proof days"
                    onChange={e => handleInputChange(e)}
                    value={inputs.proofDays}
                  />
                  {errors.proofDays && <Form.Text className="text-danger">{errors.proofDays.message}</Form.Text>}
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group className="mb-3 modal_halfPlaceholder" controlId="formDeliveryDays">
                  <Form.Label>
                    Delivery Days<span className="input__required">*</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    className={errors.deliveryDays && 'border-danger'}
                    {...register('deliveryDays')}
                    placeholder="Enter proof days"
                    onChange={e => handleInputChange(e)}
                    value={inputs.deliveryDays}
                  />
                  {errors.deliveryDays && <Form.Text className="text-danger">{errors.deliveryDays.message}</Form.Text>}
                </Form.Group>
              </div>
            </div>
            <div>
              <Form.Group className="mt-3" controlId="confirmRebate">
                <Form.Check
                  type="checkbox"
                  label="Confirm Rebate values added to quote"
                  inline
                  aria-label="confirmRebate"
                  id="confirmRebate"
                  checked={inputs.confirmRebate}
                  {...register('confirmRebate')}
                  onChange={e => {
                    handleInputChange(e);
                  }}
                />
                <span style={{ marginLeft: '-15px' }} className="input__required">
                  *
                </span>
                {errors.confirmRebate && <Form.Text className="text-danger">{errors.confirmRebate.message}</Form.Text>}
              </Form.Group>
              <span>Please confirm that have included you supplier rebate % within your uploaded costs.</span>
            </div>
          </Modal.Body>
        </Form>
        <Modal.Footer>
          <Button variant="outline-primary" className="outline__button" onClick={() => setCancelShow(true)}>
            Cancel
          </Button>
          {statusCode === 'NewRFQ' && (
            <Button variant="outline-primary" className="outline__button" type="submit" form="createQuote" onClick={() => setIsSave(true)}>
              Save
            </Button>
          )}
          <Button variant="primary" className="full__button" type="submit" form="createQuote" onClick={() => setIsSave(false)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={confirmationShow}
        aria-labelledby="contained-modal-title-vcEnter"
        backdropClassName="fullScreen"
        centered
        backdrop="static"
        onHide={() => setConfirmationShow(false)}
        dialogClassName="modal__popup_su"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Confirmation</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fullScreenStyling">
          <>
            <p>Are you sure you want to submit the quotation?</p>
            <p>Have you remembered to include your agreed supplier rebate % within your uploaded costs.</p>
            {(materialOther || (inputs.materialSupplierId && materialList.find(item => item.value === inputs.materialSupplierId)?.label?.toLowerCase() === 'other')) && (
              <>
                <p className="text-danger">
                  As you have selected use of a non-CDS materials merchant you may be liable to £{((materialRebate * Number(inputs?.materialRate)) / 100)?.toFixed(2)} charge if the order is accepted.
                </p>
                <br />
              </>
            )}
            <br />
            <Table style={{ border: '1px solid #e1d5ec' }} borderless={true}>
              <p>Pricing Summary Information</p>
              <table>
                <thead style={{ backgroundColor: '#eae3f1' }}>
                  <tr>
                    <th style={{ textAlign: 'right' }}>Production(&#163;)</th>
                    <th style={{ textAlign: 'right' }}>Material (&#163;)</th>
                    <th style={{ textAlign: 'right' }}>Delivery (&#163;)</th>
                    <th style={{ textAlign: 'right' }}>Total (&#163;)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'right' }}>{Number(inputs.productionRate).toFixed(2)}</td>
                    <td style={{ textAlign: 'right' }}>{Number(inputs.materialRate).toFixed(2)}</td>
                    <td style={{ textAlign: 'right' }}>{Number(inputs.deliveryRate).toFixed(2)}</td>
                    <td style={{ textAlign: 'right' }}>{Number(Number(inputs.deliveryRate) + Number(inputs.materialRate) + Number(inputs.productionRate))?.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>

              {props.quantityRunOn != null && props.quantityRunOn != 0 && (
                <>
                  <hr />
                  <p>Runon Summary Information</p>

                  <table>
                    <thead style={{ backgroundColor: '#eae3f1' }}>
                      <tr>
                        <th style={{ textAlign: 'right' }}>Production Runon (&#163;)</th>
                        <th style={{ textAlign: 'right' }}>Material Runon (&#163;)</th>
                        <th style={{ textAlign: 'right' }}>Delivery Runon (&#163;)</th>
                        <th style={{ textAlign: 'right' }}>Total Runon (&#163;)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'right' }}>{Number(inputs.productionRunon).toFixed(2)}</td>
                        <td style={{ textAlign: 'right' }}>{Number(inputs.materialRunon).toFixed(2)}</td>
                        <td style={{ textAlign: 'right' }}>{Number(inputs.deliveryRunon).toFixed(2)}</td>
                        <td style={{ textAlign: 'right' }}>{Number(Number(inputs.deliveryRunon) + Number(inputs.materialRunon) + Number(inputs.productionRunon))?.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
            </Table>
          </>
        </Modal.Body>
        <hr style={{ margin: '0px' }} />
        <Modal.Footer>
          <Button variant="light" className="outline__button_su" onClick={() => setConfirmationShow(false)}>
            No
          </Button>
          <Button
            variant="light"
            className="outline__button_su"
            type="submit"
            form="createQuote"
            onClick={e => {
              submitForm();
              setConfirmationShow(false);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal backdrop="static" keyboard={false} show={cancelShow} aria-labelledby="contained-modal-title-vcenter" backdropClassName="fullScreen" centered onHide={() => setCancelShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span>Are you sure? Any changes will be lost</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="modal_footer">
          <>
            <Button variant="outline-primary" onClick={() => setCancelShow(false)} className="outline__button">
              No
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                setCancelShow(false);
                onHide();
              }}
              className="full__button"
            >
              Yes
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateQuote;
