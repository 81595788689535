import React, { useState, useEffect } from 'react';

import { getIsClient } from '../../components/modules/sideBar/slice/selectors';
import { getJobInformation } from 'store/reducers/entities/selectors';
import { protectedResources } from '../../../configs/msalAuthConfigs';
import { getAllUserList, getAllTaskData } from './slice/selectors';
import { Table, Button, Row, Col, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useRequestForQuoteSlice } from './slice/index';
import { useParams } from 'react-router-dom';
import PopupTaskNext from './PopupTaskNext';
import MODULES from '../../../data/modules';
import MomentUtils from '@date-io/moment';
import { request } from 'utils/request';
import PopupTask from './PopupTask';

const Tasks = props => {
  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));
  const isCancelled = jobInformation?.statusName === 'Cancelled' || props.isActive === 'No';
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [nextModal, setNextModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteID, setDeleteId] = useState(null);
  const [tasks, setTaskData] = useState<any>({});
  const moment = new MomentUtils();
  const users: { value: number; label: string }[] = useSelector(getAllUserList);
  const tasklistDetails = useSelector(getAllTaskData);
  const { actions } = useRequestForQuoteSlice();
  const dispatch = useDispatch();
  const isClient = useSelector(getIsClient);
  const { jobId } = useParams();
  const handleDelete = async id => {
    await request(`${protectedResources.api.apiEndpoint}/Task/DeleteTasks/${id}`, { method: 'DELETE' });
    dispatch(actions.setDeleteTasksData({ id: id, isProductId: props.isProductId }));
  };

  const handleEdit = entity => {
    setNextModal(true);
    setSelectedRow(entity.id);
    setIsEdit(true);
    setTaskData({ ...entity, completionDate: entity.completionDate ? new Date(entity.completionDate) : null, dueDate: new Date(entity.dueDate) });
  };

  useEffect(() => {
    let url = `/Task/GetTasksbyJob/${jobId}`;
    if (props.isProductId) url = `/Task/GetTasksbyJob/${jobId}?productTemplateId=${props.productid}`;
    dispatch(actions.loadAllTaskData({ url: url, isProductId: props.isProductId }));
    dispatch(actions.loadAllUsers({ isClient: isClient, jobId: Number(jobId) }));
  }, [actions, dispatch, jobId, props.isProductId, props.productid]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(tasklistDetails));
    setTaskData(data);
  }, [tasklistDetails]);

  const Template = (data: any = []) => {
    return (
      <>
        <div className="row" style={{ marginTop: '4px' }}>
          <div className="col-md-6" style={{ marginLeft: '6px' }}>
            Tasks ({data.length})
          </div>
          <Button
            variant="outline-secondary"
            disabled={isCancelled}
            className="col-sm-2"
            style={{
              marginLeft: '40%',
              width: '8%',
              marginBottom: '2%',
              fontSize: '11px',
              height: '38px',
            }}
            onClick={() => {
              setIsEdit(false);
              if (props.isProductId) {
                setConfirmModal(true);
              } else {
                setNextModal(true);
              }
            }}
          >
            Add Task
          </Button>
        </div>
        <Table striped>
          <thead>
            <tr>
              {!props.productid && <th style={{ width: '170' }}>Item Name</th>}
              <th style={{ width: '170' }}>Task Name</th>
              <th style={{ width: '170' }}>Status</th>
              <th style={{ width: '170' }}>Due Date</th>
              <th style={{ width: '170' }}>Completion Date</th>
              <th style={{ width: '1950' }}>Asignee</th>
              <th style={{ width: '1950' }}>Notes</th>
              <th style={{ width: '1950' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(task => (
              <tr>
                {!props.productid && <td>{task?.productTemplateTitle}</td>}
                <td>{task?.name}</td>
                <td>{task?.status}</td>
                <td>{task?.dueDate != null ? `${moment.date(task?.dueDate).format('DD/MM/YYYY')}` : ''}</td>
                <td>{task.completionDate != null ? `${moment.date(task?.completionDate).format('DD/MM/YYYY')}` : ''}</td>
                <td>{users?.find((e: { value: number; label: string }) => e.value === task.assignee)?.label}</td>
                <td>{task.notes}</td>
                <td>
                  <Row>
                    <Col>
                      <button disabled={!task.amend || isCancelled} onClick={() => handleEdit(task)} style={{ border: '0px', color: 'black', fontSize: '77%' }}>
                        Edit
                      </button>
                      <button
                        disabled={!task.amend || isCancelled}
                        onClick={() => {
                          setDeleteModal(true);
                          setDeleteId(task.id);
                        }}
                        style={{ border: '0px', color: 'black', fontSize: '77%' }}
                      >
                        Delete
                      </button>
                    </Col>
                  </Row>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Modal backdrop="static" keyboard={false} show={deleteModal} aria-labelledby="contained-modal-title-vcenter" backdropClassName="fullScreen" centered onHide={() => setDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <span>Are you sure you want to delete this task?</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="modal_footer">
            <>
              <Button variant="outline-primary" onClick={() => setDeleteModal(false)} className="outline__button">
                No
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => {
                  handleDelete(deleteID);
                  setDeleteModal(false);
                }}
                className="full__button"
              >
                Yes
              </Button>
            </>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <>
      {props.isProductId ? Template(tasklistDetails.taskInProductList) : Template(tasklistDetails.taskDetailsList)}
      {props.isProductId ? (
        <>
          <PopupTask
            show={ConfirmModal}
            onHide={() => setConfirmModal(false)}
            data={props.isProductId ? tasklistDetails.taskInProductList : tasklistDetails.taskDetailsList}
            productid={props.productid}
            isProductId={props.isProductId}
            task={props.isProductId ? tasklistDetails.taskInProductList : tasklistDetails.taskDetailsList}
            onSetShow={() => setConfirmModal(true)}
          />
          <PopupTaskNext
            show={nextModal}
            onHide={() => setNextModal(false)}
            taskId={selectedRow}
            productid={props.productid}
            data={props.isProductId ? tasklistDetails.taskInProductList : tasklistDetails.taskDetailsList}
            task={isEdit ? tasks : { status: 'Open' }}
            isEdit={isEdit}
            isProductId={props.isProductId}
          />
        </>
      ) : (
        <PopupTaskNext
          show={nextModal}
          onHide={() => setNextModal(false)}
          taskId={selectedRow}
          productid={null}
          data={props.isProductId ? tasklistDetails.taskInProductList : tasklistDetails.taskDetailsList}
          task={isEdit ? tasks : { status: 'Open' }}
          isProductId={props.isProductId}
          isEdit={isEdit}
        />
      )}
    </>
  );
};

export default Tasks;
