//  Externals
import { Button, Form, Modal, InputGroup, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { omit } from 'lodash';
import * as yup from 'yup';
//  Internals
import { selectMaterial, selectVatRate } from '../../RFQSupplierView/slice/selectors';
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { useCreateQuoteSlice } from '../../RFQSupplierView/slice/index';
import { getRowsOfTextArea } from './../../../../utils/form/index';
import { selectProductItemDetailsRows } from '../slice/selectors';
import { useRequestForQuoteSlice } from '../slice';
import { request } from 'utils/request';
import './style.sass';
import { getActionToggle } from 'store/reducers/entities/selectors';
import MODULES from '../../../../data/modules';

type CreateQuote = {
  jobQuoteId: number;
  reference: string;
  productionRate: number | null;
  materialRate: number | string;
  deliveryRate: number | string;
  total?: number;
  productionRunon: number | string | undefined;
  materialRunon: number | string | undefined;
  deliveryRunon: number | string | undefined;
  vatRateId: number | null;
  materialSupplierId: number | string;
  material: string;
  materialQuantity: number | string;
  supplierQuoteNotes: string;
  proofDays: string;
  deliveryDays: string;
  productTemplateId: number;
  supplierId: number;
  cdsMerchantRate: number;
  totalRate: number;
  cdsMerchantRunon: number;
  totalRunon: number;
  supplierFormData: number;
  materialRebate: number;
  confirmRebate: boolean;
};

const EditSupplierQuote = props => {
  const { disabled, jobQuoteId, onHide, productType, quantityRunOn, show, rowid } = props;
  const params = useParams();
  const { actions: rfqActions } = useRequestForQuoteSlice();
  const [materialOther, setMaterialOther] = useState(false);
  const [materialCounter, setMaterialCounter] = useState(0);
  const [materialquantityCounter, setMaterialquantityCounter] = useState(0);
  const [notesCounter, setNotesCounter] = useState(0);
  const [cancelShow, setCancelShow] = useState(false);
  const { actions: createQuoteActions } = useCreateQuoteSlice();
  const dispatch = useDispatch();
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));
  const vatRate = useSelector(selectVatRate);
  let vatRateData = JSON.parse(JSON.stringify(vatRate));
  const vatRateList: { value: number; label: string }[] = vatRateData.map(item => ({
    value: item.id,
    label: item.name,
  }));
  // const vatRate = useSelector(selectVatRate);
  const material = useSelector(selectMaterial);
  let data = JSON.parse(JSON.stringify(material));
  const [confirmationShow, setConfirmationShow] = useState(false);
  const [saveFlag, setIsSave] = useState(false);

  const materialList: { value: number; label: string }[] = data.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const supplierFormData = useSelector(selectProductItemDetailsRows)
    .filter(row => row.id === rowid)[0]
    ?.details.jobQuoteSummaryDetails?.filter(item => {
      if (item?.jobQuoteInfoSummaryDetails?.jobQuoteId === jobQuoteId) {
        return item?.jobQuoteInfoSummaryDetails;
      }
    });
  const materialRebate = supplierFormData[0].jobQuoteInfoSummaryDetails.materialRebate;

  const [inputs, setInputs] = useState<CreateQuote>({
    jobQuoteId,
    reference: '',
    productionRate: 0,
    materialRate: 0,
    deliveryRate: 0,
    total: 0,
    productionRunon: 0,
    materialRunon: 0,
    deliveryRunon: 0,
    vatRateId: null,
    materialSupplierId: 0,
    material: '',
    materialQuantity: 0,
    supplierQuoteNotes: '',
    proofDays: '',
    deliveryDays: '',
    productTemplateId: 0,
    supplierId: 0,
    cdsMerchantRate: 0,
    totalRate: 0,
    cdsMerchantRunon: 0,
    totalRunon: 0,
    supplierFormData: 0,
    materialRebate: 0,
    confirmRebate: false,
  });

  const handleInputChange = e => {
    const updatedInputs = {
      ...inputs,
      [e.target.name]: e.target?.checked ? e.target.checked : e.target.value,
    };
    if (e.target.name === 'vatRateId') updatedInputs.vatRateId = Number(updatedInputs.vatRateId);
    setInputs(updatedInputs);
  };

  const handleInputMaterialSupplier = e => {
    const updatedInputs = {
      ...inputs,
      materialSupplierId: e,
    };
    updatedInputs.materialSupplierId = Number(updatedInputs.materialSupplierId);
    const entity = material.find(o => o.id === updatedInputs.materialSupplierId);
    if (entity?.name.toLowerCase().trim() === 'other') setMaterialOther(true);
    else setMaterialOther(false);
    setInputs(updatedInputs);
  };
  const handleInputVatRateId = e => {
    const updatedInputs = {
      ...inputs,
      vatRateId: e,
    };
    updatedInputs.vatRateId = Number(updatedInputs.vatRateId);
    setInputs(updatedInputs);
  };

  const validationSchema = yup.object({
    productionRate: yup
      .number()
      .typeError('Please provide a valid Production rate.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a Production rate.'),
    materialRate: yup
      .number()
      .typeError('Please provide a valid Material rate.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a Material rate.'),
    deliveryRate: yup
      .number()
      .typeError('Please provide a valid Delivery rate.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a Delivery rate.'),
    ...(quantityRunOn != null &&
      quantityRunOn != 0 && {
        productionRunon: yup
          .number()
          .typeError('Please provide a valid Production runon.')
          .transform(value => (isNaN(value) ? null : value))
          .nullable()
          .required('Please provide a Production runon.'),
        materialRunon: yup
          .number()
          .typeError('Please provide a valid Material runon.')
          .transform(value => (isNaN(value) ? null : value))
          .nullable()
          .required('Please provide a Material runon.'),
        deliveryRunon: yup
          .number()
          .typeError('Please provide a valid Delivery runon.')
          .transform(value => (isNaN(value) ? null : value))
          .nullable()
          .required('Please provide a Delivery runon.'),
      }),
    materialSupplierId: yup
      .number()
      .nullable()
      .transform(value => (isNaN(value) ? null : value))
      .required('Please select a material supplier'),
    proofDays: yup.string().trim().nullable().required('Proof days are required'),
    deliveryDays: yup.string().trim().nullable().required('Delivery days are required'),
    confirmRebate: yup
      .bool()
      .transform(value => (value === null ? null : value))
      .nullable()
      .oneOf([true], 'Please Select Confirm Rebate.'),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: inputs,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async values => {
    if (saveFlag) {
      submitForm();
      onHide();
    } else {
      onHide();
      setConfirmationShow(true);
    }
  };

  const submitForm = async () => {
    delete inputs.total;
    delete inputs[''];
    const required = omit(inputs, [
      'productTemplateId',
      'supplierId',
      'cdsMerchantRate',
      'totalRate',
      'cdsMerchantRunon',
      'totalRunon',
      'rfqJobId',
      'vatName',
      'merchantSupplier',
      'quoteDate',
      'quoteTime',
      'dueDate',
      'dueTime',
      'notes',
    ]);
    const payload = {
      inputs: {
        ...required,
        jobQuoteId,
        productionRate: Number(inputs.productionRate),
        materialRate: Number(inputs.materialRate),
        deliveryRate: Number(inputs.deliveryRate),
        productionRunon: inputs.productionRunon == null ? null : Number(inputs.productionRunon),
        materialRunon: inputs.materialRunon == null ? null : Number(inputs.materialRunon),
        deliveryRunon: inputs.deliveryRunon == null ? null : Number(inputs.deliveryRunon),
        confirmRebate: inputs.confirmRebate,
        vatRateId: inputs.vatRateId,
        saveFlag,
      },
    };
    const method = 'PUT';
    let APIName = 'CreateSupplierQuote';
    try {
      await request(`${protectedResources.api.apiEndpoint}/Quotes/${APIName}/${jobQuoteId}`, {
        method,
        body: JSON.stringify(payload.inputs),
      }).then(res => {
        params.jobId && dispatch(rfqActions.loadRfqData({ jobId: params.jobId, toggleAction: toggleAction }));
      });
    } catch (responseError) {
      console.error('Error while submitting the form ' + responseError);
    }
  };

  useEffect(() => {
    dispatch(createQuoteActions.loadRFQModal());
    let data = {};
    for (const [key, value] of Object.entries(supplierFormData[0].jobQuoteInfoSummaryDetails)) {
      data = { ...data, [key]: value };
    }
    setInputs({
      ...inputs,
      ...data,
    });
    reset({
      ...inputs,
      ...data,
    });
  }, []);

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="fullScreen"
        centered
        onHide={() => {
          onHide();
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)} id="editSupplierQuote">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>Edit Supplier Quote</h4>
              <small>{productType}</small>
            </Modal.Title>
          </Modal.Header>
          <div style={{ paddingLeft: '5px' }}>
            <Modal.Body className="fullScreenStyling">
              <Form.Group className="mb-3 modal_placeholder col-6" controlId="formSupplierReference">
                <Form.Label>Supplier Reference</Form.Label>
                <Form.Control autoComplete="off" type="text" name="reference" placeholder="Enter reference" value={inputs?.reference} onChange={e => handleInputChange(e)} />
              </Form.Group>
              <span>
                <strong>Please include your agreed supplier rebate % within your uploaded costs</strong>
              </span>
              <br></br>
              <h5 style={{ paddingTop: '10px' }}>Pricing Information</h5>
              <div className="row">
                <div className="col">
                  <Form.Group className="mb-3 text-clr modal_halfPlaceholder" controlId="formProductionRate">
                    <Form.Label>
                      Production (&#163;)<span className="input__required">*</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      type="number"
                      className={errors.productionRate && 'border-danger'}
                      {...register('productionRate')}
                      value={inputs?.productionRate?.toString()}
                      placeholder="Enter Production"
                      onChange={e => handleInputChange(e)}
                    />
                    {errors.productionRate && <Form.Text className="text-danger">{errors.productionRate.message}</Form.Text>}
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mb-3 modal_halfPlaceholder" controlId="formMaterialRate">
                    <Form.Label>
                      Material (&#163;)<span className="input__required">*</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      className={errors.materialRate && 'border-danger'}
                      {...register('materialRate')}
                      type="number"
                      placeholder="Enter Material"
                      value={inputs?.materialRate}
                      onChange={e => handleInputChange(e)}
                    />
                    {errors.materialRate && <Form.Text className="text-danger">{errors.materialRate.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
              <Form.Group className="mb-3 modal_placeholder col-6" controlId="formDeliveryRate">
                <Form.Label>
                  Delivery(&#163;)<span className="input__required">*</span>
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="number"
                  className={errors.deliveryRate && 'border-danger'}
                  {...register('deliveryRate')}
                  placeholder="Enter Delivery"
                  value={inputs?.deliveryRate}
                  onChange={e => handleInputChange(e)}
                />
                {errors.deliveryRate && <Form.Text className="text-danger">{errors.deliveryRate.message}</Form.Text>}
              </Form.Group>
              <Form.Group className="mb-3 modal_placeholder col-6" controlId="formTotalRate">
                <span>Total</span>
                <InputGroup className="mb-3">
                  <InputGroup.Text>£</InputGroup.Text>
                  <Form.Control aria-label="Amount (to the nearest dollar)" disabled value={(Number(inputs.deliveryRate) + Number(inputs.materialRate) + Number(inputs.productionRate))?.toFixed(2)} />
                </InputGroup>
              </Form.Group>
              {quantityRunOn != null && quantityRunOn != 0 && (
                <>
                  <h5>Runon Information</h5>
                  <div className="row">
                    <div className="col">
                      <Form.Group className="mb-3 text-clr modal_halfPlaceholder" controlId="formProductionRunon">
                        <Form.Label>
                          Production Runon (&#163;)<span className="input__required">*</span>
                        </Form.Label>
                        <Form.Control
                          autoComplete="off"
                          type="number"
                          className={errors.productionRunon && 'border-danger'}
                          {...register('productionRunon')}
                          placeholder="Enter Production Runon"
                          value={inputs?.productionRunon}
                          onChange={e => handleInputChange(e)}
                        />
                        {errors.productionRunon && <Form.Text className="text-danger">{errors.productionRunon.message}</Form.Text>}
                      </Form.Group>
                    </div>
                    <div className="col">
                      <Form.Group className="mb-3 modal_halfPlaceholder" controlId="formMaterialRunon">
                        <Form.Label>
                          Material Runon (&#163;)<span className="input__required">*</span>
                        </Form.Label>
                        <Form.Control
                          autoComplete="off"
                          type="number"
                          className={errors.materialRunon && 'border-danger'}
                          {...register('materialRunon')}
                          placeholder="Enter Material Runon"
                          value={inputs?.materialRunon}
                          onChange={e => handleInputChange(e)}
                        />
                        {errors.materialRunon && <Form.Text className="text-danger">{errors.materialRunon.message}</Form.Text>}
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group className="mb-3 modal_placeholder col-6" controlId="formDeliveryRunon">
                    <Form.Label>
                      Delivery Runon (&#163;)<span className="input__required">*</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      type="number"
                      className={errors.deliveryRunon && 'border-danger'}
                      {...register('deliveryRunon')}
                      placeholder="Enter Delivery Runon"
                      value={inputs?.deliveryRunon}
                      onChange={e => handleInputChange(e)}
                    />
                    {errors.deliveryRunon && <Form.Text className="text-danger">{errors.deliveryRunon.message}</Form.Text>}
                  </Form.Group>
                  <Form.Group className="mb-3 modal_placeholder col-6" controlId="formMaterialRunon">
                    <span>Total</span>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>£</InputGroup.Text>
                      <Form.Control
                        autoComplete="off"
                        aria-label="Amount (to the nearest dollar)"
                        disabled
                        value={(Number(inputs.deliveryRunon) + Number(inputs.materialRunon) + Number(inputs.productionRunon))?.toFixed(2)}
                      />
                    </InputGroup>
                  </Form.Group>
                </>
              )}
              <h5>VAT Rate</h5>
              <Form.Group controlId="form__materialSupplierId" className="mb-3 col-6">
                <Controller
                  control={control}
                  name="vatRateId"
                  render={({ field: { name, onBlur, onChange, ref, value } }) => {
                    return (
                      <Select
                        className={errors.vatRateId && 'border-danger-select option__placeholder'}
                        classNamePrefix="custom-select-input"
                        id="form__vatRateId"
                        onBlur={onBlur}
                        onChange={val => {
                          onChange(val?.value);
                          handleInputVatRateId(val?.value);
                        }}
                        options={vatRateList}
                        placeholder="Select VAT Rate"
                        ref={ref}
                        value={vatRateList.find(item => item.value === value) || (inputs.vatRateId && vatRateList.find(item => item.value === inputs.vatRateId)) || null}
                      />
                    );
                  }}
                />
              </Form.Group>
              <h5>Material Information</h5>
              <div className="row">
                <div className="col">
                  <Form.Group controlId="form__materialSupplierId" style={{ gridColumn: '1 / 2' }}>
                    <Form.Label>
                      Material Supplier<span className="input__required">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="materialSupplierId"
                      render={({ field: { name, onBlur, onChange, ref, value } }) => {
                        return (
                          <Select
                            className={errors.materialSupplierId && 'border-danger-select'}
                            classNamePrefix="custom-select-input"
                            id="form__materialSupplierId"
                            onBlur={onBlur}
                            onChange={val => {
                              onChange(val?.value);
                              handleInputMaterialSupplier(val?.value);
                            }}
                            options={materialList}
                            placeholder="Select Supplier"
                            ref={ref}
                            value={materialList.find(item => item.value === value) || null}
                          />
                        );
                      }}
                    />
                    {errors.materialSupplierId && <Form.Text className="text-danger">{errors.materialSupplierId.message}</Form.Text>}
                    {(materialOther || (inputs.materialSupplierId && materialList.find(item => item.value === inputs.materialSupplierId)?.label?.toLowerCase() === 'other')) && (
                      <Form.Text className="text-danger">The supplier is not using a CDS materials merchant</Form.Text>
                    )}
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mb-3 modal_placeholder" controlId="formMaterialQuantity">
                    <Form.Label>
                      Material Quantity
                      <span style={{ marginLeft: '200px' }}>{materialquantityCounter}/100</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      type="number"
                      {...register('materialQuantity')}
                      className={errors.materialQuantity && 'border-danger'}
                      placeholder="Enter material quantity"
                      value={inputs?.materialQuantity}
                      onChange={e => {
                        handleInputChange(e);
                        setMaterialquantityCounter(e.target.value.length);
                      }}
                    />
                    {errors.materialQuantity && <Form.Text className="text-danger">{errors.materialQuantity.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
              <Form.Group className="mb-3 modal_placeholder" controlId="formMaterial">
                <Form.Label className="d-flex align-items-center">
                  Material
                  <span className="ms-auto">{materialCounter}/1000</span>
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  as="textarea"
                  placeholder="Enter Material"
                  {...register('material')}
                  className={errors.material && 'border-danger'}
                  rows={disabled ? getRowsOfTextArea(materialCounter, materialCounter) : 3}
                  value={inputs.material}
                  onChange={e => {
                    handleInputChange(e);
                    setMaterialCounter(e.target.value.length);
                  }}
                />
                {errors.material && <Form.Text className="text-danger">{errors.material.message}</Form.Text>}
              </Form.Group>
              <Form.Group className="mb-3 modal_placeholder" controlId="formNotes">
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  Notes<span>{notesCounter}/5000</span>
                </Form.Label>
                <Form.Control
                  rows={disabled ? getRowsOfTextArea(notesCounter, notesCounter) : 3}
                  autoComplete="off"
                  as="textarea"
                  type="text"
                  name="supplierQuoteNotes"
                  placeholder="Enter notes"
                  value={inputs.supplierQuoteNotes}
                  onChange={e => {
                    handleInputChange(e);
                    setNotesCounter(e.target.value.length);
                  }}
                />
              </Form.Group>
              <h5>Scheduled Information</h5>
              <div className="row">
                <div className="col">
                  <Form.Group className="mb-3 text-clr modal_halfPlaceholder" controlId="formProofDays">
                    <Form.Label>
                      Proof Days<span className="input__required">*</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      type="number"
                      className={errors.proofDays && 'border-danger'}
                      {...register('proofDays')}
                      placeholder="Enter proof days"
                      onChange={e => handleInputChange(e)}
                    />
                    {errors.proofDays && <Form.Text className="text-danger">{errors.proofDays.message}</Form.Text>}
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mb-3 modal_halfPlaceholder" controlId="formDeliveryDays">
                    <Form.Label>
                      Delivery Days<span className="input__required">*</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      type="number"
                      className={errors.deliveryDays && 'border-danger'}
                      {...register('deliveryDays')}
                      placeholder="Enter proof days"
                      onChange={e => handleInputChange(e)}
                    />
                    {errors.deliveryDays && <Form.Text className="text-danger">{errors.deliveryDays.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
              <div>
                <Form.Group className="mt-3" controlId="confirmRebate">
                  <Form.Check
                    type="checkbox"
                    label="Confirm Rebate values added to quote"
                    inline
                    aria-label="confirmRebate"
                    id="confirmRebate"
                    {...register('confirmRebate')}
                    onChange={e => {
                      handleInputChange(e);
                    }}
                  />
                  <span className="input__required" style={{ marginLeft: '-15px' }}>
                    *
                  </span>
                  {errors.confirmRebate && <Form.Text className="text-danger">{errors.confirmRebate.message}</Form.Text>}
                </Form.Group>
                <span>Please confirm that have included you supplier rebate % within your uploaded costs.</span>
              </div>
            </Modal.Body>
          </div>
        </Form>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="outline__button"
            onClick={() => {
              setCancelShow(true);
            }}
          >
            Cancel
          </Button>
          <Button variant="outline-primary" className="outline__button" type="submit" form="editSupplierQuote" onClick={() => setIsSave(true)}>
            Save
          </Button>
          <Button type="submit" form="editSupplierQuote" variant="primary" className="full__button" onClick={() => setIsSave(false)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={confirmationShow}
        aria-labelledby="contained-modal-title-vcEnter"
        backdropClassName="fullScreen"
        centered
        backdrop="static"
        onHide={() => setConfirmationShow(false)}
        dialogClassName="modal__popup_su"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Confirmation</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fullScreenStyling">
          <>
            <p>Are you sure you want to submit the quotation?</p>
            <p>Have you remembered to include your agreed supplier rebate % within your uploaded costs.</p>
            {(materialOther || (inputs.materialSupplierId && materialList.find(item => item.value === inputs.materialSupplierId)?.label?.toLowerCase() === 'other')) && (
              <>
                <Form.Text className="text-danger">The supplier is not using a CDS materials merchant</Form.Text>
                <br />
              </>
            )}
            <br />
            <Table style={{ border: '1px solid #e1d5ec' }} borderless={true}>
              <p>Pricing Summary Information</p>
              <table>
                <thead style={{ backgroundColor: '#eae3f1' }}>
                  <tr>
                    <th className="text-end">Production(&#163;)</th>
                    <th className="text-end">Material (&#163;)</th>
                    <th className="text-end">Delivery (&#163;)</th>
                    <th className="text-end">Total (&#163;)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-end">{Number(inputs.productionRate).toFixed(2)}</td>
                    <td className="text-end">{Number(inputs.materialRate).toFixed(2)}</td>
                    <td className="text-end">{Number(inputs.deliveryRate).toFixed(2)}</td>
                    <td className="text-end">{Number(Number(inputs.deliveryRate) + Number(inputs.materialRate) + Number(inputs.productionRate))?.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>

              {props.quantityRunOn != null && props.quantityRunOn != 0 && (
                <>
                  <hr />
                  <p>Runon Summary Information</p>

                  <table>
                    <thead style={{ backgroundColor: '#eae3f1' }}>
                      <tr>
                        <th className="text-end">Production Runon (&#163;)</th>
                        <th className="text-end">Material Runon (&#163;)</th>
                        <th className="text-end">Delivery Runon (&#163;)</th>
                        <th className="text-end">Total Runon (&#163;)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-end">{Number(inputs.productionRunon).toFixed(2)}</td>
                        <td className="text-end">{Number(inputs.materialRunon).toFixed(2)}</td>
                        <td className="text-end">{Number(inputs.deliveryRunon).toFixed(2)}</td>
                        <td className="text-end">{Number(Number(inputs.deliveryRunon) + Number(inputs.materialRunon) + Number(inputs.productionRunon))?.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
            </Table>
          </>
        </Modal.Body>
        <hr style={{ margin: '0px' }} />
        <Modal.Footer>
          <Button variant="light" className="outline__button_su" onClick={() => setConfirmationShow(false)}>
            No
          </Button>
          <Button
            variant="light"
            className="outline__button_su"
            type="submit"
            form="createQuote"
            onClick={e => {
              submitForm();
              setConfirmationShow(false);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal backdrop="static" keyboard={false} show={cancelShow} aria-labelledby="contained-modal-title-vcenter" backdropClassName="fullScreen" centered onHide={() => setCancelShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span>Are you sure? Any changes will be lost</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="modal_footer">
          <>
            <Button variant="outline-primary" onClick={() => setCancelShow(false)} className="outline__button">
              No
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                setCancelShow(false);
                onHide();
              }}
              className="full__button"
            >
              Yes
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditSupplierQuote;
