import { protectedResources } from '../../../../../configs/msalAuthConfigs';
import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { adminSuppliersActions as actions } from './';

//  Suppliers
function* setSupplierList() {
  const requestURL = `${protectedResources.api.apiEndpoint}/Supplier/api/Supplier/GetAllSupplier`;
  try {
    const supplierList: any = yield call(request, requestURL);
    if (supplierList) {
      yield put(actions.supplierListLoaded(supplierList));
    } else {
      yield put(
        actions.supplierListError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.supplierListError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.supplierListError({ error: 'Issue with api', loading: false }));
    }
  }
}

//  Geo Locations
function* setGeoLocations() {
  const requestURL = `${protectedResources.api.apiEndpoint}/MasterData/GeoLocations`;
  try {
    const response: any = yield call(request, requestURL);
    if (response.isSuccess) {
      yield put(actions.geoLocationsLoaded(response.data.items));
    } else {
      yield put(
        actions.geoLocationError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.geoLocationError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.geoLocationError({ error: 'Issue with api', loading: false }));
    }
  }
}

//  Supplier Categories
function* setSupplierCategories() {
  const requestURL = `${protectedResources.api.apiEndpoint}/MasterData/SupplierCategories`;
  try {
    const response: any = yield call(request, requestURL);
    if (response.isSuccess) {
      yield put(actions.supplierCategoriesLoaded(response.data));
    } else {
      yield put(
        actions.supplierCategoriesError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.supplierCategoriesError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.supplierCategoriesError({ error: 'Issue with api', loading: false }));
    }
  }
}

export function* adminSuppliersSaga() {
  yield takeLatest(actions.loadSupplierList.type, setSupplierList);
  yield takeLatest(actions.loadGeoLocations.type, setGeoLocations);
  yield takeLatest(actions.loadSupplierCategories.type, setSupplierCategories);
}
