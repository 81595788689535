import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { AddSuppliersPopup } from './AddSuppliersPopup';
import { SuppliersList } from './SuppliersList';
import { getSuppliersList } from '../../slice/selector';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const Suppliers = () => {
  const dispatch = useDispatch();
  const SuppliersRows = useSelector(getSuppliersList);
  const { actions: loadSuppliers } = useInvoiceSlice();
  const { accountId } = useParams();
  const [suppliersPopup, setSuppliersPopup] = useState(false);
  const [suppliersData, setSuppliersData] = useState<any>();
  const navigate = useNavigate();
  useEffect(() => {
    setSuppliersData(SuppliersRows);
  }, [SuppliersRows]);
  useEffect(() => {
    accountId && dispatch(loadSuppliers.loadSuppliersList(+accountId));
  }, []);

  return (
    <div className="products__page">
      <AddSuppliersPopup show={suppliersPopup} onHide={() => setSuppliersPopup(false)} suppliersData={suppliersData} />
      {suppliersData && suppliersData?.data?.items?.length === 0 ? (
        <div className="extra__container">
          <div className="addProduct__container">
            <Button className="full__button" variant="primary" style={{ paddingRight: '2rem', paddingLeft: '2rem' }} onClick={() => setSuppliersPopup(true)}>
              Add Suppliers
            </Button>
            <span style={{ marginTop: '0.5rem' }}>You have not added a Suppliers yet</span>
            <span>
              Click on <strong>'Add Suppliers'</strong>
            </span>
          </div>
        </div>
      ) : (
        <>
          <SuppliersList openSuppliersPopup={() => setSuppliersPopup(true)} />
        </>
      )}
      <footer>
        <Button className="full__button" variant="primary" style={{ paddingRight: '2rem', paddingLeft: '2rem' }} onClick={() => navigate(`/administration/account`)}>
          Done
        </Button>
      </footer>
    </div>
  );
};
