import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { useDispatch, useSelector } from 'react-redux';
import { myAccountList } from '../slice/selectors';
import { useMyProfileSlice } from '../slice';
import { Pagination } from '@mui/material';
import usePagination from '../Pagination';

const label = { inputProps: { 'aria-label': 'controlled' } };

export const MyAccounts = () => {
  let [page, setPage] = useState(1);
  const myAccountDetailsList = useSelector(myAccountList);
  const { actions: myProfileAction } = useMyProfileSlice();
  const dispatch = useDispatch();

  const dataPerPage = 20;
  const count = Math.ceil(myAccountDetailsList.length / dataPerPage);
  const _PerPageData_ = usePagination(myAccountDetailsList, dataPerPage);

  const handlePageChange = (e, p) => {
    setPage(p);
    _PerPageData_.jump(p);
  };

  return (
    <div className="account__container">
      <div className="account__list">
        <div className="account__list__header" style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
          <span>
            <Checkbox
              {...label}
              icon={<FavoriteBorder />}
              checkedIcon={<Favorite />}
              color="error"
              checked={myAccountDetailsList.filter(item => !item.isFavourite).length == 0}
              onChange={event =>
                dispatch(
                  myProfileAction.addOrRemoveUserFavouriteAccount({
                    requestBody: {
                      accountIds: myAccountDetailsList.map(item => item.accountId),
                      isRemove: event.target.checked ? false : true,
                    },
                    isAll: true,
                  }),
                )
              }
            />
          </span>
          <span className="account__list__name">Account Name</span>
        </div>
        <div className="account__body">
          {_PerPageData_.currentData()?.map(items => (
            <div key={`${items.name}_${items.accountId}`}>
              <span>
                <Checkbox
                  {...label}
                  icon={<FavoriteBorder />}
                  id={items.accountId.toString()}
                  checkedIcon={<Favorite />}
                  color="error"
                  checked={items.isFavourite}
                  onChange={event =>
                    dispatch(
                      myProfileAction.addOrRemoveUserFavouriteAccount({
                        requestBody: {
                          accountIds: [Number(event.target.id)],
                          isRemove: event.target.checked ? false : true,
                        },
                        isAll: false,
                      }),
                    )
                  }
                />
              </span>
              <span className="account__list__name">{items.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination">
        <Pagination count={count} size="large" page={page} onChange={handlePageChange} />
      </div>
    </div>
  );
};
