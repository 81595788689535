import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { MyProfileActions as actions } from '.';

function* getMyAccounts() {
  const requestURL = `${protectedResources.api.apiEndpoint}/User/GetUserFavouriteAccounts`;
  try {
    const myAccountData: any = yield call(request, requestURL);
    if (myAccountData) {
      yield put(actions.myAccountLoaded(myAccountData));
    } else {
      yield put(
        actions.myProfileError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.myProfileError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.myProfileError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* getMyTeam() {
  const requestURL = `${protectedResources.api.apiEndpoint}/User/GetUserTeamMembers`;
  try {
    const myAccountData: any = yield call(request, requestURL);
    if (myAccountData) {
      yield put(actions.myTeamLoaded(myAccountData));
    } else {
      yield put(
        actions.myProfileError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.myProfileError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.myProfileError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* addOrRemoveTeamMember(action) {
  const requestURL = `${protectedResources.api.apiEndpoint}/User/AddOrRemoveUserTeamMembers`;
  try {
    const myAccountData: any = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(action.payload.requestBody),
    });
    if (myAccountData) {
      yield put(actions.updateMyTeamList(action.payload));
    } else {
      yield put(
        actions.myProfileError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.myProfileError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.myProfileError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* addOrRemoveFavouriteUser(action) {
  const requestURL = `${protectedResources.api.apiEndpoint}/User/AddOrRemoveUserFavouriteAccount`;
  try {
    const myAccountData: any = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(action.payload.requestBody),
    });
    if (myAccountData) {
      yield put(actions.updateFavouriteList(action.payload));
    } else {
      yield put(
        actions.myProfileError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.myProfileError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.myProfileError({ error: 'Issue with api', loading: false }));
    }
  }
}
function* userProfileData(action) {
  let userId = action.payload.userId;
  const requestURL = `${protectedResources.api.apiEndpoint}/User/GetProfile?UserId=${userId}`;
  try {
    const myAccountData: any = yield call(request, requestURL);
    if (myAccountData) {
      yield put(actions.myProfileLoaded(myAccountData));
    } else {
      yield put(
        actions.myProfileError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.myProfileError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.myProfileError({ error: 'Issue with api', loading: false }));
    }
  }
}

export function* myProfileSaga() {
  yield takeLatest(actions.loadMyAccount.type, getMyAccounts);
  yield takeLatest(actions.loadMyProfile.type, userProfileData);
  yield takeLatest(actions.loadMyTeam.type, getMyTeam);
  yield takeLatest(actions.addOrRemoveMyTeam.type, addOrRemoveTeamMember);
  yield takeLatest(actions.addOrRemoveUserFavouriteAccount.type, addOrRemoveFavouriteUser);
}
