import { protectedResources } from 'configs/msalAuthConfigs';
import { call, put, takeEvery } from 'redux-saga/effects';
import { request } from 'utils/request';
import { createQuoteActions as actions } from '.';
import { vatRate, supplierListRow, material } from './types';

function* getVatRate() {
  const requestURL = `${protectedResources.api.apiEndpoint}/MasterData/GetVatRate`;
  try {
    const vatRate: vatRate[] = yield call(request, requestURL);
    if (vatRate) {
      yield put(actions.CreateQuoteVatRate(vatRate));
    } else {
      yield put(
        actions.CreateQuoteError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.CreateQuoteError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.CreateQuoteError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* setSupplierList(action) {
  const order = action?.payload?.order;
  const outPut = action?.payload?.filter;
  const isFilter = action?.payload?.isFilter;
  let requestURL = order
    ? isFilter
      ? `${protectedResources.api.apiEndpoint}/Quotes/SupplierQuotelist?Orders=${true}${outPut}`
      : `${protectedResources.api.apiEndpoint}/Quotes/SupplierQuotelist?Orders=${true}`
    : isFilter
    ? `${protectedResources.api.apiEndpoint}/Quotes/SupplierQuotelist?Orders=${false}${outPut}`
    : `${protectedResources.api.apiEndpoint}/Quotes/SupplierQuotelist`;
  try {
    const response: any = yield call(request, requestURL);
    if (response.isSuccess) {
      const supplierList: supplierListRow[] = response.data.items;
      if (supplierList) {
        yield put(actions.suppliersList(supplierList));
      } else {
        yield put(
          actions.CreateQuoteError({
            error: 'Received an empty response',
            loading: false,
          }),
        );
      }
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.CreateQuoteError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.CreateQuoteError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* getPrintSpecifications(action) {
  const productTemplateId = action.payload.productTemplateId;
  const requestURL = `${protectedResources.api.apiEndpoint}/Quotes/GetSupplierProductDetails/${productTemplateId}`;
  try {
    const printSpecification: any = yield call(request, requestURL);
    if (printSpecification.isSuccess) {
      printSpecification.data.isActive = printSpecification.data.isActive ? 'Yes' : 'No';
      printSpecification.data.laminated = printSpecification.data.laminated ? 'Yes' : 'No';
      printSpecification.data.samples = printSpecification.data.samples ? 'Yes' : 'No';
      printSpecification.data.sealerOnCover = printSpecification.data.sealerOnCover ? 'Yes' : 'No';
      printSpecification.data.sealerOnFace = printSpecification.data.sealerOnFace ? 'Yes' : 'No';
      printSpecification.data.sealerOnReverse = printSpecification.data.sealerOnReverse ? 'Yes' : 'No';
      printSpecification.data.sealerOnText = printSpecification.data.sealerOnText ? 'Yes' : 'No';
      yield put(actions.CreatePrintSpecifications([printSpecification.data]));
    } else {
      yield put(
        actions.CreateQuoteError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.CreateQuoteError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.CreateQuoteError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* getMaterial() {
  const requestURL = `${protectedResources.api.apiEndpoint}/MasterData/GetMerchatRate`;
  try {
    const data: material[] = yield call(request, requestURL);
    if (data) {
      yield put(actions.materialLoaded(data));
    } else {
      yield put(
        actions.CreateQuoteError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.CreateQuoteError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.CreateQuoteError({ error: 'Issue with api', loading: false }));
    }
  }
}

export function* CreateQuoteSaga() {
  yield takeEvery(actions.loadRFQModal.type, getVatRate);
  yield takeEvery(actions.loadRFQsList.type, setSupplierList);
  yield takeEvery(actions.loadSupplierList.type, getPrintSpecifications);
  yield takeEvery(actions.loadRFQModal.type, getMaterial);
}
