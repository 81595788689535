//  Externals
import React from 'react';

const BannerDetails = props => {
  const { productItemDetails, type } = props;

  return (
    <>
      {type === 'clientQuote' ? (
        <>
          <div className="grid__container">
            <div>
              <span>Reference</span>
              <p className="fw-bold font-14">{productItemDetails?.lineId}</p>
            </div>
            <div>
              <span>Quantity</span>
              <p className="fw-bold font-14">{productItemDetails?.quantity}</p>
            </div>
            {productItemDetails?.quantityRunOn !== 0 && productItemDetails?.quantityRunOn !== null && (
              <>
                <div>
                  <span>Runon Quantity</span>
                  <p className="fw-bold font-14">{productItemDetails?.quantityRunOn}</p>
                </div>
                {productItemDetails?.quantityActual !== 0 && productItemDetails?.quantityActual !== null && (
                  <div>
                    <span>Quantity Actual</span>
                    <p className="fw-bold font-14">{productItemDetails?.quantityActual}</p>
                  </div>
                )}
              </>
            )}
            <div>
              <span>No.Of Pages</span>
              <p className="fw-bold font-14">{productItemDetails?.noOfPages}</p>
            </div>
          </div>
          <div className="mt-3">
            <span>Finished Size</span>
            <p className="fw-bold font-14 p_wrap">{productItemDetails?.finishedSizeOther}</p>
          </div>
          <div className="mt-3">
            <span>Colours</span>
            <p className="fw-bold font-14 p_wrap">{productItemDetails?.coloursOther}</p>
          </div>
          <div className="mt-3">
            <span>Material Type</span>
            <p className="fw-bold font-14 p_wrap">{productItemDetails?.materialOther}</p>
          </div>
          <div className="mt-3">
            <span>Finishing</span>
            <p className="fw-bold font-14 p_wrap">{productItemDetails?.finishingOther}</p>
          </div>
          <div className="mt-3">
            <span>Addendum</span>
            <p className="fw-bold font-14 p_wrap">{productItemDetails?.addendum}</p>
          </div>
        </>
      ) : (
        <div className="border-bottom pt-10 pb-10">
          <div className="d-flex">
            {productItemDetails?.quantityRunOn === 0 || productItemDetails?.quantityRunOn === null ? (
              <div className="fare_details">
                <h6 className="h6_style">Quantity</h6>
                <span>{productItemDetails?.quantity}</span>
              </div>
            ) : (
              <div className="fare_details">
                <h6 className="h6_style">Quantity Actual</h6>
                <span>{productItemDetails?.quantityActual}</span>
              </div>
            )}
            <div className="fare_details">
              <h6 className="h6_style">No.Of Pages</h6>
              <span>{productItemDetails?.noOfPages}</span>
            </div>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Finished Size</h6>
            <span className="p_wrap">{productItemDetails?.finishedSizeOther}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Colours</h6>
            <span className="p_wrap">{productItemDetails?.coloursOther}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Material Type</h6>
            <span className="p_wrap">{productItemDetails?.materialOther}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Finishing</h6>
            <span className="p_wrap">{productItemDetails?.finishingOther}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style p_wrap">Addendum</h6>
            <span className="p_wrap">{productItemDetails?.addendum != null ? productItemDetails?.addendum : '-'}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerDetails;
