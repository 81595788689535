export interface SupplierType {
  categories: any;
  supplierName: string | null;
  supplierReference: string | null;
  contactEmailAddress: string | null;
  address: string | null;
  postCode: string | null;
  telephone: number | null;
  codeName: string | null;
  website: string | null;
  notes: string | null;
  isActive: boolean | string | null;
  reminderPreferrences: boolean | null;
  vendorId: string | null;
  supplierGeolocationsId: (number | null)[];
  supplierId?: number;
}

export interface Category {
  primaryCategoryId: null | number;
  secondaryCategoryId: number[];
}

export interface SecondaryCategory {
  primaryCategoryId: number;
  label: string;
  value: number;
}

export const initialSupplier = {
  categories: [],
  supplierName: null,
  supplierReference: null,
  contactEmailAddress: null,
  address: null,
  postCode: null,
  telephone: null,
  codeName: null,
  website: null,
  notes: null,
  isActive: null,
  reminderPreferrences: null,
  vendorId: null,
  supplierGeolocationsId: [],
};

export const initialCategory: Category = {
  primaryCategoryId: null,
  secondaryCategoryId: [],
};
