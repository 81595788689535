import React, { useState } from 'react';

import CustomSelect from 'app/components/shared/CustomSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col, Button } from 'react-bootstrap';
import SendInvoicePopup from './SendInvoicePopUp';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import './style.sass';

export const InvoiceSelector = props => {
  const { invoice, invoiceWeekly, invoiceAccount, getData, getFrequencyData } = props;

  const validationSchema = yup.object({
    AllAccounts: yup.number().nullable().required(),
    InvoiceFreQuency: yup.number().nullable().required(),
  });
  const {
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onAllAccountsChange = Id => {
    invoice.getReadytoInvoice?.map(item => {
      if (item?.accountId === Id) {
        getData(Id);
        setValue('InvoiceFreQuency', item?.frequency, {
          shouldValidate: true,
        });
      }
      if (Id === undefined) {
        getData(Id);
      }
    });
  };
  const onFrequencyChange = Id => {
    invoice.getReadytoInvoice?.map(item => {
      if (item?.frequency === Id) {
        getFrequencyData(Id);
      }
      if (Id === undefined) {
        getFrequencyData(Id);
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Ready To Invoice</title>
      </Helmet>
      <Row style={{ margin: '20px' }}>
        <Col xs></Col>
        <Col xs={{ order: 12 }}>
          <CustomSelect
            required={false}
            controlId={'form__InvoiceFreQuency'}
            label={''}
            control={control}
            name={'Invoice FreQuency'}
            placeholder={'Frequency'}
            options={invoiceWeekly.map(contact => {
              return {
                value: contact,
                label: contact,
              };
            })}
            errors={errors.contactId}
            enableMultilineOptions={true}
            isSearchable={true}
            isDisabled={false}
            onChange={onFrequencyChange}
          />
        </Col>
        <Col xs={{ order: 1 }}>
          <CustomSelect
            required={false}
            controlId={'form__AllAccounts'}
            label={''}
            control={control}
            name={'AllAccounts'}
            placeholder={'All Accounts'}
            options={invoiceAccount.map(contact => {
              return {
                value: +contact.value,
                label: contact.name,
              };
            })}
            errors={errors.contactId}
            enableMultilineOptions={true}
            isSearchable={true}
            isDisabled={false}
            onChange={onAllAccountsChange}
          />
        </Col>
      </Row>
    </>
  );
};

export const InvoiceFooter = props => {
  const { invoiceFooter, invoiceRow, sendInvoiceId } = props;
  const [sendInvoicePopup, setSendInvoicePopup] = useState<Boolean>(false);

  const handleSendInvoice = () => {
    setSendInvoicePopup(true);
  };
  return (
    <>
      <div className="invoiceFooter" style={{ backgroundColor: '#F2F5F5', borderTop: '1px solid black', position: 'fixed', bottom: '0px', display: 'flex' }}>
        <span style={{ marginRight: '180px', marginLeft: '20px', marginTop: '20px' }}>Total</span>
        <span style={{ marginRight: '150px' }}>
          <span className="Invoice_Body_head"> No.of Jobs</span>
          <h6>{invoiceFooter.noofJobs}</h6>
        </span>
        <span style={{ marginRight: '150px' }}>
          <span className="Invoice_Body_head" style={{ textAlign: 'right' }}>
            Net Total (£)
          </span>
          <h6 style={{ textAlign: 'right' }}>{invoiceFooter.netTotals?.toFixed(2)}</h6>
        </span>
        <span style={{ marginRight: '150px' }}>
          <span className="Invoice_Body_head" style={{ textAlign: 'right' }}>
            VAT amount (£)
          </span>
          <h6 style={{ textAlign: 'right' }}>{invoiceFooter.vatAmounts?.toFixed(2)}</h6>
        </span>
        <span style={{ marginRight: '150px' }}>
          <span className="Invoice_Body_head" style={{ textAlign: 'right' }}>
            Gross (£)
          </span>
          <h6 style={{ textAlign: 'right' }}>{invoiceFooter.grossAmounts?.toFixed(2)}</h6>
        </span>
        <Button onClick={handleSendInvoice} disabled={sendInvoiceId?.length === 0} style={{ marginTop: '8px', height: '38px', width: '150px' }} variant="outline-primary">
          Send to invoice
        </Button>
      </div>
      <SendInvoicePopup show={sendInvoicePopup} sendInvoiceId={sendInvoiceId} invoiceRow={invoiceRow} onHide={() => setSendInvoicePopup(false)} />
    </>
  );
};
