//  Internals
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
//  Externals
import LargeFormatDetails from './../../../components/modules/products/largeFormatBoard/details';
import BusinessCardsDetails from './../../../components/modules/products/businessCards/details';
import PullUpBannerDetails from './../../../components/modules/products/pullUpBanner/details';
import SpecialItemDetails from './../../../components/modules/products/specialItem/details';
import LetterheadDetails from './../../../components/modules/products/letterhead/details';
import PostcardsDetails from './../../../components/modules/products/postcards/details';
import ClothingDetails from './../../../components/modules/products/clothing/details';
import BookletDetails from './../../../components/modules/products/booklet/details';
import LeafletDetails from './../../../components/modules/products/leaflet/details';
import SignageDetails from './../../../components/modules/products/signage/details';
import BannerDetails from './../../../components/modules/products/banner/details';
import PosterDetails from './../../../components/modules/products/poster/details';
import CardsDetails from './../../../components/modules/products/cards/details';
import FlyerDetails from './../../../components/modules/products/flyer/details';
import { getMasterData } from '../../../../store/reducers/masterData/selectors';
import BrochureDetails from 'app/components/modules/products/brochure/details';
import BagDetails from './../../../components/modules/products/bag/details';
import { getProductItems } from 'store/reducers/entities/selectors';
import VALIDATIONS from '../../../../data/validations';
import MODULES from '../../../../data/modules';
import {
  BAG,
  BANNER,
  BOOKLET,
  BROCHURE,
  BUSINESS_CARDS,
  CARDS,
  CLOTHING,
  FLYER,
  LARGE_FORMAT_BOARD,
  LEAFLET,
  LETTERHEAD,
  POSTCARDS,
  POSTER,
  PULL_UP_BANNER,
  SIGNAGE,
  SPECIAL_ITEM,
} from '../../../../data/constants';

const ProductItemDetails = props => {
  const { item } = props;
  const productItems = useSelector(getProductItems(MODULES.MY_JOBS));
  const productItem = productItems.find(product => product.printSpecifications.templateId === item.templateId).printSpecifications;

  const artworkFormats = useSelector(getMasterData('artworkFormats'));
  const typeOfProofs = useSelector(getMasterData('typesOfProofs'));

  const [artworkFormatValue, setArtworkFormatValue] = useState('-');
  const [typeOfProofsValue, setTypeOfProofsValue] = useState('-');

  useEffect(() => {
    const { artworkFormatId, typeOfProofsId } = productItem;
    //  Artwork Format
    const artworkFormat = artworkFormats.find(entity => entity.value === artworkFormatId);
    if (artworkFormat && artworkFormat.label === VALIDATIONS.OTHER) setArtworkFormatValue(productItem.artworkFormatOther);
    else setArtworkFormatValue(artworkFormat.label);
    //  Type of Proofs
    const typeOfProof = typeOfProofs.find(entity => entity.value === typeOfProofsId);
    if (typeOfProof && typeOfProof.label === VALIDATIONS.OTHER) setTypeOfProofsValue(productItem.typeOfProofsOther);
    else setTypeOfProofsValue(typeOfProof.label);
  }, [artworkFormats, productItem, productItems, typeOfProofs]);

  return (
    <>
      {productItem.productType === BAG && <BagDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === BANNER && <BannerDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === BOOKLET && <BookletDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === BROCHURE && <BrochureDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === BUSINESS_CARDS && <BusinessCardsDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === CARDS && <CardsDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === CLOTHING && <ClothingDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === FLYER && <FlyerDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === LARGE_FORMAT_BOARD && <LargeFormatDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === LEAFLET && <LeafletDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === LETTERHEAD && <LetterheadDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === POSTCARDS && <PostcardsDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === POSTER && <PosterDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === PULL_UP_BANNER && <PullUpBannerDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === SIGNAGE && <SignageDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      {productItem.productType === SPECIAL_ITEM && <SpecialItemDetails productItemDetails={productItem} type="placeSupplierOrder" />}
      <div className="border-bottom pt-10">
        <div className="fare_details">
          <h6 className="h6_style">Delivery Contact</h6>
          <span>{productItem.deliveryContact != null && productItem.deliveryContact != '' ? productItem.deliveryContact : '-'}</span>
        </div>
        <div className="fare_details">
          <h6 className="h6_style">Delivery Address</h6>
          <span className="p_wrap">{productItem.deliveryAddress != null && productItem.deliveryAddress != '' ? productItem.deliveryAddress : '-'}</span>
        </div>
        <div className="fare_details">
          <h6 className="h6_style">Delivery Instructions</h6>
          <span className="p_wrap">{productItem.deliveryInstructions != null && productItem.deliveryInstructions != '' ? productItem.deliveryInstructions : '-'}</span>
        </div>
        <div className="fare_details">
          <h6 className="h6_style">Delivery Due Date</h6>
          <p>{productItem.deliveryDueDate != null && productItem.deliveryDueDate != '' ? moment(productItem.deliveryDueDate).format('DD/MM/YYYY') : '-'}</p>
        </div>
        <div className="fare_details">
          <h6 className="h6_style">No Fail Deliver Date</h6>
          <p>{productItem.noFailDeliveryDate === true ? 'Yes' : 'No'}</p>
        </div>
      </div>
      <div className="border-bottom">
        <h6 className="h6_style">Notes</h6>
        <p className="p_wrap">{productItem.supplierNotes !== null && productItem.supplierNotes !== '' ? productItem.supplierNotes : '-'}</p>
      </div>
      <div className="border-bottom pt-10">
        <div className="fare_details">
          <h6 className="h6_style">Artwork Format</h6>
          <p className="p_wrap">{artworkFormatValue}</p>
        </div>
        <div className="fare_details">
          <h6 className="h6_style">Artwork Due Date</h6>
          <p>{productItem.artworkDueDate !== null ? `${moment(productItem.artworkDueDate).format('DD/MM/YYYY')}` : '-'}</p>
        </div>
        <div className="fare_details">
          <h6 className="h6_style">Type Of Proofs</h6>
          <p className="p_wrap">{typeOfProofsValue}</p>
        </div>
        <div className="fare_details">
          <h6 className="h6_style">Proof Due Date</h6>
          <p>{productItem.proofDueDate !== null && productItem.proofDueDate !== '' ? `${moment(productItem.proofDueDate).format('DD/MM/YYYY')}` : ' -'}</p>
        </div>
      </div>
      <div>
        <div className="fare_details pt-10">
          <h6 className="h6_style">Samples</h6>
          <p>{productItem.samples}</p>
        </div>
        {productItem.samples === 'Yes' && (
          <>
            <div className="fare_details">
              <h6 className="h6_style">Number Of Sample Copies</h6>
              <span>{productItem.noOfSampleCopies}</span>
            </div>
            <div className="fare_details">
              <h6 style={{ color: '#8D8C8D' }}>Sample Copies Delivery Address</h6>
              <span className="p_wrap">{productItem.sampleCopiesDeliveryAddress}</span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProductItemDetails;
