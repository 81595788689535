//  Externals
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { request } from 'utils/request';
import * as yup from 'yup';
//  Internals
import { getSelectApprovers } from 'app/pages/adminstrationAccount/slice/selector';
import { useInvoiceSlice } from 'app/pages/adminstrationAccount/slice';
import { getMasterData } from 'store/reducers/masterData/selectors';
import CustomSelect from 'app/components/shared/CustomSelect';
import { protectedResources } from 'configs/msalAuthConfigs';
import { setUpdateJobData } from 'store/reducers/entities';
import { apiCallBegan } from 'store/reducers/loading';
import MODULES from '../../../../../../data/modules';
import API from '../../../../../../data/api';

const AddDiscounts = props => {
  const { show, onHide, printSpecifications, clientQuotations } = props;

  const discountReasons = useSelector(getMasterData('discountReasons'));
  const { actions: approversAction } = useInvoiceSlice();
  const { actions: accountAction } = useInvoiceSlice();
  const getApprovers = useSelector(getSelectApprovers);
  const dispatch = useDispatch();

  const [totalSupplierNetValue, setTotalSupplierNetValue] = useState<number>();
  const [totalCurrentValue, setTotalCurrentValue] = useState<number>();
  const [supplierNetValue, setSupplierNetValue] = useState<number>();
  const [supplierValidate, setSupplierValidate] = useState(false);
  const [selectApprovers, setSelectApprovers] = useState<any>({});
  const [currentValue, setCurrentValue] = useState<any>(false);
  const [clientValidate, setClientValidate] = useState(false);
  const [newLineValue, setNewLineValue] = useState<number>();

  const [inputs, setInput] = useState<any>({
    client: null,
    supplier: null,
  });
  const validationSchema = yup.object({
    isActive: yup.string().trim().nullable().nullable(),
    addUser: yup.string().trim().nullable().nullable(),
    analsysCategoryId: yup.number().nullable(),
    reference: yup.string().nullable(),
    name: yup.string().nullable(),
  });

  const { register, handleSubmit, control, getValues, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const handleInputChange = e => {
    setCurrentValue(false);
    setInput({
      ...inputs,
      [e.target.name]: e.target?.checked ? e.target.checked : e.target.value,
    });
  };

  useEffect(() => {
    setTotalCurrentValue(clientQuotations?.actualSummary?.totalNetPrice);
    setTotalSupplierNetValue(clientQuotations?.actualSummary?.supplierNetValue);
  }, [clientQuotations?.actualSummary?.supplierNetValue, clientQuotations?.actualSummary?.totalNetPrice]);

  const handleCalculateNewLineValue = () => {
    const totalDiscountValidation = Number(totalCurrentValue) - Number(inputs?.client);
    const newLineValueCalculate = totalCurrentValue && totalCurrentValue - Number(inputs?.client);
    const totalDiscountSupValidation = Number(totalSupplierNetValue) - Number(inputs?.supplier);
    const newSupplierOrderValue = totalSupplierNetValue && totalSupplierNetValue - Number(inputs?.supplier);
    setNewLineValue(newLineValueCalculate);
    setSupplierNetValue(newSupplierOrderValue);
    if ((Number(inputs?.client) >= 0 || Number(inputs?.supplier) >= 0) && totalDiscountValidation >= 0 && totalDiscountSupValidation >= 0) {
      setCurrentValue(true);
      setClientValidate(false);
      setSupplierValidate(false);
    }
    if (totalDiscountValidation < 0) {
      setClientValidate(true);
    }
    if (totalDiscountSupValidation < 0) {
      setSupplierValidate(true);
    }
  };

  useEffect(() => {
    setSelectApprovers(getApprovers);
  }, [getApprovers]);

  useEffect(() => {
    dispatch(approversAction.loadApprovers());
  }, [accountAction, approversAction, dispatch]);

  const onSubmit = async data => {
    const formData = {
      templateId: printSpecifications?.templateId,
      clientDiscount: inputs?.client != null ? Number(inputs?.client) : null,
      supplierDiscount: inputs?.supplier != null ? Number(inputs?.supplier) : null,
      discountReasonId: data?.discountReasonId,
      discountReasonOther: data?.discountReasonOther,
      approver: data?.approver,
    };
    try {
      const response: any = await request(`${protectedResources.api.apiEndpoint}/Products/SubmitDiscountForApproval`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      if (response.isSuccess) {
        dispatch(apiCallBegan({ url: `${API.GET_JOB}/${printSpecifications.jobId}`, method: 'GET', onSuccess: setUpdateJobData.type, module: MODULES.MY_JOBS }));
      }
    } catch (err) {}
    reset();
    setCurrentValue(false);
    onHide();
  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="fullScreen"
      style={{ width: '60%', marginLeft: '20%' }}
      centered
      dialogClassName="modal__maxFullScreen"
      scrollable={true}
      keyboard={false}
      onHide={() => {
        onHide();
      }}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Add Discount</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="DiscountForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row">
              <div className="col">
                <strong>Name</strong>
              </div>
              <div className="col">{printSpecifications.title}</div>
              <div className="col"></div>
            </div>
            <div className="row" style={{ marginTop: '10px' }}>
              <div className="col">
                <strong>Product Type</strong>
              </div>
              <div className="col">{printSpecifications.productType}</div>
              <div className="col"></div>
            </div>
            <div className="row" style={{ marginTop: '10px' }}>
              <div className="col">
                <strong>Current Value</strong>
              </div>
              <div className="col">{totalCurrentValue?.toFixed(2)}</div>
              <div className="col"></div>
            </div>
          </div>
          <br />
          <div className="container">
            <div className="row">
              <div className="col">
                <Form.Group style={{ width: '300px', marginRight: '35px' }} controlId="form__reference">
                  <Form.Label>
                    <span>Discount Offered To Client (£)</span>
                  </Form.Label>
                  <Form.Control placeholder={'Enter'} autoComplete="off" type="number" onChange={e => handleInputChange(e)} name="client" />
                  {clientValidate && <Form.Text className="text-danger">"Discount Offered To Client" is less then "Current Value"!</Form.Text>}
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group style={{ width: '300px', marginRight: '35px' }} controlId="form__reference">
                  <Form.Label>
                    <span>Discount From Supplier (£)</span>
                  </Form.Label>
                  <Form.Control placeholder={'Enter'} autoComplete="off" type="number" onChange={e => handleInputChange(e)} name="supplier" />
                  {supplierValidate && <Form.Text className="text-danger">"Discount From Supplier" is less then "supplier Net Value" which is({totalSupplierNetValue})!</Form.Text>}
                </Form.Group>
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-end">
            <Button
              disabled={Number(inputs?.client) === 0 && Number(inputs?.supplier) === 0}
              style={{ width: '15rem', borderRadius: '5px' }}
              variant={currentValue ? 'secondary' : 'dark'}
              onClick={() => handleCalculateNewLineValue()}
            >
              Calculate New Line Value
            </Button>
          </div>
          {currentValue && currentValue ? (
            <div>
              <hr />
              <div className="container">
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col">
                    <strong>Discount % offered to client</strong>
                  </div>
                  <div className="col">{inputs?.client != null ? <span>{((Number(inputs?.client) / printSpecifications?.value) * 100).toFixed(2)}&#x25;</span> : '-'}</div>
                  <div className="col"></div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col">
                    <strong>Discount % from supplier</strong>
                  </div>
                  <div className="col">{inputs?.supplier != null ? <span>{((Number(inputs?.supplier) / printSpecifications?.supplierNetValue) * 100)?.toFixed(2)}&#x25;</span> : '-'}</div>
                  <div className="col"></div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col">
                    <strong>New Line Value</strong>
                  </div>
                  <div className="col">
                    <strong>£ {Number(newLineValue)?.toFixed(2)}</strong>
                  </div>
                  <div className="col"></div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  <div className="col">
                    <strong>New Supplier Order Value</strong>
                  </div>
                  <div className="col">
                    <strong>£ {Number(supplierNetValue)?.toFixed(2)}</strong>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
              <br />
              <Form.Group style={{ width: '300px' }}>
                <Form.Label>
                  Reason for Discount<span className="input__required">*</span>
                </Form.Label>
                <CustomSelect
                  isDisabled={false}
                  required={false}
                  controlId={'form__discountReasonId'}
                  label={''}
                  control={control}
                  name={'discountReasonId'}
                  placeholder={'Other'}
                  options={discountReasons}
                  errors={undefined}
                  enableMultilineOptions={false}
                  isSearchable={false}
                  register={register}
                  value={getValues('discountReasonId')}
                  readOnlyFormField={false}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3" style={{ width: '600px' }} controlId="form_discountReasonOther">
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Description</span>
                </Form.Label>
                <Form.Control as="textarea" placeholder="Enter Discount Reason" rows={3} autoComplete="off" type="text" {...register('discountReasonOther')} />
              </Form.Group>
              <Form.Group style={{ width: '300px' }}>
                <CustomSelect
                  isDisabled={false}
                  required={false}
                  controlId={'form__Approvers'}
                  label={'Approver'}
                  control={control}
                  name={'approver'}
                  placeholder={'Select'}
                  options={selectApprovers?.data?.items?.map(item => {
                    return {
                      value: +item?.id,
                      label: item?.name,
                    };
                  })}
                  errors={undefined}
                  enableMultilineOptions={false}
                  isSearchable={false}
                  register={register}
                  value={getValues('approver')}
                  readOnlyFormField={false}
                />
              </Form.Group>
            </div>
          ) : (
            ''
          )}
        </Form>
      </Modal.Body>
      <hr style={{ marginBottom: '0px' }} />
      {currentValue && (
        <Modal.Footer style={{ paddingBottom: '50px', height: '50px' }}>
          <Button variant="outline-primary" className="outline__button" onClick={() => onHide()}>
            Cancel
          </Button>
          <Button variant="primary" className="full__button" form="DiscountForm" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default AddDiscounts;
