//  Months
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

//  Job information navigation
export const JOB_INFORMATION = 'jobInformation';
export const PRODUCT_ITEMS = 'productItems';
export const OTHER_COSTS = 'otherCosts';
export const TASKS = 'tasks';
export const AUDIT = 'audit';

//  Product Item navigation
export const PRINT_SPECIFICATIONS = 'printSpecifications';
export const QUOTES = 'quotes';

//  Products
export const BAG = 'Bag';
export const BANNER = 'Banner';
export const BOOKLET = 'Booklet';
export const BROCHURE = 'Brochure';
export const BUSINESS_CARDS = 'Business Cards';
export const CARDS = 'Cards';
export const CLOTHING = 'Clothing';
export const FLYER = 'Flyer';
export const LARGE_FORMAT_BOARD = 'Large Format Board';
export const LEAFLET = 'Leaflet';
export const LETTERHEAD = 'Letterhead';
export const POSTCARDS = 'Post Cards';
export const POSTER = 'Poster';
export const PULL_UP_BANNER = 'Pull Up Banner';
export const SIGNAGE = 'Signage';
export const SPECIAL_ITEM = 'Special Item';
