import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '@mui/material';
import { useForm } from 'react-hook-form';
import { request } from 'utils/request';
import { protectedResources } from 'configs/msalAuthConfigs';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useInvoiceSlice } from '../../slice';

export const AddProductsPopup = props => {
  const { onHide, show, accountList, productsTableData } = props;
  const dispatch = useDispatch();
  const { actions: accountAction } = useInvoiceSlice();
  const { accountId } = useParams();
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const validationSchema = yup.object({});

  const handleProductCount = Id => {
    setSelectedProduct([...selectedProduct, Id]);
  };
  const postIds = selectedProduct?.filter((item, index) => selectedProduct.indexOf(item) === index);
  const FilteredProductsTableData = productsTableData?.data?.items?.map(item => item?.productId);
  const FilteredProducts = accountList?.data?.items?.filter(item => !FilteredProductsTableData?.includes(item?.productId));

  const { handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const formData = {
      accountId: Number(accountId),
      productIds: [...postIds],
      isRemove: false,
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/AD/AddORRemoveAccountProducts`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      accountId && dispatch(accountAction.loadProduct(+accountId));
    } catch (err) {}
    setSelectedProduct([]);
    onHide();
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="fullScreen"
      style={{ width: '80%', marginLeft: '10%' }}
      centered
      dialogClassName="modal__printSpecificationForm"
      keyboard={false}
      onHide={() => {
        onHide();
      }}
      scrollable
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>Add Products</h2>
        </Modal.Title>
      </Modal.Header>
      <hr style={{ margin: '0px' }} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body style={{ height: '380px', marginBottom: '50px' }}>
          {accountList && FilteredProducts?.length > 0 ? (
            <>
              <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
                {FilteredProducts?.map(item => (
                  <div style={{ width: '350px', padding: '10px' }} key={item.productId}>
                    <Checkbox
                      onClick={() => handleProductCount(item.productId)}
                      sx={{
                        color: '#8D8C8D',
                        '&.Mui-checked': {
                          color: '#1BA39C',
                        },
                      }}
                    />
                    <span>{item?.name}</span>
                  </div>
                ))}
              </div>
            </>
          ) : (
            'All Products Added For this Account'
          )}
        </Modal.Body>
        <hr style={{ margin: '0px' }} />
        <Modal.Footer>
          <Button style={{ border: '0px', color: 'black', width: '15%' }} variant="outline-primary" onClick={() => onHide()}>
            Cancel
          </Button>
          <Button disabled={FilteredProducts.length === 0} variant="outline-primary" style={{ border: '0px', color: 'black', width: '15%' }} type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
