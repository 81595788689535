//  Externals
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
//  Internals
import { setDropdowns, setJobInformationNavigation, showFullNavigation, changeNavigation, setJobData } from '../../../../store/reducers/entities/index';
import { JOB_INFORMATION, OTHER_COSTS, PRODUCT_ITEMS, TASKS, AUDIT } from '../../../../data/constants';
import { getOtherJobCostTableList } from '../../../pages/requestForQuote/slice/selectors';
import { getJobInformation, getNavigation, getActionToggle } from 'store/reducers/entities/selectors';
import { getProductItems } from '../../../../store/reducers/entities/selectors';
import { useRequestForQuoteSlice } from '../../../pages/requestForQuote/slice';
import { useBreadcrumbSlice } from '../../../pages/breadcrumb/slice';
import OtherCosts from '../../../pages/requestForQuote/OtherCosts';
import { apiCallBegan } from '../../../../store/reducers/loading';
import { ProductItemType } from '../../../../types/myJobs';
import Tasks from '../../../pages/requestForQuote/Tasks';
import Breadcrumb from '../../../pages/breadcrumb';
import MODULES from '../../../../data/modules';
import JobInformation from './JobInformation';
import ProductItems from './productItems';
import Audit from '../../../pages/audit';
import URL from '../../../../data/url';
import API from '../../../../data/api';
import { MyJobsProps } from './types';
import './style.sass';

const MyJobs = (props: MyJobsProps) => {
  const { create } = props;
  const productItems: ProductItemType[] = useSelector(getProductItems(MODULES.MY_JOBS));
  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));
  const otherJobCostTableList = useSelector(getOtherJobCostTableList);
  const navigation = useSelector(getNavigation(MODULES.MY_JOBS));
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));

  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  const { actions: rfqActions } = useRequestForQuoteSlice();

  const { jobId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    //  Get the dropdowns data of job information
    dispatch(apiCallBegan({ url: API.GET_JOB_INFORMATION, method: 'GET', onSuccess: setDropdowns.type, module: MODULES.MY_JOBS }));
    //  Job information page navigation
    if (create) {
      dispatch(setJobInformationNavigation({ module: MODULES.MY_JOBS }));
    } else {
      //  Navigation
      dispatch(showFullNavigation({ module: MODULES.MY_JOBS }));
      //  Change NavigationJOB_INFORMATION
      // dispatch(changeNavigation({ module: MODULES.MY_JOBS, key: JOB_INFORMATION }));
      if (jobId) {
        dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setJobData.type, module: MODULES.MY_JOBS }));
        //  Remove
        dispatch(rfqActions.loadRfqData({ jobId: jobId, toggleAction: toggleAction }));
      }
    }
  }, [create, dispatch, jobId, rfqActions, toggleAction]);

  useEffect(() => {
    //  Job information page navigation
    if (create) {
      dispatch(
        breadcrumbActions.changeBreadcrumb([
          { name: 'Dashboard', url: '/dashboard' },
          { name: 'Request for Quote', url: URL.REQUEST_FOR_QUOTE },
        ]),
      );
    } else {
      //  Navigation
      dispatch(
        breadcrumbActions.changeBreadcrumb([
          { name: 'Dashboard', url: '/dashboard' },
          { name: 'My Jobs', url: URL.MY_JOBS },
          { name: jobInformation.title || '', url: '#' },
        ]),
      );
    }
  }, [breadcrumbActions, create, dispatch, jobInformation.title]);

  return (
    <>
      <Helmet>
        <title>My Jobs</title>
      </Helmet>
      <div className="rfq">
        <Breadcrumb />
        <div className="rfq__container">
          <div className="rfq__header">
            <h2>{create ? 'Request for Quote' : jobInformation.title}</h2>
            {!create && <h3>{jobInformation.jobNumber}</h3>}
          </div>
          <div className="inPage__navigation">
            {navigation[JOB_INFORMATION].visible && (
              <button className={`${navigation[JOB_INFORMATION].selected && 'active'}`} onClick={() => dispatch(changeNavigation({ module: MODULES.MY_JOBS, key: JOB_INFORMATION }))}>
                Job Information
              </button>
            )}
            {navigation[PRODUCT_ITEMS].visible && (
              <button className={`${navigation[PRODUCT_ITEMS].selected && 'active'}`} onClick={() => dispatch(changeNavigation({ module: MODULES.MY_JOBS, key: PRODUCT_ITEMS }))}>
                Product Items ({productItems.length})
              </button>
            )}
            {navigation[OTHER_COSTS].visible && (
              <button className={`${navigation[OTHER_COSTS].selected && 'active'}`} onClick={() => dispatch(changeNavigation({ module: MODULES.MY_JOBS, key: OTHER_COSTS }))}>
                Other Costs {otherJobCostTableList?.length > 0 && <>({otherJobCostTableList?.length})</>}
              </button>
            )}
            {navigation[TASKS].visible && (
              <button className={`${navigation[TASKS].selected && 'active'}`} onClick={() => dispatch(changeNavigation({ module: MODULES.MY_JOBS, key: TASKS }))}>
                Tasks
              </button>
            )}
            {navigation[AUDIT].visible && (
              <button className={`${navigation[AUDIT].selected && 'active'}`} onClick={() => dispatch(changeNavigation({ module: MODULES.MY_JOBS, key: AUDIT }))}>
                Audit
              </button>
            )}
          </div>
          <div className="inPage__navigation__line"></div>
          {navigation[JOB_INFORMATION].selected && <JobInformation create={create} />}
          {navigation[PRODUCT_ITEMS].selected && <ProductItems />}
          {navigation[OTHER_COSTS].selected && <OtherCosts />}
          {navigation[TASKS].selected && <Tasks isProductId={false} />}
          {navigation[AUDIT].selected && <Audit />}
        </div>
      </div>
    </>
  );
};

export default MyJobs;
