//  Externals
import LoadingSpinner from '../navigation/LoadingSpinner';
import { useSelector } from 'react-redux';
import React from 'react';
//  Internals
import { selectAdministrationSubMenu, selectIsLoading, selectModules } from 'app/components/modules/sideBar/slice/selectors';
import { getIsCDS, getIsClient } from '../modules/sideBar/slice/selectors';
import { NoAccess } from '../noAccess/NoAccess';
import URL from '../../../data/url';

type Props = {
  url: string;
  children: JSX.Element;
};

export const ProtectedRoute = ({ url, children }: Props) => {
  //  isCDS value
  const isCDS = useSelector(getIsCDS);
  //  isClient value
  const isClient = useSelector(getIsClient);
  //  Main Menu
  const modules = useSelector(selectModules);
  const routes = modules.map(module => module.url.toLowerCase());
  //  Administration Menu
  const submenu = useSelector(selectAdministrationSubMenu);
  const submenuRoutes = submenu?.map(module => '/administration'.concat(module.url.toLowerCase()));
  const loading = useSelector(selectIsLoading);
  const urlList = [...routes, ...submenuRoutes];
  if (isCDS || isClient) urlList.push(URL.REQUEST_FOR_QUOTE);

  return loading ? (
    <div style={{ position: 'relative', top: '40vh' }}>
      <LoadingSpinner />
    </div>
  ) : urlList.includes(url.toLowerCase()) ? (
    children
  ) : (
    <NoAccess />
  );
};
