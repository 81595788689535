//  Externals
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton/IconButton';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse } from '@mui/material';
import moment from 'moment';
//  Internals
import LargeFormatDetails from './../../../components/modules/products/largeFormatBoard/details';
import BusinessCardsDetails from './../../../components/modules/products/businessCards/details';
import PullUpBannerDetails from './../../../components/modules/products/pullUpBanner/details';
import SpecialItemDetails from './../../../components/modules/products/specialItem/details';
import LetterheadDetails from './../../../components/modules/products/letterhead/details';
import PostcardsDetails from './../../../components/modules/products/postcards/details';
import ClothingDetails from './../../../components/modules/products/clothing/details';
import BookletDetails from './../../../components/modules/products/booklet/details';
import LeafletDetails from './../../../components/modules/products/leaflet/details';
import SignageDetails from './../../../components/modules/products/signage/details';
import { getMasterData } from './../../../../store/reducers/masterData/selectors';
import BannerDetails from './../../../components/modules/products/banner/details';
import PosterDetails from './../../../components/modules/products/poster/details';
import CardsDetails from './../../../components/modules/products/cards/details';
import FlyerDetails from './../../../components/modules/products/flyer/details';
import BrochureDetails from 'app/components/modules/products/brochure/details';
import BagDetails from './../../../components/modules/products/bag/details';
import { getProductItems } from 'store/reducers/entities/selectors';
import VALIDATIONS from '../../../../data/validations';
import MODULES from '../../../../data/modules';
import {
  BAG,
  BANNER,
  BOOKLET,
  BROCHURE,
  BUSINESS_CARDS,
  CARDS,
  CLOTHING,
  FLYER,
  LARGE_FORMAT_BOARD,
  LEAFLET,
  LETTERHEAD,
  POSTCARDS,
  POSTER,
  PULL_UP_BANNER,
  SIGNAGE,
  SPECIAL_ITEM,
} from '../../../../data/constants';

export const ClientQuoteProductItemDetails = props => {
  const { productItem, productDetails, checkVat } = props;

  const productItems = useSelector(getProductItems(MODULES.MY_JOBS));
  //  Artwork Format
  const artworkFormats = useSelector(getMasterData('artworkFormats'));
  const [artworkFormat, setArtworkFormat] = useState('-');
  //  Typeof proofs
  const typesOfProofs = useSelector(getMasterData('typesOfProofs'));
  const [typeOfProof, setTypeOfProof] = useState('-');

  const productItemDetails = productItems?.find(product => product.printSpecifications.templateId === productItem.templateId)?.printSpecifications;

  useEffect(() => {
    if (productItemDetails) {
      //  Artwork Format
      const artworkFormatEntity = artworkFormats.find(o => o.value === productItemDetails.artworkFormatId);
      if (artworkFormatEntity && artworkFormatEntity.label === VALIDATIONS.OTHER) setArtworkFormat(productItemDetails.artworkFormatOther);
      else setArtworkFormat(artworkFormatEntity.label);
      //  Typeof proofs
      const typeOfProofEntity = typesOfProofs.find(o => o.value === productItemDetails.typeOfProofsId);
      if (typeOfProofEntity && typeOfProofEntity.label === VALIDATIONS.OTHER) setTypeOfProof(productItemDetails.typeOfProofsOther);
      else setTypeOfProof(typeOfProofEntity.label);
    }
  }, [artworkFormats, productItemDetails, typesOfProofs]);

  const [open, setOpen] = useState(false);

  return (
    <div className="productItemDetail">
      <div className="titleRow">
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(prevState => !prevState)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
        {productDetails.length && <span className="fw-bold">{`${productItem.productType}: ${productItem.title}`}</span>}
      </div>
      <div className="contentRow">
        <Collapse in={open} timeout="auto" unmountOnExit>
          {productItem.productType === BAG && <BagDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === BANNER && <BannerDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === BOOKLET && <BookletDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === BROCHURE && <BrochureDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === BUSINESS_CARDS && <BusinessCardsDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === CARDS && <CardsDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === CLOTHING && <ClothingDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === FLYER && <FlyerDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === LARGE_FORMAT_BOARD && <LargeFormatDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === LEAFLET && <LeafletDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === LETTERHEAD && <LetterheadDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === POSTCARDS && <PostcardsDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === POSTER && <PosterDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === PULL_UP_BANNER && <PullUpBannerDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === SIGNAGE && <SignageDetails productItemDetails={productItemDetails} type="clientQuote" />}
          {productItem.productType === SPECIAL_ITEM && <SpecialItemDetails productItemDetails={productItemDetails} type="clientQuote" />}
          <div className="delivery__instructions">
            <h6>DELIVERY INSTRUCTIONS</h6>
            <div className="mt-3">
              <span>Contact Name</span>
              <p className="fw-bold font-14">{productItemDetails?.deliveryContact === null ? '-' : productItemDetails?.deliveryContact}</p>
            </div>
            <div className="mt-3">
              <span>Address</span>
              <p className="fw-bold font-14 p_wrap">{productItemDetails?.deliveryAddress === null ? '-' : productItemDetails?.deliveryAddress}</p>
            </div>
            <div className="mt-3">
              <span>Instructions</span>
              <p className="fw-bold font-14 p_wrap">{productItemDetails?.deliveryInstructions === null ? '-' : productItemDetails?.deliveryInstructions}</p>
            </div>
            <div className="mt-3">
              <span>Delivery Due Date</span>
              <p className="fw-bold font-14 p_wrap">{productItemDetails?.deliveryDueDate === null ? '-' : moment(productItemDetails?.deliveryDueDate)?.format('DD/MM/YYYY')}</p>
            </div>
          </div>
          <div className="delivery__instructions">
            <h6>ARTWORK</h6>
            <div className="mt-3">
              <span>Artwork Format</span>
              <p className="fw-bold font-14">{artworkFormat}</p>
            </div>
            <div className="mt-3">
              <span>Artwork Due Date</span>
              <p className="fw-bold font-14 p_wrap">{productItemDetails?.artworkDueDate === null ? '-' : moment(productItemDetails?.artworkDueDate).format('DD/MM/YYYY')}</p>
            </div>
          </div>
          <div className="delivery__instructions">
            <h6>PROOFS</h6>
            <div className="mt-3">
              <span>Type Of Proofs</span>
              <p className="fw-bold font-14">{typeOfProof}</p>
            </div>
            <div className="mt-3">
              <span>Proof Due Date</span>
              <p className="fw-bold font-14 p_wrap">{productItemDetails?.proofDueDate === null ? '-' : moment(productItemDetails?.proofDueDate).format('DD/MM/YYYY')}</p>
            </div>
          </div>
          <div className="priceSummary">
            <h6>PRICE SUMMARY</h6>
            <div className="priceSummary__container">
              <div style={{ textAlign: 'right' }}>
                <span>Net (&#163;)</span>
                <p className="fw-bold font-14 text-right">{productItem?.priceSummary?.net ? productItem?.priceSummary?.net?.toFixed(2) : '0.00'}</p>
              </div>
              {checkVat && (
                <div style={{ textAlign: 'right' }}>
                  <span>VAT (&#163;)</span>
                  <p className="fw-bold font-14">{productItem?.priceSummary?.vat ? productItem?.priceSummary?.vat?.toFixed(2) : '0.00'}</p>
                </div>
              )}
              <div style={{ textAlign: 'right' }}>
                <span>Total (&#163;)</span>
                <p className="fw-bold font-14">{productItem?.priceSummary?.total ? productItem?.priceSummary?.total?.toFixed(2) : '0.00'}</p>
              </div>
            </div>
          </div>
          {productItem?.quantityRunOn !== null && productItem?.quantityRunOn !== undefined && productItem?.quantityRunOn !== 0 && (
            <div className="priceSummary">
              <h6>RUNON SUMMARY</h6>
              <div className="priceSummary__container">
                <div style={{ textAlign: 'right' }}>
                  <span>Runon Net (&#163;)</span>
                  <p className="fw-bold font-14">{productItem?.priceSummary?.runon === null ? '0.00' : productItem?.priceSummary?.runon?.toFixed(2)}</p>
                </div>
                {checkVat && (
                  <div style={{ textAlign: 'right' }}>
                    <span>Runon VAT (&#163;)</span>
                    <p className="fw-bold font-14">{productItem?.priceSummary?.runonVat === null ? '0.00' : productItem?.priceSummary?.runonVat?.toFixed(2)}</p>
                  </div>
                )}
                <div style={{ textAlign: 'right' }}>
                  <span>Runon Total (&#163;)</span>
                  <p className="fw-bold font-14">{productItem?.priceSummary?.runonTotal === null ? '0.00' : productItem?.priceSummary?.runonTotal?.toFixed(2)}</p>
                </div>
              </div>
            </div>
          )}
        </Collapse>
      </div>
    </div>
  );
};
