import React from 'react';
import { useSelector } from 'react-redux';
import DashboardLayout from '../layout';
import { isLoading, selectDashboardCards } from '../slice/selectors';
import LoadingSpinner from '../../../components/navigation/LoadingSpinner';

const CustomerDashboard = props => {
  const { userRoles } = props;
  const data = useSelector(selectDashboardCards);
  const isLoader = useSelector(isLoading);

  const labels = [
    {
      barLabel: 'RFQs',
      lineLabel: 'Quotes to Client',
    },
    {
      barLabel: 'Items',
      lineLabel: 'Proof to Client',
    },
    {
      barLabel: 'Items',
      lineLabel: 'Delivered on Time',
    },
  ];

  const c1 = {
    onHold: '#c5983b',
    recheck: '#e61a50',
    toInvoice: '#3e1951',
    delivered: '#9a46c6',
    partDelivered: '#fad1dc',
    ordered: '#bc84d9',
    partOrdered: '#ddc1ec',
    quotation: '#91ede8',
    amendedRfq: '#c8ecf4',
    newRfq: '#ddc1ec',
  };
  const c2 = ['#3e1951', '#1ba39c', '#3e1951'];

  return (
    <>
      {isLoader ? (
        <div style={{ position: 'absolute', top: '50%', width: '100%' }}>
          <LoadingSpinner color="#000" />
        </div>
      ) : (
        <DashboardLayout data={data} labels={labels} userRoles={userRoles} c1={c1} c2={c2} />
      )}
    </>
  );
};

export default CustomerDashboard;
