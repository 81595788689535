import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { setDropdowns } from '../../../../store/reducers/entities/index';
import { apiCallBegan } from '../../../../store/reducers/loading';
import { initialAddNewContact } from '../../../../types/myJobs';
import { request } from 'utils/request';
import API from '../../../../data/api';
import { Button, Form, Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import MODULES from '../../../../data/modules';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import React, { useState } from 'react';

const AddNewContact = props => {
  const { show, onHide, accountId, onNewContact } = props;
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const phoneRegExp = /^[0-9 ]+$/;
  const validationSchema = yup.object({
    name: yup.string().trim().nullable().required('Please enter Name'),
    emailAddress: yup
      .string()
      .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Enter valid email address'),
    phoneNumber: yup.string().nullable().required('Please provide telephone number').matches(phoneRegExp, 'Please Enter Valid Number').max(20, 'Please Enter Valid Number'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: initialAddNewContact,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: typeof initialAddNewContact) => {
    const payload = {
      ...values,
      id: 0,
      accountId: accountId,
      phoneNumber: values.phoneNumber?.toString(),
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/RFQ/CreateAccountContact/${accountId}`, {
        method: 'POST',
        body: JSON.stringify(payload),
      }).then(response => {
        if (response.isSuccess) {
          onNewContact({ contactId: response.data.id, email: response.data.emailAddress, phoneNumber: response.data.phoneNumber });
          dispatch(apiCallBegan({ url: API.GET_JOB_INFORMATION, method: 'GET', onSuccess: setDropdowns.type, module: MODULES.MY_JOBS }));
          onHide();
        } else {
          setError(response.message);
        }
      });
    } catch (responseError) {
      console.log('Error while submitting the form ' + responseError);
    }
  };

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" backdrop="static" backdropClassName="fullScreen__overPopup" centered keyboard={false} onHide={() => onHide()} scrollable show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)} id="addNewContact">
          <Form.Group controlId="addNewContactName">
            <Form.Label
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>
                Name<span className="input__required">*</span>
              </span>
            </Form.Label>
            <Form.Control autoComplete="off" type="text" className={errors.name && 'border-danger'} placeholder="Enter Name" {...register('name')} />
            {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
          </Form.Group>
          <Form.Group className="mt-3" controlId="addNewEmailAddress">
            <Form.Label
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>
                Email<span className="input__required">*</span>
              </span>
            </Form.Label>
            <Form.Control autoComplete="off" type="email" className={errors.emailAddress && 'border-danger'} placeholder="Enter Email" {...register('emailAddress')} />
            {errors.emailAddress && <Form.Text className="text-danger">{errors.emailAddress.message}</Form.Text>}
          </Form.Group>
          <Form.Group className="mt-3 mb-3" controlId="addNewPhoneNumber">
            <Form.Label
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>
                Telephone Number<span className="input__required">*</span>
              </span>
            </Form.Label>
            <Form.Control autoComplete="off" type="text" className={errors.phoneNumber && 'border-danger'} placeholder="Enter Telephone number" {...register('phoneNumber')} />
            {errors.phoneNumber && <Form.Text className="text-danger">{errors.phoneNumber.message}</Form.Text>}
          </Form.Group>
        </Form>
        {error !== '' && (
          <div className="text-danger text-center w-100">
            <strong className="fs-6">{error}</strong>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="outline__button" onClick={() => onHide()} variant="outline-primary">
          Cancel
        </Button>
        <Button className="full__button" variant="primary" type="submit" form="addNewContact">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewContact;
