/**
 * Combine all reducers in this file and export the combined reducers.
 */
//  Externals
import { combineReducers } from '@reduxjs/toolkit';
//  Internals
import { InjectedReducersType } from 'utils/types/injector-typings';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const createReducer = (injectedReducers: InjectedReducersType = {}) => {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return state => state;
  } else {
    return combineReducers({
      ...injectedReducers,
    });
  }
};

export default createReducer;
