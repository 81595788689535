//  Externals
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
//  Internals
import {
  BAG,
  BANNER,
  BOOKLET,
  BROCHURE,
  BUSINESS_CARDS,
  CARDS,
  CLOTHING,
  FLYER,
  LARGE_FORMAT_BOARD,
  LEAFLET,
  LETTERHEAD,
  POSTCARDS,
  POSTER,
  PULL_UP_BANNER,
  SIGNAGE,
  SPECIAL_ITEM,
} from '../../../../../data/constants';
import { getProductItems } from './../../../../../store/reducers/entities/selectors';
import { initialLargeFormatBoard } from './../../products/largeFormatBoard/types';
import { initialBusinessCard } from './../../products/businessCards/model';
import { initialPullUpBanner } from './../../products/pullUpBanner/types';
import { initialSpecialItem } from './../../products/specialItem/types';
import { initialLetterHead } from './../../products/letterhead/model';
import { initialPostCard } from './../../products/postcards/model';
import { initialBrochure } from './../../products/brochure/model';
import { initialClothing } from './../../products/clothing/types';
import { initialSignage } from './../../products/signage/types';
import { initialLeaflet } from './../../products/leaflet/model';
import { initialBooklet } from './../../products/booklet/model';
import { initialPoster } from './../../products/poster/model';
import { ProductItemType } from '../../../../../types/myJobs';
import { initialBanner } from './../../products/banner/types';
import { initialCards } from './../../products/cards/model';
import { initialFlyer } from './../../products/flyer/model';
import { initialBag } from '../../products/bag/types';
import { ProductItemsList } from './ProductItemsList';
import MODULES from '../../../../../data/modules';
import { Product, initialProduct } from './types';
import ProductItem from './ProductItem';
import Products from './Products';
import Header from './Header';
import '../style.sass';

const ProductItems = () => {
  const productItems: ProductItemType[] = useSelector(getProductItems(MODULES.MY_JOBS));

  const [showProductItemModal, setShowProductItemModal] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);

  const [product, setProduct] = useState<Product>(initialProduct);
  const [initialState, setInitialState] = useState<any>({});

  useEffect(() => {
    if (product.name === BAG) setInitialState(initialBag);
    if (product.name === BANNER) setInitialState(initialBanner);
    if (product.name === BOOKLET) setInitialState(initialBooklet);
    if (product.name === BROCHURE) setInitialState(initialBrochure);
    if (product.name === BUSINESS_CARDS) setInitialState(initialBusinessCard);
    if (product.name === CARDS) setInitialState(initialCards);
    if (product.name === CLOTHING) setInitialState(initialClothing);
    if (product.name === FLYER) setInitialState(initialFlyer);
    if (product.name === LARGE_FORMAT_BOARD) setInitialState(initialLargeFormatBoard);
    if (product.name === LEAFLET) setInitialState(initialLeaflet);
    if (product.name === LETTERHEAD) setInitialState(initialLetterHead);
    if (product.name === POSTCARDS) setInitialState(initialPostCard);
    if (product.name === POSTER) setInitialState(initialPoster);
    if (product.name === PULL_UP_BANNER) setInitialState(initialPullUpBanner);
    if (product.name === SIGNAGE) setInitialState(initialSignage);
    if (product.name === SPECIAL_ITEM) setInitialState(initialSpecialItem);
  }, [product.name]);

  /******************************************************************************************
  @Purpose    :   Reset the form state
  *******************************************************************************************/
  const handleReset = () => {
    setProduct({ ...initialProduct });
    //  Call the product items / jobinformation for updated product items
  };

  return (
    <div className="products__page">
      {showProductsModal && <Products onHide={() => setShowProductsModal(false)} onOpen={() => setShowProductItemModal(true)} setProduct={setProduct} show={showProductsModal} />}
      {showProductItemModal && (
        <ProductItem
          create={true}
          initialState={initialState}
          onHide={() => {
            setShowProductItemModal(false);
            handleReset();
          }}
          onShowProductsModal={() => setShowProductsModal(true)}
          productId={product.productId}
          productType={product.name}
          show={showProductItemModal}
        />
      )}
      {productItems.length !== 0 ? (
        <>
          <div className="productsPage__header">
            <Header onShowProductsModal={() => setShowProductsModal(true)} />
          </div>
          <div className="products__content">
            <ProductItemsList />
          </div>
        </>
      ) : (
        <div className="extra__container">
          <div className="addProduct__container">
            <Button className="full__button" variant="primary" style={{ paddingRight: '2rem', paddingLeft: '2rem' }} onClick={() => setShowProductsModal(true)}>
              Add Product item
            </Button>
            <span style={{ marginTop: '0.5rem' }}>You have not added a product item yet</span>
            <span>
              Click on <strong>'Add Product item'</strong>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductItems;
