import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { userDatalist } from 'store/reducers/userData/selectors';
import { useMyProfileSlice } from '../slice';
import { myTeamtList } from '../slice/selectors';
import { Pagination } from '@mui/material';
import usePagination from '../Pagination';

export const MyTeam = () => {
  const [isAddUser, setIsUser] = useState(false);
  const [addOrRemoveRequestBody, setAddOrRemoveRequestBody] = useState<any>();
  let [page, setPage] = useState(1);
  const data = useSelector(myTeamtList);
  const myTeamDetailList = [...data].reverse();
  const userList = useSelector(userDatalist);
  const { actions: myProfileAction } = useMyProfileSlice();
  const dispatch = useDispatch();

  const onAddOrRemoveUser = (event: React.ChangeEvent<any>) => {
    const index = event?.target?.selectedIndex;
    const el = event?.target?.childNodes[index];
    const name = el.getAttribute('id');
    setAddOrRemoveRequestBody({
      requestBody: {
        memberId: Number(event?.target?.value),
        isRemove: false,
      },
      name: name,
    });
  };

  const dataPerPage = 20;
  const count = Math.ceil(myTeamDetailList.length / dataPerPage);
  const _PerPageData_ = usePagination(myTeamDetailList, dataPerPage);

  const handlePageChange = (e, p) => {
    setPage(p);
    _PerPageData_.jump(p);
  };

  return (
    <div className="team__container">
      <div className="add__user" onClick={() => setIsUser(true)}>
        <strong>&#43; Add User</strong>
      </div>
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th style={{ paddingRight: '42px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {_PerPageData_.currentData()?.map((items, index) => (
            <tr key={`${items.name}_${index}`}>
              <td>{items.name}</td>
              <td>
                <span
                  className="delete__action"
                  onClick={() =>
                    dispatch(
                      myProfileAction.addOrRemoveMyTeam({
                        requestBody: {
                          memberId: Number(items.memberId),
                          isRemove: true,
                        },
                        name: items.name,
                      }),
                    )
                  }
                >
                  Delete
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <Pagination count={count} size="large" page={page} onChange={handlePageChange} />
      </div>
      {
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          backdropClassName="fullScreen"
          centered
          dialogClassName="modal__addUser"
          keyboard={false}
          onHide={() => {
            setIsUser(false);
          }}
          scrollable
          show={isAddUser}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h3>Add Users</h3>
            </Modal.Title>
          </Modal.Header>
          <hr style={{ margin: '0px', borderBottom: '1px solid #dee2e6' }} />
          <Modal.Body>
            <Form.Select aria-label="Default select example" onChange={e => onAddOrRemoveUser(e)}>
              <option>Select User</option>
              {userList.map(item => (
                <option value={item.id} id={item.name} key={`${item.name}_${item.id}`}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setIsUser(false)} className="outline__button">
              Cancel
            </Button>
            <Button
              type="submit"
              form="printSpecificationsForm"
              variant="primary"
              className="full__button"
              onClick={() => {
                dispatch(myProfileAction.addOrRemoveMyTeam(addOrRemoveRequestBody));
                setIsUser(false);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  );
};
