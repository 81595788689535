//  Externals
import { createSlice } from 'utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { auditHistorySaga } from './saga';
import { AuditHistoryState } from './types';

export const initialState: AuditHistoryState = {
  isLoading: false,
  error: null,
  auditHistory: [],
};

const slice = createSlice({
  name: 'auditHistory',
  initialState,
  reducers: {
    loadAuditInformation(state) {
      state.isLoading = true;
    },

    setAuditInformation(state, action: PayloadAction<any>) {
      state.auditHistory = action.payload.data.items;
      state.isLoading = false;
    },

    setAuditInformationError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
  },
});

export const { actions: auditHistoryActions, reducer } = slice;

export const useAuditHistorySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: auditHistorySaga });
  return { actions: slice.actions };
};
