import { protectedResources } from '../../../configs/msalAuthConfigs';
import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { userDataActions as actions } from '.';
import { userData } from './types';

function* getUserData() {
  const requestURL = `${protectedResources.api.apiEndpoint}/User/GetAllUsers`;
  try {
    const userData: userData = yield call(request, requestURL);
    if (userData) {
      yield put(actions.userDataLoaded(userData));
    } else {
      yield put(
        actions.userDataError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.userDataError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.userDataError({ error: 'Issue with api', loading: false }));
    }
  }
}

export function* userDataSaga() {
  yield takeLatest(actions.loadUserData.type, getUserData);
}
