import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { search, outstandingPurchase, po } from './types';
import { useOutstandingPurchaseSaga } from './saga';

export const initialState: outstandingPurchase = {
  isLoading: false,
  isPoDetailsLoading: true,
  error: null,
  search: {
    isSuccess: false,
    validationErrors: null,
    message: '',
    statusCode: 0,
    data: {
      items: [
        {
          name: '',
          id: 0,
          isAccount: false,
        },
      ],
    },
  },
  account: {
    isSuccess: false,
    message: '',
    statusCode: 0,
    validationErrors: [],
    data: {
      totalOutstandingProducts: 0,
      totalOutstandinvPoValue: 0,
      accountName: '',
      items: [
        {
          poNumber: '',
          jobQuoteId: null,
          title: '',
          noOfOutstandingItems: 0,
          outstandingPoValue: 0,
        },
      ],
    },
  },
  po: {
    isSuccess: false,
    message: '',
    statusCode: 0,
    validationErrors: '',
    data: {
      supplierPo: '',
      supplierName: '',
      supplierAddress: '',
      jobNumber: 0,
      supplierPostCode: '',
      supplierWebsite: '',
      jobReference: '',
      jobTitle: '',
      totalPoValue: 0,
      outstandingValue: 0,
      productDetails: [],
    },
  },
};

const slice = createSlice({
  name: 'outStandingPurchase',
  initialState,
  reducers: {
    loadSearch(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    searchLoaded(state, action: PayloadAction<search>) {
      state.search = action.payload;
      state.isLoading = false;
    },
    searchError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
    loadSelectedAccount(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    accountOrPodetails(state, action: PayloadAction<any>) {
      state.account = action.payload;
      state.isLoading = false;
    },
    accountError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
    loadSelectedPO(state, action: PayloadAction<any>) {
      state.isPoDetailsLoading = true;
    },
    PodetailsLoaded(state, action: PayloadAction<po>) {
      state.po = action.payload;
      state.isPoDetailsLoading = false;
    },
    PODetailsError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
    updateInvoiceValue(state, action: PayloadAction<{ id: number | null; value: number | null }>) {
      if (action.payload.id === null) {
        state.po.data.productDetails = state.po.data.productDetails.map(productDetails => ({
          ...productDetails,
          invoiceValue: action.payload.value,
        }));
      } else {
        state.po.data.productDetails = state.po.data.productDetails.map(productDetails =>
          action.payload.id == productDetails.jobQuoteId
            ? {
                ...productDetails,
                invoiceValue: action.payload.value,
              }
            : { ...productDetails },
        );
      }
    },
    removeClosedProductDetails(state, action: PayloadAction<{ id: number }>) {
      state.po.data.productDetails = state.po.data.productDetails.filter(item => item.jobQuoteId !== action.payload.id);
    },
    removeMultipleProductDetails(state, action: PayloadAction<{ invoiceDetails: any }>) {
      let ids = action.payload.invoiceDetails.map(item => item.jobQuoteId);
      state.po.data.productDetails = state.po.data.productDetails.filter(item => ids.indexOf(item.jobQuoteId) < 0);
    },
  },
});

export const { actions: outstandingPurchaseActions, reducer } = slice;

export const useOutstandingPurchase = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: useOutstandingPurchaseSaga });
  return { actions: slice.actions };
};
