export interface Status {
  code: string;
  id: number;
  name: string;
}

export const initialState: Status[] = [
  {
    code: '',
    id: 0,
    name: '',
  },
];
