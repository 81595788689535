import React from 'react';
import ReactDOM from 'react-dom';
import LoadingSpinner from '../navigation/LoadingSpinner';

const MOUNT_NODE = document.getElementById('loading__backdrop') as HTMLElement;

const LoadingSpinnerWithBackdrop = props => {
  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        height: '100%',
        width: '100%',
        zIndex: '10000',
        top: '0',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', verticalAlign: 'center', width: '100vw', height: '100vh', backdropFilter: 'blur(5px)' }}>
        <LoadingSpinner color={props.color} />
        <div style={{ display: 'flex', justifyContent: 'center', verticalAlign: 'center' }}>{props.message}</div>
      </div>
    </div>,
    MOUNT_NODE,
  );
};

export default LoadingSpinnerWithBackdrop;
