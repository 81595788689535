//  Externals
import { createSelector } from '@reduxjs/toolkit';
//  Internals
import { RootState } from '../../../../store/model';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.registrationFormData || initialState;

export const getAccounts = createSelector([selectSlice], registrationFormData => registrationFormData.accounts);

export const getBusinessUnits = createSelector([selectSlice], registrationFormData => registrationFormData.businessUnits);

export const getRoles = createSelector([selectSlice], registrationFormData => registrationFormData.roles);

export const getSuppliers = createSelector([selectSlice], registrationFormData => registrationFormData.suppliers);

export const getClientUsers = createSelector([selectSlice], registrationFormData => registrationFormData.clientUsers);

export const getSupplierUsers = createSelector([selectSlice], registrationFormData => registrationFormData.supplierUsers);
