//  Externals
import { configureStore, StoreEnhancer } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { createInjectorsEnhancer } from 'redux-injectors';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
//  Internals
import api from './middleware/api';
import reducer from './reducers';
//  Persist store
const persistConfig = { key: 'root', storage };

const persistedReducer = persistReducer(persistConfig, reducer());

//  Create the store with saga middleware
const sagaMiddleware = createSagaMiddleware({});
const { run: runSaga } = sagaMiddleware;

const middleware = [sagaMiddleware];

const enhancers = [
  createInjectorsEnhancer({
    createReducer: reducer,
    runSaga,
  }),
] as StoreEnhancer[];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [...middleware, api],
  devTools: process.env.NODE_ENV !== 'production',
  enhancers,
});

export const persistor = persistStore(store);
