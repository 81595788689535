//  Externals
import { createSlice } from 'utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
//  Internals
import {
  clientQuoteConfirmation,
  clientOrderConfirmation,
  productItemRow,
  RequestForQuoteState,
  supplierOrder,
  buttonsHeader,
  ProductInformationType,
  rfqDataForm,
  productTemplate,
  assignSuppliersForm,
  SelectedSupplierList,
  statusDetails,
  OtherCostRateItems,
  placeSupplierOrderDetails,
  addTasks,
  supplier,
  supplierFormData,
} from './types';
import { jobInformationInitialState } from 'app/components/shared/Constants';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { PrintSpecification } from '../../../../types/myJobs';
import { getRequestStatus } from 'utils/helperFunctions';
import { requestForQuoteSaga } from './saga';

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const createData = (form: PrintSpecification, productType: string): productItemRow => {
  return {
    id: form.templateId || 0,
    open: false,
    checked: false,
    title: form.title,
    productType: productType,
    added: null,
    quoted: null,
    quantity: form.quantity,
    quantityActual: form.quantityActual,
    value: null,
    status: 'Draft',
    statusName: 'Draft',
    lineId: null,
    details: {
      printSpecifications: {
        isLoading: false,
        error: null,
        form: form,
      },
      jobQuotes: [],
      jobQuoteSummaryDetails: [],
      quotes: {
        isLoading: false,
        error: null,
        supplierList: [],
        filteredSupplierList: [],
        tempSelectedSuppliers: [],
        selectedSuppliers: [],
        supplierData: {
          supplierFormData: [],
          assignSuppliersFormData: {
            dueDate: null,
            dueTime: null,
            notes: null,
          },
        },
        minSupplierCount: 0,
      },
      clientQuotations: {},
    },
  };
};

const createRfqDataForProductItems = (productTemplate: productTemplate): productItemRow => {
  const { productTemplateDetails, productTemplateSummary, jobQuoteSummaryDetails, clientQuotations } = productTemplate;
  let { jobQuotes } = productTemplate;

  const updatedPrintSpecificationForm = {
    ...productTemplateDetails,
    laminated: productTemplateDetails.laminated ? 'Yes' : 'No',
    samples: productTemplateDetails.samples ? 'Yes' : 'No',
    sealerOnCover: productTemplateDetails.sealerOnCover ? 'Yes' : 'No',
    sealerOnFace: productTemplateDetails.sealerOnFace ? 'Yes' : 'No',
    sealerOnReverse: productTemplateDetails.sealerOnReverse ? 'Yes' : 'No',
    sealerOnText: productTemplateDetails.sealerOnText ? 'Yes' : 'No',
    artworkDueDate: productTemplateDetails?.artworkDueDate && new Date(productTemplateDetails?.artworkDueDate),
    deliveryDueDate: productTemplateDetails?.deliveryDueDate && new Date(productTemplateDetails?.deliveryDueDate),
    proofDueDate: productTemplateDetails?.proofDueDate && new Date(productTemplateDetails?.proofDueDate),
    targetDate: productTemplateDetails?.targetDate && new Date(productTemplateDetails?.targetDate),
  };

  if (jobQuotes) {
    let copyJobQuotes = JSON.parse(JSON.stringify(jobQuotes));
    jobQuotes = copyJobQuotes && createJobQuoteList(copyJobQuotes, clientQuotations);
  } else jobQuotes = [];

  return {
    id: productTemplateDetails.templateId || 0,
    open: false,
    checked: false,
    title: productTemplateSummary.title,
    productType: productTemplateSummary.productType,
    added: productTemplateSummary.added,
    quoted: productTemplateSummary.quoted,
    quantity: productTemplateSummary.quantity,
    quantityActual: productTemplateDetails.quantityActual,
    value: productTemplateSummary.value,
    status: getRequestStatus(productTemplateSummary.status ? productTemplateSummary.status : 0),
    statusName: productTemplateSummary.statusName,
    lineId: productTemplateSummary.lineId,
    details: {
      printSpecifications: {
        isLoading: false,
        error: null,
        form: updatedPrintSpecificationForm,
      },
      jobQuotes: jobQuotes,
      jobQuoteSummaryDetails: jobQuoteSummaryDetails,
      quotes: {
        isLoading: false,
        error: null,
        supplierList: [],
        filteredSupplierList: [],
        tempSelectedSuppliers: [],
        selectedSuppliers: [],
        supplierData: {
          supplierFormData: [],
          assignSuppliersFormData: {
            dueDate: null,
            dueTime: null,
            notes: null,
          },
        },
        minSupplierCount: 0,
      },
      clientQuotations: clientQuotations,
    },
  };
};

const createJobQuoteList = (data, clientQuotations) => {
  var supplierRowArray =
    data &&
    data.map(item => {
      return item.jobQuoteSummary;
    });
  let dataTotal = supplierRowArray.filter(item => item.totalRate != 0 && item.totalRate != null);
  const getMinTotal = Math.min(...dataTotal.map(item => item.totalRate));
  if (clientQuotations == null) {
    supplierRowArray = supplierRowArray.map(item =>
      getMinTotal == 0 ? { ...item, checked: false, open: false } : getMinTotal === item.totalRate ? { ...item, checked: true, open: false } : { ...item, checked: false, open: false },
    );
  } else {
    supplierRowArray = supplierRowArray.map(item => (clientQuotations.jobQuoteId === item.jobQuoteId ? { ...item, checked: true, open: false } : { ...item, checked: false, open: false }));
  }

  var finalData = data.map((item, i) => {
    return { ...item, jobQuoteSummary: supplierRowArray[i] };
  });
  return finalData;
};

const createSupplierData = (supplierName: string, id: number): supplierFormData => {
  return {
    id,
    name: supplierName,
    open: false,
    checked: false,
    quantity: 1000,
    productionRate: 10,
    materialRate: 20,
    delivery: 20,
    total: 2000,
    notes: 'Notes',
    status: 'Quoted',
    dueDate: 'Oct 25, 2021 1:17 Am',
    details: {
      extraInfo: 'Extra Information',
    },
  };
};

const productItemsInitialState = {
  isLoading: false,
  error: null,
  productItems: [],
  filteredProductItems: [],
  selectedProductItems: [],
  tempSelectedProductItems: [],
  productItemDetails: {
    isLoading: false,
    error: null,
    rows: [],
  },
};

export const initialState: RequestForQuoteState = {
  selectedAccountId: null,
  navigation: {
    jobInformation: { visible: true, selected: true },
    productItems: { visible: false, selected: false },
    otherCosts: { visible: false, selected: false },
    tasks: { visible: false, selected: false },
  },
  productItemsState: productItemsInitialState,
  productInformation: {
    isLoading: false,
    error: null,
    values: {
      artworkFormat: [],
      colours: [],
      coloursOnFace: [],
      coloursOnInside: [],
      coloursOnOutside: [],
      coloursOnReverse: [],
      coloursOnText: [],
      coverMaterial: [],
      coverMaterialWeight: [],
      deliveryAddressChangeReasons: [],
      finishedSize: [],
      finishing: [],
      jobItemOptions: [],
      material: [],
      materialWeight: [],
      mechansim: [],
      primaryCategories: [],
      secondaryCategories: [],
      tempSecondaryCategories: [],
      typeOfProofs: [],
      discountDetails: {
        requestedBy: '',
        requestedDateTime: '',
        clientDiscount: 0,
        supplierDiscount: 0,
        totalDiscount: 0,
        clientDiscountPercentage: '',
        supplierDiscountPercentage: '',
        totalDiscountPercentage: '',
        newLineValue: 0,
        discountReasonId: 0,
        discountReasonOther: '',
        status: '',
        rejectReason: '',
        reviewDateTime: '',
        reviewedBy: '',
      },
      discountSummary: {
        originalCost: 0,
        discountedPercentage: '',
        discountedValue: 0,
        totalNetPrice: 0,
        vatPercentage: '',
        vatValue: 0,
        grossAmount: 0,
        approvedDateTime: '',
      },
    },
  },
  productItemsButtonHeader: {
    quoteBasket: '',
    buttons: {
      filters: { hide: false, disabled: false },
      submit: { hide: false, disabled: false },
      clientQuote: { hide: true, disabled: true },
      approveQuote: { hide: true, disabled: true },
      order: { hide: false, disabled: true },
      addProductItem: { hide: false, disabled: false },
      orderFromSupplier: { hide: false, disabled: true },
      useSupplierQuote: { hide: false, disabled: true },
      delivered: { hide: true, disabled: true },
      readyToInvoice: { hide: false, disabled: false },
    },
  },
  confirmClientQuote: {
    isLoading: false,
    error: null,
    details: {},
  },
  confirmClientOrder: {
    isLoading: false,
    error: null,
    details: {
      firstTimeOrder: false,
      currentOrder: {
        poNumber: '',
        total: 0,
      },
      currentOrderDetails: [],
      newOrderDetails: [],
      poDetails: [],
      comment: null,
      poValueAvailable: false,
    },
  },
  placeSupplierOrder: {
    isLoading: false,
    error: null,
    details: {
      poNumber: '',
      supplier: '',
      totalValue: 0,
      productItems: [],
    },
  },
  rfqData: {
    isLoading: false,
    error: null,
    form: {
      rfqJobInformation: jobInformationInitialState,
      productTemplate: [],
      otherJobCosts: [],
    },
    isJobCostSubmitted: false,
  },
  otherCostsTypes: {
    id: 14,
    name: 'OtherCost',
    masterData: [
      {
        id: 84,
        name: 'Design',
        masterDataTypeId: 14,
      },
      {
        id: 86,
        name: 'Scanning',
        masterDataTypeId: 14,
      },
    ],
  },
  isLoading: false,
  error: null,
  SelectedSupplierList: [],
  productTemplate: {
    isLoading: false,
    error: null,
  },
  vatRateDetails: [],
  EditSupplierQuote: {},
  statusDetails: [],
  placeSupplierOrderDetails: [],
  userList: [],
  taskList: {
    taskDetailsList: [],
    taskInProductList: [],
  },
  addTasks: [],
  OtherCostRateItems: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: {
      items: [],
    },
  },
};

const slice = createSlice({
  name: 'requestForQuote',
  initialState,
  reducers: {
    changeAccountId(state, action: PayloadAction<boolean>) {
      state.selectedAccountId = action.payload;
    },

    //  Product Information Reducers
    loadProductInformation(state, action: PayloadAction<{ productId: number; jobId: number }>) {
      state.productInformation.isLoading = true;
    },

    productInformationLoaded(state, action: PayloadAction<ProductInformationType>) {
      state.productInformation.values = action.payload;
      state.productInformation.isLoading = false;
    },

    productInformationError(state, action: PayloadAction<string>) {
      state.productInformation.error = action.payload;
    },

    changeSecondaryCategoryValues(state, action: PayloadAction<number>) {
      state.productInformation.values.secondaryCategories = state.productInformation.values.tempSecondaryCategories.filter(item => item.primaryCategoryId === action.payload);
    },

    resetSecondaryCategoryValues(state) {
      state.productInformation.values.secondaryCategories = state.productInformation.values.tempSecondaryCategories;
    },

    //Product Item Details Reducers
    loadProductItemDetails(state) {
      state.productItemsState.productItemDetails.isLoading = true;
    },

    productItemDetailsLoaded(state, action: PayloadAction<productItemRow[]>) {
      state.productItemsState.productItemDetails.rows = action.payload;
      state.productItemsState.productItemDetails.isLoading = false;
    },

    productItemsDetailsError(state, action: PayloadAction<string>) {
      state.productItemsState.productItemDetails.error = action.payload;
    },

    addNewProductItemDetails(
      state,
      action: PayloadAction<{
        form: PrintSpecification;
        productType: string;
      }>,
    ) {
      const row = createData(action.payload.form, action.payload.productType);

      state.productItemsState.productItemDetails.rows = [...state.productItemsState.productItemDetails.rows, row];
    },

    changeOpenForProductItem(state, action: PayloadAction<number>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(item => {
        return {
          ...item,
          open: item.id === action.payload ? !item.open : item.open,
        };
      });
    },

    changeCheckedForProductItem(state, action: PayloadAction<number>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(item => {
        return {
          ...item,
          checked: item.id === action.payload ? !item.checked : item.checked,
        };
      });
    },

    deleteProductItem(state, action: PayloadAction<number>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.filter(item => item.id !== action.payload);
    },

    masterProductItemOnChange(state, action: PayloadAction<{ flag: boolean; open: boolean; checked: boolean }>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(item => {
        return {
          ...item,
          open: action.payload.flag ? !action.payload.open : item.open,
          checked: action.payload.flag ? item.checked : !action.payload.checked,
        };
      });
    },

    changeSelectedProductsOnClone(state, action: PayloadAction<number>) {},

    addClonedProductItemDetails(state, action: PayloadAction<productTemplate>) {
      state.productItemsState.productItemDetails.rows = [...state.productItemsState.productItemDetails.rows, createRfqDataForProductItems(action.payload)];
    },

    addClonedProductItemDetailsError(state, action: PayloadAction<string>) {
      state.productItemsState.productItemDetails.error = action.payload;
    },

    updateProductItemDetailsAfterSubmit(state, action: PayloadAction<productTemplate[]>) {
      const updatedIds = action.payload.map(row => row.productTemplateDetails.templateId);
      const oldArray = state.productItemsState.productItemDetails.rows.filter(row => !updatedIds.includes(row.id));
      const newArray = action.payload.map(item => createRfqDataForProductItems(item));

      state.productItemsState.productItemDetails.rows = [...oldArray, ...newArray];
      state.productItemsState.productItemDetails.rows.sort((a, b) => a?.id - b?.id);
    },

    updateProductRowStatus(state, action: PayloadAction<number[]>) {
      state.productItemsState.productItemDetails.rows.map((row, index: number) => {
        if (action.payload.includes(row.id)) {
          state.productItemsState.productItemDetails.rows[index].status = 'New RFQ';
          state.productItemsState.productItemDetails.rows[index].statusName = 'New RFQ';
        }
      });
    },

    changeSelectedProductsOnDelete(state, action: PayloadAction<number>) {
      const newSelectedProducts = state.productItemsState.selectedProductItems.filter(item => item.id !== action.payload);
      state.productItemsState.selectedProductItems = [...newSelectedProducts];
    },

    //  Supplier Reducers
    loadSupplierList(state, action: PayloadAction<{ id: number; productId: number }>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload.id) {
          let details = row.details;
          details.quotes.isLoading = true;
          return { ...row, details };
        }
        return row;
      });
    },

    supplierListLoaded(state, action: PayloadAction<{ id: number; suppliers: any }>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload.id) {
          let details = row.details;
          details.quotes.supplierList = action.payload.suppliers?.suppliers;
          details.quotes.filteredSupplierList = action.payload.suppliers?.suppliers;
          details.quotes.isLoading = false;
          details.quotes.minSupplierCount = action.payload.suppliers?.minSupplierCount;
          return { ...row, details };
        }
        return row;
      });
    },

    supplierListError(state, action: PayloadAction<{ id: number; error: string }>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload.id) {
          let details = row.details;
          details.quotes.error = action.payload.error;
          return { ...row, details };
        }
        return row;
      });
    },

    filterSupplierList(state, action: PayloadAction<{ id: number; searchString: string }>) {
      if (state.productItemsState.productItemDetails.rows.find(row => row.id === action.payload.id)) {
        if (action.payload.searchString.trim() === '') {
          state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
            if (row.id === action.payload.id) {
              let details = row.details;
              details.quotes.filteredSupplierList = details.quotes.supplierList;
              return { ...row, details };
            }
            return row;
          });
        } else {
          const searchRegex = new RegExp(escapeRegExp(action.payload.searchString), 'i');
          const updatedSupplierList = state.productItemsState.productItemDetails.rows
            .filter(row => row.id === action.payload.id)[0]
            .details.quotes.supplierList.filter(({ supplierId, ...rest }) => {
              return Object.keys(rest).some((field: any) => {
                return searchRegex.test(rest[field].toString());
              });
            });
          state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
            if (row.id === action.payload.id) {
              let details = row.details;
              details.quotes.filteredSupplierList = updatedSupplierList;
              return { ...row, details };
            }
            return row;
          });
        }
      }
    },

    resetTempSelectedSupplierList(state, action: PayloadAction<number>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload) {
          let details = row.details;
          details.quotes.tempSelectedSuppliers = details.quotes.selectedSuppliers;
          return { ...row, details };
        }
        return row;
      });
    },

    setTempSelectedSupplierList(state, action: PayloadAction<{ id: number; suppliers: supplier[] }>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload.id) {
          let details = row.details;
          details.quotes.tempSelectedSuppliers = action.payload.suppliers;
          return { ...row, details };
        }
        return row;
      });
    },

    setSelectedSupplierList(state, action: PayloadAction<number>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload) {
          let details = row.details;
          details.quotes.selectedSuppliers = details.quotes.tempSelectedSuppliers;
          return { ...row, details };
        }
        return row;
      });
    },

    setAssignSuppliersFormValues(state, action: PayloadAction<{ id: number; form: assignSuppliersForm }>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload.id) {
          let details = row.details;
          details.quotes.supplierData.assignSuppliersFormData = action.payload.form;
          return { ...row, details };
        }
        return row;
      });
    },

    addSelectedSuppliersToSupplierData(state, action: PayloadAction<number>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload) {
          let supplierData = row.details.quotes.selectedSuppliers.map(supplier => createSupplierData(supplier.supplierName, supplier.supplierId));
          let details = row.details;
          details.quotes.supplierData = {
            ...details.quotes.supplierData,
            supplierFormData: supplierData,
          };

          return { ...row, details };
        }
        return row;
      });
    },

    masterSupplierDataOnChange(
      state,
      action: PayloadAction<{
        flag: boolean;
        rowId: number;
        open: boolean;
        checked: boolean;
      }>,
    ) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload.rowId) {
          let supplierData = row.details.quotes.supplierData.supplierFormData.map(item => {
            return {
              ...item,
              open: action.payload.flag ? !action.payload.open : item.open,
              checked: action.payload.flag ? item.checked : !action.payload.checked,
            };
          });
          let details = row.details;
          details.quotes.supplierData.supplierFormData = supplierData;
          return { ...row, details };
        }
        return row;
      });
    },

    changeCheckedForSupplierData(state, action: PayloadAction<{ rowId: number; id: number }>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload.rowId) {
          let supplierData = row.details.quotes.supplierData.supplierFormData.map(item => {
            return {
              ...item,
              checked: item.id === action.payload.id ? !item.checked : item.checked,
            };
          });
          let details = row.details;
          details.quotes.supplierData.supplierFormData = supplierData;
          return { ...row, details };
        }
        return row;
      });
    },

    changeOpenForSupplierData(state, action: PayloadAction<{ rowId: number; id: number }>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload.rowId) {
          let supplierData = row.details.quotes.supplierData.supplierFormData.map(item => {
            return {
              ...item,
              open: item.id === action.payload.id ? !item.open : item.open,
            };
          });
          let details = row.details;
          details.quotes.supplierData.supplierFormData = supplierData;
          return { ...row, details };
        }
        return row;
      });
    },

    // Update if row checked for supplier row array from API
    changeCheckedForSupplierRowArray(state, action: PayloadAction<{ rowId: number; id: number }>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload.rowId) {
          row.details.jobQuotes.map(item => {
            item.jobQuoteSummary.checked = item.jobQuoteSummary.jobQuoteId === action.payload.id ? !item.jobQuoteSummary.checked : item.jobQuoteSummary.checked;
          });
          return { ...row };
        }
        return row;
      });
    },

    // Update if row open for supplier row array from API
    changeOpenForSupplierRowArray(state, action: PayloadAction<{ rowId: number; id: number }>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload.rowId) {
          row.details.jobQuotes.map(item => {
            item.jobQuoteSummary.open = item.jobQuoteSummary.supplierId === action.payload.id ? !item.jobQuoteSummary.open : item.jobQuoteSummary.open;
          });
          return { ...row };
        }
        return row;
      });
    },

    //Print Specification Reducers
    setDefaultValuesForPrintSpecificationsForm(state, action: PayloadAction<{ id: number; form: PrintSpecification }>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(row => {
        if (row.id === action.payload.id) {
          row.details.printSpecifications.form = action.payload.form;
          row.title = action.payload.form.title;
          row.quantity = action.payload.form.quantity;
          return { ...row };
        }
        return row;
      });
    },

    //  Client Quote Confirmation Reducers
    loadClientQuoteConfirmationPopUp(state, action: PayloadAction<any>) {
      state.confirmClientQuote.isLoading = true;
    },

    clientQuoteConfirmationPopUpLoaded(state, action: PayloadAction<clientQuoteConfirmation>) {
      state.confirmClientQuote.details = action.payload;
      state.confirmClientQuote.isLoading = false;
    },

    clientQuoteConfirmationPopUpError(state, action: PayloadAction<string>) {
      state.confirmClientQuote.error = action.payload;
    },

    clientOrderConfirmationPopUpLoaded(state, action: PayloadAction<clientOrderConfirmation>) {
      state.confirmClientOrder.details = action.payload.details;
      state.confirmClientOrder.isLoading = false;
    },

    clientOrderConfirmationPopUpError(state, action: PayloadAction<string>) {
      state.confirmClientOrder.error = action.payload;
    },

    addPODetails(state, action: PayloadAction<any>) {
      var data = action.payload;
      if (data == '') {
        state.confirmClientOrder.details.poDetails = [
          ...state.confirmClientOrder.details.poDetails,
          {
            id: uuid().slice(0, 8),
            poNumber: '',
            poValue: 0,
            valid: true,
            satisfiedTotal: false,
            satisfiedRemoved: false,
            satisfiedNewOrder: false,
            isValidValue: true,
            isPreviousSatisfiedNewOrder: false,
            isPreviousSatisfiedRemoved: false,
          },
        ];
      } else {
        state.confirmClientOrder.details.poDetails = data.map(item => ({
          ...state.confirmClientOrder.details.poDetails,
          id: item?.poId,
          poNumber: item?.poNumber,
          poValue: (item?.poValue).toFixed(2),
          valid: true,
          satisfiedTotal: false,
          satisfiedRemoved: false,
          satisfiedNewOrder: false,
          isValidValue: true,
          isPreviousSatisfiedNewOrder: false,
          isPreviousSatisfiedRemoved: false,
        }));
      }
    },

    resetPODetails(
      state,
      action: PayloadAction<{
        id: number;
        poNumber: string;
        poValue: number;
        valid: boolean;
        satisfiedTotal: boolean;
        satisfiedRemoved: boolean;
        satisfiedNewOrder: boolean;
        isValidValue: boolean;
        isPreviousSatisfiedNewOrder: boolean;
        isPreviousSatisfiedRemoved: boolean;
      }>,
    ) {
      state.confirmClientOrder.details.poDetails = [
        {
          id: action.payload.id,
          poNumber: action.payload.poNumber,
          poValue: action.payload.poValue,
          valid: action.payload.valid,
          satisfiedTotal: action.payload.satisfiedTotal,
          satisfiedRemoved: action.payload.satisfiedRemoved,
          satisfiedNewOrder: action.payload.satisfiedNewOrder,
          isValidValue: action.payload.isValidValue,
          isPreviousSatisfiedNewOrder: action.payload.isPreviousSatisfiedNewOrder,
          isPreviousSatisfiedRemoved: action.payload.isPreviousSatisfiedRemoved,
        },
      ];
    },

    removePODetails(state, action: PayloadAction<number>) {
      state.confirmClientOrder.details.poDetails = state.confirmClientOrder.details.poDetails.filter(poDetail => poDetail.id !== action.payload);
    },

    removeAllPODetails(state) {
      state.confirmClientOrder.details.poDetails = [];
      state.confirmClientOrder.details.poValueAvailable = false;
      state.confirmClientOrder.details.comment = '';
    },

    updatePONumber(state, action: PayloadAction<{ id: number; value: string; isValidValue: boolean }>) {
      state.confirmClientOrder.details.poDetails = state.confirmClientOrder.details.poDetails.map(poDetail =>
        poDetail.id === action.payload.id ? { ...poDetail, poNumber: action.payload.value, isValidValue: action.payload.isValidValue } : { ...poDetail },
      );
    },

    updatePOValue(
      state,
      action: PayloadAction<{
        id: number;
        value: number;
        valid: boolean;
        satisfiedTotal: boolean;
        satisfiedRemoved: boolean;
        satisfiedNewOrder: boolean;
        isPreviousSatisfiedNewOrder: boolean;
        isPreviousSatisfiedRemoved: boolean;
      }>,
    ) {
      state.confirmClientOrder.details.poDetails = state.confirmClientOrder.details.poDetails.map(poDetail =>
        poDetail.id === action.payload.id
          ? {
              ...poDetail,
              poValue: action.payload.value,
              valid: action.payload.valid,
              satisfiedTotal: action.payload.satisfiedTotal,
              satisfiedRemoved: action.payload.satisfiedRemoved,
              satisfiedNewOrder: action.payload.satisfiedNewOrder,
              isPreviousSatisfiedNewOrder: action.payload.isPreviousSatisfiedNewOrder,
              isPreviousSatisfiedRemoved: action.payload.isPreviousSatisfiedRemoved,
            }
          : { ...poDetail },
      );
    },

    updatePOValueChange(state, action: PayloadAction<{ value: boolean }>) {
      state.confirmClientOrder.details.poValueAvailable = action.payload.value;
      state.confirmClientOrder.details.comment = '';
    },

    updatePOComment(state, action: PayloadAction<{ value: string }>) {
      state.confirmClientOrder.details.comment = action.payload.value;
    },

    //  Place Supplier Order Reducers
    loadPlaceSupplierOrderPopUp(state) {
      state.placeSupplierOrder.isLoading = true;
    },

    placeSupplierOrderPopUpLoaded(state, action: PayloadAction<supplierOrder>) {
      state.placeSupplierOrder.details = action.payload.details;
      state.placeSupplierOrder.isLoading = false;
    },

    placeSupplierOrderPopUpError(state, action: PayloadAction<string>) {
      state.placeSupplierOrder.error = action.payload;
    },

    //Buttons header reducers
    changeButtonsHeader(state, action: PayloadAction<buttonsHeader>) {
      state.productItemsButtonHeader.buttons = action.payload;
    },

    //  Dynamic RFQ reducers
    loadRfqData(state, action: PayloadAction<any>) {
      state.rfqData.isLoading = true;
      state.rfqData.error = null;
    },

    rfqDataLoaded(state, action: PayloadAction<rfqDataForm>) {
      state.rfqData.form = action.payload;
      state.rfqData.isLoading = false;
      if (action.payload.productTemplate) {
        const rows = action.payload.productTemplate.map(item => createRfqDataForProductItems(item));
        state.productItemsState.productItemDetails.rows = [...rows];
      } else {
        state.productItemsState.productItemDetails.rows = [];
      }
    },

    //Other Costs
    loadRfqDataForOtherCost(state, action: PayloadAction<any>) {
      state.rfqData.isLoading = true;
      state.rfqData.error = null;
    },
    addNewOtherCostRow(state, action: PayloadAction<any>) {
      const updateData = action.payload.data;
      if (action.payload.data.id != null) {
        state.rfqData.form.otherJobCosts = state.rfqData.form.otherJobCosts.concat(updateData);
      } else {
        state.rfqData.form.otherJobCosts = state.rfqData.form.otherJobCosts.concat(updateData);
      }
    },
    deleteOtherCostRow(state, action: PayloadAction<any>) {
      state.rfqData.form.otherJobCosts = state.rfqData.form.otherJobCosts.filter(item => item.id != action.payload);
    },
    editOtherCostRow(state, action: PayloadAction<any>) {
      const edittedData = action.payload.inputs;
      state.rfqData.form.otherJobCosts = state.rfqData.form.otherJobCosts.map(item =>
        item.id === action.payload.id
          ? {
              ...item,
              rateItemId: edittedData.rateItemId,
              id: edittedData.id,
              name: edittedData.name,
              description: edittedData.description,
              hours: edittedData.hours,
              netValue: edittedData.netValue,
              vatRate: edittedData.vatRate,
            }
          : { ...item },
      );
    },

    resetSubmitCost(state, action: PayloadAction<any>) {
      state.rfqData.isJobCostSubmitted = false;
    },

    rfqDataForOtherCostLoaded(state, action: PayloadAction<any>) {
      state.rfqData.form = {
        ...state.rfqData.form,
        otherJobCosts: action.payload.otherJobCosts,
      };
      state.rfqData.isLoading = false;
      state.rfqData.isJobCostSubmitted = true;
    },

    loadSelectedSupplierList(state) {
      state.isLoading = true;
    },

    SupplierListLoaded(state, action: PayloadAction<SelectedSupplierList[]>) {
      state.SelectedSupplierList = action.payload;
      state.isLoading = false;
    },

    SupplierListError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },

    loadVatRateDetails(state) {
      state.isLoading = true;
    },

    CreateQuoteVatRate: (state, action: PayloadAction<any>) => {
      state.vatRateDetails = action.payload;
      state.isLoading = false;
    },

    EditSupplierQuote(state, action: PayloadAction<any>) {
      state.EditSupplierQuote = action.payload;
    },

    loadStatusDetails(state) {
      state.isLoading = true;
    },

    statusLoaded: (state, action: PayloadAction<statusDetails[]>) => {
      state.statusDetails = action.payload;
      state.isLoading = false;
    },

    StatusError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },

    loadPlaceSupplierOrder(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },

    PlaceSupplierOrderLoaded: (state, action: PayloadAction<placeSupplierOrderDetails[]>) => {
      state.placeSupplierOrderDetails = action.payload;
      state.isLoading = false;
    },

    PlaceSupplierOrderError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },

    loadAllUsers(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },

    setAllUsers(state, action: PayloadAction<any>) {
      const data = action.payload?.data?.items.map(user => ({
        label: user.name,
        value: user.id,
      }));
      state.userList = data;
      state.isLoading = false;
    },

    loadAllTaskData(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },

    setAllTaskData(state, action: PayloadAction<{ data: any; isProductId: boolean }>) {
      if (action.payload.isProductId) {
        state.taskList.taskInProductList = action.payload.data;
      } else {
        state.taskList.taskDetailsList = action.payload.data;
      }
      state.isLoading = false;
    },

    loadOtherCostRateItems(state, action: PayloadAction<number>) {
      state.isLoading = true;
    },

    setOtherCostRateItems(state, action: PayloadAction<OtherCostRateItems>) {
      state.OtherCostRateItems = action.payload;
      state.isLoading = false;
    },
    loadAddTasksData(state) {
      state.isLoading = true;
    },

    setAddTasksData(state, action: PayloadAction<addTasks[]>) {
      state.addTasks = action.payload;
      state.isLoading = false;
    },

    setNewAddTasksData(state, action: PayloadAction<any>) {
      const updateData = action.payload.data;
      if (action.payload.data.productTemplateId) {
        state.taskList.taskInProductList = state.taskList.taskInProductList.concat(updateData);
      } else {
        state.taskList.taskDetailsList = state.taskList.taskDetailsList.concat(updateData);
      }
    },

    setUpdateTasksData(state, action: PayloadAction<{ id: number; data: any; isProductId: boolean }>) {
      var data = {
        ...action.payload.data,
        id: uuid().slice(0, 8),
        productTemplateId: null,
        productTemplateTitle: null,
        defaultYN: false,
      };
      if (action.payload.isProductId) {
        state.taskList.taskInProductList = state.taskList.taskInProductList.map(item =>
          item.id == action.payload.id
            ? {
                ...item,
                notes: data.notes,
                completionDate: data.completionDate,
                dueDate: data.dueDate,
                assignee: data.assignee,
                status: data.status,
                name: data.name,
              }
            : { ...item },
        );
      } else if (!action.payload.isProductId) {
        state.taskList.taskDetailsList = state.taskList.taskDetailsList.map(item =>
          item.id == action.payload.id
            ? {
                ...item,
                notes: data.notes,
                completionDate: data.completionDate,
                dueDate: data.dueDate,
                assignee: data.assignee,
                status: data.status,
                name: data.name,
              }
            : { ...item },
        );
      }
    },

    setDeleteTasksData(state, action: PayloadAction<{ id: number; isProductId: boolean }>) {
      if (action.payload.isProductId) {
        state.taskList.taskInProductList = state.taskList.taskInProductList.filter(item => item.id !== action.payload.id);
      } else if (!action.payload.isProductId) {
        state.taskList.taskDetailsList = state.taskList.taskDetailsList.filter(item => item.id !== action.payload.id);
      }
    },

    updateStatusToAccepted(state) {
      let checkedRowsId = state.productItemsState.productItemDetails.rows.filter(item => item.checked).map(item => item.id);
      if (checkedRowsId.length > 0) {
        let result = state.productItemsState.productItemDetails.rows.map(item => {
          if (checkedRowsId.indexOf(item.id) > -1 && item.status == 'Quotation') {
            item.status = '	Accepted';
            item.statusName = '	Accepted';
          }
          return item;
        });
        state.productItemsState.productItemDetails.rows = [...result];
      } else {
        let result = state.productItemsState.productItemDetails.rows.map(item => {
          if (item.status == 'Quotation') {
            item.status = '	Accepted';
            item.statusName = '	Accepted';
          }
          return item;
        });
        state.productItemsState.productItemDetails.rows = [...result];
      }
    },

    UpdateSupplierPlaceOrder(state) {
      let checkedRowsId = state.productItemsState.productItemDetails.rows.filter(item => item.checked).map(item => item.id);
      if (checkedRowsId.length > 0) {
        let result = state.productItemsState.productItemDetails.rows.map(item => {
          if (checkedRowsId.indexOf(item.id) > -1 && item.status == 'Accepted') {
            item.status = 'Ordered';
            item.statusName = 'Ordered';
          }
          return item;
        });
        state.productItemsState.productItemDetails.rows = [...result];
      } else {
        let result = state.productItemsState.productItemDetails.rows.map(item => {
          if (item.status == 'Accepted') {
            item.status = 'Ordered';
            item.statusName = 'Ordered';
          }
          return item;
        });
        state.productItemsState.productItemDetails.rows = [...result];
      }
    },

    updateStatusToOrdered(state) {
      let checkedRowsId = state.productItemsState.productItemDetails.rows.filter(item => item.checked).map(item => item.id);
      let result = state.productItemsState.productItemDetails.rows.map(item => {
        if (checkedRowsId.indexOf(item.id) > -1 && item.status == 'Ordered') {
          item.status = '	Delivered';
          item.statusName = '	Delivered';
        }
        return item;
      });
      state.productItemsState.productItemDetails.rows = [...result];
    },

    UpdateToReadyToInvoice(state) {
      let checkedRowsId = state.productItemsState.productItemDetails.rows.filter(item => item.checked).map(item => item.id);
      let result = state.productItemsState.productItemDetails.rows.map(item => {
        if (checkedRowsId.indexOf(item.id) > -1 && item.status == 'Delivered') {
          item.status = 'Ready To Invoice';
          item.statusName = 'Ready To Invoice';
        }
        return item;
      });
      state.productItemsState.productItemDetails.rows = [...result];
    },

    UpdateStatusToCancelled(state) {
      let checkedRowsId = state.productItemsState.productItemDetails.rows.filter(item => item.checked).map(item => item.id);
      let result = state.productItemsState.productItemDetails.rows.map(item => {
        if (checkedRowsId.indexOf(item.id) > -1 && item.status == 'Quotation') {
          item.status = 'Cancelled';
          item.statusName = 'Cancelled';
        }
        return item;
      });
      state.productItemsState.productItemDetails.rows = [...result];
    },
  },
});

export const { actions: requestForQuoteActions, reducer } = slice;

export const useRequestForQuoteSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: requestForQuoteSaga });
  return { actions: slice.actions };
};
