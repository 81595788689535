import { accountSaga } from './saga';
import {
  allAccounts,
  accountData,
  createRfqNavigation,
  dropDown,
  editAccountData,
  productData,
  productDetailList,
  supplierList,
  BusinessUnitList,
  JobrateList,
  supplierDropDownList,
  EditJobrateList,
  EditBusinessUnitList,
  masterData,
  selectApprovers,
  selectAccountService,
  accountUsers,
} from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { ACCOUNTDETAILS, BUSINESSUNIT, JOBRATE, PRODUCTS, SUPPLIERS } from 'app/components/shared/Constants';

export const initialState: allAccounts = {
  dropDown: {
    isSuccess: true,
    validationErrors: null,
    message: '',
    statusCode: 0,
    //All Account
    data: {
      users: [],
      primaryCdscontact: [],
      secondaryCdscontact: [],
      contract: [],
      geoLocation: [],
      sector: [],
      cdsBusinesUnit: [],
      exportType: [],
      vatRate: [],
      analysisCategory: [],
    },
  },
  // Navigation
  navigation: {
    accountDetails: { visible: true, selected: true },
    businessUnit: { visible: false, selected: false },
    jobrate: { visible: false, selected: false },
    products: { visible: false, selected: false },
    suppliers: { visible: false, selected: false },
  },
  accountLoading: false,
  error: null,
  accountData: {
    isSuccess: true,
    validationErrors: null,
    message: '',
    statusCode: 0,
    data: {
      items: [],
    },
  },
  // Edit Account
  editAccountData: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: [],
  },
  // Product Data
  productData: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: [],
  },
  // Product Details List
  productDetailList: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: {
      items: [],
    },
  },
  // Suppliers List
  supplierList: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: {
      pageNumber: 0,
      pageSize: 0,
      totalCount: 0,
      items: [],
    },
  },
  // Suppliers DD List
  supplierDropDownList: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: {
      items: [],
    },
  },
  // Business Unit List
  BusinessUnitList: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: [],
  },
  //Edit Business Unit List
  EditBusinessUnitList: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: [],
  },
  // Jobrate List
  JobrateList: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: [],
  },
  // Edit Jobrate
  EditJobrateList: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: [],
  },
  // Master Data Decline
  masterData: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: {
      discountReasons: [],
    },
  },
  // Select Approval Decline
  selectApprovers: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: {
      items: [],
    },
  },
  // Select AccountService DD
  selectAccountService: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: {
      items: [],
    },
  },
  // Select AccountService DD
  accountUsers: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [''],
    data: {
      items: [],
    },
  },
};

const slice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    //Accounts load
    loadAccount(state) {
      state.accountLoading = true;
    },
    accountDataLoaded(state, action: PayloadAction<accountData>) {
      state.accountData = action.payload;
      state.accountLoading = false;
    },
    //Edit Accounts load

    editAccount(state, action: PayloadAction<number>) {
      state.accountLoading = true;
    },
    editAccountDataLoaded(state, action: PayloadAction<editAccountData>) {
      state.editAccountData = action.payload;
      state.accountLoading = false;
    },
    //Product load

    loadProduct(state, action: PayloadAction<number>) {
      state.accountLoading = true;
    },
    productDataLoaded(state, action: PayloadAction<productData>) {
      state.productData = action.payload;
      state.accountLoading = false;
    },
    //Product List load
    loadProductList(state, action: PayloadAction<number>) {
      state.accountLoading = true;
    },
    productListDataLoaded(state, action: PayloadAction<productDetailList>) {
      state.productDetailList = action.payload;
      state.accountLoading = false;
    },
    //Suppliers List load
    loadSuppliersList(state, action: PayloadAction<number>) {
      state.accountLoading = true;
    },
    suppliersListDataLoaded(state, action: PayloadAction<supplierList>) {
      state.supplierList = action.payload;
      state.accountLoading = false;
    },
    //Suppliers DD load
    loadSuppliersDropDownList(state) {
      state.accountLoading = true;
    },
    suppliersDropDownLoaded(state, action: PayloadAction<supplierDropDownList>) {
      state.supplierDropDownList = action.payload;
      state.accountLoading = false;
    },
    //  Business Units
    loadBusinessUnits(state, action: PayloadAction<number>) {
      state.accountLoading = true;
    },
    BusinessUnitDataLoaded(state, action: PayloadAction<BusinessUnitList>) {
      state.BusinessUnitList = action.payload;
      state.accountLoading = false;
    },
    //Edit Busness Unit load
    loadEditBusinessUnitList(state, action: PayloadAction<number>) {
      state.accountLoading = true;
    },
    EditBusinessUnitDataLoaded(state, action: PayloadAction<EditBusinessUnitList>) {
      state.EditBusinessUnitList = action.payload;
      state.accountLoading = false;
    },

    //JobRate load
    loadJobRateList(state, action: PayloadAction<number>) {
      state.accountLoading = true;
    },
    JobRateDataLoaded(state, action: PayloadAction<JobrateList>) {
      state.JobrateList = action.payload;
      state.accountLoading = false;
    },
    //Edit JobRate load
    loadEditJobRateLis(state, action: PayloadAction<number>) {
      state.accountLoading = true;
    },
    EditJobRateDataLoaded(state, action: PayloadAction<EditJobrateList>) {
      state.EditJobrateList = action.payload;
      state.accountLoading = false;
    },
    //masterData decline
    loadMasterData(state) {
      state.accountLoading = true;
    },
    masterDataLoaded(state, action: PayloadAction<masterData>) {
      state.masterData = action.payload;
      state.accountLoading = false;
    },
    //selectApprovers decline
    loadApprovers(state) {
      state.accountLoading = true;
    },
    ApproversLoaded(state, action: PayloadAction<selectApprovers>) {
      state.selectApprovers = action.payload;
      state.accountLoading = false;
    },
    // Select AccountService DD
    loadAccountService(state, action: PayloadAction<number>) {
      state.accountLoading = true;
    },
    AccountService(state, action: PayloadAction<selectAccountService>) {
      state.selectAccountService = action.payload;
      state.accountLoading = false;
    },
    // Select AccountService DD
    loadAccountUsers(state, action: PayloadAction<number>) {
      state.accountLoading = true;
    },
    AccountUsers(state, action: PayloadAction<accountUsers>) {
      state.accountUsers = action.payload;
      state.accountLoading = false;
    },

    //  Add Account DropDown load
    loadDropDowns(state) {
      state.accountLoading = true;
    },
    dropDownDataLoaded(state, action: PayloadAction<dropDown>) {
      state.dropDown = action.payload;
      state.accountLoading = false;
    },
    dataLoadError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.accountLoading = action.payload.loading;
    },

    //Navigation Reducers
    changeNavigationMenu(state, action: PayloadAction<createRfqNavigation>) {
      state.navigation = action.payload;
    },
    selectNavigationButton(state, action: PayloadAction<string>) {
      switch (action.payload) {
        case ACCOUNTDETAILS:
          state.navigation.accountDetails.selected = true;
          state.navigation.businessUnit.selected = false;
          state.navigation.jobrate.selected = false;
          state.navigation.products.selected = false;
          state.navigation.suppliers.selected = false;
          break;
        case BUSINESSUNIT:
          state.navigation.accountDetails.selected = false;
          state.navigation.businessUnit.selected = true;
          state.navigation.jobrate.selected = false;
          state.navigation.products.selected = false;
          state.navigation.suppliers.selected = false;
          break;
        case JOBRATE:
          state.navigation.accountDetails.selected = false;
          state.navigation.businessUnit.selected = false;
          state.navigation.jobrate.selected = true;
          state.navigation.products.selected = false;
          state.navigation.suppliers.selected = false;
          break;
        case PRODUCTS:
          state.navigation.accountDetails.selected = false;
          state.navigation.businessUnit.selected = false;
          state.navigation.jobrate.selected = false;
          state.navigation.products.selected = true;
          state.navigation.suppliers.selected = false;
          break;
        case SUPPLIERS:
          state.navigation.accountDetails.selected = false;
          state.navigation.businessUnit.selected = false;
          state.navigation.jobrate.selected = false;
          state.navigation.products.selected = false;
          state.navigation.suppliers.selected = true;
          break;
      }
    },
  },
});

export const { actions: dashboardActions, reducer } = slice;

export const useInvoiceSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: accountSaga });
  return { actions: slice.actions };
};
