import { call, put, takeLatest } from 'redux-saga/effects';
import { auditHistoryActions as actions } from '.';
import { request } from 'utils/request';

function* getAuditInformation() {
  const requestURL = `https://run.mocky.io/v3/f0468e99-b64a-4d56-bd6d-980abda11933`;
  try {
    const data: any = yield call(request, requestURL);
    if (data) {
      yield put(actions.setAuditInformation(data));
    } else {
      yield put(
        actions.setAuditInformationError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.setAuditInformationError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(
        actions.setAuditInformationError({
          error: 'Issue with api',
          loading: false,
        }),
      );
    }
  }
}

export function* auditHistorySaga() {
  yield takeLatest(actions.loadAuditInformation.type, getAuditInformation);
}
