//  Externals
//  Internals
import { useInjectReducer } from '../../utils/redux-injectors';
import { createSlice } from '../../utils/@reduxjs/toolkit';

const slice = createSlice({
  name: 'loading',
  initialState: false,
  reducers: {
    apiCallBegan: (state, action) => true,
    apiCallSuccess: () => false,
    apiCallFailed: () => false,
    apiCallEnd: () => false,
    resetLoading: () => false,
  },
});

export const { apiCallBegan, apiCallSuccess, apiCallFailed, apiCallEnd, resetLoading } = slice.actions;

export default slice.reducer;

export const useLoadingSlice = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
