import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccount } from './slice/selectors';
import Table from 'react-bootstrap/Table';
import { useClosedPurchase } from './slice';
import PoNumberClosedPurchase from './PoNumberClosedPurchase';
const AccountClosedPurchase = () => {
  const account = useSelector(selectAccount);
  const dispatch = useDispatch();
  const [isSwitchPo, setIsSwitchPo] = useState(false);
  const { actions: closedPurchaseActions } = useClosedPurchase();
  const switchPO = id => {
    dispatch(closedPurchaseActions.loadSelectedAccount({ id: id }));
    setIsSwitchPo(true);
  };
  return (
    <React.Fragment>
      <>
        {isSwitchPo ? (
          <PoNumberClosedPurchase />
        ) : (
          <>
            <h5 className="margin_outStanding">{account.data.accountName}</h5>
            <div className="margin_outStanding ">
              <Table className="table_outStanding">
                <thead className="tableHead_outStanding">
                  <tr className="tableHorizontal_outStanding">
                    <th>PO Number</th>
                    <th>Title</th>
                  </tr>
                </thead>

                <tbody>
                  {account.data.items.map(item => (
                    <tr className="tableHorizontal_outStanding">
                      <td className="text-decoration-underline" style={{ cursor: 'pointer' }} onClick={() => switchPO(item?.jobQuoteId)}>
                        {item.poNumber}
                      </td>
                      <td>{item.title}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  );
};

export default AccountClosedPurchase;
