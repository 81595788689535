export interface SupplierUserType {
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
  telephoneNumber: string | null;
  roleId: number | null;
  supplierId: number | null;
  active: boolean | string | null;
  cache: boolean;
  approver: boolean;
  userId?: number;
}

export interface AddSupplierUserPropType {
  create: boolean;
  onHide: () => void;
  show: boolean;
  supplierUser: SupplierUserType;
}

export interface RoleList {
  value: string;
  label: string;
}

export const initialSupplierUser: SupplierUserType = {
  firstName: null,
  lastName: null,
  emailAddress: null,
  telephoneNumber: null,
  roleId: null,
  supplierId: null,
  active: null,
  cache: false,
  approver: false,
};
