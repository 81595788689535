import RFQSupplierView from 'app/pages/RFQSupplierView';
import React from 'react';
import { useSelector } from 'react-redux';
import { getIsClient, getIsSupplier } from '../sideBar/slice/selectors';
import MyJobs from './myJobsList';

export const OrdersTabOfUsers = () => {
  const isClient = useSelector(getIsClient);
  const isSupplier = useSelector(getIsSupplier);

  return (
    <div>
      {isSupplier && <RFQSupplierView />}
      {isClient && <MyJobs />}
    </div>
  );
};
