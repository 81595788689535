import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { CreateQuoteSaga } from './saga';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { vatRate, SupplierView, supplierListRow, printSpecificationsForm, ProductInformationType, material } from './types';

const productItemsInitialState = {
  isLoading: false,
  error: null,
  productItems: [],
  productItemDetails: {
    isLoading: false,
    error: null,
    rows: [],
  },
};
export const initialState: SupplierView = {
  supplierListRow: [],
  material: [],
  jobQuoteId: 0,
  vatRate: [],
  isLoading: true,
  isSupplierListLoading: true,
  error: null,
  productItemsState: productItemsInitialState,
  productInformation: {
    isLoading: false,
    error: null,
    values: {
      finishedSize: [],
      materialWeight: [],
      artworkFormat: [],
      finishing: [],
      material: [],
      colours: [],
      coloursOnFace: [],
      coloursOnReverse: [],
      typeOfProofs: [],
    },
  },
  productItems: [],
  printSpecificationsForm: [],
  confirmationListRow: [],
  createQuoteRes: {},
};

const createQuoteSlice = createSlice({
  name: 'supplierView',
  initialState,
  reducers: {
    CreateQuoteError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },

    UpdateSupplierList(state, action: PayloadAction<any>) {
      const { inputs, jobQuoteId, saveFlag } = action.payload;
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(item => {
        if (item.jobQuoteId === jobQuoteId) {
          item = { ...item, ...inputs };
          item.saveFlag = saveFlag;
          if (!saveFlag) {
            item.quoteStatusCode = 'Quoted';
            item.quoteStatusName = 'Quoted';
          }
        }
        return item;
      });
    },

    UpdateSupplierListDecline(state, action: PayloadAction<any>) {
      const { jobQuoteId, cancellationReasonId, cancellationReasonOther } = action.payload;
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(item => {
        if (item.jobQuoteId === jobQuoteId) {
          item.quoteStatusCode = 'Declined';
          item.quoteStatusName = 'Declined';
          item.open = true;
        }
        return item;
      });
      state.printSpecificationsForm = state.printSpecificationsForm.map(item => {
        if (item.jobQuoteId === jobQuoteId) item = { ...item, cancellationReasonId, cancellationReasonOther };
        return item;
      });
    },

    loadRFQsList(state, action: PayloadAction<any>) {
      state.isSupplierListLoading = true;
    },

    suppliersList: (state, action: PayloadAction<supplierListRow[]>) => {
      state.supplierListRow = action.payload;
      state.productItemsState.productItemDetails.rows = action.payload;
      state.isSupplierListLoading = false;
    },

    loadRFQModal(state) {
      state.isLoading = true;
    },

    CreateQuoteVatRate: (state, action: PayloadAction<vatRate[]>) => {
      state.vatRate = action.payload;
      state.isLoading = false;
    },

    loadSupplierList(state, action: PayloadAction<{ productTemplateId: number }>) {
      state.isLoading = true;
    },

    CreatePrintSpecifications: (state, action: PayloadAction<printSpecificationsForm[]>) => {
      const entity = action.payload[0];
      const isExisted = state.printSpecificationsForm.find(e => e.jobQuoteId === action.payload[0].jobQuoteId);
      if (isExisted) {
        state.printSpecificationsForm = state.printSpecificationsForm.map(item => {
          if (item.jobQuoteId === action.payload[0].jobQuoteId) {
            item = { ...item, ...action.payload[0] };
          }
          return item;
        });
      } else {
        state.printSpecificationsForm = [...state.printSpecificationsForm, entity];
      }
      state.isLoading = false;
    },

    changeOpenForSupplierItem(state, action: PayloadAction<number>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(item => ({
        ...item,
        open: item.jobQuoteId === action.payload ? !item.open : item.open,
      }));
    },

    changeCheckedForSupplierItem(state, action: PayloadAction<number>) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map((item, i) => {
        return {
          ...item,
          checked: i === action.payload ? !item.checked : item.checked,
        };
      });
    },

    masterProductItemOnChange(
      state,
      action: PayloadAction<{
        flag: boolean;
        open: boolean;

        checked: boolean;
      }>,
    ) {
      state.productItemsState.productItemDetails.rows = state.productItemsState.productItemDetails.rows.map(item => {
        return {
          ...item,
          open: action.payload.flag ? !action.payload.open : item.open,
          checked: action.payload.flag ? item.checked : !action.payload.checked,
        };
      });
    },

    loadProductInformation(state) {
      state.productInformation.isLoading = true;
    },

    productInformationLoaded(state, action: PayloadAction<ProductInformationType>) {
      state.productInformation.values = action.payload;
      state.productInformation.isLoading = false;
    },

    productInformationError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },

    loadMaterial(state) {
      state.isLoading = true;
    },

    materialLoaded(state, action: PayloadAction<material[]>) {
      state.material = action.payload;
      state.isLoading = false;
    },

    materialError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },

    responseCreateQuote(state, action: PayloadAction<any>) {
      state.printSpecificationsForm = state.printSpecificationsForm.map(item => {
        if (item.jobQuoteId === action.payload.jobQuoteId) item = { ...item, ...action.payload };
        return item;
      });
    },
  },
});

export const { actions: createQuoteActions, reducer } = createQuoteSlice;

export const useCreateQuoteSlice = () => {
  useInjectReducer({
    key: createQuoteSlice.name,
    reducer: createQuoteSlice.reducer,
  });
  useInjectSaga({ key: createQuoteSlice.name, saga: CreateQuoteSaga });
  return { actions: createQuoteSlice.actions };
};
