import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../store/model';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.outStandingPurchase || initialState;

export const selectSearch = createSelector([selectSlice], outstandingPurchaseState => outstandingPurchaseState.search);
export const selectAccount = createSelector([selectSlice], outstandingPurchaseState => outstandingPurchaseState.account);
export const selectPo = createSelector([selectSlice], outstandingPurchaseState => outstandingPurchaseState.po.data);
export const isPoDetailsLoading = createSelector([selectSlice], outstandingPurchaseState => outstandingPurchaseState.isPoDetailsLoading);
