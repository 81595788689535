//  Externals
import React from 'react';
//  Internals
import {
  BAG,
  BANNER,
  BOOKLET,
  BROCHURE,
  BUSINESS_CARDS,
  CARDS,
  CLOTHING,
  FLYER,
  LARGE_FORMAT_BOARD,
  LEAFLET,
  LETTERHEAD,
  POSTCARDS,
  POSTER,
  PULL_UP_BANNER,
  SIGNAGE,
  SPECIAL_ITEM,
} from '../../../../../data/constants';
import { GetProductItemProps } from './types';
/*  Products  Component */
import Bag from './../../products/bag/index';
import Banner from './../../products/banner/index';
import Booklet from '../../products/booklet/index';
import Brochure from '../../products/brochure/index';
import BusinessCard from '../../products/businessCards';
import Cards from '../../products/cards/index';
import Clothing from './../../products/clothing/index';
import Flyer from '../../products/flyer/index';
import LargeFormatBoard from './../../products/largeFormatBoard/index';
import Leaflet from '../../products/leaflet';
import Letterhead from '../../products/letterhead';
import PostCard from '../../products/postcards/index';
import Poster from '../../products/poster';
import PullUpBanner from './../../products/pullUpBanner/index';
import Signage from './../../products/signage/index';
import SpecialItem from './../../products/specialItem/index';

const GetProductItem = (props: GetProductItemProps) => {
  const { clientQuotations, create, disabled, formId, initialState, jobId, onHide, productId, productType, statusName } = props;
  if (productType === BAG)
    return (
      <Bag
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === BANNER)
    return (
      <Banner
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === BOOKLET)
    return (
      <Booklet
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === BROCHURE)
    return (
      <Brochure
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === BUSINESS_CARDS)
    return (
      <BusinessCard
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === CARDS)
    return (
      <Cards
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === CLOTHING)
    return (
      <Clothing
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === FLYER)
    return (
      <Flyer
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === LARGE_FORMAT_BOARD)
    return (
      <LargeFormatBoard
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === LEAFLET)
    return (
      <Leaflet
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === LETTERHEAD)
    return (
      <Letterhead
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === POSTCARDS)
    return (
      <PostCard
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === POSTER)
    return (
      <Poster
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === PULL_UP_BANNER)
    return (
      <PullUpBanner
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === SIGNAGE)
    return (
      <Signage
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  if (productType === SPECIAL_ITEM)
    return (
      <SpecialItem
        clientQuotations={clientQuotations}
        create={create}
        disabled={disabled}
        formId={formId}
        initialState={initialState}
        jobId={jobId}
        onHide={onHide}
        productId={productId}
        statusName={statusName}
      />
    );
  return <></>;
};

export default GetProductItem;
