import React from 'react';

import { protectedResources } from '../../../configs/msalAuthConfigs';
import { ReactComponent as CalendarIcon } from 'images/calendar.svg';
import { getActionToggle } from 'store/reducers/entities/selectors';
import { setUpdateJobData } from 'store/reducers/entities';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiCallBegan } from 'store/reducers/loading';
import { useRequestForQuoteSlice } from './slice';
import MODULES from '../../../data/modules';
import DatePicker from 'react-datepicker';
import { request } from 'utils/request';
import API from '../../../data/api';
import moment from 'moment';
import * as yup from 'yup';
import './style.sass';

export const DeliveredPODPopup = props => {
  const { show, onHide, rfqJobId, productTemplateIds, productTemplateTitle } = props;
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));
  const { actions } = useRequestForQuoteSlice();
  const dispatch = useDispatch();
  const validationSchema = yup.object({
    podReference: yup.string().trim().nullable().required('Please enter comments'),
    podDate: yup.string().trim().nullable().required('Please provide a Date'),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const formData = {
      productTemplateIds: [...productTemplateIds],
      rfqJobId: Number(rfqJobId),
      podDate: data.podDate && moment(new Date(data.podDate), 'DD/MM/YYYY').format('YYYY-MM-DD'),
      podReference: data.podReference,
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Quotes/ProofOfDelivery/${rfqJobId}`, {
        method: 'PUT',
        body: JSON.stringify(formData),
      });
      dispatch(actions.updateStatusToOrdered());
      rfqJobId && dispatch(actions.loadRfqData({ jobId: rfqJobId, toggleAction: toggleAction }));
      rfqJobId && dispatch(apiCallBegan({ url: `${API.GET_JOB}/${rfqJobId}`, method: 'GET', onSuccess: setUpdateJobData.type, module: MODULES.MY_JOBS }));
      reset();
      onHide();
    } catch (err) {}
  };

  return (
    <Modal backdrop="static" keyboard={false} show={show} onHide={() => onHide()} centered>
      <Modal.Header style={{ border: '0px' }} closeButton>
        <Modal.Title>
          <h5>Proof of Delivery</h5>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <h6>You are providing proof of delivery for the following lines</h6>
          {productTemplateTitle.map(item => (
            <h6 style={{ color: '#968d8d' }} key={item}>
              {item}
            </h6>
          ))}
          <Form.Group style={{ marginTop: '30px' }}>
            <Form.Label>Proof Of Delivery Date</Form.Label>
            <Controller
              control={control}
              name="podDate"
              render={({ field: { onBlur, onChange, ref, value } }) => (
                <div className="form-control picker__container">
                  <DatePicker
                    className="picker__input"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    onBlur={onBlur} //  Notify when input is touched
                    onChange={onChange}
                    placeholderText="Select Date"
                    ref={ref}
                    selected={value}
                    showYearDropdown
                    strictParsing
                    shouldCloseOnSelect={true}
                  />
                  <CalendarIcon />
                </div>
              )}
            />
            {errors.podDate && <Form.Text className="text-danger">{errors.podDate.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="form__podReference" style={{ gridColumn: '1' }}>
            <Form.Label>Comments</Form.Label>
            <Form.Control as="textarea" placeholder="POD Reference" className={errors.podReference && 'border-danger'} rows={3} {...register('podReference')} />
            {errors.podReference && <Form.Text className="text-danger">{errors.podReference.message}</Form.Text>}
          </Form.Group>
        </Modal.Body>
        <hr style={{ marginBottom: '0px' }} />
        <Modal.Footer style={{ paddingBottom: '0px' }}>
          <Button className="outline__button" variant="outline-primary" onClick={() => onHide()}>
            Cancel
          </Button>
          <Button variant="outline-primary" className="outline__button" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
