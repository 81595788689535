import React, { useEffect, useState } from 'react';

import { getIsClient } from '../../components/modules/sideBar/slice/selectors';
import { ReactComponent as CalendarIcon } from '../../../images/calendar.svg';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { protectedResources } from '../../../configs/msalAuthConfigs';
import { useRequestForQuoteSlice } from '../requestForQuote/slice';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { getAllUserList } from './slice/selectors';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { request } from 'utils/request';
import Select from 'react-select';
import moment from 'moment';
import * as yup from 'yup';
import './style.sass';
import { fixTimezoneOffset } from 'utils/helperFunctions';

type FormValues = {
  status: string;
  assignee: number;
  dueDate: string;
  completionDate: number;
  notes: string;
  name: string;
};

const PopupTaskNext = props => {
  const dispatch = useDispatch();
  const { actions: requestForQuoteActions } = useRequestForQuoteSlice();
  const { show, onHide, data, isEdit, titleName, onBack } = props;
  const [modalShow, setModalShow] = useState(false);
  const { jobId } = useParams();
  const users: { value: number; label: string }[] = useSelector(getAllUserList);
  const isClient = useSelector(getIsClient);
  const validationSchema = yup.object({
    ...(!props.isProductId && {
      name: yup.string().trim().nullable().required('Please provide title'),
    }),
    status: yup.string().trim().nullable(),
    assignee: yup.number().nullable().required('Please provide a assignee'),
    dueDate: yup.string().trim().nullable().required('Please provide due date'),
    completionDate: yup.string().trim().nullable(),
    notes: yup.string().trim().nullable(),
  });

  const onSubmit = async values => {
    const requestPayload = values;
    delete requestPayload?.taskDetailsList;
    delete requestPayload?.taskInProductList;
    delete requestPayload?.defaultYN;
    delete requestPayload?.productTemplateTitle;
    props.isEdit && delete requestPayload?.productTemplateId;
    const updatedRequestPayload = {
      ...requestPayload,
      ...(props.isProductId && { name: props.isEdit ? requestPayload.name : props?.titleName }),
      ...(props.isEdit && { id: props.task?.id }),
      ...(!props.isEdit && { rfqJobId: Number(jobId), taskId: null, productTemplateId: props.isProductId ? props?.productid : null }),

      dueDate: requestPayload.dueDate && JSON.stringify(fixTimezoneOffset(new Date(requestPayload.dueDate))).replace(/"/g, ''),
      completionDate: requestPayload.completionDate && JSON.stringify(fixTimezoneOffset(new Date(requestPayload.completionDate))).replace(/"/g, ''),
    };
    try {
      if (props.isEdit) {
        await request(`${protectedResources.api.apiEndpoint}/Task/EditTasks/${updatedRequestPayload.id}`, {
          method: 'PUT',
          body: JSON.stringify(updatedRequestPayload),
        });
        dispatch(requestForQuoteActions.setUpdateTasksData({ id: updatedRequestPayload.id, data: updatedRequestPayload, isProductId: props.isProductId }));
      } else {
        await request(`${protectedResources.api.apiEndpoint}/Task/AddTasks`, {
          method: 'POST',
          body: JSON.stringify(updatedRequestPayload),
        }).then(res => {
          dispatch(requestForQuoteActions.setNewAddTasksData({ ...res }));
        });
      }
      let url = `/Task/GetTasksbyJob/${jobId}`;
      if (props.isProductId) url = `/Task/GetTasksbyJob/${jobId}?productTemplateId=${props.productid}`;
      dispatch(requestForQuoteActions.loadAllTaskData({ url: url, isProductId: props.isProductId }));
      dispatch(requestForQuoteActions.loadAllUsers({ isClient: isClient, jobId: Number(jobId) }));
    } catch (responseError) {
      console.error('Error while submitting the form ' + responseError);
    }
    props.onHide();
  };

  useEffect(() => {
    setModalShow(props.show);
    reset(props.task);
  }, [props]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <div className="popup_task_next">
        <Modal backdrop="static" keyboard={false} centered show={modalShow} onHide={onHide}>
          <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)} id="popupTask">
              <Row>
                {props.isProductId ? (
                  <div className="d-flex align-items-center" tabIndex={-1}>
                    <IconButton
                      tabIndex={-1}
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        onHide();
                        onBack();
                      }}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <span>{titleName}</span>
                    <IconButton className="ms-auto" aria-label="close row" size="small" onClick={() => onHide()} tabIndex={-1}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                ) : (
                  <div>
                    <div className="d-flex">
                      <h4 className="mb-3">Add Task</h4>
                      <div className="ms-3 ms-auto" tabIndex={-1}>
                        <IconButton aria-label="close row" size="small" onClick={() => onHide()} tabIndex={-1}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                    <Form.Group>
                      <Form.Label>
                        Title<span className="input__required">*</span>
                      </Form.Label>
                      <Form.Control autoComplete="off" disabled={props.disabled} type="text" placeholder="Enter name" maxLength={100} {...register('name')} />
                      {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
                    </Form.Group>
                  </div>
                )}
              </Row>
              <div className="mt-3">
                <Row>
                  <Col>
                    <Form.Group controlId="form__status">
                      <Form.Label
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        Status
                      </Form.Label>
                      <Form.Control autoComplete="off" disabled type="text" {...register('status')} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>
                          Assignee<span className="input__required">*</span>
                        </span>
                      </Form.Label>
                      <Controller
                        control={control}
                        name="assignee"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Select
                            className={errors.status && 'border-danger'}
                            classNamePrefix="custom-select-input"
                            id="form__assignee"
                            onBlur={onBlur}
                            onChange={val => {
                              onChange(val?.value);
                            }}
                            options={users}
                            ref={ref}
                            value={users.find(user => user.value === value) || null}
                          />
                        )}
                      />
                      {errors.assignee && (
                        <Form.Text className="text-danger">
                          <h6>{errors.assignee.message}</h6>
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <div className="row mt-3">
                  <div className="col-6 ">
                    <Form.Group controlId="form__status">
                      <Form.Label>
                        Due Date<span className="input__required">*</span>
                      </Form.Label>
                      <Controller
                        control={control}
                        name="dueDate"
                        render={({ field: { onBlur, onChange, ref, value } }) => (
                          <div className="form-control picker__container picker__container__task">
                            <DatePicker
                              style={{ border: 'none', width: '100%' }}
                              className="picker__input"
                              dateFormat="dd/MM/yyyy"
                              minDate={new Date()}
                              onBlur={onBlur} //  Notify when input is touched
                              onChange={date => onChange(date)}
                              placeholderText="Select Due Date"
                              ref={ref}
                              selected={Date.parse(value)}
                              showYearDropdown
                              strictParsing
                              shouldCloseOnSelect={true}
                            />
                            <CalendarIcon />
                          </div>
                        )}
                      />
                      {errors.dueDate && <Form.Text className="text-danger">{errors.dueDate.message}</Form.Text>}
                    </Form.Group>
                  </div>
                  {isEdit && (
                    <div className="col-6">
                      <Form.Group controlId="form__status">
                        <Form.Label>Completion Date</Form.Label>
                        <Controller
                          control={control}
                          name="completionDate"
                          render={({ field: { onBlur, onChange, ref, value } }) => (
                            <div className="form-control picker__container picker__container__task">
                              <DatePicker
                                className="picker__input"
                                minDate={new Date()}
                                onBlur={onBlur} //  Notify when input is touched
                                onChange={onChange}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Select Completion Date"
                                ref={ref}
                                selected={value}
                                showYearDropdown
                                strictParsing
                                shouldCloseOnSelect={true}
                              />
                              <CalendarIcon />
                            </div>
                          )}
                        />
                      </Form.Group>
                    </div>
                  )}
                </div>
                <Col className="mt-3">
                  <Form.Group>
                    <Form.Label>Notes</Form.Label>
                    <Form.Control as="textarea" placeholder="Enter Notes" rows={3} {...register('notes')} />
                  </Form.Group>
                </Col>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" className="outline__button" onClick={() => onHide()}>
              Cancel
            </Button>
            <Button variant="outline-primary" className="outline__button" type="submit" form="popupTask">
              {props.isEdit ? 'Update Item' : 'Add Task'}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default PopupTaskNext;
