import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import './style.sass';
import { selectSupplierData, selectTempSelectedSupplierList, useParamSelector } from '../slice/selectors';
import { useDispatch } from 'react-redux';
import { useRequestForQuoteSlice } from '../slice';
import { ReactComponent as CalendarIcon } from './../../../../images/calendar.svg';
import { ReactComponent as ClockIcon } from './../../../../images/clock.svg';
import moment from 'moment';
import Select, { components } from 'react-select';
import { getTimePickerHours } from 'utils/helperFunctions';

const AssignSuppliersForm = props => {
  const dispatch = useDispatch();
  const { actions } = useRequestForQuoteSlice();
  const selectedTempSuppliers = useParamSelector(selectTempSelectedSupplierList, props.rowid);
  const [timePickerHours, setTimePickerHours] = useState(getTimePickerHours());

  const disableFieldFlag = selectedTempSuppliers.length > 0;
  const initialValues = useParamSelector(selectSupplierData, props.rowid);
  const validationSchema = yup.object({
    dueDate: yup.string().trim().nullable().required('Please enter Due Date'),
    dueTime: yup.string().trim().nullable().required('Please enter Due Time'),
    note: yup.string().trim().nullable(),
  });

  const onSubmit = values => {
    props.showConfirmModal(true);
    var value = moment();
    var m = moment(value, 'ddd MMM D YYYY HH:mm:ss');
    m.set({ h: values.dueTime, m: 0 });

    const updatedValues = {
      ...values,
      dueDate: values.dueDate && JSON.stringify(values.dueDate).replace(/"/g, ''),
      dueTime: values.dueTime && JSON.stringify(m.toDate()).replace(/"/g, ''),
    };

    dispatch(
      actions.setAssignSuppliersFormValues({
        id: props.rowid,
        form: updatedValues,
      }),
    );
  };

  const onError = error => {
    console.error('ERROR:::', error);
    props.showConfirmModal(false);
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: initialValues.assignSuppliersFormData,
    resolver: yupResolver(validationSchema),
  });

  const { dueDate } = useWatch({ control });

  const GetClockIcon = () => {
    return (
      <ClockIcon
        style={{
          position: 'absolute',
          right: '8px',
          top: '8px',
          width: '24px',
          outline: 'none',
        }}
      />
    );
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <GetClockIcon />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    const x = moment(getValues('dueDate')).format('YYYY-MM-DD');
    const y = moment().format('YYYY-MM-DD');
    var hours: any[] = [];

    if (moment(x).isSame(moment(y))) {
      for (let i = 0; i <= moment().hour(); i++) {
        timePickerHours[i]['disabled'] = true;
        hours = [...hours, { ...timePickerHours[i] }];
      }
    }

    const z = timePickerHours.map((item, index) => (item?.value == hours[index]?.value ? { ...item, disabled: true } : { ...item, disabled: false }));
    setTimePickerHours(z);
  }, [dueDate, getValues]);

  useEffect(() => {
    !disableFieldFlag && reset();
  }, [disableFieldFlag, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)} id="supplierForm" className="supplierForm">
      <Form.Group controlId="form__dueDate" id="form__dueDate">
        <Form.Label>
          Select Due Date<span className="input__required">*</span>
        </Form.Label>
        <Controller
          control={control}
          name="dueDate"
          render={({ field }) => (
            <div className={`form-control picker__container ${!disableFieldFlag ? 'picker__container__disabled' : ''} ${disableFieldFlag && errors.dueDate && 'border-danger'} `}>
              <DatePicker
                disabled={!disableFieldFlag}
                className="picker__input"
                minDate={new Date()}
                dateFormat="dd/MM/yyyy"
                placeholderText="Select Date"
                selected={Date.parse(field.value)}
                strictParsing
                showYearDropdown
                shouldCloseOnSelect={true}
                scrollableYearDropdown
                onChange={date => {
                  field.onChange(date);
                }}
              />
              <CalendarIcon />
            </div>
          )}
        />
        {disableFieldFlag && errors.dueDate && <Form.Text className="text-danger">{errors.dueDate.message}</Form.Text>}
      </Form.Group>
      <Form.Group controlId="form__dueTime">
        <Form.Label>
          Select Due Time<span className="input__required">*</span>
        </Form.Label>
        <Controller
          control={control}
          name="dueTime"
          render={({ field: { onBlur, onChange, ref, value } }) => (
            <Select
              isDisabled={!disableFieldFlag}
              classNamePrefix="assign-supplier-select-input"
              className={errors.dueTime && 'border-danger-select'}
              components={{ DropdownIndicator }}
              id="form__dueTime"
              onBlur={onBlur}
              onChange={val => onChange(val?.value)}
              options={timePickerHours}
              isOptionDisabled={option => option.disabled}
              placeholder={'Select Time'}
              ref={ref}
              value={timePickerHours.find(item => item.value === value) || null}
            />
          )}
        />
        {disableFieldFlag && errors.dueTime && <Form.Text className="text-danger">{errors.dueTime.message}</Form.Text>}
      </Form.Group>
      <Form.Group controlId="form__note" style={{ gridColumn: '1/3' }}>
        <Form.Label>
          <span>Note</span>
        </Form.Label>
        <Form.Control autoComplete="off" as="textarea" placeholder="Enter note here" disabled={!disableFieldFlag} rows={3} {...register('notes', {})} />
        {errors.note && <Form.Text className="text-danger">{errors.notes.message}</Form.Text>}
      </Form.Group>
    </Form>
  );
};

export default AssignSuppliersForm;
