//  Externals
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { Chip } from '@material-ui/core';
import { sortBy } from 'lodash';
//  Internals
import { ReactComponent as AddProductItemIcon } from '../../../../images/addProductItem.svg';
import LoadingSpinnerWithBackdrop from 'app/components/shared/LoadingSpinnerWithBackdrop';
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { getGeoLocations, getSupplierCategories } from './slice/selector';
import { Category, SecondaryCategory, initialCategory } from './types';
import { DropDown } from 'app/components/modules/products/types';
import PLACEHOLDER from '../../../../data/placeholder';
import { useAdminSuppliersSlice } from './slice';
import { request } from 'utils/request';
import schema from './validator';

export interface InitialInputState {
  categories: any;
  supplierName: string;
  supplierReference: string;
  contact: string;
  contactEmailAddress: string;
  address: string;
  postCode: string;
  telephone: string;
  codeName: string;
  website: string;
  notes: string;
  isActive: boolean;
  reminderPreferrences: boolean;
  vendorId: string;
  supplierGeolocationsId: (number | null)[];
  primaryCategoryId: number;
  secondaryCategoryId: number;
}

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: 'flex' }}>
          <Form.Check aria-label={props.label} checked={props.isSelected} id={props.label} onChange={() => null} />
          <label style={{ marginLeft: '0.5rem' }}>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

const EditSupplier = props => {
  const { onHide, show, supplier } = props;

  const dispatch = useDispatch();

  const { actions } = useAdminSuppliersSlice();

  const supplierCategories = useSelector(getSupplierCategories);
  const geoLocations = useSelector(getGeoLocations);

  const [geoLocationsList, setGeoLocationsList] = useState<DropDown[]>([]);
  const [selectedGeoLocationsList, setSelectedGGeoLocationsList] = useState<DropDown[]>([]);

  useEffect(() => {
    setGeoLocationsList(geoLocations.map(geoLocation => ({ value: geoLocation.id, label: geoLocation.name })));
  }, [geoLocations]);

  useEffect(() => {
    //  Geographic Locations
    const sortedGeoLocationsList = sortBy(geoLocationsList, 'label');
    setSelectedGGeoLocationsList(sortedGeoLocationsList.filter(geoLocation => supplier.supplierGeolocationsId.includes(geoLocation.value)));
  }, [geoLocationsList, supplier.supplierGeolocationsId]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema()),
  });

  useEffect(() => {
    reset({ ...supplier, isActive: supplier.isActive !== null ? (supplier.isActive === true ? 'Yes' : 'No') : null, reminderPreferrences: supplier.reminderPreferrences === true ? 'Yes' : 'No' });
  }, [reset, supplier]);

  const [categories, setCategories] = useState<Category[]>([{ ...initialCategory }]);
  const [primaryCategories, setPrimaryCategories] = useState<DropDown[]>([]);
  const [secondaryCategories, setSecondaryCategories] = useState<SecondaryCategory[]>([]);

  useEffect(() => {
    setPrimaryCategories(
      supplierCategories.supplierPrimaryCategories.map(o => ({
        label: o.name,
        value: o.primaryCategoryId,
      })),
    );
    setSecondaryCategories(
      supplierCategories.supplierSecondaryCategories.map(o => ({
        label: o.name,
        primaryCategoryId: o.primaryCategoryId,
        value: o.secondaryCategoryId,
      })),
    );
    //  Categories
    setCategories([...supplier.categories]);
  }, [supplier.categories, supplierCategories.supplierPrimaryCategories, supplierCategories.supplierSecondaryCategories]);

  /******************************************************************************************
  @Purpose    :  Handle Geographic location
  *******************************************************************************************/
  const handleGeographicLocation = values => {
    const sortedValues = sortBy(values, 'label');
    setSelectedGGeoLocationsList(sortedValues);
  };

  /******************************************************************************************
  @Purpose    :  Handle Remove Geographic location
  *******************************************************************************************/
  const handleRemoveGeographicLocation = value => {
    const sortedGeoLocationsList = sortBy(selectedGeoLocationsList, 'label');
    const updatedGeographicLocations = sortedGeoLocationsList.filter(geoLocation => geoLocation.value !== value);
    setValue(
      'supplierGeolocationsId',
      updatedGeographicLocations.map(geoLocation => geoLocation.value),
      {
        shouldValidate: false,
      },
    );
    setSelectedGGeoLocationsList(updatedGeographicLocations);
  };

  /******************************************************************************************
  @Purpose    :  Handle Add Category
  *******************************************************************************************/
  const handleAddCategory = () => {
    setCategories([...categories, { ...initialCategory }]);
  };

  /******************************************************************************************
  @Purpose    :  Handle Add Category
  *******************************************************************************************/
  const handleRemoveCategory = index => {
    const updateSecondaryCategoryId = categories.map((category, i) => {
      const entity = { ...category };
      if (i === index) entity.secondaryCategoryId = [];
      return entity;
    });
    const updatedCategories = updateSecondaryCategoryId.filter((category, i) => index !== i);
    setCategories(updatedCategories);
  };

  /******************************************************************************************
  @Purpose    :  Handle Primary Category
  *******************************************************************************************/
  const handlePrimaryCategory = (index, value) => {
    const updatedCategories = categories.map((category, i) => {
      const entity = { ...category };
      if (i === index) {
        entity.primaryCategoryId = value;
        entity.secondaryCategoryId = [];
      }
      return entity;
    });
    setCategories([...updatedCategories]);
  };

  /******************************************************************************************
  @Purpose    :  Handle Secondary Category
  *******************************************************************************************/
  const handleSecondaryCategory = (index, values) => {
    const uniqueValues = values
      ? values.filter((value, i) => {
          const index = values.findIndex(o => o.value === value.value);
          if (index === i) return value;
        })
      : [];
    const sortedValues = sortBy(uniqueValues, 'label');

    const updatedCategories = categories.map((entity, i) => {
      const category = { ...entity };
      if (i === index) category.secondaryCategoryId = sortedValues ? sortedValues.map(o => o.value) : [];
      return category;
    });
    setCategories(updatedCategories);
  };

  /******************************************************************************************
  @Purpose    :  Handle Remove Secondary Category
  *******************************************************************************************/
  const handleRemoveSecondaryCategory = (index: number, value: number) => {
    const updatedCategories = categories.map((category, i) => {
      if (i === index) category.secondaryCategoryId = category.secondaryCategoryId.filter(secondaryCategory => secondaryCategory !== value);
      return category;
    });
    setCategories(updatedCategories);
  };

  const onSubmit = async values => {
    setLoading(true);
    const payload = {
      ...values,
      isActive: values.isActive ? (values.isActive === 'Yes' ? true : false) : null,
      reminderPreferrences: values.reminderPreferrences === 'Yes' ? true : false,
      categories: categories.filter(category => category.primaryCategoryId),
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Supplier/api/Supplier/UpdateSupplier/${supplier.supplierId}`, {
        method: 'PUT',
        body: JSON.stringify(payload),
      }).then(response => {
        if (response.isSuccess) {
          dispatch(actions.loadSupplierList());
          onHide();
        } else {
          setError(response.message);
        }
      });
    } catch (responseError) {
      console.log('Error while submitting the form ' + responseError);
    }
    setLoading(false);
  };

  const onError = error => {
    console.log('ERROR:::', error);
  };

  return (
    <>
      {loading && <LoadingSpinnerWithBackdrop color="grey" />}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="fullScreen"
        centered
        keyboard={false}
        onHide={() => {
          onHide();
        }}
        show={show}
        size="lg"
      >
        <Modal.Header closeButton className="border-0">
          <h4>Edit Supplier</h4>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit, onError)} id="editSupplier">
          <Modal.Body>
            <>
              {error !== '' && (
                <div className="text-danger text-center w-100" style={{ marginBottom: '20px' }}>
                  <strong className="fs-6">{error}</strong>
                </div>
              )}
              <div className="row">
                <div className="col-6">
                  <Form.Group controlId="form__supplierName">
                    <Form.Label>
                      Name<span className="input__required">*</span>
                    </Form.Label>
                    <Form.Control autoComplete="off" className={errors.supplierName && 'border-danger'} placeholder="Enter Name" type="text" {...register('supplierName')} />
                    {errors.supplierName && <Form.Text className="text-danger">{errors.supplierName.message}</Form.Text>}
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group controlId="form__supplierReference">
                    <Form.Label>
                      Supplier Ref<span className="input__required">*</span>
                    </Form.Label>
                    <Form.Control autoComplete="off" className={errors.supplierReference && 'border-danger'} placeholder="Enter Supplier Ref" type="text" {...register('supplierReference')} />
                    {errors.supplierReference && <Form.Text className="text-danger">{errors.supplierReference.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <Form.Group controlId="form__contactEmailAddress">
                    <Form.Label>
                      Email<span className="input__required">*</span>
                    </Form.Label>
                    <Form.Control autoComplete="off" className={errors.contactEmailAddress && 'border-danger'} placeholder="Enter Email" type="text" {...register('contactEmailAddress')} />
                    {errors.contactEmailAddress && <Form.Text className="text-danger">{errors.contactEmailAddress.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <Form.Group controlId="form__address">
                  <Form.Label>
                    Address<span className="input__required">*</span>
                  </Form.Label>
                  <Form.Control as="textarea" autoComplete="off" className={errors.address && 'border-danger'} placeholder="Enter Address" type="text" rows={3} {...register('address')} />
                  {errors.address && <Form.Text className="text-danger">{errors.address.message}</Form.Text>}
                </Form.Group>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <Form.Group controlId="form__postCode">
                    <Form.Label>
                      Post Code<span className="input__required">*</span>
                    </Form.Label>
                    <Form.Control autoComplete="off" className={errors.postCode && 'border-danger'} max={10} placeholder="Enter Post Code" type="text" {...register('postCode')} />
                    {errors.postCode && <Form.Text className="text-danger">{errors.postCode.message}</Form.Text>}
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group controlId="form__telephone">
                    <Form.Label>
                      Telephone<span className="input__required">*</span>
                    </Form.Label>
                    <Form.Control autoComplete="off" className={errors.telephone && 'border-danger'} placeholder="Enter Telephone" type="text" {...register('telephone')} />
                    {errors.telephone && <Form.Text className="text-danger">{errors.telephone.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <Form.Group controlId="form__website">
                    <Form.Label>Website</Form.Label>
                    <Form.Control autoComplete="off" className={errors.website && 'border-danger'} placeholder="Enter Website" type="text" {...register('website')} />
                    {errors.website && <Form.Text className="text-danger">{errors.website.message}</Form.Text>}
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group controlId="form__isActive">
                    <Form.Label>
                      Active<span className="input__required">*</span>
                    </Form.Label>
                    <div>
                      <Form.Check aria-label="isActive-yes" id="form__isActive-yes" inline label="Yes" type="radio" value="Yes" {...register('isActive')} />
                      <Form.Check aria-label="isActive-no" id="form__isActive-no" inline label="No" type="radio" value="No" {...register('isActive')} />
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <Form.Group controlId="form__notes">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control as="textarea" autoComplete="off" className={errors.notes && 'border-danger'} placeholder="Enter Notes" type="text" rows={3} {...register('notes')} />
                </Form.Group>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <Form.Group controlId="form__codeName">
                    <Form.Label>Code Name</Form.Label>
                    <Form.Control autoComplete="off" className={errors.codeName && 'border-danger'} placeholder="Enter Code Name" type="text" {...register('codeName')} />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group controlId="form__reminderPreferrences">
                    <Form.Label>
                      Receive Reminders<span className="input__required">*</span>
                    </Form.Label>
                    <div>
                      <Form.Check aria-label="reminderPreferrences-yes" id="form__reminderPreferrences-yes" inline label="Yes" type="radio" value="Yes" {...register('reminderPreferrences')} />
                      <Form.Check aria-label="reminderPreferrences-no" id="form__reminderPreferrences-no" inline label="No" type="radio" value="No" {...register('reminderPreferrences')} />
                    </div>
                    {errors.reminderPreferrences && <Form.Text className="text-danger">{errors.reminderPreferrences.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <Form.Group controlId="form__vendorId">
                    <Form.Label>Vendor id</Form.Label>
                    <Form.Control autoComplete="off" className={errors.vendorId && 'border-danger'} placeholder="Enter Code Name" type="text" {...register('vendorId')} />
                    {errors.vendorId && <Form.Text className="text-danger">{errors.vendorId.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <Form.Group controlId="form__supplierGeolocationsId">
                    <Form.Label>Geographic Locations</Form.Label>
                    <Controller
                      control={control}
                      name="supplierGeolocationsId"
                      render={({ field: { onBlur, onChange, ref } }) => {
                        return (
                          <Select
                            className={`mt-1 ${errors.supplierGeolocationsId && 'border-danger-select'}`}
                            classNamePrefix="custom-select-input"
                            closeMenuOnSelect={false}
                            components={{ Option }}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            id="form__supplierGeolocationsId"
                            isClearable
                            isMulti
                            maxMenuHeight={175}
                            onBlur={onBlur} //  Notify when input is touched
                            onChange={(val, { action }) => {
                              if (action === 'clear') {
                                onChange(null);
                              } else {
                                onChange(val.map(o => o.value));
                              }
                              handleGeographicLocation(val);
                            }} // Send value to hook f = items.find(item => item.value = val);
                            options={geoLocationsList}
                            placeholder={PLACEHOLDER.SELECT_GEOGRAPHIC_LOCATION}
                            ref={ref}
                            value={selectedGeoLocationsList}
                          />
                        );
                      }}
                    />
                    {errors.supplierGeolocationsId && <Form.Text className="text-danger">{errors.vendorId.message}</Form.Text>}
                  </Form.Group>
                  {selectedGeoLocationsList.length !== 0 &&
                    selectedGeoLocationsList.map(selectedGeoLocation => (
                      <Chip
                        key={selectedGeoLocation.value}
                        label={selectedGeoLocation.label}
                        style={{ margin: '10px 10px 0px 0px', borderRadius: '5px', border: '1px solid #cdcccc' }}
                        clickable
                        onDelete={() => handleRemoveGeographicLocation(selectedGeoLocation.value)}
                      />
                    ))}
                </div>
              </div>
              <h6 className="fw-bold mt-5 ">Categories</h6>
              <div className="mt-3">
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '23rem', marginBottom: '0.5rem' }}>
                  <div onClick={() => handleAddCategory()} className="addMore">
                    Add More Categories
                    <AddProductItemIcon className="svg__icon" />
                  </div>
                </div>
                {categories.map((category, i) => (
                  <div className="row mb-3" key={i}>
                    <div className="col-6">
                      <Form.Group controlId="form__primaryCategoryId" style={{ gridColumn: '1 / 2' }}>
                        <Form.Label>Primary Category</Form.Label>
                        <Select
                          className={errors.primaryCategoryId && 'border-danger-select'}
                          classNamePrefix="custom-select-input"
                          id={`form__primaryCategoryId_${i}`}
                          isClearable
                          maxMenuHeight={175}
                          name="primaryCategoryId"
                          onChange={(val, { action }) => {
                            if (action === 'clear') {
                              handlePrimaryCategory(i, null);
                            } else {
                              handlePrimaryCategory(i, val?.value);
                            }
                          }} // Send value to hook f = items.find(item => item.value = val);
                          options={primaryCategories.filter(primaryCategory => {
                            if (primaryCategory.value === category.primaryCategoryId) return primaryCategory;
                            if (
                              !categories
                                .filter(category => category.primaryCategoryId)
                                .map(category => category.primaryCategoryId)
                                .includes(primaryCategory.value)
                            )
                              return primaryCategory;
                          })}
                          placeholder={PLACEHOLDER.SELECT_ARTWORK_FORMAT}
                          value={primaryCategories.find(primaryCategory => primaryCategory.value === category.primaryCategoryId)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      <Form.Group controlId={`secondaryCategoryId-${i}`}>
                        <div className="d-flex">
                          <Form.Label>Secondary Category</Form.Label>
                          {categories.length !== 1 && (
                            <span className="ms-auto pe-auto text-danger" onClick={() => handleRemoveCategory(i)} style={{ textAlign: 'right', cursor: 'pointer' }}>
                              Remove
                            </span>
                          )}
                        </div>
                        <Select
                          className={errors.secondaryCategoryId && 'border-danger-select'}
                          classNamePrefix="custom-select-input"
                          closeMenuOnSelect={false}
                          components={{ Option }}
                          controlShouldRenderValue={false}
                          hideSelectedOptions={false}
                          id={`form__secondaryCategoryId${i}`}
                          isClearable
                          isMulti
                          maxMenuHeight={175}
                          onChange={(val, { action }) => {
                            if (action === 'clear') {
                              handleSecondaryCategory(i, null);
                            } else {
                              handleSecondaryCategory(i, val);
                            }
                          }} // Send value to hook f = items.find(item => item.value = val);
                          options={secondaryCategories.filter(
                            secondaryCategory => secondaryCategory.primaryCategoryId === category.primaryCategoryId && { label: secondaryCategory.label, value: secondaryCategory.value },
                          )}
                          placeholder={PLACEHOLDER.SELECT_GEOGRAPHIC_LOCATION}
                          value={secondaryCategories.filter(secondaryCategory => category.secondaryCategoryId.includes(secondaryCategory.value))}
                        />
                      </Form.Group>
                      {category.secondaryCategoryId.map(value => (
                        <Chip
                          key={value}
                          label={secondaryCategories.find(item => item.value === value)?.label}
                          style={{ margin: '10px 10px 0px 0px', borderRadius: '5px', border: '1px solid #cdcccc' }}
                          clickable
                          onDelete={() => handleRemoveSecondaryCategory(i, value)}
                        />
                      ))}
                    </div>
                    {categories.length > 1 && <hr className="mt-3" />}
                  </div>
                ))}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="outline__button"
              onClick={() => {
                props.onHide();
              }}
              variant="outline-primary"
            >
              Cancel
            </Button>
            <Button className="full__button" form="editSupplier" type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditSupplier;
