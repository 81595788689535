import React, { useState } from 'react';
import { protectedResources } from '../../../configs/msalAuthConfigs';
import { ReactComponent as CalendarIcon } from 'images/calendar.svg';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExportJsonCsv } from 'react-export-json-csv';
import { useInvoiceSlice } from './slice';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { TableRow } from '@mui/material';
import { request } from 'utils/request';
import moment from 'moment';
import * as yup from 'yup';
import './style.sass';
import LoadingSpinnerWithBackdrop from 'app/components/shared/LoadingSpinnerWithBackdrop';

const SendInvoicePopup = props => {
  const { invoiceRow, sendInvoiceId } = props;
  const { actions } = useInvoiceSlice();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const validationSchema = yup.object({
    invoiceDate: yup.string().trim().nullable().required('Please provide a Date'),
  });

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async data => {
    const formData = [...sendInvoiceId];
    const invoiceDate = data?.invoiceDate && moment(new Date(data?.invoiceDate), 'DD/MM/YYYY').format('YYYY-MM-DD');
    setLoading(true);
    try {
      await request(`${protectedResources.api.apiEndpoint}/Quotes/SendInvoice?invoiceDate=${invoiceDate}`, {
        method: 'POST',
        body: JSON.stringify(formData),
      })
        .then(Response => {
          setLoading(false);
          props.onHide();
        })
        .catch(error => {
          setLoading(false);
          setError(' Something went wrong please try again.');
        });
      dispatch(actions.updateSendToInvoiceData([...sendInvoiceId]));
      dispatch(actions.loadInvoice());
      reset();
    } catch (err) {}
  };
  const data = invoiceRow.filter(item => sendInvoiceId.includes(item.accountId));
  const headers = [
    {
      label: 'Account',
      key: 'accountName',
    },
    {
      label: 'Frequency',
      key: 'frequency',
    },
    {
      label: 'Export Type',
      key: 'exportType',
    },
    {
      label: 'No.of Jobs',
      key: 'noofJob',
    },
    {
      label: 'Net Total',
      key: 'netTotal',
    },
    {
      label: 'Vat Amount',
      key: 'vatAmount',
    },
    {
      label: 'Gross',
      key: 'gross',
    },
  ];

  return (
    <>
      {loading && <LoadingSpinnerWithBackdrop color="grey" message="Please wait while connecting to access dimmensions" />}
      <Modal
        backdrop="static"
        keyboard={false}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="fullScreen"
        centered
        onHide={() => {
          props.onHide();
        }}
        dialogClassName="modal__confirmOrder"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Send to Invoice</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body className="modal__confirmOrderBody" style={{ height: '500px' }}>
            <>
              <Form.Group style={{ width: '400px' }}>
                <Form.Label>Invoice Date</Form.Label>
                <Controller
                  control={control}
                  name="invoiceDate"
                  render={({ field: { onBlur, onChange, ref, value } }) => (
                    <div className="form-control picker__container">
                      <DatePicker
                        className="picker__input"
                        dateFormat="dd/MM/yyyy"
                        // minDate={new Date()}
                        shouldCloseOnSelect={true}
                        onBlur={onBlur} //  Notify when input is touched
                        onChange={onChange}
                        placeholderText="Select Date"
                        ref={ref}
                        selected={value}
                        showYearDropdown
                        strictParsing
                      />
                      <CalendarIcon />
                    </div>
                  )}
                />
                {errors.invoiceDate && <Form.Text className="text-danger">{errors.invoiceDate.message}</Form.Text>}
              </Form.Group>
              <br />
              <Form.Group controlId="form__deliveryContact" style={{ gridColumn: '1/3' }}>
                <Table>
                  <tbody>
                    <TableRow style={{ border: '1px solid gray', fontSize: '12px', backgroundColor: '#e6e6e6' }}>
                      <th>Batch number</th>
                      <th>Account</th>
                      <th>Frequency</th>
                      <th>Export type</th>
                      <th style={{ textAlign: 'right' }}>No.of Jobs</th>
                      <th style={{ textAlign: 'right' }}>Net total (£)</th>
                      <th style={{ textAlign: 'right' }}>VAT amount (£)</th>
                      <th style={{ textAlign: 'right' }}>Grosst (£)</th>
                    </TableRow>
                    {invoiceRow?.map(item => {
                      return (
                        <>
                          {sendInvoiceId.map(id => {
                            return (
                              <>
                                {item.accountId === id && (
                                  <TableRow style={{ border: '1px solid gray' }}>
                                    <td>{item?.batchId ? item?.batchId : '-'}</td>
                                    <td>{item.accountName}</td>
                                    <td>{item.frequency}</td>
                                    <td>{item.exportName}</td>
                                    <td style={{ textAlign: 'right' }}>{item.noofJob}</td>
                                    <td style={{ textAlign: 'right' }}>{item.netTotal?.toFixed(2)}</td>
                                    <td style={{ textAlign: 'right' }}>{item.vatAmount?.toFixed(2)}</td>
                                    <td style={{ textAlign: 'right' }}>{item.gross?.toFixed(2)}</td>
                                  </TableRow>
                                )}
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </Form.Group>
              {error !== '' && (
                <div className="text-danger text-center w-100 pt-4">
                  <strong className="fs-6">{error}</strong>
                </div>
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <ExportJsonCsv
              variant="outline-primary"
              style={{ border: '1px solid #0099e6', borderRadius: '5px', color: '#0088cc', position: 'absolute', left: '15px', backgroundColor: 'white', height: '40px' }}
              headers={headers}
              items={data}
            >
              Export to CSV
            </ExportJsonCsv>
            <Button style={{ position: 'absolute', left: '155px' }} variant="outline-primary">
              Print
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                props.onHide();
                setError('');
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outline-primary"
              type="submit"
              onClick={() => {
                setError('');
              }}
            >
              Send To Invoice
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default SendInvoicePopup;
