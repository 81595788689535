//  Externals
import { IndeterminateCheckBox as IndeterminateCheckBoxIcon, KeyboardArrowRight as KeyboardArrowRightIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { Box, Collapse, Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
//  Internals
import { getJobInformation, getActionToggle } from 'store/reducers/entities/selectors';
import { setProductItems, setJobData } from '../../../../../store/reducers/entities';
import { PRINT_SPECIFICATIONS, QUOTES, TASKS } from '../../../../../data/constants';
import { getProductItems } from '../../../../../store/reducers/entities/selectors';
import { useRequestForQuoteSlice } from '../../../../pages/requestForQuote/slice';
import { protectedResources } from '../../../../../configs/msalAuthConfigs';
import { productTemplate } from 'app/pages/requestForQuote/slice/types';
import Quotes from '../../../../pages/requestForQuote/quotes/Quotes';
import { ProductItemType } from './../../../../../types/myJobs';
import Tasks from '../../../../pages/requestForQuote/Tasks';
import { getIsCDS } from '../../sideBar/slice/selectors';
import { userInfo } from '../../sideBar/slice/selectors';
import { DRAFT } from 'app/components/shared/Constants';
import ProductItemDetails from './ProductItemDetails';
import { apiCallBegan } from 'store/reducers/loading';
import MODULES from '../../../../../data/modules';
import AddDiscount from './actions/AddDiscounts';
import STATUS from '../../../../../data/status';
import API from '../../../../../data/api';
import { request } from 'utils/request';
import { setIsAddPoAction } from 'store/reducers/entities/index';

const RowDetails = props => {
  const { onOpen, onSelect, productItem, showActionDropDown, actionDropDownClick, productItems } = props;
  const { clientQuotations, printSpecifications } = productItem;
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));
  const { actions } = useRequestForQuoteSlice();
  const isCDS = useSelector(getIsCDS);
  const [showAddDiscountModal, setShowAddDiscountModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelStatusId, setCancelStatusId] = useState<number>();
  const [onHoldStatusId, setOnHoldStatusId] = useState<number>();
  const [offHoldStatusId, setoffHoldStatusId] = useState<number>();
  const [showModelDelete, setShowModelDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<any>();
  const [cancelId, setCancelId] = useState<any>();

  const dispatch = useDispatch();

  const [tabs, setTabs] = useState({
    [PRINT_SPECIFICATIONS]: { visible: true, selected: true, disabled: false },
    [QUOTES]: { visible: true, selected: false, disabled: false },
    [TASKS]: { visible: true, selected: false, disabled: false },
  });

  const userInformation = useSelector(userInfo);
  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));

  useEffect(() => {
    //  Check whether the product items status is Draft or not
    const quotesDisabledStatus = printSpecifications.status.code === STATUS.DRAFT;
    const tasksDisabledStatus = printSpecifications.status.code === STATUS.DRAFT;
    const updatedNavigation = {
      ...tabs,
    };
    updatedNavigation[QUOTES].disabled = quotesDisabledStatus;
    updatedNavigation[TASKS].disabled = tasksDisabledStatus;
    setTabs(updatedNavigation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printSpecifications.status.code]);

  /******************************************************************************************
  @Purpose    :   To switch Navigation
  *******************************************************************************************/
  const switchTab = (tab: string) => {
    setTabs(prevState => {
      return {
        [PRINT_SPECIFICATIONS]: {
          ...prevState[PRINT_SPECIFICATIONS],
          selected: tab === PRINT_SPECIFICATIONS,
        },
        [QUOTES]: {
          ...prevState[QUOTES],
          selected: tab === QUOTES,
        },
        [TASKS]: {
          ...prevState[TASKS],
          selected: tab === TASKS,
        },
      };
    });
  };

  const getStatusList = async () => {
    const response = await request(`${protectedResources.api.apiEndpoint}/MasterData/getstatuslist`, {
      method: 'GET',
    });
    const cancelEntity = response.data.items.find(status => status.code === STATUS.CANCELLED);
    const onHoldEntity = response.data.items.find(status => status.code === STATUS.ON_HOLD);
    const offHoldEntity = response.data.items.find(status => status.code === STATUS.OFF_HOLD);
    if (cancelEntity) {
      setCancelStatusId(cancelEntity.id);
    }
    if (onHoldEntity) {
      setOnHoldStatusId(onHoldEntity.id);
    }
    if (offHoldEntity) {
      setoffHoldStatusId(offHoldEntity.id);
    }
  };

  useEffect(() => {
    getStatusList();
  }, []);

  const handleDelete = async () => {
    const id = deleteId;
    try {
      await request(`${protectedResources.api.apiEndpoint}/Products/${id}`, {
        method: 'DELETE',
      });
      dispatch(actions.loadRfqData({ jobId: jobInformation.jobId, toggleAction: toggleAction }));
      dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobInformation.jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setJobData.type, module: MODULES.MY_JOBS }));
    } catch (responseError) {}
  };

  const handleOnHold = async printSpecifications => {
    const formData = {
      templateId: printSpecifications.templateId,
      status: onHoldStatusId && onHoldStatusId,
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Products/ProductLineItemStatus`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      dispatch(actions.loadRfqData({ jobId: jobInformation.jobId, toggleAction: toggleAction }));
      dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobInformation.jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setJobData.type, module: MODULES.MY_JOBS }));
    } catch (err) {}
  };
  const handleOffHold = async printSpecifications => {
    const formData = {
      templateId: printSpecifications.templateId,
      status: offHoldStatusId && offHoldStatusId,
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Products/ProductLineItemStatus`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      dispatch(actions.loadRfqData({ jobId: jobInformation.jobId, toggleAction: toggleAction }));
      dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobInformation.jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setJobData.type, module: MODULES.MY_JOBS }));
    } catch (err) {}
  };

  const handleCopy = async printSpecifications => {
    const { templateId } = printSpecifications;
    const requestURL = `${protectedResources.api.apiEndpoint}/Products/CopyProductTemplate/${templateId}`;
    try {
      const result: productTemplate = await request(requestURL);
      if (result) {
        dispatch(actions.addClonedProductItemDetails(result));
        dispatch(actions.loadRfqData({ jobId: jobInformation.jobId, toggleAction: toggleAction }));
        dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobInformation.jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setJobData.type, module: MODULES.MY_JOBS }));
      }
    } catch (responseError) {
      console.log('Error while cloning product item', responseError);
    }
  };

  const handleCancel = async printSpecifications => {
    const id = cancelId;
    const formData = {
      templateId: id,
      status: cancelStatusId && cancelStatusId,
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Products/ProductLineItemStatus`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      dispatch(actions.loadRfqData({ jobId: jobInformation.jobId, toggleAction: toggleAction }));
      dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobInformation.jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setJobData.type, module: MODULES.MY_JOBS }));
      dispatch(actions.UpdateStatusToCancelled());
    } catch (err) {}
    setShowCancelModal(false);
  };

  const handleInActive = async printSpecifications => {
    const formData = {
      templateId: printSpecifications.templateId,
      active: printSpecifications?.isActive === 'Yes' ? false : true,
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/Products/SetActiveOrInActive`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      dispatch(actions.loadRfqData({ jobId: jobInformation.jobId, toggleAction: toggleAction }));
      dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobInformation.jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setJobData.type, module: MODULES.MY_JOBS }));
    } catch (err) {}
  };

  const handleAddPONumber = () => {
    dispatch(setIsAddPoAction({ module: MODULES.IS_ADD_PO_ACTION, isAddPoAction: true }));
    // dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobInformation.jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setJobData.type, module: MODULES.MY_JOBS }));
  };

  return (
    <>
      <Modal backdrop="static" keyboard={false} show={showModelDelete} aria-labelledby="contained-modal-title-vcenter" backdropClassName="fullScreen" centered onHide={() => setShowModelDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span>Are you sure you want to delete this line?</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="modal_footer">
          <>
            <Button variant="outline-primary" onClick={() => setShowModelDelete(false)} className="outline__button">
              No
            </Button>
            <Button variant="outline-primary" onClick={handleDelete} className="full__button">
              Yes
            </Button>
          </>
        </Modal.Footer>
      </Modal>
      <Modal backdrop="static" keyboard={false} show={showCancelModal} aria-labelledby="contained-modal-title-vcenter" backdropClassName="fullScreen" centered onHide={() => setShowCancelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span>Are you sure you want to cancel this line?</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="modal_footer">
          <>
            <Button variant="outline-primary" onClick={() => setShowCancelModal(false)} className="outline__button">
              No
            </Button>
            <Button variant="outline-primary" onClick={handleCancel} className="full__button">
              Yes
            </Button>
          </>
        </Modal.Footer>
      </Modal>
      {showAddDiscountModal ? (
        <AddDiscount show={showAddDiscountModal} onHide={() => setShowAddDiscountModal(false)} printSpecifications={printSpecifications} clientQuotations={clientQuotations} />
      ) : (
        <></>
      )}
      <TableRow
        className={`productItem__details__row ${productItem.open && 'open'}`}
        hover={true}
        id="table-row"
        onClick={event => onOpen(event)}
        selected={productItem.checked}
        sx={{
          '& > *': { borderBottom: 'unset' },
        }}
      >
        <TableCell className="productItem__details__bodyCell" style={{ paddingLeft: '1rem' }}>
          <IconButton aria-label="expand row" size="small">
            {productItem.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
          <Checkbox
            id="product-row-id"
            checked={productItem.checked}
            name="checked"
            onChange={e => onSelect(e)}
            sx={{
              color: '#8D8C8D',
              '&.Mui-checked': {
                color: '#1BA39C',
              },
            }}
          />
        </TableCell>
        <TableCell
          className="productItem__details__bodyCell"
          sx={{
            paddingLeft: 0,
          }}
        >
          {printSpecifications.title}
        </TableCell>
        <TableCell className="productItem__details__bodyCell">{printSpecifications.productType}</TableCell>
        <TableCell className="productItem__details__bodyCell">{printSpecifications.added ? moment(printSpecifications.added).format('DD/MM/YYYY') : '-'}</TableCell>
        <TableCell className="productItem__details__bodyCell">{printSpecifications.quoted ? moment(printSpecifications.quoted).format('DD/MM/YYYY') : '-'}</TableCell>
        <TableCell className="productItem__details__bodyCell">{printSpecifications.quantityActual ? printSpecifications.quantityActual : printSpecifications.quantity}</TableCell>
        <TableCell className="productItem__details__bodyCell text-end pe-2">{printSpecifications.value != null ? printSpecifications.value?.toFixed(2) : '-'}</TableCell>
        <TableCell className="productItem__details__bodyCell">{printSpecifications.status.name}</TableCell>
        <TableCell className="productItem__details__bodyCell" align="center">
          <Dropdown show={showActionDropDown[productItem.printSpecifications.templateId]}>
            <Dropdown.Toggle
              disabled={jobInformation?.statusName === 'Cancelled' || [STATUS.OFF_HOLD, STATUS.READY_TO_INVOICE, STATUS.INVOICED].includes(printSpecifications.status.code)}
              onClick={() => actionDropDownClick()}
              aria-haspopup="true"
              id="dropdown-actions"
              style={{
                backgroundColor: 'white',
                border: 'none',
                color: 'gray',
              }}
            >
              <strong>Actions</strong>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {productItems?.filter(item => [STATUS.DELIVERED, STATUS.INVOICED, STATUS.READY_TO_INVOICE, null].includes(item?.printSpecifications?.status?.code)).length === 0 &&
                [STATUS.DRAFT, STATUS.NEW_RFQ, STATUS.QUOTATION, STATUS.ACCEPTED, STATUS.ORDERED, STATUS.REJECTED, STATUS.CANCELLED, STATUS.ON_HOLD].includes(printSpecifications.status.code) && (
                  <Dropdown.Item onClick={() => handleCopy(printSpecifications)}>Copy</Dropdown.Item>
                )}
              {[STATUS.NEW_RFQ, STATUS.QUOTATION, STATUS.ORDERED, STATUS.REJECTED, STATUS.ACCEPTED].includes(printSpecifications.status.code) && (
                <Dropdown.Item
                  onClick={() => {
                    setCancelId(printSpecifications?.templateId);
                    setShowCancelModal(true);
                  }}
                >
                  Cancel
                </Dropdown.Item>
              )}
              {printSpecifications.status.code === STATUS.DRAFT && (
                <Dropdown.Item
                  onClick={() => {
                    setDeleteId(printSpecifications?.templateId);
                    setShowModelDelete(true);
                  }}
                >
                  Delete
                </Dropdown.Item>
              )}
              {[STATUS.NEW_RFQ, STATUS.QUOTATION, STATUS.ACCEPTED, STATUS.REJECTED, STATUS.ORDERED].includes(printSpecifications.status.code) && (
                <Dropdown.Item onClick={() => handleInActive(printSpecifications)}>{printSpecifications?.isActive === 'Yes' ? 'Inactive' : 'Active'}</Dropdown.Item>
              )}
              {printSpecifications?.isActive === 'Yes' && [STATUS.NEW_RFQ, STATUS.QUOTATION, STATUS.ACCEPTED, STATUS.ORDERED].includes(printSpecifications.status.code) && (
                <Dropdown.Item onClick={() => handleOnHold(printSpecifications)}>On hold</Dropdown.Item>
              )}
              {[STATUS.ON_HOLD].includes(printSpecifications.status.code) && <Dropdown.Item onClick={() => handleOffHold(printSpecifications)}>Off hold</Dropdown.Item>}
              {printSpecifications?.noPo && [STATUS.ACCEPTED, STATUS.ORDERED, STATUS.DELIVERED, STATUS.ON_HOLD].includes(printSpecifications.status.code) && (
                <Dropdown.Item onClick={() => handleAddPONumber()}>Add PO number</Dropdown.Item>
              )}
              {(printSpecifications?.supplierPo != null || [STATUS.DELIVERED].includes(printSpecifications.status.code)) && (
                <Dropdown.Item
                  onClick={() => {
                    setShowAddDiscountModal(true);
                  }}
                >
                  Add Discount
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </TableCell>
      </TableRow>
      {productItem.open && (
        <TableRow>
          <TableCell className="productItem__details__subContainer productItem__details__bodyCell" colSpan={12}>
            <Collapse in={productItem.open} timeout="auto" unmountOnExit>
              <Box>
                <div className="productDetails__separator"></div>
                <div style={{ margin: '0rem 0.5rem' }}>
                  <div className="inPage__navigation">
                    {tabs[PRINT_SPECIFICATIONS].visible && (
                      <button className={`${tabs[PRINT_SPECIFICATIONS].selected && 'active'}`} onClick={() => switchTab(PRINT_SPECIFICATIONS)}>
                        Print Specifications
                      </button>
                    )}
                    {isCDS && tabs[QUOTES].visible && (
                      <button
                        className={`
                          ${tabs.quotes.selected && 'active'}
                          ${tabs.quotes.disabled && 'disabledBtn'}
                        `}
                        onClick={() => !tabs[QUOTES].disabled && switchTab(QUOTES)}
                      >
                        Quotes
                      </button>
                    )}
                    <button className={`${tabs[TASKS].selected && 'active'} ${tabs[TASKS].disabled && 'disabledBtn'}`} onClick={() => !tabs.tasks.disabled && switchTab(TASKS)}>
                      Tasks
                    </button>
                  </div>
                  <div className="inPage__navigation__line"></div>
                  <div style={{ margin: '1rem' }}>
                    {tabs.printSpecifications.selected && <ProductItemDetails clientQuotations={clientQuotations} printSpecifications={printSpecifications} />}
                    {tabs.quotes.selected && (
                      <Quotes
                        supplierPo={printSpecifications.supplierPo}
                        productid={printSpecifications.productId}
                        productType={printSpecifications.productType}
                        rowid={printSpecifications.templateId}
                        title={printSpecifications.title}
                        status={printSpecifications?.status?.name}
                        supplierNotes={printSpecifications.supplierNotes}
                        isActive={printSpecifications?.isActive}
                      />
                    )}
                    {printSpecifications.statusName !== DRAFT && tabs.tasks.selected && userInformation.isCDS && (
                      <Tasks productid={printSpecifications.templateId} isProductId={true} isActive={printSpecifications?.isActive} />
                    )}
                  </div>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export const ProductItemsList = () => {
  const productItems: ProductItemType[] = useSelector(getProductItems(MODULES.MY_JOBS));

  const toggleState = useSelector(getActionToggle(MODULES.MY_JOBS));
  let setState: string = toggleState ? 'Yes' : 'No';
  // const checkToggle = productItems?.filter(item => item.printSpecifications.isActive).includes(setState);
  const dispatch = useDispatch();

  const [checkedAll, setCheckedAll] = useState(false);
  const [openAll, setOpenAll] = useState(false);
  const [showActionDropDown, setShowActionDropDown] = useState({});

  /******************************************************************************************
  @Purpose    :   To expand all rows
  *******************************************************************************************/
  const handleOpenAll = () => {
    const openedItems = productItems.map(item => ({ ...item, open: !openAll }));
    dispatch(setProductItems({ module: MODULES.MY_JOBS, productItems: openedItems }));
    setOpenAll(!openAll);
  };

  /******************************************************************************************
  @Purpose    :   To expand a row
  *******************************************************************************************/
  const handleOpen = useCallback(
    (event, index) => {
      if (event.target.name === 'checked' || ['Actions', 'Copy', 'Cancel', 'Hold', 'Delivered', 'Add Discount'].includes(event.target.innerText)) {
        event.stopPropagation();
      } else {
        const openedItems = productItems.map((productItem, i) => {
          const entity = { ...productItem };
          if (index === i) {
            entity.open = !productItem.open;
            if (openAll && productItem.open) setOpenAll(!productItem.open);
          }
          return entity;
        });
        //  Check all are selected or not
        if (openedItems.length === openedItems.filter(item => item.open).length) setOpenAll(true);
        dispatch(setProductItems({ module: MODULES.MY_JOBS, productItems: openedItems }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, productItems],
  );

  /******************************************************************************************
  @Purpose    :   To select all rows
  *******************************************************************************************/
  const handleSelectAll = () => {
    const checkedItems = productItems.map(item => ({ ...item, checked: !checkedAll }));
    dispatch(setProductItems({ module: MODULES.MY_JOBS, productItems: checkedItems }));
    setCheckedAll(!checkedAll);
  };

  /******************************************************************************************
  @Purpose    :   To select single row
  *******************************************************************************************/
  const handleSelect = useCallback(
    (event, index) => {
      if (event.target.name !== 'checked') {
        event.stopPropagation();
      } else {
        const checkedItems = productItems.map((productItem, i) => {
          const entity = { ...productItem };
          if (index === i) {
            entity.checked = !productItem.checked;
            if (checkedAll && productItem.checked) setCheckedAll(!productItem.checked);
          }
          return entity;
        });
        //  Check all are selected or not
        if (checkedItems.length === checkedItems.filter(item => item.checked).length) setCheckedAll(true);
        dispatch(setProductItems({ module: MODULES.MY_JOBS, productItems: checkedItems }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, productItems],
  );

  const removeActionDowdown = () => {
    var x: any = '';
    if (!isEmpty(showActionDropDown)) {
      x = Object.keys(showActionDropDown);
      setShowActionDropDown({ ...showActionDropDown, [Number(x)]: false });
    }
  };

  useEffect(() => {
    document.addEventListener('click', removeActionDowdown);

    return () => {
      document.removeEventListener('click', removeActionDowdown);
    };
  });

  return (
    <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none', minHeight: 'calc(100vh - 100px)', background: 'transparent' }}>
      <Table aria-label="collapsible table" className="productItem__details__table">
        <TableHead>
          <TableRow style={{ height: '3rem' }}>
            <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '1rem', width: '6.5rem' }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  handleOpenAll();
                }}
              >
                {openAll ? <KeyboardArrowUpIcon /> : productItems.filter(productItem => productItem.open).length !== 0 ? <RemoveIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
              <Checkbox
                sx={{
                  color: '#8D8C8D',
                  '&.Mui-checked': {
                    color: '#1BA39C',
                  },
                }}
                indeterminate={checkedAll ? false : productItems.filter(productItem => productItem.checked).length !== 0}
                indeterminateIcon={<IndeterminateCheckBoxIcon sx={{ color: '#1BA39C' }} />}
                checked={checkedAll}
                onChange={() => {
                  handleSelectAll();
                }}
              />
            </TableCell>
            <TableCell className="productItem__details__headerCell" sx={{ paddingLeft: 0 }} align="left">
              Title
            </TableCell>
            <TableCell className="productItem__details__headerCell">Product Type</TableCell>
            <TableCell className="productItem__details__headerCell">Submitted</TableCell>
            <TableCell className="productItem__details__headerCell">Quoted</TableCell>
            <TableCell className="productItem__details__headerCell">Quantity</TableCell>
            <TableCell className="productItem__details__headerCell text-end pe-2">Value (&#163;)</TableCell>
            <TableCell className="productItem__details__headerCell">Status</TableCell>
            <TableCell className="productItem__details__headerCell" align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productItems.map((productItem, i) => (
            <Fragment key={productItem.printSpecifications.templateId}>
              <TableRow sx={{ height: '4px' }}></TableRow>
              <RowDetails
                onOpen={(e: any) => {
                  handleOpen(e, i);
                }}
                onSelect={(e: any) => {
                  handleSelect(e, i);
                }}
                productItem={productItem}
                productItems={productItems}
                showActionDropDown={showActionDropDown}
                actionDropDownClick={() => setShowActionDropDown({ [productItem.printSpecifications.templateId]: true })}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
