import React, { useState, useEffect } from 'react';

import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';
import { getIsClient } from 'app/components/modules/sideBar/slice/selectors';
import { protectedResources } from '../../../configs/msalAuthConfigs';
import TableContainer from '@mui/material/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestForQuoteSlice } from './slice';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { Button, Modal } from 'react-bootstrap';
import TableRow from '@mui/material/TableRow';
import { Dropdown } from 'react-bootstrap';
import { TableCell } from '@mui/material';
import { Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { request } from 'utils/request';

export const OtherCostsList = props => {
  const { getInvoicedId, getEditOtherCosts, isCancelled } = props;
  const rows: any[] = props.otherJobCostTableList;
  const dispatch = useDispatch();
  const { actions } = useRequestForQuoteSlice();
  const [showModelDelete, setShowModelDelete] = useState<boolean>(false);
  const [editOtherCosts, setEditOtherCosts] = useState<any>();
  const [costId, setCostsId] = useState<any>([]);
  const [deleteId, setDeleteId] = useState<any>();
  const isClient = useSelector(getIsClient);
  const handleCheck = id => {
    const updatedIds = [...costId];
    const index = updatedIds.indexOf(id);
    if (index >= 0) {
      updatedIds.splice(index, 1);
    } else {
      updatedIds.push(id);
    }
    setCostsId([...updatedIds]);
  };

  const handleDelete = async () => {
    const otherJobCostId = deleteId;
    try {
      await request(`${protectedResources.api.apiEndpoint}/RFQ/DeleteOtherJobCosts/${otherJobCostId}`, {
        method: 'DELETE',
      });
    } catch (responseError) {}
    setShowModelDelete(false);
    dispatch(actions.deleteOtherCostRow(otherJobCostId));
  };

  useEffect(() => {
    getInvoicedId(costId);
    editOtherCosts && getEditOtherCosts(editOtherCosts);
  }, [costId, editOtherCosts, getEditOtherCosts, getInvoicedId]);

  return (
    <>
      <div className="productsPage__header">{props.children}</div>
      <TableContainer component={Paper} className="tabular" sx={{ borderRadius: '0', boxShadow: 'none', minHeight: 'calc(100vh - 100px)', background: 'transparent' }}>
        <Table aria-label="collapsible table" className="productItem__details__table tabular">
          <TableHead>
            <TableRow>
              <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '1rem', width: '6.5rem' }}>
                <Checkbox
                  sx={{
                    color: '#8D8C8D',
                    '&.Mui-checked': {
                      color: '#1BA39C',
                    },
                  }}
                  indeterminateIcon={<IndeterminateCheckBox sx={{ color: '#1BA39C' }} />}
                />
              </TableCell>
              <TableCell className="productItem__details__headerCell" sx={{ paddingLeft: 0 }} align="left">
                Type
              </TableCell>
              <TableCell className="productItem__details__headerCell">Name</TableCell>
              <TableCell className="productItem__details__headerCell">Rate</TableCell>
              <TableCell className="productItem__details__headerCell">Hours</TableCell>
              <TableCell className="productItem__details__headerCell">Net</TableCell>
              <TableCell className="productItem__details__headerCell">Vat Rate</TableCell>
              <TableCell className="productItem__details__headerCell">Vat Amount</TableCell>
              <TableCell className="productItem__details__headerCell">Value Gross</TableCell>
              <TableCell className="productItem__details__headerCell"> Status</TableCell>
              {!isClient && (
                <TableCell className="productItem__details__headerCell" align="center">
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <>
                <>
                  <Modal
                    backdrop="static"
                    keyboard={false}
                    show={showModelDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdropClassName="fullScreen"
                    centered
                    onHide={() => setShowModelDelete(false)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                        <span>Are you sure you want to Delete this Cost?</span>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className="modal_footer">
                      <>
                        <Button variant="outline-primary" onClick={() => setShowModelDelete(false)} className="outline__button">
                          No
                        </Button>
                        <Button variant="outline-primary" onClick={() => handleDelete()} className="full__button">
                          Yes
                        </Button>
                      </>
                    </Modal.Footer>
                  </Modal>
                </>
                <TableRow sx={{ height: '4px' }}></TableRow>
                <TableRow
                  sx={{
                    '& > *': { borderBottom: 'unset' },
                  }}
                  hover={true}
                  selected={row?.checked}
                  className={`productItem__details__row `}
                >
                  <TableCell className="productItem__details__bodyCell" style={{ paddingLeft: '1rem', width: '6.5rem' }}>
                    <Checkbox
                      sx={{
                        color: '#8D8C8D',
                        '&.Mui-checked': {
                          color: '#1BA39C',
                        },
                      }}
                      onChange={() => handleCheck(row?.id)}
                      id="supplierList-row-id"
                    />
                  </TableCell>
                  <TableCell
                    className="productItem__details__bodyCell"
                    sx={{
                      paddingLeft: 0,
                    }}
                  >
                    {row?.rateItemType}
                  </TableCell>
                  <TableCell className="productItem__details__bodyCell">{row?.name}</TableCell>
                  <TableCell className="productItem__details__bodyCell">{row?.rate?.toFixed(2)}</TableCell>
                  <TableCell className="productItem__details__bodyCell">{row?.hours?.toFixed(2)}</TableCell>
                  <TableCell className="productItem__details__bodyCell">{row?.netValue?.toFixed(2)}</TableCell>
                  <TableCell className="productItem__details__bodyCell">{row?.vatName}</TableCell>
                  <TableCell className="productItem__details__bodyCell">{row?.vatAmount?.toFixed(2)}</TableCell>
                  <TableCell className="productItem__details__bodyCell">{row?.grossValue?.toFixed(2)}</TableCell>
                  <TableCell className="productItem__details__bodyCell">{row?.status ? row?.status?.name : '-'}</TableCell>
                  {!isClient && (
                    <TableCell className="productItem__details__bodyCell" align="center">
                      <Dropdown>
                        <Dropdown.Toggle
                          aria-haspopup="true"
                          disabled={isCancelled}
                          id="dropdown-actions"
                          style={{
                            backgroundColor: 'white',
                            border: 'none',
                            color: 'gray',
                          }}
                        >
                          <strong>Actions</strong>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setDeleteId(row?.id);
                              setShowModelDelete(true);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setEditOtherCosts(row)}>Edit</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </TableCell>
                  )}
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
