import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBreadcrumbSlice } from '../breadcrumb/slice';
import Breadcrumb from '../breadcrumb';
import { AllAccounts } from './AllAccounts';
import { getAccountData } from './slice/selector';
import { useInvoiceSlice } from './slice/index';
import './style.sass';

const Account = () => {
  const dispatch = useDispatch();
  const accountRow = useSelector(getAccountData);
  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  const { actions: accountAction } = useInvoiceSlice();
  const [allAccounts, setAllAccounts] = useState<any>([]);

  useEffect(() => {
    dispatch(accountAction.loadAccount);
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Dashboard', url: '/dashboard' },
        { name: 'Administration/Account', url: '/administration/account' },
      ]),
    );
  }, [accountAction.loadAccount, breadcrumbActions, dispatch]);

  useEffect(() => {
    setAllAccounts(accountRow.data.items);
  }, [accountRow]);

  return (
    <div className="myJobs__container">
      <Breadcrumb />
      <AllAccounts accounts={allAccounts} />
    </div>
  );
};

export default Account;
