//  Externals
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { Chip } from '@material-ui/core';
import { sortBy } from 'lodash';
//  Internals
import { getBusinessUnits, getRoles, getAccounts } from '../slice/selectors';
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { AddClientUserPropType, BusinessUnit, RoleList } from './types';
import { DropDown } from 'app/components/modules/products/types';
import PLACEHOLDER from '../../../../data/placeholder';
import { useRegistrationSlice } from '../slice';
import { request } from 'utils/request';
import schema from './validator';
import '../style.sass';

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: 'flex' }}>
          <Form.Check aria-label={props.label} checked={props.isSelected} id={props.label} onChange={() => null} />
          <label style={{ marginLeft: '0.5rem' }}>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

const AddClientUser = (props: AddClientUserPropType) => {
  const { create, onHide, show, clientUser } = props;

  const { actions } = useRegistrationSlice();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.initialRegistrationState());
  }, [actions, dispatch]);

  const [isApproverChecked, setIsApproverChecked] = useState(false);
  const [isCacheChecked, setIsCacheChecked] = useState(false);
  const [accountId, setAccountId] = useState<number | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState('');

  const roles = useSelector(getRoles);

  const [rolesList, setRolesList] = useState<RoleList[]>([]);

  useEffect(() => {
    const rolesList = roles.filter(role => role.isClient).map(role => ({ label: role.name, value: role.id, admin: role.admin }));
    setRolesList(rolesList);
  }, [roles]);

  const businessUnits = useSelector(getBusinessUnits);
  const accounts = useSelector(getAccounts);

  const [businessUnitsList, setBusinessUnitsList] = useState<BusinessUnit[]>([]);
  const [selectedBusinessUnitsList, setSelectedBusinessUnitsList] = useState<BusinessUnit[]>([]);

  const [accountsList, setAccountsList] = useState<DropDown[]>([]);

  useEffect(() => {
    //  Business Units list
    const businessUnitsList = businessUnits.map(businessUnit => ({ label: businessUnit.name, value: businessUnit.businessUnitId, accountId: businessUnit.parentAccountId }));
    setBusinessUnitsList(businessUnitsList);
    // //  Accounts list
    const accountsList = accounts.map(account => ({ label: account.name, value: account.accountId }));
    setAccountsList(accountsList);
  }, [accounts, businessUnits]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema({ isAdmin })),
  });

  useEffect(() => {
    const { accountId, approver, businessUnitsId, cache, roleId } = clientUser;
    setError('');
    reset({ ...clientUser });
    //  Account
    setAccountId(accountId);
    //  Approver
    setIsApproverChecked(approver);
    //  Cache
    setIsCacheChecked(cache);
    //  Business Unit
    setSelectedBusinessUnitsList(businessUnitsList.filter(businessUnit => businessUnitsId?.includes(businessUnit.value)));
    //  Is Admin
    const role = rolesList.find(role => role.value === roleId);
    if (role) {
      const isAdmin = role?.admin;
      setIsAdmin(isAdmin);
    }
  }, [reset, clientUser, businessUnitsList, rolesList]);

  const onError = error => {
    console.log('ERROR:::', error);
  };

  const onSubmit = async values => {
    const payload = {
      ...values,
      isSupplier: false,
      isClient: true,
      active: values.active && values.active === 'Yes' ? true : false,
    };
    if (!create) payload.userId = values.userId;
    const url = create ? 'CreateAccount' : `EditAccount/${clientUser.userId}`;
    const method = create ? 'POST' : 'PUT';
    try {
      await request(`${protectedResources.api.apiEndpoint}/AD/${url}`, {
        method: method,
        body: JSON.stringify(payload),
      }).then(res => {
        if (res.isSuccess) {
          dispatch(actions.loadClientOrSupplierUser({ isClient: true, isSupplier: false }));
          onHide();
        } else {
          setError(res.message);
        }
      });
    } catch (responseError) {
      console.log('Error while submitting the form ' + responseError);
    }
  };

  /******************************************************************************************
  @Purpose    :  Check whether the role is admin or not
  *******************************************************************************************/
  const handleRole = value => {
    const isAdmin = value && value?.admin;
    setIsAdmin(isAdmin);
    setAccountId(value);
  };

  /******************************************************************************************
  @Purpose    :  Account id for Business units
  *******************************************************************************************/
  const handleAccount = value => {
    setAccountId(value);
  };

  /******************************************************************************************
  @Purpose    :  Handle Business Units
  *******************************************************************************************/
  const handleBusinessUnits = values => {
    const sortedValues = sortBy(values, 'label');
    setSelectedBusinessUnitsList(sortedValues);
  };

  /******************************************************************************************
  @Purpose    :  Handle Remove Business Units
  *******************************************************************************************/
  const handleRemoveBusinessUnits = value => {
    const sortedBusinessUnits = sortBy(selectedBusinessUnitsList, 'label');
    const updatedBusinessUnits = sortedBusinessUnits.filter(businessUnit => businessUnit.value !== value);
    setValue(
      'businessUnitsId',
      updatedBusinessUnits.map(businessUnit => businessUnit.value),
      {
        shouldValidate: false,
      },
    );
    setSelectedBusinessUnitsList(updatedBusinessUnits);
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="fullScreen"
      centered
      size="lg"
      keyboard={false}
      onHide={() => {
        onHide();
      }}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>{create ? 'Add Client User' : 'Edit Client User'}</h4>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Body>
          <div className="row mb-3">
            <div className="col-6">
              <Form.Group controlId="form_firstName">
                <Form.Label>
                  <span>
                    First Name<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control autoComplete="off" className={errors.firstName && 'border-danger'} placeholder="Enter First Name" type="text" {...register('firstName')} />
                {errors.firstName && <Form.Text className="text-danger">{errors.firstName.message}</Form.Text>}
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group controlId="form__lastName">
                <Form.Label>
                  <span>
                    Last Name<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control autoComplete="off" className={errors.lastName && 'border-danger'} placeholder=" Enter Last Name" type="text" {...register('lastName')} />
                {errors.lastName && <Form.Text className="text-danger">{errors.lastName.message}</Form.Text>}
              </Form.Group>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              <Form.Group controlId="form_emailAddress">
                <Form.Label>
                  <span>
                    Email<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control autoComplete="off" className={errors.emailAddress && 'border-danger'} placeholder="Enter Email" type="text" {...register('emailAddress')} />
                {errors.emailAddress && <Form.Text className="text-danger">{errors.emailAddress.message}</Form.Text>}
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group controlId="form__telephoneNumber">
                <Form.Label>
                  <span>
                    Telephone<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control autoComplete="off" className={errors.telephoneNumber && 'border-danger'} placeholder="Enter Telephone Number" type="text" {...register('telephoneNumber')} />
                {errors.telephoneNumber && <Form.Text className="text-danger">{errors.telephoneNumber.message}</Form.Text>}
              </Form.Group>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              <Form.Group controlId="form__roleId">
                <Form.Label>
                  Role<span className="input__required">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="roleId"
                  render={({ field: { onBlur, onChange, ref, value } }) => {
                    return (
                      <Select
                        className={errors.roleId && 'border-danger-select'}
                        classNamePrefix="custom-select-input"
                        id="form__roleId"
                        isClearable
                        maxMenuHeight={175}
                        onBlur={onBlur} //  Notify when input is touched
                        onChange={(val, { action }) => {
                          if (action === 'clear') {
                            onChange(null);
                          } else {
                            onChange(val?.value);
                          }
                          handleRole(val);
                        }} // Send value to hook f = items.find(item => item.value = val);
                        options={rolesList}
                        placeholder={PLACEHOLDER.SELECT_ROLE}
                        ref={ref}
                        value={rolesList.find(role => role.value === value)}
                      />
                    );
                  }}
                />
                {errors.roleId && <Form.Text className="text-danger">{errors.roleId.message}</Form.Text>}
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group controlId="form__active">
                <Form.Label>
                  <span>
                    Active<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <div>
                  <Form.Check aria-label="active-yes" id="form__active-yes" inline label="Yes" type="radio" value="Yes" {...register('active')} />
                  <Form.Check aria-label="active-no" id="form__active-no" inline label="No" type="radio" value="No" {...register('active')} />
                </div>
                {errors.active && <Form.Text className="text-danger">{errors.active.message}</Form.Text>}
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Form.Group controlId="form__accountId">
                <Form.Label>
                  Account<span className="input__required">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="accountId"
                  render={({ field: { onBlur, onChange, ref, value } }) => {
                    return (
                      <Select
                        className={errors.accountId && 'border-danger-select'}
                        classNamePrefix="custom-select-input"
                        id="form__accountId"
                        isClearable
                        maxMenuHeight={175}
                        onBlur={onBlur} //  Notify when input is touched
                        onChange={(val, { action }) => {
                          if (action === 'clear') {
                            onChange(null);
                          } else {
                            onChange(val?.value);
                          }
                          handleAccount(val?.value);
                        }} // Send value to hook f = items.find(item => item.value = val);
                        options={accountsList}
                        placeholder={PLACEHOLDER.SELECT_ACCOUNT}
                        ref={ref}
                        value={accountsList.find(account => account.value === value)}
                      />
                    );
                  }}
                />
                {errors.accountId && <Form.Text className="text-danger">{errors.accountId.message}</Form.Text>}
              </Form.Group>
            </div>
            <div className="col-6 mt-4">
              <div className="d-flex" style={{ marginTop: '0.9rem' }}>
                <div>
                  <Form.Group controlId="form__cache">
                    <Controller
                      control={control}
                      name="cache"
                      render={({ field: { onBlur, onChange, ref } }) => (
                        <Form.Check
                          onBlur={onBlur}
                          onChange={e => {
                            onChange(e);
                            setIsCacheChecked(!isCacheChecked);
                          }}
                          label="Cache"
                          ref={ref}
                          type="checkbox"
                          checked={isCacheChecked}
                        />
                      )}
                    />
                    {errors.cache && <Form.Text className="text-danger">{errors.cache.message}</Form.Text>}
                  </Form.Group>
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <Form.Group controlId="form__approver">
                    <Controller
                      control={control}
                      name="approver"
                      render={({ field: { onBlur, onChange, ref } }) => (
                        <Form.Check
                          onBlur={onBlur}
                          onChange={e => {
                            onChange(e);
                            setIsApproverChecked(!isApproverChecked);
                          }}
                          label="Approver"
                          ref={ref}
                          type="checkbox"
                          checked={isApproverChecked}
                        />
                      )}
                    />
                    {errors.approver && <Form.Text className="text-danger">{errors.approver.message}</Form.Text>}
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          {!isAdmin && (
            <div className="row">
              <div className="col-6 mt-3">
                <>
                  <Form.Group controlId="form__businessUnitsId">
                    <Form.Label>
                      Business Units<span className="input__required">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="businessUnitsId"
                      render={({ field: { onBlur, onChange, ref } }) => {
                        return (
                          <Select
                            className={`mt-1 ${errors.businessUnitsId && 'border-danger-select'}`}
                            classNamePrefix="custom-select-input"
                            closeMenuOnSelect={false}
                            components={{ Option }}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            id="form__businessUnitsId"
                            isClearable
                            isMulti
                            maxMenuHeight={175}
                            onBlur={onBlur} //  Notify when input is touched
                            onChange={(val, { action }) => {
                              if (action === 'clear') {
                                onChange(null);
                              } else {
                                onChange(val.map(o => o.value));
                              }
                              handleBusinessUnits(val);
                            }} // Send value to hook f = items.find(item => item.value = val);
                            options={businessUnitsList.filter(businessUnit => businessUnit.accountId === accountId)}
                            placeholder={PLACEHOLDER.SELECT_BUSINESS_UNITS}
                            ref={ref}
                            value={selectedBusinessUnitsList}
                          />
                        );
                      }}
                    />
                    {errors.businessUnitsId && <Form.Text className="text-danger">{errors.businessUnitsId.message}</Form.Text>}
                  </Form.Group>
                  {selectedBusinessUnitsList.length !== 0 &&
                    selectedBusinessUnitsList.map(selectedBusinessUnit => (
                      <Chip
                        key={selectedBusinessUnit.value}
                        label={selectedBusinessUnit.label}
                        style={{ margin: '10px 10px 0px 0px', borderRadius: '5px', border: '1px solid #cdcccc' }}
                        clickable
                        onDelete={() => handleRemoveBusinessUnits(selectedBusinessUnit.value)}
                      />
                    ))}
                </>
              </div>
            </div>
          )}
          {error !== '' && (
            <div className="text-danger text-center w-100 pt-4">
              <strong className="fs-6">{error}</strong>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <br />
          <hr />
          <Button
            className="outline__button"
            onClick={() => {
              onHide();
              setError('');
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button className="full__button" variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddClientUser;
