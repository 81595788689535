//  Externals
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton/IconButton';
import React, { useState } from 'react';
import { Collapse } from '@mui/material';

export const ClientQuoteOtherCostsDetails = props => {
  const { productItem, checkVat } = props;
  const [open, setOpen] = useState(false);
  return (
    <div className="productItemDetail">
      <div className="titleRow">
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(prevState => !prevState)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
        {productItem && <span className="fw-bold">Other Costs : {`${productItem?.name}`}</span>}
      </div>
      <div className="contentRow">
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className="delivery__instructions">
            <h6>Name</h6>
            <div className="mt-3">
              <span>{productItem?.name ? productItem?.name : '-'}</span>
            </div>
          </div>
          <div className="delivery__instructions">
            <h6>Description</h6>
            <div className="mt-3">
              <span>{productItem?.description ? productItem?.description : '-'}</span>
            </div>
          </div>
          <div className="delivery__instructions">
            <h6>Hours</h6>
            <div className="mt-3">
              <span>{productItem?.hours ? productItem?.hours.toFixed(2) : '0.00'}</span>
            </div>
          </div>
          <div className="priceSummary">
            <h6>PRICE SUMMARY</h6>
            <div className="priceSummary__container">
              <div style={{ textAlign: 'right' }}>
                <span>Net (&#163;)</span>
                <p className="fw-bold font-14 text-right">{productItem?.netValue ? productItem?.netValue?.toFixed(2) : '0.00'}</p>
              </div>
              {checkVat && (
                <div style={{ textAlign: 'right' }}>
                  <span>VAT (&#163;)</span>
                  <p className="fw-bold font-14 text-right">{productItem?.vatAmount ? productItem?.vatAmount?.toFixed(2) : '0.00'}</p>
                </div>
              )}
              <div style={{ textAlign: 'right' }}>
                <span>Total (&#163;)</span>
                <p className="fw-bold font-14 text-right">{productItem?.grossValue ? productItem?.grossValue?.toFixed(2) : '0.00'}</p>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};
