//  Externals
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
//  Internal
import { ReactComponent as AddProductItemIcon } from 'images/addProductItem.svg';
import { initialSupplierUser, SupplierUserType } from './types';
import { useBreadcrumbSlice } from '../../breadcrumb/slice';
import { getSupplierUsers } from '../slice/selectors';
import { useRegistrationSlice } from '../slice';
import AddSupplierUser from './AddSupplierUser';
import Breadcrumb from '../../breadcrumb';

const SupplierUsers = () => {
  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  const { actions } = useRegistrationSlice();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.loadClientOrSupplierUser({ isClient: false, isSupplier: true }));
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Dashboard', url: '/dashboard' },
        { name: 'Administration/Supplier User', url: '/administration/supplieruser' },
      ]),
    );
  }, [actions, breadcrumbActions, dispatch]);

  const supplierUsers = useSelector(getSupplierUsers);

  const [supplierUser, setSupplierUser] = useState<SupplierUserType>(initialSupplierUser);
  const [showModal, setShowModal] = useState(false);
  const [create, setCreate] = useState(false);

  /******************************************************************************************
  @Purpose    :  Add Supplier User
  *******************************************************************************************/
  const handleAdd = () => {
    setShowModal(true);
    setCreate(true);
    setSupplierUser(initialSupplierUser);
  };

  /******************************************************************************************
  @Purpose    :  Update Supplier User
  *******************************************************************************************/
  const handleUpdate = entity => {
    setShowModal(true);
    setCreate(false);
    setSupplierUser({ ...entity, active: entity.active ? 'Yes' : 'No' });
  };

  return (
    <div>
      <Helmet>
        <title>Administration Supplier User</title>
      </Helmet>
      <Breadcrumb />
      <div className="rfq__container">
        <div className="products__page">
          <div className="productsPage__header">
            <h4>Supplier Users ({supplierUsers.length})</h4>
            <span className="addAccount__button" onClick={() => handleAdd()}>
              Add Supplier User
              <AddProductItemIcon className="svg__icon" />
            </span>
          </div>
          <div className="products__content">
            <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
              <Table aria-label="collapsible table" className="productItem__details__table">
                <TableHead>
                  <TableRow style={{ height: '3rem' }}>
                    <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '2rem' }} sx={{ paddingLeft: 0 }} align="left">
                      Supplier Name
                    </TableCell>
                    <TableCell className="productItem__details__headerCell">Name</TableCell>
                    <TableCell className="productItem__details__headerCell">Email</TableCell>
                    <TableCell className="productItem__details__headerCell">Telephone</TableCell>
                    <TableCell className="productItem__details__headerCell">Last login</TableCell>
                    <TableCell className="productItem__details__headerCell">Status</TableCell>
                    <TableCell className="productItem__details__headerCell">Action</TableCell>
                  </TableRow>
                </TableHead>
                {supplierUsers &&
                  supplierUsers?.map(supplierUser => (
                    <Fragment key={supplierUser.userId}>
                      <TableRow sx={{ height: '4px' }}></TableRow>
                      <TableRow
                        className="productItem__details__row"
                        onClick={() => {
                          handleUpdate(supplierUser);
                        }}
                        style={{ cursor: 'pointer', height: '2.5rem' }}
                      >
                        <TableCell className="productItem__details__bodyCell" style={{ paddingLeft: '25px' }} sx={{ paddingLeft: 0 }} align="left">
                          {supplierUser.supplierName}
                        </TableCell>
                        <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                          {supplierUser?.firstName} {supplierUser?.lastName}
                        </TableCell>
                        <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                          {supplierUser.emailAddress}
                        </TableCell>
                        <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                          {supplierUser.telephoneNumber}
                        </TableCell>
                        <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                          {supplierUser?.lastLogin != null ? `${moment(supplierUser?.lastLogin).format('HH:mm DD/MM/YYYY')}` : ''}
                        </TableCell>
                        <TableCell className="productItem__details__bodyCell" sx={{ paddingLeft: 0 }}>
                          {supplierUser.active ? 'Active' : 'Inactive'}
                        </TableCell>
                        <TableCell className="productItem__details__bodyCell table__edit" sx={{ paddingLeft: 0 }}>
                          <span style={{ color: '#1BA39C', marginRight: '20px' }}>Edit</span>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      {showModal && (
        <AddSupplierUser
          create={create}
          onHide={() => {
            setCreate(false);
            setShowModal(false);
            setSupplierUser(initialSupplierUser);
          }}
          show={showModal}
          supplierUser={supplierUser}
        />
      )}
    </div>
  );
};

export default SupplierUsers;
