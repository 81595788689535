import React, { useState, useEffect } from 'react';
import { protectedResources } from 'configs/msalAuthConfigs';
import { Modal, Button, Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { request } from 'utils/request';
import { useMyProfileSlice } from './../slice';
import { getUserId } from '../../../components/modules/sideBar/slice/selectors';
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success.svg';
import { CONFIRMATION } from 'app/components/shared/Constants';
import _without from 'lodash/without';
import * as yup from 'yup';

type SubmitModal = {
  show: boolean;
  type: string;
  header: string;
  bodyTitle: string;
  bodyContent: [];
  showFooterButtons: boolean;
};

export const MyProfile = props => {
  const { userData } = props;
  const { actions: myProfileAction } = useMyProfileSlice();
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const validationSchema = yup.object({
    firstName: yup.string().nullable().required('Please enter First name'),
    lastName: yup.string().nullable().required('Please provide a surname'),
    telephoneNumber: yup.string().nullable().required('Please provide a telephone number'),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const initialSubmitModal: SubmitModal = {
    show: false,
    type: CONFIRMATION,
    header: 'Confirmation',
    bodyTitle: 'Profile details updated successfully.',
    bodyContent: [],
    showFooterButtons: false,
  };
  const [submitModal, setSubmitModal] = useState(initialSubmitModal);
  const hideSubmitPopUpHandler = () => {
    setSubmitModal({ ...initialSubmitModal, show: false });
  };

  useEffect(() => {
    reset({ ...userData?.data });
  }, [userData, reset]);

  useEffect(() => {
    dispatch(myProfileAction.loadMyProfile({ userId: userId }));
  }, []);
  const onSubmit = async data => {
    const formData = {
      userId: userData?.data?.userId,
      firstName: data?.firstName ? data?.firstName : null,
      lastName: data?.lastName ? data?.lastName : null,
      telephoneNumber: data?.telephoneNumber ? data?.telephoneNumber : null,
    };

    try {
      await request(`${protectedResources.api.apiEndpoint}/User/updateProfile`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
    } catch (err) {}
    setSubmitModal({ ...initialSubmitModal, show: true, bodyTitle: 'Profile details updated successfully.' });
  };

  return (
    <div className="account__container">
      <>
        <Modal
          backdrop="static"
          centered
          keyboard={false}
          onHide={() => {
            hideSubmitPopUpHandler();
          }}
          show={submitModal.show}
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title>{submitModal.header}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <SuccessIcon />
                <span>{submitModal.bodyTitle}</span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="full__button"
              onClick={() => {
                hideSubmitPopUpHandler();
              }}
              variant="primary"
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <h4>User Profile</h4>
          <br />
          <div className="container">
            <Form.Group style={{ width: '500px' }}>
              <Form.Label>
                <span>
                  First name<span className="input__required">*</span>
                </span>
              </Form.Label>
              <Form.Control autoComplete="off" type="text" {...register('firstName')} />
              {errors.firstName && <Form.Text className="text-danger">{errors.firstName.message}</Form.Text>}
            </Form.Group>
            <br />
            <Form.Group style={{ width: '500px' }}>
              <Form.Label>
                <span>
                  Surname<span className="input__required">*</span>
                </span>
              </Form.Label>
              <Form.Control autoComplete="off" type="text" {...register('lastName')} />
              {errors.lastName && <Form.Text className="text-danger">{errors.lastName.message}</Form.Text>}
            </Form.Group>
            <br />
            <Form.Group style={{ width: '500px' }}>
              <Form.Label>
                <span>
                  Telephone number<span className="input__required">*</span>
                </span>
              </Form.Label>
              <Form.Control autoComplete="off" type="text" {...register('telephoneNumber')} />
              {errors.telephoneNumber && <Form.Text className="text-danger">{errors.telephoneNumber.message}</Form.Text>}
            </Form.Group>
            <br />
          </div>
        </Modal.Body>
        <hr style={{ margin: '0px' }} />
        <Modal.Footer>
          <Button className="full__button" type="submit" variant="outline-primary">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};
