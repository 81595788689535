const placeholders = {
  DESCRIPTION: 'Enter Description',
  TITLE: 'Enter Title',
  LINE_TITLE: 'Enter Line Title',
  REFERENCE: 'Enter Reference',
  RELATED_LINE_NUMBER: 'Enter Related Line Number',
  RELATED_LINE_VALUE: 'Enter Related Line Value',
  RELATED_LINE_STATUS: 'Enter Related Line Status',
  QUANTITY: 'Enter Quantity',
  QUANTITY_RUN_ON: 'Enter Quantity Run-On',
  QUOTE_DUE_DATE_AND_TIME: 'Quote Due Date and Time',
  NUMBER_OF_PAGES: 'Enter Number Of Pages',
  SELECT_FINISHED_SIZE: 'Select Finished Size',
  FINISHED_SIZE_OTHER: 'Enter Finished Size',
  SELECT_COLOURS_ON_FACE: 'Select Colours On Face',
  COLOURS_ON_FACE_OTHER: 'Enter Colours On Face',
  SELECT_COLOURS_ON_REVERS: 'Select Colours On Reverse',
  COLOURS_ON_REVERSE_OTHER: 'Enter Colours On Reverse',
  SELECT_MATERIAL: 'Select Material',
  MATERIAL_OTHER: 'Enter Material',
  SELECT_MATERIAL_WEIGHT: 'Select Material Weight',
  MATERIAL_WEIGHT_OTHER: 'Enter Material Weight',
  SELECT_FINISHING: 'Select Finishing',
  FINISHING_OTHER: 'Enter Finishing',
  ADDENDUM: 'Enter Addendum',
  DELIVERY_CONTACT: 'Enter Delivery Contact',
  DELIVERY_ADDRESS: 'Enter Delivery Address',
  DELIVERY_INSTRUCTIONS: 'Enter Delivery Instructions',
  SELECT_DELIVERY_DUE_DATE: 'Select Delivery Due Date',
  NUMBER_OF_SAMPLE_COPIES: 'Enter Number Of Sample Copies',
  SAMPLE_COPIES_DELIVERY_ADDRESS: 'Enter Sample Copies Delivery Address',
  SELECT_ARTWORK_FORMAT: 'Select Artwork Format',
  ARTWORK_FORMAT_OTHER: 'Enter Artwork Format Other',
  SELECT_ARTWORK_DUE_DATE: 'Select Artwork Due Date',
  SELECT_TYPE_OF_PROOFS: 'Select Type Of Proofs',
  TYPE_OF_PROOFS_OTHER: 'Enter Type Of Proofs',
  SELECT_PROOF_OF_DUE_DATE: 'Select Proof Due Date',
  SELECT_PRIMARY_CATEGORY: 'Select Primary Category',
  SELECT_SECONDARY_CATEGORY: 'Select Secondary Category',
  SELECT_TARGET_DATE: 'Select Target Date',
  CDS_NOTES: 'Enter CDS Notes',
  SUPPLIER_NOTES: 'Enter Supplier Notes',
  CLIENT_NOTES: 'Enter Client Notes',
  PAGES_FOR_TEXT: 'Enter Pages For Text',
  SELECT_COLOURS_ON_TEXT: 'Select Colours on Text',
  COLOURS_ON_TEXT_OTHER: 'Enter Colours On Text',
  PAGES_FOR_COVER: 'Enter Pages For Cover',
  SELECT_COLOURS_ON_INSIDE: 'Select Colours on Inside',
  COLOURS_ON_INSIDE_OTHER: 'Enter Colours On Inside',
  SELECT_COLOURS_ON_OUTSIDE: 'Select Colours on Outside',
  COLOURS_ON_OUTSIDE_OTHER: 'Enter Colours On Outside',
  SELECT_COVER_MATERIAL: 'Select Cover Material',
  COVER_MATERIAL_OTHER: 'Enter Cover Material',
  SELECT_COVER_MATERIAL_WEIGHT: 'Select Cover Material Weight',
  COVER_MATERIAL_WEIGHT_OTHER: 'Enter Cover Material Weight',
  STATUS: 'Enter Status',
  CLIENT_REFERENCE: 'Enter Client Reference',
  SELECT_ACCOUNT: 'Select Account',
  SELECT_BUSINESS_UNIT: 'Select Business Unit',
  SELECT_BUSINESS_UNITS: 'Select Business Units',
  SELECT_CONTACT_NAME: 'Select Contact Name',
  CONTACT_EMAIL: 'Enter Contact Email',
  NAME: 'Enter Name',
  EMAIL: 'Enter Email',
  INVOICE_ADDRESS: 'Enter Invoice Address',
  MONTH: 'Month',
  YEAR: 'Year',
  SELECT_QUANTITY_ACTUAL: 'Select Quantity Actual',
  QUANTITY_ACTUAL: 'Enter Quantity Actual',
  COLOURS_OTHER: 'Enter Colours',
  NOTES: 'Notes',
  MANAGEMENTFEE: 'Enter Management Fee',
  SELECT_DELIVERY_ADDRESS_REASON_CHANGE: 'Select',
  DELIVERY_ADDRESS_REASON_CHANGE_OTHER: 'Enter Delivery Address Change Reason',
  SELECT_ROLE: 'Select Role',
  SELECT_GEOGRAPHIC_LOCATION: 'Select Geographic Location',
  SELECT_VATRATE: 'Select Vat Rate',
};

export default placeholders;
