import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { dashboardSaga } from './saga';
import { dashboardCard, DashboardState } from './types';

export const initialState: DashboardState = {
  isLoading: false,
  error: null,
  dashboardCards: {
    isSuccess: true,
    message: '',
    statusCode: 0,
    validationErrors: [],
    data: {
      spends: [],
      openJobByStatus: [],
      graphWidgets: [],
    },
  },
  selectFilterList: [],
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    loadDashboard(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    dashboardLoaded(state, action: PayloadAction<dashboardCard>) {
      state.dashboardCards.data = action.payload.data;
      state.isLoading = false;
    },
    dashboardError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },
    loadSelectFilterList(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    selectFilterListLoaded(state, action: PayloadAction<any>) {
      const payloadName = action.payload?.name;
      const payloadList = action.payload?.list?.data?.items;

      if (payloadName === 'internalCds') {
        state.selectFilterList = payloadList;
      }
      if (payloadName === 'customerDashboard') {
        state.selectFilterList = payloadList?.map(item => ({
          id: item.accountId,
          name: item.name,
        }));
      }
      if (payloadName === 'supplierDashboard') {
        state.selectFilterList = payloadList?.map(item => ({
          id: item.supplierId,
          name: item.supplierName,
        }));
      }
      state.isLoading = false;
    },
  },
});

export const { actions: dashboardActions, reducer } = slice;

export const useDashboardSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: dashboardSaga });
  return { actions: slice.actions };
};
