const status = {
  DRAFT: 'Draft',
  NEW_RFQ: 'NewRFQ',
  QUOTATION: 'Quotation',
  ACCEPTED: 'Accepted',
  ORDERED: 'Ordered',

  AMENDED_RFQ: 'AmendedRFQ',
  DELIVERED: 'Delivered',
  PART_DELIVERED: 'PartDelivered',
  PART_ORDERED: 'PartOrdered',
  READY_TO_INVOICE: 'ReadyToInvoice',
  RECHECK: 'Re-check',
  ON_HOLD: 'OnHold',
  OFF_HOLD: 'OffHold',
  TO_INVOICE: 'ToInvoice',
  CANCELLED: 'Cancelled',
  INVOICED: 'Invoiced',
  APPROVED: 'Approved',
  QUOTED: 'Quoted',

  PART_INVOICED: 'Part Invoiced',
  REJECTED: 'Rejected',
};

export default status;
