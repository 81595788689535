//  Externals
import * as yup from 'yup';

const phoneRegExp = /^[0-9 ]+$/;

const schema = () =>
  yup.object({
    firstName: yup.string().trim().nullable().required('Please Enter First Name'),
    lastName: yup.string().trim().nullable().required('Please Enter Last Name'),
    emailAddress: yup.string().email('Please Enter Valid Email').trim().nullable().required('Please Enter Email'),
    telephoneNumber: yup
      .string()
      .trim()
      .nullable()
      .required('Please Enter Telephone Number')
      .matches(phoneRegExp, 'Please Enter Valid Telephone Number')
      .max(20, 'Please Enter Valid Telephone Number'),
    roleId: yup.string().trim().nullable().required('Please Select Role'),
    supplierId: yup.number().nullable().required('Please Select Supplier'),
    active: yup.string().trim().nullable().required('Please Select Yes/No'),
    cache: yup.boolean().nullable().nullable(),
    approver: yup.boolean().nullable().nullable(),
  });

export default schema;
