import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { AddJobRataPopup } from './AddJobRatePopup';
import { JobRateList } from './JobRateList';
import { useSelector, useDispatch } from 'react-redux';
import { getJobRateList, getAccountService } from '../../slice/selector';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const JobRate = () => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const jobRateList = useSelector(getJobRateList);
  const accountService = useSelector(getAccountService);
  const checkJobRateList = accountService?.data?.items ? accountService?.data?.items?.length > 0 : false;
  const { actions: accountAction } = useInvoiceSlice();
  const [jobRatePopup, setJobRatePopup] = useState(false);
  const [accountList, setAccountList] = useState<any>();
  const navigate = useNavigate();
  useEffect(() => {
    setAccountList(jobRateList);
  }, [jobRateList]);

  useEffect(() => {
    accountId && dispatch(accountAction.loadJobRateList(+accountId));
  }, []);
  return (
    <div className="products__page">
      <AddJobRataPopup show={jobRatePopup} onHide={() => setJobRatePopup(false)} />
      {accountList && accountList?.data?.length === 0 ? (
        <div className="extra__container">
          <div className="addProduct__container">
            <Button className="full__button" disabled={!checkJobRateList} variant="primary" style={{ paddingRight: '2rem', paddingLeft: '2rem' }} onClick={() => setJobRatePopup(true)}>
              Add Job Rate
            </Button>
            <span style={{ marginTop: '0.5rem' }}>You have not added aJob Rate yet</span>
            <span>
              Click on <strong>'Add Job Rate'</strong>
            </span>
          </div>
        </div>
      ) : (
        <>
          <JobRateList openJobRatePopup={() => setJobRatePopup(true)} />
        </>
      )}
      <footer>
        <Button className="full__button" variant="primary" style={{ paddingRight: '2rem', paddingLeft: '2rem' }} onClick={() => navigate(`/administration/account`)}>
          Done
        </Button>
      </footer>
    </div>
  );
};
