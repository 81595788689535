type DropDown = {
  code: string;
  label: string;
  value: number;
}[];

export interface MasterData {
  artworkFormats: DropDown;
  colours: DropDown;
  coloursOnFaces: DropDown;
  coloursOnInside: DropDown;
  coloursOnReverses: DropDown;
  coloursOnText: DropDown;
  coloursOnOutside: DropDown;
  coverMaterial: DropDown;
  coverMaterialWeight: DropDown;
  deliveryAddressChangeReasons: DropDown;
  discountReasons: DropDown;
  exportTypes: DropDown;
  finishedSizes: DropDown;
  finishings: DropDown;
  jobItemOptions: DropDown;
  jobOptions: DropDown;
  materials: DropDown;
  materialSuppliers: DropDown;
  materialWeights: DropDown;
  mechansim: DropDown;
  otherCosts: DropDown;
  supplierQuoteDeclineReasons: DropDown;
  typesOfProofs: DropDown;
}

export const initialState: MasterData = {
  artworkFormats: [],
  colours: [],
  coloursOnFaces: [],
  coloursOnInside: [],
  coloursOnReverses: [],
  coloursOnText: [],
  coloursOnOutside: [],
  coverMaterial: [],
  coverMaterialWeight: [],
  deliveryAddressChangeReasons: [],
  discountReasons: [],
  exportTypes: [],
  finishedSizes: [],
  finishings: [],
  jobItemOptions: [],
  jobOptions: [],
  materials: [],
  materialSuppliers: [],
  materialWeights: [],
  mechansim: [],
  otherCosts: [],
  supplierQuoteDeclineReasons: [],
  typesOfProofs: [],
};
