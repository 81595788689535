import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearch, selectSearchSupplier } from './slice/selectors';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { getIsSupplier } from '../../../app/components/modules/sideBar/slice/selectors';
import './style.sass';
import { useHeaderSlice } from './slice';

const Header = () => {
  const navigate = useNavigate();
  const { actions: headerActions } = useHeaderSlice();
  const dispatch = useDispatch();
  const searchData = useSelector(selectSearch);
  const isSupplier = useSelector(getIsSupplier);
  const searchSupplierData = useSelector(selectSearchSupplier);
  const onInputSearchChange = val => {
    if (val.length >= 1) {
      if (isSupplier) {
        dispatch(headerActions.loadSearchSupplier({ value: val }));
      } else {
        dispatch(headerActions.loadSearch({ value: val }));
      }
    }
  };
  const onHandleSelectedValueChange = val => {
    if (!isSupplier) {
      const filteredData = searchData.data.items.find(item => `${item?.jobNumber} - ${item?.title}` == val);
      let data = JSON.parse(JSON.stringify(filteredData));
      const jobId = data.jobId;
      navigate(`/myjobs/${jobId}`);
    }
  };

  return (
    <div className="header" style={{ padding: '0' }}>
      <div className="header__secondContainer">
        <div className="header__secondContainer" style={{ display: 'initial' }}>
          <Stack className="d-flex w-100">
            <Autocomplete
              size="small"
              id=""
              freeSolo
              options={
                isSupplier
                  ? searchSupplierData.data.items?.map(item => (item?.jobId !== null ? `${item?.jobLineId} - ${item?.title}` : ''))
                  : searchData.data.items?.map(item => (item?.jobId !== null ? `${item?.jobNumber} - ${item?.title}` : ''))
              }
              onChange={(event, value) => {
                onHandleSelectedValueChange(value);
              }}
              renderInput={params => <TextField {...params} variant="outlined" placeholder="search..." onChange={e => onInputSearchChange(e.target.value)} />}
            />
          </Stack>
          <div className="ps-4"></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
