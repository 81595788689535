import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { protectedResources } from 'configs/msalAuthConfigs';
import Form from 'react-bootstrap/Form';
import { isEmpty } from 'lodash';
import { getMasterData } from '../../../../store/reducers/masterData/selectors';
import { useCreateQuoteSlice } from '../slice/index';
import { request } from 'utils/request';
import './style.sass';
export interface DeclineQuote {
  jobQuoteId: number;
  cancellationReasonId: number | string;
  cancellationReasonOther: string;
}
const Decline = props => {
  const { jobQuoteId, title } = props;
  const { actions: createQuoteActions } = useCreateQuoteSlice();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createQuoteActions.loadRFQModal());
  }, []);
  const [reasonDeclineShow, setReasonDeclineShow] = useState(false);
  const masterDataDecline = useSelector(getMasterData('supplierQuoteDeclineReasons'));
  const [inputs, setInputs] = useState<DeclineQuote>({
    jobQuoteId,
    cancellationReasonId: '',
    cancellationReasonOther: '',
  });
  const handleInput = e => {
    const updatedInputs = {
      ...inputs,
      [e.target.name]: e.target?.checked ? e.target.checked : e.target.value,
    };
    if (e.target.name === 'cancellationReasonId') updatedInputs.cancellationReasonId = Number(updatedInputs.cancellationReasonId);
    setInputs(updatedInputs);
  };

  const [errors, setErrors] = useState({
    cancellationReasonId: '',
    cancellationReasonOther: '',
  });

  const validateForm = () => {
    let { cancellationReasonId, cancellationReasonOther } = inputs;
    //  Material Supplier Id
    if (cancellationReasonId === '' || cancellationReasonId < 0) errors.cancellationReasonId = 'Please select a Cancellation Reason';
    else errors.cancellationReasonId = '';
    //  Material
    if (inputs.cancellationReasonId === 4) {
      if (typeof cancellationReasonOther === 'string') cancellationReasonOther = cancellationReasonOther.trim();
      if (isEmpty(cancellationReasonOther)) errors.cancellationReasonOther = 'Cancellation reason is required';
      else errors.cancellationReasonOther = '';
    } else errors.cancellationReasonOther = '';
    setErrors({ ...errors });
    const validate = isEmpty(errors.cancellationReasonId) && isEmpty(errors.cancellationReasonOther);
    return validate ? true : false;
  };

  const handleOnSubmit = async event => {
    event.preventDefault();

    if (!validateForm()) return;
    const payload = {
      inputs: {},
      jobQuoteId,
    };
    if (inputs.cancellationReasonId !== 4) {
      payload.inputs = {
        jobQuoteId,
        cancellationReasonId: Number(inputs.cancellationReasonId),
        cancellationReasonOther: null,
      };
    } else {
      payload.inputs = {
        jobQuoteId,
        cancellationReasonId: Number(inputs.cancellationReasonId),
        cancellationReasonOther: inputs.cancellationReasonOther,
      };
    }
    props.onHide();
    setReasonDeclineShow(false);
    try {
      await request(`${protectedResources.api.apiEndpoint}/Quotes/DeclineSupplierQuote/${jobQuoteId}`, {
        method: 'PUT',
        body: JSON.stringify(payload.inputs),
      });
      dispatch(createQuoteActions.UpdateSupplierListDecline(payload.inputs));
    } catch (responseError) {
      console.error('Error while submitting the form ' + responseError);
    }
    resetData();
  };

  const resetData = () => {
    const entity = {
      cancellationReasonId: '',
      cancellationReasonOther: '',
    };
    setInputs({ ...errors, ...entity, jobQuoteId });
    setErrors({ ...entity });
  };

  return (
    <React.Fragment>
      <Modal
        backdrop="static"
        keyboard={false}
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="fullScreen"
        centered
        onHide={() => {
          props.onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Decline</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: '100px' }} className="fullScreenStyling">
          <div className="popupModal__bodyContainer">
            <span>Are you sure you want to decline the quotation? </span>
            <ol>
              <li>
                <span>{title}</span>
              </li>
            </ol>
          </div>
        </Modal.Body>
        <hr style={{ margin: '0px' }} />
        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="outline__button"
            onClick={() => {
              props.onHide();
            }}
          >
            No
          </Button>
          <Button
            variant="primary"
            className="full__button"
            onClick={() => {
              props.onHide();
              setReasonDeclineShow(true);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={reasonDeclineShow} backdrop="static" aria-labelledby="contained-modal-title-vcenter" backdropClassName="fullScreen" centered onHide={() => setReasonDeclineShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>Reason for Decline</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fullScreenStyling">
          <span>Please select the reason for declaining</span>
          <Form.Select
            onChange={handleInput}
            placeholder="Select"
            name="cancellationReasonId"
            isInvalid={errors.cancellationReasonId === '' ? false : true}
            className={errors.cancellationReasonId === '' ? 'multipleSelect form-select' : 'multipleSelect form-select is-invalid'}
          >
            <option>Select</option>
            {masterDataDecline &&
              masterDataDecline?.map(item => {
                return (
                  <option value={item?.value} key={item?.value}>
                    {item?.label}
                  </option>
                );
              })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errors.cancellationReasonId}</Form.Control.Feedback>
          {/* converting string to number */}
          {inputs.cancellationReasonId === 4 && (
            <Form.Group className="mb-3 modal_placeholder" controlId="formName">
              <Form.Label> Other Reason</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                as="textarea"
                rows={3}
                placeholder="Enter reason"
                name="cancellationReasonOther"
                value={inputs.cancellationReasonOther}
                isInvalid={errors.cancellationReasonOther === '' ? false : true}
                onChange={e => handleInput(e)}
              />
              <Form.Control.Feedback type="invalid">{errors.cancellationReasonOther}</Form.Control.Feedback>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" className="outline__button" onClick={() => setReasonDeclineShow(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="full__button"
            onClick={e => {
              handleOnSubmit(e);
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default Decline;
