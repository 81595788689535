//  Pull up Banner Print Specifications
interface PullUpBannerType {
  title: string | null;
  description: string | null;
  reference: string | null;
  relatedLineId: string | null;
  relatedLineValue: number | null;
  quantity: number | null;
  quantityRunOn: number | null;
  quantityActual?: number | null;
  noOfPages: number | null;
  visibleSize: string | null;
  coloursId: number | null;
  coloursOther: string | null;
  materialId: number | null;
  materialOther: string | null;
  laminated: boolean | string | null;
  mechansimId: number | null;
  mechansimOther: string | null;
  finishingId: number | null;
  finishingOther: string | null;
  addendum: string | null;
  deliveryContact: string | null;
  deliveryAddress: string | null;
  deliveryInstructions: string | null;
  deliveryDueDate: string | Date | null;
  noFailDeliveryDate: boolean;
  samples: boolean | string | null;
  noOfSampleCopies: number | null;
  sampleCopiesDeliveryAddress: string | null;
  artworkFormatId: number | null;
  artworkFormatOther: string | null;
  artworkDueDate: string | Date | null;
  typeOfProofsId?: number | null;
  typeOfProofsOther: string | null;
  proofDueDate: string | Date | null;
  primaryCategoryId: number | null;
  secondaryCategoryId: number | null;
  jobItemOptions: number[];
  targetDate: string | Date | null;
  cdsNotes: string | null;
  supplierNotes: string | null;
  clientNotes: string | null;
  jobType: string;
  isActive: boolean;
  status?: {
    code: string;
    id: number;
    name: string;
  };
  managementFee?: number;
  deliveryAddressChangeReasonId: number | null;
  deliveryAddressChangeReasonOther: string | null;
}
//  Initial state of Pull up Banner product
export const initialPullUpBanner: PullUpBannerType = {
  title: null,
  description: null,
  reference: null,
  relatedLineId: null,
  relatedLineValue: null,
  quantity: null,
  quantityRunOn: null,
  noOfPages: null,
  visibleSize: null,
  coloursId: null,
  coloursOther: null,
  materialId: null,
  materialOther: null,
  laminated: null,
  mechansimId: null,
  mechansimOther: null,
  finishingId: null,
  finishingOther: null,
  addendum: null,
  deliveryContact: null,
  deliveryAddress: null,
  deliveryInstructions: null,
  deliveryDueDate: null,
  noFailDeliveryDate: false,
  samples: null,
  noOfSampleCopies: null,
  sampleCopiesDeliveryAddress: null,
  artworkFormatId: null,
  artworkFormatOther: null,
  artworkDueDate: null,
  typeOfProofsId: null,
  typeOfProofsOther: null,
  proofDueDate: null,
  primaryCategoryId: null,
  secondaryCategoryId: null,
  jobItemOptions: [],
  targetDate: null,
  cdsNotes: null,
  supplierNotes: null,
  clientNotes: null,
  jobType: '',
  isActive: false,
  deliveryAddressChangeReasonId: null,
  deliveryAddressChangeReasonOther: null,
};
