//  Externals
import { useSelector } from 'react-redux';
import moment from 'moment';
import React from 'react';
//  Internals
import { getIsCDS, getIsClient } from '../sideBar/slice/selectors';

const ClientQuotation = props => {
  const { clientQuotations, initialState } = props;

  const isCDS = useSelector(getIsCDS);
  const isClient = useSelector(getIsClient);
  return (
    <>
      {isCDS && (
        <>
          <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/3', marginTop: '30px' }}>
            Client Quotation
          </h6>
          <div style={{ gridColumn: '1/3' }} className="pricing__information">
            <span>Pricing Information</span>
            <div>
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th className="text-end">Production (&#163;)</th>
                    <th className="text-end">Material (&#163;)</th>
                    <th className="text-end">Delivery (&#163;)</th>
                    <th className="text-end">Total (&#163;)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-end">{clientQuotations.productionRate?.toFixed(2) ? clientQuotations.productionRate?.toFixed(2) : '-'}</td>
                    <td className="text-end">{clientQuotations.materialRate?.toFixed(2) ? clientQuotations.materialRate?.toFixed(2) : '-'}</td>
                    <td className="text-end">{clientQuotations.deliveryRate?.toFixed(2) ? clientQuotations.deliveryRate?.toFixed(2) : '-'}</td>
                    <td className="text-end">{clientQuotations.total?.toFixed(2) ? clientQuotations.total?.toFixed(2) : '-'}</td>
                  </tr>
                  {initialState.quantityRunOn && (
                    <>
                      <tr>
                        <td colSpan={4} className="pt-4">
                          <span>Runon Information</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end thead-light fw-bold border-0 border-dark border-bottom border-2">Production Runon (&#163;)</td>
                        <td className="text-end thead-light fw-bold border-0 border-dark border-bottom border-2">Material Runon (&#163;)</td>
                        <td className="text-end thead-light fw-bold border-0 border-dark border-bottom border-2">Delivery Runon (&#163;)</td>
                        <td className="text-end thead-light fw-bold border-0 border-dark border-bottom border-2">Total Runon (&#163;)</td>
                      </tr>
                      <tr>
                        <td className="text-end">{clientQuotations.productionRunon?.toFixed(2) ? clientQuotations.productionRunon?.toFixed(2) : '-'}</td>
                        <td className="text-end">{clientQuotations.materialRunon?.toFixed(2) ? clientQuotations.materialRunon?.toFixed(2) : '-'}</td>
                        <td className="text-end">{clientQuotations.deliveryRunon?.toFixed(2) ? clientQuotations.deliveryRunon?.toFixed(2) : '-'}</td>
                        <td className="text-end">{clientQuotations.summaryRunon?.toFixed(2) ? clientQuotations.summaryRunon?.toFixed(2) : '-'}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ gridColumn: '1/2' }}>
            <span>Quantity Information</span>
            <div>
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Quantity Actual</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{initialState.quantityActual ?? initialState.quantityActual}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ gridColumn: '1/4' }}>
            <span>Price Summary Information</span>
            <div>
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th className="text-end">Cost (&#163;)</th>
                    <th className="text-end">Management fee %</th>
                    <th className="text-end">Management fee (&#163;)</th>
                    <th className="text-end">Total net price (&#163;)</th>
                    <th className="text-end">VAT Rate %</th>
                    <th className="text-end">VAT Amount (&#163;)</th>
                    <th className="text-end">Gross Amount (&#163;)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-end">{clientQuotations.priceSummary.cost != null ? clientQuotations.priceSummary.cost?.toFixed(2) : '-'}</td>
                    <td className="text-end">{initialState.managementFee != null ? initialState.managementFee.toFixed(2) : '-'}&#x25;</td>
                    <td className="text-end">{clientQuotations.priceSummary.managementFeeAmount != null ? clientQuotations.priceSummary.managementFeeAmount?.toFixed(2) : '-'}</td>
                    <td className="text-end">{clientQuotations.priceSummary.totalNetPrice != null ? clientQuotations.priceSummary.totalNetPrice?.toFixed(2) : '-'}</td>
                    <td className="text-end">{clientQuotations.priceSummary.vat != null ? clientQuotations.priceSummary.vat : '-'} &#x25;</td>
                    <td className="text-end">{clientQuotations.priceSummary.vatAmount != null ? clientQuotations.priceSummary.vatAmount?.toFixed(2) : '-'}</td>
                    <td className="text-end">{clientQuotations.priceSummary.grossAmount != null ? clientQuotations.priceSummary.grossAmount?.toFixed(2) : '-'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {initialState.quantityRunOn ? (
            <div style={{ gridColumn: '1/4' }}>
              <span>Runon Summary Information</span>
              <div>
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-end">Cost (&#163;)</th>
                      <th className="text-end">Management fee % </th>
                      <th className="text-end">Management fee (&#163;)</th>
                      <th className="text-end">Total net price (&#163;)</th>
                      <th className="text-end">VAT Rate %</th>
                      <th className="text-end">VAT Amount (&#163;)</th>
                      <th className="text-end">Gross Amount (&#163;)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-end">{clientQuotations.runonSummary.cost != null ? clientQuotations.runonSummary.cost?.toFixed(2) : '-'}</td>
                      <td className="text-end">{clientQuotations.runonSummary.managementfee != null ? clientQuotations.runonSummary.managementfee?.toFixed(2) : '-'}&#x25;</td>
                      <td className="text-end">{clientQuotations.runonSummary.managementFeeAmount != null ? clientQuotations.runonSummary.managementFeeAmount?.toFixed(2) : '-'}</td>
                      <td className="text-end">{clientQuotations.runonSummary.totalNetPrice != null ? clientQuotations.runonSummary.totalNetPrice?.toFixed(2) : '-'}</td>
                      <td className="text-end">{clientQuotations.runonSummary.vat != null ? clientQuotations.runonSummary.vat : '-'}&#x25;</td>
                      <td className="text-end">{clientQuotations.runonSummary.vatAmount != null ? clientQuotations.runonSummary.vatAmount?.toFixed(2) : '-'}</td>
                      <td className="text-end">{clientQuotations.runonSummary.grossAmount != null ? clientQuotations.runonSummary.grossAmount?.toFixed(2) : '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <></>
          )}
          {initialState?.status?.code !== 'Quotation' ? (
            <div style={{ gridColumn: '1/3' }}>
              <span>Actual Summary Information</span>
              <div>
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-end">Total net price (&#163;)</th>
                      <th className="text-end">VAT Rate % </th>
                      <th className="text-end">VAT Amount (&#163;)</th>
                      <th className="text-end">Gross Amount (&#163;)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-end">{clientQuotations?.actualSummary?.totalNetPrice ? clientQuotations?.actualSummary?.totalNetPrice?.toFixed(2) : '0.00'}</td>
                      <td className="text-end">{clientQuotations?.actualSummary?.vat && clientQuotations?.actualSummary?.vat} %</td>
                      <td className="text-end">{clientQuotations?.actualSummary?.vatAmount ? clientQuotations?.actualSummary?.vatAmount.toFixed(2) : '0.00'}</td>
                      <td className="text-end">{clientQuotations?.actualSummary?.grossAmount ? clientQuotations?.actualSummary?.grossAmount?.toFixed(2) : '0.00'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <></>
          )}
          {initialState?.discountDetails?.status == 'Approved' && initialState?.discountSummary?.discountedValue > 0 && (
            <div style={{ gridColumn: '1/3' }}>
              <span>Discounts</span>
              <div>
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-end pe-2">Original Amount</th>
                      <th className="text-end pe-3">Discounted Amount</th>
                      <th className="pe-2">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-end pe-2">{initialState?.discountSummary?.originalCost && initialState?.discountSummary?.originalCost?.toFixed(2)}</td>
                      <td className="text-end pe-3">
                        {initialState?.discountSummary?.discountedValue && initialState?.discountSummary?.discountedValue?.toFixed(2) == null
                          ? '-'
                          : initialState?.discountSummary?.discountedValue?.toFixed(2)}
                      </td>
                      <td className="pe-2">{initialState?.discountSummary?.approvedDateTime ? moment(initialState?.discountSummary?.approvedDateTime).format('DD/MM/YYYY') : '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div style={{ gridColumn: '1/2', marginBottom: '30px' }}>
            <span>Merchant Information</span>
            <div>
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Merchant</th>
                    <th className="text-end">Rebate (&#163;)</th>
                    {initialState.quantityRunOn ? <th className="text-end">Runon Rebate (&#163;)</th> : <></>}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{clientQuotations.merchantName != null ? clientQuotations.merchantName : '-'}</td>
                    <td className="text-end">{clientQuotations.merchantRate != null ? clientQuotations.merchantRate?.toFixed(2) : '-'}</td>
                    {initialState.quantityRunOn ? <td className="text-end">{clientQuotations.merchantRunon != null ? clientQuotations.merchantRunon?.toFixed(2) : '-'}</td> : <></>}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      {isClient && (
        <>
          <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/3', marginTop: '30px' }}>
            Quotation
          </h6>
          <div style={{ gridColumn: '1/3' }}>
            <span>Pricing Information</span>
            <div>
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th className="text-end">Net (&#163;)</th>
                    <th className="text-end">VAT(&#163;)</th>
                    <th className="text-end">Total (&#163;)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-end">{clientQuotations.priceSummary.totalNetPrice != null ? clientQuotations.priceSummary.totalNetPrice?.toFixed(2) : '-'}</td>
                    <td className="text-end">{clientQuotations.priceSummary.vatAmount != null ? clientQuotations.priceSummary.vatAmount?.toFixed(2) : '-'}</td>
                    <td className="text-end">{clientQuotations.priceSummary.grossAmount ? clientQuotations.priceSummary.grossAmount?.toFixed(2) : '-'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {initialState.quantityRunOn ? (
            <div style={{ gridColumn: '1/3' }}>
              <span>Runon Summary</span>
              <div>
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-end">Runon Net (&#163;)</th>
                      <th className="text-end">Runon VAT(&#163;)</th>
                      <th className="text-end">Runon Total (&#163;)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-end">{clientQuotations.runonSummary.totalNetPrice != null ? clientQuotations.runonSummary.totalNetPrice?.toFixed(2) : '-'}</td>
                      <td className="text-end">{clientQuotations.runonSummary.vatAmount != null ? clientQuotations.runonSummary.vatAmount?.toFixed(2) : '-'}</td>
                      <td className="text-end">{clientQuotations.runonSummary.grossAmount ? clientQuotations.runonSummary.grossAmount?.toFixed(2) : '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <></>
          )}
          {initialState?.status?.code !== 'Quotation' && (
            <div style={{ gridColumn: '1/3' }}>
              <span>Actual Summary Information</span>
              <div>
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-end">Actual Quantity(&#163;)</th>
                      <th className="text-end">Net (&#163;)</th>
                      <th className="text-end">VAT (&#163;)</th>
                      <th className="text-end">Total (&#163;)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-end">{clientQuotations?.quantityActual ? clientQuotations?.quantityActual : '-'}</td>
                      <td className="text-end">{clientQuotations?.actualSummary?.totalNetPrice ? clientQuotations?.actualSummary?.totalNetPrice?.toFixed(2) : '0.00'}</td>
                      <td className="text-end">{clientQuotations?.actualSummary?.vatAmount ? clientQuotations?.actualSummary?.vatAmount.toFixed(2) : '0.00'}</td>
                      <td className="text-end">{clientQuotations?.actualSummary?.grossAmount ? clientQuotations?.actualSummary?.grossAmount?.toFixed(2) : '0.00'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {initialState?.discountDetails?.status == 'Approved' && initialState?.discountSummary?.discountedValue > 0 && (
            <div style={{ gridColumn: '1/3' }}>
              <span>Discounts</span>
              <div>
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-end pe-2">Original Amount</th>
                      <th className="text-end pe-3">Discounted Amount</th>
                      <th className="pe-2">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-end pe-2">{initialState?.discountSummary?.originalCost && initialState?.discountSummary?.originalCost?.toFixed(2)}</td>
                      <td className="text-end pe-3">
                        {initialState?.discountSummary?.discountedValue && initialState?.discountSummary?.discountedValue?.toFixed(2) == null
                          ? '-'
                          : initialState?.discountSummary?.discountedValue?.toFixed(2)}
                      </td>
                      <td className="pe-2">{initialState?.discountSummary?.approvedDateTime ? moment(initialState?.discountSummary?.approvedDateTime).format('DD/MM/YYYY') : '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ClientQuotation;
