//  Externals
import * as yup from 'yup';
//  Internals
import ERRORS from '../../../../data/errors';

const phoneRegExp = /^[0-9 ]+$/;

const schema = ({ isAdmin }) =>
  yup.object({
    firstName: yup.string().trim().nullable().required(ERRORS.FIRST_NAME),
    lastName: yup.string().trim().nullable().required(ERRORS.LAST_NAME),
    emailAddress: yup.string().email(ERRORS.VALID_EMAIL).trim().nullable().required(ERRORS.EMAIL),
    telephoneNumber: yup.string().trim().nullable().required(ERRORS.TELEPHONE_NUMBER).matches(phoneRegExp, ERRORS.VALID_TELEPHONE_NUMBER).max(20, ERRORS.VALID_TELEPHONE_NUMBER),
    accountId: yup.number().nullable().required(ERRORS.SELECT_ACCOUNT),
    roleId: yup.string().trim().nullable().required(ERRORS.SELECT_ROLE),
    active: yup.string().trim().nullable().required('Please state whether the user is active or not'),
    cache: yup.boolean().nullable().nullable(),
    approver: yup.boolean().nullable().nullable(),
    businessUnitsId: yup
      .array()
      .nullable()
      .when('$exist', {
        is: () => !isAdmin,
        then: yup.array().of(yup.number()).min(1, ERRORS.SELECT_BUSINESS_UNIT).nullable().required(ERRORS.SELECT_BUSINESS_UNIT),
      }),
  });

export default schema;
