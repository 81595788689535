import React, { useEffect, useState } from 'react';
import { CONFIRMATION, OUTLINED, FULL, SUCCESS } from 'app/components/shared/Constants';
import PopupModal from '../../../components/shared/PopupModal';

const PlaceOrder = props => {
  const { show, onHide, onPlaceOrder, productWithQuotation, isSuccess, onSuccess, otherCostList } = props;
  const [bodyContentTitle, setBodyContentTitle] = useState();
  const quationOtherCostList = otherCostList
    ?.filter(item => item?.status?.code === 'Quotation')
    ?.map(row => {
      return (
        <>
          <span>{row.name + ' -  Other Costs'}</span>
        </>
      );
    });

  useEffect(() => {
    const bodyContent = productWithQuotation
      ?.filter(entity => entity?.statusName === 'Quotation' && entity?.quoted != null && entity?.details?.printSpecifications?.form?.isActive === true)
      ?.map(row => {
        return (
          <>
            <span>{row.title + ' - ' + row.productType}</span>
            <br />
            <span> {row?.quantityActual != null ? 'Quantity actual -' + row?.quantityActual : ''}</span>
          </>
        );
      });
    setBodyContentTitle(quationOtherCostList ? bodyContent.concat(quationOtherCostList) : bodyContent);
  }, [show]);
  return (
    <PopupModal
      show={show}
      onHide={() => onHide()}
      type={isSuccess ? SUCCESS : CONFIRMATION}
      header={isSuccess ? SUCCESS : 'Place Order'}
      bodyTitle={isSuccess ? 'Order has been Placed' : 'Are you sure you want to place the order for the following items?'}
      bodyContent={bodyContentTitle}
      footerButtons={
        isSuccess
          ? [
              {
                type: OUTLINED,
                name: 'OK',
                onClick: () => {
                  onHide();
                  onSuccess();
                },
              },
            ]
          : [
              {
                type: OUTLINED,
                name: 'No',
                onClick: () => onHide(),
              },
              {
                type: FULL,
                name: 'Yes',
                onClick: () => onPlaceOrder(),
              },
            ]
      }
    />
  );
};

export default PlaceOrder;
