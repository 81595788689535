import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import SupplierList from './SupplierList';
import './style.sass';
import AssignSuppliersForm from './AssignSuppliersForm';
import { selectFilteredSupplierList, selectSelectedSupplierList, selectSupplierData, selectTempSelectedSupplierList, useParamSelector, getMinSupplierCount } from '../slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestForQuoteSlice } from '../slice';
import { fixTimezoneOffset } from 'utils/helperFunctions';
import { request } from 'utils/request';
import { protectedResources } from '../../../../configs/msalAuthConfigs';
import { useParams } from 'react-router-dom';
import { ReactComponent as ConfirmationWarningIcon } from '../../../../assets/icons/confirmationWarning.svg';
import { apiCallBegan } from 'store/reducers/loading';
import API from '../../../../data/api';
import MODULES from '../../../../data/modules';
import { setUpdateJobData } from 'store/reducers/entities';
import { getActionToggle } from 'store/reducers/entities/selectors';

const AssignSuppliers = props => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [checkedValues, setCheckValues] = useState<any[]>([]);
  const assignSuppliersFormValues = useParamSelector(selectSupplierData, props.rowid).assignSuppliersFormData;
  const selectedTempSuppliers = useParamSelector(selectTempSelectedSupplierList, props.rowid);
  const rows = useParamSelector(selectFilteredSupplierList, props.rowid);
  const dispatch = useDispatch();
  const { actions } = useRequestForQuoteSlice();
  const selectedSuppliers = useParamSelector(selectSelectedSupplierList, props.rowid);
  const selectedIds = selectedSuppliers.map(row => row.supplierId);
  const selectedTempIds = selectedTempSuppliers.map(row => row.supplierId);
  const { jobId } = useParams();
  const { actions: rfqActions } = useRequestForQuoteSlice();
  const minSupplierCount = useParamSelector(getMinSupplierCount, props.rowid);
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));

  const filterRows = e => {
    dispatch(
      actions.filterSupplierList({
        id: props.rowid,
        searchString: e.target.value.trim(),
      }),
    );
  };

  const submitHandler = async () => {
    //  Request quote post api
    const suppliers = selectedTempIds.map(item => {
      if (checkedValues.indexOf(item) > -1) {
        return {
          supplierId: item,
          isEmail: true,
        };
      } else {
        return {
          supplierId: item,
          isEmail: false,
        };
      }
    });

    const updatedValuesForApi = {
      ...assignSuppliersFormValues,
      dueDate: assignSuppliersFormValues.dueDate && JSON.stringify(fixTimezoneOffset(new Date(assignSuppliersFormValues.dueDate))).replace(/"/g, ''),
      dueTime: assignSuppliersFormValues.dueTime && JSON.stringify(fixTimezoneOffset(new Date(assignSuppliersFormValues.dueTime))).replace(/"/g, ''),
      suppliers: suppliers,
    };

    try {
      await request(`${protectedResources.api.apiEndpoint}/RFQ/CreateQuote/${jobId}/${props.rowid}`, {
        method: 'POST',
        body: JSON.stringify(updatedValuesForApi),
      });
    } catch (responseError) {
      console.log('Error while submitting the form ' + responseError);
    }
    jobId && dispatch(rfqActions.loadRfqData({ jobId: jobId, toggleAction: toggleAction }));
    dispatch(apiCallBegan({ url: `${API.GET_JOB}/${jobId}?inActive=${toggleAction}`, method: 'GET', onSuccess: setUpdateJobData.type, module: MODULES.MY_JOBS }));
    localStorage.setItem('AssignSupplier', 'true');
    setShowConfirmModal(false);
    props.onHide();
  };

  useEffect(() => {
    dispatch(actions.loadSupplierList({ id: props.rowid, productId: props.productid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        enforceFocus={false}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName="fullScreen"
        onHide={() => {
          props.onHide();
        }}
        dialogClassName="modal__assignSuppliers"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Assign Suppliers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="supplier__modalBody">
            <div className="top__half">
              <Form.Control autoComplete="off" type="search" className="suppliers__search__header" placeholder="Select or search suppliers" onChange={filterRows} />
              <SupplierList rows={rows} rowid={props.rowid} selectedIds={selectedIds} supplierRowArray={props?.supplierRowArray} minSupplierCount={minSupplierCount} />
            </div>
            <div className="bottom__half mt-2">
              <AssignSuppliersForm rowid={props.rowid} showConfirmModal={val => setShowConfirmModal(val)} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="outline__button"
            onClick={() => {
              props.onHide();
            }}
          >
            Discard
          </Button>
          &nbsp;
          <Button disabled={false} variant="primary" className="full__button" form="supplierForm" type="submit">
            Request Quote
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Replacing popup modal with normal modal */}
      <Modal backdrop="static" show={showConfirmModal} backdropClassName="fullScreen__overPopup" centered onHide={() => setShowConfirmModal(false)}>
        <div className="header-assign-supplier">
          <Modal.Title id="contained-modal-title-vcenter">Assign Suppliers</Modal.Title>
        </div>
        <Modal.Body>
          <div className="popupModal__bodyContainer">
            <div className="body__titleContainer">
              <ConfirmationWarningIcon />
              <span className="body__title">Are you sure you want to request quotes from these suppliers?</span>
            </div>
            {selectedTempSuppliers && (
              <ol className="body__contentContainer">
                {selectedTempSuppliers?.map(row => (
                  <li key={row.supplierId}>
                    {row.supplierName}
                    <Form.Check
                      type="checkbox"
                      id={row.supplierId}
                      label="Do not send an email notification"
                      name={row.supplierId}
                      onChange={e => {
                        e.target.checked ? setCheckValues([...checkedValues, parseInt(e.target.name)]) : setCheckValues(checkedValues.filter(item => item !== parseInt(e.target.name)));
                      }}
                    />
                  </li>
                ))}
              </ol>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button key="No" variant="outline-primary" className="outline__button" onClick={() => setShowConfirmModal(false)}>
            No
          </Button>
          <Button key="Yes" variant="primary" className="full__button" onClick={() => submitHandler()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignSuppliers;
