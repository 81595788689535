import React from 'react';
import Card from 'react-bootstrap/Card';
import { ResponsiveContainer, ComposedChart, BarChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, Label } from 'recharts';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { red, yellow } from '@mui/material/colors';

const DashbordLayout = props => {
  const { data, labels = [], userRoles, c1 = [], c2 = [] } = props;
  const openJobByStatusdata = data?.openJobByStatus;
  const copyObjopenJobByStatusdata: any = openJobByStatusdata.length > 0 && { ...openJobByStatusdata[0] };
  delete copyObjopenJobByStatusdata.name;
  var totalOpenByJobStatus = 0;
  for (let value in copyObjopenJobByStatusdata) {
    totalOpenByJobStatus += copyObjopenJobByStatusdata[value];
  }

  const DateFormatter = date => moment(date).format('MMM-YY');
  const DateFormatterWeek = date => moment(date).format('DD/MM/YYYY');

  const renderCustomizedLabel = props => {
    const { content, ...rest } = props;

    return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="Bold" />;
  };

  const renderColorfulLegendText = (id: string, entry: any) => {
    const { color } = entry;

    return (
      <span className="text-capitalize" style={{ color }}>
        {entry.payload.id}
      </span>
    );
  };

  const generateRandomColor = () => {
    let maxVal = 0xffffff; // 16777215
    let randomNumber: any = Math.random() * maxVal;
    randomNumber = Math.floor(randomNumber);
    randomNumber = randomNumber.toString(16);
    let randColor = randomNumber.padStart(6, 0);
    return `#${randColor.toUpperCase()}`;
  };

  const getStackBar = barData => {
    var copyObj: any = barData.length > 0 && { ...barData[0] };
    delete copyObj.name;
    var result: any = [];
    for (const property in copyObj) {
      result.push(
        <Bar dataKey={`${property}`} fill={c1[property]} stackId="a" id={property.replace(/([a-z0-9])([A-Z])/g, '$1 $2')} name={property.replace(/([a-z0-9])([A-Z])/g, '$1 $2').toUpperCase()}>
          <LabelList dataKey={`${property}`} position="center" content={renderCustomizedLabel} />
        </Bar>,
      );
    }
    return result;
  };

  const generateGraph = (data, index) => {
    var graphData = JSON.parse(JSON.stringify(data));
    graphData.forEach((d: any) => {
      d.dataora = moment(d.dataora).valueOf(); // date -> epoch
    });
    return (
      <ResponsiveContainer key={labels[index].barLabel + index}>
        <ComposedChart data={graphData} margin={{ top: 0, right: 0, bottom: 25, left: 0 }}>
          <XAxis
            dataKey={'dataora'}
            domain={[graphData[0].dataora, graphData[graphData.length - 1].dataora]}
            angle={-90}
            scale="time"
            type="number"
            tickFormatter={DateFormatter}
            padding={{ right: 40, left: 40 }}
            dx={-6}
            dy={20}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            yAxisId="left"
            tickFormatter={tick => {
              return `${tick}%`;
            }}
            domain={[0, 100]}
            axisLine={false}
            tickLine={false}
          />
          <YAxis yAxisId="right" orientation="right" axisLine={false} tickLine={false} />
          <Legend verticalAlign="top" height={36} formatter={renderColorfulLegendText} />
          <CartesianGrid stroke="#1BA39C" vertical={false} opacity={0.2} />
          <Bar yAxisId="left" dataKey="pv" barSize={40} fill={c2[index]} id={labels[index].barLabel}>
            <LabelList dataKey="pv" position="center" content={renderCustomizedLabel} />
          </Bar>
          <Line yAxisId="right" type="linear" dataKey="uv" stroke={index === 1 ? '#3e1951' : '#1ba39c'} id={labels[index].lineLabel} strokeDasharray="3 0" strokeWidth={1} />
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  const getKPIIcon = isIcon => {
    return (
      <div className="ms-left">
        {isIcon == 'true' ? (
          <ArrowDropUpIcon sx={{ fontSize: 60 }} color="success" />
        ) : isIcon == 'false' ? (
          <ArrowDropDownIcon sx={{ fontSize: 60, color: red[500] }} />
        ) : isIcon == 'netural' ? (
          <HorizontalRuleIcon sx={{ fontSize: 60, color: yellow[500] }} />
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <>
      <div className="dashboard__container">
        <div className="dashboard__hearder mb-5 d-flex flex-column align-items-center">
          <h6 className="text-uppercase fw-bold cds-text-primary fs-5">Open job by status</h6>
          {totalOpenByJobStatus === 0 ? (
            <strong>No open jobs available</strong>
          ) : (
            <ResponsiveContainer height={80} width={'100%'}>
              <BarChart layout="vertical" data={data?.openJobByStatus} margin={{ left: 0, right: 0 }} stackOffset="expand">
                <XAxis hide type="number" />
                <YAxis type="category" dataKey="name" stroke="#FFFFFF" fontSize="12" hide />
                <Tooltip wrapperStyle={{ zIndex: 1000 }} />
                <Legend verticalAlign="top" height={36} formatter={renderColorfulLegendText} />
                {data?.openJobByStatus && getStackBar(data?.openJobByStatus)}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
        <div className="dashboard__body d-flex">
          <div className="dashboard__left__continer pe-2">
            {!userRoles.isCDS && (
              <>
                <h6 className="text-uppercase fw-bold cds-text-primary fs-5">Spend</h6>
                <hr className="cds-text-primary" />
              </>
            )}
            <div className="spend__container">
              {data?.spends?.map((item, index) => {
                var classNames = userRoles.isSupplier && (index == 4 ? 'text-success' : index == 5 ? 'text-danger' : 'cds-text-primary');
                return (
                  <Card className="w-100 card__div" key={item.name + index}>
                    <Card.Body className="d-flex flex-column align-items-center">
                      <Card.Text className="cds-text-primary fw-bold">{item.name}</Card.Text>
                      <Card.Text className={`${classNames} cds-text-primary fw-bolder fs-2`}>{item.count}</Card.Text>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </div>
          <div className="dashboard__right__continer ps-5">
            {userRoles.isCDS ? (
              <>
                <h6 className="text-uppercase fw-bold cds-text-primary m-0 fs-5 text-center">Tasks by due date</h6>
                <hr className="cds-text-primary" />
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart width={600} height={400} data={data.graphWidgets} stackOffset="expand" margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey={'day'} tickFormatter={DateFormatterWeek} />
                    <YAxis type="number" allowDecimals={false} />
                    <Bar dataKey="count" stroke="#8884d8" fill="#8884d8" id="aarea" />
                  </BarChart>
                </ResponsiveContainer>
              </>
            ) : (
              <>
                <h6 className="text-uppercase fw-bold cds-text-primary m-0 fs-5">Kpis</h6>
                <div className="kpis__container d-flex flex-column">
                  {data?.graphWidgets &&
                    data?.graphWidgets.map((item, index) => (
                      <div key={index + 'graphWidget'}>
                        <hr className="cds-text-primary" />
                        <div className="d-flex pb-10">
                          <div className="kpis__left ">
                            {item.kpi.map((kpi, index) => (
                              <div className="d-flex flex-column align-items-center cds-text-primary" key={kpi.name + index}>
                                <div className="fs-7">{kpi.name}</div>
                                <div className="fs-2 d-flex justify-content-center align-items-center">
                                  {kpi.count}&#37; {getKPIIcon(kpi.isUp)}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="kpis__right" style={{ height: '210px' }}>
                            {generateGraph(item.graph, index)}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashbordLayout;
