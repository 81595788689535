import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { outstandingPurchaseActions as actions } from './';
import { protectedResources } from '../../../../configs/msalAuthConfigs';

function* getSearch(action) {
  const requestURL = `${protectedResources.api.apiEndpoint}/Invoice/SearchOutstandingPO?SearchTerm=${action.payload.value}`;
  try {
    const search: any = yield call(request, requestURL);
    if (search) {
      yield put(actions.searchLoaded(search));
    } else {
      yield put(
        actions.searchError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.searchError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.searchError({ error: 'Issue with api', loading: false }));
    }
  }
}

function* getAccount(action) {
  const accountId = action.payload?.id;
  const requestURL = `${protectedResources.api.apiEndpoint}/Invoice/GetPODeatilsByAccount/${accountId}`;
  try {
    const search: any = yield call(request, requestURL);
    if (search) {
      yield put(actions.accountOrPodetails(search));
    } else {
      yield put(
        actions.accountError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.accountError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.accountError({ error: 'Issue with api', loading: false }));
    }
  }
}
function* getPODetails(action) {
  const accountId = action.payload?.id;
  const requestURL = `${protectedResources.api.apiEndpoint}/Invoice/GetSupplierInvoiceDetails/${accountId}`;
  try {
    const search: any = yield call(request, requestURL);
    if (search) {
      yield put(actions.PodetailsLoaded(search));
    } else {
      yield put(
        actions.PODetailsError({
          error: 'Received an empty response',
          loading: false,
        }),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.PODetailsError({
          error: 'Could not fetch data from endpoint',
          loading: false,
        }),
      );
    } else {
      yield put(actions.PODetailsError({ error: 'Issue with api', loading: false }));
    }
  }
}

export function* useOutstandingPurchaseSaga() {
  yield takeLatest(actions.loadSearch.type, getSearch);
  yield takeLatest(actions.loadSelectedAccount.type, getAccount);
  yield takeLatest(actions.loadSelectedPO.type, getPODetails);
}
