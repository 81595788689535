//  Externals
import * as yup from 'yup';
//  Internals
import ERRORS from '../../../../data/errors';

const phoneRegExp = /^[0-9 ]+$/;

const schema = () =>
  yup.object({
    supplierName: yup.string().trim().nullable().required('Please Enter Name'),
    supplierReference: yup.string().trim().nullable().required('Please Enter Supplier Reference'),
    contactEmailAddress: yup.string().email('Please Enter Valid Email').trim().nullable().required('Please Enter Email'),
    address: yup.string().trim().nullable().required('Please Enter Address'),
    postCode: yup.string().trim().nullable().max(10, 'Post Code must be at most 10 characters').required('Enter postcode'),
    telephone: yup.string().required('Please Enter Telephone Number').matches(phoneRegExp, 'Please Enter Valid Number').max(20, 'Please Enter Valid Number'),
    website: yup.string().trim().nullable(),
    isActive: yup.string().trim().nullable().required('Please Select Yes/No'),
    notes: yup.string().trim().nullable(),
    codeName: yup.string().trim().nullable(),
    reminderPreferrences: yup.string().trim().nullable().required(ERRORS.SELECT_RECEIVE_REMINDERS),
    vendorId: yup.string().trim().nullable(),
    supplierGeolocationsId: yup.array().of(yup.number().nullable()).nullable(),
  });

export default schema;
