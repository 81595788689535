import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../store/model';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.myProfile || initialState;

export const selectNavigation = createSelector([selectSlice], myProfile => myProfile.navigation);
export const myAccountList = createSelector([selectSlice], myProfile => myProfile.accounts.data.items);
export const myTeamtList = createSelector([selectSlice], myProfile => myProfile.teams.data.items);
export const userProfileData = createSelector([selectSlice], myProfile => myProfile.myProfile);
