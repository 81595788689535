//  Externals
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
//  Internals
import { getMasterData } from './../../../../../store/reducers/masterData/selectors';
import VALIDATIONS from './../../../../../data/validations';

const BookletDetails = props => {
  const { productItemDetails, type } = props;

  const coverMaterialWeights = useSelector(getMasterData('coverMaterialWeight'));
  const coloursOnOutsides = useSelector(getMasterData('coloursOnOutside'));
  const coloursOnInsides = useSelector(getMasterData('coloursOnInside'));
  const materialWeights = useSelector(getMasterData('materialWeights'));
  const coloursOnTexts = useSelector(getMasterData('coloursOnText'));
  const coverMaterials = useSelector(getMasterData('coverMaterial'));
  const finishedSizes = useSelector(getMasterData('finishedSizes'));
  const finishings = useSelector(getMasterData('finishings'));
  const materials = useSelector(getMasterData('materials'));

  const [coverMaterialWeightValue, setCoverMaterialWeightValue] = useState('-');
  const [coloursOnOutsideValue, setColoursOnOutsideValue] = useState('-');
  const [coloursOnInsideValue, setColoursOnInsideValue] = useState('-');
  const [materialWeightValue, setMaterialWeightValue] = useState('-');
  const [coloursOnTextValue, setColoursOnTextValue] = useState('-');
  const [coverMaterialValue, setCoverMaterialValue] = useState('-');
  const [finishedSizeValue, setFinishedSizeValue] = useState('-');
  const [finishingValue, setFinishingValue] = useState('-');
  const [materialValue, setMaterialValue] = useState('-');

  useEffect(() => {
    const { coloursOnInsideId, coloursOnOutsideId, coloursOnTextId, coverMaterialId, coverMaterialWeightId, finishedSizeId, finishingId, materialId, materialWeightId } = productItemDetails;
    //  Colours On Inside
    const coloursOnInside = coloursOnInsides.find(entity => entity.value === coloursOnInsideId);
    if (coloursOnInside && coloursOnInside?.label === VALIDATIONS.OTHER) setColoursOnInsideValue(productItemDetails.coloursOnInsideOther);
    else setColoursOnInsideValue(coloursOnInside?.label);
    //  Colours On Outside
    const coloursOnOutside = coloursOnOutsides.find(entity => entity.value === coloursOnOutsideId);
    if (coloursOnOutside && coloursOnOutside?.label === VALIDATIONS.OTHER) setColoursOnOutsideValue(productItemDetails.coloursOnOutsideOther);
    else setColoursOnOutsideValue(coloursOnOutside?.label);
    //  Colours On Text
    const coloursOnText = coloursOnTexts.find(entity => entity.value === coloursOnTextId);
    if (coloursOnText && coloursOnText?.label === VALIDATIONS.OTHER) setColoursOnTextValue(productItemDetails.coloursOnTextOther);
    else setColoursOnTextValue(coloursOnText?.label);
    //  Cover Material
    const coverMaterial = coverMaterials.find(entity => entity.value === coverMaterialId);
    if (coverMaterial && coverMaterial?.label === VALIDATIONS.OTHER) setCoverMaterialValue(productItemDetails.coverMaterialOther);
    else setCoverMaterialValue(coverMaterial?.label);
    //  Cover Material Weight
    const coverMaterialWeight = coverMaterialWeights.find(entity => entity.value === coverMaterialWeightId);
    if (coverMaterialWeight && coverMaterialWeight?.label === VALIDATIONS.OTHER) setCoverMaterialWeightValue(productItemDetails.coverMaterialWeightOther);
    else setCoverMaterialWeightValue(coverMaterialWeight?.label);
    //  Finished Size Other
    const finishedSize = finishedSizes.find(entity => entity.value === finishedSizeId);
    if (finishedSize && finishedSize?.label === VALIDATIONS.OTHER) setFinishedSizeValue(productItemDetails.finishedSizeOther);
    else setFinishedSizeValue(finishedSize?.label);
    //  Finishing Other
    const finishing = finishings.find(entity => entity.value === finishingId);
    if (finishing && finishing?.label === VALIDATIONS.OTHER) setFinishingValue(productItemDetails.finishingOther);
    else setFinishingValue(finishing?.label);
    //  Material Other
    const material = materials.find(entity => entity.value === materialId);
    if (material && material?.label === VALIDATIONS.OTHER) setMaterialValue(productItemDetails.materialOther);
    else setMaterialValue(material?.label);
    //  Material Weight Other
    const materialWeight = materialWeights.find(entity => entity.value === materialWeightId);
    if (materialWeight && materialWeight?.label === VALIDATIONS.OTHER) setMaterialWeightValue(productItemDetails.materialWeightOther);
    else setMaterialWeightValue(materialWeight?.label);
  }, [coloursOnInsides, coloursOnOutsides, coloursOnTexts, coverMaterialWeights, coverMaterials, finishedSizes, finishings, materialWeights, materials, productItemDetails]);

  return (
    <>
      {type === 'clientQuote' ? (
        <>
          <div className="grid__container">
            <div>
              <span>Reference</span>
              <p className="fw-bold font-14">{productItemDetails?.lineId}</p>
            </div>
            <div>
              <span>Quantity</span>
              <p className="fw-bold font-14">{productItemDetails?.quantity}</p>
            </div>
            {productItemDetails?.quantityRunOn !== 0 && productItemDetails?.quantityRunOn !== null && (
              <>
                <div>
                  <span>Runon Quantity</span>
                  <p className="fw-bold font-14">{productItemDetails?.quantityRunOn}</p>
                </div>
                {productItemDetails?.quantityActual !== 0 && productItemDetails?.quantityActual !== null && (
                  <div>
                    <span>Quantity Actual</span>
                    <p className="fw-bold font-14">{productItemDetails?.quantityActual}</p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="grid__container mt-3">
            <div>
              <span>Pages For Text</span>
              <p className="fw-bold font-14">{productItemDetails?.pagesForText}</p>
            </div>
            <div>
              <span>Pages For Cover</span>
              <p className="fw-bold font-14">{productItemDetails?.pagesForCover}</p>
            </div>
          </div>
          <div className="grid__container mt-3">
            <div>
              <span>Sealer On Text</span>
              <p className="fw-bold font-14">{productItemDetails?.sealerOnText}</p>
            </div>
            <div>
              <span>Sealer On Cover</span>
              <p className="fw-bold font-14">{productItemDetails?.sealerOnCover}</p>
            </div>
          </div>
          <div className="mt-3">
            <span>Colours On Text</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{coloursOnTextValue}</p>
          </div>
          <div className="mt-3">
            <span>Colours On Inside</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{coloursOnInsideValue}</p>
          </div>
          <div className="mt-3">
            <span>Colours On Outside</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{coloursOnOutsideValue}</p>
          </div>
          <div className="mt-3">
            <span>Finishing</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{finishingValue}</p>
          </div>
          <div className="mt-3">
            <span>Finished Size</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{finishedSizeValue}</p>
          </div>
          <div className="mt-3">
            <span>Material</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{materialValue}</p>
          </div>
          <div className="mt-3">
            <span>Material Weight</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{materialWeightValue}</p>
          </div>
          <div className="mt-3">
            <span>Cover Material</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{coverMaterialValue}</p>
          </div>
          <div className="mt-3">
            <span>Cover Material Weight</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{coverMaterialWeightValue}</p>
          </div>
          <div className="mt-3">
            <span>Addendum</span>
            <p className="fw-bold font-14 p_wrap">{productItemDetails?.addendum}</p>
          </div>
        </>
      ) : (
        <div className="border-bottom pt-10 pb-10">
          <div className="d-flex">
            {productItemDetails?.quantityRunOn === 0 || productItemDetails?.quantityRunOn === null ? (
              <div className="fare_details">
                <h6 className="h6_style">Quantity</h6>
                <span>{productItemDetails?.quantity}</span>
              </div>
            ) : (
              <div className="fare_details">
                <h6 className="h6_style">Quantity Actual</h6>
                <span>{productItemDetails?.quantityActual}</span>
              </div>
            )}
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Finished Size</h6>
            <span className="p_wrap">{finishedSizeValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Pages For Text</h6>
            <span>{productItemDetails?.pagesForText}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style ">Colours On Text</h6>
            <span>{coloursOnTextValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Sealer On Text</h6>
            <span>{productItemDetails?.sealerOnText}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style ">Material</h6>
            <span className="p_wrap">{materialValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Material Weight</h6>
            <span>{materialWeightValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Pages For Cover</h6>
            <span>{productItemDetails?.pagesForCover}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Colours On Inside</h6>
            <span>{coloursOnInsideValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style ">Colours On Outside</h6>
            <span>{coloursOnOutsideValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Sealer On Cover</h6>
            <span>{productItemDetails?.sealerOnCover}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Cover Material</h6>
            <span>{coverMaterialValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Cover Material Weight</h6>
            <span>{coverMaterialWeightValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Finishing</h6>
            <span className="p_wrap">{finishingValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Addendum</h6>
            <span className="p_wrap">{productItemDetails?.addendum != null ? productItemDetails?.addendum : '-'}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default BookletDetails;
