import React, { useState, useEffect } from 'react';
import './style.sass';

import { getOtherJobCostTableList, getOtherCostList, selectProductItemDetailsRows } from './slice/selectors';
import { ReactComponent as ConfirmationWarningIcon } from '../../../assets/icons/confirmationWarning.svg';
import { getAccountId, getIsClient } from '../../components/modules/sideBar/slice/selectors';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';
import { ReactComponent as ClientQuoteIcon } from '../../../images/clientQuote.svg';
import { selectDropDown } from '../../pages/adminstrationAccount/slice/selector';
import { CONFIRMATION, ERROR, SUCCESS } from 'app/components/shared/Constants';
import { useInvoiceSlice } from '../../pages/adminstrationAccount/slice/index';
import { getJobInformation } from '../../../store/reducers/entities/selectors';
import ClientQuoteConfirmation from './quotes/ClientQuoteConfirmation';
import { protectedResources } from '../../../configs/msalAuthConfigs';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CustomSelect from 'app/components/shared/CustomSelect';
import { setJobInformation } from 'store/reducers/entities';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiCallBegan } from 'store/reducers/loading';
import { Button, Modal, Form } from 'react-bootstrap';
import { useRequestForQuoteSlice } from './slice';
import { OtherCostsList } from './OtherCostsList';
import { ProductItemType } from 'types/myJobs';
import { useParams } from 'react-router-dom';
import MODULES from '../../../data/modules';
import { useForm } from 'react-hook-form';
import { request } from 'utils/request';
import API from '../../../data/api';
import * as yup from 'yup';

type SubmitModal = {
  show: boolean;
  type: string;
  header: string;
  bodyTitle: string;
  bodyContent: ProductItemType[];
  showFooterButtons: boolean;
};
export interface InitialInputState {
  rateItemId: number;
  name: string;
  hours: number;
  rate: number;
  netValue: number;
  vatRate: number;
  vatAmount: number;
  grossValue: number;
}

const OtherCosts = props => {
  const productItemDetailsRows = useSelector(selectProductItemDetailsRows);
  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));
  const otherJobCostTableList = useSelector(getOtherJobCostTableList);
  const otherCostList = useSelector(getOtherCostList);
  const getDropdowns = useSelector(selectDropDown);
  const isClient = useSelector(getIsClient);
  const dispatch = useDispatch();
  const checkOtherCostList = otherCostList.data?.items.length === 0 || jobInformation?.statusName === 'Cancelled';

  const [clientQuoteCreationModal, setClientQuoteCreationModal] = useState(false);
  const [quoteBasket, setQuoteBasket] = useState<null | boolean>(null);
  const [showQuoteBasketModal, setShowQuoteBasketModal] = useState(false);
  const [addOtherCost, setAddOtherCost] = useState<null | boolean>(null);
  const [clientQuoteModal, setClientQuoteModal] = useState(false);
  const [checkedValues, setCheckValues] = useState<any>(false);
  const [editOtherCosts, setEditOtherCosts] = useState<any>();
  const [modalAddCost, setModalAddCost] = useState(false);
  const { actions: accountAction } = useInvoiceSlice();
  const [invoicedId, setInvoicedId] = useState<any>();
  const [modalShow, setModalShow] = useState(false);
  const [newRfqRow, setNewRfqRow] = useState<any>();
  const [rateItem, setRateItem] = useState<any>();
  const { actions } = useRequestForQuoteSlice();
  const { jobId } = useParams();

  const customStyles = {
    border: 0,
  };

  const validationSchema = yup.object({
    name: yup.string().trim().required('Please provide a name'),
    description: yup.string().trim().nullable(),
    hours: yup
      .number()
      .typeError('Please add the hours for this other cost')
      .transform(value => (isNaN(value) ? null : value))
      .required('Please add the hours for this other cost'),
    netValue: yup
      .number()
      .typeError('Please add the sell rate for this other cost')
      .transform(value => (isNaN(value) ? null : value))
      .required('Please add the sell rate for this other cost'),
    vatRate: yup
      .number()
      .typeError('Please select the VAT rate')
      .transform(value => (isNaN(value) ? null : value))
      .required('Please select the VAT rate'),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const getInvoicedId = data => {
    setInvoicedId(data);
  };

  const getEditOtherCosts = data => {
    setEditOtherCosts(data);
    setAddOtherCost(false);
  };

  useEffect(() => {
    if (jobId) reset({ netValue: rateItem?.sellRate ? rateItem?.sellRate?.toFixed(2) : null });
  }, [editOtherCosts, rateItem, jobId, reset]);
  useEffect(() => {
    if (editOtherCosts) {
      setModalAddCost(true);
      reset({ ...editOtherCosts });
    }
  }, [editOtherCosts]);

  const onSubmit = data => {
    const payload = {
      inputs: {
        rateItemId: rateItem ? rateItem?.id : null,
        id: editOtherCosts ? editOtherCosts?.id : null,
        name: data?.name ? data?.name : null,
        description: data?.description ? data?.description : null,
        hours: data?.hours ? data?.hours : null,
        netValue: rateItem?.sellRate ? rateItem?.sellRate : data?.netValue,
        vatRate: data?.vatRate ? data?.vatRate : null,
      },
      rfqJobId: jobId,
    };
    if (addOtherCost) {
      try {
        request(`${protectedResources.api.apiEndpoint}/RFQ/CreateOtherJobCosts/${jobId}`, {
          method: 'POST',
          body: JSON.stringify(payload.inputs),
        }).then(res => {
          dispatch(actions.addNewOtherCostRow({ ...res }));
        });
      } catch (responseError) {
        console.log('Error while submitting the form ' + responseError);
      }
    } else {
      try {
        request(`${protectedResources.api.apiEndpoint}/RFQ/UpdateOtherJobCosts`, {
          method: 'put',
          body: JSON.stringify(payload.inputs),
        });
      } catch (responseError) {
        console.log('Error while submitting the form ' + responseError);
      }
      dispatch(actions.editOtherCostRow({ inputs: payload?.inputs, id: editOtherCosts?.id }));
    }
    dispatch(actions.loadRfqDataForOtherCost(jobId));
    setModalShow(false);
    setModalAddCost(false);
    reset();
  };
  const tabsErrorPopup: SubmitModal = {
    show: false,
    type: ERROR,
    header: 'Error',
    bodyTitle: '',
    bodyContent: [],
    showFooterButtons: true,
  };
  /*  Submit Modal  */
  const initialSubmitModal: SubmitModal = {
    show: false,
    type: CONFIRMATION,
    header: 'Confirmation',
    bodyTitle: 'Are you sure you want to submit these items?',
    bodyContent: [],
    showFooterButtons: false,
  };
  const [submitModal, setSubmitModal] = useState(initialSubmitModal);
  /******************************************************************************************
  @Purpose    :   Submit Modal - To hide submit modal
  *******************************************************************************************/
  const hideSubmitPopUpHandler = () => {
    setSubmitModal({ ...initialSubmitModal, show: false });
  };

  const handleClientQuote = async () => {
    if (otherJobCostTableList?.filter(item => item.status?.code === 'NewRFQ').length > 0) {
      setClientQuoteCreationModal(true);
    } else {
      setSubmitModal({ ...tabsErrorPopup, show: true, bodyTitle: 'Please only select Other Costs which are in New RFQ Status.' });
    }
  };
  const confirmClientQuotesHandler = async () => {};
  const handleQuoteBasketModal = () => {
    setShowQuoteBasketModal(false);
    //  Update job information
    dispatch(apiCallBegan({ url: API.UPDATE_JOB, body: { ...jobInformation, quoteBasket }, method: 'put', onSuccess: setJobInformation.type, module: MODULES.MY_JOBS }));
    clientQuoteConfirmationHandler();
  };
  const handleClientQuoteConfirmationHandler = () => {
    // setClientQuoteModal(true);
    if (jobInformation.quoteBasket === null) setShowQuoteBasketModal(true);
    else clientQuoteConfirmationHandler();
    setClientQuoteCreationModal(false);
  };

  const clientQuoteConfirmationHandler = () => {
    const jobQuoteIds = productItemDetailsRows
      .map(item => item.details.jobQuotes)
      .filter(item => item.length > 0)
      .map(item => item.filter(item => item.jobQuoteSummary.checked))
      .filter(item => item.length > 0)
      .map(item => item[0].jobQuoteSummary.jobQuoteId);
    dispatch(actions.loadClientQuoteConfirmationPopUp({ rfqJobId: jobId, jobQuoteIds }));
    setClientQuoteModal(true);
  };

  const handleReadyToInvoice = async () => {
    const allInvoiced = newRfqRow?.map(item => item?.id);
    const formData = {
      rfqJobId: Number(jobId),
      otherJobCosts: invoicedId.length > 0 ? [...invoicedId] : [...allInvoiced],
    };
    try {
      await request(`${protectedResources.api.apiEndpoint}/RFQ/UpdateOtherJobCostsToReadyToInvoice`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
    } catch (err) {}
    dispatch(actions.loadOtherCostRateItems(jobInformation.accountId));
  };

  useEffect(() => {
    const newRfqData = otherJobCostTableList?.filter(item => item?.status?.name === 'New RFQ' && item);
    setNewRfqRow(newRfqData);
    dispatch(actions.loadOtherCostRateItems(jobInformation.accountId));
    dispatch(accountAction.loadDropDowns());
  }, []);
  return (
    <React.Fragment>
      <>
        <>
          <Modal
            backdrop="static"
            centered
            keyboard={false}
            onHide={() => {
              hideSubmitPopUpHandler();
            }}
            show={submitModal.show}
          >
            <Modal.Header closeButton className="border-0">
              <Modal.Title>{submitModal.header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  {(submitModal.type === CONFIRMATION || submitModal.type === ERROR) && <ConfirmationWarningIcon />}
                  {submitModal.type === SUCCESS && <SuccessIcon />}
                  <span>{submitModal.bodyTitle}</span>
                </div>
                {submitModal.bodyContent && (
                  <ol>
                    {submitModal.bodyContent.map((row, index) => (
                      <li key={index}>
                        {row.printSpecifications.title} - {row.printSpecifications.productType}
                      </li>
                    ))}
                  </ol>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {submitModal.showFooterButtons ? (
                <Button
                  className="full__button"
                  onClick={() => {
                    hideSubmitPopUpHandler();
                  }}
                  variant="primary"
                >
                  OK
                </Button>
              ) : (
                <>
                  <Button
                    className="outline__button_productConfirmation"
                    onClick={() => {
                      hideSubmitPopUpHandler();
                    }}
                    variant="outline-primary"
                  >
                    No
                  </Button>
                  <Button
                    className="outline__button_productConfirmation"
                    onClick={() => {
                      confirmClientQuotesHandler();
                    }}
                    variant="outline-primary"
                  >
                    Yes
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
          <Modal backdrop="static" show={showQuoteBasketModal} centered onHide={() => setShowQuoteBasketModal(false)}>
            <div>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Quote Confirmation</Modal.Title>
              </Modal.Header>
            </div>
            <Modal.Body>
              <div className="popupModal__bodyContainer">
                <div className="body__titleContainer mb-2">
                  <span className="body__title">Is this a Quote Basket?</span>
                </div>
                <div>
                  <Form.Check name="quoteBasket" id="form__quoteBasket-yes" inline label="Yes" type="radio" onClick={() => setQuoteBasket(true)} />
                </div>
                <div>
                  <Form.Check name="quoteBasket" id="form__quoteBasket-no" inline label="No" type="radio" onClick={() => setQuoteBasket(false)} />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button key="NO" variant="outline-primary" className="outline__button" onClick={() => setShowQuoteBasketModal(false)}>
                Cancel
              </Button>
              <Button key="YES" variant="primary" className="full__button" onClick={() => handleQuoteBasketModal()}>
                Save & Proceed
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal backdrop="static" show={clientQuoteCreationModal} centered onHide={() => setClientQuoteCreationModal(false)}>
            <div>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Client Quote</Modal.Title>
              </Modal.Header>
            </div>
            <Modal.Body>
              <div className="popupModal__bodyContainer">
                <div className="body__titleContainer mb-2">
                  <span className="body__title">Are you sure you want to create client quotes from the following?</span>
                </div>
                {otherJobCostTableList && otherJobCostTableList?.filter(item => item?.status?.code === 'NewRFQ').length > 0 && (
                  <>
                    <ol className="body__contentContainer mt-3">
                      {otherJobCostTableList?.map((row, index) => (
                        <li key={index}>{row?.name}</li>
                      ))}
                    </ol>
                    <ol>
                      <Form.Check type="checkbox" label="Do not send an email notification" onChange={e => setCheckValues(true)} />
                    </ol>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button key="NO" variant="outline-primary" className="outline__button" onClick={() => setClientQuoteCreationModal(false)}>
                No
              </Button>
              <Button key="YES" variant="primary" className="full__button" onClick={handleClientQuoteConfirmationHandler}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </>
      <>
        <ClientQuoteConfirmation
          show={clientQuoteModal}
          checkedValues={checkedValues}
          onHide={() => setClientQuoteModal(false)}
          productItemDetailsRows={productItemDetailsRows}
          productItemDetailsRowsConfirm={productItemDetailsRows}
        />
      </>
      {otherJobCostTableList?.length > 0 ? (
        <OtherCostsList isCancelled={checkOtherCostList} otherJobCostTableList={otherJobCostTableList} getEditOtherCosts={getEditOtherCosts} getInvoicedId={getInvoicedId}>
          <h4 className="left_header">Other Costs</h4>
          <div className="buttons__container">
            {!isClient && (
              <div className="buttons__container">
                {otherJobCostTableList?.map(item => item?.clientQuoteFlag).includes(true) && otherJobCostTableList?.filter(item => item.status?.code === 'NewRFQ').length > 0 && (
                  <p className={'header__button'} onClick={() => handleClientQuote()}>
                    Client Quote
                    <ClientQuoteIcon className="svg__icon" />
                  </p>
                )}
                {otherJobCostTableList?.map(item => item?.approveQuoteFlag).includes(true) && otherJobCostTableList?.filter(item => item.status?.code === 'Quotation').length > 0 && (
                  <p className={'header__button'}>
                    Approve Quote
                    <ClientQuoteIcon className="svg__icon" />
                  </p>
                )}
                {otherJobCostTableList?.map(item => item?.readyToInvoiceFlag).includes(true) && (
                  <p className={`header__button`} onClick={() => handleReadyToInvoice()}>
                    Ready To Invoice
                  </p>
                )}
                {!checkOtherCostList && (
                  <p
                    className={`header__button`}
                    onClick={() => {
                      setRateItem('');
                      setModalShow(true);
                      setAddOtherCost(true);
                    }}
                  >
                    + Add Other Costs
                  </p>
                )}
              </div>
            )}
          </div>
        </OtherCostsList>
      ) : (
        <div className="center_button">
          <div className="center_container">
            {!isClient && (
              <Button
                variant="primary"
                className="full__button"
                disabled={checkOtherCostList}
                style={{ paddingRight: '2rem', paddingLeft: '2rem', width: '200px' }}
                onClick={() => {
                  setRateItem('');
                  setModalShow(true);
                  setAddOtherCost(true);
                }}
              >
                Add Other Costs
              </Button>
            )}
            <span style={{ marginTop: '0.5rem' }}>You have no Other Costs yet</span>
            {!isClient && (
              <span>
                Click on <strong>'Add Other Costs'</strong>
              </span>
            )}
          </div>
        </div>
      )}
      <Modal backdrop="static" keyboard={false} show={modalShow} aria-labelledby="contained-modal-title-vcenter" backdropClassName="fullScreen" centered onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Select Other Costs</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_form">
          {otherCostList &&
            otherCostList?.data?.items.map(item => {
              return (
                <Form.Group className="mb-3 modal_form" controlId="formBasicDesign">
                  <Form.Check
                    type="radio"
                    id={item?.accountJobRateName}
                    label={item?.accountJobRateName}
                    className="radio"
                    name="rateItemId"
                    value={item?.accountJobRateName}
                    onChange={() => setRateItem(item)}
                  />
                </Form.Group>
              );
            })}
        </Modal.Body>
        <Modal.Footer className="modal_footer" style={customStyles}>
          <>
            <Button variant="outline-primary" onClick={() => setModalShow(false)} className="outline__button">
              Cancel
            </Button>
            <Button
              variant="outline-primary"
              disabled={!rateItem}
              onClick={() => {
                setModalAddCost(true);
                setModalShow(false);
              }}
              className="full__button"
            >
              Next
            </Button>
          </>
        </Modal.Footer>
      </Modal>
      <Modal backdrop="static" keyboard={false} show={modalAddCost} aria-labelledby="contained-modal-title-vcenter" backdropClassName="fullScreen" centered onHide={() => setModalAddCost(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <ArrowBackIosIcon
              onClick={() => {
                setRateItem('');
                setModalAddCost(false);
                setModalShow(true);
              }}
            />
            {rateItem?.name}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body className="modal_form">
            <Form.Group className="mb-3 modal_placeholder" controlId="formName">
              <Form.Label>
                Name<span className="input__required">*</span>
              </Form.Label>
              <Form.Control autoComplete="off" placeholder="Enter name" type="text" {...register('name')} />
              {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3 modal_placeholder" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} autoComplete="off" placeholder="Enter Description" type="text" {...register('description')} />
            </Form.Group>
            <div className="row">
              <div className="col">
                <Form.Group className="mb-3 text-clr modal_halfPlaceholder" controlId="formHours">
                  <Form.Label>
                    Hours<span className="input__required">*</span>
                  </Form.Label>
                  <Form.Control autoComplete="off" placeholder="Enter Hours" type="string" {...register('hours')} />
                  {errors.hours && <Form.Text className="text-danger">{errors.hours.message}</Form.Text>}
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group className="mb-3 modal_halfPlaceholder" controlId="formValueNet">
                  <Form.Label>Sell Rate (Net) {rateItem?.sellRate ? '' : <span className="input__required">*</span>}</Form.Label>
                  <Form.Control autoComplete="off" disabled={rateItem?.sellRate} placeholder="Enter value" type="string" {...register('netValue')} />
                  {errors.netValue && <Form.Text className="text-danger">{errors.netValue.message}</Form.Text>}
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Form.Label>
                  VAT Rate <span className="input__required">*</span>
                </Form.Label>
                <Form.Group style={{ gridColumn: '1', width: '218px' }}>
                  <CustomSelect
                    isDisabled={false}
                    styles={{ gridColumn: '1' }}
                    required={false}
                    controlId={'form__vatRate'}
                    label={''}
                    control={control}
                    name={'vatRate'}
                    placeholder={'Select'}
                    options={getDropdowns?.data?.vatRate?.map(item => {
                      return {
                        value: +item?.id,
                        label: item?.value,
                      };
                    })}
                    errors={errors.vatRate}
                    enableMultilineOptions={true}
                    isSearchable={true}
                    register={register}
                    value={getValues('vatRate')}
                    readOnlyFormField={false}
                  />
                </Form.Group>
              </div>
              <div className="col"></div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal_footer" style={customStyles}>
            <>
              <Button
                variant="outline-primary"
                onClick={() => {
                  setModalAddCost(false);
                }}
                className="outline__button"
              >
                Cancel
              </Button>
              <Button variant="outline-primary" type="submit" className="full__button">
                Add
              </Button>
            </>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default OtherCosts;
