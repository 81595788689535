import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from 'app/components/shared/CustomSelect';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { request } from 'utils/request';
import { protectedResources } from 'configs/msalAuthConfigs';
import { getSuppliersDeopDown } from '../../slice/selector';
import { useInvoiceSlice } from '../../slice';
import * as yup from 'yup';
export const AddSuppliersPopup = props => {
  const { onHide, show, suppliersData } = props;
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const sectorList = useSelector(getSuppliersDeopDown);
  const { actions: loadSuppliers } = useInvoiceSlice();
  const { actions: loadSectors } = useInvoiceSlice();
  const validationSchema = yup.object({
    supplierIds: yup.number().nullable(),
  });
  const [suppliersSector, setSuppliersSector] = useState<any>();
  const FilteredSuppliersData = suppliersData?.data?.items?.map(data => data?.supplierID);
  const FilteredSuppliers = suppliersSector?.data?.items.filter(item => !FilteredSuppliersData?.includes(item.supplierId));
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    // defaultValues: initialState,
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    setSuppliersSector(sectorList);
  }, [sectorList]);
  useEffect(() => {
    dispatch(loadSectors.loadSuppliersDropDownList());
  }, []);
  const onSubmit = async data => {
    const supId = data?.supplierIds;
    const formData = {
      accountId: Number(accountId),
      supplierIds: [supId],
      isRemove: false,
    };
    try {
      const res = await request(`${protectedResources.api.apiEndpoint}/AD/AddORRemoveAccountSupplier`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      accountId && dispatch(loadSuppliers.loadSuppliersList(+accountId));
    } catch (err) {}
    reset();
    onHide();
  };
  return (
    <>
      <Modal backdrop="static" keyboard={false} size="lg" show={show} aria-labelledby="contained-modal-title-vcEnter" backdropClassName="fullScreen" centered onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Add Supplier</h4>
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body style={{ marginLeft: '20px', height: '150px', marginRight: '20px' }}>
            <Form.Group style={{ width: '600px' }}>
              <CustomSelect
                isDisabled={false}
                required={false}
                controlId={'form__supplierIds'}
                label={'Select Supplier'}
                control={control}
                name={'supplierIds'}
                placeholder={'Search'}
                options={FilteredSuppliers?.map(item => {
                  return {
                    value: +item?.supplierId,
                    label: item?.supplierName,
                  };
                })}
                errors={undefined}
                enableMultilineOptions={false}
                isSearchable={false}
                register={register}
                value={getValues('supplierIds')}
                readOnlyFormField={false}
              />
            </Form.Group>
          </Modal.Body>
          <hr style={{ marginBottom: '0px' }} />
          <Modal.Footer>
            <Button variant="outline-primary" className="outline__button" onClick={() => onHide()}>
              Cancel
            </Button>
            <Button variant="primary" className="full__button" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
