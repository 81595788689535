import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBreadcrumbSlice } from '../breadcrumb/slice';
import { getInvoiceData } from './slice/selectors';
import Breadcrumb from '../breadcrumb';
import './style.sass';
import { InvoiceSelector } from './InvoicsRows';
import { InvoiceBody } from './InvoiceBody';
import { useInvoiceSlice } from './slice';
import { accounts, GetReadyToInvoice } from './slice/types';

const Invoice = () => {
  const invoiceRows = useSelector(getInvoiceData);
  const dispatch = useDispatch();
  const { actions: breadcrumbActions } = useBreadcrumbSlice();
  const { actions: invoiceActions } = useInvoiceSlice();
  const [invoice, setInvoice] = useState<any>();
  const [invoiceWeekly, setInvoiceWeekly] = useState([]);
  const [invoiceAccount, setInvoiceAccount] = useState<accounts[]>([]);
  const [invoiceFooter, setInvoiceFooter] = useState({});
  const [invoiceRow, setInvoiceRow] = useState<GetReadyToInvoice[]>([]);
  const [accountFilter, setAccountFilter] = useState<any>();
  const [FrequencyFilter, setFrequencyFilter] = useState<any>();

  const getData = data => {
    setAccountFilter(data);
  };

  const getFrequencyData = data => {
    setFrequencyFilter(data);
  };

  useEffect(() => {
    dispatch(invoiceActions.loadInvoice());
    dispatch(
      breadcrumbActions.changeBreadcrumb([
        { name: 'Dashboard', url: '/dashboard' },
        { name: 'Review ready to invoice jobs', url: '/readytoinvoice' },
      ]),
    );
  }, [breadcrumbActions, dispatch, invoiceActions]);

  useEffect(() => {
    const rfqs = invoiceRows;
    setInvoice(rfqs);
    setInvoiceRow(rfqs.getReadytoInvoice);
    setInvoiceFooter(rfqs.totalInvoiceSummary);
    setInvoiceWeekly(rfqs.frequencys);
    setInvoiceAccount(rfqs.accounts);
  }, [invoiceRows]);

  return (
    <div className="myJobs__container">
      <Breadcrumb />
      <InvoiceSelector getFrequencyData={getFrequencyData} getData={getData} invoice={invoice} invoiceWeekly={invoiceWeekly} invoiceAccount={invoiceAccount} />
      <InvoiceBody FrequencyFilter={FrequencyFilter} filter={accountFilter} invoiceFooter={invoiceFooter} invoiceRow={invoiceRow} />
    </div>
  );
};

export default Invoice;
