//  Internals
import { createSelector } from '@reduxjs/toolkit';
//  Externals
import { RootState } from '../../../../store/model';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.accounts || initialState;
//Navigation Selectors
export const selectNavigation = createSelector([selectSlice], allAccounts => allAccounts.navigation);
//DropDown Selectors
export const selectDropDown = createSelector([selectSlice], allAccounts => allAccounts.dropDown);
//All accounts
export const getAccountData = createSelector([selectSlice], allAccounts => allAccounts.accountData);
//Edit accounts
export const getEditAccountData = createSelector([selectSlice], allAccounts => allAccounts.editAccountData);
//Product Data
export const grtProductData = createSelector([selectSlice], allAccounts => allAccounts.productData);
//Product Data List
export const grtProductList = createSelector([selectSlice], allAccounts => allAccounts.productDetailList);
//Suppliers Data
export const getSuppliersList = createSelector([selectSlice], allAccounts => allAccounts.supplierList);
//Suppliers Data
export const getSuppliersDeopDown = createSelector([selectSlice], allAccounts => allAccounts.supplierDropDownList);
//Business Units Data
export const getBusinessUnitsList = createSelector([selectSlice], allAccounts => allAccounts.BusinessUnitList);
//Business Units Data
export const getEditBusinessUnitsData = createSelector([selectSlice], allAccounts => allAccounts.EditBusinessUnitList);
//Job Rate Data
export const getJobRateList = createSelector([selectSlice], allAccounts => allAccounts.JobrateList);
//EditJob Rate Data
export const getEditJobRateList = createSelector([selectSlice], allAccounts => allAccounts.EditJobrateList);
//MasterData discount
export const getMasterData = createSelector([selectSlice], allAccounts => allAccounts.masterData);
//selectApprovers Deccount
export const getSelectApprovers = createSelector([selectSlice], allAccounts => allAccounts.selectApprovers);
//selectAccountService DD
export const getAccountService = createSelector([selectSlice], allAccounts => allAccounts.selectAccountService);
//selectAccount Users DD
export const getAccountUsers = createSelector([selectSlice], allAccounts => allAccounts.accountUsers);
