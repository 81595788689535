import React, { useState, useEffect } from 'react';

import { TableCell, TableContainer, Table, TableHead, TableBody, TableRow, Paper } from '@mui/material';
import { ReactComponent as AddProductItemIcon } from '../../../../../images/addProductItem.svg';
import { getBusinessUnitsList } from '../../slice/selector';
import { useSelector, useDispatch } from 'react-redux';
import { EditBusinessUnit } from './EditBusinessUnit';
import { useInvoiceSlice } from '../../slice';
import { useParams } from 'react-router-dom';

export const BusinessUnitList = props => {
  const { openBusinessUnitPopup } = props;
  const dispatch = useDispatch();
  const BusinessUnits = useSelector(getBusinessUnitsList);
  const { actions: businessAction } = useInvoiceSlice();
  const { actions: accountAction } = useInvoiceSlice();
  const { accountId } = useParams();
  const [editBusinessUnitPopup, setEditBusinessUnitPopup] = useState(false);
  const [editBusinessUnitId, setEditBusinessUnitId] = useState<number>();
  const [businessUnitData, setBusinessUnitsData] = useState<any>();

  const handleEdit = Id => {
    setEditBusinessUnitId(Id);
    setEditBusinessUnitPopup(true);
  };

  useEffect(() => {
    setBusinessUnitsData(BusinessUnits);
  }, [BusinessUnits]);

  useEffect(() => {
    dispatch(accountAction.loadDropDowns());
    accountId && dispatch(businessAction.loadBusinessUnits(+accountId));
  }, [accountAction, accountId, businessAction, dispatch]);

  return (
    <div>
      <EditBusinessUnit show={editBusinessUnitPopup} onHide={() => setEditBusinessUnitPopup(false)} businessUnitId={editBusinessUnitId} />
      <div className="productsPage__header">
        <span>Business Units</span>
        <span className="header__button" onClick={() => openBusinessUnitPopup()}>
          Add Business Unit
          <AddProductItemIcon className="svg__icon" />
        </span>
      </div>
      <div className="products__content">
        <TableContainer component={Paper} sx={{ borderRadius: '0', boxShadow: 'none' }}>
          <Table aria-label="collapsible table" className="productItem__details__table">
            <TableHead>
              <TableRow>
                <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '1rem' }}>
                  Business unit Name
                </TableCell>
                <TableCell className="productItem__details__headerCell">Users</TableCell>
                <TableCell className="productItem__details__headerCell">Reference</TableCell>
                <TableCell className="productItem__details__headerCell">Analysis Category</TableCell>
                <TableCell className="productItem__details__headerCell">status</TableCell>
                <TableCell className="productItem__details__headerCell" align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {businessUnitData &&
                businessUnitData?.data?.map(item => (
                  <TableRow key={item.businessUnitId} onClick={() => handleEdit(item.businessUnitId)} style={{ cursor: 'pointer' }}>
                    <TableCell className="productItem__details__headerCell" style={{ paddingLeft: '1rem' }}>
                      {item.name}
                    </TableCell>
                    <TableCell className="productItem__details__headerCell">{item.usersCount}</TableCell>
                    <TableCell className="productItem__details__headerCell">{item.reference}</TableCell>
                    <TableCell className="productItem__details__headerCell">{item.analsysCategoryName}</TableCell>
                    <TableCell className="productItem__details__headerCell">{item?.isActive ? 'Active' : 'Inactive'}</TableCell>
                    <TableCell className="productItem__details__headerCell table__edit" align="center">
                      <span style={{ marginRight: '20px', color: '#1BA39C' }}>Edit</span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
