//  Externals
import Select, { components, DropdownIndicatorProps, GroupBase } from 'react-select';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import RCPagination from 'rc-pagination';
import React, { useState } from 'react';
//  Internals
import { getEntities } from './../../../store/reducers/entities/selectors';
import { setLimit, setPage } from 'store/reducers/entities';
import './Pagination.sass';

type PaginationProps = {
  module: string;
  name: string;
};

type Number = {
  value: number;
  label: string;
};

//  Component starts
const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const { module, name } = props;
  //	Redux-store
  const dispatch = useDispatch();
  const { page, limit, totalCount } = useSelector(getEntities(module));
  const [value, setValue] = useState<number>(20);
  const numbers: Number[] = [
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 50, label: '50' },
  ];

  /******************************************************************************************
  @Purpose    :   React Select down and up arrows.
  *******************************************************************************************/
  const DropdownIndicator = (props: JSX.IntrinsicAttributes & DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>) => {
    return <components.DropdownIndicator {...props}>{props.selectProps.menuIsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</components.DropdownIndicator>;
  };

  /******************************************************************************************
  @Purpose    :   To get the NEXT and PREVIOUS icons
  *******************************************************************************************/
  const PrevNextArrow = (current: number, type: string, originalElement: any) => {
    if (type === 'prev') {
      return (
        <button>
          <i className="fa fa-angle-double-left"></i>
        </button>
      );
    }
    if (type === 'next') {
      return (
        <button>
          <i className="fa fa-angle-double-right"></i>
        </button>
      );
    }
    return originalElement;
  };

  return totalCount > 20 ? (
    <>
      <div className="d-flex justify-content-between">
        <div className="mr-auto p-2">
          <div className="d-flex flex-row">
            <div className="p-2 align-self-center">
              <label>Show</label>
            </div>
            <div className="p-2 align-self-center">
              <Select
                components={{
                  DropdownIndicator,
                }}
                id="form__forecastYear"
                maxMenuHeight={175}
                menuPlacement="top"
                onChange={(number: any) => {
                  dispatch(setLimit({ module, limit: number.value }));
                  setValue(number.value);
                }}
                options={numbers}
                value={numbers.find(number => number.value === value) || 20}
              />
            </div>
            <div className="p-2 align-self-center">
              <label>{name} per page</label>
            </div>
          </div>
        </div>
        <div className="p-2 align-self-center">
          <RCPagination
            className="pagination-data"
            current={page} //  current page
            itemRender={PrevNextArrow}
            onChange={(number: number) => dispatch(setPage({ module, page: number }))} //  page change callback
            pageSize={limit} //  items per page
            total={totalCount} //  items total count
          />
        </div>
      </div>
      <div></div>
    </>
  ) : (
    <></>
  );
};

export default Pagination;
