//  Externals
import { PayloadAction } from '@reduxjs/toolkit';
//  Internals
import { useInjectReducer } from '../../../utils/redux-injectors';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { Status, initialState } from './types';

const slice = createSlice({
  name: 'statusList',
  initialState,
  reducers: {
    setStatusList: (statusList, action: PayloadAction<{ items: Status[] }>) => {
      statusList = [...action.payload.items];
    },
  },
});

export default slice.reducer;

export const { setStatusList } = slice.actions;

export const useStatusListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
