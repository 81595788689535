import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import Select, { components } from 'react-select';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './CustomSelect.sass';

const DropdownIndicator = props => {
  return <components.DropdownIndicator {...props}>{props.selectProps.menuIsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</components.DropdownIndicator>;
};

const MultilineOption = props => {
  return (
    <>
      <components.Option {...props} className="search__select">
        <span>{props.data.label}</span>
        <span>{props.data.mail}</span>
      </components.Option>
    </>
  );
};

const SingleOption = props => {
  return (
    <>
      <components.Option {...props} className="search__select">
        <span>{props.data.label}</span>
      </components.Option>
    </>
  );
};

type option = {
  value: string | number;
  label: string;
  mail?: string | null;
  phoneNumber?: string;
};
type AppProps = {
  controlId: string;
  label: string;
  control: Control<any>;
  name: string;
  placeholder: string;
  options: option[];
  errors: FieldError | undefined;
  enableMultilineOptions: boolean;
  isSearchable: boolean;
  isDisabled?: boolean;
  menuPlacementTop?: boolean;
  onChange?: (e) => void;
  styles?: React.CSSProperties | undefined;
  required?: boolean;
  defaultValue?: number | string | undefined;
  register?: UseFormRegister<any>;
  otherField?: {
    label: string;
    name: string;
    error: FieldError | undefined;
    value?: string;
    textCount?: number;
    style?: React.CSSProperties | undefined;
  };
  value?: string;
  readOnlyFormField?: boolean;
};
const CustomSelect = (props: AppProps) => {
  const [customSelectValue, setCustomSelectValue] = useState<string>(props.value ? props.options?.filter(option => option.value === props.value)?.[0]?.label : '');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: `${props.isDisabled && props.otherField ? '5px' : '15px'}`,
        ...props.styles,
      }}
    >
      {!props.readOnlyFormField ? (
        <Form.Group controlId={props.controlId} className="custom-select-group">
          {props.required ? (
            <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                {props.label}
                <span className="input__required">*</span>
              </span>
            </Form.Label>
          ) : (
            <Form.Label>{props.label}</Form.Label>
          )}
          <Controller
            control={props.control}
            name={props.name}
            render={({ field }) => (
              <label className={`'form-select custom-select' ${props.errors && 'border-danger-select'} ${props.isDisabled && 'custom-select-disabled'}`}>
                <Select
                  ref={field.ref}
                  classNamePrefix="custom-select-input"
                  components={{
                    DropdownIndicator,
                    Option: props.enableMultilineOptions ? MultilineOption : SingleOption,
                  }}
                  isSearchable={props.isSearchable}
                  isClearable
                  menuPlacement={props.menuPlacementTop ? 'top' : 'bottom'}
                  isDisabled={props.isDisabled}
                  placeholder={props.placeholder}
                  options={props.options}
                  value={props.options?.find(val => val.value === field.value) || null}
                  onChange={(val, { action }) => {
                    field.onChange(val?.value);
                    props.onChange?.(val?.value);
                    action === 'clear' && field.onChange(null);
                    setCustomSelectValue(val?.label.trim().toLowerCase() || '');
                  }}
                  defaultValue={undefined}
                  maxMenuHeight={175}
                />
              </label>
            )}
          />
          {props.errors && <Form.Text className="text-danger">{props.errors.message}</Form.Text>}
        </Form.Group>
      ) : (
        <Form.Group>
          <Form.Label>
            {props.label}
            {props.required && <span className="input__required">*</span>}
          </Form.Label>
          <Form.Control type="text" disabled value={customSelectValue} />
        </Form.Group>
      )}
      {customSelectValue && customSelectValue.trim().toLowerCase() === 'other' && props.otherField && !props.isDisabled && (
        <Form.Group>
          <Form.Label className="other__filed_form_label" style={props.otherField?.style}>
            {props.otherField?.label}
            <span className="input__required">*</span>
            {props.otherField?.textCount === 0 && <span className="count_textArea">{props.otherField.textCount}/1000</span>}
          </Form.Label>
          {props.otherField.textCount === 0 ? (
            <Form.Control as="textarea" rows={3} {...props.register?.(props.otherField?.name)} className={`${props.otherField?.error && 'border-danger'}`} style={props.otherField?.style} />
          ) : (
            <Form.Control type="text" className={props.otherField?.error && 'border-danger'} {...props.register?.(props.otherField?.name)} />
          )}

          {props.otherField?.error && <Form.Text className="text-danger">{props.otherField?.error.message}</Form.Text>}
        </Form.Group>
      )}
      {props.isDisabled && props.otherField && <span>{props.otherField.value}</span>}
    </div>
  );
};

export default CustomSelect;
