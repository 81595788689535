//  For Internet Explorer 9
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
//  Externals
import reportWebVitals from 'reportWebVitals';
import ReactDOM from 'react-dom';
import React from 'react';
// Internals
import App from './App';
// Use consistent styling
import 'react-datepicker/dist/react-datepicker.css';
import 'react-awesome-time-picker/assets/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'sanitize.css/sanitize.css';
import './app/style.sass';
//  Microsoft Authentication Library for JavaScript (MSAL.js) 2.0 for Browser-Based Single-Page Applications
import { PublicClientApplication } from '@azure/msal-browser';
//  Microsoft Authentication Library for React
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './configs/msalAuthConfigs';
// Initialize languages
import './locales/i18n';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </MsalProvider>,
  MOUNT_NODE,
);

// Hot reload translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
