//  Externals
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
//  Internals
import { getMasterData } from './../../../../../store/reducers/masterData/selectors';
import VALIDATIONS from './../../../../../data/validations';

const LetterheadDetails = props => {
  const { productItemDetails, type } = props;

  const coloursOnReverses = useSelector(getMasterData('coloursOnReverses'));
  const materialWeights = useSelector(getMasterData('materialWeights'));
  const coloursOnFaces = useSelector(getMasterData('coloursOnFaces'));
  const finishedSizes = useSelector(getMasterData('finishedSizes'));
  const finishings = useSelector(getMasterData('finishings'));
  const materials = useSelector(getMasterData('materials'));

  const [coloursOnReverseValue, setColoursOnInsideValue] = useState('-');
  const [materialWeightValue, setMaterialWeightValue] = useState('-');
  const [coloursOnFaceValue, setColoursOnFaceValue] = useState('-');
  const [finishedSizeValue, setFinishedSizeValue] = useState('-');
  const [finishingValue, setFinishingValue] = useState('-');
  const [materialValue, setMaterialValue] = useState('-');

  useEffect(() => {
    const { coloursOnFaceId, coloursOnReverseId, finishedSizeId, finishingId, materialId, materialWeightId } = productItemDetails;
    //  Colours On Face Other
    const coloursOnFace = coloursOnFaces.find(entity => entity.value === coloursOnFaceId);
    if (coloursOnFace && coloursOnFace.label === VALIDATIONS.OTHER) setColoursOnFaceValue(productItemDetails.coloursOnFaceOther);
    else setColoursOnFaceValue(coloursOnFace.label);
    //  Colours On Reverse Other
    const coloursOnReverse = coloursOnReverses.find(entity => entity.value === coloursOnReverseId);
    if (coloursOnReverse && coloursOnReverse.label === VALIDATIONS.OTHER) setColoursOnInsideValue(productItemDetails.coloursOnReverseOther);
    else setColoursOnInsideValue(coloursOnReverse.label);
    //  Finished Size Other
    const finishedSize = finishedSizes.find(entity => entity.value === finishedSizeId);
    if (finishedSize && finishedSize.label === VALIDATIONS.OTHER) setFinishedSizeValue(productItemDetails.finishedSizeOther);
    else setFinishedSizeValue(finishedSize.label);
    //  Finishing Other
    const finishing = finishings.find(entity => entity.value === finishingId);
    if (finishing && finishing.label === VALIDATIONS.OTHER) setFinishingValue(productItemDetails.finishingOther);
    else setFinishingValue(finishing.label);
    //  Material Other
    const material = materials.find(entity => entity.value === materialId);
    if (material && material.label === VALIDATIONS.OTHER) setMaterialValue(productItemDetails.materialOther);
    else setMaterialValue(material.label);
    //  Material Weight Other
    const materialWeight = materialWeights.find(entity => entity.value === materialWeightId);
    if (materialWeight && materialWeight.label === VALIDATIONS.OTHER) setMaterialWeightValue(productItemDetails.materialWeightOther);
    else setMaterialWeightValue(materialWeight.label);
  }, [coloursOnFaces, coloursOnReverses, finishedSizes, finishings, materialWeights, materials, productItemDetails]);

  return (
    <>
      {type === 'clientQuote' ? (
        <>
          <div className="grid__container">
            <div>
              <span>Reference</span>
              <p className="fw-bold font-14">{productItemDetails?.lineId}</p>
            </div>
            <div>
              <span>Quantity</span>
              <p className="fw-bold font-14">{productItemDetails?.quantity}</p>
            </div>
            {productItemDetails?.quantityRunOn !== 0 && productItemDetails?.quantityRunOn !== null && (
              <>
                <div>
                  <span>Runon Quantity</span>
                  <p className="fw-bold font-14">{productItemDetails?.quantityRunOn}</p>
                </div>
                {productItemDetails?.quantityActual !== 0 && productItemDetails?.quantityActual !== null && (
                  <div>
                    <span>Quantity Actual</span>
                    <p className="fw-bold font-14">{productItemDetails?.quantityActual}</p>
                  </div>
                )}
              </>
            )}
            <div>
              <span>No Of Pages</span>
              <p className="fw-bold font-14">{productItemDetails?.noOfPages}</p>
            </div>
          </div>
          <div className="grid__container mt-3">
            <div>
              <span>Sealer On Face</span>
              <p className="fw-bold font-14">{productItemDetails?.sealerOnFace}</p>
            </div>
            <div>
              <span>Sealer On Reverse</span>
              <p className="fw-bold font-14">{productItemDetails?.sealerOnReverse}</p>
            </div>
          </div>
          <div className="mt-3">
            <span>Colours On Face</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{coloursOnFaceValue}</p>
          </div>
          <div className="mt-3">
            <span>Colours On Reverse</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{coloursOnReverseValue}</p>
          </div>
          <div className="mt-3">
            <span>Finishing</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{finishingValue}</p>
          </div>
          <div className="mt-3">
            <span>Finished Size</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{finishedSizeValue}</p>
          </div>
          <div className="mt-3">
            <span>Material</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{materialValue}</p>
          </div>
          <div className="mt-3">
            <span>Material Weight</span>
            <p className="fw-bold font-14 mt-1 p_wrap">{materialWeightValue}</p>
          </div>
          <div className="mt-3">
            <span>Addendum</span>
            <p className="fw-bold font-14 p_wrap">{productItemDetails?.addendum}</p>
          </div>
        </>
      ) : (
        <div className="border-bottom pt-10 pb-10">
          <div className="d-flex">
            {productItemDetails?.quantityRunOn === 0 || productItemDetails?.quantityRunOn === null ? (
              <div className="fare_details">
                <h6 className="h6_style">Quantity</h6>
                <span>{productItemDetails?.quantity}</span>
              </div>
            ) : (
              <div className="fare_details">
                <h6 className="h6_style">Quantity Actual</h6>
                <span>{productItemDetails?.quantityActual}</span>
              </div>
            )}
            <div className="fare_details">
              <h6 className="h6_style">No.Of Pages</h6>
              <span>{productItemDetails?.noOfPages}</span>
            </div>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Finished Size</h6>
            <span className="p_wrap">{finishedSizeValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Colours On Face</h6>
            <span className="p_wrap">{coloursOnFaceValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Colours On Reverse</h6>
            <span className="p_wrap">{coloursOnReverseValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Sealer On Face</h6>
            <span>{productItemDetails?.sealerOnFace}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Sealer On Reverse</h6>
            <span>{productItemDetails?.sealerOnReverse}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Material</h6>
            <span className="p_wrap">{materialValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Material Weight</h6>
            <span className="p_wrap">{materialWeightValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Finishing</h6>
            <span className="p_wrap">{finishingValue}</span>
          </div>
          <div className="fare_details">
            <h6 className="h6_style">Addendum</h6>
            <span className="p_wrap">{productItemDetails?.addendum != null ? productItemDetails?.addendum : '-'}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default LetterheadDetails;
