//  Externals
import { PayloadAction } from '@reduxjs/toolkit';
//  Internals
import { Account, BusinessUnit, ClientUser, RegistrationFormData, RoleType, Supplier, SupplierUser } from './types';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { RegistrationSaga } from './saga';

export const initialState: RegistrationFormData = {
  businessUnits: [],
  isLoading: true,
  error: null,
  roles: [],
  accounts: [],
  clientUsers: [],
  supplierUsers: [],
  suppliers: [],
};

const registrationSlice = createSlice({
  name: 'registrationFormData',
  initialState,
  reducers: {
    initialRegistrationState(state) {
      state.isLoading = true;
      state.error = null;
    },

    RegistrationFormBusinessUnitData: (state, action: PayloadAction<BusinessUnit[]>) => {
      state.businessUnits = action.payload;
    },

    RegistrationError(state, action: PayloadAction<{ error: string; loading: boolean }>) {
      state.error = action.payload.error;
      state.isLoading = action.payload.loading;
    },

    loadClientOrSupplierUser(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },

    setAccounts: (state, action: PayloadAction<Account[]>) => {
      state.accounts = action.payload;
    },

    setRoles: (state, action: PayloadAction<RoleType[]>) => {
      state.roles = action.payload;
    },

    setClientUsers(state, action: PayloadAction<ClientUser[]>) {
      state.clientUsers = action.payload;
      state.isLoading = false;
    },

    setSuppliers: (state, action: PayloadAction<Supplier[]>) => {
      state.suppliers = action.payload;
      state.isLoading = false;
    },

    setSupplierUsers(state, action: PayloadAction<SupplierUser[]>) {
      state.supplierUsers = action.payload;
      state.isLoading = false;
    },
  },
});

export const { actions: registrationActions, reducer } = registrationSlice;

export const useRegistrationSlice = () => {
  useInjectReducer({
    key: registrationSlice.name,
    reducer: registrationSlice.reducer,
  });
  useInjectSaga({ key: registrationSlice.name, saga: RegistrationSaga });
  return { actions: registrationSlice.actions };
};
