//  Externals
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { quotationSchema } from './validator';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import moment from 'moment';
//  Internals
import { getProductInformationData } from '../../../pages/requestForQuote/slice/selectors';
import { useRequestForQuoteSlice } from './../../../pages/requestForQuote/slice/index';
import { getIsCDS, getIsClient, getIsSupplier } from './../sideBar/slice/selectors';
import { getActionToggle, getJobInformation } from '../../../../store/reducers/entities/selectors';
import { ReactComponent as CalendarIcon } from '../../../../images/calendar.svg';
import LoadingSpinnerWithBackdrop from '../../shared/LoadingSpinnerWithBackdrop';
import { setPrintSpecifications } from '../../../../store/reducers/entities';
import { CONFIRMATION, OUTLINED, FULL, ERROR, DRAFT, NEWRFQ, READYTOINVOICE, INVOICED } from '../../shared/Constants';
import { DropDown, JobItemOption, QuotationProps } from './types';
import { allowNumbers } from './../../../../utils/form/index';
import PopupModal from 'app/components/shared/PopupModal';
import VALIDATIONS from '../../../../data/validations';
import PLACEHOLDER from '../../../../data/placeholder';
import callApi from './../../../../services/callApi';
import MODULES from '../../../../data/modules';
import API from '../../../../data/api';
import './style.sass';
import { apiCallBegan } from 'store/reducers/loading';
import { setJobData } from 'store/reducers/entities/index';

const Quotation = (props: QuotationProps) => {
  const { initialState, onHide, productId, show } = props;

  const dispatch = useDispatch();

  const { actions } = useRequestForQuoteSlice();

  const productInformationData = useSelector(getProductInformationData);
  const jobInformation = useSelector(getJobInformation(MODULES.MY_JOBS));
  const toggleAction = useSelector(getActionToggle(MODULES.MY_JOBS));
  const isSupplier = useSelector(getIsSupplier);
  const isClient = useSelector(getIsClient);
  const isCDS = useSelector(getIsCDS);

  const [jobItemOptionsList, setJobItemOptionsList] = useState<JobItemOption[]>([]);
  const [quantityActualList, setQuantityActualList] = useState<DropDown[]>([]);
  const [artworkFormatsList, setArtworkFormatsList] = useState<DropDown[]>([]);
  const [typeOfProofsList, setTypeOfProofsList] = useState<DropDown[]>([]);

  useEffect(() => {
    let { artworkFormat, jobItemOptions, typeOfProofs } = productInformationData;
    //  Art work format list
    setArtworkFormatsList(artworkFormat.map(item => ({ value: +item.value, label: item.name })));
    //  Job Options list
    let updateJobItemOptions = jobItemOptions.map(item => ({ value: +item.value, label: item.name, checked: false }));
    if (!isEmpty(initialState.jobItemOptions)) {
      updateJobItemOptions = jobItemOptions.map(item => ({ value: +item.value, label: item.name, checked: initialState.jobItemOptions.includes(+item.value) }));
      //  Show / Hide Target Date
      const complexJobEntity = updateJobItemOptions.find(jobOption => jobOption.label.trim() === 'Complex job' && jobOption.checked);
      if (complexJobEntity) setTargetDate(true);
      else setTargetDate(false);
    } else setTargetDate(false);
    setJobItemOptionsList(updateJobItemOptions);
    //  Quantity Actual list
    /******************************************************************************************
    Quantity Actual list if we have quantity run-on
                  > quantity
                  > quantity + quantityRunOn
                  > quantity + quantityRunOn + quantityRunOn
    *******************************************************************************************/
    if (initialState.quantity && initialState.quantityRunOn) {
      const quantityActual: DropDown[] = [];
      //  Initial Quantity
      quantityActual.push({ value: initialState.quantity, label: initialState.quantity.toString() });
      //  Initial Quantity + Quantity Run-On
      quantityActual.push({ value: initialState.quantity + initialState.quantityRunOn, label: (initialState.quantity + initialState.quantityRunOn).toString() });
      //  Initial Quantity + Quantity Run-On + Quantity Run-On
      quantityActual.push({
        value: initialState.quantity + initialState.quantityRunOn + initialState.quantityRunOn,
        label: (initialState.quantity + initialState.quantityRunOn + initialState.quantityRunOn).toString(),
      });
      //  Initial Quantity + Quantity Run-On + Quantity Run-On + Quantity Run-On
      quantityActual.push({
        value: initialState.quantity + initialState.quantityRunOn + initialState.quantityRunOn + initialState.quantityRunOn,
        label: (initialState.quantity + initialState.quantityRunOn + initialState.quantityRunOn + initialState.quantityRunOn).toString(),
      });
      setQuantityActualList(quantityActual);
    }
    //  Type of Proofs list
    setTypeOfProofsList(typeOfProofs.map(item => ({ value: +item.value, label: item.name })));
  }, [initialState.jobItemOptions, initialState.quantity, initialState.quantityRunOn, productInformationData]);

  /******************************************************************************************
  @Purpose    :  Use Form
  *******************************************************************************************/
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: initialState,
    resolver: yupResolver(
      quotationSchema({
        artworkFormatsList,
        jobItemOptionsList,
      }),
    ),
  });

  const onError = error => {
    console.log('ERROR:::', error);
  };

  //  Other Fields
  const [isNoFailDeliveryDateChecked, setIsNoFailDeliveryDateChecked] = useState(false);
  const [showProofOfDueDate, setShowProofOfDueDate] = useState(false);
  const [artworkFormatOther, setArtworkFormatOther] = useState(false);
  const [typeOfProofsOther, setTypeOfProofsOther] = useState(false);
  const [typeOfProofs, setTypeOfProofs] = useState(true);
  const [targetDate, setTargetDate] = useState(false);
  const [samples, setSamples] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { artworkFormatId, noFailDeliveryDate, samples, typeOfProofsId } = initialState;

    //  Artwork format Other
    const artworkFormat = artworkFormatsList.find(entity => entity.value === artworkFormatId);
    if (artworkFormat && artworkFormat.label === VALIDATIONS.OTHER) setArtworkFormatOther(true);
    else setArtworkFormatOther(false);
    //  No Fail Delivery Date
    if (noFailDeliveryDate) setIsNoFailDeliveryDateChecked(true);
    else setIsNoFailDeliveryDateChecked(false);
    //  Samples
    setSamples(samples === 'Yes' ? true : false);
    //  Type of Proofs Other
    if (typeOfProofsList.length) {
      const typeOfProof = typeOfProofsList.find(entity => entity.value === typeOfProofsId);
      if (typeOfProof && typeOfProof.label === VALIDATIONS.OTHER) setTypeOfProofsOther(true);
      else setTypeOfProofsOther(false);
      if (typeOfProof && typeOfProof.label !== VALIDATIONS.PROOF_NOT_REQUIRED) setShowProofOfDueDate(true);
      else setShowProofOfDueDate(false);
    } else {
      setTypeOfProofs(false);
      setTypeOfProofsOther(true);
      setShowProofOfDueDate(true);
    }
  }, [artworkFormatsList, initialState, typeOfProofsList]);

  /*  Counters  */
  //  Artwork Format Other
  const [artworkFormatOtherCounter, setArtworkFormatOtherCounter] = useState(0);
  //  Sample Copies Deliver Address
  const [sampleCopiesDeliveryAddressCounter, setSampleCopiesDeliveryAddressCounter] = useState(0);
  //  Deliver Instructions
  const [deliveryInstructionsCounter, setDeliveryInstructionsCounter] = useState(0);
  //  Delivery Address
  const [deliveryAddressCounter, setDeliveryAddressCounter] = useState(0);
  //  Description
  const [descriptionCounter, setDescriptionCounter] = useState(0);
  //  CDS Notes
  const [cdsNotesCounter, setCdsNotesCounter] = useState(0);
  //  Supplier Notes
  const [supplierNotesCounter, setSupplierNotesCounter] = useState(0);
  //  Client Notes
  const [clientNotesCounter, setClientNotesCounter] = useState(0);
  //  Reference
  const [referenceCounter, setReferenceCounter] = useState(0);
  //  Title
  const [titleCounter, setTitleCounter] = useState(0);
  //  Type Of Proofs Other
  const [typeOfProofsOtherCounter, setTypeOfProofsOtherCounter] = useState(0);

  useEffect(() => {
    const { artworkFormatOther, cdsNotes, clientNotes, deliveryAddress, deliveryInstructions, description, sampleCopiesDeliveryAddress, supplierNotes, title, typeOfProofsOther } = initialState;
    //  Artwork Format Other
    setArtworkFormatOtherCounter(artworkFormatOther?.length ? artworkFormatOther?.length : 0);
    //  CDS Notes
    setCdsNotesCounter(cdsNotes?.length ? cdsNotes.length : 0);
    //  Client Notes
    setClientNotesCounter(clientNotes?.length ? clientNotes.length : 0);
    //  Delivery Address
    setDeliveryAddressCounter(deliveryAddress?.length ? deliveryAddress.length : 0);
    //  Deliver Instructions
    setDeliveryInstructionsCounter(deliveryInstructions?.length ? deliveryInstructions.length : 0);
    //  Sample Copies Delivery Address
    setSampleCopiesDeliveryAddressCounter(sampleCopiesDeliveryAddress?.length ? sampleCopiesDeliveryAddress.length : 0);
    //  Description
    setDescriptionCounter(description?.length ? description.length : 0);
    //  Supplier Notes
    setSupplierNotesCounter(supplierNotes?.length ? supplierNotes.length : 0);
    //  Title
    setTitleCounter(title?.length ? title.length : 0);
    //  Type Of Proofs Other
    setTypeOfProofsOtherCounter(typeOfProofsOther?.length ? typeOfProofsOther?.length : 0);
  }, [initialState]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  /******************************************************************************************
  @Purpose    :  Handle Form Submit
  *******************************************************************************************/
  const onSubmit = async values => {
    setLoading(true);
    const requestBody = {
      ...initialState,
      ...values,
      productId,
      rfqjobId: jobInformation.jobId,
      isActive: values.isActive === 'Yes' ? true : false,
      laminated: values.laminated === 'Yes' ? true : false,
      samples: values.samples === 'Yes' ? true : false,
      noOfSampleCopies: values.samples === 'Yes' ? values.noOfSampleCopies : null,
      sampleCopiesDeliveryAddress: values.samples === 'Yes' ? values.sampleCopiesDeliveryAddress : null,
      sealerOnCover: values.sealerOnCover === 'Yes' ? true : false,
      sealerOnFace: values.sealerOnFace === 'Yes' ? true : false,
      sealerOnReverse: values.sealerOnReverse === 'Yes' ? true : false,
      sealerOnText: values.sealerOnText === 'Yes' ? true : false,
      artworkDueDate: values.artworkDueDate && moment(new Date(values.artworkDueDate)).format('YYYY-MM-DD'),
      deliveryDueDate: values.deliveryDueDate && moment(new Date(values.deliveryDueDate)).format('YYYY-MM-DD'),
      proofDueDate: values.proofDueDate && moment(new Date(values.proofDueDate)).format('YYYY-MM-DD'),
      targetDate: values.targetDate && moment(new Date(values.targetDate)).format('YYYY-MM-DD'),
      jobItemOptions: values.jobItemOptions.map((jobItemOption: string | number) => +jobItemOption),
    };
    try {
      //  Updating
      const response: any = await callApi({ url: `${API.UPDATE_PRODUCT_TEMPLATE}/${initialState.templateId}`, method: 'put', body: requestBody });
      if (response.isSuccess) {
        dispatch(setPrintSpecifications({ module: MODULES.MY_JOBS, printSpecifications: { ...response.data, productType: initialState.productType } }));
        dispatch(actions.loadRfqData({ jobId: jobInformation.jobId, toggleAction: toggleAction }));
      }
      setLoading(false);
      onHide();
    } catch (responseError) {
      setLoading(false);
      setErrorModal(true);
      console.log('Error while submitting the form ' + responseError);
    }
  };

  /******************************************************************************************
  @Purpose    :  Handle Drop down select for other fields show / hide
  *******************************************************************************************/
  const handleSelect = (name: string, val: DropDown | null) => {
    //  Artwork Format
    if (name === 'artworkFormatId') {
      if (val && val.label === VALIDATIONS.OTHER) {
        setArtworkFormatOther(true);
      } else {
        setValue('artworkFormatOther', null, {
          shouldValidate: false,
        });
        setArtworkFormatOther(false);
      }
    }
    //  Type Of Proofs
    if (name === 'typeOfProofsId') {
      //  Check option other is selected or not
      if (val && val.label === VALIDATIONS.OTHER) {
        setTypeOfProofsOther(true);
      } else {
        setValue('typeOfProofsOther', null, {
          shouldValidate: false,
        });
        setTypeOfProofsOther(false);
      }
      //  Check option Proof not required is selected or not
      if (val && val.label !== VALIDATIONS.PROOF_NOT_REQUIRED) setShowProofOfDueDate(true);
      else setShowProofOfDueDate(false);
    }
  };

  /******************************************************************************************
  @Purpose    :  Handle Samples
  *******************************************************************************************/
  const handleSamples = (value: boolean) => {
    setSamples(value);
    setValue('samples', value ? 'Yes' : 'No', { shouldValidate: false });
  };

  /******************************************************************************************
  @Purpose    :  Handle Job options change
  *******************************************************************************************/
  const handleJobItemChange = entity => {
    const updatedJobItemOptions = jobItemOptionsList.map(jobItemOption => {
      if (jobItemOption.value === entity.value) {
        return { ...jobItemOption, checked: !entity.checked };
      }
      return jobItemOption;
    });
    setJobItemOptionsList(updatedJobItemOptions);
    //  Show / Hide Target Date
    if (entity.label.trim() === 'Complex job') {
      setTargetDate(!entity.checked);
      setValue('targetDate', null, { shouldValidate: false });
    }
  };

  /******************************************************************************************
  @Purpose    :  Get Minimum Date
  *******************************************************************************************/
  const getMinimumDate = (value: string | number | Date | null): Date => {
    if (value && new Date() > new Date(value)) return new Date(value);
    return new Date();
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="fullScreen"
        centered
        dialogClassName="modal__printSpecificationForm"
        keyboard={false}
        onHide={() => {
          setShowConfirmModal(true);
        }}
        scrollable
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              {jobInformation.title} - {initialState.productType}
            </h2>
            <h3>{jobInformation.jobNumber}</h3>
          </Modal.Title>
        </Modal.Header>
        <hr style={{ margin: '0px' }} />
        <Modal.Body>
          {loading && <LoadingSpinnerWithBackdrop color="grey" />}
          <h6 className="form__subHeader top__subHeader">Print Specifications</h6>
          <Form className="printSpecifications__form" id="printSpecificationsForm-Quotation" onSubmit={handleSubmit(onSubmit, onError)}>
            <Form.Group controlId="form__title" style={{ gridColumn: '1 / 3' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>
                  Title<span className="input__required">*</span>
                </span>
                <span>{titleCounter}/100</span>
              </Form.Label>
              <Form.Control
                autoComplete="off"
                className={errors.title && 'border-danger'}
                maxLength={100}
                placeholder={PLACEHOLDER.TITLE}
                type="text"
                {...register('title')}
                onChange={e => setTitleCounter(e.target.value.length)}
              />
              {errors.title && <Form.Text className="text-danger">{errors.title.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__description" style={{ gridColumn: '1 / 3' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Description</span>
                <span>{descriptionCounter}/1000</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                autoComplete="off"
                className={errors.description && 'border-danger'}
                maxLength={1000}
                placeholder={PLACEHOLDER.DESCRIPTION}
                rows={3}
                type="text"
                {...register('description')}
                onChange={event => setDescriptionCounter(event.target.value.length)}
              />
              {errors.description && <Form.Text className="text-danger">{errors.description.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__reference" style={{ gridColumn: '1 / 2' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                Reference
                <span>{referenceCounter}/100</span>
              </Form.Label>
              <Form.Control
                autoComplete="off"
                maxLength={100}
                className={errors.reference && 'border-danger'}
                placeholder={PLACEHOLDER.REFERENCE}
                type="text"
                {...register('reference')}
                onChange={e => setReferenceCounter(e.target.value.length)}
              />
              {errors.reference && <Form.Text className="text-danger">{errors.reference.message}</Form.Text>}
            </Form.Group>
            {isCDS && (
              <Form.Group controlId="form__managementFee" style={{ gridColumn: '2/2' }}>
                <Form.Label>
                  Management Fee %<span className="input__required">*</span>
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  disabled={initialState?.quoteDate != null}
                  className={errors.managementFee && 'border-danger'}
                  placeholder={PLACEHOLDER.MANAGEMENTFEE}
                  type="text"
                  {...register('managementFee', {
                    valueAsNumber: true,
                  })}
                />
                {errors.managementFee && <Form.Text className="text-danger">{errors.managementFee.message}</Form.Text>}
              </Form.Group>
            )}
            <Form.Group controlId="form__quantity" style={{ gridColumn: '1' }}>
              <Form.Label>
                Quantity<span className="input__required">*</span>
              </Form.Label>
              <Form.Control
                autoComplete="off"
                className={errors.quantity && 'border-danger'}
                disabled
                placeholder={PLACEHOLDER.QUANTITY}
                type="number"
                {...register('quantity', {
                  valueAsNumber: true,
                })}
              />
              {errors.quantity && <Form.Text className="text-danger">{errors.quantity.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__quantityRunOn">
              <Form.Label>Quantity Run-On</Form.Label>
              <Form.Control autoComplete="off" className={errors.quantityRunOn && 'border-danger'} disabled placeholder={PLACEHOLDER.QUANTITY_RUN_ON} type="number" {...register('quantityRunOn')} />
              {errors.quantityRunOn && <Form.Text className="text-danger">{errors.quantityRunOn.message}</Form.Text>}
            </Form.Group>
            {initialState.quantityRunOn ? (
              <Form.Group controlId="form__quantityActual">
                <Form.Label>Quantity Actual</Form.Label>
                <Controller
                  control={control}
                  name="quantityActual"
                  render={({ field: { onBlur, onChange, ref, value } }) => {
                    return (
                      <Select
                        className={errors.quantityActual && 'border-danger-select'}
                        classNamePrefix="custom-select-input"
                        id="form__quantityActual"
                        isClearable
                        maxMenuHeight={175}
                        onBlur={onBlur} //  Notify when input is touched
                        onChange={(val, { action }) => {
                          if (action === 'clear') {
                            onChange(null);
                          } else {
                            onChange(val?.value);
                          }
                        }} // Send value to hook f = items.find(item => item.value = val);
                        options={quantityActualList}
                        placeholder={PLACEHOLDER.SELECT_QUANTITY_ACTUAL}
                        ref={ref}
                        value={quantityActualList.find(quantityActual => quantityActual.value === value)}
                      />
                    );
                  }}
                />
                {errors.quantityActual && <Form.Text className="text-danger">{errors.quantityActual.message}</Form.Text>}
              </Form.Group>
            ) : (
              <></>
            )}
            <h6 style={{ gridColumn: '1/4', marginTop: '30px' }}>Delivery Information</h6>
            <Form.Group controlId="form__deliveryContact" style={{ gridColumn: '1 / 3' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Delivery Contact</span>
              </Form.Label>
              <Form.Control autoComplete="off" className={errors.deliveryContact && 'border-danger'} placeholder={PLACEHOLDER.DELIVERY_CONTACT} type="text" {...register('deliveryContact')} />
              {errors.deliveryContact && <Form.Text className="text-danger">{errors.deliveryContact.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__deliveryAddress" style={{ gridColumn: '1/3' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Delivery Address</span>
                <span>{deliveryAddressCounter}/1000</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                autoComplete="off"
                className={errors.deliveryAddress && 'border-danger'}
                maxLength={1000}
                placeholder={PLACEHOLDER.DELIVERY_ADDRESS}
                rows={3}
                type="text"
                {...register('deliveryAddress')}
                onChange={event => setDeliveryAddressCounter(event.target.value.length)}
              />
              {errors.deliveryAddress && <Form.Text className="text-danger">{errors.deliveryAddress.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__deliveryInstructions" style={{ gridColumn: '1/3' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Delivery Instructions</span>
                <span>{deliveryInstructionsCounter}/1000</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                autoComplete="off"
                className={errors.deliveryInstructions && 'border-danger'}
                maxLength={1000}
                placeholder={PLACEHOLDER.DELIVERY_INSTRUCTIONS}
                rows={3}
                type="text"
                {...register('deliveryInstructions')}
                onChange={event => setDeliveryInstructionsCounter(event.target.value.length)}
              />
              {errors.deliveryInstructions && <Form.Text className="text-danger">{errors.deliveryInstructions.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__deliveryDueDate" style={{ gridColumn: '1' }}>
              <Form.Label>Delivery Due Date</Form.Label>
              <Controller
                control={control}
                name="deliveryDueDate"
                render={({ field: { onBlur, onChange, ref, value } }) => (
                  <div className={`form-control picker__container ${errors.deliveryDueDate ? 'border-danger' : ''}`}>
                    <DatePicker
                      className="picker__input"
                      dateFormat="dd/MM/yyyy"
                      minDate={getMinimumDate(value)}
                      onBlur={onBlur} //  Notify when input is touched
                      onChange={onChange}
                      placeholderText={PLACEHOLDER.SELECT_DELIVERY_DUE_DATE}
                      ref={ref}
                      selected={value ? new Date(value) : null}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      strictParsing
                    />
                    <CalendarIcon />
                  </div>
                )}
              />
              {errors.deliveryDueDate && <Form.Text className="text-danger">{errors.deliveryDueDate.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__noFailDeliveryDate" style={{ gridColumn: '1' }}>
              <Controller
                control={control}
                name="noFailDeliveryDate"
                render={({ field: { onBlur, onChange, ref, value } }) => (
                  <Form.Check
                    onBlur={onBlur}
                    onChange={e => {
                      onChange(e);
                      setIsNoFailDeliveryDateChecked(!isNoFailDeliveryDateChecked);
                    }}
                    label="No Fail Deliver Date"
                    ref={ref}
                    type="checkbox"
                    checked={isNoFailDeliveryDateChecked}
                  />
                )}
              />
              {errors.noFailDeliveryDate && <Form.Text className="text-danger">{errors.noFailDeliveryDate.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="form__samples" style={{ gridColumn: '1' }}>
              <Form.Label>
                Samples<span className="input__required">*</span>
              </Form.Label>
              <div>
                <Form.Check aria-label="samples__radio-yes" id="form__samples-yes" inline label="Yes" type="radio" value="Yes" {...register('samples')} onChange={() => handleSamples(true)} />
                <Form.Check aria-label="samples__radio-no" id="form__samples-no" inline label="No" type="radio" value="No" {...register('samples')} onChange={() => handleSamples(false)} />
              </div>
              {errors.samples && <Form.Text className="text-danger">{errors.samples.message}</Form.Text>}
            </Form.Group>
            {samples && (
              <>
                <Form.Group controlId="form__noOfSampleCopies" style={{ gridColumn: '1 / 2' }}>
                  <Form.Label>
                    Number Of Sample Copies<span className="input__required">*</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className={errors.noOfSampleCopies && 'border-danger'}
                    min={0}
                    onKeyDown={e => allowNumbers(e)}
                    placeholder={PLACEHOLDER.NUMBER_OF_SAMPLE_COPIES}
                    type="number"
                    {...register('noOfSampleCopies', {
                      valueAsNumber: true,
                    })}
                  />
                  {errors.noOfSampleCopies && <Form.Text className="text-danger">{errors.noOfSampleCopies.message}</Form.Text>}
                </Form.Group>
                <Form.Group controlId="form__sampleCopiesDeliveryAddress" style={{ gridColumn: '1 / 3' }}>
                  <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                      Sample Copies Delivery Address
                      <span className="input__required">*</span>
                    </span>
                    <span>{sampleCopiesDeliveryAddressCounter}/1000</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    autoComplete="off"
                    className={errors.sampleCopiesDeliveryAddress && 'border-danger'}
                    maxLength={1000}
                    placeholder={PLACEHOLDER.SAMPLE_COPIES_DELIVERY_ADDRESS}
                    rows={3}
                    type="text"
                    {...register('sampleCopiesDeliveryAddress')}
                    onChange={event => setSampleCopiesDeliveryAddressCounter(event.target.value.length)}
                  />
                  {errors.sampleCopiesDeliveryAddress && <Form.Text className="text-danger">{errors.sampleCopiesDeliveryAddress.message}</Form.Text>}
                </Form.Group>
              </>
            )}
            <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/4', marginTop: '30px' }}>
              Artwork
            </h6>
            <Form.Group controlId="form__artworkFormatId" style={{ gridColumn: '1 / 2' }}>
              <Form.Label>
                Artwork Format<span className="input__required">*</span>
              </Form.Label>
              <Controller
                control={control}
                name="artworkFormatId"
                render={({ field: { name, onBlur, onChange, ref, value } }) => {
                  return (
                    <Select
                      className={errors.artworkFormatId && 'border-danger-select'}
                      classNamePrefix="custom-select-input"
                      id="form__artworkFormatId"
                      isClearable
                      isDisabled={true}
                      maxMenuHeight={175}
                      onBlur={onBlur} //  Notify when input is touched
                      options={artworkFormatsList}
                      placeholder={PLACEHOLDER.SELECT_ARTWORK_FORMAT}
                      ref={ref}
                      value={artworkFormatsList.find(artworkFormat => artworkFormat.value === value)}
                    />
                  );
                }}
              />
              {errors.artworkFormatId && <Form.Text className="text-danger">{errors.artworkFormatId.message}</Form.Text>}
            </Form.Group>

            {artworkFormatOther && (
              <Form.Group controlId="form__quantity" style={{ gridColumn: '1' }}>
                <Form.Label>
                  Other Artwork Format<span className="input__required">*</span>
                </Form.Label>
                <Form.Control autoComplete="off" className={errors.quantity && 'border-danger'} disabled type="text" {...register('artworkFormatOther')} />
                {errors.artworkFormatOther && <Form.Text className="text-danger">{errors.artworkFormatOther.message}</Form.Text>}
              </Form.Group>
            )}
            <Form.Group controlId="form__artworkDueDate" style={{ gridColumn: '1' }}>
              <Form.Label>Artwork Due Date</Form.Label>
              <Controller
                control={control}
                name="artworkDueDate"
                render={({ field: { onBlur, onChange, ref, value } }) => (
                  <div
                    className={`form-control picker__container ${[READYTOINVOICE, INVOICED].includes(initialState?.status?.name) ? 'picker__container__disabled' : ''} ${
                      errors.proofDueDate ? 'border-danger' : ''
                    }`}
                  >
                    <DatePicker
                      className="picker__input"
                      dateFormat="dd/MM/yyyy"
                      disabled={[READYTOINVOICE, INVOICED].includes(initialState?.status?.name)}
                      minDate={getMinimumDate(value)}
                      onBlur={onBlur} //  Notify when input is touched
                      onChange={onChange}
                      placeholderText={PLACEHOLDER.SELECT_ARTWORK_DUE_DATE}
                      ref={ref}
                      selected={value ? new Date(value) : null}
                      shouldCloseOnSelect={true}
                      showYearDropdown
                      strictParsing
                    />
                    <CalendarIcon />
                  </div>
                )}
              />
              {errors.artworkDueDate && <Form.Text className="text-danger">{errors.artworkDueDate.message}</Form.Text>}
            </Form.Group>
            <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/4', marginTop: '30px' }}>
              Proofs
            </h6>
            <Form.Group controlId="form__typeOfProofsId" style={{ gridColumn: '1 / 2' }}>
              <Form.Label>
                Type Of Proofs<span className="input__required">*</span>
              </Form.Label>
              <Controller
                control={control}
                name="typeOfProofsId"
                render={({ field: { name, onBlur, onChange, ref, value } }) => {
                  return (
                    <Select
                      className={errors.typeOfProofsId && 'border-danger-select'}
                      classNamePrefix="custom-select-input"
                      id="form__typeOfProofsId"
                      isClearable
                      isDisabled={true}
                      maxMenuHeight={175}
                      onBlur={onBlur} //  Notify when input is touched
                      onChange={(val, { action }) => {
                        if (action === 'clear') {
                          onChange(null);
                        } else {
                          onChange(val?.value);
                        }
                        handleSelect(name, val);
                      }} // Send value to hook f = items.find(item => item.value = val);
                      options={typeOfProofsList}
                      placeholder={PLACEHOLDER.SELECT_TYPE_OF_PROOFS}
                      ref={ref}
                      value={typeOfProofsList.find(typeOfProof => typeOfProof.value === value)}
                    />
                  );
                }}
              />
              {errors.typeOfProofsId && <Form.Text className="text-danger">{errors.typeOfProofsId.message}</Form.Text>}
            </Form.Group>
            {typeOfProofsOther && (
              <Form.Group controlId="form__typeOfProofsOther" style={{ gridColumn: '1 / 3' }}>
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>
                    Other Type Of Proofs<span className="input__required">*</span>
                  </span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  autoComplete="off"
                  className={errors.typeOfProofsOther && 'border-danger'}
                  disabled
                  maxLength={1000}
                  placeholder={PLACEHOLDER.TYPE_OF_PROOFS_OTHER}
                  type="text"
                  {...register('typeOfProofsOther')}
                  onChange={event => setTypeOfProofsOtherCounter(event.target.value.length)}
                />
                {errors.typeOfProofsOther && <Form.Text className="text-danger">{errors.typeOfProofsOther.message}</Form.Text>}
              </Form.Group>
            )}
            {showProofOfDueDate && (
              <Form.Group controlId="form__proofDueDate" style={{ gridColumn: '1' }}>
                <Form.Label>Proof Due Date</Form.Label>
                <Controller
                  control={control}
                  name="proofDueDate"
                  render={({ field: { onBlur, onChange, ref, value } }) => (
                    <div
                      className={`form-control picker__container ${[READYTOINVOICE, INVOICED].includes(initialState?.status?.name) ? 'picker__container__disabled' : ''} ${
                        errors.proofDueDate ? 'border-danger' : ''
                      }`}
                    >
                      <DatePicker
                        className="picker__input"
                        dateFormat="dd/MM/yyyy"
                        minDate={getMinimumDate(value)}
                        onBlur={onBlur} //  Notify when input is touched
                        onChange={onChange}
                        placeholderText={PLACEHOLDER.SELECT_PROOF_OF_DUE_DATE}
                        ref={ref}
                        selected={value ? new Date(value) : null}
                        shouldCloseOnSelect={true}
                        showYearDropdown
                        strictParsing
                      />
                      <CalendarIcon />
                    </div>
                  )}
                />
                {errors.proofDueDate && <Form.Text className="text-danger">{errors.proofDueDate.message}</Form.Text>}
              </Form.Group>
            )}

            {isCDS && (
              <>
                <Form.Group controlId="form__jobItemOptions" style={{ gridColumn: '1/4' }}>
                  <Form.Label>Job Options</Form.Label>
                  <br />
                  {jobItemOptionsList.map(jobItemOption => (
                    <Form.Check
                      key={jobItemOption.label}
                      id={jobItemOption.label}
                      inline
                      label={jobItemOption.label}
                      type="checkbox"
                      value={jobItemOption.value}
                      checked={jobItemOption?.checked}
                      {...register('jobItemOptions')}
                      onChange={() => handleJobItemChange(jobItemOption)}
                    />
                  ))}
                  {errors.jobItemOptions && <Form.Text className="text-danger">{errors.jobItemOptions}</Form.Text>}
                </Form.Group>
                {targetDate && (
                  <Form.Group controlId="form__targetDate" style={{ gridColumn: '1' }}>
                    <Form.Label>
                      Target Date
                      <span className="input__required">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="targetDate"
                      render={({ field: { onBlur, onChange, ref, value } }) => (
                        <div className={`form-control picker__container ${errors.targetDate ? 'border-danger' : ''}`}>
                          <DatePicker
                            className="picker__input"
                            dateFormat="dd/MM/yyyy"
                            minDate={getMinimumDate(value)}
                            onBlur={onBlur} //  Notify when input is touched
                            onChange={onChange}
                            placeholderText={PLACEHOLDER.SELECT_TARGET_DATE}
                            ref={ref}
                            selected={value ? new Date(value) : null}
                            shouldCloseOnSelect={true}
                            showYearDropdown
                            strictParsing
                          />
                          <CalendarIcon />
                        </div>
                      )}
                    />
                    {errors.targetDate && <Form.Text className="text-danger">{errors.targetDate.message}</Form.Text>}
                  </Form.Group>
                )}
              </>
            )}

            <h6 className="form__subHeader top__subHeader" style={{ gridColumn: '1/4', marginTop: '30px' }}>
              Notes
            </h6>
            {isCDS && (
              <Form.Group controlId="form__cdsNotes" style={{ gridColumn: '1 / 3' }}>
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>CDS Notes (For Internal Use)</span>
                  <span>{cdsNotesCounter}/1000</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  autoComplete="off"
                  className={errors.cdsNotes && 'border-danger'}
                  maxLength={1000}
                  placeholder={PLACEHOLDER.CDS_NOTES}
                  rows={3}
                  type="text"
                  {...register('cdsNotes')}
                  onChange={event => setCdsNotesCounter(event.target.value.length)}
                />
                {errors.cdsNotes && <Form.Text className="text-danger">{errors.cdsNotes.message}</Form.Text>}
              </Form.Group>
            )}
            {(isCDS || isSupplier) && (
              <Form.Group controlId="form__supplierNotes" style={{ gridColumn: '1 / 3' }}>
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {isSupplier ? <span></span> : <span>Supplier Notes (Can Be Seen By Supplier)</span>}
                  <span>{supplierNotesCounter}/1000</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  autoComplete="off"
                  className={errors.supplierNotes && 'border-danger'}
                  maxLength={1000}
                  placeholder={isSupplier ? ' ' : PLACEHOLDER.SUPPLIER_NOTES}
                  rows={3}
                  type="text"
                  {...register('supplierNotes')}
                  onChange={event => setSupplierNotesCounter(event.target.value.length)}
                />
                {errors.supplierNotes && <Form.Text className="text-danger">{errors.supplierNotes.message}</Form.Text>}
              </Form.Group>
            )}
            {!isSupplier && (
              <Form.Group controlId="form__clientNotes" style={{ gridColumn: '1 / 3' }}>
                <Form.Label style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {isCDS && <span>Client Notes (Can Be Seen By Client)</span>}
                  {isClient && <span>Notes</span>}
                  <span>{clientNotesCounter}/1000</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  autoComplete="off"
                  className={errors.clientNotes && 'border-danger'}
                  maxLength={1000}
                  placeholder={isCDS ? PLACEHOLDER.CLIENT_NOTES : PLACEHOLDER.NOTES}
                  rows={3}
                  type="text"
                  {...register('clientNotes')}
                  onChange={event => setClientNotesCounter(event.target.value.length)}
                />
                {errors.clientNotes && <Form.Text className="text-danger">{errors.clientNotes.message}</Form.Text>}
              </Form.Group>
            )}
            <Form.Group controlId="form__jobType" style={{ gridColumn: '1 / 2' }}>
              <Form.Label>Job Type</Form.Label>
              <Form.Control autoComplete="off" disabled type="text" value={initialState.productType} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="outline__button"
            onClick={() => {
              setShowConfirmModal(true);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button className="full__button" form="printSpecificationsForm-Quotation" type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <PopupModal
        bodyTitle={'Are you sure? Any changes will be lost'}
        footerButtons={[
          {
            type: OUTLINED,
            name: 'No',
            onClick: () => setShowConfirmModal(false),
          },
          {
            type: FULL,
            name: 'Yes',
            onClick: () => {
              setShowConfirmModal(false);
              onHide();
            },
          },
        ]}
        header={'Confirmation'}
        onHide={() => setShowConfirmModal(false)}
        show={showConfirmModal}
        type={CONFIRMATION}
      />
      <PopupModal
        show={errorModal}
        onHide={() => setErrorModal(false)}
        type={ERROR}
        header={'Error'}
        bodyTitle={'Saving Job Information failed!'}
        footerButtons={[
          {
            type: OUTLINED,
            name: 'Close',
            onClick: () => setErrorModal(false),
          },
        ]}
      />
    </>
  );
};

export default Quotation;
