import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { selectBreadcrumb } from './slice/selectors';
import './style.sass';

const Breadcrumb = () => {
  const breadcrumb = useSelector(selectBreadcrumb);
  const flag = breadcrumb.length === 3;

  return (
    <>
      <ul className="breadcrumb__rfq">
        {breadcrumb.map(breadcrumbItem => (
          <li key={breadcrumbItem.name + breadcrumbItem.url}>
            <NavLink to={breadcrumbItem.url} className={`${flag && breadcrumbItem.name === 'My Jobs' && 'notActive'}`}>
              {breadcrumbItem.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="partitionLine"></div>
    </>
  );
};

export default Breadcrumb;
