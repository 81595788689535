//  Externals
import * as yup from 'yup';
//  Internals
import { checkIsOtherSelected, checkIsComplexJobSelected } from '../../../../../utils/form';
import ERRORS from '../../../../../data/errors';

const schema = ({
  artworkFormatsList,
  coloursOnInsideList,
  coloursOnOutsideList,
  coloursOnTextList,
  coverMaterialsList,
  coverMaterialWeightsList,
  finishedSizesList,
  finishingsList,
  isCDS,
  jobItemOptionsList,
  materialsList,
  materialWeightsList,
  typeOfProofsList,
}) =>
  yup.object({
    title: yup.string().trim().nullable().required(ERRORS.LINE_TITLE),
    description: yup.string().trim().nullable(),
    reference: yup.string().trim().nullable(),
    quantity: yup
      .number()
      .typeError('Please provide a valid Quantity.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a Quantity.')
      .min(1, 'Please provide a valid Quantity'),
    quantityRunOn: yup
      .number()
      .typeError('Please provide a valid Quantity Run-On.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable(),
    finishedSizeId: yup.number().nullable().required('Please select Finished Size.'),
    finishedSizeOther: yup
      .string()
      .trim()
      .nullable()
      .when('finishedSizeId', {
        is: (finishedSizeId: number) => checkIsOtherSelected(finishedSizeId, finishedSizesList),
        then: yup.string().trim().nullable().required('Please provide Other Finished Size details.'),
      }),
    pagesForText: yup
      .number()
      .typeError('Please provide a valid Pages For Text.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide a Pages For Text.')
      .min(1, 'Please provide a valid Pages For Text.'),
    coloursOnTextId: yup.number().nullable().required('Please select Colours On Text.'),
    coloursOnTextOther: yup
      .string()
      .trim()
      .nullable()
      .when('coloursOnTextId', {
        is: (coloursOnTextId: number) => checkIsOtherSelected(coloursOnTextId, coloursOnTextList),
        then: yup.string().trim().nullable().required('Please provide Other Colours on Text details.'),
      }),
    sealerOnText: yup.string().trim().nullable().required('Please provide Sealer On Text.'),
    materialId: yup.number().nullable().required('Please select Material.'),
    materialOther: yup
      .string()
      .trim()
      .nullable()
      .when('materialId', {
        is: (materialId: number) => checkIsOtherSelected(materialId, materialsList),
        then: yup.string().trim().nullable().required('Please provide Other Material details.'),
      }),
    materialWeightId: yup.number().nullable().required('Please select Material Weight.'),
    materialWeightOther: yup
      .string()
      .trim()
      .nullable()
      .when('materialWeightId', {
        is: (materialWeightId: number) => checkIsOtherSelected(materialWeightId, materialWeightsList),
        then: yup.string().trim().nullable().required('Please provide Other Material Weight details.'),
      }),
    pagesForCover: yup
      .number()
      .typeError('Please provide a valid Pages for Cover.')
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required('Please provide Pages for Cover.')
      .min(1, 'Please provide a valid Pages for Cover'),
    coloursOnInsideId: yup.number().nullable().required('Please provide Colours On Inside.'),
    coloursOnInsideOther: yup
      .string()
      .trim()
      .nullable()
      .when('coloursOnInsideId', {
        is: (coloursOnInsideId: number) => checkIsOtherSelected(coloursOnInsideId, coloursOnInsideList),
        then: yup.string().trim().nullable().required('Please provide Other Colours On Inside details.'),
      }),
    coloursOnOutsideId: yup.number().nullable().required('Please provide Colours On Outside.'),
    coloursOnOutsideOther: yup
      .string()
      .trim()
      .nullable()
      .when('coloursOnOutsideId', {
        is: (coloursOnOutsideId: number) => checkIsOtherSelected(coloursOnOutsideId, coloursOnOutsideList),
        then: yup.string().trim().nullable().required('Please provide Other Colours On Oustide details.'),
      }),
    sealerOnCover: yup.string().trim().nullable().required('Please provide Sealer On Cover.'),
    coverMaterialId: yup.number().nullable().required('Please provide Cover Material.'),
    coverMaterialOther: yup
      .string()
      .trim()
      .nullable()
      .when('coverMaterialId', {
        is: (coverMaterialId: number) => checkIsOtherSelected(coverMaterialId, coverMaterialsList),
        then: yup.string().trim().nullable().required('Please provide Other Cover Material details.'),
      }),
    coverMaterialWeightId: yup.number().nullable().required('Please provide Cover Material Weight.'),
    coverMaterialWeightOther: yup
      .string()
      .trim()
      .nullable()
      .when('coverMaterialWeightId', {
        is: (coverMaterialWeightId: number) => checkIsOtherSelected(coverMaterialWeightId, coverMaterialWeightsList),
        then: yup.string().trim().nullable().required('Please provide Other Cover Material Weight details.'),
      }),

    finishingId: yup.number().nullable().required('Please select Finishing.'),
    finishingOther: yup
      .string()
      .trim()
      .nullable()
      .when('finishingId', {
        is: (finishingId: number) => checkIsOtherSelected(finishingId, finishingsList),
        then: yup.string().trim().nullable().required('Please provide Other Finishing details.'),
      }),
    addendum: yup.string().trim().nullable(),
    deliveryContact: yup.string().trim().nullable(),
    deliveryAddress: yup.string().trim().nullable(),
    deliveryInstructions: yup.string().trim().nullable(),
    deliveryDueDate: yup.string().trim().nullable(),
    noFailDeliveryDate: yup.boolean().required('Please provide No Fail Delivery Date.'),
    samples: yup.string().trim().nullable().required('Please provide Samples.'),
    noOfSampleCopies: yup
      .number()
      .nullable()
      .when('samples', {
        is: (samples: string) => samples === 'Yes',
        then: yup
          .number()
          .typeError('Please provide a valid No of Sample Copies.')
          .transform(value => (isNaN(value) ? null : value))
          .nullable()
          .required('Please provide a No of Sample Copies.')
          .min(1, 'Please provide a valid No of Sample Copies.'),
      }),
    sampleCopiesDeliveryAddress: yup
      .string()
      .trim()
      .nullable()
      .when('samples', {
        is: (samples: string) => samples === 'Yes',
        then: yup.string().nullable().required('Please provide Sample Copies Delivery Address.'),
      }),
    artworkFormatId: yup.number().nullable().required('Please select Artwork Format.'),
    artworkFormatOther: yup
      .string()
      .trim()
      .nullable()
      .when('artworkFormatId', {
        is: (artworkFormatId: number) => checkIsOtherSelected(artworkFormatId, artworkFormatsList),
        then: yup.string().trim().nullable().required('Please provide Other Artwork Format details.'),
      }),
    artworkDueDate: yup.string().trim().nullable(),
    typeOfProofsId: yup.number().nullable().required('Please select Type Of Proofs.'),
    typeOfProofsOther: yup
      .string()
      .trim()
      .nullable()
      .when('typeOfProofsId', {
        is: (typeOfProofsId: number) => checkIsOtherSelected(typeOfProofsId, typeOfProofsList),
        then: yup.string().trim().nullable().required('Please provide Other Type Of Proofs details.'),
      }),
    proofDueDate: yup.string().trim().nullable(),
    primaryCategoryId: yup
      .number()
      .nullable()
      .when('$exist', {
        is: () => isCDS,
        then: yup.number().nullable().required('Please select Primary Category.'),
      }),
    secondaryCategoryId: yup
      .number()
      .nullable()
      .when('$exist', {
        is: () => isCDS,
        then: yup.number().nullable().required('Please select Secondary Category.'),
      }),
    jobItemOptions: yup.array().nullable(),
    targetDate: yup
      .string()
      .trim()
      .nullable()
      .when('jobItemOptions', {
        is: (jobItemOptions: string[]) => checkIsComplexJobSelected(jobItemOptions, jobItemOptionsList),
        then: yup.string().trim().nullable().required('Please provide a valid complex job target quote date.'),
      }),
    cdsNotes: yup.string().trim().nullable(),
    supplierNotes: yup.string().trim().nullable(),
    clientNotes: yup.string().trim().nullable(),
  });

export default schema;
