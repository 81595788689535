//  Brochure Print Specifications
interface BrochureType {
  title: string | null;
  description: string | null;
  reference: string | null;
  relatedLineId: string | null;
  relatedLineValue: number | null;
  quantity: number | null;
  quantityRunOn: number | null;
  quantityActual?: number | null;
  finishedSizeId: number | null;
  finishedSizeOther: string | null;
  pagesForText: number | null;
  coloursOnTextId: number | null;
  coloursOnTextOther: string | null;
  sealerOnText: boolean | string | null;
  materialId: number | null;
  materialOther: string | null;
  materialWeightId: number | null;
  materialWeightOther: string | null;
  pagesForCover: number | null;
  coloursOnInsideId: number | null;
  coloursOnInsideOther: string | null;
  coloursOnOutsideId: number | null;
  coloursOnOutsideOther: string | null;
  sealerOnCover: boolean | string | null;
  coverMaterialId: number | null;
  coverMaterialOther: string | null;
  coverMaterialWeightId: number | null;
  coverMaterialWeightOther: string | null;
  finishingId: number | null;
  finishingOther: string | null;
  addendum: string | null;
  deliveryContact: string | null;
  deliveryAddress: string | null;
  deliveryInstructions: string | null;
  deliveryDueDate: string | Date | null;
  noFailDeliveryDate: boolean;
  samples: boolean | string | null;
  noOfSampleCopies: number | null;
  sampleCopiesDeliveryAddress: string | null;
  artworkFormatId: number | null;
  artworkFormatOther: string | null;
  artworkDueDate: string | Date | null;
  typeOfProofsId: number | null;
  typeOfProofsOther: string | null;
  proofDueDate: string | Date | null;
  primaryCategoryId: number | null;
  secondaryCategoryId: number | null;
  jobItemOptions: number[];
  targetDate: string | Date | null;
  cdsNotes: string | null;
  supplierNotes: string | null;
  clientNotes: string | null;
  jobType: string;
  isActive: boolean;
  status?: {
    code: string;
    id: number;
    name: string;
  };
  managementFee?: number;
  deliveryAddressChangeReasonId: number | null;
  deliveryAddressChangeReasonOther: string | null;
}

//  Initial state of BrochureType product
export const initialBrochure: BrochureType = {
  title: null,
  description: null,
  reference: null,
  relatedLineId: null,
  relatedLineValue: null,
  quantity: null,
  quantityRunOn: null,
  finishedSizeId: null,
  finishedSizeOther: null,
  pagesForText: null,
  coloursOnTextId: null,
  coloursOnTextOther: null,
  sealerOnText: null,
  materialId: null,
  materialOther: null,
  materialWeightId: null,
  materialWeightOther: null,
  pagesForCover: null,
  coloursOnInsideId: null,
  coloursOnInsideOther: null,
  coloursOnOutsideId: null,
  coloursOnOutsideOther: null,
  sealerOnCover: null,
  coverMaterialId: null,
  coverMaterialOther: null,
  coverMaterialWeightId: null,
  coverMaterialWeightOther: null,
  finishingId: null,
  finishingOther: null,
  addendum: null,
  deliveryContact: null,
  deliveryAddress: null,
  deliveryInstructions: null,
  deliveryDueDate: null,
  noFailDeliveryDate: false,
  samples: null,
  noOfSampleCopies: null,
  sampleCopiesDeliveryAddress: null,
  artworkFormatId: null,
  artworkFormatOther: null,
  artworkDueDate: null,
  typeOfProofsId: null,
  typeOfProofsOther: null,
  proofDueDate: null,
  primaryCategoryId: null,
  secondaryCategoryId: null,
  jobItemOptions: [],
  targetDate: null,
  cdsNotes: null,
  supplierNotes: null,
  clientNotes: null,
  jobType: '',
  isActive: false,
  deliveryAddressChangeReasonId: null,
  deliveryAddressChangeReasonOther: null,
};
